import React from 'react';
import Root from './Root';
import routes from './routes';
import ReactDOM from 'react-dom';
import { fromJS } from 'immutable';
import configureStore from './redux/configureStore';
import createHistory from 'history/createHashHistory';
import { syncHistoryWithStore } from 'react-router-redux';
import { api } from 'api';
import { setUserInfo } from './redux/modules/login';
import { push } from 'react-router-redux';

if (!global._babelPolyfill) {
    require('babel-polyfill');
}

let initialState = {};

// rehydrate initialState for JS app
if (window.__INITIAL_STATE__) {
    initialState = window.__INITIAL_STATE__;
    // Transform into Immutable.js collections,

    // but leave top level keys untouched for Redux
    Object.keys(initialState).forEach((key) => {
        initialState[key] = fromJS(initialState[key]);
    });
}

const hashHistory = createHistory();
const store = configureStore(initialState, hashHistory);
const history = syncHistoryWithStore(hashHistory, store);

let hasRendered = false;

const renderApp = () => {
   if (!hasRendered) {
       // Render the React application to the DOM
       // Root component is to bootstrap Provider, Router and DevTools
       ReactDOM.render(
           <Root history={history} routes={routes} store={store} />,
           document.getElementById('app-container')
       );
   }
   hasRendered = true;
};


ReactDOM.render(
   <div>Cargando...</div>,
   document.getElementById('app-container')
);

const token = localStorage.getItem('token');

if (token) {
    api.get(`me/getUserInfo`, {}).then((response) => {
        if (response){
            if(response.role_name === 'superuser' || response.role_name === 'superusuario'){
                response['username'] = 'Super Usuario'
            }
            store.dispatch(setUserInfo(response))
        }
    }).catch((error) => {
        console.log("error al obtener información del usuario")
    }).finally(() => {
        renderApp();
    });
} else {
    renderApp();
}
