import Form from './AppointmentsTodayForm';
import React, { Component } from 'react';
import LoadMask from 'Utils/LoadMask/LoadMask';

export default class AppointmentsCrear extends Component {

    state = {
        editar: false,
        mensaje: 'Agregar cita',
        search_patient: 1,
        no_patient_options: 1,
    }
    componentWillUnmount() {
        this.props.resetStore();
    }

    componentWillMount(){

        const {
            createInitialize,
        } = this.props;

        createInitialize();

        if(this.props.match.params.id){
            this.props.detail(this.props.match.params.id);
            this.setState({
                    editar: true,
                    mensaje: 'Editar Cita'
                });
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot){
        if(prevProps.updateData.patient_id != this.props.updateData.patient_id){
            if(this.props.updateData.patient_id){
                this.setState({
                    search_patient: 0,
                    no_patient_options: 0,
                });
            }
        }
    }

    initializePatientBirthdate = () => {

        const {
            createInitializePatient
        } = this.props;

        this.setState({
            search_patient: !this.state.search_patient,
        })

        createInitializePatient();
    }

    showPatientOptions = () => {
        this.setState({
            no_patient_options: !this.state.no_patient_options,
        })
    }

    showPatientSearch = () => {
        this.setState({
            search_patient: !this.state.search_patient,
        })
    }

    render() {

        const {
            createToday,
            update,
            detailPatient,
        } = this.props;

        const {
            municipios,
            validateNit,
            updateData,
            setUpdateData,
            cargando,
        } = this.props;

        return (
            <div className="content">
                <div className="mx-5 py-4">
                    <div className="table-decoration">
                        <LoadMask loading={cargando} blur_1>
                            <div className="fields">
                                <div className="top-title">
                                    CREAR CITA
                                </div>
                                <Form
                                    onSubmit={createToday}
                                    validateNit={validateNit}
                                    municipios={municipios}
                                    detailPatient={detailPatient}
                                    patient_exists={this.state.patient_exists}
                                    search_patient={this.state.search_patient}
                                    showPatientSearch={this.showPatientSearch}
                                    showPatientOptions={this.showPatientOptions}
                                    no_patient_options={this.state.no_patient_options}
                                    initializePatientBirthdate={this.initializePatientBirthdate}
                                />
                            </div>
                        </LoadMask>
                    </div>
                </div>
            </div>
        )
    }
}
