import Form from './AppointmentsForm';
import React, { Component } from 'react';
import LoadMask from 'Utils/LoadMask/LoadMask';
import classNames from 'classnames';

export default class AppointmentsCrear extends Component {

    state = {
        editar: false,
        mensaje: 'Agregar cita',
        search_patient: 1,
        no_patient_options: 1,
    }

    componentWillMount(){
        const {
            createInitialize,
        } = this.props;

        createInitialize();

        if(this.props.match.params.id){
            this.props.detail(this.props.match.params.id);
            this.setState({
                    editar: true,
                    mensaje: 'Editar Cita'
                });
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot){
        if(prevProps.updateData.patient_id != this.props.updateData.patient_id){
            if(this.props.updateData.patient_id){
                this.setState({
                    search_patient: 0,
                    no_patient_options: 0,
                });
            }
        }
    }

    initializePatientBirthdate = () => {

        const {
            createInitializePatient
        } = this.props;

        this.setState({
            search_patient: !this.state.search_patient,
        })

        createInitializePatient();
    }

    showPatientOptions = () => {
        this.setState({
            no_patient_options: !this.state.no_patient_options,
        })
    }

    showPatientSearch = () => {
        this.setState({
            search_patient: !this.state.search_patient,
        })
    }

    render() {

        const {
            create,
            week_update,
            detailPatient,
            isNavBarOpen,
        } = this.props;

        const {
            municipios,
            updateData,
            setUpdateData,
            cargando,
        } = this.props;

        return (
            <div className={classNames('content', { 'content-min': isNavBarOpen })}>
                <div className="d-inline-flex w-100">
                    <div className="row-fluid w-50 tab-background-blue">
                        <a href="/#/appointments" className="d-flex justify-content-center align-items-center tab-active tab-br-tr">
                            CITAS DE HOY
                        </a>
                    </div>
                    <div className="row-fluid w-50 tab-background">
                        <a href="/#/schedule" className="d-flex justify-content-center align-items-center tab tab-br-bl">
                            AGENDA
                        </a>
                    </div>
                </div>
                <div className="mx-5 my-4">
                    <div className="table-decoration">
                        <LoadMask loading={cargando} blur_1>
                            {
                                this.state.editar ?
                                    <div className="fields">
                                        <div className="top-title">
                                            CITA
                                        </div>
                                        <Form
                                            editando={true}
                                            onSubmit={week_update}
                                            updateData={updateData}
                                            setUpdateData={setUpdateData}
                                            municipios={municipios}
                                            detailPatient={detailPatient}

                                            patient_exists={this.state.patient_exists}
                                            search_patient={this.state.search_patient}
                                            showPatientSearch={this.showPatientSearch}
                                            showPatientOptions={this.showPatientOptions}
                                            no_patient_options={this.state.no_patient_options}
                                            initializePatientBirthdate={this.initializePatientBirthdate}
                                        />
                                    </div>
                            :
                                <div className="fields">
                                    <div className="top-title">
                                        CREAR CITA
                                    </div>
                                    <Form
                                        onSubmit={create}

                                        municipios={municipios}
                                        detailPatient={detailPatient}

                                        patient_exists={this.state.patient_exists}
                                        search_patient={this.state.search_patient}
                                        showPatientSearch={this.showPatientSearch}
                                        showPatientOptions={this.showPatientOptions}
                                        no_patient_options={this.state.no_patient_options}
                                        initializePatientBirthdate={this.initializePatientBirthdate}
                                    />
                                </div>
                            }
                        </LoadMask>
                    </div>
                </div>
            </div>
        )
    }
}
