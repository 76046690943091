import { connect } from 'react-redux';
import { actions } from '../../../redux/modules/reportes/new-arqueos';

import DetalleArqueo from './DetalleArqueo';
import {resetStore} from "../../../redux/modules/login";

const ms2p = (state) => {
    return {
        ...state.arqueoNew,
    };
};

const md2p = { ...actions, resetStore };

export default connect(ms2p, md2p)(DetalleArqueo);
