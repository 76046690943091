import React, { Component } from 'react';
import { RenderCurrency } from 'Utils/renderField/renderReadField';
import { renderCurrency } from 'Utils/renderField/renderField';
import { Field, FieldArray, reduxForm} from 'redux-form';
import { validate, validators } from 'validate-redux-form';
import MyModal from '../../Utils/myModal';
import { renderCardRow } from "./cardRow";
import { connect } from 'react-redux';

let index = 0;
class CardsForm extends Component{
    render(){
        index = this.props.index;

        const {
            handleSubmit,
        } = this.props;

        return(
            <form onSubmit={handleSubmit} id="selectCard">
                <table className="table table-sm" id="table-tarjet">
                    <thead className="thead-tarjet">
                        <tr>
                            <th>Cantidad</th>
                            <th>Tarjeta</th>
                            <th>Acciones</th>
                        </tr>
                    </thead>
                    <tbody>
                        <FieldArray
                            name="card_payments"
                            component={renderCardRow}
                            props = {{ cards: this.props.listCards }}
                        />
                    </tbody>
                </table>
            </form>
        );
    }
}

CardsForm = reduxForm({
    form: 'cardsForm',
    enableReinitialize: true,
    validate: data => {
        const errors = {};
        const membersArrayErrors = [];

        data.card_payments.forEach((member, memberIndex) => {
            const memberErrors = {}
            if(!member || !member.amount){
                memberErrors.amount = 'Campo requerido';
                membersArrayErrors[memberIndex] = memberErrors;
            }

            if(!member || !member.cards){
                memberErrors.cards = 'Campo requerido';
                membersArrayErrors[memberIndex] = memberErrors;
            }
        });

        if (membersArrayErrors.length) {
            errors.card_payments = membersArrayErrors
        }

        return errors;

    }
 })(CardsForm);


 CardsForm = connect(
     state => { return ({
         initialValues: state.sales.productsInCarOrdered[index].dataPayments
     })}
 )(CardsForm)


 export default CardsForm;
