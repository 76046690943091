import Table from '../Utils/Grid';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { BreakLine } from '../Utils/tableOptions';
import HeaderSimple from '../Utils/Header/HeaderSimple';
import ToolbarSimple from '../Utils/Toolbar/ToolbarSimple';
import { activeFormatter } from '../Utils/Acciones/Acciones';
import moment from "moment";

function formatColor(cell){
    if (cell){
        return <span className="color-list" style={{background:cell}}></span>
    } else {
        return <span style={{background:"white"}}></span>
    }
}

class Doctor extends Component {
    componentWillUnmount() {
        this.props.resetStore();
    }

    componentWillMount() {
        this.props.listar()
    }

    render() {
        const {
            listar,
            detail,
            create,
            update,
            destroy,
        } = this.props;

        const {
            cargando,
            page,
            data
        } = this.props;

        return (
            <div className="content">
                <div className="m-auto row-fluid w-100">
                    <HeaderSimple
                        texto={"Agregar doctor"}
                        ruta={"/doctors/create"}
                    />
                    <div className="table-decoration">
                        <div className="top-title">
                            LISTADO DE DOCTORES
                        </div>
                        <Table
                            onPageChange={listar}
                            data={data}
                            loading={cargando}
                            page={page}
                        >
                            <TableHeaderColumn
                                tdStyle={BreakLine}
                                thStyle={BreakLine}
                                dataField="name" dataSort>Nombre</TableHeaderColumn>
                            <TableHeaderColumn
                                tdStyle={BreakLine}
                                thStyle={BreakLine}
                                dataField="clinic" dataSort>Clínica</TableHeaderColumn>
                            <TableHeaderColumn
                                tdStyle={BreakLine}
                                thStyle={BreakLine}
                                dataField="created"
                                dataSort
                                dataFormat={(cell)=>{
                                    return moment(cell).format("DD-MM-YYYY");
                                }}
                                >Fecha de registro</TableHeaderColumn>
                            <TableHeaderColumn
                                tdStyle={BreakLine}
                                thStyle={BreakLine}
                                dataFormat= {formatColor}
                                dataField="color" dataSort>Color</TableHeaderColumn>
                            <TableHeaderColumn
                                dataField="id"
                                isKey={true}
                                dataAlign="center"
                                dataFormat={activeFormatter({ ver: '/doctors', editar: '/doctors', eliminar: destroy })}>Acciones</TableHeaderColumn>
                        </Table>
                    </div>
                </div>
            </div>
        )
    }
}

export default Doctor;
