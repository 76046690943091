const React = require('react')
class Upload extends React.Component {

    state = {
        archivos: [],
        url_archivos: [],
    }

    handleChange = (event) => {
        console.log("EVENT: ", event.target.files);
        
        //add...
        let archivos = [];
        if(this.props.multiple){
            archivos = this.state.archivos;
        }

        //const archivos = this.state.archivos; //mod
        const listaArchivos = event.target.files;

        let indice_acumulado = archivos.length;
                
        for (let iterador = 0; iterador < listaArchivos.length; iterador++){        
            const archivo = listaArchivos.item(iterador);
            const indice = iterador + 1 + indice_acumulado; //mod
            const name = 'foto' + indice.toString();
            archivos.push({name, archivo});
            this.props.handleChange(archivos);

            const img = URL.createObjectURL(archivo);

            //add...
            let url_archivos = [];
            if(this.props.multiple){
                url_archivos = this.state.url_archivos;
            }

            //let url_archivos = this.state.url_archivos;
            url_archivos.push(img);

            this.setState({ url_archivos, archivos });
        }
    }

    removeFile = (index) => {
        const url_archivos = this.state.url_archivos.filter((item, key) => {
            if(key !== index){
                return item;
            }
        });

        const archivos = this.state.archivos.filter((item, key) => {
            if(key !== index){
                return item;
            }
        });

        this.props.removeFile(archivos);
        this.setState({ url_archivos, archivos });
    }

    getData = () => {
        return this.state.archivos;
    }

    render() {
        return (
            <div className="d-flex flex-row flex-wrap">
                {this.state.url_archivos.length > 0 ?
                    <React.Fragment>
                        {this.state.url_archivos.map((item, i) => {
                            
                            let pdf = false;
                            try{
                                if(this.state.archivos[i].archivo.name.includes(".pdf")){
                                    pdf = true;
                                }
                            }catch(e){
                                console.log("error: ", e);
                            }

                            return(
                                <div
                                    key={i}
                                    style={{width:100, height:100, marginRight:20, alignContent: 'right', position: 'relative'}}
                                >
                                    <button
                                        type="button"
                                        onClick={()=>this.removeFile(i)}
                                        style={{
                                            borderRadius: 10,
                                            background: 'red',
                                            color: 'white',
                                            fontWeight: 'bold',
                                            border: 'none',
                                            width:21,
                                            height: 21,
                                            textAlign: 'center',
                                            alignItems: 'center',
                                            padding: '',
                                            position: 'absolute',
                                            cursor: 'pointer',
                                            right: 5,
                                            top: 5,
                                        }}
                                    >x</button>
                                    {pdf ?
                                            <img src={require("../../../../../assets/img/icons/pdf.png")} style={{width:100, height:100}}/>
                                        :
                                            <img src={item} style={{width:100, height:100}}/>
                                    }
                                </div>
                            )
                        })}
                    </React.Fragment>
                : null}

                <label className="d-flex flex-row justify-content-center align-items-center" style={{width:100, height:100, background: '#dcdcdc', cursor: 'pointer'}}>
                    <input
                        type="file" onChange={this.handleChange}
                        multiple={this.props.multiple}
                        style={{
                            display: 'none',
                        }}
                    />
                    <div className="d-flex flex-row justify-content-center align-items-center" style={{width:40, height:40, background: '#7F7F7F', textAlign: 'center', borderRadius:5}}>
                        <span style={{fontSize:50, fontWeight:'bold',color:'#dcdcdc'}}>+</span>
                    </div>
                </label>

                    <input
                        type="file" onChange={this.handleChange}
                        multiple={this.props.multiple}
                        style={{
                            display: 'none',
                        }}
                    />
            </div>
        );
  }
}

export default Upload;
