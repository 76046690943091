import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import { Link } from 'react-router-dom';
import './acciones.css';
import Swal from 'sweetalert2';

class Acciones extends Component {
    constructor(props) {
        super(props);
        this.state = { redirect: false, url: '' };
        this.redirect = this.redirect.bind(this);
        this.eliminar = this.eliminar.bind(this);
        this.eliminarExtra = this.eliminarExtra.bind(this);
        this.editar = this.editar.bind(this);
    }
    redirect(url) {
        return () => {
            this.setState({ url });
            this.setState({ redirect: true });
        };
    }
    eliminar(id, row) {
        return () => {
            Swal({
                title: '¿Desea eliminar este elemento?',
                text: 'No puedes deshacer esta acción',
                type: 'warning',
                showCancelButton: true,
                confirmButtonText: 'Sí, eliminar',
                cancelButtonText: 'No, cancelar',
                reverseButtons: true
            }).then((result) => {
                if (result.value) {
                    this.props.eliminar(id, row);
                }
            });
        }
    }

    eliminarExtra(id, row) {
        return () => {
            Swal({
                title: '¿Desea eliminar este elemento?',
                text: 'No puedes deshacer esta acción',
                type: 'warning',
                showCancelButton: true,
                confirmButtonText: 'Sí, eliminar',
                cancelButtonText: 'No, cancelar',
                reverseButtons: true
            }).then((result) => {
                if (result.value) {
                    this.props.eliminar_extra(id, row);
                }
            });
        }
    }

    editar(id) {
        return () => {
            this.props.editar(id);
        }
    }

    render() {
        const { id, ver, editar, historial_clinico, eliminar, eliminar_extra, administrar, adicional, ruta_retorno='', obj, row, ver_icono, ver_factura, ver_factura_cobrar, anular_factura, imp_factura, historial_cobro, ver_movimientos, editar_oc, authorization_oc, ingresar, ver_ingreso_producto, adm_smbx} = this.props;

        if (this.state.redirect) {
            return (<Redirect to={`${this.state.url}/${id}`} />);
        }
        let offset = 0;
        if (ver !== undefined) {
            offset += 1;
        }
        if (editar !== undefined) {
            offset += 1;
        }
        if (eliminar !== undefined) {
            offset += 1;
        }
        if (ver_icono !== undefined) {
            offset += 1;
        }

        return (
            <div className="row">
                <div className="col-lg-12 col-md-12 col-sm-12">
                    {(adicional !== undefined) && adicional(id,row)}

                    {(ver_icono !== undefined) && (
                        <Link to={`${ver_icono}/${id}/`} ><img className="action-img watch" title="Ver" src={require("../../../../../assets/img/icons/view-icon.png")} alt="Ver" /></Link>
                    )}
                    {(editar !== undefined) && ((typeof editar) === "string") && (
                        <Link to={`${editar}/${id}/edit`} ><img className="action-img edit" title="Editar" src={require("../../../../../assets/img/icons/edit-icon.png")} alt="Editar" /></Link>
                    )}
                    {(editar !== undefined) && ((typeof editar) !== "string") && (
                        <img onClick={this.editar(id)} className="action-img edit" title="Editar" src={require("../../../../../assets/img/icons/edit-icon.png")} alt="Editar" />
                    )}
                    {(ver !== undefined) && ((typeof ver) === "string") && (
                        <Link to={`${ver}/${id}/view`} ><img className="action-img watch" title="Ver" src={require("../../../../../assets/img/icons/view-icon.png")} alt="Editar" /></Link>
                    )}
                    {(ver !== undefined) && ((typeof ver) !== "string") && (
                        <img onClick={this.ver(id)} className="action-img watch" title="Ver" src={require("../../../../../assets/img/icons/view-icon.png")} alt="Editar" />
                    )}
                    {(eliminar !== undefined) && (
                        <img onClick={this.eliminar(id, row)} className="action-img delete" title="Eliminar" src={require("../../../../../assets/img/icons/delete-icon.png")} alt="Eliminar" />
                    )}
                    {(historial_clinico !== undefined) && (
                        <Link to={`${historial_clinico}/${id}`}>
                            <img
                                className='action-img'
                                src={require("../../../../../assets/img/icons/history-icon-orange.png")}
                                title='Ver historial'
                                alt='Ver historial'
                            />
                        </Link>
                    )}
                    {(eliminar_extra !== undefined) && (
                        <img onClick={this.eliminarExtra(id,row)} className="action-img delete" title="Eliminar" src={require("../../../../../assets/img/icons/delete-icon.png")} alt="Eliminar" />
                    )}
                    {(administrar !== undefined) && ((typeof administrar) === "string") && (
                        <a className="btn btn-primary" href={`/#${administrar}/${id}/admin`}>Administrar</a>
                    )}

                    {(imp_factura !== undefined) && ((row.movement_ref === null) && ((typeof imp_factura) === "string" && row.hasOwnProperty('fel_cancel_status') && (row.movement_type === 1 || row.movement_type === 'Venta'))  || (row.fel_credit_note_status === 3) ) && (
                        <Link to={`${imp_factura}/${id}/${ruta_retorno}`} ><img className="action-img print" title="Imprimir" src={require("../../../../../assets/img/icons/print-icon-white.png")} alt="Imprimir" /></Link>
                    )}
                    {(ver_factura !== undefined) && ((row.movement_ref === null) && ((typeof ver_factura) === "string" && row.hasOwnProperty('fel_cancel_status') && (row.movement_type === 1 || row.movement_type === 'Venta')) || (row.fel_credit_note_status === 3) ) && (
                        <Link to={`${ver_factura}/${id}/${ruta_retorno}`} ><img className="action-img watch" title="Ver" src={require("../../../../../assets/img/icons/view-icon.png")} alt="Ver" /></Link>
                    )}

                    {(row !== null) && (row.active === true) && (row.oppeging_clossing_box !== undefined) && (row.oppeging_clossing_box.state === 1) && (anular_factura !== undefined) && ((typeof anular_factura) === "string" && row.hasOwnProperty('fel_cancel_status') && (row.fel_cancel_status !== 3) && (row.movement_type !== 1 || row.movement_type === 'Venta') && (row.fel_credit_note_status !== 3)) && (
                        <Link to={`${anular_factura}/${id}/${ruta_retorno}`} ><img className="action-img delete" title="Anular" src={require("../../../../../assets/img/icons/cancel-icon-white.png")} alt="Anular" /></Link>
                    )}

                    {(ver_factura_cobrar !== undefined) && ((typeof ver_factura_cobrar) === "string") && (
                        <Link to={`${ver_factura_cobrar}/${id}/${ruta_retorno}`} ><img className="action-img receivable" title="Ver" src={require("../../../../../assets/img/icons/cobrar-icon-blue.png")} alt="Historial" /></Link>
                    )}
                    {(historial_cobro !== undefined) && ((typeof historial_cobro) === "string") && (
                        <Link to={`${historial_cobro}/${id}/${ruta_retorno}`} ><img className="action-img receivable" title="Ver" src={require("../../../../../assets/img/icons/history-icon-white.png")} alt="Historial" /></Link>
                    )}
                    {(ver_movimientos !== undefined) && ((typeof ver_movimientos) === "string") && (
                        <Link to={`${ver_movimientos}/${id}`} ><button className="btn-small btn-primary">Administrar</button></Link>
                    )}
                    {(editar_oc !== undefined) && row.state !== undefined && (row.state === 1) && (
                        <Link to={`${editar_oc}/${id}/edit`} ><img className="action-img edit" title="Editar" src={require("../../../../../assets/img/icons/edit-icon.png")} alt="Editar" /></Link>
                    )}

                    {(authorization_oc !== undefined) && (
                        <Link to={`${authorization_oc}/${id}/authorization`} ><img className="action-img watch" title="Autorizar" src={require("../../../../../assets/img/icons/view-icon.png")} alt="Autorización" /></Link>
                    )}

                    {(ingresar !== undefined) && row.state !== undefined && (row.state !== 1) && (
                        <Link to={`${ingresar}/${id}/entry`} ><img className="action-img edit" title="Ingresar Productos" src={require("../../../../../assets/img/icons/box-icon-white.png")} alt="Autorización" /></Link>
                    )}

                    {(ver_ingreso_producto !== undefined) && ((typeof ver_ingreso_producto) === "string") &&
                        <React.Fragment>
                        { parseFloat(row.total) === (parseFloat(row.paid) + parseFloat(row.retention_amount)) ?
                            <Link to={`${ver_ingreso_producto}/${id}/view`} ><img className="action-img watch" title="ver" src={require("../../../../../assets/img/icons/view-icon.png")} alt="Historial" /></Link>
                            :
                            <Link to={`${ver_ingreso_producto}/${id}/view`} ><img className="action-img view" title="ver" src={require("../../../../../assets/img/icons/cobrar-icon-blue.png")} alt="Historial" /></Link>
                        }
                        </React.Fragment>
                    }

                    {(adm_smbx !== undefined && typeof adm_smbx === "string") &&
                        <Link to={`${adm_smbx}/${id}/admin`} ><button className="btn btn-primary btn-sm">Administrar</button></Link>
                    }
                </div>
            </div>
        );
    }
}

Acciones.propTypes = {
};

export function activeFormatter(acciones) {
    return (cell, row) => {
        return (<Acciones id={cell} {...acciones} row={row} contenido={row} />)
    };
}

export function activeFormatter2(acciones) {
        return (<Acciones id={acciones.cell} {...acciones} row={acciones.row} contenido={acciones.row} />)
}
