const url = 'entry_movement';
import { api } from 'api';
import Swal from 'sweetalert2';
import { push } from 'react-router-redux';
import { goBack } from 'react-router-redux';
import { initialize as initializeForm } from 'redux-form';
import { createActions, handleActions } from 'redux-actions';
import moment from "moment";

const LOADER_ENTRY_ORDER = 'ENTRY_ORDER';
const SET_DATA_ENTRY_ORDER = 'SET_DATA_ENTRY_ORDER';
const SET_PAGE_ENTRY_ORDER = 'SET_PAGE_ENTRY_ORDER';
const SET_UPDATE_DATA_ENTRY_ORDER = 'SET_UPDATE_DATA_ENTRY_ORDER';
const SET_BUSCADOR_ENTRY_ORDER = 'SET_BUSCADOR_ENTRY_ORDER';
const SET_FILTRO_ENTRY_ORDER = 'SET_FILTRO_ENTRY_ORDER';
const SET_DATA_ENTRY_DETAIL = 'SET_DATA_ENTRY_DETAIL';
const SHOW_MODAL = 'SHOW_MODAL';
const ADD_PAYMENT_ENTRY_DESCRIPTION = 'ADD_PAYMENT_ENTRY_DESCRIPTION';
const ADD_PAYMENT_LIST = 'ADD_PAYMENT_LIST';

const SET_INICIAL_DATE_ENTRY_ORDER = 'SET_INICIAL_DATE_ENTRY_ORDER';
const SET_FINAL_DATE_ENTRY_ORDER = 'SET_FINAL_DATE_ENTRY_ORDER';
const SET_TOTAL_PAY = "SET_TOTAL_PAY"

const groupBy = (xs, f) => {
    if (xs)
    return xs.reduce((r, v, i, a, k = f(v)) => ((r[k] || (r[k] = [])).push(v), r), {});
    return ""
}

const listar = (page = 1, order = []) =>  (dispatch, getStore) => {
    dispatch({type: LOADER_ENTRY_ORDER, cargando: true});
    const store = getStore();
    const search = store.entry_order.buscador;
    const filtro = store.entry_order.filtro_entry_order;

    let initial_date = store.entry_order.initial_date;
    let final_date = store.entry_order.final_date;

    if (initial_date == undefined){
        initial_date = moment().date(1).format("DD/MM/YYYY");
        dispatch({type:SET_INICIAL_DATE_ENTRY_ORDER, initial_date });
    }

    if (final_date == undefined){
        final_date = moment().format("DD/MM/YYYY");
        dispatch({type:SET_FINAL_DATE_ENTRY_ORDER, final_date});
    }

    let params = {page, search, initial_date, final_date};
    if(order.length > 0) params.order = order

    if(filtro){
        params[''] = filtro
    }

    api.get(`${url}/listar`, params).catch((error) => {
        dispatch({type: LOADER_ENTRY_ORDER, cargando: false});
        Swal(
            'Error',
            error.detail || 'Ha ocurrido un error, por favor vuelva a intentar.',
            'error'
        );
    }).then((data) => {
        if(data){
            console.log("Data de cuentas por pagar: ", data);
            dispatch({type: SET_DATA_ENTRY_ORDER, data});
            dispatch({type: SET_PAGE_ENTRY_ORDER, page});

            api.get(`${url}/listarTotal`, params).catch((error) => {
                Swal(
                    'Error',
                    error.detail || 'Ha ocurrido un error, por favor vuelva a intentar.',
                    'error'
                );
            }).then((response) => {
                if(response){
                    console.log("total response: ", response.total);
                    dispatch({type:SET_TOTAL_PAY, total_pay: response.total});
                }
            }).finally(() => {
                dispatch({type: LOADER_ENTRY_ORDER, cargando: false});
            })
        } else {
            dispatch({type: LOADER_ENTRY_ORDER, cargando: false});
        }
    })
};

const search = (search) => (dispatch) => {
    dispatch({type: SET_BUSCADOR_ENTRY_ORDER, buscador: search});
    dispatch(listar(1));
};

const filtro = (filtro) => (dispatch, getStore) => {
    if (filtro == 0){
        filtro = null
    }
    dispatch({type: SET_FILTRO_ENTRY_ORDER, filtro_entry_order: filtro});
    dispatch(listar(1));
}

const viewOneEntry = (entry_movement_id) => (dispatch, getStore) => {
    console.log("obtener entrada con id: ", entry_movement_id);

    api.get(`${url}/${entry_movement_id}`).then((data) => {
        console.log("Entry detail: ", data);
        dispatch({type: SET_DATA_ENTRY_DETAIL, entry_detail: data});
    }).catch((error) => {
        Swal(
            'Error',
             error.detail || 'Ha ocurrido un error, por favor vuelva a intentar.',
            'error'
        );
    });
}

const addEntryPayment = () => (dispatch, getStore) => {
    let paymentForm = getStore().form.form_payment;
    let total_payment = 0;
    let url_retorno = "";
    let entry_movement_id = 0;
    const store = getStore().entry_order;
    const _total = parseFloat(parseFloat(store.entry_detail.total).toFixed(2));
    const pagado = parseFloat(parseFloat(store.entry_detail.paid).toFixed(2));
    const retencion = parseFloat(parseFloat(store.entry_detail.retention_amount).toFixed(2)); // add...
    const total = parseFloat(_total - pagado - retencion).toFixed(2); // mod...

    console.log("Sólo total: ", total);
    console.log("Sólo pagado: ", pagado);
    console.log("Sólo retención: ", retencion);
    console.log("DATA FORM: ", paymentForm);

    if(paymentForm !== undefined && paymentForm !== null){
        paymentForm = paymentForm.values;
        url_retorno = paymentForm.url_retorno;
        entry_movement_id = paymentForm.id_purchase;
        paymentForm.entry_movement_id = entry_movement_id;

        if(paymentForm.cash !== undefined){
            total_payment += parseFloat(paymentForm.cash);
            paymentForm.small_box = paymentForm.small_box.value;
        }
        if(paymentForm.card !== undefined){
            total_payment += parseFloat(paymentForm.card);
        }
        if(paymentForm.due !== undefined){
            total_payment += parseFloat(paymentForm.due);
        }
        if(paymentForm.transaction !== undefined){
            total_payment += parseFloat(paymentForm.transaction);
            paymentForm.bank_account = paymentForm.bank_account.value
        }

        paymentForm.total_payment = total_payment;
    }else{
        paymentForm = null;
    }

    console.log("TOTAL: ", total, " TOTAL PAYMENT: ", total_payment);

    if (total_payment > total){
        Swal.fire(
            'El total de pago excede la cantidad a pagar',
            'Corrija',
            'question'
        )
    }else{
        const data = {
            total,
            payment: paymentForm,
        }

        console.log("data a enviar: ", data);

        api.post(`${url}/addEntryPayment`, data).then((data) => {
            Swal('Éxito', 'Pago registrado correctamente.', 'success')
            .then(() => {
                dispatch(initializeForm('form_payment', {}));
                dispatch({type:SHOW_MODAL, modal_payment_show: false});
                dispatch(chargeEntryPayments(entry_movement_id));
                dispatch(push(`/view-entry-order/${entry_movement_id}/payment-history`));
            })
        }).catch((error) => {
            Swal(
                'Error',
                    error ? error.detail : 'Ha ocurrido un error, por favor vuelva a intentar.',
                'error'
            );
        }).finally(() => {

        });
    }
}

export const chargeEntryPayments = (id_entry_movement) => (dispatch) => {
    api.get(`${url}/getEntryPayment`, {id_entry_movement}).catch((error) => {
        console.error("Error: ", error);
    }).then((data) => {
        console.log("Datos de pago: ", data);
        dispatch({type: ADD_PAYMENT_LIST, payments: data });
        const result = groupBy(data, (a) => a.payment_type);
        const payment = {id_entry_movement, result};
        console.log("Data ordenada: ", payment);
        dispatch({type: ADD_PAYMENT_ENTRY_DESCRIPTION, payment });
    })
}

export const cancelEntryPayment = (id_payment, ruta_retorno) => (dispatch) => {
    Swal.fire({
        title: '¿Está seguro(a) que desea anular el pago?',
        type: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#aaa',
        confirmButtonText: 'Aceptar',
        cancelButtonText: 'Cancelar',
        reverseButtons: true
    }).then((result) => {
        if (result.value) {
            api.post(`${url}/cancelEntryPayment`, {id_payment}).then((data) => {
                Swal('Éxito', data.detail , 'success')
                .then(() => {
                    dispatch(push(ruta_retorno));
                })
            }).catch((error) => {
                Swal(
                    'Error',
                     error.detail || 'Ha ocurrido un error, por favor vuelva a intentar.',
                    'error'
                );
            })
        }
    });
}

export const cancelEntry = (id_entry_movement, ruta_retorno) => (dispatch) => {
    Swal.fire({
        title: '¿Está seguro(a) que desea anular la cuenta por pagar?',
        type: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#aaa',
        confirmButtonText: 'Aceptar',
        cancelButtonText: 'Cancelar',
        reverseButtons: true
    }).then((result) => {
        if (result.value) {
            api.post(`${url}/cancelEntry`, {id_entry_movement}).then((data) => {
                Swal('Éxito', data.detail , 'success')
                .then(() => {
                    dispatch(push('/to-pay'));
                })
            }).catch((error) => {
                Swal(
                    'Error',
                     error.detail || 'Ha ocurrido un error, por favor vuelva a intentar.',
                    'error'
                );
            })
        }
    });
}

export const PayGroupEntry = () => async (dispatch, getStore) => {
    console.log("pagando el grupo de facturas");
    dispatch({type: LOADER_ENTRY_ORDER, cargando: true})
    let formulario = getStore().form.paymentGroup.values;

    let ids=[];
    formulario.cuentas.forEach(item => {
        ids.push(item.id);
    })

    formulario.cuentas = ids;
    console.log("Formulario: ", formulario);

    let respuesta = true

    await api.post(`${url}/registerGroupPayment`, formulario).then((data) => {
        Swal('Éxito', data.detail , 'success')
        .then(() => {
            dispatch(listar());
        })
    }).catch((error) => {
        respuesta=false
        Swal(
            'Error',
            error ? error.detail : 'Ha ocurrido un error, por favor vuelva a intentar.',
            'error'
        );
    }).finally(() => {
        dispatch({type: LOADER_ENTRY_ORDER, cargando: false})
    })
    return respuesta
}

const setFinalDate = (value) => (dispatch) => {
    dispatch({type: SET_FINAL_DATE_ENTRY_ORDER, final_date: value});
    dispatch(listar());
}

const setInitialDate = (value) => (dispatch) => {
    dispatch({type: SET_INICIAL_DATE_ENTRY_ORDER, initial_date: value});
    dispatch(listar());
}

export const actions = {
    listar,
    search,
    filtro,
    viewOneEntry,
    addEntryPayment,
    chargeEntryPayments,
    cancelEntryPayment,
    cancelEntry,
    PayGroupEntry,

    setFinalDate,
    setInitialDate,
};

export const reducer = {
    [ADD_PAYMENT_LIST]: (state, { payments }) => {
        return{
            ...state,
            payments: payments
        }
    },
    [ADD_PAYMENT_ENTRY_DESCRIPTION]: (state, { payment }) => {
        return{
            ...state,
            data_payments: payment
        }
    },
    [SHOW_MODAL] : (state, modal_payment_show) => {
        return {
            ...state,
            modal_payment_show
        }
    },
    [SET_DATA_ENTRY_DETAIL]: (state, {entry_detail}) => {
        return { ...state, entry_detail}
    },
    [LOADER_ENTRY_ORDER]: (state, { cargando }) => {
        return {...state, cargando }
    },
    [SET_DATA_ENTRY_ORDER]: (state, { data }) => {
        return {...state, data }
    },
    [SET_PAGE_ENTRY_ORDER]: (state, { page }) => {
        return {...state, page }
    },
    [SET_UPDATE_DATA_ENTRY_ORDER]: (state, { updateData }) => {
        return {...state, updateData }
    },
    [SET_BUSCADOR_ENTRY_ORDER]: (state, { buscador }) => {
        return {...state, buscador }
    },
    [SET_FILTRO_ENTRY_ORDER]: (state, { filtro_rol }) => {
        return {...state, filtro_rol }
    },

    [SET_INICIAL_DATE_ENTRY_ORDER]: (state, { initial_date }) => {
        return {...state, initial_date }
    },
    [SET_FINAL_DATE_ENTRY_ORDER]: (state, { final_date }) => {
        return {...state, final_date }
    },
    [SET_TOTAL_PAY]: (state, { total_pay }) => {
        return {...state, total_pay }
    },
};

export const initialState = {
    cargando: false,
    page: 1,

    /*
    data: {
        count: 0,
        next: null,
        previous: null,
        results: [],
    },
    */
    data: [],
    initial_date: moment().date(1).format("DD/MM/YYYY"),
    final_date: moment().format("DD/MM/YYYY"),

    buscador: '',
    filtro_entry_order: null,
    updateData: {},
    entry_detail: null,
    modal_payment_show: false,
    data_payments: null,
    payments: [],
    total_pay: 0,
};

export default handleActions(reducer, initialState)
