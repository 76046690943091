import { connect } from 'react-redux';
import { actions } from '../../../redux/modules/subsidiary';
import Subsidiary from './Subsidiary';
import {resetStore} from "../../../redux/modules/login";


const ms2p = (state) => {
    return {
        ...state.subsidiary,
    };
};

const md2p = { ...actions, resetStore };

export default connect(ms2p, md2p)(Subsidiary);
