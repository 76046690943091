/**
 * Función que se utiliza para motrar datos anidados en la data recuperada (para la Grid de Bootstrap)
 * @param {*} data
 * @param {*} row
 * @param {*} field
 */
export const showNestedFields = (data, row, field) => {
    let nested  = field.split('__');
    let dato = row;
    for(var x=0;x<nested.length;x++){
         dato = (dato.hasOwnProperty(nested[x]) && dato[nested[x]] != null) ? dato[nested[x]] : null
         //console.log("Dato en iteracion: ", dato);
         if(dato == null)
             break;
    }

    return (dato == null) ? "Sin asignar" : dato;
}
