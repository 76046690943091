
import React, {Component} from 'react';

class Footer extends Component {
    render(){
        return(
            <div className='footer-transcription'>
                <div className='row mt-4 d-flex flex-row justify-content-start mb-5'>
                    <div className='transcription-footer-cuadrito'>

                    </div>
                    <div className='d-flex flex-row flex-fill'>
                        <div className='d-flex flex-column align-items-start w-100'>
                            <div className='d-flex flex-row'>
                                <div className="col-8">
                                    <p className="parrafo-descripcion">
                                        5a. Calle 14-35 zona 3, Quetzaltenango
                                    </p>
                                    <p className="parrafo-descripcion">
                                        Edificio Las Tapias, oficina 107
                                    </p>
                                    <p className="parrafo-descripcion">
                                        Tels. 7736-8712 y 77676590
                                    </p>
                                </div>
                                <div className="col-4">
                                    <p className="parrafo-descripcion">
                                        www.prolaserguatemala.com.gt
                                    </p>
                                    <p className="parrafo-descripcion">
                                        prolaserguatemala@gmail.com
                                    </p>
                                    <p className="parrafo-descripcion">
                                        whatsapp: 4220-3735
                                    </p>
                                </div>
                            </div>
                            <div className='transcription-footer-division-line w-100'>

                            </div>
                            <div className='transcription-footer-line-shadow'>

                            </div>
                            <img
                                className="action-img transcription-footer-puntero"
                                src={require("../../../../../assets/img/icons/puntero-icon.png")}
                                alt="Ver"
                            />
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Footer;
