const url = 'dues';
import { api } from 'api';
import Swal from 'sweetalert2';
import { push } from 'react-router-redux';
import { initialize as initializeForm } from 'redux-form';
import { handleActions } from 'redux-actions';

const LOADER_DUES = 'LOADER_DUES';
const SET_DATA_DUES = 'SET_DATA_DUES';
const SET_PAGE_DUES = 'SET_PAGE_DUES';
const SET_UPDATE_DATA_DUES = 'SET_UPDATE_DATA_DUES';
const SET_BUSCADOR_DUES = 'SET_BUSCADOR_DUES';
const SET_FILTRO_DUES = 'SET_FILTRO_DUES';

const SET_REMOVE_ROW = 'SET_REMOVE_ROW';
const REMOVE_ROW = 'REMOVE_ROW';
const SET_REMOVE = 'SET_REMOVE';
const ADD_NEW_ROW = 'ADD_NEW_ROW';

const listar = (page = 1) =>  (dispatch, getStore) => {
    dispatch({type: LOADER_DUES, cargando: true});
    const store = getStore();
    const search = store.due.buscador;
    const filtro = store.due.filtro_dues;
    let params = {page, search};
    if(filtro){
        params[''] = filtro
    }

    api.get(`${url}`, params).catch((error) => {
        dispatch({type: LOADER_DUES, cargando: false});
        Swal(
            'Error',
            error.detail || 'Ha ocurrido un error, por favor vuelva a intentar.',
            'error'
        );
    }).then((data) => {
        const search2 = getStore().due.buscador;
        if(search == search2){
            if(data){
                console.log("data: ", data);
                dispatch({type: SET_DATA_DUES, data});
                dispatch({type: SET_PAGE_DUES, page});
                dispatch({type:SET_REMOVE});
            }
            dispatch({type: LOADER_DUES, cargando: false});
        }
    })
};

const save = () => (dispatch, getStore) => {
    const formData = _.cloneDeep(getStore().form.dues.values);

    formData.pos = formData.pos.value;
    console.log("due: ", formData)
    dispatch({type: LOADER_DUES, cargando: true})

    api.post(`${url}`, formData).then((data) => {
        dispatch({type: LOADER_DUES, cargando: false})
        Swal('Éxito', 'Cuota registrada correctamente.', 'success')
        .then(() => {
            dispatch({type:SET_REMOVE});
            dispatch(push('/dues'))
        })
    }).catch((error) => {
        dispatch({type: LOADER_DUES, cargando: false})
        Swal(
            'Error',
             error.detail || 'Ha ocurrido un error, por favor vuelva a intentar.',
            'error'
        );
    }).finally(() => {
        dispatch({type: LOADER_DUES, cargando: false})
    });
};

const detail = id => (dispatch, getState) =>{
    dispatch({type: LOADER_DUES, cargando: true});
    api.get(`${url}/${id}`).catch((error) => {
        dispatch({type: LOADER_DUES, cargando: false});
        if(error.statusCode === 404){
            dispatch(push('/dues'))
        }
    }).then((data) => {
        dispatch({type: LOADER_DUES, cargando: false});
        if(data){
            console.log("DATA DE EL DUE: ", data)
            data.pos = {'value': data.pos.id, 'label': data.pos.name}
            console.log("DATA DE EL DUE: ", data)
            dispatch({type: SET_UPDATE_DATA_DUES, updateData: data});
        }
    })
}

const update = () => (dispatch, getStore) => {
    const formData = _.cloneDeep(getStore().form.dues.values);

    formData.pos = formData.pos.value;
    console.log("due: ", formData)
    dispatch({type: LOADER_DUES, cargando: true})


    api.put(`${url}/${formData.principal_id}`, formData).then((data) => {
        dispatch({type: LOADER_DUES, cargando: false})
        Swal('Éxito', 'Cuota actualizada correctamente.', 'success')
        .then(() => {
            dispatch(push('/dues'));
        })
    }).catch((error) => {
        dispatch({type: LOADER_DUES, cargando: false})
        Swal(
            'Error',
             error.detail || 'Ha ocurrido un error, por favor vuelva a intentar.',
            'error'
        );
    }).finally(() => {
        dispatch({type: LOADER_DUES, cargando: false})
    });
}

const destroy = (id) => (dispatch, getStore) => {
    dispatch({type: LOADER_DUES, cargando: true});
    api.eliminar(`${url}/${id}`).catch((error) => {
        dispatch({type: LOADER_DUES, cargando: false});
        Swal('Error', 'No se ha logrado borrar el registro.', 'error')
            .then(() => {
                dispatch(listar(1));
            })
    }).then((data) => {
        dispatch({type: LOADER_DUES, cargando: false});
        Swal('Éxito', 'Se ha eliminado correctamente.', 'success')
            .then(() => {
                dispatch(listar(1));
            })
    })
}

const removeRow = (row) => (dispatch, getStore) => {
    /*
    console.log("Form data al eliminar: ", getStore().form.dues.values);
    const rows = getStore().form.dues.values.due_detail;
    rows.forEach(dato => {
        //if(typeof dato !== 'undefined' && !dato.hasOwnProperty('id')){
        if(dato !== 'undefined' && typeof dato !== 'undefined' ){
            console.log("row: ", dato);
            dispatch({type: ADD_NEW_ROW, dato});
        }
    });
    */

    dispatch({type: SET_REMOVE_ROW, row});
    const id = row.id;
    dispatch({type: REMOVE_ROW, id});
}

const search = (search) => (dispatch) => {
    dispatch({type: SET_BUSCADOR_DUES, buscador: search});
    dispatch(listar(1));
};

const filtro = (filtro) => (dispatch, getStore) => {
    if (filtro == 0){
        filtro = null
    }
    dispatch({type: SET_FILTRO_DUES, filtro_rol: filtro});
    dispatch(listar(1));
};

export const actions = {
    listar,
    save,
    update,
    detail,
    destroy,
    search,
    filtro,
    removeRow,
};

export const reducer = {
    [LOADER_DUES]: (state, { cargando }) => {
        return {...state, cargando }
    },
    [SET_DATA_DUES]: (state, { data }) => {
        return {...state, data }
    },
    [SET_PAGE_DUES]: (state, { page }) => {
        return {...state, page }
    },
    [SET_UPDATE_DATA_DUES]: (state, { updateData }) => {
        return {
                ...state,
                principal_id: updateData.id,
                name: updateData.name,
                pos: updateData.pos,
                due_detail: updateData.due_detail,
            }
    },
    [SET_BUSCADOR_DUES]: (state, { buscador }) => {
        return {...state, buscador }
    },
    [SET_FILTRO_DUES]: (state, { filtro_rol }) => {
        return {...state, filtro_rol }
    },
    [REMOVE_ROW]: (state, { id }) => {
        const arreglo = state.due_detail.filter((item) => {
            return item.id !== id;
        });

        return{
            ...state,
            due_detail: arreglo
        }
    },
    [SET_REMOVE_ROW]: (state, { row }) => {
        return{
            ...state,
            due_detail_remove: [...state.due_detail_remove, row]
        }
    },
    [SET_REMOVE]: (state) => {
        return{
            ...state,
            due_detail_remove: []
        }
    },
    [ADD_NEW_ROW]: (state, { dato }) => {
        return{
            ...state,
            due_detail: [...state.due_detail.concat(dato)]
        }
    },
};

export const initialState = {
    cargando: false,
    page: 1,
    data: {
        count: 0,
        next: null,
        previous: null,
        results: [],
    },
    principal_id: 0,
    due_detail: [],
    due_detail_remove: [],
    buscador: '',
    filtro_dues: null,
    updateData: {},
};

export default handleActions(reducer, initialState)
