import { connect } from 'react-redux';
import PosCreate from './PosCreate';
import { actions } from '../../../redux/modules/pos';
import {resetStore} from "../../../redux/modules/login";


const ms2p = (state) => {
    return {
        ...state.pos,
    };
};

const md2p = { ...actions, resetStore };

export default connect(ms2p, md2p)(PosCreate);
