import Table from '../Utils/Grid';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { BreakLine } from '../Utils/tableOptions';
import HeaderSimple from '../Utils/Header/HeaderSimple';
import ToolbarSimple from '../Utils/Toolbar/ToolbarSimple';
import { activeFormatter } from '../Utils/Acciones/Acciones';
import DayPickerInput from 'react-day-picker/DayPickerInput';
import { Link } from 'react-router-dom';
import moment from "moment";
import Select from 'react-select';
import MomentLocaleUtils, {
    formatDate,
    parseDate,
} from 'react-day-picker/moment';
import Form from './TransactionForm';
import numeral from 'numeral';
import MyModal from '../Utils/myModal';
import { initialState } from '../../../redux/modules/referent';
import { INCOME, EXPENDITURY } from "../../../redux/modules/banks";
import { Modal, ModalBody, ModalHeader, ModalFooter } from 'reactstrap';

function formatSubsidairy(fact){
    if (fact == INCOME){
        return 'INGRESO'
    } else {
        return 'EGRESO'
    }
}

function formatDebe(cell, row){
    if (row.debe > 0) {
        return 'Q. ' + numeral(row.debe).format('0,0.00')
    } else {
        return ''
    }
}

function formatHaber(cell, row){
    if (row.haber > 0) {
        return 'Q. ' + numeral(row.haber).format('0,0.00')
    } else {
        return ''
    }
}

function formatSaldo(cell, row){
    return 'Q. ' + numeral(row.saldo).format('0,0.00')
}

class BankTransactions extends Component {

    componentWillMount() {
        if(this.props.match.params.id){
            this.props.detail(this.props.match.params.id);
            this.setState({
                editar: true,
                mensaje: 'Editar cuenta bancaria'
            });
        }
    }

    state = {
        income: false,
        expenditury: false,
        isOpen: false,
        pendingTransaction: 'Ingreso',
    }

    expandComponent(row) {
        return (
            <React.Fragment>
                <div className="d-flex justify-content-start mr-md-5 pr-md-5 py-md-2 expanded-prices-rows expanded-rows-title">
                    <div className="d-flex justify-content-start w-100 px-5">
                        Motivo:
                    </div>
                </div>
                <div className="d-flex justify-content-start mr-md-5 pr-md-5 py-md-2 expanded-prices-rows expanded-rows-title">
                    <p className="d-flex justify-content-start px-5 paragraph-string">
                        {row.transanction_justification}
                    </p>
                </div>
            </React.Fragment>
        );
    }

    toggleModal = () => {
        this.setState((prevState) => ({ isOpen: !prevState.isOpen }));
    }

    pendigForm = (cell, row) => {
        return (
            <img
                onClick={() => {
                    this.props.detail_transaction(cell).then(() => {
                        this.toggleModal(cell);
                        if (row.transaction_type == INCOME){
                            this.setState({
                                pendingTransaction: 'Ingreso'
                            })
                        } else if (row.transaction_type == EXPENDITURY) {
                            this.setState({
                                pendingTransaction: 'Egreso'
                            })
                        }
                    })
                }}
                className="action-img set-account" title="Execute" src={require("../../../../assets/img/icons/set-account.png")} alt="Execute"
            />
        )
    }

    render() {
        const {
            listar,
            listar_transactions,
            detail,
            detail_transaction,
            create_transaction,
            update_transaction,
            destroy,
            set_month,
            set_year
        } = this.props;

        const {
            cargando,
            page,
            transactions_data,
            transactions_page,
            month,
            year,
            updateData,
            generarExcelMovBancarios
        } = this.props;
        return (
            <div className="content">
                {/* <div className="d-inline-flex w-100">
                    <div className="row-fluid w-50 tab-background-blue">
                        <div className="d-flex justify-content-center align-items-center tab-active tab-br-tr">
                            LISTADO
                        </div>
                    </div>
                    <div className="row-fluid w-50 tab-background">
                        <a href="/#/pending" className="d-flex justify-content-center align-items-center tab tab-br-bl">
                            PENDIENTES
                        </a>
                    </div>
                </div> */}

                <div className="d-flex flex-inline align-items-center">
                    <Link to="/banks">
                        <div className="d-flex my-4 align-items-center ml-3 mr-3 back-blue-arrow"/>
                    </Link>
                    {/* <button className="btn btn-primary my-2 mx-2" onClick={ () => { this.setState({ income: true })}} >Realizar ingreso</button> */}
                    <MyModal isOpen={false}
                        title="Nuevo ingreso"
                        formId="bank_transaction_form"
                        textButton="Realizar ingreso"
                        classToggleButton="btn btn-primary my-2 mx-2"
                        size="lg"
                        body={
                            <Form onSubmit={create_transaction} initialValues={{bank_account: this.props.updateData.id, transaction_type: INCOME, date: moment(new Date()).format("DD-MM-YYYY"), hour: moment(new Date())}}></Form>
                        }
                    />
                    <MyModal isOpen={false}
                        title="Nuevo egreso"
                        formId="bank_transaction_form"
                        textButton="Realizar egreso"
                        classToggleButton="btn btn-primary my-2 mx-2"
                        size="lg"
                        body={
                            <Form onSubmit={create_transaction} initialValues={{bank_account: this.props.updateData.id, transaction_type: EXPENDITURY, date: moment(new Date()).format("DD-MM-YYYY"), hour: moment(new Date())}}></Form>
                        }
                    />
                    <button
                        className='btn btn-success btn-sm'
                        onClick={() => generarExcelMovBancarios()}
                    >Generar Excel</button>
                </div>
                <div className="pb-4 row-fluid w-100">
                    <div className="table-decoration">
                        <div className="top-title-banks d-flex justify-content-between align-items-center">
                            <div>CUENTA:&nbsp;&nbsp;&nbsp;{updateData.owner}&nbsp;&nbsp;&nbsp;No.:&nbsp;&nbsp;&nbsp;{updateData.account_number}</div>
                            <div className="mr-4 d-flex flex-inline align-items-center">
                                <div className="ml-4">
                                    <div className="d-flex justify-content-between">
                                        <div>
                                            Debe:
                                        </div>
                                        <div className="ml-2">
                                            Q. {numeral(transactions_data.debe).format('0,0.00')}
                                        </div>
                                    </div>
                                    <div className="d-flex justify-content-between va-color">
                                        <div>
                                            Haber:
                                        </div>
                                        <div className="ml-2">
                                            Q. {numeral(transactions_data.haber).format('0,0.00')}
                                        </div>
                                    </div>
                                </div>
                                <div className="mx-2 d-flex flex-inline align-items-center">
                                    <div className="mx-2">
                                        MES:
                                    </div>
                                    <Select
                                        value={month}
                                        className="form-control form-month p-0 border-trasparent"
                                        classNamePrefix="react-select"
                                        onChange={(value)=>{set_month(value)}}
                                        theme={(theme) => {
                                            return {
                                                ...theme,
                                                borderRadius: '1em',
                                                background: 'white',
                                                colors: {
                                                    ...theme.colors,
                                                    text: '#415362',
                                                    primary25: '#ebebeb',
                                                primary: '#0a5995',
                                                },
                                            }
                                        }}
                                        options={[
                                            {value:'01', label:'Enero'},
                                            {value:'02', label:'Febrero'},
                                            {value:'03', label:'Marzo'},
                                            {value:'04', label:'Abril'},
                                            {value:'05', label:'Mayo'},
                                            {value:'06', label:'Junio'},
                                            {value:'07', label:'Julio'},
                                            {value:'08', label:'Agosto'},
                                            {value:'09', label:'Septiembre'},
                                            {value:'10', label:'Octubre'},
                                            {value:'11', label:'Noviembre'},
                                            {value:'12', label:'Diciembre'},
                                        ]}
                                        autoFocus
                                    />
                                </div>
                                <div className="d-flex flex-inline align-items-center">
                                    <div className="mx-2">
                                        AÑO:
                                    </div>
                                    <Select
                                        value={year}
                                        className="form-control form-year p-0 border-trasparent"
                                        classNamePrefix="react-select"
                                        onChange={(value)=>{set_year(value)}}
                                        theme={(theme) => {
                                            return {
                                                ...theme,
                                                borderRadius: '1em',
                                                background: 'white',
                                                colors: {
                                                    ...theme.colors,
                                                    text: '#415362',
                                                    primary25: '#ebebeb',
                                                primary: '#0a5995',
                                                },
                                            }
                                        }}
                                        options={[
                                            {value:'2010', label:'2010'},
                                            {value:'2011', label:'2011'},
                                            {value:'2012', label:'2012'},
                                            {value:'2013', label:'2013'},
                                            {value:'2014', label:'2014'},
                                            {value:'2015', label:'2015'},
                                            {value:'2016', label:'2016'},
                                            {value:'2017', label:'2017'},
                                            {value:'2018', label:'2018'},
                                            {value:'2019', label:'2019'},
                                            {value:'2020', label:'2020'},
                                            {value:'2021', label:'2021'},
                                            {value:'2022', label:'2022'},
                                            {value:'2023', label:'2023'},
                                            {value:'2024', label:'2024'},
                                            {value:'2025', label:'2025'},
                                            {value:'2026', label:'2026'},
                                            {value:'2027', label:'2027'},
                                            {value:'2028', label:'2028'},
                                            {value:'2029', label:'2029'},
                                            {value:'2030', label:'2030'},
                                            {value:'2031', label:'2031'},
                                            {value:'2032', label:'2032'},
                                            {value:'2033', label:'2033'},
                                            {value:'2034', label:'2034'},
                                        ]}
                                    />
                                </div>
                                <div className="ml-4">
                                    <div className="d-flex justify-content-between">
                                        <div>
                                            Viene:
                                        </div>
                                        <div className="ml-2">
                                            Q. {numeral(transactions_data.viene).format('0,0.00')}
                                        </div>
                                    </div>
                                    <div className="d-flex justify-content-between va-color">
                                        <div>
                                            Va:
                                        </div>
                                        <div className="ml-2">
                                            Q. {numeral(transactions_data.va).format('0,0.00')}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <Table
                            onPageChange={(page) => listar_transactions(page, updateData.id)}
                            data={transactions_data}
                            loading={cargando}
                            pagination={false}
                            page={transactions_page}
                            expandableRow={ () => (true) }
                            expandComponent={ this.expandComponent }
                        >
                            <TableHeaderColumn
                                tdStyle={BreakLine}
                                thStyle={BreakLine}
                                dataField="date" dataSort>Fecha</TableHeaderColumn>
                            <TableHeaderColumn
                                tdStyle={BreakLine}
                                thStyle={BreakLine}
                                dataField="provider" dataSort>Proveedor</TableHeaderColumn>
                            <TableHeaderColumn
                                tdStyle={BreakLine}
                                thStyle={BreakLine}
                                dataField="no_document" dataSort>Documento</TableHeaderColumn>
                            <TableHeaderColumn
                                tdStyle={BreakLine}
                                thStyle={BreakLine}
                                dataFormat= {formatSubsidairy}
                                dataField="transaction_type" dataSort>Movimiento</TableHeaderColumn>
                            <TableHeaderColumn
                                tdStyle={BreakLine}
                                thStyle={BreakLine}
                                dataFormat={formatHaber}
                                headerAlign={'center'}
                                dataAlign={'right'}
                                dataField="haber" dataSort>Debe</TableHeaderColumn>
                            <TableHeaderColumn
                                tdStyle={BreakLine}
                                thStyle={BreakLine}
                                dataFormat={formatDebe}
                                headerAlign={'center'}
                                dataAlign={'right'}
                                dataField="debe" dataSort>Haber</TableHeaderColumn>
                            <TableHeaderColumn
                                tdStyle={BreakLine}
                                thStyle={BreakLine}
                                headerAlign={'center'}
                                dataFormat= {formatSaldo}
                                dataAlign={'right'}
                                dataField="saldo" dataSort>Saldo</TableHeaderColumn>
                            <TableHeaderColumn
                                dataField="id"
                                isKey={true}
                                dataAlign="center"
                                dataFormat={this.pendigForm}>Acciones</TableHeaderColumn>
                        </Table>
                        <Modal isOpen={this.state.isOpen} size={'lg'}>
                            <ModalHeader>{this.state.pendingTransaction}</ModalHeader>
                                <ModalBody>
                                    <Form onSubmit={update_transaction}></Form>
                                </ModalBody>
                            <ModalFooter className="d-flex justify-content-center text-center">
                                <button className="btn btn-secondary btn-modal" onClick={this.toggleModal}>Cancelar</button>
                                <button type="submit" onClick={() => {this.toggleModal()}} className="btn btn-primary btn-modal" form="bank_transaction_form">Aceptar</button>
                            </ModalFooter>
                        </Modal>
                    </div>
                </div>
            </div>
        )
    }
}

export default BankTransactions;
