import React, { Component } from 'react';
import { RenderCurrency } from 'Utils/renderField/renderReadField';
import { renderCurrency } from 'Utils/renderField/renderField';
import { Field, FieldArray, reduxForm} from 'redux-form';
import { validate, validators } from 'validate-redux-form';
import MyModal from '../../Utils/myModal';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import BillingForm from './FormBillingInformation';
import NumberFormat from 'react-number-format';

import Form from './CardsForm';
import DueForm from './Duesform';

import './sale.css';
export const isEmpty = value => value === undefined || value === null || value === '';

class PaymentSaleForm extends Component {
    state = {
        view : 'payment',
    };

    verifyCash = (value) => {
        const cantidad = !isEmpty(value) ? value : 0;
        const total = this.props.card_payments_total + this.props.due_payments_total + parseFloat(cantidad);

        if(total > this.props.totalInCar){
            return "La cantidad excede el total de la venta";
        }else{
            this.props.setDataPayment();
            return null;
        }
    }

    componentDidMount = () => {
        this.props.getCards(); /* To get cards in field card_list of state */
        this.props.getDues();
    }

    cardPayments = () => {
        this.props.cardsPayment();
    }

    duePayments = () => {
        this.props.duesPayment();
    }

    setDataPayment = (e) => {
        e.preventDefault();
        this.setState({ view: 'confirm'});
        this.props.setDataPayment();
    }

    setEfectivo = () => {
        this.props.setDataPayment();
    }

    setCashPayment = (cantidad) => {
        this.props.setCashPayment(cantidad);
    }

    changeView = (view) => {
        this.setState({ view });
    }

    render(){

        console.log("PROPS in services: ", this.props);

        return(
            <React.Fragment>
                {this.state.view === 'payment' ?
                <div className="table-responsive">
                    {/* <form onSubmit={(e) => this.setDataPayment(e)}> */}
                        <table className="table table-sm" id="table-payment">
                            <thead>
                                <tr>
                                    <th></th>
                                    <th></th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <th scope="row">TOTAL A PAGAR</th>
                                    <td><RenderCurrency value={this.props.totalInCar} /></td>
                                    <td>falta: <RenderCurrency value={(this.props.total_dif <= 0) ? 0 : this.props.total_dif} /></td>
                                </tr>
                                <tr>
                                    <th  scope="row">EFECTIVO</th>
                                    <td>
                                        <NumberFormat className="form-control w-75"
                                            decimalScale={2} fixedDecimalScale={true}
                                            thousandSeparator={true} prefix="Q "
                                            onValueChange={(values) => {
                                                const {value} = values;
                                                const cash = value === '' ? 0 : parseFloat(value);
                                                this.setCashPayment(cash);
                                            }}
                                        />
                                    </td>
                                    <td></td>
                                </tr>
                                <tr>
                                    <th scope="row">TARJETA</th>
                                    <td >
                                        <RenderCurrency value={this.props.card_payments_total} />
                                    </td>
                                    <td >
                                    <MyModal
                                        isOpen={false}
                                        title="Pagos con tarjeta"
                                        formId="selectCard"
                                        textButton="Pagar"
                                        classToggleButton="btn btn-sm btn-primary"
                                        //aceptar={this.cardPayments}
                                        body={
                                            <React.Fragment>
                                                <Form onSubmit={this.cardPayments} listCards={this.props.card_list} />
                                            </React.Fragment>
                                        }
                                    />
                                    </td>
                                </tr>
                                <tr>
                                    <th scope="row">CUOTAS</th>
                                    <td>
                                        <RenderCurrency value={this.props.due_payments_total} />
                                    </td>
                                    <td>
                                        <MyModal
                                            isOpen={false}
                                            title="Pagos con cuota"
                                            formId="selectDue"
                                            textButton="Pagar"
                                            classToggleButton="btn btn-sm btn-primary"
                                            body={
                                                <React.Fragment>
                                                    <DueForm onSubmit={this.duePayments} listDues={this.props.dues_list} />
                                                </React.Fragment>
                                            }
                                        />
                                    </td>
                                </tr>
                                <tr>
                                    <td colSpan="3">
                                        <div className="d-flex justify-content-center flex-column flex-sm-row align-items-stretch align-items-sm-center">
                                            <Link className="btn btn-primary m-2" to="/oppening-closing/sales/">Cancelar</Link>
                                            {this.props.total_dif === 0 ?
                                                <MyModal isOpen={false}
                                                    size="lg"
                                                    title="Confirmar Datos de Facturación"
                                                    formId="billing_information"
                                                    textButton="Finalizar Venta"
                                                    classToggleButton="btn btn-sm btn-secondary"
                                                    body={
                                                        <BillingForm onSubmit={this.props.finishSale} validateNit={this.props.validateNit}/>
                                                    }
                                                />
                                                :
                                                <button type="submit" className="btn btn-secondary m-2" disabled>Finalizar Venta la</button>
                                            }
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    {/*</form>*/}
                </div>

                :
                    <div className="d-flex" id="content-confirm">
                        <BillingForm changeView={this.changeView} onSubmit={this.props.finishSale} validateNit={this.props.validateNit}/>
                    </div>
                }
            </React.Fragment>
        );
    }
}

PaymentSaleForm = reduxForm({
    form: 'PaymentSaleForm',
    validate: data => {
         return validate(data, {

        })
    }
})(PaymentSaleForm);

PaymentSaleForm = connect(
    state => ({
        initialValues: state.sales
    })
)(PaymentSaleForm)

export default PaymentSaleForm;
