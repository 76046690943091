import React from 'react';
import { renderField } from '../Utils/renderField/renderField';
import { Field, reduxForm, formValueSelector } from 'redux-form';
import { validate, validators } from 'validate-redux-form';
import { connect } from 'react-redux'

export const simpleInput = (props) => {
    return(
        <input type="text" value="Algo" />
    );
}

let initialValues = {};

let FormConfirmCancel = (props) => {
    const {
        handleSubmit,
        movement_id,

    } = props;
    initialValues.movement_id = movement_id;

    return (
    <form onSubmit={handleSubmit} id="formulario_anulacion" className="d-flex justify-content-center flex-column flex-sm-row align-items-stretch align-items-sm-center">
        <div className="form-group">
            <Field name="movement_id" component={"input"} type="hidden"/>
            <div className="d-flex justify-content-start flex-column flex-sm-row align-items-stretch align-items-sm-center">
                <label htmlFor="" className="m-0">Motivo de la anulación</label>
            </div>
            <div className="d-flex justify-content-start flex-column flex-sm-row align-items-stretch align-items-sm-center">
                <Field
                    name="reason"
                    component={renderField}
                    type="text"
                    className="form-control"
                />
            </div>
        </div>
    </form>
    );
}

FormConfirmCancel = reduxForm({
    form: 'FormConfirmCancel',
    initialValues,
    validate: data => {
         return validate(data, {
             'motivo_anulacion': validators.exists()('Campo requerido.'),
        })
    }
 })(FormConfirmCancel);

export default FormConfirmCancel;
