const url = 'cash_boxes';
const oppeningClosingUrl = 'cash_box_op';
const listUrl = 'cash_box_op/listCashBox';
const movementUrl = 'movements';

import { api } from 'api';
import Swal from 'sweetalert2';
import { push } from 'react-router-redux';
import { goBack } from 'react-router-redux';
import { initialize as initializeForm } from 'redux-form';
import { createActions, handleActions } from 'redux-actions';
import { actions as printActions } from "../../common/components/Utils/Print";
import { actions as sale_actions } from "../../redux/modules/sales";

const LOADER_CASHBOX = 'LOADER_CASHBOX';
const SET_DATA_CASHBOX = 'SET_DATA_CASHBOX';
const SET_PAGE_MOVEMENTS = 'SET_PAGE_MOVEMENTS';
const SET_BUSCADOR_CASHBOX = 'SET_BUSCADOR_CASHBOX';
const SET_FILTRO_CASHBOX = 'SET_FILTRO_CASHBOX';
const SET_CHECK_CASHBOX = 'SET_CHECK_CASHBOX';
const SET_MOVEMENTS_CASHBOX = 'SET_MOVEMENTS_CASHBOX';
const SET_MODAL = 'SET_MODAL';
const SET_CASHBOX = 'SET_CASHBOX';
const SET_CONFIG = 'SET_CONFIG'

const SET_DATA_SALE_BOX = 'SET_DATA_SALE_BOX';
const GROUP_BILL = 'GROUP_BILL';

const SET_CANCEL_MASK = 'SET_CANCEL_MASK';

const SET_SUMMARY_BOXES = 'SET_SUMMARY_BOXES';
const SET_SUMMARY_CATEGORY = 'SET_SUMMARY_CATEGORY';

const groupBy = (xs, f) => {
    return xs.reduce((r, v, i, a, k = f(v)) => ((r[k] || (r[k] = [])).push(v), r), {});
}

const getConfig = () => (dispatch) => {
    api.get(`configuration/getConfiguration`).catch((error) => {
        console.error("No se pudo acceder a la configuración")
    }).then((data) => {
        if(data){
            dispatch({type: SET_CONFIG, configuration: data});
        }
    })
}

const openAccount = (codigo) => (dispatch) => {
    dispatch(sale_actions.openAccount(codigo));
}

const addCash = (idCashBox) => (dispatch, getStore) => {
    const formData = getStore().form.addCash.values;
    formData.type=2;
    //console.log("Form data: ", formData);

    api.post(`${oppeningClosingUrl}/${idCashBox}/registerMovement`, formData).then((data)=>{
        Swal('Éxito', 'El efectivo se ingresó correctamente.', 'success')
        dispatch(checkCashBox());
        dispatch({type: LOADER_CASHBOX, cargando: false});
        const modal = { isOpen: false }
        dispatch({type: SET_MODAL, modal});
        dispatch(listarMovimientos(1))
    }).catch((error) => {

        dispatch({type: LOADER_CASHBOX, cargando: false});
        Swal(
            'Error',
            error.detail || 'Ha ocurrido un error, por favor vuelva a intentar.',
            'error'
        )

    });
}

const checkCashBox = () => (dispatch) =>{
    api.get(`${oppeningClosingUrl}/checkCashBox`).catch((error) => {
        dispatch({type: LOADER_CASHBOX, cargando: false});
        Swal(
            'Error',
            error.detail || 'Ha ocurrido un error, por favor vuelva a intentar.',
            'error'
        );
    }).then((data) => {
        if(data && data.length){
            let openCashBox = Object()

            if(data == "THERE_IS_NO_OPEN_CASHBOX"){
                openCashBox.isOpen = false;
                openCashBox.data = null;
            }else{
                openCashBox.isOpen = true;
                //openCashBox.data = data;
                openCashBox.data = data[0].oppeging_clossing_box;
            }

            dispatch({type: SET_CHECK_CASHBOX, openCashBox});

            if(data !== "THERE_IS_NO_OPEN_CASHBOX"){
                dispatch({type: SET_SUMMARY_BOXES, summary_boxes: data});
            }
        }
        dispatch({type: LOADER_CASHBOX, cargando: false});
    })
}

const getSumaryPerCategory = () => (dispatch) => {
    dispatch({type: LOADER_CASHBOX, cargando: true})
    api.get(`${oppeningClosingUrl}/getSummaryCategory`).catch((error) => {
        Swal(
            'Error',
            error.detail || 'Ha ocurrido un error, por favor vuelva a intentar.',
            'error'
        );
    }).then((data) => {
        console.log("data getSumaryPerCategory: ", data);
        /*
        const result = groupBy(data, (x) => x.financial_entity);
        console.log("result: ", result)
        dispatch({type:SET_SUMMARY_CATEGORY, summary_category: result});
        */

        dispatch({type:SET_SUMMARY_CATEGORY, summary_category: data});
    }).finally(()=>{
        dispatch({type: LOADER_CASHBOX, cargando: false});
    })
}

const listar = (page = 1) =>  (dispatch, getStore) => {
    dispatch({type: LOADER_CASHBOX, cargando: true});
    const store = getStore();
    const search = store.oppeningClosingBox.buscador;
    const filtro = store.oppeningClosingBox.filtro_cashbox;

    let params = {page, search};
    if(filtro){
        params[''] = filtro
    }

    api.get(`${listUrl}`, params).catch((error) => {
        dispatch({type: LOADER_CASHBOX, cargando: false});
        Swal(
            'Error',
            error.body.detail || 'Ha ocurrido un error, por favor vuelva a intentar.',
            'error'
        );
        dispatch(push('/oppening-closing'));
    }).then((data) => {
        if(data){
            dispatch({type: SET_DATA_CASHBOX, data});
        }
        dispatch({type: LOADER_CASHBOX, cargando: false});
    })
};

const openBox = (data) => (dispatch, getStore) => {
    console.log("data: ", data);
    //delete data.cash_box;

    Swal.fire({
        title: '¿Está seguro(a) de realizar la apertura?',
        type: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#aaa',
        confirmButtonText: 'Aceptar',
        cancelButtonText: 'Cancelar',
        reverseButtons: true
      }).then((result) => {
        if (result.value) {
            api.post(`${oppeningClosingUrl}`, data).then((data) => {
                dispatch({type: LOADER_CASHBOX, cargando: false})
                Swal('Éxito', 'La caja se ha aperturado correctamente.', 'success')
                .then(() => {
                    dispatch(listar(1));
                    dispatch(checkCashBox());
                    //dispatch(push('/oppening-closing/box'));
                    dispatch(push('/oppening-closing'));
                })
            }).catch((error) => {
                dispatch({type: LOADER_CASHBOX, cargando: false})
                Swal(
                    'Error',
                     error.detail || 'Ha ocurrido un error, por favor vuelva a intentar.',
                    'error'
                );
            }).finally(() => {
                dispatch({type: LOADER_CASHBOX, cargando: false})
            });
        }
      })
}

const getCashBox = id => (dispatch, getState) =>{
    dispatch({type: LOADER_CASHBOX, cargando: true});
    api.get(`${url}/${id}`).catch((error) => {
        dispatch({type: LOADER_CASHBOX, cargando: false});
        // if(error.statusCode === 404){
        //     console.log("Error");
        // }
    }).then((data) => {
        dispatch({type: LOADER_CASHBOX, cargando: false});
        if(data){
            dispatch({type: SET_CASHBOX, cashBox: data});
            // console.log("Data recibida: ", data);
        }
    })
}

const closeBox = (oppeningClosingBoxId) => (dispatch, getStore) => {
    const { summary_boxes, summary_category } = getStore().oppeningClosingBox;
    console.log(summary_boxes, summary_category);

    api.post(`${oppeningClosingUrl}/${oppeningClosingBoxId}/closeBox`,{ summary_boxes, summary_category}).catch((error) => {
        dispatch({type: LOADER_CASHBOX, cargando: false});
        Swal('Error', 'No se ha logrado cerrar la caja.', 'error')
            .then(() => {
                dispatch(checkCashBox());
            })
    }).then((data) => {
        dispatch({type: LOADER_CASHBOX, cargando: false});
        Swal('Éxito', 'La caja se ha cerrado correctamente.', 'success')
            .then(() => {
                dispatch({type: SET_SUMMARY_BOXES, summary_boxes: null});
                dispatch({type: SET_CHECK_CASHBOX, openCashBox:null});
                dispatch(checkCashBox());
                dispatch(push('/oppening-closing/box'));
            })
    })
}

const listAllMovements = (page = 1) =>  (dispatch, getStore) => {
    dispatch({type: LOADER_CASHBOX, cargando: true});

    /*
    const store = getStore();
    const search = store.oppeningClosingBox.buscador;
    const filtro = store.oppeningClosingBox.filtro_movements;
    */
    const filtro = {};
    let params = {};


    if(filtro){
        params[''] = filtro
    }

    api.get(`${movementUrl}/listAllMovements`, params).catch((error) => {
        dispatch({type: LOADER_CASHBOX, cargando: false});
        Swal(
            'Error',
            error.detail || 'Ha ocurrido un error, por favor vuelva a intentar.',
            'error'
        );
    }).then((data) => {
        if(data){
            dispatch({type: SET_MOVEMENTS_CASHBOX, data});
            dispatch({type: SET_PAGE_MOVEMENTS, page});
        }
        dispatch({type: LOADER_CASHBOX, cargando: false});
    })
};

const listarMovimientos = (page = 1) =>  (dispatch, getStore) => {
    const openCashBox = getStore().oppeningClosingBox.openCashBox;
    console.log('DATOS CAJA', openCashBox)
    if(openCashBox !== null && openCashBox !== undefined && openCashBox.data !== null) {
        const openCashBoxId = openCashBox.data.id;
        dispatch({type: LOADER_CASHBOX, cargando: true});
        const store = getStore();
        const search = store.oppeningClosingBox.buscador;
        const filtro = store.oppeningClosingBox.filtro_movements;
        let params = {page, search};

        if(filtro){
            params[''] = filtro
        }

        api.get(`${movementUrl}/${openCashBoxId}/movementList`, params).catch((error) => {
            dispatch({type: LOADER_CASHBOX, cargando: false});
            Swal(
                'Error',
                error.detail || 'Ha ocurrido un error, por favor vuelva a intentar.',
                'error'
            );
        }).then((data) => {
            console.log("Listado de movimientos: ", data);
            if(data){
                dispatch({type: SET_MOVEMENTS_CASHBOX, data});
                dispatch({type: SET_PAGE_MOVEMENTS, page});
            }
            dispatch({type: LOADER_CASHBOX, cargando: false});
        })
    }
};

const search = (search) => (dispatch) => {
    dispatch({type: SET_BUSCADOR_CASHBOX, buscador: search});
    dispatch(listar(1));
};

const filtro = (filtro) => (dispatch, getStore) => {
    if (filtro == 0){
        filtro = null
    }
    dispatch({type: SET_FILTRO_CASHBOX, filtro_cashbox: filtro});
    dispatch(listar(1));
};

const viewOneSale = (movement_id) => (dispatch) => {
    api.get("movements/getOneSale", { movement_id }).then((data) => {
        console.log("data: ", data);

        try{
            dispatch({type: SET_DATA_SALE_BOX, data});
        }catch(error){
            console.log("----------------- Error: ", error);
        }

        dispatch({type: GROUP_BILL});
    }).catch((error) => {
        Swal(
            'Error',
             error.detail || 'Ha ocurrido un error, por favor vuelva a intentar.',
            'error'
        );
    });
};

const verifyFelCancel = (movement_id) => (dispatch) => {
    console.log("Ejecutando función de verificación con id: ", movement_id);
    api.get("movements/verifyFelCancel", {movement_id}).then((data) => {
        console.log("data ---------> ", data);
        const status = data.status_fel_cancel;
        console.log("data in verifyFel: ", status);
        if(status == 'waiting'){
            setTimeout(() => {
                dispatch(verifyFelCancel(movement_id));
            }, 1000);
        }else if(status === 'finished'){
            dispatch({type:SET_CANCEL_MASK, cancel_mask: false});
            Swal('Éxito', 'Factura anulada correctamente.', 'success')
            .then(() => {
                console.log("Factura anulada correctamente");
            })
            dispatch(push('/oppening-closing/box'));
        }else{
            dispatch({type:SET_CANCEL_MASK, cancel_mask: false});
            console.log("Error detectado");

            Swal(
                'Error',
                 status,
                'error'
            );
        }
    }).catch((error)=>{
        Swal(
            'Error',
             error.detail || 'Ha ocurrido un error, por favor vuelva a intentar.',
            'error'
        );
    });
}

const cancelBill = () => (dispatch, getStore) => {
    const datos = getStore().form.FormConfirmCancel.values;
    const movement_id = datos.movement_id;

    console.log("datos: ----------------> ", datos);

    api.post("movements/cancelBill", { datos }).then((data) => {
        dispatch({type:SET_CANCEL_MASK, cancel_mask: true});
        setTimeout(() => {
            dispatch(verifyFelCancel(movement_id));
        }, 1000);
    }).catch((error) => {
        Swal(
            'Error',
             error.detail || 'Ha ocurrido un error, por favor vuelva a intentar.',
            'error'
        );
    });

    /*
    Swal.fire({
        title: '¿Está seguro(a) que desea anular la venta?',
        type: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#aaa',
        confirmButtonText: 'Aceptar',
        cancelButtonText: 'Cancelar',
        reverseButtons: true
    }).then((result) => {
        if (result.value) {
            api.post("movements/cancelBill", { movement_id }).then((data) => {
                dispatch({type:SET_CANCEL_MASK, cancel_mask: true});
                setTimeout(() => {
                    dispatch(verifyFelCancel(movement_id));
                }, 1000);
            }).catch((error) => {
                Swal(
                    'Error',
                     error.detail || 'Ha ocurrido un error, por favor vuelva a intentar.',
                    'error'
                );
            });
        }
    });
    */
}

/*
const cancelBill = (movement_id) => (dispatch) => {
    Swal.fire({
        title: '¿Está seguro(a) que desea anular la venta?',
        type: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#aaa',
        confirmButtonText: 'Aceptar',
        cancelButtonText: 'Cancelar',
        reverseButtons: true
    }).then((result) => {
        if (result.value) {
            api.post("movements/cancelBill", { movement_id }).then((data) => {
                dispatch({type:SET_CANCEL_MASK, cancel_mask: true});
                setTimeout(() => {
                    dispatch(verifyFelCancel(movement_id));
                }, 1000);
            }).catch((error) => {
                Swal(
                    'Error',
                     error.detail || 'Ha ocurrido un error, por favor vuelva a intentar.',
                    'error'
                );
            });
        }
    });
}
*/

export const actions = {
    getConfig,
    checkCashBox,
    listar,
    search,
    filtro,
    openBox,
    addCash,
    closeBox,
    listarMovimientos,
    getCashBox,
    viewOneSale,
    cancelBill,
    getSumaryPerCategory,
    openAccount,
};

export const reducer = {
    [SET_SUMMARY_BOXES] : (state, { summary_boxes} ) => {
        return {
            ...state,
            summary_boxes,
        }
    },
    [LOADER_CASHBOX]: (state, { cargando }) => {
        return {...state, cargando }
    },
    [SET_CHECK_CASHBOX]: (state, { openCashBox }) => {
        return {...state, openCashBox }
    },
    [SET_DATA_CASHBOX]: (state, { data }) => {
        return {...state, data }
    },
    [SET_PAGE_MOVEMENTS]: (state, { page }) => {
        return {...state, page }
    },
    [SET_BUSCADOR_CASHBOX]: (state, { buscador }) => {
        return {...state, buscador }
    },
    [SET_FILTRO_CASHBOX]: (state, { filtro_movements }) => {
        return {...state, filtro_movements }
    },
    [SET_MOVEMENTS_CASHBOX]: (state, { data }) => {
        return {...state, movements: data }
    },
    [SET_MODAL]: (state, { modal }) => {
        return {...state, modal }
    },
    [SET_CASHBOX]: (state, { cashBox }) => {
        return {...state, cashBox }
    },
    [SET_CONFIG]: (state, { configuration }) => {
        return {...state, configuration }
    },
    [SET_DATA_SALE_BOX]: (state, {data}) => {
        let arreglo = [];
        let payment = 0;
        const patientInfo = {};

        const movement = data;
            const correlative = movement.correlative;
            const movement_id = movement.id;
            const codigo = movement.codigo;

            ///// Agregado
            const fel_error = movement.fel_error;
            const fel_status = movement.fel_status;
            const fel_cancel_status = movement.fel_cancel_status;
            const serie = movement.serie;
            const numero = movement.numero;
            const numeroAutorizacion = movement.numeroAutorizacion;
            ///// Agregado...

            const factura = movement.document_type === "REC" ? "recibo" : "factura";
            const financial_entity = movement.financial_entity.id;
            const financial_entity_name = movement.financial_entity.usuario_fel;

            let medic = null;
            if(movement.doctor){
                const medic = { value: movement.doctor.id, label: movement.doctor.name };
            }

            const patient = { value: movement.patient.id, label: movement.patient.first_name };

            const recibo = movement.financial_entity.afiliacion_fel === "exento" ? true : false;

            const referent = {
                financial_entity: 0,
                //idReferentCategory: movement.referent.institution.referent_category.id,
                idReferentCategory: 0,
                label: movement.referent.name,
                //usuario_fel: movement.referent.institution.referent_category.financial_entity.usuario_fel,
                usuario_fel: movement.financial_entity.usuario_fel,
                receipt: recibo,
                value: movement.referent.id,
            };

            const nit = movement.nit;
            patientInfo.medic = medic;
            patientInfo.patient = patient;
            patientInfo.referent = referent;
            patientInfo.nit = nit;

            console.log("PATIENT INFO <------------------------ ", patientInfo);

            payment += parseFloat(movement.payment);

            console.log("Movement_detail_movement: ", movement.movement_detail_movement[0]);
            movement.movement_detail_movement.forEach(detail => {
                let coupons = [];
                detail.coupon_payment.forEach(cupon => {
                    const service = cupon.coupon.service !== null ? cupon.coupon.service.id : null; //add
                    const coupon = {
                        amount: cupon.coupon.amount,
                        amount_label: cupon.quantity,
                        code: cupon.coupon.code,
                        id: cupon.coupon.id,
                        name: cupon.coupon.name,
                        percentage: null,
                        //service: cupon.coupon.service.id,
                        service, //add
                        type_coupon: cupon.coupon.type_coupon,
                        used: cupon.coupon.used,
                    };

                    coupons.push(coupon);
                });

                const descuento = (((parseFloat(detail.discount) * 100) / parseFloat(detail.price)) / 100);
                const total_static = parseFloat((parseFloat(detail.price) * parseInt(detail.quantity)).toFixed(2));

                const service = {
                    cant: detail.quantity,
                    coupons,
                    descuento,
                    descuentoValor: detail.discount,
                    descuento_limite: detail.service.discount,
                    discount_authorization: detail.discount_authorization,
                    cupon: false,
                    factura,
                    financial_entity,
                    financial_entity_name,
                    id: detail.service.id,
                    name: detail.service.name,
                    price: detail.price,
                    total: parseFloat(detail.subtotal),
                    totalStatic: total_static,
                    correlative,
                    movement_id,
                    codigo,
                    medic,
                    patient,

                    //agregado
                    fel_error,
                    fel_status,
                    fel_cancel_status,
                    serie,
                    numero,
                    numeroAutorizacion,
                    //agregado...
                };

                arreglo.push(service);
            });

        return {
            ...state,
            productsInCar: arreglo,
            totalInCar: payment,
            patientInfo,
        }
    },
    [GROUP_BILL]: (state) => {
        const arreglo = state.productsInCar;
        const result = groupBy(arreglo, (a) => a.financial_entity_name);
        let facturas = [];
        let cont = 0;
        Object.entries(result).forEach((element) => {
            let total = 0, descuento=0;
            let financial_entity = { id: 0 , name: element[0] }
            //modificado...
            let dataBilling = { nit: "", name: "", correlative: null, code:"", state: "", id: 0, fel_status:0, fel_error: '', serie:'', numero:'', numeroAutorizacion:'', fel_cancel_status:0};
            //El id en dataBilling es el id del movimiento si hubiere sido de una cuenta abierta
            let patientInfo = { medic: null, patient: null }; //add

            let verify_movement_id = false;
            let verify_code = false;

            //Agregado
            let verify_fel_status = false;
            let verify_fel_cancel_status = false;
            let verify_fel_error  = false;
            let verify_serie = false;
            let verify_numero = false;
            let verify_numeroAutorizacion = false;
            //Agregado...

            let services = [];
            element[1].forEach((service, key) => {
                let descuento_sumado = 0;
                if(key === 0){
                    financial_entity.id = service.financial_entity;
                    financial_entity.factura = service.factura;
                    dataBilling.correlative = service.correlative;
                }

                if(!verify_movement_id && service.movement_id !== 0){
                    dataBilling.id = service.movement_id;
                    verify_movement_id = true;
                }

                if(!verify_code && service.code !== ''){
                    dataBilling.code = service.codigo;
                    verify_code = true;
                }

                //Agregado
                if(!verify_fel_status && service.fel_status !== 0){
                    dataBilling.fel_status = service.fel_status;
                    verify_fel_status = true;
                }

                if(!verify_fel_cancel_status && service.fel_cancel_status !== 0){
                    dataBilling.fel_cancel_status = service.fel_cancel_status;
                    verify_fel_cancel_status = true;
                }

                if(!verify_fel_error && service.fel_error !== ''){
                    dataBilling.fel_error = service.fel_error;
                    verify_fel_error = true;
                }

                if(!verify_serie && service.serie !== ''){
                    dataBilling.serie = service.serie;
                    verify_serie = true;
                }

                if(!verify_numero && service.numero !== ''){
                    dataBilling.numero = service.numero;
                    verify_numero = true;
                }

                if(!verify_numeroAutorizacion && service.numeroAutorizacion !== ''){
                    dataBilling.numeroAutorizacion = service.numeroAutorizacion;
                    verify_numeroAutorizacion = true;
                }
                //Agregado...

                /*
                if(!verify_medic && service.medic !== null){
                    patientInfo.medic = service.medic;
                    verify_medic = true;
                }

                if(!verify_patient && service.patient !== null){
                    patientInfo.patient = service.patient;
                    verify_patient = true;
                }
                */

                let totc = service.cant * service.price;

                //total += parseFloat( parseFloat(service.total).toFixed(2) );
                total += parseFloat( parseFloat(totc).toFixed(2) );
                descuento += parseFloat(service.descuentoValor);
                descuento_sumado += parseFloat(service.descuentoValor); //ADD
                service.coupons.forEach(coupon => {
                    descuento += parseFloat(coupon.amount_label);
                    descuento_sumado += parseFloat(coupon.amount_label); //ADD
                });

                let obj = {
                    coupons: service.coupons,
                    service_name: service.name,
                    service: service.id,
                    price: service.price,
                    quantity : service.cant,
                    discount : service.descuentoValor,
                    discount_authorization: service.discount_authorization,
                    accumulated_discount: descuento_sumado,//ADD
                    subtotal : service.total,
                };

                services.push(obj);
            });


            const totalLabel = parseFloat((total.toFixed(2) - descuento.toFixed(2)).toFixed(2));

            const calculate = { total: parseFloat(total.toFixed(2)), totalLabel, descuento: parseFloat(descuento.toFixed(2)), resto: totalLabel };
            const dataPayments = {
                                    card_payments:[],
                                    due_payments: [],
                                    deposit_payments: [],
                                    cash: 0,
                                    retention: false,
                                    retention_amount: 0,
                                    cards_total: 0,
                                    dues_total: 0,
                                    deposit_total:0,
                                    index:cont,
                                    type: "venta",
                                };
            facturas.push({ financial_entity, services, dataPayments, calculate, dataBilling });
            cont++;
        })

        //console.log("FACTURAS: ", facturas);

        return{
            ...state,
            productsInCarOrdered: facturas
        }
    },
    [SET_CANCEL_MASK]: (state, { cancel_mask }) => {
        return {
            ...state,
            cancel_mask,
        }
    },
    [SET_SUMMARY_CATEGORY]: (state, { summary_category }) => {
        return {
            ...state,
            summary_category,
        }
    }
}

export const initialState = {
    configuration: null,
    cargando: false,
    page: 1,
    openCashBox: null,
    data: {
        count: 0,
        next: null,
        previous: null,
        results: [],
    },
    movements: {
        count: 0,
        next: null,
        previous: null,
        results: [],
    },
    buscador: '',
    filtro_movements: null,
    modal:{
        isOpen: false,
    },
    cashBox: null,

    productsInCar: [],
    productsInCarOrdered: [],
    totalInCar: 0,
    patientInfo: {
        patient: '',
        name : '',
        nit : '',
        referent: null,
        doctor:null,
    },
    cancel_mask: false,
    summary_boxes: [],
    summary_category: null,
};

export default handleActions(reducer, initialState)
