import { connect } from 'react-redux';
import Patient from './Patient';
import { actions } from '../../../redux/modules/patients';
import {resetStore} from "../../../redux/modules/login";


const ms2p = (state) => {
    return {
        ...state.patients,
    };
};

const md2p = { ...actions, resetStore };

export default connect(ms2p, md2p)(Patient);
