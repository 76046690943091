import React, { Component } from 'react';

export default class PrintRootContainer extends Component {
    render() {
        const print = window.location.href.includes('pdf')
        const { children } = this.props

        return (
            <div>
                <div className={print ? "" : "dont-print"}>
                    {children}
                </div>
                <div id="PRINT-ROOT-CONTAINER">
                </div>
            </div>
        )
    }
}
