const url = 'cash_box_op';
import { api } from 'api';
import Swal from 'sweetalert2';
import { push } from 'react-router-redux';
import { goBack } from 'react-router-redux';
import { initialize as initializeForm } from 'redux-form';
import { createActions, handleActions } from 'redux-actions';
import { actions as sale_actions } from "../../../redux/modules/sales";
import moment from "moment";
moment.locale('es');

const LOADER_ARQUEO = 'LOADER_ARQUEO';
const SET_DATA_ARQUEO = 'SET_DATA_ARQUEO';
const SET_PAGE_ARQUEO = 'SET_PAGE_ARQUEO';
const SET_BUSCADOR_ARQUEO = 'SET_BUSCADOR_ARQUEO';
const SET_FILTRO_ARQUEO = 'SET_FILTRO_ARQUEO';
const SET_MONTH = 'SET_MONTH';
const SET_YEAR = 'SET_YEAR';
const SET_CATEGORY_DETAIL = 'SET_CATEGORY_DETAIL';

const SET_INITIAL_DATE_NEW_DASHBOARD = 'SET_INITIAL_DATE_NEW_DASHBOARD';
const SET_FINAL_DATE_NEW_DASHBOARD = 'SET_FINAL_DATE_NEW_DASHBOARD'
const SET_UPDATE_DATA_NEW_DASHBOARD = 'SET_UPDATE_DATA_NEW_DASHBOARD';

const groupBy = (xs, f) => {
    return xs.reduce((r, v, i, a, k = f(v)) => ((r[k] || (r[k] = [])).push(v), r), {});
}

const listar = (page = 1) => (dispatch, getStore) => {
    dispatch({ type: LOADER_ARQUEO, cargando: true });
    const store = getStore();
    const search = store.arqueo.buscador;
    const filtro = store.arqueo.filtro_roles;

    const date_string = '15/' + store.banks.month.value + '/' + store.banks.year.value
    const date_moment = moment(date_string, "DD/MM/YYYY")
    const initial_date = date_moment.startOf('month').format('DD/MM/YYYY')
    const final_date = date_moment.endOf('month').format('DD/MM/YYYY')

    let params = { page, search, initial_date, final_date };
    if (filtro) {
        params[''] = filtro
    }

    api.get(`${url}/getArqueos`, params).catch((error) => {
        dispatch({ type: LOADER_ARQUEO, cargando: false });
        Swal(
            'Error',
            error.detail || 'Ha ocurrido un error, por favor vuelva a intentar.',
            'error'
        );
    }).then((data) => {
        console.log("Data: ", data);

        if (data) {
            dispatch({ type: SET_DATA_ARQUEO, data });
            dispatch({ type: SET_PAGE_ARQUEO, page });
        }
        dispatch({ type: LOADER_ARQUEO, cargando: false });
    })
};

const detail = () => (dispatch, getState) => {
    const store = getState().arqueoNew;        
    dispatch({ type: LOADER_ARQUEO, cargando: true });
    api.get(`${url}/getNewDashboard`, { initial_date: store.initial_date, final_date: store.final_date }).catch((error) => {        
        if (error.statusCode === 404) {
            dispatch(push('/arqueos'))
        }
    }).then((data) => {        
        if (data) {
            if(Array.isArray(data)){
                dispatch({ type: SET_UPDATE_DATA_NEW_DASHBOARD, updateData: data });
            }                     
                
            api.get(`${url}/newDashboardGetCategoryDetail`, { initial_date: store.initial_date, final_date: store.final_date }).catch((error) => {
                if (error.statusCode === 404) {
                   // dispatch(push('/arqueos'))
                }                
            }).then((data) => {
                console.log("data in new dashboard get category detail... ", data);
                if (data) {                    
                    dispatch({ type: SET_CATEGORY_DETAIL, category_detail: data });
                }                
            }).finally(()=>{
                dispatch({ type: LOADER_ARQUEO, cargando: false });
            })
        }
    }).finally(()=>{
        dispatch({ type: LOADER_ARQUEO, cargando: false });
    })
}

const search = (search) => (dispatch) => {
    dispatch({ type: SET_BUSCADOR_ROL, buscador: search });
    dispatch(listar(1));
};

const filtro = (filtro) => (dispatch, getStore) => {
    if (filtro == 0) {
        filtro = null
    }
    dispatch({ type: SET_FILTRO_ROL, filtro_rol: filtro });
    dispatch(listar(1));
};

const set_month = (value) => (dispatch, getStore) => {
    const store = getStore()
    dispatch({ type: SET_MONTH, month: value })
    dispatch(listar());
}

const set_year = (value) => (dispatch, getStore) => {
    const store = getStore()
    dispatch({ type: SET_YEAR, year: value })
    dispatch(listar());
}

const openAccount = (codigo) => (dispatch) => {
    dispatch(sale_actions.openAccount(codigo));
}

const setFinalDate = (value) => (dispatch) => {    
    dispatch({type: SET_FINAL_DATE_NEW_DASHBOARD, final_date: value});
}

const setInitialDate = (value) => (dispatch) => {        
    dispatch({type: SET_INITIAL_DATE_NEW_DASHBOARD, initial_date: value});    
}

export const actions = {
    listar,
    detail,
    search,
    filtro,
    set_year,
    set_month,
    openAccount,    
    setFinalDate,
    setInitialDate
};

export const reducer = {
    [LOADER_ARQUEO]: (state, { cargando }) => {
        return { ...state, cargando }
    },
    [SET_DATA_ARQUEO]: (state, { data }) => {
        return { ...state, data }
    },
    [SET_PAGE_ARQUEO]: (state, { page }) => {
        return { ...state, page }
    },
    [SET_UPDATE_DATA_NEW_DASHBOARD]: (state, { updateData }) => {
        return { ...state, updateData }
    },
    [SET_BUSCADOR_ARQUEO]: (state, { buscador }) => {
        return { ...state, buscador }
    },
    [SET_FILTRO_ARQUEO]: (state, { filtro_rol }) => {
        return { ...state, filtro_rol }
    },
    [SET_MONTH]: (state, { month }) => {
        return { ...state, month }
    },
    [SET_YEAR]: (state, { year }) => {
        return { ...state, year }
    },
    [SET_CATEGORY_DETAIL]: (state, { category_detail }) => {
        return { ...state, category_detail }
    },

    

    [SET_INITIAL_DATE_NEW_DASHBOARD]: (state, { initial_date }) => {
        return{
            ...state,
            initial_date
        }
    },
    [SET_FINAL_DATE_NEW_DASHBOARD]: (state, { final_date }) => {
        return{
            ...state,
            final_date
        }
    },
};

export const initialState = {
    cargando: false,
    page: 1,
    data: {
        count: 0,
        next: null,
        previous: null,
        results: [],
    },    
    buscador: '',
    filtro_roles: null,
    updateData: {},    
    month: { value: moment().format("MM"), label: moment().format("MMMM").charAt(0).toUpperCase() + moment().format("MMMM").slice(1) },
    year: { value: moment().format('YYYY'), label: moment().format('YYYY') },
    category_detail: [],

    initial_date: moment().date(1).format("DD/MM/YYYY"),
    final_date: moment().format("DD/MM/YYYY"),
};

export default handleActions(reducer, initialState)
