import React, { useState, useEffect, useLayoutEffect, createRef } from "react";
import Select, { AsyncCreatable, Async } from 'react-select';
import AsyncSelect from 'react-select/lib/Async';
import NumberFormat from 'react-number-format';
import classNames from 'classnames';
import Switch from "react-switch";
import _ from "lodash";
import moment from "moment";
import TimePicker from 'react-time-picker';
import DatePicker from '../../Utils/DatePicker/index';
import { TimePicker as AntTimePicker, Input} from 'antd';
import FileUploader from '../FileUploader/FileUploader';
import {Jodit} from "jodit";
import "jodit/build/jodit.css";
import { useVerificarCui, useValidateNit} from '../../Utils/hooks/auth_fel';

import MyUpload from './myUpLoad';

export const renderField = ({ input, label, autofocus, letras, upper, onBlurs, onKeyDownEnter, inputId, disabled, addClass, type, meta: { touched, error } }) => {
    const invalid = touched && error;
    return (
        <div>
            <input
                {...input}
                placeholder={label}
                type={type}
                autoFocus={autofocus ? true:false}
                id={inputId}
                onBlur={(value) => { if (onBlurs) onBlurs(value.target.value); }}
                onKeyDown={(e) => {
                    if (e.key === 'Enter' && onKeyDownEnter) {
                        e.preventDefault();
                        onKeyDownEnter(input.value);
                    }
                }}
                onKeyPress={letras ? (event)=>{if(!/[a-zA-Z]|\s|ñ/i.test(event.key))event.preventDefault()}: ""}
                disabled={disabled}
                className={classNames('form-control', { 'is-invalid': invalid }, addClass)}
            />
            {invalid && <div className="invalid-feedback">{error}</div>}
        </div>
    )
};

export const renderFieldDPI = ({input, label, onBlurs, autofocus, letras, onKeyDownEnter, inputId, disabled, addClass, type, meta: { touched, error } }) => {
    const invalid = touched && error;

    const { loading, errorFetch, data, setCui } = useVerificarCui();
    // const [text, setText] = React.useState("");
    React.useEffect(() => {
        if (data) {
            onKeyDownEnter(data);
        }
        // if (data && data.first_name && data.last_name && data.first_name == '' && data.last_name === '') {
        //     setText('No se encontró el DPI');
        // }
        // else {
        //     setText('');
        // }
    }, [data]);

    return (
        <div>
            <input
                {...input}
                placeholder={label}
                type={type}
                autoFocus={autofocus ? true:false}
                id={inputId}
                onBlur={(value) => { if (onBlurs) setCui(value.target.value); }}
                onKeyDown={(e) => { //DESCOMENTAR
                    if (e.key === 'Enter' && onKeyDownEnter) {
                        e.preventDefault();
                        if (!invalid){
                            setCui(input.value);
                        }
                    }
                }}
                onKeyPress={letras ? (event)=>{if(!/[a-zA-Z]|\s|ñ/i.test(event.key))event.preventDefault()}: ""}
                disabled={disabled}
                className={classNames('form-control', { 'is-invalid': invalid }, addClass)}
            />
            {invalid && <div className="invalid-feedback">{error}</div>}
        </div>
    )
};

export const renderFieldNIT = ({ input, label, autofocus, letras, onKeyDownEnter, inputId, disabled, addClass, type, meta: { touched, error } }) => {
    const invalid = touched && error;
    return (
        <div>
            <input
                {...input}
                placeholder={label}
                type={type}
                autoFocus={autofocus ? true:false}
                id={inputId}
                onKeyDown={(e) => {
                    if (e.key === 'Enter' && onKeyDownEnter) {
                        e.preventDefault();
                        onKeyDownEnter(input.value);
                    }
                }}
                onKeyPress={letras ? (event)=>{if(!/[a-zA-Z]|\s|ñ/i.test(event.key))event.preventDefault()}: ""}
                disabled={disabled}
                className={classNames('form-control', { 'is-invalid': invalid }, addClass)}
            />
            {invalid && <div className="invalid-feedback">{error}</div>}
        </div>
    )
};


export const renderRadioInput = ({ input, id, defaultChecked=false, disabled, addClass, meta: { touched, error } }) => {
    const invalid = touched && error;
    console.log('VALUE: ', input.checked)

    return (
        <div>
            <input
                id={id}
                type={'radio'}
                value={input.value}
                checked={input.checked}
                onClick={()=>{
                    if (input.checked){
                        document.getElementById(id).checked= null
                        input.onChange(null)
                    }
                }}
                name={input.name}
                disabled={disabled}
                onBlur={input.onBlur}
                onDrop={input.onDrop}
                onFocus={input.onFocus}
                onChange={input.onChange}
                onDragStart={input.onDragStart}
                defaultChecked={defaultChecked}
                className={classNames('form-control', { 'is-invalid': invalid }, addClass)}
            />
            {invalid && <div className="invalid-feedback">{error}</div>}
        </div>
    )
};

export const renderFieldDateFormat = ({ input, label, autoFocus, upper, inputId, disabled, addClass, type, meta: { touched, error } }) => {
    const invalid = touched && error;
    return (
        <div>
            <NumberFormat
                className={classNames('form-control', { 'is-invalid': invalid }, addClass)}
                id={inputId}
                disabled={disabled}
                format="##/##/####"
                placeholder="DD/MM/YYYY"
                mask={['D', 'D', 'M', 'M', 'Y', 'Y', 'Y', 'Y']}
                {...input}
                style={{minWidth: 125}}
                onValueChange={(values) => {
                    const dia = values.formattedValue.indexOf("D")
                    const mes = values.formattedValue.indexOf("M")
                    const anio = values.formattedValue.indexOf("Y")

                    if((dia + mes + anio) < 0){
                        //set_initial_date(values.formattedValue);
                        input.onChange(values.formattedValue);
                    }
                }}
                autoFocus={autoFocus ? true : false}
                onFocus={(e) => {
                    e.target.setSelectionRange(0,10);
                }}
            />
            {invalid && <div className="invalid-feedback" >
                {error}
            </div>}
        </div>
    )
};

export const renderFieldFormat = ({ input, autoFocus, fieldFormat, placeholder, onValueChange, mask, inputId, disabled, addClass, type, meta: { touched, error } }) => {
    const invalid = touched && error;
    return (
        <div>
            <NumberFormat
                className={classNames('form-control', { 'is-invalid': invalid }, addClass)}
                id={inputId}
                disabled={disabled}
                format={fieldFormat}
                placeholder={placeholder}
                mask={mask}
                {...input}
                style={{minWidth: 125}}
                onValueChange={values => input.onChange(onValueChange(values))}
                autoFocus={autoFocus ? true: false}
                onFocus={(e) => {
                    e.target.setSelectionRange(0,8);
                }}
            />
            {invalid && <div className="invalid-feedback" >
                {error}
            </div>}
        </div>
    )
};

export const renderDatePicker = ({
    id, input, minDate, maxDate, isDisabled, addClass,label, type, meta: { touched, error }, className,
    }) => {
    const invalid = touched && error;
    return (
        <div id={id}>
            <DatePicker
                isDisabled={isDisabled}
                value={input.value}
                maxDate={maxDate}
                minDate={minDate}
                className={classNames('form-control', { 'is-invalid': invalid }, addClass)}
                onChange={(date) => {
                    input.onChange(date);
                }}
            />
            {invalid && (
                <div className="invalid-feedback">
                    {error}
                </div>
            )}
        </div>
    );
};

export const RenderDatePicker = ({
    id, input, minDate, maxDate, isDisabled, addClass,label, type, meta: { touched, error }, className,
    }) => {
    const invalid = touched && error;
    return (
        <div id={id}>
            <DatePicker
                isDisabled={isDisabled}
                value={input.value}
                maxDate={maxDate}
                minDate={minDate}
                className={classNames('form-control', { 'is-invalid': invalid }, addClass)}
                onChange={(date) => {
                    input.onChange(date);
                }}
            />
            {invalid && (
                <div className="invalid-feedback">
                    {error}
                </div>
            )}
        </div>
    );
};

export const renderFieldPrefix = ({ input, label, disabled, addClass, type, meta: { touched, error } }) => {
    const invalid = touched && error;
    return (
        <div>
            <input {...input} placeholder={label} type={type}
                disabled={disabled?'disabled':''}
                className={classNames('form-control', { 'is-invalid': invalid }, addClass)} minLength="1" maxLength="3"/>
            {invalid && <div className="invalid-feedback" >
                {error}
            </div>}
        </div>
    )
};

// export const renderFieldCheck = ({ input, label, value, disabled, type, meta: { touched, error } }) => {
//     const invalid = touched && error;
//     return (
//         <div>
//             <input {...input} placeholder={label} type={type}
//                 disabled={disabled?'disabled':''}
//                 className={classNames('form-check-input', { 'is-invalid': invalid })} />
//             {invalid && <div className="invalid-feedback" >
//                 {error}
//             </div>}
//         </div>
//     )
// };

export const renderFieldCheck = ({ input, label, value, disabled, type, meta: { touched, error } }) => {
    const invalid = touched && error;
    return (
        <React.Fragment>
            <div className="checkbox c-checkbox">
                <label className="needsclick m-0">
                    <input
                        type="checkbox"
                        checked={input.value ? input.value : false}
                        disabled={disabled}
                        {...input}
                        className={classNames('', { 'is-invalid': invalid })}
                    />
                    <span className="fa fa-check" />
                    &nbsp;{label}
                </label>
            </div>
            {invalid && (
                <div className="invalid-feedback">
                    {error}
                </div>
            )}
        </React.Fragment>
    )
};

export const renderTextArea = ({ input, label, disabled, rows, type, meta: { touched, error } }) => {
    const invalid = touched && error;
    return (
        <div>
            <textarea
                disabled={disabled?'disabled':''}
                {...input} placeholder={label} style={{ resize: "none" }} rows={rows ? rows : 3}
                className={classNames('form-control', { 'is-invalid': invalid })} />
            {invalid && <div className="invalid-feedback">
                {error}
            </div>}
        </div>
    )
};

/**
 * This function use for de input textarea used into a modal form
 * @param {} param0
 */
export const modalTextArea = ({ input, label, disabled, autofocus, rows, cols, type, className, meta: { touched, error } }) => {
    const invalid = touched && error;
    return (
        <div className='w-100'>
            <textarea
                disabled={disabled?'disabled':''}
                {...input}
                placeholder={label}
                style={{ resize: "none" }} rows={rows ? rows : 3}
                cols={cols ? cols : 20}
                className={classNames(className, { 'is-invalid': invalid })}
                autoFocus={autofocus ? true : false}
            />
                {invalid && <div className="invalid-feedback">
                {error}
            </div>}
        </div>
    )
};

export const modalEditor = ({ input, label, disabled, autofocus, rows, cols, type, className, meta: { touched, error } }) => {
    const invalid = touched && error;
	useLayoutEffect(() => {
        const editor = Jodit.make("#editor",{
            placeholder: "Ingrese texto...",
            height: "500px",
            buttons: [
                "bold",
                "italic",
                "underline",
                '|',
                'ul',
                'ol',
                'eraser',
                '|',
                'font',
                'fontsize',
                'brush',
                'paragraph',
                '|',
                'image',
                'table',
                '|',
                'align',
                '|',
                'undo',
                'redo',
                '|',
                'fullsize',
            ],
            uploader: {
                url: '/api/diagnostic_order/uploadDiagnosticImage/',
                headers: {
                    "Authorization": "Token " + localStorage.getItem('token')
                },
                data: null,
                filesVariableName: function(i){
                    return "imgs["+i+"]"
                },
                withCredentials: false,
                pathVariableName: "path",
                format: "json",
                method: "POST",
                prepareData: function(formData){
                    formData.append('id', 1)
                },
                isSuccess: function (resp) {
                    return !resp.error;
                },
                getMessage: function (resp) {
                    return resp.msgs.join('\n');
                },
                process: function (resp) {
                    return resp;
                },
                defaultHandlerSuccess: function (resp) {
                    for(var i=0; i<resp.imgs.length; i++) {
                        this.selection.insertImage(resp.imgs[i]['url']/*, {width: resp.imgs[i]['width'], height: resp.imgs[i]['height']}*/);
                    }
                }
            }
        },[]);
        editor.value = input.value ? input.value : ""
        editor.events.on("change", (value) => input.onChange(value))
    }, []);

    return (
        <div className='w-100'>
            <textarea id="editor" name="editor" />
                {invalid && <div className="invalid-feedback">
                {error}
            </div>}
        </div>
    )
}

/*export const modalEditor = ({ input, label, disabled, autofocus, rows, cols, type, className, meta: { touched, error } }) => {
    const invalid = touched && error;
    const op_toolbar = disabled ? [] : ['inline', 'textAlign', 'fontSize', 'colorPicker'];
    return (
        <div className='w-100'>
            <Editor
                editorStyle={{ height: '40vh' }}
                editorState={editorValue ? editorValue : EditorState.createEmpty()}
                wrapperClassName="editor-container"
                toolbarClassName={`editor-toolbar ${disabled && 'disabled'}`}
                editorClassName="editor-content"
                onEditorStateChange={(value) => {
                    input.onChange(stateToHTML(value.getCurrentContent()))
                    setEditorValue(value)
                }}
                toolbar={{
                    options: op_toolbar,
                }}
                readOnly={disabled ? disabled : false}
                className={classNames(className, { 'is-invalid': invalid })}
            />
                {invalid && <div className="invalid-feedback">
                {error}
            </div>}
        </div>
    )
};*/


export const renderSearchSelect = ({ id, special, input, autofocus, onChange, clearable=true, addClass, disabled, loadOptions, valueKey, labelKey, defaultOptions, opciones =[], meta: { touched, error } }) => {
    const invalid = touched && error;
    return (
        <div className={`border-trasparent ${special}`}>
            <AsyncSelect isClearable={clearable} isDisabled={disabled}
                    id={id}
                    value={input.value} className={classNames('form-control p-0 border-trasparent', { 'is-invalid': invalid }, addClass)}
                    onChange={(e) => {
                        input.onChange(e ? e : null);
                        console.log(onChange);
                        if (onChange) {
                            onChange(e);
                        }
                    }}

                    autoFocus={autofocus ? true : false}

                    theme={(theme) => {
                        return {
                            ...theme,
                            borderRadius: '1em',
                            background: 'white',
                            colors: {
                                ...theme.colors,
                                text: '#415362',
                                primary25: '#ebebeb',
                                primary: '#0a5995',
                            },
                        }
                    }}

                    classNamePrefix="react-select"
                    cache={false} placeholder="Escriba para buscar"
                    defaultOptions={ true }
                    searchPromptText="Escriba para buscar"
                    getOptionValue={(option) => (option[valueKey])}
                    getOptionLabel={(option) => (option[labelKey])}
                    loadOptions={loadOptions}
                     />
            {invalid && <div className="invalid-feedback">
                {error}
            </div>}
        </div>
    )
};

export const renderSearchSelectPatient = ({ input, clearable, disabled, loadOptions, valueKey, labelKey, defaultOptions, opciones =[], meta: { touched, error } }) => {
    const invalid = touched && error;
    return (
        <div className="border-trasparent">
            <AsyncSelect clearable={clearable} isDisabled={disabled}
                    value={ input.value} className={classNames('patient-search-input form-control-patient p-0 border-trasparent', { 'is-invalid': invalid })}
                    classNamePrefix="react-select"
                    onChange={(e) => {
                        input.onChange(e ? e : null);
                    }}
                    theme={(theme) => {
                        return {
                            ...theme,
                            borderRadius: '1em',
                            background: 'white',
                            colors: {
                                ...theme.colors,
                                text: '#415362',
                                primary25: '#ebebeb',
                                primary: '#0a5995',
                            },
                        }
                    }}
                    // classNamePrefix="react-select"
                    cache={false} placeholder="Escriba para buscar"
                    defaultOptions={ true }
                    searchPromptText="Escriba para buscar"
                    getOptionValue={(option) => (option[valueKey])}
                    getOptionLabel={(option) => (option[labelKey])}
                    loadOptions={loadOptions} />
            {invalid && <div className="invalid-feedback" style={{ display: invalid ? 'block' : 'none' }}>{error}</div>}
        </div>
    )
};

const colourStyles = {
    option: (styles, { data, isDisabled, isFocused, isSelected, invalid }) => {
        let color_texto = 'black';

        if(data && data.label.includes('Create "')){
            color_texto = 'red';
        }

        const color = data.value;
        return {
            ...styles,
            control: styles => ({ ...styles, }),
            color: color_texto,
            //color: isDisabled ? 'white' : isSelected ? 'white' : isFocused ? 'black' : data.value,
            cursor: isDisabled ? 'not-allowed' : 'default',
            ':active': {
                ...styles[':active'],
                backgroundColor: !isDisabled && (isSelected ? data.value : color),
            },
        };
    }
};

export const renderSearchCreateSelect = ({ input, isDisabled, autofocus, loadOptions, valueKey, labelKey, addClass, meta: { touched, error } }) => {
    const invalid = touched && error;
    return (
        <div>
            <AsyncCreatable
                isDisabled={isDisabled}
                value={input.value} className={classNames('form-control p-0 border-trasparent', { 'is-invalid': invalid }, addClass)}
                onChange={(e) => {
                    input.onChange(e ? e : null);}
                }
                classNamePrefix="react-select"
                cacheOptions
                defaultOptions

                theme={(theme) => {
                    return {
                        ...theme,
                        borderRadius: '1em',
                        background: 'white',
                        colors: {
                            ...theme.colors,
                            text: '#415362',
                            primary25: '#ebebeb',
                            primary: '#a8ba3a',
                        },
                    }
                }}
                autoFocus={autofocus ? true : false}
                styles={colourStyles}
                // getOptionValue={(option) => (option[valueKey])}
                // getOptionLabel={(option) => (option[labelKey])}
                searchPromptText="Escriba para buscar" valueKey={valueKey} labelKey={labelKey}
                loadOptions={loadOptions}
                promptTextCreator={(label) => {
                    console.log("Ejecutando crear");
                    return `Crear opción ${label}` }} />
            {invalid && (
                <div className="invalid-feedback">
                    {error}
                </div>
            )}
        </div>
    )
};

export const renderSelectCustom = ({ input, disabled, options, autofocus, defaultValue, valueKey, labelKey, meta: { touched, error } }) => {
    const invalid = touched && error;
    return (
        <div className='border-trasparent' >
            <Select
                isDisabled={disabled}
                value={input.value} className={classNames('form-control p-0 border-trasparent', { 'is-invalid': invalid })}
                onChange={(e) => {
                    input.onChange(e ? e : null)

                }}
                autoFocus={autofocus ? true : false}
                classNamePrefix="react-select"

                defaultOptions

                theme={(theme) => {
                    return {
                        ...theme,
                        borderRadius: '1em',
                        background: 'white',
                        colors: {
                            ...theme.colors,
                            text: '#415362',
                            primary25: '#ebebeb',
                        primary: '#0a5995',
                        },
                    }
                }}
                // getOptionValue={(option) => (option[valueKey])}
                // getOptionLabel={(option) => (option[labelKey])}
                searchPromptText="Escriba para buscar" valueKey={valueKey} labelKey={labelKey}
                options={options}
                promptTextCreator={(label) => {
                    return `Crear opción ${label}` }} />
            {invalid && <div className="invalid-feedback">
                {error}
            </div>}
        </div>
    )
};

export const renderSelectCustomSmall = ({ input,cambio, disabled, options, addClass, valueKey, labelKey, meta: { touched, error } }) => {
    const invalid = touched && error;
    return (
        <div className='border-trasparent' >
            <Select
                isDisabled={disabled}
                value={input.value} className={classNames('appointment-hour-input p-0 border-trasparent', { 'is-invalid': invalid }, addClass)}
                onChange={(e) => {
                    input.onChange(e ? e : null);
                    if (cambio) cambio(e ? e : null);
                }}
                classNamePrefix="react-select"
                defaultOptions
                theme={(theme) => {
                    return {
                        ...theme,
                        borderRadius: '1em',
                        background: 'white',
                        colors: {
                            ...theme.colors,
                            text: '#415362',
                            primary25: '#ebebeb',
                        primary: '#0a5995',
                        },
                    }
                }}
                // getOptionValue={(option) => (option[valueKey])}
                // getOptionLabel={(option) => (option[labelKey])}
                searchPromptText="Escriba para buscar" valueKey={valueKey} labelKey={labelKey}
                options={options}
                promptTextCreator={(label) => {
                    return `Crear opción ${label}` }} />
            {invalid && <div className="invalid-feedback">
                {error}
            </div>}
        </div>
    )
};

export const renderSelectField = ({ input, label, type, meta: { touched, error, warning } }) => {
    const invalid = touched && error;
    return (
        <div>
            <select {...input}
                disabled={disabled}
                key={ key }
                className={classNames('form-control', { 'is-invalid': invalid })}>
                <option  value="" className="select-text-default" style={{color: '#918080'}}>
                    Seleccione
                </option>);
                {options.map((opcion, index) => {
                    return (<option
                        key={typeof (opcion) === "string"+ key? opcion : String(opcion[valueKey])+String(key)}
                        value={typeof (opcion) === "string" ? opcion : opcion[valueKey]}>
                        {typeof (opcion) === "string" ? opcion : opcion[labelKey]}

                    </option>);
                })}
            </select>
            {invalid && <div className="invalid-feedback">
                {error}
            </div>}
        </div>
    )
};

export const renderSelectField2 = ({
    input,
    disabled,
    options,
    required,
    item_vacio,
    meta: { touched, error },
}) => {
    const invalid = touched && error;
    return (
        <React.Fragment>
            <select
                {...input}
                disabled={disabled}
                required={required}
                className={classNames("form-control", {
                    "is-invalid": invalid,
                })}
            >
                {item_vacio && <option hidden selected>Selecciona una opción</option>}

                {options.map((opcion) => {
                    return (
                        <option
                            key={
                                typeof opcion === "string" ? opcion : opcion.id
                            }
                            value={
                                typeof opcion === "string"
                                    ? opcion
                                    : opcion.value
                            }
                        >
                            {typeof opcion === "string" ? opcion : opcion.label}
                        </option>
                    );
                })}
            </select>
            {invalid && <div className="invalid-feedback d-block">{error}</div>}
        </React.Fragment>
    );
};



export const renderNumber = ({ input, label, type, decimalScale, meta: { touched, error } }) => {
    const invalid = touched && error;
    return (
        <div>
            <NumberFormat className={classNames('form-control', { 'is-invalid': invalid })}
                decimalScale={decimalScale ? decimalScale : 0} fixedDecimalScale={true}
                value={input.value} thousandSeparator={true} prefix={''}
                onValueChange={(values) => {
                    input.onChange(values.value);
                }}
            />
            {invalid && <div className="invalid-feedback">
                {error}
            </div>}
        </div>
    )
};

export const dateInput = ({ input, label, type, autofocus, isDisabled, meta: { touched, error } }) => {
    const invalid = touched && error;
    return (
        <div>
            <NumberFormat format="##-##-####" placeholder="DD-MM-YYYY" mask={['D', 'D', 'M', 'M', 'Y', 'Y', 'Y', 'Y']}
                className={classNames('form-control', { 'is-invalid': invalid })}
                value={input.value}
                disabled={!!isDisabled ? true: false}
                onValueChange={(values) => {
                    const dia = values.formattedValue.indexOf("D")
                    const mes = values.formattedValue.indexOf("M")
                    const anio = values.formattedValue.indexOf("Y")

                    if((dia + mes + anio) < 0){
                        input.onChange(values.formattedValue);
                    }
                }}

                autoFocus={autofocus ? true : false}
                onFocus={(e) => {
                    e.target.setSelectionRange(0,10);
                }}
            />

            {invalid && <div className="invalid-feedback">
                {error}
            </div>}
        </div>
    )
};

export const renderNoAsyncSelectField = ({ input, prerequisitos_list,  clearable, disabled, options, valueKey,isMulti, labelKey, meta: { touched, error } }) => {
    const invalid = touched && error;
    return (
        <div>
            <Select clearable={clearable} isDisabled={disabled}
                // value={input.value}
                value={ options.find(o => o[valueKey]  === input.value)}
                className={classNames('form-control p-0 ', { 'is-invalid': invalid })}
                   isMulti={ isMulti }
                   onChange={(e) => {
                        input.onChange(e ? e.value : null);
                    }}

                    theme={(theme) => {
                        return {
                      ...theme,
                      borderRadius: '1em',
                            background: 'white',
                      colors: {
                      ...theme.colors,
                        text: '#415362',
                        primary25: '#ebebeb',
                        primary: '#a8ba3a',
                      },
                    }
                    }}

                    classNamePrefix="react-select"

                   cache={false} placeholder="Escriba para buscar"
                   key={valueKey}
                   defaultOptions
                   options={ options }
                //    defaultOptions
                   searchPromptText="Escriba para buscar"
                    getOptionValue={(option) => (option[valueKey])}
                    getOptionLabel={(option) => (option[labelKey])}/>
            {invalid && <div className="invalid-feedback">
                {error}
            </div>}
        </div>
    )
};

export const render = ({ input, prerequisitos_list,  clearable, disabled, options, valueKey,isMulti, labelKey, meta: { touched, error } }) => {
    const invalid = touched && error;
    return (
        <div>
            <Select
            clearable={clearable} isDisabled={disabled} value={input.value}
            className={classNames('form-control p-0 select-reset', { 'is-invalid': invalid }, addClass,
            {'disabled': disabled})}
                   isMulti={ isMulti }
                   onChange={(e) => {
                        input.onChange(e ? e : null);
                    }}
                    theme={(theme) => {
                        return {
                      ...theme,
                      colors: {
                      ...theme.colors,
                        'neutral0': "#FFFFFF",
                        text: '#415362',
                        primary25: '#ebebeb',
                        primary: '#a8ba3a',
                      },
                    }
                    }}

                    classNamePrefix="react-select"
                   cache={false} placeholder="Escriba para buscar"
                   key={valueKey}
                   defaultOptions
                   options={ options }
                //    defaultOptions
                   searchPromptText="Escriba para buscar"
                    getOptionValue={(option) => (option[valueKey])}
                    getOptionLabel={(option) => (option[labelKey])}/>
            {invalid && <div className="invalid-feedback">
                {error}
            </div>}
        </div>
    )
};

export const renderCurrency = ({ input, label, autofocus, disabled, addClass, functionChange, type, meta: { touched, error } }) => {
    const invalid = touched && error;
    return (
          <div className={classNames({ 'is-invalid': invalid })}>
            <NumberFormat
                disabled={disabled}
                className={classNames('form-control', { 'is-invalid': invalid }, addClass)}
                decimalScale={2} fixedDecimalScale={true}
                value={input.value} thousandSeparator={true} prefix={'Q '}
                onValueChange={(values) => {
                    const {floatValue} = values;
                    input.onChange(values.value);

                    if(functionChange){
                        functionChange(floatValue);
                    }
                }}
                autoFocus={autofocus ? true : false}
            />
            {invalid && <div className="invalid-feedback">
                {error}
            </div>}
        </div>
    )
};

export const modalCurrency = ({ input, initialValue=undefined, className, customChange, disabled, addClass, type, meta: { touched, error } }) => {
    const invalid = touched && error;
    return (
          <div className={classNames({ 'is-invalid': invalid })}>
            <NumberFormat
                disabled={disabled}
                className={classNames(className, { 'is-invalid': invalid }, addClass)}
                decimalScale={2} fixedDecimalScale={true}
                value={initialValue!==undefined?initialValue:input.value} thousandSeparator={true} prefix={'Q '}
                onValueChange={(values) => {
                    input.onChange(values.value);
                    customChange && customChange(values.value)
                }}
                autoFocus
            />
            {invalid && <div className="invalid-feedback">
                {error}
            </div>}
        </div>
    )
};

export const renderSwitch = ({ input, disabled, label, type, meta: { touched, error } }) => {
    const invalid = touched && error;
    return (
        <div>
            <Switch
                onColor="#0a5995"
                offColor="#ccc"
                onChange={(value) => {
                    input.onChange(value);
                }}

                disabled={disabled}
                checked={input.value ? input.value : false}
                id="normal-switch"
            />
            {invalid && <div className="invalid-feedback">
                {error}
            </div>}
        </div>
    )
};

export const renderFileUpload = ({className, placeholder, input, label, meta: { touched, error } }) => {
    const invalid = touched && error;
    return (
        <div>
            <input
                className={classNames('form-control', { 'is-invalid': invalid })}
                placeholder={placeholder}
                type="file"
                accept=".csv"
                onChange={(e, file) => {
                file = file || e.target.files[0];
                const reader = new FileReader();
                reader.onload = (e) => {
                    input.onChange(reader.result);
                };
                reader.readAsDataURL(file);
            }}/>
            {invalid && <div className="invalid-feedback">
                {error}
            </div>}
        </div>
    )
};

export const renderTimeField = ({ required, name, className, input, label, disabled, type, meta: { touched, error } }) => {
    const invalid = touched && error;
    return (
        <div className={classNames(`${className}`, { 'is-invalid': invalid })}>
            <TimePicker
                maxDetail="minute"
                locale="en-US"
                disableClock={ true }
                clearIcon={ null }
                name={ name }
                value={ input.value }
                onChange={(value) => {
                    input.onChange(value);
                }}/>
            {invalid && <div className="invalid-feedback" >
                {error}
            </div>}
        </div>
    )
};

export const renderAntTimeField = ({ hour_value, required, name, className, input, label, disabled, type, meta: { touched, error } }) => {
    const invalid = touched && error;
    const format = 'HH:mm';
    return (
        <div className={classNames(`${className}`, { 'is-invalid': invalid })}>
            <AntTimePicker
                defaultValue={input.value || moment('12:08', format)}
                // defaultValue={moment('12:08', format)}
                format={format}
                className="custom-ant-time-picker"
                onChange={input.onChange}
                value={input.value}
                allowClear={false}
            />
            {invalid && <div className="invalid-feedback" >
                {error}
            </div>}
        </div>
    )
};

export const renderPercentage = ({ input, label, disabled, addClass, type, meta: { touched, error } }) => {
    const invalid = touched && error;
    return (
          <div className={classNames({ 'is-invalid': invalid })}>
            <NumberFormat
                disabled={disabled}
                className={classNames('form-control', { 'is-invalid': invalid }, addClass)}
                decimalScale={2} fixedDecimalScale={true}
                value={input.value} thousandSeparator={true} suffix={' %'}
                onValueChange={(values) => {
                    input.onChange(values.value);
                }}
            />
            {invalid && <div className="invalid-feedback">
                {error}
            </div>}
        </div>
    )
};

export const renderImagePicker = ({photo, setFile, className, placeholder, disabled, input, label, meta: {touched, error}}) => {
    const invalid = touched && error;
    return (
    <div className={classNames(`${className}`, { 'is-invalid': invalid })}>
        <FileUploader
            disabled={disabled}
            img= {!!photo ? photo : null}
            onFileChange={(e, file) => {
                file = file || e.target.files[0];
                const reader = new FileReader();
                reader.onload = (e) => {
                    input.onChange(reader.result);
                    if (!!setFile) {
                        setFile(file);
                    }
                };
                reader.readAsDataURL(file);
            }} />
        {invalid && <div className="invalid-feedback">
            {error}
        </div>}
    </div>
    );
}

export const renderMyUpload = ({photo, setFiles, multiple, className, placeholder, disabled, input, label, meta: {touched, error}}) => {
    const invalid = touched && error;
    return (
    <div className={classNames(`${className}`, { 'is-invalid': invalid })}>
        <MyUpload
            disabled={disabled}
            img={!!photo ? photo : null}
            handleChange={(archivos) => {
                setFiles(archivos);
            }}
            removeFile={(archivos) => {
                setFiles(archivos);
            }}
            multiple={multiple ? multiple : false}
            />
        {invalid && <div className="invalid-feedback">
            {error}
        </div>}
    </div>
    );
}

export const RenderField = {
    renderField,
    renderFieldDateFormat,
    renderFieldFormat,
    renderFieldCheck,
    renderTextArea,
    renderSearchSelect,
    renderSearchCreateSelect,
    renderSelectField,
    renderSelectField2,
    renderNumber,
    renderCurrency,
    renderSwitch,
    renderNoAsyncSelectField,
    modalTextArea,
    modalCurrency,
    renderMyUpload,
    renderRadioInput,
    dateInput,
    renderFieldDPI,
    renderFieldNIT,
};


/*temp1.filter(e=>{return e.value != 3})*/
