import { api } from 'api';
import Swal from 'sweetalert2';
import { push } from 'react-router-redux';
import { initialize as initializeForm } from 'redux-form';
import { createActions, handleActions } from 'redux-actions';

import { actions as actions_sale } from './sales';

const ADD_PAYMENT_DESCRIPTION = 'ADD_PAYMENT_DESCRIPTION';

const groupBy = (xs, f) => {
    return xs.reduce((r, v, i, a, k = f(v)) => ((r[k] || (r[k] = [])).push(v), r), {});
}

export const chargePayments = (id_movement) => (dispatch) => {
    api.get("movements/getMovementPayment", {id_movement}).catch((error) => {
        console.error("Error: ", error);
    }).then((data) => {
        //const result = groupBy(data, (a) => a.payment_type);
        //Agregado para que reconozca los pagos iniciales al crear la cuenta al credito
        let tarjetas=[]; let cuotas = []; let depositos = [];
        data.tarjeta.forEach(pay =>{
            data.datos.push({
                id: pay.id,
                payment: pay.quantity,
                made: pay.created,
                payment_type: 'Tarjeta de crédito',
                movement_ref: pay.movement,
                anticipo: true,
                tipo_pago: 'tarjeta',
            });
        });

        data.cuotas.forEach(pay =>{
            data.datos.push({
                id: pay.id,
                payment: pay.quantity,
                made: pay.created,
                payment_type: 'Cuotas',
                movement_ref: pay.movement,
                anticipo: true,
                tipo_pago: 'cuota',
            });
        });

        data.depositos.forEach(pay =>{
            data.datos.push({
                id: pay.id,
                payment: pay.quantity,
                made: pay.created,
                payment_type: 'Deposito',
                movement_ref: pay.movement,
                anticipo: true,
                tipo_pago: 'deposito',
            });
        });

        console.log("data: ", data);
        const result = groupBy(data.datos, (a) => a.payment_type);
        //...
        const payment = {id_movement, result};
        dispatch({type: ADD_PAYMENT_DESCRIPTION, payment });
    })
}

export const cancelPayment = (id_movement, codigo=null, referencia_retorno) => (dispatch) => {

    console.log("REFERENCIA_RETORNO: ", referencia_retorno);

    Swal.fire({
        title: '¿Está seguro(a) que desea anular el pago?',
        type: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#aaa',
        confirmButtonText: 'Aceptar',
        cancelButtonText: 'Cancelar',
        reverseButtons: true
    }).then((result) => {
        if (result.value) {
            api.post("movements/cancelPayment", {id_movement}).then((data) => {


                Swal('Éxito', 'Pago anulado correctamente. ' + data.detail , 'success')
                .then(() => {
                    //console.log("El código es: ", codigo);
                    //console.log("La referencia de retorno: ", referencia_retorno);
                    //En base a la referencia de retorno se sabrá si retornar a la tabla de pagos en cuenta abierta (actualmente),
                    //movimientos o cuentas por cobrar...

                    if(referencia_retorno.includes('deposit') === true){
                        dispatch(actions_sale.openAccount(codigo, false));
                    }else{
                        console.log("Aparece caja en la ruta retorno");
                        dispatch(push(referencia_retorno));
                    }
                })
            }).catch((error) => {
                Swal(
                    'Error',
                     error.detail || 'Ha ocurrido un error, por favor vuelva a intentar.',
                    'error'
                );
            })
        }
    });
}

//Para anular los pagos por anticipo de cuentas por pagar (Pagos iniciales que no aparecían al principio.)
export const cancelPaymentFix = (id_pago, codigo=null, referencia_retorno, id_movimiento, tipo_pago) => (dispatch) => {
    console.log("REFERENCIA_RETORNO: ", referencia_retorno);

    Swal.fire({
        title: '¿Está seguro(a) que desea anular el pago?',
        type: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#aaa',
        confirmButtonText: 'Aceptar',
        cancelButtonText: 'Cancelar',
        reverseButtons: true
    }).then((result) => {
        if (result.value) {
            api.post("movements/cancelPaymentFix", {id_pago, id_movimiento, tipo_pago}).then((data) => {


                Swal('Éxito', 'Pago anulado correctamente. ' + data.detail , 'success')
                .then(() => {
                    //console.log("El código es: ", codigo);
                    //console.log("La referencia de retorno: ", referencia_retorno);
                    //En base a la referencia de retorno se sabrá si retornar a la tabla de pagos en cuenta abierta (actualmente),
                    //movimientos o cuentas por cobrar...

                    if(referencia_retorno.includes('deposit') === true){
                        dispatch(actions_sale.openAccount(codigo, false));
                    }else{
                        console.log("Aparece caja en la ruta retorno");
                        dispatch(push(referencia_retorno));
                    }
                })
            }).catch((error) => {
                Swal(
                    'Error',
                     error.detail || 'Ha ocurrido un error, por favor vuelva a intentar.',
                    'error'
                );
            })
        }
    });
}

export const actions = {
    chargePayments,
    cancelPayment,
    cancelPaymentFix,
};

export const reducer = {
    [ADD_PAYMENT_DESCRIPTION]: (state, { payment }) => {
        return{
            ...state,
            data_payments: payment
        }
    },
};

export const initialState = {
    data_payments: null,
};

export default handleActions(reducer, initialState)
