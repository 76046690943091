import { handleActions } from 'redux-actions';
import Swal from 'sweetalert2';
import moment from 'moment';
import { api } from 'api';
import { push } from 'react-router-redux';

// -----------------------------------
// Constants
// -----------------------------------
const URL_ENDPOINT = 'dashboard';
const STORE_ID = 'dashboard';
const LOADER = 'DASHBOARD_LOADER';
const DATA   = 'DASHBOARD_DATA';
const ENABLE_TO_GENERATE = 'const ENABLE_TO_GENERATE';
// -----------------------------------
// Pure Actions
// -----------------------------------
const setLoader = loader => ({
    type: LOADER,
    loader,
});

const setData = data => ({
    type: DATA,
    data,
});

const setEnableToGenerate = enable_to_generate => ({
    type: ENABLE_TO_GENERATE,
    enable_to_generate
})

// -----------------------------------
// Actions
// -----------------------------------
const getDashboardData = () =>  (dispatch, getStore) => {
    const store = getStore()[STORE_ID];
    const { today_date, week_start_date, week_end_date, month_start_date, month_end_date, actual_year } = store;
    let params = {
        today_date: today_date.format('DD/MM/YYYY'),
        week_start_date: week_start_date.format('DD/MM/YYYY'),
        week_end_date: week_end_date.format('DD/MM/YYYY'),
        month_start_date: month_start_date.format('DD/MM/YYYY'),
        month_end_date: month_end_date.format('DD/MM/YYYY'),
        years_of_income: [actual_year-1, actual_year],
    }; 
    dispatch(setLoader(true));
    //api.get(`${URL_ENDPOINT}/get_dashboard_data`, params).then(response => {
    api.get(`${URL_ENDPOINT}/generate_dashboard_data`, params).then(response => {
        Swal('Éxito', response.message, 'success').then(()=>{
            dispatch(push('/tasks'));
        })                
    }).catch((error) => {
        dispatch({type: LOADER, loader: false});
        Swal('Error', error.detail || 'Ocurrió un error al intentar obtener la información para el Dashboard.', 'error');
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

const getDashboardDataCharged = () => (dispatch, getStore) => {
    const store = getStore()[STORE_ID];
    dispatch(setLoader(true));
    
    api.get(`${URL_ENDPOINT}/get_dashboard_data`, {}).then(response => {        
        if(response.message && response.message == "Generando el dashboard"){
            Swal('Información', 'El reporte aún se está generando, puede ver en la sección de tareas el estado del reporte', 'info').then(()=>{
                dispatch(push('/tasks'));
            })  
        }else{
            dispatch(setData(response));            
            dispatch(setEnableToGenerate(false));
        }        
    }).catch((error) => {        
        dispatch(setEnableToGenerate(true));
        //dispatch({type: LOADER, loader: false});
        //Swal('Error', error.detail || 'Ocurrió un error al intentar obtener la información para el Dashboard.', 'error');
    }).finally(() => {
        dispatch(setLoader(false));
    });   
}

const getIgssAfilation = () => (dispatch, getStore) => {
    const store = getStore()[STORE_ID];
    dispatch(setLoader(true));
    
    api.get(`${URL_ENDPOINT}/get_igss_member`, {}).then(response => {        
        console.log("responsito ::: ::: ", response);
    }).catch((error) => {        
        console.log("errorcito ::: ::: ", error);
    }).finally(() => {
        dispatch(setLoader(false));
    });   
}

export const actions = {
    getDashboardData,
    getDashboardDataCharged,
    getIgssAfilation
};

// -----------------------------------
// Reducers
// -----------------------------------
export const reducer = {
    [LOADER]: (state, { loader }) => {
        return {
            ...state,
            loader
        }
    },
    [ENABLE_TO_GENERATE]: (state, { enable_to_generate }) => {
        return {
            ...state,
            enable_to_generate
        }
    },
    [DATA]: (state, { data }) => {
        return {
            ...state,
            data
        }
    },
};

export const initialState = {
    enable_to_generate: false,
    loader: false,
    today_date: moment(),
    week_start_date: moment().startOf('isoWeek'),
    week_end_date: moment().endOf('isoWeek'),
    month_start_date: moment().startOf('month'),
    month_end_date: moment().endOf('month'),
    actual_year: moment().year(),
    data: {
        utilities_by_product_category: [],
        best_referents: [],
        higher_accounts_payable: [],
        higher_accounts_receivable: [],
        total_income_and_expenses: {},
        income_by_years: {},
        products_with_alert: [],
        average_times_by_consult: {},
        average_times_by_procedure: {},
    },
};

export default handleActions(reducer, initialState)
