import React, { Component } from 'react';
import "./toolbar.css";
import SearchChange from "./SearchChange";
import DatePicker from '../../Utils/DatePicker/datePickerCustom';

export default class CustomToolbar extends Component {
    render() {
        return (
            <div className="w-100 d-inline-flex justify-content-between" id={this.props.id}>
                {this.props.titulo &&
                    <div className="col-md-6 row">
                        <div className="titulo d-flex align-items-center">
                            <span className="ml-2 text-uppercase text-titulo"><strong>{this.props.titulo}</strong></span>
                        </div>
                    </div>
                }
                {this.props.date && typeof(this.props.date) !== 'object' &&
                    <DatePicker
                        isDisabled={false}
                        value={this.props.buscador_fecha}
                        //maxDate={maxDate}
                        //minDate={minDate}
                        onChange={(date) => {
                            this.props.buscarFecha(date);
                        }}
                    />
                }
                <div className="text-left search">
                    {(this.props.buscar !== undefined) && (
                        <SearchChange 
                            buscar={this.props.buscar} 
                            buscador={this.props.buscador}
                            autofocus={this.props.autofocus}
                            date={this.props.date}
                        />
                    )}
                </div>
            </div>
        )
    }
}
