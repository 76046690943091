import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Modal, ModalBody, ModalHeader, ModalFooter } from 'reactstrap';

import Form from './PendingForm';
import Table from '../Utils/Grid';
import { BreakLine } from '../Utils/tableOptions';
import HeaderSimple from '../Utils/Header/HeaderSimple';
import ToolbarSimple from '../Utils/Toolbar/ToolbarSimple';
import { activeFormatter }  from '../Utils/Acciones/Acciones';
import { INCOME, EXPENDITURY } from '../../../redux/modules/banks';

function formatSubsidairy(fact){
    if (fact == INCOME){
        return 'INGRESO'
    } else {
        return 'EGRESO'
    }
}

function formatAmount(cell, row){
    if (row.transaction_type == INCOME){
        return 'Q. ' + row.debe
    } else if (row.transaction_type == EXPENDITURY) {
        return 'Q. ' + row.haber
    }
}

class Pending extends Component {
    componentWillUnmount() {
        this.props.resetStore();
    }
    state = {
        isOpen: false,
        pendingTransaction: 'Ingreso',
    }

    componentWillMount() {
        this.props.listar()
    }

    toggleModal = () => {
        this.setState((prevState) => ({ isOpen: !prevState.isOpen }));
    }

    pendigForm = (cell, row) => {
        return (
            <img
                onClick={() => {
                    this.props.detail(cell).then(() => {
                        this.toggleModal(cell);
                        if (row.transaction_type == INCOME){
                            this.setState({
                                pendingTransaction: 'Ingreso'
                            })
                        } else if (row.transaction_type == EXPENDITURY) {
                            this.setState({
                                pendingTransaction: 'Egreso'
                            })
                        }
                    })
                }}
                className="action-img set-account" title="Execute" src={require("../../../../assets/img/icons/set-account.png")} alt="Execute"
            />
        )
    }

    render() {
        const {
            listar,
            detail,
            create_transaction,
            update,
            destroy,
            cargando,
            page,
            data,
            isNavBarOpen,
        } = this.props;

        return (
            <div className={classNames('content-with-tabs', { 'content-with-tabs-min': isNavBarOpen })}>
                <div className="d-inline-flex w-100">
                    <div className="row-fluid w-50 tab-background">
                        <a href="/#/banks" className="d-flex justify-content-center align-items-center tab tab-br-br">
                            LISTADO
                        </a>
                    </div>
                    <div className="row-fluid w-50 tab-background-blue">
                        <a href="/#/pending" className="d-flex justify-content-center align-items-center tab-active tab-br-tl">
                            PENDIENTES
                        </a>
                    </div>
                </div>

                <div className="px-3 py-4 row-fluid w-100">
                    {/* <HeaderSimple
                        texto={"Agregar número de cuenta"}
                        ruta={"/bank-accounts/create"}
                    /> */}
                    <div className="table-decoration">
                        <div className="top-title">
                            LISTADO DE CUENTAS BANCARIAS
                        </div>
                        <Table
                            onPageChange={listar}
                            data={data}
                            loading={cargando}
                            page={page}
                        >
                            <TableHeaderColumn
                                tdStyle={BreakLine}
                                thStyle={BreakLine}
                                width={'15%'}
                                dataField="date" dataSort>Fecha y hora</TableHeaderColumn>
                                <TableHeaderColumn
                                tdStyle={BreakLine}
                                thStyle={BreakLine}
                                dataFormat= {formatSubsidairy}
                                dataField="transaction_type" dataSort>Tipo</TableHeaderColumn>
                            <TableHeaderColumn
                                tdStyle={BreakLine}
                                thStyle={BreakLine}
                                width={'50%'}
                                dataField="transanction_justification" dataSort>Descripción</TableHeaderColumn>
                            <TableHeaderColumn
                                tdStyle={BreakLine}
                                thStyle={BreakLine}
                                headerAlign={'center'}
                                dataAlign={'right'}
                                dataFormat={formatAmount}
                                dataField="amount" dataSort>Monto</TableHeaderColumn>
                            <TableHeaderColumn
                                dataField="id"
                                isKey={true}
                                dataAlign="center"
                                dataFormat={this.pendigForm}>Acciones</TableHeaderColumn>
                        </Table>
                        <Modal isOpen={this.state.isOpen} size={'lg'}>
                            <ModalHeader>{this.state.pendingTransaction} pendiente</ModalHeader>
                                <ModalBody>
                                    <Form onSubmit={update}></Form>
                                </ModalBody>
                            <ModalFooter className="d-flex justify-content-center text-center">
                                <button className="btn btn-secondary btn-modal" onClick={this.toggleModal}>Cancelar</button>
                                <button type="submit" onClick={() => {this.toggleModal()}} className="btn btn-primary btn-modal" form="pending_transaction_form">Aceptar</button>
                            </ModalFooter>
                        </Modal>
                    </div>
                </div>
            </div>
        )
    }
}

export default Pending;
