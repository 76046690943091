import React, { Component } from 'react';
import classNames from 'classnames';

import Table from '../Utils/Grid';
import { BreakLine } from '../Utils/tableOptions';
import HeaderSimple from '../Utils/Header/HeaderSimple';
import { activeFormatter } from '../Utils/Acciones/Acciones';
import { showNestedFields } from '../Utils/MyOptions';

function formatBoolean(cell) {
    return (cell == true) ? 'Sí' : 'No';
}
class ReferentCategory extends Component {
    componentWillMount() {
        this.props.listar();
    }
    componentWillUnmount() {
        this.props.resetStore();
    }

    render() {
        const {
            page,
            data,
            listar,
            detail,
            create,
            update,
            destroy,
            cargando,
            isNavBarOpen,
        } = this.props;

        return (
            <div className={classNames('content', { 'content-min': isNavBarOpen })}>
                <div className="m-auto row-fluid w-100">
                    <HeaderSimple
                        texto="Crear categoría de referentes"
                        ruta="/referent-categories/create"
                    />
                    <div className="table-decoration">
                        <div className="top-title">
                            LISTA DE CATEGORIAS
                        </div>
                        <Table
                            onPageChange={listar}
                            data={data}
                            loading={cargando}
                            page={page}
                        >
                            <TableHeaderColumn
                                tdStyle={BreakLine}
                                thStyle={BreakLine}
                                dataField="name"
                                dataSort
                            >
                                Nombre
                            </TableHeaderColumn>
                            <TableHeaderColumn
                                tdStyle={BreakLine}
                                thStyle={BreakLine}
                                dataField="description"
                                dataSort
                            >
                                Descripción
                            </TableHeaderColumn>

                            <TableHeaderColumn
                                tdStyle={BreakLine}
                                thStyle={BreakLine}
                                dataField="financial_entity"
                                formatExtraData="financial_entity__usuario_fel"
                                dataFormat={showNestedFields}
                                dataSort
                            >
                                Ente Facturador (SAT)
                            </TableHeaderColumn>

                            <TableHeaderColumn
                                tdStyle={BreakLine}
                                thStyle={BreakLine}
                                dataFormat={formatBoolean}
                                dataField="issue_receipt"
                                dataSort
                            >
                                Emite Recibo
                            </TableHeaderColumn>

                            <TableHeaderColumn
                                isKey
                                dataField="id"
                                dataAlign="center"
                                dataFormat={activeFormatter({ ver: '/referent-categories', editar: '/referent-categories', eliminar: destroy })}
                            >
                                Acciones
                            </TableHeaderColumn>
                        </Table>
                    </div>
                </div>
            </div>
        );
    }
}

export default ReferentCategory;
