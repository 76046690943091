import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Redirect } from 'react-router';
import classNames from 'classnames';

import PatientForm from './PatientForm';
import Table from '../Utils/Grid';
import ToolbarChange from '../Utils/Toolbar/ToolbarChange';
import { BreakLine } from '../Utils/tableOptions';
import { RenderCurrency } from '../Utils/renderField/renderReadField';
import SearchSelect from '../Utils/Toolbar/Select';

/*import './tab.css';*/

const options = [
    {value: 0, label: "Todas"},
    {value: 1, label: 'Cuenta'},
    {value: 2, label: 'Cirugía'},
    {value: 3, label: 'Diagnóstico'},
    {value: 4, label: 'Excluir IGSS'}
];
class Sales extends Component {

    state = {
        redirect: true
    }

    componentWillMount = () => {
        this.props.getDefaultEntity();
        this.props.setDataSaleNoPatient();
        this.props.setDefaultValueExtraAmount();
    }

    componentDidMount = () => {
        if(this.props.patientInfo.referent !== null && this.props.isServiceRoute === true){
            this.props.setRutaAnterior();
            this.setState({redirect: false});

            this.props.isOpenAccount(false);
        }

        //Se envía true para que al terminar de checkear la caja cargue las cuentas abiertas
        this.props.checkCashBox(true);

        /*
        setTimeout(() => {
            if(this.props.openCashBox !== null && this.props.openCashBox !== undefined){
                this.props.getOpenAccounts();
            }
        }, 700);
        */
    }

    render() {
        const {
            getOpenAccounts,
            cancelOpenAccount,
            page_open_accounts,
            charge_open_account,
            searchOpenAccounts,
            buscador_cuenta_abierta,
            openCashBox,
            patientInfo,
            open_accounts,
            accout_referent_filter,
            changeReferentFilter,
            openAccount,
            findNit,
            setPaciente,
            cancelSale1,
            dataPatient,
            isNavBarOpen,
        } = this.props;

        return (

            <React.Fragment>
            {(patientInfo.referent !== null && this.props.isServiceRoute === false && this.state.redirect) ?
                <React.Fragment>
                    <Redirect to="oppening-closing/sales"/>
                </React.Fragment>
                :
                <React.Fragment>
                {openCashBox !== null && openCashBox.isOpen === false &&
                    <Redirect to="oppening-closing/box"/>
                }
                </React.Fragment>
            }

            <div className={classNames('content-with-tabs', { 'content-with-tabs-min': isNavBarOpen })}>
                <div className="d-inline-flex w-100">
                    <div className="row-fluid w-50 tab-background-blue">
                        <a href="/#/oppening-closing" className="d-flex justify-content-center align-items-center tab-active tab-br-tr">
                            VENTA
                        </a>
                    </div>
                    <div className="row-fluid w-50 tab-background">
                        <a href="/#/oppening-closing/box" className="d-flex justify-content-center align-items-center tab tab-br-bl">
                            CAJA
                        </a>
                    </div>
                </div>

                <div className="table-decoration row mx-5 mt-5 mb-0 d-flex">
                        <div className="row-fluid w-100 mt-2 mb-1">
                                <div className="top-title">
                                     DATOS DEL PACIENTE
                                </div>
                                <PatientForm setPaciente={setPaciente} onSubmit={dataPatient} findNit={findNit} cancelSale1={cancelSale1} />
                                <div className="d-flex flex-row w-100 justify-content-center">
                                    <div className="d-flex justify-content-arouns  flex-column w-85">
                                    {(open_accounts.count > 0 || buscador_cuenta_abierta !== '') &&
                                        <React.Fragment>
                                            <div className="mb-2">
                                                <ToolbarChange  
                                                    titulo="CUENTAS ABIERTAS"
                                                    buscar={searchOpenAccounts}
                                                    buscador={buscador_cuenta_abierta}
                                                    //autofocus
                                                />
                                                
                                            </div> 
                                            
                                            <div style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center', paddingInline: '32px', marginBottom: '16px'}}>
                                                <div
                                                    style={{ 
                                                        display: 'flex', justifyContent: 'center', alignItems: 'center',
                                                        width: 'fit-content',
                                                        gap: '16px'
                                                    }}                                                    
                                                >
                                                    <label
                                                        style={{
                                                            display: "flex",
                                                            width: "100%"
                                                        }}
                                                    >Filtros &nbsp;</label>
                                                    <SearchSelect 
                                                        options={options}
                                                        buscar={changeReferentFilter}
                                                        buscador={accout_referent_filter}
                                                    />             
                                                </div>
                                            </div>
                                            <Table
                                                onPageChange={getOpenAccounts}
                                                data={open_accounts}
                                                loading={charge_open_account}
                                                page={page_open_accounts}
                                            >
                                                <TableHeaderColumn
                                                dataFormat={(cell) => {
                                                    console.log("Tipo procedimiento: ", cell)
                                                    if(cell === 1){
                                                        return (
                                                            <span style={{background:'#7F7F7F', padding:2, color:'white', borderRadius:5, fontSize:11}}>cuenta</span>
                                                        );
                                                    }else if(cell === 2){
                                                        return(
                                                            <span style={{background:'#0a5995', padding:2, color:'white', borderRadius:5, fontSize:11}}>cirugía</span>
                                                        );
                                                    }else if(cell === 3){
                                                        return(
                                                            <span style={{background:'#459058', padding:2, color:'white', borderRadius:5, fontSize:11}}>diagnóstico</span>
                                                        );
                                                    }
                                                }}
                                                dataField="type_procedure" dataSort
                                                width='65'
                                                thStyle={{ whiteSpace: 'normal' }}
                                                ></TableHeaderColumn>

                                                <TableHeaderColumn
                                                    tdStyle={BreakLine}
                                                    thStyle={BreakLine}
                                                    dataField="account"
                                                    dataSort
                                                    dataFormat={(cell, row) => {
                                                        return row.patient.first_name + ' ' + row.patient.last_name;
                                                    }}
                                                    >Paciente</TableHeaderColumn>

                                                <TableHeaderColumn
                                                    tdStyle={BreakLine}
                                                    thStyle={BreakLine}
                                                    dataField="account"
                                                    dataSort
                                                    dataFormat={(cell, row) => {
                                                        return row.referent.name
                                                    }}
                                                    >Referente</TableHeaderColumn>

                                                <TableHeaderColumn
                                                    tdStyle={BreakLine}
                                                    thStyle={BreakLine}
                                                    dataFormat={(cell, row) => {
                                                        const saldo = parseFloat(parseFloat(cell).toFixed(2));
                                                        if(saldo === 0){
                                                            return "Pagado en su totalidad";
                                                        }else{
                                                            return (
                                                                <React.Fragment>
                                                                    <span>Saldo pendiente:</span><br />
                                                                    <RenderCurrency value={saldo}/>
                                                                </React.Fragment>
                                                            );
                                                        }
                                                    }}
                                                    dataField="saldo_pendiente" dataSort>Estado</TableHeaderColumn>

                                                <TableHeaderColumn
                                                    tdStyle={BreakLine}
                                                    thStyle={BreakLine}
                                                    dataField="made"
                                                    width='100'
                                                >Fecha</TableHeaderColumn>

                                                <TableHeaderColumn
                                                    dataField="id"
                                                    isKey={true}
                                                    dataAlign="center"
                                                    dataFormat={(cell, row) => {
                                                        return (
                                                            <React.Fragment>
                                                                <div key={row.codigo ? row.codigo: row.id}>
                                                                    {row.codigo ?
                                                                        <React.Fragment>
                                                                            <span className="account" onClick={()=>openAccount(row.codigo)}>Cargar</span>
                                                                            <span  className="cancel-account" onClick={()=>cancelOpenAccount(row.codigo)}>Anular</span>
                                                                        </React.Fragment>
                                                                        :
                                                                        <React.Fragment>
                                                                            <span className="account" onClick={()=>openAccount(row.id)}>Cargar</span>
                                                                            <span  className="cancel-account" onClick={()=>cancelOpenAccount(row.id)}>Anular</span>
                                                                        </React.Fragment>
                                                                    }
                                                                </div>
                                                            </React.Fragment>
                                                        );
                                                    }}
                                                    >Acciones</TableHeaderColumn>
                                            </Table>
                                        </React.Fragment>
                                    }
                                    </div>
                                 </div>
                        </div>
                 </div>
            </div>
            </React.Fragment>
        )
    }
}

export default Sales;
