import React, {Component} from 'react';
import Modal from 'react-responsive-modal';
import classNames from 'classnames';
import "../notations";


export class NervioOptico extends Component {
    constructor(props) {
        super(props);
        this.state = { open: false};
    }

    openModal = () => {
        this.setState({ open: true });
    };

    closeModal = () => {
        this.setState({ open: false });
    };

    selectOption = (input, value) => {
        input.onChange(value);
        this.closeModal();
    };

    render() {
        const { input, meta: { touched, error }, title, jaeger } = this.props;
        const { open } = this.state;
        const invalid = touched && error;

        let fi = 0.0;
        const ff = 0.5;

        let ci = 0;
        const cf = 2;

        let columnas = [];

        for(fi;fi<=ff;fi=fi+0.1){
            ci = 0;
            let fila = [];
            for(ci;ci<=cf;ci++){
                const cantidad = fi + (ci*0.6);
                fila.push(cantidad.toFixed(2))
            }
            columnas.push(fila);
        }

        return (
            <React.Fragment>
                <Modal open={open} onClose={this.closeModal} >
                    <div className="text-center">
                        <h3 className="border-bottom mt-4">{title}</h3>
                    </div>

                    {/*
                    <div className="text-center">
                        <strong className="">Notaciones equivalentes</strong>
                        <div className="d-flex flex-row justify-content-center my-3">
                            <div className="radio">
                                <label htmlFor="service_type" className="radio-inline mr-5 d-flex align-items-center"
                                       onClick={() => this.selectOption(input, "(NPL)")}>
                                    <input type={"radio"} value={"(NPL)"} {...input} onChange={() => this.selectOption(input, "(NPL)")}
                                           className={classNames('form-control', { 'is-invalid': invalid })} />
                                    &nbsp;(NPL)
                                </label>
                            </div>
                            <div className="radio">
                                <label htmlFor="service_type" className="radio-inline mr-5 d-flex align-items-center"
                                       onClick={() => this.selectOption(input, "(PL)")}>
                                    <input type={"radio"} value={"(PL)"} {...input} onChange={() => this.selectOption(input, "(PL)")}
                                           className={classNames('form-control', { 'is-invalid': invalid })} />
                                    &nbsp;(PL)
                                </label>
                            </div>
                            <div className="radio">
                                <label htmlFor="service_type" className="radio-inline mr-5 d-flex align-items-center"
                                       onClick={() => this.selectOption(input, "(MM)")}>
                                    <input type={"radio"} value={"(MM)"} {...input} onChange={() => this.selectOption(input, "(MM)")}
                                           className={classNames('form-control', { 'is-invalid': invalid })} />
                                    &nbsp;(MM)
                                </label>
                            </div>
                        </div>
                    </div>
                    */}
                    <table id="notations-table-modal">
                        <tr>
                            {/*
                            <th>Decimal</th>
                            <th>US</th>
                            <th>CD</th>
                            <th>X/200</th>
                            {jaeger && <th>JAEGER</th>}
                            */}
                        </tr>
                        <tbody>
                        {columnas.map(columna=>(
                            <tr key={columna}>
                                {columna.map(fila=>(
                                    <React.Fragment key={fila}>
                                    {fila <= 1.0 ?
                                        <td>
                                            <div className="radio">
                                                <label htmlFor="service_type" className="radio-inline mr-5 d-flex align-items-center"
                                                    onClick={() => this.selectOption(input, fila)}>
                                                    <input type={"radio"} value={fila} {...input} onChange={() => this.selectOption(input, fila)}
                                                        className={classNames('form-control', { 'is-invalid': invalid })} />
                                                    &nbsp;{fila}
                                                </label>
                                            </div>
                                        </td>
                                    :
                                        <td>
                                        </td>
                                    }
                                    </React.Fragment>
                                ))}
                            </tr>
                        ))}
                        </tbody>
                    </table>
                    <div className="d-flex justify-content-center align-items-center mt-4">
                        <div
                            className="btn btn-secondary mx-4"
                            onClick={() => {
                                input.onChange(undefined);
                                this.closeModal();
                            }}>
                            Cancelar
                        </div>
                    </div>
                </Modal>
                <span className="form-control text-center" onClick={this.openModal} >{input.value}</span>
            </React.Fragment>
        );
    }
}
