import Form from './ServiceCategoryForm';
import React, { Component } from 'react';
import LoadMask from 'Utils/LoadMask/LoadMask';


export default class ServiceCategoryCrear extends Component {

    state = {
        editar: false,
        mensaje: 'Agregar categoría de producto',
        password: false,
    }
    
    componentWillMount(){
        if(this.props.match.params.id){
            this.props.detail(this.props.match.params.id);
            this.setState({
                    editar: true,
                    mensaje: 'Agregar categoría'
                });
        }
    }

    render() {

        const {
            create,
            update
        } = this.props;

        const {
            updateData,
            cargando
        } = this.props;

        return (
            <div className="content">
                <div className="m-auto row-fluid card-form">
                    <div className="table-decoration">
                        <LoadMask loading={cargando} blur_1>
                            {
                                this.state.editar ?
                                    <div className="fields">
                                        <div className="top-title">
                                            CATEGORIA DE PRODUCO/SERVICIO
                                        </div>
                                        <Form
                                        onSubmit={update}
                                        updateData={updateData}
                                        editando={true} />
                                    </div>
                            :
                                <div className="fields">
                                    <div className="top-title">
                                        CREAR CATEGORIA DE PRODUCTO/SERVICIO
                                    </div>
                                    <Form onSubmit={create}  />
                                </div>
                            }
                        </LoadMask>
                    </div>
                </div>
            </div>
        )
    }
}
