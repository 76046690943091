import React, { Component } from 'react';
import Table from '../Utils/Grid';
import ToolbarChange from '../Utils/Toolbar/ToolbarChange';
import { BreakLine } from '../Utils/tableOptions';
import { RenderCurrency } from '../Utils/renderField/renderReadField';
import { showNestedFields } from '../Utils/MyOptions';
import Select from 'react-select';
import CustomToolbar from '../Utils/Toolbar/CustomToolbar';
import MomentLocaleUtils, {
    formatDate,
    parseDate,
} from 'react-day-picker/moment';
import DayPickerInput from 'react-day-picker/DayPickerInput';
import moment from "moment";
import TableDescription from './TableDescription';
import { Link } from 'react-router-dom';
import '../arqueos/arqueo.css';
import NumberFormat from "react-number-format";

class NewDetalleArqueo extends Component {
      existeFecha(fecha){
        var fechaf = fecha.split("/");
        var d = fechaf[0];
        var m = fechaf[1];
        var y = fechaf[2];
        return m > 0 && m < 13 && y > 1900 && y < 32768 && d > 0 && d <= (new Date(y, m, 0)).getDate();
    }

    componentWillMount = () => {
      this.props.detail();        
    }

    render() {
        const {
            initial_date,
            final_date,
            cargando,
            updateData,
            category_detail,
        } = this.props;

        const {
            openAccount,

            setInitialDate,
            setFinalDate
        } = this.props;
        
        var fecha1 = moment(initial_date, "DD/MM/YYYY");
        var fecha2 = moment(final_date, "DD/MM/YYYY");
        let diferenceDay = 0
        if(fecha1 && fecha2){
          diferenceDay = fecha2.diff(fecha1, 'days');
        }
        
        console.log("diferenceDay ::: ", diferenceDay)
        return (
            <div className="content">              
                <div className="d-flex flex-row justify-content-end align-items-center w-50">
                    <NumberFormat format="##/##/####" placeholder="DD/MM/YYYY" mask={['D', 'D', 'M', 'M', 'Y', 'Y', 'Y', 'Y']}
                        className="form-control w-20"
                        value={initial_date}
                        onValueChange={(values) => {
                            if(this.existeFecha(values.formattedValue)){
                                setInitialDate(values.formattedValue)
                            }
                        }}
                        autoFocus
                        onFocus={(e) => {
                            e.target.setSelectionRange(0,10);
                        }}
                    />

                    <NumberFormat format="##/##/####" placeholder="DD/MM/YYYY" mask={['D', 'D', 'M', 'M', 'Y', 'Y', 'Y', 'Y']}
                        className="form-control w-20 ml-2 mr-2"
                        value={final_date}
                        onValueChange={(values) => {
                            if (this.existeFecha(values.formattedValue)){
                                setFinalDate(values.formattedValue)
                            }

                        }}

                        onFocus={(e) => {
                            e.target.setSelectionRange(0,10);
                        }}
                    />

                    <button 
                      className='btn btn-sm btn-primary'
                      onClick={()=> this.props.detail()}
                      disabled={diferenceDay > 7}
                    >
                      Filtrar
                    </button>
                </div>
                <h4>{cargando && "Cargando..."}</h4>
                <div className="m-2">
                    {updateData.length > 0 ?
                        <React.Fragment>
                            {updateData.map((detalle, i) =>
                                <TableDescription
                                    key={i}
                                    {...detalle}
                                    summary_category={category_detail}
                                    openAccount={openAccount}
                                />
                            )}
                        </React.Fragment>
                        : <h1>No hay Movimientos</h1>
                    }


                </div>
            </div>
        )
    }
}

export default NewDetalleArqueo;
