import React from 'react';
import { api } from "api";
import { Link } from 'react-router-dom';
import validate from './validation';
import { Field, reduxForm, FieldArray } from 'redux-form';
import { renderField, renderSearchSelect } from 'Utils/renderField/renderField';
import './services.css';

const TYPE_PRODUCT = 1
const TYPE_SERVICE = 2
const TYPE_PACKAGE = 3

const SERVICE = 'service'
const PRODUCT = 'product'

const PACKAGE_STR = 'package'
const SERVICE_STR = 'service/product'


let items = []
let persons = []
let options = []

// Load options to select a service category in the renderSearchSelect field

const getOptions = (search)  =>{
    return api.get("service_categories",{search}).catch((error) => {})
        .then((data) => {
            if (data){
                options = [];
                data.results.forEach(x=>{
                    options.push({value: x.id, label: x.name})
                })
                return options
            }
            else{
                return []
            }
        })
        .catch(e => {
            return []
        })
}

// Load options to select a new item to add to a package in the renderSearchSelect field

const getItems = (search)  =>{
    return api.get("services",{search}).catch((error) => {})
        .then((data) => {
            if (data){
                items = [];
                data.results.forEach(x=>{
                    items.push({value: x.id, label: x.name, code: x.code})
                })
                return items
            }
            else{
                return []
            }
        })
        .catch(e => {
            return []
        })
}

const getUsers = (search)  =>{
    return api.get("users",{search}).catch((error) => {})
        .then((data) => {
            if (data){
                persons = [];
                data.results.forEach(x=>{
                    persons.push({value: x.id, label: x.first_name})
                })
                return persons
            }
            else{
                return []
            }
        })
        .catch(e => {
            return []
        })
}

// Load financial_entities
let entities=[];
const getEntity = (search)  =>{
    return api.get("financial_entities", {search}).catch((error) => {})
        .then((data) => {
            if (data){
                entities = [];
                data.results.forEach(x=>{
                    entities.push({value: x.id, label: x.usuario_fel});
                })

                return entities;
            }
            else{
                return []
            }
        })
        .catch(e => {
            return []
        })
}

/* Agregado por (Ricky) */
export const renderEntityRow =({ fields, meta: {error, submitFailed}, edit, view}) => (
    <React.Fragment>
        {
            fields.map((field, index) => (
                <div className="package-item-row pl-md-3" key={index}>
                    <div className="package-item-c d-inline-flex justify-content-center flex-sm-row align-items-stretch align-items-sm-center">
                        <Field
                            name={`${field}.financial_entity`}
                            valueKey="value"
                            labelKey="label"
                            label="name"
                            component={renderSearchSelect}
                            loadOptions={getEntity}
                            className="no-border"
                            disabled={view}
                        />
                    </div>

                    <div className="package-item-c d-inline-flex justify-content-center flex-sm-row align-items-stretch align-items-sm-center">

                    </div>

                    {
                        !view &&
                            <div className="package-small-item-c d-inline-flex justify-content-center">
                                <img className="action-img delete-item-package" src={require("../../../../assets/img/icons/delete-icon.png")} onClick={() => fields.remove(index)}  alt="Eliminar" />
                            </div>
                    }
                </div>
                )
            )
        }
        {/* row for the add button */}
        {
            !view &&
                <div className="package-item-row pl-md-3 no-border d-flex justify-content-end">
                    <div className="package-small-item-c d-inline-flex justify-content-center">
                        <img onClick={() => fields.push()} className="action-img add" src={require("../../../../assets/img/icons/add-icon.png")} alt="Agregar" />
                    </div>
                </div>
        }
        {
            submitFailed && (
                <div className="invalid-feedback d-flex justify-content-center">
                    {error}
                </div>
            )
        }
    </React.Fragment>
)

export const renderCommissionRow =({ fields, meta: {error, submitFailed}, edit, view}) => (
    <React.Fragment>
        {
            fields.map((field, index) => (
                <div className="package-item-row pl-md-3" key={index}>
                    <div className="package-item-c d-inline-flex justify-content-center flex-sm-row align-items-stretch align-items-sm-center">
                        <Field
                            name={`${field}.user`}
                            valueKey="value"
                            labelKey="label"
                            label="name"
                            component={renderSearchSelect}
                            loadOptions={getUsers}
                            className="no-border"
                            disabled={view}
                        />
                    </div>
                    <div className="package-item-c d-inline-flex justify-content-center flex-sm-row align-items-stretch align-items-sm-center">
                        <Field name={`${field}.commission`} disabled={view} component={renderField} type="text" className="form-control"/>
                    </div>
                    {
                        !view &&
                            <div className="package-small-item-c d-inline-flex justify-content-center">
                                <img className="action-img delete-item-package" src={require("../../../../assets/img/icons/delete-icon.png")} onClick={() => fields.remove(index)}  alt="Eliminar" />
                            </div>
                    }
                </div>
                )
            )
        }
        {/* row for the add button */}
        {
            !view &&
                <div className="package-item-row pl-md-3 no-border d-flex justify-content-end">
                    <div className="package-small-item-c d-inline-flex justify-content-center">
                        <img onClick={() => fields.push()} className="action-img add" src={require("../../../../assets/img/icons/add-icon.png")} alt="Agregar" />
                    </div>
                </div>
        }
        {
            submitFailed && (
                <div className="invalid-feedback d-flex justify-content-center">
                    {error}
                </div>
            )
        }
    </React.Fragment>
)

export const renderItemRow =({ fields, meta: {error, submitFailed}, edit, view}) => (
    <React.Fragment>
        {
            fields.map((field, index) => (
                <div className="package-item-row pl-md-3" key={index}>
                    <div className="package-item d-inline-flex justify-content-center flex-sm-row align-items-stretch align-items-sm-center">
                        <Field
                            name={`${field}.item`}
                            valueKey="value"
                            labelKey="label"
                            label="name"
                            component={renderSearchSelect}
                            loadOptions={getItems}
                            className="no-border"
                            disabled={ view }
                        />
                    </div>
                    <div className="package-item d-inline-flex justify-content-center flex-sm-row align-items-stretch align-items-sm-center">
                        <Field
                            name={`${field}.item`}
                            valueKey="value"
                            labelKey="code"
                            label="name"
                            component={renderSearchSelect}
                            loadOptions={getItems}
                            className="no-border"
                            disabled={ view }
                        />
                    </div>
                    <div className="package-small-item d-inline-flex justify-content-center flex-sm-row align-items-stretch align-items-sm-center">
                        <Field name={`${field}.quantity`} disabled={ view } component={renderField}  type="text" addClass="w-75 m-auto quantity-input"/>
                    </div>
                    {
                        !view &&
                            <div className="package-small-item d-inline-flex justify-content-center">
                                <img className="action-img delete-item-package" src={require("../../../../assets/img/icons/delete-icon.png")} onClick={() => fields.remove(index)}  alt="Eliminar" />
                            </div>
                    }
                </div>
                )
            )
        }
        {/* row for the add button */}
        {
            !view &&
                <div className="package-item-row pl-md-3 no-border d-flex justify-content-end">
                    <div className="package-small-item d-inline-flex justify-content-center">
                        <img onClick={() => fields.push({quantity:1 })} className="action-img add" src={require("../../../../assets/img/icons/add-icon.png")} alt="Agregar" />
                    </div>
                </div>
        }
        {
            submitFailed && (
                <div className="invalid-feedback d-flex justify-content-center">
                    {error}
                </div>
            )
        }
    </React.Fragment>
)

export const renderCategoryPrices = ({ fields, meta: {error, submitFailed}, edit, view}) => (
    <div>
        {
            fields.map((categoryPrice, index) => (
                <div key={index} className="category-prices px-md-5 mt-2">
                    <div className=" row-fluid d-flex justify-content-between flex-column flex-sm-row align-items-stretch align-items-sm-center">
                        <div className="m-0">
                            <div className="category-label-width d-flex justify-content-start flex-column flex-sm-row align-items-stretch align-items-sm-center">
                                <label htmlFor="category-name" className="m-0">{(fields.get(index).referent_category) ? fields.get(index).referent_category.name : fields.get(index).name}</label>
                            </div>
                        </div>
                        <div className="line-price mr-5 d-flex justify-content-start flex-column flex-sm-row align-items-stretch align-items-sm-center w-100">
                            {/* This empty div is important to show the line among each category-prices row  */}
                        </div>
                    </div>
                    <div className="category-prices-labels row-fluid d-flex justify-content-between flex-column flex-sm-row align-items-stretch align-items-sm-center">
                        <div className="m-0">
                            <div className="d-flex justify-content-start flex-column flex-sm-row align-items-stretch align-items-sm-center">
                                <label htmlFor={`${categoryPrice}.price`} className="mb-1">Precio de {(fields.get(index).referent_category) ? fields.get(index).referent_category.name : fields.get(index).name}</label>
                            </div>
                            <div className="d-flex justify-content-start flex-column flex-sm-row align-items-stretch align-items-sm-center">
                                <Field name={`${categoryPrice}.price`} disabled={view} component={renderField} type="text" className="form-control"/>
                            </div>
                        </div>
                        <div className="m-0 ">
                            <div className="d-flex justify-content-start flex-column flex-sm-row align-items-stretch align-items-sm-center">
                                <label htmlFor={`${categoryPrice}.payback_1`} className="mb-1">Payback médico</label>
                            </div>
                            <div className="d-flex justify-content-start flex-column flex-sm-row align-items-stretch align-items-sm-center">
                                <Field name={`${categoryPrice}.payback_1`} disabled={view} component={renderField} type="text" className="form-control"/>
                            </div>
                        </div>
                        <div className="m-0 ">
                            <div className="d-flex justify-content-start flex-column flex-sm-row align-items-stretch align-items-sm-center">
                                <label htmlFor={`${categoryPrice}.payback_2`} className="mb-1">Payback trabajador social</label>
                            </div>
                            <div className="d-flex justify-content-start flex-column flex-sm-row align-items-stretch align-items-sm-center">
                                <Field name={`${categoryPrice}.payback_2`} disabled={view} component={renderField} type="text" className="form-control"/>
                            </div>
                        </div>
                    </div>
                </div>
                )
            )
        }
    </div>
);

const Form = props => {

    const edit = window.location.href.includes("edit");
    const view = window.location.href.includes("view");

    const {
        type,
        updateData,
        handleSubmit
    } = props;

    return(
        <form onSubmit={handleSubmit} className="my-3 ml-3 mr-3 inside-card">
            <label className="pt-4 pl-4 section-title-label w-100">DATOS GENERALES</label>
            {
                type !== PACKAGE_STR &&
                <div className="px-md-5 pt-3 d-flex flex-column flex-sm-row align-items-stretch align-items-sm-center">
                    <div className="radio">
                        <label htmlFor="service_type" className="radio-inline mr-5 d-flex align-items-center">
                            <Field name="service_type" disabled={ edit || view } component={renderField} type="radio" value={SERVICE}/>
                            &nbsp;Servicio
                        </label>
                    </div>
                    <div className="radio">
                        <label htmlFor="service_type" className="radio-inline mr-5 d-flex align-items-center">
                            <Field name="service_type" disabled={ edit || view } component={renderField} type="radio" value={PRODUCT}/>
                            &nbsp;Producto
                        </label>
                    </div>
                </div>
            }
            <div className="pr-5 pb-3">
                <div className="px-md-5 pt-2 d-flex flex-column flex-sm-row align-items-stretch align-items-sm-center">
                    <div className="my-2 mr-5">
                        <div className="d-flex justify-content-start flex-column flex-sm-row align-items-stretch align-items-sm-center">
                            <label htmlFor="name" className="m-0">Nombre del servicio</label>
                        </div>
                        <div className=" d-flex justify-content-start flex-column flex-sm-row align-items-stretch align-items-sm-center">
                            <Field name="name" disabled={view} component={renderField} type="text" className="form-control"/>
                        </div>
                    </div>
                    <div className="m-2 ml-5 service-price-discount d-flex justify-content-between">
                        <div>
                            <div className="service-price-input d-flex justify-content-start flex-column flex-sm-row align-items-stretch align-items-sm-center">
                                <label htmlFor="price" className="m-0">Precio</label>
                            </div>
                            <div className="service-price-input d-flex justify-content-end flex-column flex-sm-row align-items-stretch align-items-sm-center">
                                <Field name="price" disabled={view} component={renderField} type="text" className="form-control"/>
                            </div>
                        </div>
                        <div>
                            <div className="service-discount-input d-flex justify-content-start flex-column flex-sm-row align-items-stretch align-items-sm-center">
                                <label htmlFor="discount" className="m-0">Descuento (%)</label>
                            </div>
                            <div className="service-discount-input d-flex justify-content-end flex-column flex-sm-row align-items-stretch align-items-sm-center">
                                <Field name="discount" disabled={view} component={renderField} type="text" className="form-control"/>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="px-md-5 row-fluid d-flex flex-column flex-sm-row align-items-stretch align-items-sm-center">
                    <div className="mr-5">
                        <div className="d-flex justify-content-start flex-column flex-sm-row align-items-stretch align-items-sm-center">
                            <label htmlFor="payback" className="m-0">Payback</label>
                        </div>
                        <div className="d-flex justify-content-start flex-column flex-sm-row align-items-stretch align-items-sm-center">
                            <Field name="payback" disabled={view} component={renderField} type="text" className="form-control"/>
                        </div>
                    </div>
                    <div className="ml-5">
                        <div className="d-flex justify-content-start flex-column flex-sm-row align-items-stretch align-items-sm-center">
                            <label htmlFor="service_category" className="m-0">Categoría</label>
                        </div>
                        <div className="d-flex justify-content-start flex-column flex-sm-row align-items-stretch align-items-sm-center">
                            <Field
                                name="service_category"
                                valueKey="value"
                                labelKey="label"
                                label="name"
                                component={renderSearchSelect}
                                loadOptions={getOptions}
                                className="no-border"
                                disabled={view}
                            />
                        </div>
                    </div>
                </div>
            </div>

            <label className="pt-4 pl-4 section-title-label w-100">COMISIONES</label>
            <div>
                <div className="package-item-row-container">
                    <div className="package-items-title-row d-flex pl-md-3">
                        <label htmlFor="service-name" className="package-item-title-c my-0">Nombre del usuario</label>
                        <label htmlFor="service-code" className="package-item-title-c my-0">Comisión</label>
                        <label htmlFor="service-action" className="package-small-item-title-c my-0">Eliminar</label>
                    </div>
                    <div>
                        <FieldArray
                            name="commissions"
                            component={renderCommissionRow}
                            view={view}
                            edit={edit}
                        />
                    </div>
                </div>
            </div>

            <label className="pt-4 pl-4 section-title-label w-100">ENTE DE FACTURACIÓN</label>
            <div>
                <div className="package-item-row-container">
                    <div className="package-items-title-row d-flex pl-md-3">
                        <label htmlFor="service-name" className="package-item-title-c my-0">Nombre</label>
                        <label htmlFor="service-action" className="package-small-item-title-c my-0"></label>
                        <label htmlFor="service-action" className="package-small-item-title-c my-0"></label>
                        <label htmlFor="service-action" className="package-small-item-title-c my-0">Eliminar</label>
                    </div>
                    <div>
                        <FieldArray
                            name="financial_entities"
                            component={renderEntityRow}
                            view={view}
                            edit={edit}
                        />
                    </div>
                </div>
            </div>

            <label className="pt-3 pl-4 section-title-label w-100">TIPO DE PRECIOS</label>
            <div className="pr-5">
                <div className="">
                    <FieldArray
                        name="categories_prices"
                        component={renderCategoryPrices}
                        view={view}
                        edit={edit}
                    />
                </div>
            </div>
            {
                type == PACKAGE_STR &&
                    <div className="pt-2">
                        <label className="pt-4 pl-4 section-title-label w-100">SERVICIOS</label>
                        <div>
                            <div className="package-item-row-container">
                                <div className="package-items-title-row d-flex pl-md-3">
                                    <label htmlFor="service-name" className="package-item-title my-0">Nombre del servicio</label>
                                    <label htmlFor="service-code" className="package-item-title my-0">Código</label>
                                    <label htmlFor="service-quantity" className="package-small-item-title my-0">Cantidad</label>
                                    <label htmlFor="service-action" className="package-small-item-title my-0">Eliminar</label>
                                </div>
                                <div>
                                    <FieldArray
                                        name="package_items"
                                        component={renderItemRow}
                                        view={view}
                                        edit={edit}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
            }
            <div className="row-fluid">
                <div className="d-flex justify-content-center flex-column flex-sm-row align-items-stretch align-items-sm-center">
                    {/*<Link className="btn btn-secondary m-4" to="/services">{!view ? ("Cancelar") : ("Regresar")}</Link>*/}
                    {
                        window.location.href.includes("services_conf") &&
                        <Link className="btn btn-secondary m-4" to="/services_conf">{!view ? ("Cancelar") : ("Regresar")}</Link>
                    }
                    {!view && (<button type="submit" className="btn btn-primary m-4">Guardar</button>)}
                </div>
            </div>
        </form>
    )
};

const ServicesForm = reduxForm({
    form: 'services',
    validate,
})(Form);

export default ServicesForm
