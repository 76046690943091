import Table from '../Utils/Grid';
import React, { Component } from 'react';
import { BreakLine } from '../Utils/tableOptions';
import HeaderSimple from '../Utils/Header/HeaderSimple';
import { activeFormatter }  from '../Utils/Acciones/Acciones'
import ToolbarChange from '../Utils/Toolbar/ToolbarChange';

class Dues extends Component {
    componentWillUnmount() {
        this.props.resetStore();
    }
    componentWillMount() {
        this.props.listar()
    }

    render() {
        const {
            listar,
            detail,
            create,
            update,
            destroy,
        } = this.props;


        const {
            cargando,
            page,
            data,
        } = this.props;

        return (
            <div className="content">
                <div className="m-auto row-fluid w-100">
                    <HeaderSimple texto={"Agregar cuota"} ruta={"/dues/create"}/>
                    <div className="table-decoration">
                        <div className="top-title-toolbar">
                            <ToolbarChange
                                titulo="LISTADO DE CUOTAS"
                                buscar={this.props.search}
                                buscador={this.props.buscador}
                                autofocus
                            />
                        </div>
                        <Table
                            onPageChange={listar}
                            data={data}
                            loading={cargando}
                            page={page}
                        >

                        <TableHeaderColumn
                            tdStyle={BreakLine}
                            thStyle={BreakLine}
                            dataField="due_description" dataSort>Nombre</TableHeaderColumn>

                         <TableHeaderColumn
                            tdStyle={BreakLine}
                            thStyle={BreakLine}
                            dataField="modified" dataSort>Última Modificación</TableHeaderColumn>

                        <TableHeaderColumn
                            dataField="id"
                            isKey={true}
                            dataAlign="center"
                            dataFormat={activeFormatter({ editar: '/dues', eliminar: destroy })}>Acciones</TableHeaderColumn>
                        </Table>
                    </div>
                </div>
            </div>
        )
    }
}

export default Dues;
