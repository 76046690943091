import React, { Component } from 'react';
import { RenderCurrency } from 'Utils/renderField/renderReadField';
import { renderCurrency } from 'Utils/renderField/renderField';
import { Field, FieldArray, reduxForm} from 'redux-form';
import { validate, validators } from 'validate-redux-form';
import MyModal from '../../Utils/myModal';
import { renderDueRow } from "./dueRow";
import { connect } from 'react-redux';
let index = 0;
class DuesForm extends Component{
    render(){
        index = this.props.index;

        const {
            handleSubmit,
        } = this.props;

        return(
            <form onSubmit={handleSubmit} id="selectDue">
                <table className="table table-sm" id="table-tarjet2">
                    <thead className="thead-tarjet">
                        <tr>
                            <th>Cantidad</th>
                            <th>Tarjeta</th>
                            <th className="col-1">Cuotas</th>
                            <th>% Recargo</th>
                            <th>Cantidad + Recargo</th>
                            <th>Acciones</th>
                        </tr>
                    </thead>
                    <tbody>
                        <FieldArray
                            name="due_payments"
                            component={renderDueRow}
                            props = {{ dues: this.props.listDues, due_detail:[] }}
                        />
                    </tbody>
                </table>
            </form>
        );
    }
}

DuesForm = reduxForm({
    form: 'duesForm',
    enableReinitialize: true,
    validate: data => {
        const errors = {};
        const membersArrayErrors = [];
        data.due_payments.forEach((member, memberIndex) => {
            const memberErrors = {}
            if(!member || !member.amount){
                memberErrors.amount = 'Campo requerido';
                membersArrayErrors[memberIndex] = memberErrors;
            }

            if(!member || !member.dues){
                memberErrors.dues = 'Campo requerido';
                membersArrayErrors[memberIndex] = memberErrors;
            }

            if(!member || !member.due_detail){
                memberErrors.due_detail = 'Campo requerido';
                membersArrayErrors[memberIndex] = memberErrors;
            }
        });

        if (membersArrayErrors.length) {
            errors.due_payments = membersArrayErrors
        }

        return errors
    }
})(DuesForm);

DuesForm = connect(
     state => ({
         initialValues: state.sales.productsInCarOrdered[index].dataPayments
     })
)(DuesForm)

export default DuesForm;
