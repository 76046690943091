import React from "react";
import { api } from "api";

const URL_GET_TOKEN = "get-token-fel"; // Backend
const URL_GET_NIT = "patients/validateNit"; // Backend
const URL_GET_CUI = "get-valid-cui"; // Backend
const URL_VERIFICAR_CUI = "https://felcloud-instance-three.feel.com.gt/api/v2/servicios/externos/cui"; // API Feel

// let token = "eyJhbGciOiJIUzI1NiJ9eyJpZCI6ODIxMTYsInByZWZpam8iOiJGRUwxNzNURU1QIiwibGxhdmUiOiJFRjAxNzk2QjBGNkI5RUZEQjc0M0VFMzlCQkJGOTM5OCIsIm5pdF9lbWlzb3IiOiI5MjAwMDAwMDM2OThLIiwiZXN0YWvX2VmIjoxLCJlc3RhZG9faW1wIjoyLCJleHAiOjE2NzE1ODI1Mjl9.TReIU4fCcqwaiZ_cxMctFMbWB9rgHmvaEPHdYgAvdDU"

// let cui = 2083327660807

/**
 * @description Autenticación por barrer token y body con cui
 */
const verificarCui = async (token, cui) => {
    const options = {
        method: 'POST',
        headers: {
          Authorization: 'Bearer ' + token,
        },
        body: `{"cui": ${cui}}`
      };
    const response = fetch(URL_VERIFICAR_CUI, options)
        .then(response => response.json())
        .then(data => {
            return data;
        })
        .catch(error => {
            return error;
        });
    console.log(response);
    return response
};


/**
 * @description Obtener token para autenticación
 */
const getToken = async () => {
    const response = await api.get(URL_GET_TOKEN);
    let token = response.token? response.token : " ";
    return token;
};


/**
 * @description Este hook se encarga de verificar y obtener la información de un Cui que se le pase por parámetro
 * @param {number} cui
 * @returns {object} {loading, error, data}
 * @example const {loading, error, data} = useVerificarCui("123456789");
 *
 */
const useVerificarCui = () => {
    const [loading, setLoading] = React.useState(false);
    const [errorFetch, setError] = React.useState(false);
    const [data, setData] = React.useState(null);
    const [cui, setCui] = React.useState(null);

    React.useEffect(() => {
        const fetchData = async () => {
            setError(false);
            setLoading(true);
            try {
                const data = await api.post(URL_GET_CUI, {cui});
                setData(data);
            } catch (error) {
                setError(true);
            }
            setLoading(false);
            setCui(null);
        };
        if (cui) {
            fetchData();
        }
    }, [cui]);

    return { loading, errorFetch, data, setCui };
};

/**
 * Example de uso
 * const {loading, error, data, setNit} = useValidateNit();
 * setNit("123456789");
 * if (loading) return <p>Loading...</p>;
 * if (error) return <p>Error :(</p>;
 * return  <p>{data}</p>;
 * /

/**
 * @description Este hook se encarga de verificar y obtener la información de un NIT que se le pase por parámetro
 * @param {number} nit
 * @returns {object} {loading, error, data}
 * @example const {loading, error, data} = useValidateNit("123456789");
 *
*/
const useValidateNit = () => {
    const [loading, setLoading] = React.useState(false);
    const [error, setError] = React.useState(null);
    const [data, setData] = React.useState(null);
    const [nit, setNit] = React.useState(null);

    React.useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            try {
                const data = await api.get(URL_GET_NIT,{nit});
                setData(data.data);
            } catch (error) {
                setError(error);
            }
            setLoading(false);
        };
        fetchData();
    }, [nit]);

    return { loading, error, data, setNit };
};

/**
 * Example de uso
 * const {loading, error, data, setCui} = useVerificarCui();
 * setCui("123456789");
 * if (loading) return <p>Loading...</p>;
 * if (error) return <p>Error :(</p>;
 * return  <p>{data}</p>;
 */

export { useValidateNit, useVerificarCui };
