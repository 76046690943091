import React from 'react';
import { api } from 'api';
import { Link } from 'react-router-dom';
import { Field, reduxForm } from 'redux-form';
import { validate, validators } from 'validate-redux-form';
import { renderField, renderSearchSelect } from 'Utils/renderField/renderField';

let referent_categories = []

const getReferentCategories = (search) =>{
    return api.get("referent_categories",{search}).catch((error) => {})
            .then((data) => {
                if (data){
                    referent_categories = [];
                    data.results.forEach(x=>{
                        referent_categories.push({value: x.id, label: x.name})
                    })
                    return referent_categories
                }
                else{
                    return []
                }
            })
            .catch(e => {
                return []
            })
}

const Form = props => {

    const {
        handleSubmit
    } = props;

    const view = window.location.href.includes("view");

    return(
        <form onSubmit={handleSubmit} className="py-2 px-5">
            <div className="px-md-5 d-flex justify-content-around flex-column flex-sm-row align-items-stretch align-items-sm-center">
                <div className="m-2">
                    <div className="d-flex justify-content-start flex-column flex-sm-row align-items-stretch align-items-sm-center">
                        <label htmlFor="name" className="m-0">Nombre</label>
                    </div>
                    <div className="d-flex justify-content-start flex-column flex-sm-row align-items-stretch align-items-sm-center">
                        <Field name="name" disabled={view} autofocus component={renderField} type="text" letras={true} className="form-control"/>
                    </div>
                </div>
                <div className="m-2">
                    <div className="d-flex justify-content-start flex-column flex-sm-row align-items-stretch align-items-sm-center">
                        <label htmlFor="description" className="m-0">Descripción</label>
                    </div>
                    <div className="d-flex justify-content-start flex-column flex-sm-row align-items-stretch align-items-sm-center">
                        <Field name="description" disabled={view} component={renderField} type="text" className="form-control"/>
                    </div>
                </div>
            </div>
            <div className="px-md-5 d-flex justify-content-around flex-column flex-sm-row align-items-stretch align-items-sm-center">
                <div className="m-2">
                    <div className="d-flex justify-content-start flex-column flex-sm-row align-items-stretch align-items-sm-center">
                        <label htmlFor="referent_category" className="m-0">Categoría</label>
                    </div>
                    <div className="d-flex justify-content-start flex-column flex-sm-row align-items-stretch align-items-sm-center">
                        <Field
                            disabled={view}
                            name="referent_category"
                            valueKey="value"
                            labelKey="label"
                            label="referent_category"
                            component={renderSearchSelect}
                            loadOptions={getReferentCategories}
                            className="no-border"
                        />
                    </div>
                </div>
                <div className="m-2 invisible">
                    <div className="d-flex justify-content-start flex-column flex-sm-row align-items-stretch align-items-sm-center">
                        <Field name="description" disabled={view} component={renderField} type="text" className="form-control"/>
                    </div>
                </div>
            </div>
            <div className="row-fluid">
                <div className="d-flex justify-content-center flex-column flex-sm-row align-items-stretch align-items-sm-center">
                    <Link className="btn btn-secondary m-3" to="/institutions">{!view ? ("Cancelar") : ("Regresar")}</Link>
                    {!view && (<button type="submit" className="btn btn-primary m-3">Guardar</button>)}
                </div>
            </div>
       </form>
    )
};

const InstitucionForm = reduxForm({
    form: 'institution',
    validate: data => {
        return validate(data, {
            'name':validators.exists()('Campo Requerido'),
            'referent_category':validators.exists()('Campo Requerido'),
        })
    }
})(Form);

 export default InstitucionForm
