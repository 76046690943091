import React, { Component } from 'react';
import "./toolbar.css";
import Search from "./Search"
import SearchChange from "./SearchChange"

export default class ToolbarSimple extends Component {    
    render() {
        console.log("THIS.PROPS.BUSCAR: ", this.props.buscar);
        if(this.props.buscar === undefined){
            console.log("THIS.PROPS.BUSCAR ESTÁ INDEFINIDO");
        }

        return (
            <div className="w-100 d-flex justify-content-between">
                <div className="row">
                    <div className="titulo d-flex align-items-start">
                        {/* <img width={35} className="" src={this.props.icono} alt="Usuario" /> */}
                        <span className="ml-2 text-uppercase text-titulo"><strong>{this.props.titulo}</strong></span>
                    </div>
                </div>
                <div className="text-right search">
                    {(this.props.buscar !== undefined) && (
                        <Search 
                            buscar={this.props.buscar} 
                            buscador={this.props.buscador}
                            autofocus={this.props.autofocus}
                            date = {this.props.date}
                        />
                    )}
                </div>
            </div>
        )
    }
}

