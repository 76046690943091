const url_inventario = 'inventario';
const url_whearhouse = 'whearhouse';
const url = 'whearhouse-admin'
import { api } from 'api';
import Swal from 'sweetalert2';
import { push } from 'react-router-redux';
import { goBack } from 'react-router-redux';
import { initialize as initializeForm } from 'redux-form';
import { createActions, handleActions } from 'redux-actions';
import Service from '../../common/components/sales/Services';
import moment from "moment";
import _ from "lodash";

const LOADER_WHEARHOUSE = 'LOADER_WHEARHOUSE';
const SET_DATA_WHEARHOUSE = 'SET_DATA_WHEARHOUSE';
const SET_PAGE_WHEARHOUSE = 'SET_PAGE_WHEARHOUSE';
const SET_UPDATE_DATA_WHEARHOUSE = 'SET_UPDATE_DATA_WHEARHOUSE';
const SET_BUSCADOR_WHEARHOUSE = 'SET_BUSCADOR_WHEARHOUSE';
const SET_FILTRO_WHEARHOUSE = 'SET_FILTRO_WHEARHOUSE';
const SET_BODEGA = 'SET_BODEGA';

const LOADER_SERVICE = 'LOADER_SERVICE';
const SET_DATA_SERVICE = 'SET_DATA_SERVICE';
const SET_PAGE_SERVICE = 'SET_PAGE_SERVICE';
const SET_UPDATE_DATA_SERVICE ='SET_UPDATE_DATA_SERVICE';
const SET_BUSCADOR_SERVICE = 'SET_BUSCADOR_SERVICE';
const SET_FILTRO_SERVICE = 'SET_FILTRO_SERVICE';

const ADD_PRODUCT_TO_LIST = 'ADD_PRODUCT_TO_LIST';
const TOTAL_CALCULATE_WAREHOUSE_ADMIN = 'TOTAL_CALCULATE_WAREHOUSE_ADMIN';
const REMOVE_SERVICE_TO_LIST = 'REMOVE_SERVICE_TO_LIST';
const UP_DOWN_CANT = 'UP_DOWN_CANT';
const SET_LOTE = 'SET_LOTE';
const SET_VENCIMIENTO = 'SET_VENCIMIENTO';
const VALIDATE_LIST = 'VALIDATE_LIST';
const SET_PRICE = 'SET_PRICE';

const SET_DATA_INVENTORY = 'SET_DATA_INVENTORY';
const SET_PAGE_INVENTORY = 'SET_PAGE_INVENTORY';
const SET_BUSCADOR_INVENTORY = 'SET_BUSCADOR_INVENTORY';
const SET_FILTRO_INVENTORY = 'SET_FILTRO_INVENTORY';

const SET_SERVICE_LIST = 'SET_SERVICE_LIST';

const SET_EXISTENCIA_REAL = 'SET_EXISTENCIA_REAL';
const SET_CANTIDAD_LOTE = 'SET_CANTIDAD_LOTE';
const SET_PRODUCTS_LIST = 'SET_PRODUCTS_LIST';

const listar = (page = 1) =>  (dispatch, getStore) => {
    dispatch({type: LOADER_WHEARHOUSE, cargando: true});
    const store = getStore();
    const search = store.whearhouse.buscador;
    const filtro = store.whearhouse.filtro_whearhouse;
    let params = {page, search};
    if(filtro){
        params[''] = filtro
    }

    api.get(`${url_whearhouse}`, params).catch((error) => {
        dispatch({type: LOADER_WHEARHOUSE, cargando: false});
        Swal(
            'Error',
            error.detail || 'Ha ocurrido un error, por favor vuelva a intentar.',
            'error'
        );
    }).then((data) => {
        if(data){
            dispatch({type: SET_DATA_WHEARHOUSE, data});
            dispatch({type: SET_PAGE_WHEARHOUSE, page});
        }
        dispatch({type: LOADER_WHEARHOUSE, cargando: false});
    })
};

const listarInventario = (page = 1) =>  (dispatch, getStore) => {
    dispatch({type: LOADER_WHEARHOUSE, cargando: true});
    const store = getStore();
    const search = store.whearhouseAdmin.buscador;
    const filtro = store.whearhouseAdmin.filtro_whearhouse;
    const bodega = store.whearhouseAdmin.id_bodega;

    let params = {page, search, bodega};
    if(filtro){
        params[''] = filtro
    }

    api.get(`${url_inventario}`, params).catch((error) => {
        dispatch({type: LOADER_WHEARHOUSE, cargando: false});
        Swal(
            'Error',
            error.detail || 'Ha ocurrido un error, por favor vuelva a intentar.',
            'error'
        );
    }).then((data) => {
        if(data){
            dispatch({type: SET_DATA_WHEARHOUSE, data});
            dispatch({type: SET_PAGE_WHEARHOUSE, page});
        }
        dispatch({type: LOADER_WHEARHOUSE, cargando: false});
    })
};

const setIdBodega = id_bodega => (dispatch, getState) =>{
    api.get(`whearhouse/${id_bodega}`).catch((error) => {
        console.log("error: ", error)
    }).then((data) => {
        if(data){
            console.log("Data bodega: ", data);
            dispatch({type: SET_BODEGA, data});
            dispatch(chargeInventory(1));
        }
    })
}

const listarServicios = (page = 1) =>  (dispatch, getStore) => {
    console.log("Ejecutando función listar servicios in whearhouseAdmin.js ");
    dispatch({type: LOADER_SERVICE, cargando: true});
    const store = getStore();
    const search = store.whearhouseAdmin.buscador_service;
    const filtro = store.whearhouseAdmin.filtro_service;
    let params = {page, search };

    if(filtro){
        params[''] = filtro
    }
    api.get(`services/getServiceWearhouse`, params).catch((error) => {
    //api.get(`services`, params).catch((error) => {
        dispatch({type: LOADER_SERVICE, cargando: false});
        Swal(
            'Error',
            error.detail || 'Ha ocurrido un error, por favor vuelva a intentar.',
            'error'
        );
    }).then((data) => {
        const search2 = getStore().whearhouseAdmin.buscador_service;
        if(search2 == search){
            if(data){
                console.log("Data recibida: ", data);
                dispatch({type: SET_DATA_SERVICE, data});
                dispatch({type: SET_PAGE_SERVICE, page});
            }
            dispatch({type: LOADER_SERVICE, cargando: false});
            dispatch({type: SET_UPDATE_DATA_SERVICE, updateData: {}});
        }
    })
};

const addServiceToListEntry = (service) => (dispatch, getStore) => {
        const producto = {
            id: service.id,
            name: service.name,
            cantidad: 1,

            //vencimiento: moment(new Date()).format("YYYY-MM-DD"),
            vencimiento: moment(new Date()).format("DD-MM-YYYY"),
            //precio: service.price,
            precio: service.cost, //mod
            precio_error: false,
            //total: service.price,
            total: service.cost
        };

        dispatch({type: ADD_PRODUCT_TO_LIST, producto});
        dispatch({type: TOTAL_CALCULATE_WAREHOUSE_ADMIN});
}

const addServiceToList = (inventario,nombre_producto) => (dispatch, getStore) => {
    let lote_repetido = false;
    const arreglo_actual = _.cloneDeep(getStore().whearhouseAdmin.productsInList);

    arreglo_actual.forEach((item) => {
        if(item.inventario === inventario.id){
            lote_repetido = true;
        }
    });

    if(lote_repetido){
        Swal(
            'Error',
            '' || 'El producto con el número de lote ya está ingresado, agregue uno diferente',
            'error'
        );
    }
    else{
        const producto = {
            inventario: inventario.id,
            nombre_producto,
            //Para el reajuste
            lote: inventario.lote.lote,
            cantidad_por_lote: inventario.existencias,
            existencia_real : inventario.existencias,
            //Para la baja
            cantidad_baja: 1,
        };

        dispatch({type: ADD_PRODUCT_TO_LIST, producto});
    }
}

/**
 * Función para el cambio de cantidad existente en bodega según el lote (Sección reajuste)
 */
const setExistenciaReal = (index, value) => (dispatch, getStore) => {
    //console.log("ejecutando existencia real");
    //const data = { index, value};
    //dispatch({type: SET_EXISTENCIA_REAL, data});

    const productsInList = _.cloneDeep(getStore().whearhouseAdmin.productsInList);
    let arreglo = [];
    productsInList.forEach((item, key) => {
        if(key === index){
            item.existencia_real = value;
        }

        arreglo.push(item);
    });

    dispatch({type: SET_PRODUCTS_LIST, productsInList: arreglo});
}

const setCantidadBaja = (index, value) => (dispatch, getStore) => {
    const productsInList = _.cloneDeep(getStore().whearhouseAdmin.productsInList);

    let arreglo = [];
    productsInList.forEach((item, key) => {
        if(key === index){
            if(value === ''){
                item.existencia_real = undefined;
            }else{
                //item.cantidad_baja = parseInt(value);
                item.cantidad_baja = parseFloat(value);
            }
        }

        arreglo.push(item);
    });


    dispatch({type: SET_PRODUCTS_LIST, productsInList: arreglo});
    console.log("arreglo al setar la baja de producto: ", arreglo);
}

const cantidadSegunLote = (index, value) => (dispatch, getStore) => {

    let lote_repetido = false;
    const arreglo_actual = _.cloneDeep(getStore().whearhouseAdmin.productsInList);
    let lote_del_combo_cambiante = arreglo_actual[index].lote;

    console.log(" ************************************************************************* ");
    arreglo_actual.forEach((item) => {
        console.log(" Lotes seleccionados actualmente: ", item.lote);
        if(item.lote.label === value.label){
            lote_repetido = true;
        }
    });
    console.log(" ************************************************************************* ");

    console.log("lote_repetido: ", lote_repetido);

    if(!lote_repetido){
        console.log("LOTE VÁLIDO");
        arreglo_actual[index].cantidad_por_lote = value.value;
        arreglo_actual[index].existencia_real = value.value;
        arreglo_actual[index].lote = value;
        dispatch({type: SET_CANTIDAD_LOTE, productsInList: arreglo_actual});

    }else{
        setTimeout(() => {
            arreglo_actual[index].lote = lote_del_combo_cambiante;
            dispatch({type: SET_CANTIDAD_LOTE, productsInList: arreglo_actual});
          }, 500);
    }

    /*
    let lote_repetido = false;
    const clon2 = _.cloneDeep(getStore().whearhouseAdmin.productsInList);
    const hola = _.cloneDeep(getStore().whearhouseAdmin.productsInList);


    console.log("Array actual: ", clon2);

    const nuevaLista = [];

    hola.forEach((item, key) => {
        console.log("array: ", item.lote.id_lote, " value: ", value.id_lote);
        if(item.lote.id_lote === value.id_lote){
            console.log("Lote repetido");
            lote_repetido = true;
        }

        item.inventario.forEach((inven) => {
            if(key === index && value.id_lote === inven.lote.id){
                item.cantidad_por_lote = inven.existencias;
                item.existencia_real = inven.existencias;
                item.lote = value;
            }
        });

        nuevaLista.push(item);
    });

    console.log("¿lote repetido? ", lote_repetido);

    if(!lote_repetido){
        dispatch({type: SET_CANTIDAD_LOTE, productsInList: nuevaLista});
    }else{
        console.log("El lote ya está seleccionado");
        dispatch({type: SET_CANTIDAD_LOTE, productsInList: clon2});
    }
    */
}

/** --- */

const setServiceList = () => (dispatch) => {
    dispatch({type: SET_SERVICE_LIST});
}

const cancelEntry = () => (dispatch, getStore) => {

    Swal.fire({
        title: '¿Está seguro(a) que desea cancelar el ingreso? Perdera los datos del ingreso actual',
        type: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#aaa',
        confirmButtonText: 'Aceptar',
        cancelButtonText: 'Cancelar',
        reverseButtons: true
    }).then((result) => {
        if (result.value) {
            dispatch({type: SET_SERVICE_LIST});
            const bodega = getStore().whearhouseAdmin.bodega;
            const url = "/administrar-bodega/" + bodega.id + "/admin";
            dispatch(push(url));
        }
    });
}

const removeServiceToList = (index) => (dispatch) => {
    console.log("Llamada a la función remove service to list, id: ", index);
    dispatch({type: REMOVE_SERVICE_TO_LIST, index});
    dispatch({type: TOTAL_CALCULATE_WAREHOUSE_ADMIN});
}

const upDownCant = (index, up_down) => (dispatch, getStore) => {
    /*
    console.log("whearhouseAdmin.js upDownCant ejecutándose");
    const data = { index, up_down };
    dispatch({type: UP_DOWN_CANT, data})
    dispatch({type: TOTAL_CALCULATE});
    */
    /* Acá está lo nuevo */

    const listado = _.cloneDeep(getStore().whearhouseAdmin.productsInList);

    let arreglo = [];
    listado.forEach((item, key) => {
        if(key === index){
            if(up_down){
                item.cantidad = item.cantidad + 1;
            }else{
                item.cantidad = (item.cantidad > 1) ? item.cantidad - 1 : 1 ;
            }
        }

        item.total = parseFloat(item.precio) * parseFloat(item.cantidad);
        arreglo.push(item);
    });

    dispatch({type: UP_DOWN_CANT, data: arreglo})
    dispatch({type: TOTAL_CALCULATE_WAREHOUSE_ADMIN});
}

const setCant = (index, cantidad) => (dispatch, getStore) => {
    /*
    console.log("whearhouseAdmin.js upDownCant ejecutándose");
    const data = { index, up_down };
    dispatch({type: UP_DOWN_CANT, data})
    dispatch({type: TOTAL_CALCULATE});
    */
    /* Acá está lo nuevo */

    const listado = _.cloneDeep(getStore().whearhouseAdmin.productsInList);

    let arreglo = [];
    listado.forEach((item, key) => {
        if(key === index){
            item.cantidad = cantidad;

            if(item.cantidad < 0){
                item.cantidad = 0;
            }
        }

        item.total = parseFloat(item.precio) * parseFloat(item.cantidad);
        arreglo.push(item);
    });

    dispatch({type: UP_DOWN_CANT, data: arreglo})
    dispatch({type: TOTAL_CALCULATE_WAREHOUSE_ADMIN});
}

const setLote = (index, value) => (dispatch, getStore) => {
    const store = getStore().whearhouseAdmin;
    let existe = false;
    const products = store.productsInList;
    products.forEach(product => {
        if(product.lote === value){
            existe = true;
            const data = {index, value: ''};
            dispatch({type: SET_LOTE, data});
        }
    });

    if(value !== '' && existe){
        Swal.fire(
            'El lote ya está asignado',
            'Por favor verifique',
            'question'
        )
    }else{
        const data = { index, value};
        console.log("data in set lote action: ", data);
        dispatch({type: SET_LOTE, data});
        //dispatch({type: VALIDATE_LIST});
    }
}

const setPrice = (index, value) => (dispatch, getStore) => {
    /*
    const data = { index, value};
    console.log("data in set precio action: ", data);
    dispatch({type: SET_PRICE, data});
    dispatch({type: VALIDATE_LIST});
    */


    const listado = _.cloneDeep(getStore().whearhouseAdmin.productsInList);
    let arreglo = [];
    listado.forEach((item, key) => {
        if(key === index){
            if(value.trim() === ''){
                item.precio = undefined;
                item.total = 0;
            }else{
                item.precio = parseFloat(value.trim());
                item.total = item.precio * item.cantidad;
            }
        }

        arreglo.push(item);
    });

    //dispatch({type: SET_PRODUCTS_LIST, productsInList: arreglo});
    dispatch({type: SET_PRICE, data: arreglo});
    //dispatch({type: VALIDATE_LIST});
    dispatch({type: TOTAL_CALCULATE_WAREHOUSE_ADMIN});
}

const setVencimiento = (id, value) => (dispatch) => {
    const data = { id, value};
    console.log("data in set vencimiento action: ", data);
    dispatch({type: SET_VENCIMIENTO, data});
}

const search = (search) => (dispatch) => {
    dispatch({type: SET_BUSCADOR_WHEARHOUSE, buscador: search});
    dispatch(listar(1));
};

const filtro = (filtro) => (dispatch, getStore) => {
    if (filtro == 0){
        filtro = null
    }
    dispatch({type: SET_FILTRO_WHEARHOUSE, filtro_whearhouse: filtro});
    dispatch(listar(1));
};

const filtroInventario = (filtro) => (dispatch, getStore) => {
    if (filtro == 0){
        filtro = null
    }
    dispatch({type: SET_FILTRO_INVENTORY, filtro_inventario: filtro});
    dispatch(chargeInventory(1));
};

const searchService = (search) => (dispatch) => {
    dispatch({type: SET_BUSCADOR_SERVICE, buscador_service: search});
    dispatch(listarServicios(1));
};

const filtroService = (filtro) => (dispatch, getStore) => {
    if (filtro == 0){
        filtro = null
    }
    dispatch({type: SET_FILTRO_SERVICE, filtro_service: filtro});
    dispatch(listarServicios(1));
};

const searchInventario = (search) => (dispatch) => {
    dispatch({type: SET_BUSCADOR_INVENTORY, buscador_inventario: search});
    dispatch(chargeInventory(1));
};

const finishBaja = () => (dispatch, getStore) => {
    const formData = getStore().form.bajaBodega.values;
    const comment = formData.note !== undefined ? formData.note : '';
    const bodega = getStore().whearhouseAdmin.bodega;
    const productsList = getStore().whearhouseAdmin.productsInList;

    let arreglo = [];
    productsList.forEach(product => {
        const cantidad_nueva = product.cantidad_por_lote - product.cantidad_baja;


        const producto = {
            inventario: product.inventario,
            cantidad_anterior: product.cantidad_por_lote,
            cantidad_nueva: cantidad_nueva,
            cantidad_baja: product.cantidad_baja,
        };
        arreglo.push(producto);
    });

    const data = {
        comment,
        readjustment_type: 1,
        details: arreglo,
        bodega: bodega.id,
    }

    console.log("Data para enviar: ", data);

    api.post(`reajuste_inventario`, data).then((data) => {
        Swal('Éxito', 'Se ha registrado la baja de producto en bodega.', 'success')
        .then(() => {
            dispatch(setServiceList());
            dispatch(push('/administracion-bodega'))
            const url = "/administrar-bodega/" + bodega.id + "/admin";
            dispatch(push(url));
        })
    }).catch((error) => {
        console.log("Error -------------------> ", error);
        Swal(
            'Error',
             error.detail || 'Ha ocurrido un error, por favor vuelva a intentar.',
            'error'
        );
    }).finally(() => {

    });
}

const finishReadjustment = () => (dispatch, getStore) => {
    const formData = getStore().form.reajusteBodega.values;
    const comment = formData.note !== undefined ? formData.note : '';
    const bodega = getStore().whearhouseAdmin.bodega;
    const productsList = getStore().whearhouseAdmin.productsInList;

    let arreglo = [];
    productsList.forEach(product => {
        const producto = {
            inventario: product.inventario,
            cantidad_anterior: product.cantidad_por_lote,
            cantidad_nueva: product.existencia_real,
        };
        arreglo.push(producto);
    });

    const data = {
        comment,
        readjustment_type: 0,
        details: arreglo,
        bodega: bodega.id,
    }

    console.log("Data para enviar: ", data);

    api.post(`reajuste_inventario`, data).then((data) => {
        Swal('Éxito', 'Se ha registrado el reajuste de producto en bodega.', 'success')
        .then(() => {
            dispatch(setServiceList());
            dispatch(push('/administracion-bodega'))
            const url = "/administrar-bodega/" + bodega.id + "/admin";
            dispatch(push(url));
        })
    }).catch((error) => {
        console.log("Error -------------------> ", error);
        Swal(
            'Error',
             error.detail || 'Ha ocurrido un error, por favor vuelva a intentar.',
            'error'
        );
    }).finally(() => {

    });
}

const finishEntry = () => (dispatch, getStore) => {
    const formData = getStore().form.ingresoBodega.values;
    console.log("formData in Finish Entry: ", formData);
    const comment = formData.comment !== undefined ? formData.comment : '';
    const bodega = getStore().whearhouseAdmin.bodega;
    let arreglo = [];
    const productsList = getStore().whearhouseAdmin.productsInList;
    productsList.forEach(product => {
        const producto = {
            service: product.id,
            quantity: product.cantidad,
            lote: product.lote,
            expiration_date: product.expiracion,
            cost: product.precio,
            fecha_vencimiento: product.vencimiento
        };
        arreglo.push(producto);
    });

    const data = {
                bodega,
                provider: formData.provider.value,
                document_number : formData.document_number,
                comment,
                entry_type: 1,
                details: arreglo,
            }

    console.log("Data ---> ", data);
    api.post(`ingreso_producto`, data).then((data) => {
        Swal('Éxito', 'Se ha registrado la entrada de producto.', 'success')
        .then(() => {
            dispatch(setServiceList());
            dispatch(push('/administracion-bodega'))
            const url = "/administrar-bodega/" + bodega.id + "/admin";
            dispatch(push(url));
        })
    }).catch((error) => {
        Swal(
            'Error',
             error.detail || 'Ha ocurrido un error, por favor vuelva a intentar.',
            'error'
        );
    }).finally(() => {

    });
}

const chargeInventory = (page = 1) => (dispatch, getStore) => {
    const store = getStore().whearhouseAdmin;
    const bodega = store.bodega.id;
    const search = store.buscador_inventario;
    const filtro = store.filtro_inventario;

    let params = {page, search, bodega};

    if(filtro){
        params[''] = filtro
    }

    console.log("PARAMS: ", params);

    api.get("inventario/loadInventory", params).catch((error) => {
        Swal(
            'Error',
            error.detail || 'Ha ocurrido un error, por favor vuelva a intentar.',
            'error'
        );
    }).then((data) => {
        console.log("data charge inventory: ", data);
        dispatch({type: SET_DATA_INVENTORY, data});
        dispatch({type: SET_PAGE_INVENTORY, page_inventario: page});
    })
}

export const actions = {
    setIdBodega,
    listarInventario,
    listar,
    search,
    filtro,
    listarServicios,
    searchService,
    filtroService,
    addServiceToList,
    removeServiceToList,
    upDownCant,
    setLote,
    setVencimiento,
    setPrice,
    finishEntry,
    searchInventario,
    filtroInventario,
    chargeInventory,
    cancelEntry,
    setExistenciaReal,
    cantidadSegunLote,
    finishReadjustment,
    setCantidadBaja,
    finishBaja,
    addServiceToListEntry,
    setCant,
};

export const reducer = {
    [SET_PRODUCTS_LIST]: (state, {productsInList}) => {
        return {
            ... state,
            productsInList,
        }
    },
    [SET_CANTIDAD_LOTE]: (state, {productsInList}) => {
        return {
            ...state,
            productsInList,
        }
    },
    [SET_SERVICE_LIST]: (state) => {
        return {
            ...state,
            productsInList: [],
            total_in_list: 0,
        }
    },
    [LOADER_WHEARHOUSE]: (state, { cargando }) => {
        return {...state, cargando }
    },
    [SET_DATA_WHEARHOUSE]: (state, { data }) => {
        return {...state, data }
    },
    [SET_PAGE_WHEARHOUSE]: (state, { page }) => {
        return {...state, page }
    },
    [SET_PAGE_INVENTORY]: (state, { page_inventario }) => {
        return {...state, page_inventario }
    },
    [SET_BUSCADOR_WHEARHOUSE]: (state, { buscador }) => {
        return {...state, buscador }
    },
    [SET_BUSCADOR_INVENTORY]: (state, { buscador_inventario }) => {
        return {...state, buscador_inventario }
    },
    [SET_FILTRO_WHEARHOUSE]: (state, { filtro_whearhouse }) => {
        return {...state, filtro_whearhouse }
    },
    [SET_BODEGA]: (state, { data }) => {
        return {...state, bodega: data}
    },

    [LOADER_SERVICE]: (state, { cargando }) => {
        return {...state, cargando_service: cargando }
    },
    [SET_DATA_SERVICE]: (state, { data }) => {
        return {...state, data_service : data }
    },
    [SET_PAGE_SERVICE]: (state, { page }) => {
        return {...state, page_service: page }
    },
    [SET_UPDATE_DATA_SERVICE]: (state, { updateData }) => {
        return {...state, update_data_service: updateData }
    },
    [SET_BUSCADOR_SERVICE]: (state, { buscador_service }) => {
        return {...state, buscador_service }
    },
    [SET_FILTRO_SERVICE]: (state, { filtro_services }) => {
        return {...state, filtro_services }
    },
    [SET_FILTRO_INVENTORY]: (state, { filtro_inventario }) => {
        return {...state, filtro_inventario }
    },
    [ADD_PRODUCT_TO_LIST]: (state, { producto }) => {
        return{
            ...state,
            productsInList: [...state.productsInList, producto]
        }
    },
    [REMOVE_SERVICE_TO_LIST]: (state, { index }) => {
        let arreglo = state.productsInList.filter((item, key) => {
             return index !== key;
         });

         return{
             ...state,
             productsInList: arreglo
         }
     },
     [UP_DOWN_CANT]: (state, { data }) => {
         /*
        const arreglo = state.productsInList.filter((item, key) => {
            if(key === data.index){
                if(data.up_down){
                    item.cantidad    = item.cantidad + 1;
                }else{
                    item.cantidad = (item.cantidad > 1) ? item.cantidad - 1 : 1 ;
                }

                item.total = parseFloat(item.precio) * parseFloat(item.cantidad);
            }
            return item;
        });

        return{
            ...state,
            productsInList: arreglo
        }
        */

       return{
        ...state,
        productsInList: data
    }
    },
    [SET_LOTE]: (state, { data }) => {
        const arreglo = state.productsInList.filter((item, key) => {
            if(key === data.index){
                item.lote = data.value;
            }
            return item;
        });

        return{
            ...state,
            productsInList: arreglo
        }
    },
    [SET_PRICE]: (state, { data }) => {
        /*
        const arreglo = state.productsInList.filter((item, key) => {
            if(key === data.index){
                if(data.value === ''){
                    item.precio = undefined;
                }else{
                    item.precio = parseFloat(data.value);
                }
            }

            return item;
        });

        return{
            ...state,
            productsInList: arreglo
        }
        */

       return{
            ...state,
            productsInList: data
        }
    },
    [SET_VENCIMIENTO]: (state, { data }) => {
        let validate_list = true;
        const arreglo = state.productsInList.filter((item) => {
            if(item.id === data.id){
                if(data.value === '' || data.value === undefined){
                    console.log("Error detectado")
                    item.vencimiento_error = true;
                    validate_list = false;
                }else{
                    item.vencimiento = data.value;
                }
            }
            return item;
        });

        return{
            ...state,
            productsInList: arreglo,
            validate_list,
        }
    },
    [TOTAL_CALCULATE_WAREHOUSE_ADMIN]: (state) => {
        let acum = 0;
        state.productsInList.forEach(item => {
            acum += parseFloat(item.total);
        });

        return{
            ...state,
            total_in_list: acum,
        }
    },
    [VALIDATE_LIST]: (state) => {
        console.log("validando datos");
        let validate_list = true;
        const arreglo = state.productsInList.filter((item) => {
            item.precio_error = false;
            if(item.precio === '' || item.precio == undefined ){
                item.precio_error = true;
                validate_list = false;
            }

            return item;
        });

        return{
            ...state,
            productsInList: arreglo,
            validacion: validate_list,
        }
    },
    [SET_DATA_INVENTORY]: (state, { data }) => {
        return {...state, data_inventario : data }
    },

    //Esto es de la parte del reajuste en bodega...
    [SET_EXISTENCIA_REAL]: (state, { data }) => {
        const arreglo = state.productsInList.filter((item, key) => {
            console.log("item en SET_EXISTENCIA_REAL: ", item);
            if(key === data.index){
                item.existencia_real = parseFloat(data.value);
                /*
                if(data.value === ''){
                    item.existencia_real = undefined;
                }else{
                    //item.existencia_real = parseInt(data.value);
                    item.existencia_real = parseFloat(data.value);
                }
                */
            }

            return item;
        });

        return{
            ...state,
            productsInList: arreglo
        }
    },

};

export const initialState = {
    cargando: false,
    page: 1,
    data: {
        count: 0,
        next: null,
        previous: null,
        results: [],
    },

    page_service: 1,
    cargando_service: false,
    data_service: {
        count: 0,
        next: null,
        previous: null,
        results: [],
    },

    buscador_service: '',
    filtro_service: null,

    buscador: '',
    filtro_whearhouse: null,
    updateData: {},
    update_data_service: {},
    id_bodega: 0,
    bodega: null,
    productsInList: [],
    validacion: true,
    total_in_list: 0,

    data_inventario: {
        count: 0,
        next: null,
        previous: null,
        results: [],
    },
    buscador_inventario: '',
    page_inventario: 1,
    cargando_inventario: false,
};

export default handleActions(reducer, initialState)
