import MethodPayment from './depositPayment';
import React, { Component } from 'react';

export default class tabsComponent extends Component{
    state = {
        tab_active: "venta"
    }

    changeTab(tabName){
        this.props.cancelDataPayment(this.props.index,tabName);
        this.setState({tab_active: tabName});
        this.props.forceRendering();
        this.forceUpdate();
    }

    render(){
        let tventa = (this.state.tab_active === "venta") ? "tp-active" : "tp-inactive";
        let tcredito = (this.state.tab_active === "credito") ? "tp-active" : "tp-inactive";
        let firstactive = (this.state.tab_active === "venta") ? "first-active" : "";

        return(
            <div className="ml-4 mr-4 d-flex flex-column h-75">
                <div className={"d-flex flex-wrap flex-row justify-content-start align-items-end w-100 b-white"}>
                    <div className={"d-flex w-30 tab-payment justify-content-center align-items-center " + tventa}>ABONO</div>
                    <div className={"d-flex w-60 tab-comodin justify-content-center align-items-center"} >
                        {/*<button className="btn btn-success btn-sm btn-historial">Historial</button>*/}
                        <a href={"/#/payments-history/" + this.props.factura.dataBilling.id + "/deposito"} className="btn btn-success btn-sm btn-historial">Historial</a>
                    </div>
                </div>
                <div className={"d-flex tab-payment-content b-white p-2 " + firstactive}>
                    {
                        (this.state.tab_active == "venta") ?
                            <React.Fragment>
                                <MethodPayment {...this.props} forceRendering={this.props.forceRendering} />
                            </React.Fragment>
                        :
                            <MethodPayment {...this.props} forceRendering={this.props.forceRendering} />
                    }
                </div>
            </div>
        );
    }
}
