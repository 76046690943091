import moment from "moment";
const url = 'small_box';
import { api } from "api";
import Swal from 'sweetalert2';
import { push } from 'react-router-redux';
import { initialize as initializeForm } from 'redux-form'
import { createActions, handleActions } from 'redux-actions'
import _ from "lodash";

const LOADER_CAJA_CHICA = 'LOADER_CAJA_CHICA';
const SET_DATA_CAJA_CHICA = 'SET_DATA_CAJA_CHICA';
const SET_PAGE_CAJA_CHICA = 'SET_PAGE_CAJA_CHICA';
const SET_UPDATE_DATA_CAJA_CHICA ='SET_UPDATE_DATA_CAJA_CHICA';
const SET_BUSCADOR_CAJA_CHICA = 'SET_BUSCADOR_CAJA_CHICA';
const SET_FILTRO_CAJA_CHICA = 'SET_FILTRO_CAJA_CHICA';
const SET_CAJA_CHICA = 'SET_CAJA_CHICA';
const SET_DATA_MOVEMENTS = 'SET_DATA_MOVEMENTS';
const SET_PAGE_MOVEMENTS = 'SET_PAGE_MOVEMENTS';
const SET_BUSCADOR_MOVEMENTS = 'SET_BUSCADOR_MOVEMENTS';
const LOADER_MOVEMENTS = 'LOADER_MOVEMENTS';
const SET_TOTAL_SMALL_BOX = 'SET_TOTAL_SMALL_BOX';
const SET_INITIAL_DATE_SMALL_BOX_1 = 'SET_INITIAL_DATE_SMALL_BOX_1';
const SET_FINAL_DATE_SMALL_BOX_1 = 'SET_FINAL_DATE_SMALL_BOX_1';

const listar = (page = 1) =>  (dispatch, getStore) => {
    dispatch({type: LOADER_CAJA_CHICA, cargando: true});
    const store = getStore();
    const search = store.small_box.buscador;
    const filtro = store.small_box.filtro_cajas;
    let params = {page, search};

    if(filtro){
        params[''] = filtro
    }
    api.get(`${url}`, params).catch((error) => {
        dispatch({type: LOADER_CAJA_CHICA, cargando: false});
        Swal(
            'Error',
            error.detail || 'Ha ocurrido un error, por favor vuelva a intentar.',
            'error'
        );
    }).then((data) => {
        if(data){
            console.log("data: ", data);
            dispatch({type: SET_DATA_CAJA_CHICA, data});
            dispatch({type: SET_PAGE_CAJA_CHICA, page});
        }
        dispatch({type: LOADER_CAJA_CHICA, cargando: false});
    })
};

const detail = id => (dispatch, getState) =>{
    dispatch({type: LOADER_CAJA_CHICA, cargando: true});
    api.get(`${url}/${id}`).catch((error) => {
        dispatch({type: LOADER_CAJA, cargando: false});
        if(error.statusCode  === 404){
            dispatch(push('/small-box'))
        }
    }).then((data) => {
        dispatch({type: LOADER_CAJA_CHICA, cargando: false});
        if(data){
            data.subsidiary.value = data.subsidiary.id;
            data.subsidiary.label = data.subsidiary.name;
            dispatch({type: SET_UPDATE_DATA_CAJA_CHICA, updateData: data});
            dispatch(initializeForm('smallBox', data))
        }
    })
}

const create = () => (dispatch, getStore) => {
    const formData = getStore().form.smallBox.values;
    formData.subsidiary = formData.subsidiary.value;
    dispatch({type: LOADER_CAJA_CHICA, cargando: true})

    console.log("formData: ", formData);

    api.post(`${url}`, formData).then((data) => {
        dispatch({type: LOADER_CAJA_CHICA, cargando: false})
        Swal('Éxito', 'Se ha creado la caja exitosamente.', 'success')
        .then(() => {
            dispatch(push('/small-box'))
        })
    }).catch((error) => {
        dispatch({type: LOADER_CAJA_CHICA, cargando: false})
        Swal(
            'Error',
             error.detail || 'Ha ocurrido un error, por favor vuelva a intentar.',
            'error'
        );
    }).finally(() => {
        dispatch({type: LOADER_CAJA_CHICA, cargando: false})
    });
};

const update = () => (dispatch, getStore) => {
    const formData = _.cloneDeep(getStore().form.smallBox.values);
    dispatch({type: LOADER_CAJA_CHICA, cargando: true});
    formData.subsidiary = formData.subsidiary.value;
    api.put(`${url}/${formData.id}`, formData)
    .then((data) => {
        dispatch({type: LOADER_CAJA_CHICA, cargando: false});
        if(data){
            Swal(
                'Éxito',
                'Datos editados correctamente.',
                'success'
            ).then(() => {
                dispatch(push('/small-box'));
            })
        }
    })
    .catch((error) =>{
        dispatch({type: LOADER_CAJA_CHICA, cargando: false});
        Swal(
            'Error',
             error.detail || 'Ha ocurrido un error, verifique los datos.',
            'error'
        )
        if(error.statusCode === 404){
            dispatch(push('/small-box'));
        }
    })
}
const destroy = (id) => (dispatch, getStore) => {
    dispatch({type: LOADER_CAJA_CHICA, cargando: true});
    api.eliminar(`${url}/${id}`).catch((error) => {
        dispatch({type: LOADER_CAJA_CHICA, cargando: false});
        Swal('Error', 'No se ha logrado borrar el registro.', 'error')
            .then(() => {
                dispatch(listar(1));
        })
    }).then((data) => {
        dispatch({type: LOADER_CAJA_CHICA, cargando: false});
        Swal('Éxito', 'Se ha eliminado correctamente.', 'success')
            .then(() => {
                dispatch(listar(1));
            })
    })
}

const search = (search) => (dispatch, getStore) => {
    dispatch({type: SET_BUSCADOR_CAJA_CHICA, buscador: search});
    dispatch(listar(1));
};

const filtro = (filtro) => (dispatch, getStore) => {
    if (filtro == 0){
        filtro = null
    }
    dispatch({type: SET_FILTRO_CAJA_CHICA, filtro_cajas: filtro});
    dispatch(listar(1));
};

/* Del Admin */
const setSmallBox = id => (dispatch, getState) =>{
    dispatch({type: LOADER_CAJA_CHICA, cargando: true});
    api.get(`${url}/${id}`).catch((error) => {
        // dispatch({type: LOADER_CAJA, cargando: false});
        if(error.statusCode  === 404){
            dispatch(push('/adm-smbx'))
        }
    }).then((data) => {
        dispatch({type: LOADER_CAJA_CHICA, cargando: false});
        if(data){
            dispatch({type: SET_CAJA_CHICA, small_box: data});
        }
    })
}

const getSmallBoxMovements = (page = 1, id=null) => (dispatch, getStore) => {
    dispatch({type: LOADER_MOVEMENTS, cargando_movements: true});

    const store = _.cloneDeep(getStore().small_box);
    let initial_date = store.initialDate;
    let final_date = store.finalDate

    let small_box = 0;
    if(id !== null){
        small_box = id;
    }else{
        small_box = store.small_box.id;
    }
    if(initial_date === undefined){
        initial_date = moment().date(1).format("DD/MM/YYYY")
    }
    if(final_date === undefined){
        final_date = moment().format("DD/MM/YYYY")
    }
    console.log('DATE', initial_date);
    console.log('DATE 2', final_date);
    const search = store.buscador_movement;
    const filtro = store.filtro_movement;

    let params = {page, search, small_box, initial_date, final_date};
    if(filtro){
        params[''] = filtro
    }

    api.get("small_box_movement/getSmallBoxMovements/", params).catch((error) => {
        Swal(
            'Error',
            error.detail || 'Ha ocurrido un error, por favor vuelva a intentar.',
            'error'
        );
    }).then((data) => {
        console.log("small box movements: *-------------------> ", data);
        dispatch({type: SET_DATA_MOVEMENTS, data_movements: data });
        dispatch({type: SET_PAGE_MOVEMENTS, page_movements: page});
        dispatch({type: SET_INITIAL_DATE_SMALL_BOX_1, initialDate: initial_date});
        dispatch({type: SET_FINAL_DATE_SMALL_BOX_1, finalDate: final_date});
        api.get("small_box_movement/getTotalSmallBoxMovements/", params).catch((error) => {
            Swal(
                'Error',
                error.detail || 'Ha ocurrido un error, por favor vuelva a intentar.',
                'error'
            ).then(r => console.log('success'));
        }).then((response) => {
            dispatch({type: SET_TOTAL_SMALL_BOX, total_small_box: response.total});

            console.log("total: *-------------------> ", response);
        })
    }).finally(()=>{
        dispatch({type: LOADER_MOVEMENTS, cargando_movements: false});
    })
}

const searchMovements = (search) => (dispatch, getStore) => {
    dispatch({type: SET_BUSCADOR_MOVEMENTS, buscador_movement: search});
    dispatch(getSmallBoxMovements(1));
};

const createMovement = () => (dispatch, getStore) => {
    const store = getStore().small_box;
    const formData = getStore().form.formSmallBoxMovement.values;
    const small_box = store.small_box.id;
    formData.small_box = small_box;
    formData.bank_account = formData.bank_account.value;

    console.log("FormData: ", formData);

    api.post("small_box_movement/registerMovement/", formData).then((data) => {
        dispatch({type: LOADER_MOVEMENTS, cargando: false})
        Swal('Éxito', 'Movimiento registrado correctamente', 'success')
        .then(() => {
            dispatch(getSmallBoxMovements(1));
        })
    }).catch((error) => {
        Swal(
            'Error',
             error.detail || 'Ha ocurrido un error, por favor vuelva a intentar.',
            'error'
        );
    }).finally(() => {
        dispatch({type: LOADER_MOVEMENTS, cargando: false})
    });

}
const setInitialData = () => (dispatch) => {
    dispatch({
        type: SET_DATA_MOVEMENTS, data_movements: {
            count: 0,
            next: null,
            previous: null,
            results: [],
        }
    });
    dispatch({type: SET_PAGE_MOVEMENTS, page_movements: 1});
    dispatch({type: SET_TOTAL_SMALL_BOX, total_small_box: 0});
    dispatch({type: SET_CAJA_CHICA, small_box: null});
    dispatch({type: SET_BUSCADOR_MOVEMENTS, buscador_movement: ''});
}

const setFinalDate1 = (value) => (dispatch) => {
    dispatch({type: SET_FINAL_DATE_SMALL_BOX_1, finalDate: value});
    dispatch(getSmallBoxMovements());
}

const setInitialDate1 = (value) => (dispatch) => {
    dispatch({type: SET_INITIAL_DATE_SMALL_BOX_1, initialDate: value});
    dispatch(getSmallBoxMovements());
}

export const actions = {
    listar,
    detail,
    create,
    update,
    destroy,
    search,
    filtro,

    //del admin
    setSmallBox,
    getSmallBoxMovements,
    searchMovements,
    createMovement,
    setFinalDate1,
    setInitialDate1,
    setInitialData,
};

export const reducer = {
    [SET_CAJA_CHICA]: (state, { small_box }) => {
        return {...state, small_box }
    },
    [SET_DATA_MOVEMENTS]: (state, { data_movements }) => {
        return {...state, data_movements }
    },
    [SET_PAGE_MOVEMENTS]: (state, { page_movements }) => {
        return {...state, page_movements }
    },
    [SET_BUSCADOR_MOVEMENTS]: (state, { buscador_movement }) => {
        return {...state, buscador_movement }
    },
    [LOADER_MOVEMENTS]: (state, { cargando_movements }) => {
        return {...state, cargando_movements }
    },

    [LOADER_CAJA_CHICA]: (state, { cargando }) => {
        return {...state, cargando }
    },
    [SET_DATA_CAJA_CHICA]: (state, { data }) => {
        return {...state, data }
    },
    [SET_PAGE_CAJA_CHICA]: (state, { page }) => {
        return {...state, page }
    },
    [SET_UPDATE_DATA_CAJA_CHICA]: (state, { updateData }) => {
        return {...state, updateData }
    },
    [SET_BUSCADOR_CAJA_CHICA]: (state, { buscador }) => {
        return {...state, buscador }
    },
    [SET_FILTRO_CAJA_CHICA]: (state, { filtro_cajas }) => {
        return {...state, filtro_cajas }
    },
    [SET_TOTAL_SMALL_BOX]: (state, { total_small_box }) => {
        return {...state, total_small_box }
    },
    [SET_INITIAL_DATE_SMALL_BOX_1]: (state, { initialDate }) => {
        return{...state,initialDate}
    },
    [SET_FINAL_DATE_SMALL_BOX_1]: (state, { finalDate }) => {
        return{...state,finalDate}
    },
}

export const initialState = {
    cargando: false,
    page: 1,
    data: {
        count: 0,
        next: null,
        previous: null,
        results: [],
    },
    buscador: '',
    filtro_cajas: null,
    updateData: {},

    //del admin
    cargando_movements: false,
    page_movements: 1,
    data_movements: {
        count: 0,
        next: null,
        previous: null,
        results: [],
    },
    buscador_movement: '',
    filtro_movement: null,
    small_box: null,
    total_small_box: 0,
    initialDate: moment().date(1).format("DD/MM/YYYY"),
    finalDate: moment().format("DD/MM/YYYY"),
};

export default handleActions(reducer, initialState)
