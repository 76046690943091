import Swal from 'sweetalert2';
import { api } from 'api';
import { createActions, handleActions } from 'redux-actions'
import { goBack } from 'react-router-redux'
import { initialize as initializeForm } from 'redux-form'
import { push } from 'react-router-redux';
import moment from "moment";
const url = 'appointments';
import { departamentos, municipios } from 'variables';

const LOADER_APPOINTMENTS = 'LOADER_APPOINTMENTS';
const SET_DATA_APPOINTMENTS = 'SET_DATA_APPOINTMENTS';
const SET_PAGE_APPOINTMENTS = 'SET_PAGE_APPOINTMENTS';
const SET_UPDATE_DATA_APPOINTMENTS ='SET_UPDATE_DATA_APPOINTMENTS';
const SET_BUSCADOR_APPOINTMENTS = 'SET_BUSCADOR_APPOINTMENTS';
const SET_RESULT_APPOINTMENTS = 'SET_RESULT_APPOINTMENTS';
const SET_FILTRO_APPOINTMENTS = 'SET_FILTRO_APPOINTMENTS';
const SET_INITIALIZE_FORM_DATA = 'SET_INITIALIZE_FORM_DATA';
const SET_APPOINTMENTS_APPOINTMENTS = 'SET_APPOINTMENTS_APPOINTMENTS';
const SET_OVERLAPS_APPOINTMENTS = 'SET_OVERLAPS_APPOINTMENTS';
const SET_DOCTORS = 'SET_DOCTORS';

const SET_TOOLTIP_INFO = 'SET_TOOLTIP_INFO';
const SET_SHOW_TOOLTIP_MODAL = 'SET_SHOW_TOOLTIP_MODAL';

const MALE = 1
const FEMALE = 2

const MALE_STR = 'Masculino'
const FEMALE_STR = 'Femenino'

const DOCTOR = 0

const hours = [
    {value:0.0, label:'0:00 AM', hrs:'00:00'}, {value:0.5, label:'0:30 AM', hrs:'00:30'},
    {value:1.0, label:'1:00 AM', hrs:'01:00'}, {value:1.5, label:'1:30 AM', hrs:'01:30'},
    {value:2.0, label:'2:00 AM', hrs:'02:00'}, {value:2.5, label:'2:30 AM', hrs:'02:30'},
    {value:3.0, label:'3:00 AM', hrs:'03:00'}, {value:3.5, label:'3:30 AM', hrs:'03:30'},
    {value:4.0, label:'4:00 AM', hrs:'04:00'}, {value:4.5, label:'4:30 AM', hrs:'04:30'},
    {value:5.0, label:'5:00 AM', hrs:'05:00'}, {value:5.5, label:'5:30 AM', hrs:'05:30'},
    {value:6.0, label:'6:00 AM', hrs:'06:00'}, {value:6.5, label:'6:30 AM', hrs:'06:30'},
    {value:7.0, label:'7:00 AM', hrs:'07:00'}, {value:7.5, label:'7:30 AM', hrs:'07:30'},
    {value:8.0, label:'8:00 AM', hrs:'08:00'}, {value:8.5, label:'8:30 AM', hrs:'08:30'},
    {value:9.0, label:'9:00 AM', hrs:'09:00'}, {value:9.5, label:'9:30 AM', hrs:'09:30'},

    {value:10.0, label:'10:00 AM', hrs:'10:00'}, {value:10.5, label:'10:30 AM', hrs:'10:30'},
    {value:11.0, label:'11:00 AM', hrs:'11:00'}, {value:11.5, label:'11:30 AM', hrs:'11:30'},
    {value:12.0, label:'12:00 PM', hrs:'12:00'}, {value:12.5, label:'12:30 PM', hrs:'12:30'},
    {value:13.0, label:'1:00 PM', hrs:'13:00'}, {value:13.5, label:'1:30 PM', hrs:'13:30'},
    {value:14.0, label:'2:00 PM', hrs:'14:00'}, {value:14.5, label:'2:30 PM', hrs:'14:30'},
    {value:15.0, label:'3:00 PM', hrs:'15:00'}, {value:15.5, label:'3:30 PM', hrs:'15:30'},
    {value:16.0, label:'4:00 PM', hrs:'16:00'}, {value:16.5, label:'4:30 PM', hrs:'16:30'},
    {value:17.0, label:'5:00 PM', hrs:'17:00'}, {value:17.5, label:'5:30 PM', hrs:'17:30'},
    {value:18.0, label:'6:00 PM', hrs:'18:00'}, {value:18.5, label:'6:30 PM', hrs:'18:30'},
    {value:19.0, label:'7:00 PM', hrs:'19:00'}, {value:19.5, label:'7:30 PM', hrs:'19:30'},
    {value:20.0, label:'8:00 PM', hrs:'20:00'}, {value:20.5, label:'8:30 PM', hrs:'20:30'},
    {value:21.0, label:'9:00 PM', hrs:'21:00'}, {value:21.5, label:'9:30 PM', hrs:':2130'},
    {value:22.0, label:'10:00 PM', hrs:'22:00'}, {value:22.5, label:'10:30 PM', hrs:'22:30'},
    {value:23.0, label:'11:00 PM', hrs:'23:00'}, {value:23.5, label:'11:30 PM', hrs:'23:30'},
]

const getDoctors = () => (dispatch, getStore) => {
    let persons = []
    api.get("referents/listAllDoctors").catch((error) => {})
        .then((data) => {
            if (data){
                persons = [];
                data.results.forEach( x => { persons.push(x) })
                dispatch({type: SET_DOCTORS, doctors: persons});
            }
        })
        .catch(e => {

        })
}

export const setUpdateData = updateData => ({
    type: SET_UPDATE_DATA_APPOINTMENTS,
    updateData,
});


const listar = (initial_date, final_date, doctor_id='', exclude_locks=0) =>  (dispatch, getStore) => {
    dispatch({type: LOADER_APPOINTMENTS, cargando: true});

    const current_user = getStore().login.me;
    const subsidiary = current_user.person_user.subsidiary;
    // const user = current_user.id;

    let params = {initial_date, final_date, doctor_id, exclude_locks, subsidiary};

    api.get('appointments/all', params).catch((error) => {
        dispatch({type: LOADER_APPOINTMENTS, cargando: false});
        Swal(
            'Error',
            error.detail || 'Ha ocurrido un error, por favor vuelva a intentar.',
            'error'
        );
    }).then((data) => {
        console.log("data list appointments: ", data);

        if(data){
            dispatch({type: SET_DATA_APPOINTMENTS, data});
            let day = moment(initial_date)
            let gap = 0
            let overlap_level = 1
            let first_start_value = 0
            let last_end_value = 0

            let overlaps = []
            let columns = []
            let all_appointments = []

            for (let index = 0; index < 7; index++) {
                overlaps.push(new Array(48).fill(null))
                let register = data.filter(function (el) {
                    return el.date == day.format('YYYY-MM-DD');
                }).sort((item, item_2) => {
                    if (item.start_value - item_2.start_value === 0)
                        return (
                            (item_2.end_value - item_2.start_value) - (item.end_value - item.start_value)
                        )
                    return item.start_value - item_2.start_value
                })

                all_appointments.push(register)

                for (let index_2 = 1; index_2 < register.length; index_2++) {
                    if(index_2 - 1 == 0){
                        gap = 0
                        overlap_level = 1
                        first_start_value = 0
                        last_end_value = 0
                    }
                    if (parseInt(register[index_2].start_value * 2) < parseInt(register[index_2 - 1].end_value * 2) || parseInt(register[index_2].start_value * 2) < parseInt(last_end_value)) {
                        overlap_level = overlap_level + 1
                        if (gap == 0) {
                            gap = 1
                            let overlap_values = new Array(3)
                            first_start_value = register[index_2 - 1].start_value * 2
                            if (parseInt(register[index_2].end_value * 2) > parseInt(register[index_2 - 1].end_value * 2)) {
                                last_end_value = parseInt(register[index_2].end_value * 2)
                            } else {
                                last_end_value = parseInt(register[index_2 - 1].end_value * 2)
                            }
                            overlap_values[0] = first_start_value
                            overlap_values[1] = last_end_value
                            overlap_values[2] = overlap_level
                            overlaps[index][first_start_value] = overlap_values
                            // if(index== 1){
                            //     console.log("VALORES: ", overlap_values)
                            // }
                        } else {
                            if (parseInt(register[index_2].end_value * 2) > last_end_value) {
                                last_end_value = parseInt(register[index_2].end_value * 2)
                                overlaps[index][first_start_value][1] = last_end_value
                            }
                            overlaps[index][first_start_value][2] = overlap_level
                        }
                    } else {
                        gap = 0
                        overlap_level = 1
                        first_start_value = 0
                        last_end_value = 0
                    }

                }
                day = moment(day, "DD-MM-YYYY").add(1, 'days')
            }

            // console.log("ALL APPOINTMENTS", all_appointments)

            for (let index = 0; index < 7; index++) {
                if(all_appointments[index].length){
                    let column = new Array(48).fill(null).map(() => { return [0] })

                    // columns.push(Array(48).fill(null).map(() => {
                    //     return [null]
                    // }))
                    for (let index_2 = 0; index_2 < overlaps[index].length; index_2++) {
                        if(overlaps[index][index_2] != null){
                            // console.log("OVERLAP OBJECT: ", overlaps[index][index_2][2])
                            for (let index_3 = overlaps[index][index_2][0]; index_3 < overlaps[index][index_2][1]; index_3++) {
                                column[index_3] = new Array(overlaps[index][index_2][2]).fill(0)
                                // column[index_3] = new Array(overlaps[index][index_2][2]).fill(null)
                                // column[index_3][0] = 0
                            }
                        }
                    }
                    columns.push(column)

                } else (
                    columns.push(new Array(48).fill([null]))
                )
            }

            // HASTA AQUÍ YA ESTÁ EL ESPACIADO DE LAS MATRICES SOLO HACE FALTA COLOCAR LOS DATOS PARA QUE SE RENDERIZEN CORRECTAMENTE

            let horizantal_deep = 0;
            let sticker_id = 0;
            for (let index = 0; index < all_appointments.length; index++) {
                for (let index_2 = 0; index_2 < all_appointments[index].length; index_2++) {
                    horizantal_deep = 0;
                    for (let horizontal_position = 0; horizontal_position < columns[index][all_appointments[index][index_2].start_value * 2].length; horizontal_position++) {
                        if(columns[index][all_appointments[index][index_2].start_value * 2][horizontal_position] == 0 || columns[index][all_appointments[index][index_2].start_value * 2][horizontal_position] == null){
                            horizantal_deep = horizontal_position
                            horizontal_position = all_appointments[index].length
                        }
                    }
                    for (let index_3 = parseInt(all_appointments[index][index_2].start_value * 2); index_3 < parseInt(all_appointments[index][index_2].end_value * 2); index_3++) {
                        //console.log("all_appointments[index][index_2]: ", all_appointments[index][index_2]);
                        if (index_3 == parseInt(all_appointments[index][index_2].start_value * 2)) {
                            let obj
                            if (all_appointments[index][index_2].patient != null) {

                                let paciente = all_appointments[index][index_2].in_line === true ? all_appointments[index][index_2].patient !== null ? all_appointments[index][index_2].patient.first_name + ' ' + all_appointments[index][index_2].patient.last_name : all_appointments[index][index_2].patient_name : all_appointments[index][index_2].patient_name;
                                
                                paciente += all_appointments[index][index_2].note ? ` / ${all_appointments[index][index_2].note}` : '';

                                obj = {
                                    'first': 1,
                                    'sid': sticker_id,
                                    'id': all_appointments[index][index_2].id,
                                    'color': all_appointments[index][index_2].doctor.color,
                                    //'patient': all_appointments[index][index_2].patient.first_name + ' ' + all_appointments[index][index_2].patient.last_name,
                                    //'patient': all_appointments[index][index_2].patient_name,
                                    'requirements': all_appointments[index][index_2].requirements,
                                    'note': all_appointments[index][index_2].note,
                                    'patient': paciente,
                                    'holder': all_appointments[index][index_2].appointment_holder,
                                    'phone': all_appointments[index][index_2].phone_holder,
                                    'cellphone': all_appointments[index][index_2].cellphone_holder,
                                    'date': all_appointments[index][index_2].date,
                                    'start': hours[parseInt(all_appointments[index][index_2].start_value * 2)].label,
                                    'end': hours[parseInt(all_appointments[index][index_2].end_value * 2)].label,
                                    'locked': all_appointments[index][index_2].locked,
                                    //add..
                                    'created': all_appointments[index][index_2].created,
                                    'user': all_appointments[index][index_2].user.username,
                                    'in_line': all_appointments[index][index_2].in_line,
                                }
                                sticker_id = sticker_id + 1
                            } else {
                                //const paciente = all_appointments[index][index_2].in_line === true ? all_appointments[index][index_2].patient.first_name + ' ' + all_appointments[index][index_2].patient.last_name : all_appointments[index][index_2].patient_name;
                                let paciente = all_appointments[index][index_2].in_line === true ? all_appointments[index][index_2].patient !== null ? all_appointments[index][index_2].patient.first_name + ' ' + all_appointments[index][index_2].patient.last_name : all_appointments[index][index_2].patient_name : all_appointments[index][index_2].patient_name;

                                paciente += all_appointments[index][index_2].note ? ` / ${all_appointments[index][index_2].note}` : '';

                                obj = {
                                    'first': 1,
                                    'sid': sticker_id,
                                    'id': all_appointments[index][index_2].id,
                                    'color': all_appointments[index][index_2].doctor.color,
                                    //'patient': all_appointments[index][index_2].patient_name,
                                    //'patient': all_appointments[index][index_2].patient_name,
                                    'requirements': all_appointments[index][index_2].requirements,
                                    'note': all_appointments[index][index_2].note,
                                    'patient': paciente,
                                    'holder': all_appointments[index][index_2].appointment_holder,
                                    'phone': all_appointments[index][index_2].phone_holder,
                                    'cellphone': all_appointments[index][index_2].cellphone_holder,
                                    'date': all_appointments[index][index_2].date,
                                    'start': hours[parseInt(all_appointments[index][index_2].start_value * 2)].label,
                                    'end': hours[parseInt(all_appointments[index][index_2].end_value * 2)].label,
                                    'locked': all_appointments[index][index_2].locked,
                                    //add..
                                    'created': all_appointments[index][index_2].created,
                                    'user': all_appointments[index][index_2].user.username,
                                    'in_line': all_appointments[index][index_2].in_line,
                                }
                                sticker_id = sticker_id + 1
                            }
                            columns[index][index_3][horizantal_deep] = obj
                        } else {
                            let obj
                            if (all_appointments[index][index_2].patient != null) {
                                //const paciente = all_appointments[index][index_2].in_line === true ? all_appointments[index][index_2].patient.first_name + ' ' + all_appointments[index][index_2].patient.last_name : all_appointments[index][index_2].patient_name;
                                let paciente = all_appointments[index][index_2].in_line === true ? all_appointments[index][index_2].patient !== null ? all_appointments[index][index_2].patient.first_name + ' ' + all_appointments[index][index_2].patient.last_name : all_appointments[index][index_2].patient_name : all_appointments[index][index_2].patient_name;

                                paciente += all_appointments[index][index_2].note ? ` / ${all_appointments[index][index_2].note}` : '';

                                obj = {
                                    'sid': sticker_id,
                                    'id': all_appointments[index][index_2].id,
                                    'color': all_appointments[index][index_2].doctor.color,
                                    //'patient': all_appointments[index][index_2].patient.first_name + ' ' + all_appointments[index][index_2].patient.last_name,
                                    //'patient': all_appointments[index][index_2].patient_name,
                                    'requirements': all_appointments[index][index_2].requirements,
                                    'note': all_appointments[index][index_2].note,
                                    'patient': paciente,
                                    'holder': all_appointments[index][index_2].appointment_holder,
                                    'phone': all_appointments[index][index_2].phone_holder,
                                    'cellphone': all_appointments[index][index_2].cellphone_holder,
                                    'date': all_appointments[index][index_2].date,
                                    'start': hours[parseInt(all_appointments[index][index_2].start_value * 2)].label,
                                    'end': hours[parseInt(all_appointments[index][index_2].end_value * 2)].label,
                                    'locked': all_appointments[index][index_2].locked,
                                    //add..
                                    'created': all_appointments[index][index_2].created,
                                    'user': all_appointments[index][index_2].user.username,
                                    'in_line': all_appointments[index][index_2].in_line,
                                }
                                sticker_id = sticker_id + 1
                            } else {
                                //const paciente = all_appointments[index][index_2].in_line === true ? all_appointments[index][index_2].patient.first_name + ' ' + all_appointments[index][index_2].patient.last_name : all_appointments[index][index_2].patient_name;
                                let paciente = all_appointments[index][index_2].in_line === true ? all_appointments[index][index_2].patient !== null ? all_appointments[index][index_2].patient.first_name + ' ' + all_appointments[index][index_2].patient.last_name : all_appointments[index][index_2].patient_name : all_appointments[index][index_2].patient_name;

                                paciente += all_appointments[index][index_2].note ? ` / ${all_appointments[index][index_2].note}` : '';

                                obj = {
                                    'sid': sticker_id,
                                    'id': all_appointments[index][index_2].id,
                                    'color': all_appointments[index][index_2].doctor.color,
                                    //'patient': all_appointments[index][index_2].patient_name,
                                    //'patient': all_appointments[index][index_2].patient_name,
                                    'requirements': all_appointments[index][index_2].requirements,
                                    'note': all_appointments[index][index_2].note,
                                    'patient': paciente,
                                    'holder': all_appointments[index][index_2].appointment_holder,
                                    'phone': all_appointments[index][index_2].phone_holder,
                                    'cellphone': all_appointments[index][index_2].cellphone_holder,
                                    'date': all_appointments[index][index_2].date,
                                    'start': hours[parseInt(all_appointments[index][index_2].start_value * 2)].label,
                                    'end': hours[parseInt(all_appointments[index][index_2].end_value * 2)].label,
                                    'locked': all_appointments[index][index_2].locked,
                                    //add..
                                    'created': all_appointments[index][index_2].created,
                                    'user': all_appointments[index][index_2].user.username,
                                    'in_line': all_appointments[index][index_2].in_line,
                                }
                                sticker_id = sticker_id + 1
                            }
                            columns[index][index_3][horizantal_deep] = obj
                            // let obj = {
                            //     'sdi': sticker_id,
                            //     'id': all_appointments[index][index_2].id,
                            //     'color': all_appointments[index][index_2].doctor.color
                            // }
                            // sticker_id = sticker_id + 1
                            // columns[index][index_3][horizantal_deep] = obj
                        }
                        // if(columns[index][index_3].length){
                        //     columns[index][index_3][horizantal_deep] = "x"
                        // }

                    }
                    // sticker_id = sticker_id + 1
                }
            }

            let max_position = 0
            for (let index = 0; index < overlaps.length; index++) {
                for (let index_2 = 0; index_2 < overlaps[index].length; index_2++) {
                    if(overlaps[index][index_2] != null){
                        max_position = 0
                        for (let index_3 = overlaps[index][index_2][0]; index_3 < overlaps[index][index_2][1]; index_3++) {
                            for (let index_4 = 0; index_4 < columns[index][index_3].length; index_4++) {
                                if(columns[index][index_3][index_4] != 0 && index_4 > max_position){
                                    max_position = index_4
                                }
                            }

                        }
                        for (let index_5 = overlaps[index][index_2][0]; index_5 < overlaps[index][index_2][1]; index_5++) {
                            for (let index_6 = max_position + 1; index_6 < overlaps[index][index_2][2]; index_6++) {
                                // columns[index][index_5][index_6] = null
                                columns[index][index_5].pop()
                            }
                        }
                    }
                }
            }

            // console.log("APPOINTMENTS: ", all_appointments)
            // console.log("Columns: ", columns)
            // console.log("ARRAY OVERLAPS -------------- \n",overlaps,"\n")
            dispatch({type: SET_OVERLAPS_APPOINTMENTS, overlaps})
            dispatch({type: SET_APPOINTMENTS_APPOINTMENTS, appointments: columns});
        }
        dispatch({type: LOADER_APPOINTMENTS, cargando: false});
    })
};

const detailPatient = id => (dispatch, getState) =>{
    dispatch({type: LOADER_APPOINTMENTS, cargando: true});
    api.get(`patients/${id}`).catch((error) => {
        dispatch({type: LOADER_APPOINTMENTS, cargando: false});
        if(error.statusCode  === 404){
            dispatch(push('/'))
        }
    }).then((data) => {
        dispatch({type: LOADER_APPOINTMENTS, cargando: false});
        if(data){
            const old_data = getState().form.appointments.values;
            data.patient_id = data.id
            delete data.id
            if(data.gender == MALE){
                data.gender = {value: MALE, label: 'Masculino'}
            } else {
                data.gender = {value: FEMALE, label: 'Femenino'}
            }
            for (let index = 0; index < departamentos.length; index++) {
                if(departamentos[index].value == data.departamento){
                    data.departamento = departamentos[index];
                }
            }
            for (let index = 0; index < municipios.length; index++) {
                if(municipios[index].value == data.municipio){
                    data.municipio = municipios[index];
                }
            }
            if(data.family_member_of){
                data.family_member_of.value = data.family_member_of.id;
                data.family_member_of.label = data.family_member_of.name;
            }
            if(data.referred_by){
                data.referred_by.value = data.referred_by.id;
                data.referred_by.label = data.referred_by.name;
            }
            dispatch(initializeForm('appointments', {...data, ...old_data}))
        }
    })
}

const detail = id => (dispatch, getState) =>{
    dispatch({type: LOADER_APPOINTMENTS, cargando: true});
    api.get(`${url}/${id}`).catch((error) => {
        dispatch({type: LOADER_APPOINTMENTS, cargando: false});
        if(error.statusCode  === 404){
            dispatch(push('/appointments'))
        }
    }).then((data) => {
        dispatch({type: LOADER_APPOINTMENTS, cargando: false});
        if(data){
            let temporary = {}
            temporary.value = data.doctor.id;
            temporary.label = data.doctor.name;
            data.doctor = temporary;
            temporary = {}
            data.start = hours.find((item, index) => item.value == parseFloat(data.start_value))
            data.end = hours.find((item, index) => item.value == parseFloat(data.end_value))
            data.start.label = data.start.hrs
            data.end.label = data.end.hrs
            // delete data.start.hrs
            // delete data.end.hrs
            if(!data.patient){
                delete data.patient;
            } else {
                data.patient_id = data.patient.id;
                data.first_name = data.patient.first_name;
                data.last_name = data.patient.last_name;
                data.birthdate = data.patient.birthdate;
                data.nationality = data.patient.nationality;
                data.gender = {}
                if(data.patient.gender == MALE){
                    data.gender.value = MALE;
                    data.gender.label = MALE_STR;
                } else {
                    data.gender.value = MALE;
                    data.gender.label = MALE_STR;
                }
                if(data.patient.address != null){
                    data.address = data.patient.address
                }
                if(data.patient.cellphone != null){
                    data.cellphone = data.patient.cellphone;
                }
                if(data.patient.dpi != null){
                    data.dpi = data.patient.dpi;
                }
                if(data.patient.email != null){
                    data.email = data.patient.email;
                }
                if(data.patient.family_member_of != null){
                    data.family_member_of = {}
                    data.family_member_of.value = data.patient.family_member_of.id;
                    data.family_member_of.label = data.patient.family_member_of.name;
                }
                if(data.patient.referred_by != null){
                    data.referred_by = {}
                    data.referred_by.value = data.patient.referred_by.id;
                    data.referred_by.label = data.patient.referred_by.name;
                }
                if(data.patient.married_last_name != null){
                    data.married_last_name = data.patient.married_last_name;
                }
                if(data.patient.phone != null){
                    data.phone = data.patient.phone;
                }
                if(data.patient.profession != null){
                    data.profession = data.patient.profession;
                }
                for (let index = 0; index < departamentos.length; index++) {
                    if(departamentos[index].value == data.patient.departamento){
                        data.departamento = departamentos[index];
                    }
                }
                for (let index = 0; index < municipios.length; index++) {
                    if(municipios[index].value == data.patient.municipio){
                        data.municipio = municipios[index];
                    }
                }
            }

            delete data.active;
            delete data.created;
            delete data.patient;
            delete data.modified;
            delete data.end_value;
            delete data.start_value;
            delete data.appointment_type;
            delete data.subsidiary;
            data.cuenta = 0;

            dispatch({type: SET_UPDATE_DATA_APPOINTMENTS, updateData: data});
            dispatch(initializeForm('appointments', data))
        }
    })
}

const create = () => (dispatch, getStore) => {
    const formData = _.cloneDeep(getStore().form.appointments.values);

    const current_user = getStore().login.me;

    formData.subsidiary = current_user.person_user.subsidiary;
    formData.user = current_user.id;

    formData.doctor = formData.doctor.value;
    formData.start_value = formData.start.value;


    formData.end_value = formData.end.value;
    formData.end_value = formData.end.value;

    formData.start = formData.start.label + ':00';
    formData.end = formData.end.label + ':00';
    formData.appointment_type = DOCTOR

    if (formData.cellphone_holder == undefined){
        formData.cellphone_holder = ''
    }

    if(formData.first_name && formData.first_name != null){
        formData.patient = {}
        formData.patient.first_name = formData.first_name;
        formData.patient.last_name = formData.last_name;
        formData.patient.gender = formData.gender.value;
        formData.patient.birthdate = formData.birthdate;
        formData.patient.nationality = formData.nationality;
        delete formData.first_name;
        delete formData.last_name;
        delete formData.gender;
        delete formData.birthdate;
        delete formData.nationality;

        if(formData.petient_search){
            formData.patient.id = formData.patient_id;
            delete formData.active;
            delete formData.created;
            delete formData.modified;
            delete formData.patient_id;
            delete formData.petient_search;
        }
    }
    if(formData.married_last_name || formData.married_last_name == null){
        if(formData.married_last_name != null){
            formData.patient.married_last_name = formData.married_last_name;
        }
        delete formData.married_last_name;
    }
    if(formData.address || formData.address == null ){
        if(formData.address != null){
            formData.patient.address = formData.address;
        }
        delete formData.address;
    }
    if(formData.dpi || formData.dpi == null){
        if(formData.dpi != null){
            formData.patient.dpi = formData.dpi;
        }
        delete formData.dpi;
    }
    if(formData.profession || formData.profession == null){
        if(formData.profession != null){
            formData.patient.profession = formData.profession;
        }
        delete formData.profession;
    }
    if(formData.email || formData.email == null){
        if(formData.email != null){
            formData.patient.email = formData.email;
        }
        delete formData.email;
    }
    if(formData.phone || formData.phone == null){
        if(formData.phone != null){
            formData.patient.phone = formData.phone;
        }
        delete formData.phone;
    }
    if(formData.cellphone || formData.cellphone == null){
        if(formData.cellphone != null){
            formData.patient.cellphone = formData.cellphone;
        }
        delete formData.cellphone;
    }
    if(formData.departamento || formData.departamento == null){
        if(formData.departamento != null){
            formData.patient.departamento = formData.departamento.value;
        }
        delete formData.departamento;
    }
    if(formData.municipio || formData.municipio == null){
        if(formData.municipio != null){
            formData.patient.municipio = formData.municipio.value;
        }
        delete formData.municipio;
    }
    if(formData.family_member_of || formData.family_member_of == null){
        if(formData.family_member_of != null){
            formData.patient.family_member_of = formData.family_member_of.value;
        }
        delete formData.family_member_of;
    }
    if(formData.referred_by || formData.referred_by == null){
        if(formData.referred_by != null){
            formData.patient.referred_by = formData.referred_by.value;
        }
        delete formData.referred_by;
    }

    if(typeof formData.end_value === 'object'){
        formData.end_value = formData.end_value.value;
    }

    console.log("APPOINTMENT FORMDATA: ",formData)

    dispatch({type: LOADER_APPOINTMENTS, cargando: true})
    api.post(`${url}`, formData).then((data) => {
        dispatch({type: LOADER_APPOINTMENTS, cargando: false})
        Swal('Éxito', 'Se ha creado la cita.', 'success')
        .then(() => {
            dispatch(push('/appointments'))
        })
    }).catch((error) => {
        dispatch({type: LOADER_APPOINTMENTS, cargando: false})
        Swal(
            'Error',
             error.detail || 'Ha ocurrido un error, por favor vuelva a intentar.',
            'error'
        );
    }).finally(() => {
        dispatch({type: LOADER_APPOINTMENTS, cargando: false})
    });
};

const createToday = () => (dispatch, getStore) => {
    const formData = _.cloneDeep(getStore().form.appointments.values);

    const current_user = getStore().login.me;

    formData.subsidiary = current_user.person_user.subsidiary;
    formData.user = current_user.id;

    formData.doctor = formData.doctor.value;
    formData.start_value = formData.start.value;
    formData.end_value = formData.end.value;
    formData.start = formData.start.label + ':00';
    formData.end = formData.end.label + ':00';
    formData.appointment_type = DOCTOR

    if (formData.cellphone_holder == undefined){
        formData.cellphone_holder = ''
    }

    if(formData.first_name && formData.first_name != null){
        formData.patient = {}
        formData.patient.first_name = formData.first_name;
        formData.patient.last_name = formData.last_name;
        formData.patient.gender = formData.gender.value;
        formData.patient.birthdate = formData.birthdate;
        formData.patient.nationality = formData.nationality;
        delete formData.first_name;
        delete formData.last_name;
        delete formData.gender;
        delete formData.birthdate;
        delete formData.nationality;

        if(formData.petient_search){
            formData.patient.id = formData.patient_id;
            delete formData.active;
            delete formData.created;
            delete formData.modified;
            delete formData.patient_id;
            delete formData.petient_search;
        }
    }
    if(formData.married_last_name || formData.married_last_name == null){
        if(formData.married_last_name != null){
            formData.patient.married_last_name = formData.married_last_name;
        }
        delete formData.married_last_name;
    }
    if(formData.address || formData.address == null ){
        if(formData.address != null){
            formData.patient.address = formData.address;
        }
        delete formData.address;
    }
    if(formData.dpi || formData.dpi == null){
        if(formData.dpi != null){
            formData.patient.dpi = formData.dpi;
        }
        delete formData.dpi;
    }
    if(formData.profession || formData.profession == null){
        if(formData.profession != null){
            formData.patient.profession = formData.profession;
        }
        delete formData.profession;
    }
    if(formData.email || formData.email == null){
        if(formData.email != null){
            formData.patient.email = formData.email;
        }
        delete formData.email;
    }
    if(formData.phone || formData.phone == null){
        if(formData.phone != null){
            formData.patient.phone = formData.phone;
        }
        delete formData.phone;
    }
    if(formData.cellphone || formData.cellphone == null){
        if(formData.cellphone != null){
            formData.patient.cellphone = formData.cellphone;
        }
        delete formData.cellphone;
    }
    if(formData.departamento || formData.departamento == null){
        if(formData.departamento != null){
            formData.patient.departamento = formData.departamento.value;
        }
        delete formData.departamento;
    }
    if(formData.municipio || formData.municipio == null){
        if(formData.municipio != null){
            formData.patient.municipio = formData.municipio.value;
        }
        delete formData.municipio;
    }
    if(formData.family_member_of || formData.family_member_of == null){
        if(formData.family_member_of != null){
            formData.patient.family_member_of = formData.family_member_of.value;
        }
        delete formData.family_member_of;
    }
    if(formData.referred_by || formData.referred_by == null){
        if(formData.referred_by != null){
            formData.patient.referred_by = formData.referred_by.value;
        }
        delete formData.referred_by;
    }

    formData.add_to_queue = true;

    console.log("FORMDATA: ",formData)

    dispatch({type: LOADER_APPOINTMENTS, cargando: true})
    api.post(`${url}`, formData).then((data) => {
        dispatch({type: LOADER_APPOINTMENTS, cargando: false})
        Swal('Éxito', 'Se ha creado la cita.', 'success')
        .then(() => {
            dispatch(push('/query-queue'))
        })
    }).catch((error) => {
        dispatch({type: LOADER_APPOINTMENTS, cargando: false})
        Swal(
            'Error',
             error.detail || 'Ha ocurrido un error, por favor vuelva a intentar.',
            'error'
        );
    }).finally(() => {
        dispatch({type: LOADER_APPOINTMENTS, cargando: false})
    });
};

const schedule_create = (data, state) => (dispatch, getStore) => {

    const formData = _.cloneDeep(getStore().form.schedule.values);

    formData.doctor = formData.doctor.value;
    formData.start_value = formData.start.value;
    formData.end_value = formData.end.value;
    formData.start = formData.start.label + ':00';
    formData.end = formData.end.label + ':00';
    formData.appointment_type = DOCTOR

    const current_user = getStore().login.me;

    formData.subsidiary = current_user.person_user.subsidiary;
    formData.user = current_user.id;

    if (formData.cellphone_holder == undefined){
        formData.cellphone_holder = ''
    }

    if(formData.first_name && formData.first_name != null){
        formData.patient = {}
        formData.patient.first_name = formData.first_name;
        formData.patient.last_name = formData.last_name;
        formData.patient.gender = formData.gender.value;
        formData.patient.birthdate = formData.birthdate;
        formData.patient.nationality = formData.nationality;
        delete formData.first_name;
        delete formData.last_name;
        delete formData.gender;
        delete formData.birthdate;
        delete formData.nationality;

        if(formData.petient_search){
            formData.patient.id = formData.patient_id;
            delete formData.active;
            delete formData.created;
            delete formData.modified;
            delete formData.patient_id;
            delete formData.petient_search;
        }
    }

    //console.log("FORMDATA: ",formData)

    dispatch({type: LOADER_APPOINTMENTS, cargando: true})
    api.post(`${url}`, formData).then((data) => {
        dispatch({type: LOADER_APPOINTMENTS, cargando: false})
        Swal('Éxito', 'Se ha creado la cita.', 'success')
        .then(() => {
            dispatch(push('/schedule'))
            if (state.doctor_id) {
                dispatch(listar(state.week.monday.format("YYYY-MM-DD"), state.week.sunday.format("YYYY-MM-DD"), state.doctor_id))
            }
            else {
                dispatch(listar(state.week.monday.format("YYYY-MM-DD"), state.week.sunday.format("YYYY-MM-DD"), state.doctor_id, 1))
            }
        })
    }).catch((error) => {
        dispatch({type: LOADER_APPOINTMENTS, cargando: false})
        Swal(
            'Error',
             error.detail || 'Ha ocurrido un error, por favor vuelva a intentar.',
            'error'
        );
    }).finally(() => {
        dispatch({type: LOADER_APPOINTMENTS, cargando: false})
    });
};

const update = () => (dispatch, getStore) => {
    const formData = _.cloneDeep(getStore().form.appointments.values);
    formData.doctor = formData.doctor.value;
    formData.start_value = formData.start.value;
    formData.end_value = formData.end.value;
    formData.start = formData.start.label + ':00';
    formData.end = formData.end.label + ':00';
    formData.appointment_type = DOCTOR
    if(formData.first_name){
        formData.patient = {}
        formData.patient.first_name = formData.first_name;
        formData.patient.last_name = formData.last_name;
        formData.patient.gender = formData.gender.value;
        formData.patient.birthdate = formData.birthdate;
        formData.patient.nationality = formData.nationality;
        delete formData.first_name;
        delete formData.last_name;
        delete formData.gender;
        delete formData.birthdate;
        delete formData.nationality;

        if(formData.petient_search){
            formData.patient.id = formData.patient_id;
            delete formData.active;
            delete formData.created;
            delete formData.modified;
            delete formData.patient_id;
            delete formData.petient_search;
        } else if(formData.patient_id){
            formData.patient.id = formData.patient_id;
            delete formData.active;
            delete formData.created;
            delete formData.modified;
            delete formData.patient_id;
        }

        if(formData.married_last_name){
            formData.patient.married_last_name = formData.married_last_name;
            delete formData.married_last_name;
        }
        if(formData.address){
            formData.patient.address = formData.address;
            delete formData.address;
        }
        if(formData.dpi){
            formData.patient.dpi = formData.dpi;
            delete formData.dpi;
        }
        if(formData.profession){
            formData.patient.profession = formData.profession;
            delete formData.profession;
        }
        if(formData.email){
            formData.patient.email = formData.email;
            delete formData.email;
        }
        if(formData.phone){
            formData.patient.phone = formData.phone;
            delete formData.phone;
        }
        if(formData.cellphone){
            formData.patient.cellphone = formData.cellphone;
            delete formData.cellphone;
        }
        if(formData.departamento){
            formData.patient.departamento = formData.departamento.value;
            delete formData.departamento;
        }
        if(formData.municipio){
            formData.patient.municipio = formData.municipio.value;
            delete formData.municipio;
        }
        if(formData.married_last_name || formData.married_last_name == null){
            if(formData.married_last_name != null){
                formData.patient.married_last_name = formData.married_last_name;
            }
            delete formData.married_last_name;
        }
        if(formData.address || formData.address == null ){
            if(formData.address != null){
                formData.patient.address = formData.address;
            }
            delete formData.address;
        }
        if(formData.dpi || formData.dpi == null){
            if(formData.dpi != null){
                formData.patient.dpi = formData.dpi;
            }
            delete formData.dpi;
        }
        if(formData.profession || formData.profession == null){
            if(formData.profession != null){
                formData.patient.profession = formData.profession;
            }
            delete formData.profession;
        }
        if(formData.email || formData.email == null){
            if(formData.email != null){
                formData.patient.email = formData.email;
            }
            delete formData.email;
        }
        if(formData.phone || formData.phone == null){
            if(formData.phone != null){
                formData.patient.phone = formData.phone;
            }
            delete formData.phone;
        }
        if(formData.cellphone || formData.cellphone == null){
            if(formData.cellphone != null){
                formData.patient.cellphone = formData.cellphone;
            }
            delete formData.cellphone;
        }
        if(formData.departamento || formData.departamento == null){
            if(formData.departamento != null){
                formData.patient.departamento = formData.departamento.value;
            }
            delete formData.departamento;
        }
        if(formData.municipio || formData.municipio == null){
            if(formData.municipio != null){
                formData.patient.municipio = formData.municipio.value;
            }
            delete formData.municipio;
        }
        if(formData.family_member_of || formData.family_member_of == null){
            if(formData.family_member_of != null){
                formData.patient.family_member_of = formData.family_member_of.value;
            }
            delete formData.family_member_of;
        }
        if(formData.referred_by || formData.referred_by == null){
            if(formData.referred_by != null){
                formData.patient.referred_by = formData.referred_by.value;
            }
            delete formData.referred_by;
        }
        if(formData.family_member_of){
            formData.patient.family_member_of = formData.family_member_of.value;
            delete formData.family_member_of;
        }
        if(formData.referred_by){
            formData.patient.referred_by = formData.referred_by.value;
            delete formData.referred_by;
        }
    }

    console.log("UPDATE DATA", formData)

    dispatch({type: LOADER_APPOINTMENTS, cargando: true});
    api.put(`${url}/${formData.id}`, formData)
    .then((data) => {
        dispatch({type: LOADER_APPOINTMENTS, cargando: false});
        if(data){
            Swal(
                'Éxito',
                'Datos editados correctamente.',
                'success'
            ).then(() => {
                dispatch(push('/appointments'));
            })
        }
    })
    .catch((error) =>{
        dispatch({type: LOADER_APPOINTMENTS, cargando: false});
        Swal(
            'Error',
             error.detail || 'Ha ocurrido un error, verifique los datos.',
            'error'
        )
        if(error.statusCode === 404){
            dispatch(push('/appointments'));
        }
    })
}


const week_update = () => (dispatch, getStore) => {
    const formData = _.cloneDeep(getStore().form.appointments.values);
    formData.doctor = formData.doctor.value;
    formData.start_value = formData.start.value;
    formData.end_value = formData.end.value;
    formData.start = formData.start.label + ':00';
    formData.end = formData.end.label + ':00';

    //formData.start = formData.start.label;
    //formData.end = formData.end.label;

    formData.appointment_type = DOCTOR
    if(formData.first_name){
        formData.patient = {}
        formData.patient.first_name = formData.first_name;
        formData.patient.last_name = formData.last_name;
        formData.patient.gender = formData.gender.value;
        formData.patient.birthdate = formData.birthdate;
        formData.patient.nationality = formData.nationality;
        delete formData.first_name;
        delete formData.last_name;
        delete formData.gender;
        delete formData.birthdate;
        delete formData.nationality;

        if(formData.petient_search){
            formData.patient.id = formData.patient_id;
            delete formData.active;
            delete formData.created;
            delete formData.modified;
            delete formData.patient_id;
            delete formData.petient_search;
        } else if(formData.patient_id){
            formData.patient.id = formData.patient_id;
            delete formData.active;
            delete formData.created;
            delete formData.modified;
            delete formData.patient_id;
        }

        if(formData.married_last_name){
            formData.patient.married_last_name = formData.married_last_name;
            delete formData.married_last_name;
        }
        if(formData.address){
            formData.patient.address = formData.address;
            delete formData.address;
        }
        if(formData.dpi){
            formData.patient.dpi = formData.dpi;
            delete formData.dpi;
        }
        if(formData.profession){
            formData.patient.profession = formData.profession;
            delete formData.profession;
        }
        if(formData.email){
            formData.patient.email = formData.email;
            delete formData.email;
        }
        if(formData.phone){
            formData.patient.phone = formData.phone;
            delete formData.phone;
        }
        if(formData.cellphone){
            formData.patient.cellphone = formData.cellphone;
            delete formData.cellphone;
        }
        if(formData.departamento){
            formData.patient.departamento = formData.departamento.value;
            delete formData.departamento;
        }
        if(formData.municipio){
            formData.patient.municipio = formData.municipio.value;
            delete formData.municipio;
        }
        if(formData.married_last_name || formData.married_last_name == null){
            if(formData.married_last_name != null){
                formData.patient.married_last_name = formData.married_last_name;
            }
            delete formData.married_last_name;
        }
        if(formData.address || formData.address == null ){
            if(formData.address != null){
                formData.patient.address = formData.address;
            }
            delete formData.address;
        }
        if(formData.dpi || formData.dpi == null){
            if(formData.dpi != null){
                formData.patient.dpi = formData.dpi;
            }
            delete formData.dpi;
        }
        if(formData.profession || formData.profession == null){
            if(formData.profession != null){
                formData.patient.profession = formData.profession;
            }
            delete formData.profession;
        }
        if(formData.email || formData.email == null){
            if(formData.email != null){
                formData.patient.email = formData.email;
            }
            delete formData.email;
        }
        if(formData.phone || formData.phone == null){
            if(formData.phone != null){
                formData.patient.phone = formData.phone;
            }
            delete formData.phone;
        }
        if(formData.cellphone || formData.cellphone == null){
            if(formData.cellphone != null){
                formData.patient.cellphone = formData.cellphone;
            }
            delete formData.cellphone;
        }
        if(formData.departamento || formData.departamento == null){
            if(formData.departamento != null){
                formData.patient.departamento = formData.departamento.value;
            }
            delete formData.departamento;
        }
        if(formData.municipio || formData.municipio == null){
            if(formData.municipio != null){
                formData.patient.municipio = formData.municipio.value;
            }
            delete formData.municipio;
        }
        if(formData.family_member_of || formData.family_member_of == null){
            if(formData.family_member_of != null){
                formData.patient.family_member_of = formData.family_member_of.value;
            }
            delete formData.family_member_of;
        }
        if(formData.referred_by || formData.referred_by == null){
            if(formData.referred_by != null){
                formData.patient.referred_by = formData.referred_by.value;
            }
            delete formData.referred_by;
        }
        if(formData.family_member_of){
            formData.patient.family_member_of = formData.family_member_of.value;
            delete formData.family_member_of;
        }
        if(formData.referred_by){
            formData.patient.referred_by = formData.referred_by.value;
            delete formData.referred_by;
        }
    }

    /* ES ACÁ DONDE NO REGISTRA BIEN LA HORA DE ENTRADA Y SALIDA DE LA CITA*/
    console.log("UPDATE DATA", formData)

    dispatch({type: LOADER_APPOINTMENTS, cargando: true});
    api.put(`${url}/${formData.id}`, formData)
    .then((data) => {
        dispatch({type: LOADER_APPOINTMENTS, cargando: false});
        if(data){
            Swal(
                'Éxito',
                'Datos editados correctamente.',
                'success'
            ).then(() => {
                dispatch(push('/schedule'));
            })
        }
    })
    .catch((error) =>{
        dispatch({type: LOADER_APPOINTMENTS, cargando: false});
        Swal(
            'Error',
             error.detail || 'Ha ocurrido un error, verifique los datos.',
            'error'
        )
        if(error.statusCode === 404){
            dispatch(push('/schedule'));
        }
    })
}

const destroy = (id, initial_date, final_date, doctor_id, current_doctor) => (dispatch, getStore) => {
    let initial=null;
    let final=null;
    if(initial_date!=null && final_date!=null){
        initial = initial_date.format("YYYY-MM-DD");
        final = final_date.format("YYYY-MM-DD")
    }
    else
    {
        initial=moment().format("YYYY-MM-DD");
        final=moment().format("YYYY-MM-DD");
    }   
    Swal({
        title: '¿Desea eliminar este elemento?',
        text: 'No puedes deshacer esta acción',
        type: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Sí, eliminar',
        cancelButtonText: 'No, cancelar',
        reverseButtons: true
    }).then((result) => {
        if (result.value) {
            dispatch({type: LOADER_APPOINTMENTS, cargando: true});
            api.eliminar(`${url}/${id}`)
            .then((data) => {
                dispatch({type: LOADER_APPOINTMENTS, cargando: false});
                Swal('Éxito', 'Se ha eliminado correctamente.', 'success')
                    .then(() => {
                        if (current_doctor){
                            dispatch(listar(initial, final, doctor_id));
                        } else {
                            dispatch(listar(initial, final, doctor_id, 1));
                        }
                    })
            })
            .catch((error) => {
                dispatch({type: LOADER_APPOINTMENTS, cargando: false});
                Swal('Error', 'No se ha logrado borrar el registro.', 'error')
                    .then(() => {
                        if (current_doctor){
                            dispatch(listar(initial, final, doctor_id));
                        } else {
                            dispatch(listar(initial, final, doctor_id, 1));
                        }
                })
            })
        }
        dispatch({type:SET_SHOW_TOOLTIP_MODAL, modal_tooltip_show: false});
    });
}

const createInitialize = () => (dispatch, getStore) => {
    dispatch(initializeForm('appointments',
        {
            'date': getStore().appointments.date,
            'end': getStore().appointments.end,
            'start': getStore().appointments.start,
        }
    ))
}

const miniCreateInitialize = () => (dispatch, getStore) => {
    dispatch(initializeForm('schedule',
        {
            'date': getStore().appointments.date,
            'end': getStore().appointments.end,
            'start': getStore().appointments.start,
        }
    ))
}

const createInitializePatient = () => (dispatch, getState) => {
    const old_data = getState().form.appointments.values;
    dispatch(initializeForm('appointments', {birthdate: moment(new Date()).format("YYYY-MM-DD"), ...old_data}))
}

const changeCreateInitializeData = (date, start, end) => (dispatch, getStore) => {
    dispatch({type: SET_INITIALIZE_FORM_DATA, date: date.format("YYYY-MM-DD"), start, end})
}

const search = (search) => (dispatch, getStore) => {
    dispatch({type: SET_BUSCADOR_APPOINTMENTS, buscador: search});
    dispatch(listar(1));
};
const filtro = (filtro) => (dispatch, getStore) => {
    if (filtro == 0){
        filtro = null
    }
    dispatch({type: SET_FILTRO_APPOINTMENTS, filtro_appointments: filtro});
    dispatch(listar(1));
};

const pasarACola = (id, initial_date, final_date, doctor_id, current_doctor) => (dispatch, getState) => {
    console.log("Pasando a cola, cita con id: ", id);
    let initial=null;
    let final=null;
    if(initial_date!=null && final_date!=null){
        initial = initial_date.format("YYYY-MM-DD");
        final = final_date.format("YYYY-MM-DD")
    }
    else
    {
        initial=moment().format("YYYY-MM-DD");
        final=moment().format("YYYY-MM-DD");
    }   
    Swal({
        title: '¿Seguro que desea pasar la cita a cola?',
        text: 'No puede deshacer esta acción',
        type: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Sí, pasar a cola',
        cancelButtonText: 'No, cancelar',
        reverseButtons: true
    }).then((result) => {
        if (result.value) {
            dispatch({type: LOADER_APPOINTMENTS, cargando: true});
            api.post(`${url}/pasarACola`, {id}).then((data) => {
                dispatch({type: LOADER_APPOINTMENTS, cargando: false});
                Swal('Éxito', 'La cita se ha pasado a cola, correctamente.', 'success')
                    .then(() => {
                        if (current_doctor){
                            dispatch(listar(initial, final, doctor_id));
                        } else {
                            dispatch(listar(initial, final, doctor_id, 1));
                        }
                    })
            })
            .catch((error) => {
                dispatch({type: LOADER_APPOINTMENTS, cargando: false});
                Swal('Error', error.detail, 'error')
                    .then(() => {
                        if (current_doctor){
                            dispatch(listar(initial, final, doctor_id));
                        } else {
                            dispatch(listar(initial, final, doctor_id, 1));
                        }
                })
            })
        }
        dispatch({type:SET_SHOW_TOOLTIP_MODAL, modal_tooltip_show: false});
    });
}

const showTooltipModal = (value, data=null) => (dispatch) => {
    console.log("Data modal tooltip: ", data);
    if(data != null){
        dispatch({type: SET_TOOLTIP_INFO, tooltip_info: data});
    }
    dispatch({type:SET_SHOW_TOOLTIP_MODAL, modal_tooltip_show: value});

}

const validateNit = nit => (dispatch, getState) => {
    dispatch({ type: LOADER_APPOINTMENTS, cargando: true });
    const params = { nit };
    api.get("patients/validateNit", params).catch((error) => {
        Swal('Error', error.detail || 'Ha ocurrido un error, por favor vuelva a intentar.', 'error');
    }).then((response) => {
        if (response.detail) {
            const data = getState().form.appointments.values;
            if (response.detail.first_name !== '') data.first_name = response.detail.first_name;
            if (response.detail.last_name !== '') data.last_name = response.detail.last_name;
            if (response.detail.married_last_name !== '') data.married_last_name = response.detail.married_last_name;
            dispatch(initializeForm('appointments', data));
        }
    }).finally(() => dispatch({ type: LOADER_APPOINTMENTS, cargando: false }));
};


export const actions = {
    listar,
    detail,
    create,
    update,
    validateNit,
    week_update,
    search,
    filtro,
    destroy,
    getDoctors,
    detailPatient,
    setUpdateData,
    schedule_create,
    createInitialize,
    miniCreateInitialize,
    createInitializePatient,
    changeCreateInitializeData,
    pasarACola,
    createToday,
    showTooltipModal,
};
export const reducer = {
    [LOADER_APPOINTMENTS]: (state, { cargando }) => {
        return {...state, cargando }
    },
    [SET_DATA_APPOINTMENTS]: (state, { data }) => {
        return {...state, data }
    },
    [SET_PAGE_APPOINTMENTS]: (state, { page }) => {
        return {...state, page }
    },
    [SET_RESULT_APPOINTMENTS]: (state, { result }) => {
        return {...state, result }
    },
    [SET_UPDATE_DATA_APPOINTMENTS]: (state, { updateData }) => {
        return {...state, updateData }
    },
    [SET_BUSCADOR_APPOINTMENTS]: (state, { buscador }) => {
        return {...state, buscador }
    },
    [SET_FILTRO_APPOINTMENTS]: (state, { filtro_appointments }) => {
        return {...state, filtro_appointments }
    },
    [SET_INITIALIZE_FORM_DATA]: (state, { date, end, start }) => {
        return {...state, date, end, start }
    },
    [SET_APPOINTMENTS_APPOINTMENTS]: (state, {appointments}) => {
        return {...state, appointments }
    },
    [SET_OVERLAPS_APPOINTMENTS]: (state, {overlaps}) => {
        return {...state, overlaps }
    },
    [SET_DOCTORS]: (state, {doctors}) =>{
        return {...state, doctors}
    },


    [SET_SHOW_TOOLTIP_MODAL]: (state, {modal_tooltip_show}) =>{
        return {...state, modal_tooltip_show}
    },
    [SET_TOOLTIP_INFO]: (state, {tooltip_info}) =>{
        return {...state, tooltip_info}
    }
}

export const initialState = {
    doctors: [],
    cargando: false,
    // page: 1,
    // result: [],
    data: [],
    data_list: {},
    buscador: '',
    filtro_appointments: null,
    updateData: {},
    date: moment(new Date()).format("YYYY-MM-DD"),
    end: {value:10.5, label:'10:30'},
    start: {value:10.0, label:'10:00'},
    appointments: [],
    overlaps: [],

    modal_tooltip_show: false,
    tooltip_info: null,
};

export default handleActions(reducer, initialState);
