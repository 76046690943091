import Table from '../Utils/Grid';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { BreakLine } from '../Utils/tableOptions';
import HeaderSimple from '../Utils/Header/HeaderSimple';
import { activeFormatter }  from '../Utils/Acciones/Acciones'

class Roles extends Component {
    componentWillMount() {
        this.props.listar()
    }
    componentWillUnmount() {
        this.props.resetStore();
    }

    render() {
        const {
            listar,
            detail,
            create,
            update,
            destroy,
        } = this.props;


        const {
            cargando,
            page,
            data,
        } = this.props;

        return (
            <div className="content">
                <div className="m-auto row-fluid w-100">
                    <HeaderSimple texto={"Agregar rol"} ruta={"/roles/create"}/>
                    <div className="table-decoration">
                        <div className="top-title">
                            LISTADO DE ROLES
                        </div>
                        <Table
                            onPageChange={listar}
                            data={data}
                            loading={cargando}
                            page={page}
                        >
                            <TableHeaderColumn
                                tdStyle={BreakLine}
                                thStyle={BreakLine}
                                dataField="name" dataSort>Nombre</TableHeaderColumn>

                            <TableHeaderColumn
                                dataField="id"
                                isKey={true}
                                dataAlign="center"
                                dataFormat={activeFormatter({ ver: '/roles', editar: '/roles', eliminar: destroy })}>Acciones</TableHeaderColumn>
                        </Table>
                    </div>
                </div>
            </div>
        )
    }
}

export default Roles;
