import React from 'react';
import { Link } from 'react-router-dom';
import { Field, reduxForm } from 'redux-form';
import { renderField } from 'Utils/renderField/renderField';
import { validate } from 'validate-redux-form';
import custom_validate from './validations';

let RecordForm = props => {
    const {
        urlId,
        handleSubmit
    } = props;
    return (
        <form onSubmit={handleSubmit} className="py-3 px-5">
            <div className="px-md-5 d-flex justify-content-around flex-column flex-sm-row align-items-stretch align-items-sm-center">
                <div className="m-2">
                    <div className="d-flex justify-content-start flex-column flex-sm-row align-items-stretch align-items-sm-center">
                        <label htmlFor="name" className="m-0">Nombre</label>
                    </div>
                    <div className=" d-flex justify-content-start flex-column flex-sm-row align-items-stretch align-items-sm-center">
                        <Field name="name" component={renderField} autofocus type="text" className="form-control"/>
                    </div>
                </div>
            </div>
            { urlId == 1 && (
                <div className="px-md-5 d-flex justify-content-around flex-column flex-sm-row align-items-stretch align-items-sm-center">
                    <div className="m-2">
                        <div className="d-flex justify-content-start flex-column flex-sm-row align-items-stretch align-items-sm-center">
                            <label htmlFor="md_treatment" className="m-0">Medico tratamiento</label>
                        </div>
                        <div className=" d-flex justify-content-start flex-column flex-sm-row align-items-stretch align-items-sm-center">
                            <Field name="md_treatment" component={renderField} type="text" className="form-control"/>
                        </div>
                    </div>
                </div>
            )}
            { urlId == 5 && (
                <div className="px-md-5 d-flex justify-content-around flex-column flex-sm-row align-items-stretch align-items-sm-center">
                    <div className="m-2">
                        <div className="d-flex justify-content-start flex-column flex-sm-row align-items-stretch align-items-sm-center">
                            <label htmlFor="fam_family" className="m-0">Familia</label>
                        </div>
                        <div className=" d-flex justify-content-start flex-column flex-sm-row align-items-stretch align-items-sm-center">
                            <Field name="fam_family" component={renderField} type="text" className="form-control"/>
                        </div>
                    </div>
                </div>
            )}
            <div className="row-fluid">
                <div className="d-flex justify-content-center flex-column flex-sm-row align-items-stretch align-items-sm-center">
                    <Link className="btn btn-secondary m-4" to={`/records/${urlId}`}>Cancelar</Link>
                    <button type="submit" className="btn btn-primary m-3">Guardar</button>
                </div>
            </div>
        </form>
    )
}

RecordForm = reduxForm({
    form: 'recordForm',
    validate: custom_validate
})(RecordForm);

export default RecordForm;
