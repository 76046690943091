import React from 'react';
import { api } from "api";
import trim from 'lodash/trim';
import { Link } from 'react-router-dom';
import validarNit from 'validar-nit-gt';
import { Field, reduxForm, formValueSelector } from 'redux-form';
import { validate, validators } from 'validate-redux-form';
import { renderField, renderSwitch, renderPercentage } from 'Utils/renderField/renderField';
import { connect } from 'react-redux'
import { renderSelectColor } from 'Utils/renderField/renderSelectColor';
import './styleDoctors.css';

const Form = props => {
    const {
        handleSubmit,
        partner,
    } = props;

    const view = window.location.href.includes("view");

    return(
        <form onSubmit={handleSubmit} className="py-3 px-5">

            <div className="px-md-5 d-flex justify-content-around flex-column flex-sm-row align-items-stretch align-items-sm-center">
                <div className="m-1">
                    <div className="d-flex justify-content-start flex-column flex-sm-row align-items-stretch align-items-sm-center">
                        <label htmlFor="name" className="">Nombre</label>
                    </div>
                    <div className="d-flex justify-content-start flex-column flex-sm-row align-items-stretch align-items-sm-center">
                        <Field name="name" autofocus disabled={view} component={renderField} type="text" className="form-control"/>
                    </div>
                </div>
                <div className="m-21">
                    <div className="d-flex justify-content-start flex-column flex-sm-row align-items-stretch align-items-sm-center">
                        <label htmlFor="clinic" className="">Clínica</label>
                    </div>
                    <div className="d-flex justify-content-start flex-column flex-sm-row align-items-stretch align-items-sm-center">
                        <Field name="clinic" disabled={view} component={renderField} type="text" className="form-control"/>
                    </div>
                </div>
            </div>


            <div className="px-md-5 row-fluid d-flex justify-content-around flex-column flex-sm-row align-items-stretch align-items-sm-center">
                <div className="m-21">
                    <div className="d-flex justify-content-start flex-column flex-sm-row align-items-stretch align-items-sm-center">
                        <label htmlFor="color" className="">Color</label>
                    </div>
                    <div className="d-flex justify-content-start flex-column flex-sm-row align-items-stretch align-items-sm-center">
                        <Field  name="color"
                                disabled={view}
                                component={renderSelectColor}

                                valueKey="value"
                                labelKey="label"
                                label="color"

                                id="select-color"
                                className=""/>
                    </div>
                </div>

                <div className="">
                    <div className="d-flex justify-content-start flex-column flex-sm-row align-items-stretch align-items-sm-center">
                        <label htmlFor="minimal existence" className="m-0">Socio</label>
                    </div>
                    <div>
                        <Field
                            name="partner"
                            component={renderSwitch}
                            className="form-control"
                            disabled={view}
                        />
                    </div>
                </div>
                <div className="invisible">
                    <div className="d-flex justify-content-start flex-column flex-sm-row align-items-stretch align-items-sm-center">
                        <label htmlFor="minimal existence" className="m-0">Comodin</label>
                    </div>
                </div>
            </div>


            <div className="px-md-5 row-fluid d-flex justify-content-around flex-column flex-sm-row align-items-stretch align-items-sm-center">
                <div className="m-2">
                    <div className="d-flex justify-content-start flex-column flex-sm-row align-items-stretch align-items-sm-center">
                        <label htmlFor="percentage" className="m-0">Porcentaje (%)</label>
                    </div>
                    <div className=" d-flex justify-content-start flex-column flex-sm-row align-items-stretch align-items-sm-center">
                        <Field name="fee" disabled={view} component={renderPercentage} type="text" className="form-control"/>
                    </div>
                </div>

                <div className="m-2 invisible">
                    <div className="d-flex justify-content-start flex-column flex-sm-row align-items-stretch align-items-sm-center">
                        <label htmlFor="comodin" className="m-0">comodin</label>
                    </div>
                    <div className=" d-flex justify-content-start flex-column flex-sm-row align-items-stretch align-items-sm-center">
                        <Field name="comodin" component='input' type="text" className="form-control"/>
                    </div>
                </div>
            </div>


            <div className="row-fluid">
                <div className="d-flex justify-content-center flex-column flex-sm-row align-items-stretch align-items-sm-center">
                    <Link className="btn btn-secondary m-3" to="/doctors">{!view ? ("Cancelar") : ("Regresar")}</Link>
                    {!view && (<button type="submit" className="btn btn-primary m-3">Guardar</button>)}
                </div>
            </div>
        </form>
    )
};

let DoctorForm = reduxForm({
   form: 'doctors',
   validate: data => {
       return validate(data, {
           'name': validators.exists()('Campo requerido.'),
           'clinic': validators.exists()('Campo requerido.'),
           'color': validators.exists()('Campo requerido.'),
       })
   }
})(Form);

const selector = formValueSelector('doctors');

DoctorForm = connect(state => {
    const partner = selector(state, "partner");

    return {
        partner,
    };

})(DoctorForm);

export default DoctorForm;
