const url = 'purchase_order';
import { api } from 'api';
import Swal from 'sweetalert2';
import { push } from 'react-router-redux';
import { goBack } from 'react-router-redux';
import { initialize as initializeForm } from 'redux-form';
import { createActions, handleActions } from 'redux-actions';
import moment from "moment";

const LOADER_PURCHASE_ORDER = 'LOADER_PURCHASE_ORDER';
const SET_DATA_PURCHASE_ORDER = 'SET_DATA_PURCHASE_ORDER';
const SET_PAGE_PURCHASE_ORDER = 'SET_PAGE_PURCHASE_ORDER';
const SET_UPDATE_DATA_PURCHASE_ORDER = 'SET_UPDATE_DATA_PURCHASE_ORDER';
const SET_BUSCADOR_PURCHASE_ORDER = 'SET_BUSCADOR_PURCHASE_ORDER';
const SET_FILTRO_PURCHASE_ORDER = 'SET_FILTRO_PURCHASE_ORDER';
const BUSQUEDA_ORDEN_SOLICITADA = 'BUSQUEDA_ORDEN_SOLICITADA';
const BUSQUEDA_ORDEN_RECIBIDA = 'BUSQUEDA_ORDEN_RECIBIDA';
const BUSQUEDA_ORDEN_RECEPCION = 'BUSQUEDA_ORDEN_RECEPCION';

const listarOrdenesPendientes = (page = 1) =>  (dispatch, getStore) => {
    dispatch({type: LOADER_PURCHASE_ORDER, cargando: true});
    const store = getStore();
    const search = store.purchase_order.buscador;
    const filtro = store.purchase_order.filtro_order;
    const custom_search = store.purchase_order.buscar_orden_recepcion;

    let params = {page, search, custom_search};
    if(filtro){
        params[''] = filtro
    }

    api.get(`purchase_order/getEntryOrders`, params).catch((error) => {
        dispatch({type: LOADER_PURCHASE_ORDER, cargando: false});
        Swal(
            'Error',
            error.detail || 'Ha ocurrido un error, por favor vuelva a intentar.',
            'error'
        );
    }).then((data) => {
        if(data){
            console.log("Data de ordenes: ", data);
            dispatch({type: SET_DATA_PURCHASE_ORDER, data});
            dispatch({type: SET_PAGE_PURCHASE_ORDER, page});
        }
        dispatch({type: LOADER_PURCHASE_ORDER, cargando: false});
    })
};

const listarOrdenesSolicitadas = (page = 1) =>  (dispatch, getStore) => {
    dispatch({type: LOADER_PURCHASE_ORDER, cargando: true});
    const store = getStore();
    const search = store.purchase_order.buscador;
    const filtro = store.purchase_order.filtro_order;
    const custom_search = store.purchase_order.buscar_orden_solicitada;

    let params = {page, search, custom_search};
    if(filtro){
        params[''] = filtro
    }

    api.get(`purchase_order/getOrdersRequested/`, params).catch((error) => {
        dispatch({type: LOADER_PURCHASE_ORDER, cargando: false});
        Swal(
            'Error',
            error.detail || 'Ha ocurrido un error, por favor vuelva a intentar.',
            'error'
        );
    }).then((data) => {
        if(data){
            dispatch({type: SET_DATA_PURCHASE_ORDER, data});
            dispatch({type: SET_PAGE_PURCHASE_ORDER, page});
        }
        dispatch({type: LOADER_PURCHASE_ORDER, cargando: false});
    })
};

const listar = (page = 1) =>  (dispatch, getStore) => {
    dispatch({type: LOADER_PURCHASE_ORDER, cargando: true});
    const store = getStore();
    const search = store.purchase_order.buscador;
    const filtro = store.purchase_order.filtro_whearhouse;
    const custom_search = store.purchase_order.buscar_orden_recibida;

    let params = {page, search, custom_search};
    if(filtro){
        params[''] = filtro
    }

    //api.get(`${url}`, params).catch((error) => {
    api.get(`purchase_order/getAdminOrders/`, params).catch((error) => {
        dispatch({type: LOADER_PURCHASE_ORDER, cargando: false});
        Swal(
            'Error',
            error.detail || 'Ha ocurrido un error, por favor vuelva a intentar.',
            'error'
        );
    }).then((data) => {
        if(data){
            dispatch({type: SET_DATA_PURCHASE_ORDER, data});
            dispatch({type: SET_PAGE_PURCHASE_ORDER, page});
        }
        dispatch({type: LOADER_PURCHASE_ORDER, cargando: false});
    })
};

const detail = id => (dispatch, getState) =>{
    dispatch({type: LOADER_PURCHASE_ORDER, cargando: true});
    api.get(`${url}/${id}`).catch((error) => {
        dispatch({type: LOADER_PURCHASE_ORDER, cargando: false});
        if(error.statusCode === 404){
            dispatch(push('/orders'))
        }
    }).then((data) => {
        dispatch({type: LOADER_PURCHASE_ORDER, cargando: false});
        if(data){
            const subsidiary = { value: data.subsidiary.id, label: data.subsidiary.name }
            data.subsidiary = subsidiary;
            dispatch({type: SET_UPDATE_DATA_PURCHASE_ORDER, updateData: data});
            dispatch(initializeForm('ordenes', data))
        }
    })
}

const create = () => (dispatch, getStore) => {
    const formData = getStore().form.bodegas.values;
    const subsidiary = formData.subsidiary.value;
    formData.subsidiary = subsidiary;

    dispatch({type: LOADER_PURCHASE_ORDER, cargando: true})

    api.post(`${url}`, formData).then((data) => {
        dispatch({type: LOADER_PURCHASE_ORDER, cargando: false})
        Swal('Éxito', 'Se ha creado la bodega.', 'success')
        .then(() => {
            dispatch(push('/ordenes'))
        })
    }).catch((error) => {
        dispatch({type: LOADER_PURCHASE_ORDER, cargando: false})
        Swal(
            'Error',
             error.detail || 'Ha ocurrido un error, por favor vuelva a intentar.',
            'error'
        );
    }).finally(() => {
        dispatch({type: LOADER_PURCHASE_ORDER, cargando: false})
    });
};

const update = () => (dispatch, getStore) => {
    const formData = _.cloneDeep(getStore().form.bodegas.values);
    const subsidiary = formData.subsidiary.value;
    formData.subsidiary = subsidiary;

    dispatch({type: LOADER_PURCHASE_ORDER, cargando: true});
    api.put(`${url}/${formData.id}`, formData)
    .then((data) => {
        dispatch({type: LOADER_PURCHASE_ORDER, cargando: false});
        if(data){
            Swal(
                'Éxito',
                'Datos editados correctamente.',
                'success'
            ).then(() => {
                dispatch(push('/ordenes'));
            })
        }
    })
    .catch((error) =>{
        dispatch({type: LOADER_WHEARHOUSE, cargando: false});
        Swal(
            'Error',
             error.detail || 'Ha ocurrido un error, verifique los datos.',
            'error'
        )
        if(error.statusCode === 404){
            dispatch(push('/warehouse/create'));
        }
    })
}

const destroy = (id) => (dispatch, getStore) => {
    dispatch({type: LOADER_PURCHASE_ORDER, cargando: true});
    api.eliminar(`${url}/${id}`).catch((error) => {
        dispatch({type: LOADER_PURCHASE_ORDER, cargando: false});
        Swal('Error', 'No se ha logrado borrar el registro.', 'error')
            .then(() => {
                dispatch(listar(1));
            })
    }).then((data) => {
        dispatch({type: LOADER_PURCHASE_ORDER, cargando: false});
        Swal('Éxito', 'Se ha eliminado correctamente.', 'success')
            .then(() => {
                dispatch(listar(1));
            })
    })
}

const search = (search) => (dispatch) => {
    dispatch({type: SET_BUSCADOR_PURCHASE_ORDER, buscador: search});
    dispatch(listar(1));
};

const searchOrdenesSolicitadas = (search) => (dispatch) => {
    dispatch({type: SET_BUSCADOR_PURCHASE_ORDER, buscador: search});
    dispatch(listarOrdenesSolicitadas(1));
};

const searchOrdenesPendientesEntrada = (search) => (dispatch) => {
    dispatch({type: SET_BUSCADOR_PURCHASE_ORDER, buscador: search});
    dispatch(listarOrdenesPendientes(1));
};

const filtroOrdenesPendientesEntrada = (filtro) => (dispatch, getStore) => {
    if (filtro == 0){
        filtro = null
    }
    dispatch({type: SET_FILTRO_PURCHASE_ORDER, filtro_whearhouse: filtro});
    dispatch(listarOrdenesPendientes(1));
};

const filtroOrdenesSolicitadas = (filtro) => (dispatch, getStore) => {
    if (filtro == 0){
        filtro = null
    }
    dispatch({type: SET_FILTRO_PURCHASE_ORDER, filtro_whearhouse: filtro});
    dispatch(listarOrdenesSolicitadas(1));
};

const filtro = (filtro) => (dispatch, getStore) => {
    if (filtro == 0){
        filtro = null
    }
    dispatch({type: SET_FILTRO_PURCHASE_ORDER, filtro_whearhouse: filtro});
    dispatch(listar(1));
};

export const cancelPurchasePayment = (id_purchase, url_retorno=null) => (dispatch) => {
    Swal.fire({
        title: '¿Está seguro(a) que desea anular el pago?',
        type: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#aaa',
        confirmButtonText: 'Aceptar',
        cancelButtonText: 'Cancelar',
        reverseButtons: true
    }).then((result) => {
        if (result.value) {
            api.post(`${url}/cancelPurchasePayment`, {id_purchase}).then((data) => {
                Swal('Éxito', data.detail , 'success')
                .then(() => {
                    console.log("Url retorno: ", url_retorno);
                    if(url_retorno === 'pending-orders'){
                        dispatch(listarOrdenesPendientes());
                    }else if(url_retorno == 'orders-adm'){
                        dispatch(listar());
                    }else{
                        dispatch(listarOrdenesSolicitadas());
                    }
                })
            }).catch((error) => {
                Swal(
                    'Error',
                     error.detail || 'Ha ocurrido un error, por favor vuelva a intentar.',
                    'error'
                );
            })
        }
    });
}

const customSearchOrdenesSolicitadas = (search) => (dispatch) => {
    dispatch({type: BUSQUEDA_ORDEN_SOLICITADA, buscar_orden_solicitada: search});
    dispatch(listarOrdenesSolicitadas(1));
};

const customSearchOrdenesRecibidas = (search) => (dispatch) => {
    dispatch({type: BUSQUEDA_ORDEN_RECIBIDA, buscar_orden_recibida: search});
    dispatch(listar(1));
};

const customSearchOrdenesRecepcion = (search) => (dispatch) => {
    dispatch({type: BUSQUEDA_ORDEN_RECEPCION, buscar_orden_recepcion: search});
    dispatch(listarOrdenesPendientes(1));
};

export const actions = {
    listar,
    create,
    update,
    detail,
    destroy,
    search,
    filtro,
    searchOrdenesSolicitadas,
    searchOrdenesPendientesEntrada,
    filtroOrdenesSolicitadas,
    filtroOrdenesPendientesEntrada,
    listarOrdenesPendientes,
    listarOrdenesSolicitadas,
    cancelPurchasePayment,

    customSearchOrdenesSolicitadas,
    customSearchOrdenesRecibidas,
    customSearchOrdenesRecepcion,
};

export const reducer = {
    [LOADER_PURCHASE_ORDER]: (state, { cargando }) => {
        return {...state, cargando }
    },
    [SET_DATA_PURCHASE_ORDER]: (state, { data }) => {
        return {...state, data }
    },
    [SET_PAGE_PURCHASE_ORDER]: (state, { page }) => {
        return {...state, page }
    },
    [SET_UPDATE_DATA_PURCHASE_ORDER]: (state, { updateData }) => {
        return {...state, updateData }
    },
    [SET_BUSCADOR_PURCHASE_ORDER]: (state, { buscador }) => {
        return {...state, buscador }
    },
    [SET_FILTRO_PURCHASE_ORDER]: (state, { filtro_purchase_order }) => {
        return {...state, filtro_purchase_order }
    },

    [BUSQUEDA_ORDEN_SOLICITADA]: (state, { buscar_orden_solicitada }) => {
        return {...state, buscar_orden_solicitada }
    },
    [BUSQUEDA_ORDEN_RECIBIDA]: (state, { buscar_orden_recibida }) => {
        return {...state, buscar_orden_recibida }
    },
    [BUSQUEDA_ORDEN_RECEPCION]: (state, { buscar_orden_recepcion }) => {
        return {...state, buscar_orden_recepcion }
    },        
};

export const initialState = {
    //De la lista
    cargando: false,
    page: 1,
    data: {
        count: 0,
        next: null,
        previous: null,
        results: [],
    },
    buscador: '',
    filtro_purchase_order: null,
    updateData: {},

    buscar_orden_solicitada: '',
    buscar_orden_recibida: '',
    buscar_orden_recepcion: '',
};

export default handleActions(reducer, initialState);
