import React, { Component } from 'react';
import { RenderCurrency} from '../../Utils/renderField/renderReadField';
import Switch    from 'react-switch';

import { renderCurrency } from 'Utils/renderField/renderField';
import { Field, FieldArray, reduxForm} from 'redux-form';
import { validate, validators } from 'validate-redux-form';
import MyModal from '../../Utils/myModal';

import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import BillingForm from '../payment_methods/FormBillingInformation';
import NumberFormat from 'react-number-format';

import Form from '../payment_methods/CardsForm';
import DueForm from '../payment_methods/Duesform';
import DepositForm from '../payment_methods/DepositForm';
import Swal from 'sweetalert2';

import '../payment_methods/sale.css';
export const isEmpty = value => value === undefined || value === null || value === '';

export default class MethodsPaymentForm extends Component {
    state = {
        view : 'payment',
        factura: null,
        restante: 0,
    };

    verifyCash = (value) => {
        const cantidad = !isEmpty(value) ? value : 0;
        const total = this.props.card_payments_total + this.props.due_payments_total + parseFloat(cantidad);

        if(total > this.props.totalInCar){
            return "La cantidad excede el total de la venta";
        }else{
            this.props.setDataPayment();
            return null;
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if((this.state.restante == 0 || this.state.restante == undefined || this.state.restante == null)
            && prevProps.factura.dataPayments != this.props.factura.dataPayments) {
                this.setState({ restante: Number(this.props.factura.calculate.resto)})
        }
    }

    componentDidMount = () => {
        this.props.getCards(); /* To get cards in field card_list of state */
        this.props.getDues();
        this.setState({ restante: Number(this.props.factura.calculate.resto)})
        //this.props.chargePayments(this.props.factura.dataBilling.id);
    }

    cardPayments = () => {
        const res = this.props.cardsPayment();
        if(res){
            this.forceUpdate();
            this.props.forceRendering();
        }
    }

    duePayments = () => {
        const res = this.props.duesPayment();
        if(res){
            this.forceUpdate();
            this.props.forceRendering();
        }
    }

    depositPayment = () => {
        const res = this.props.depositPayment();
        if(res){
            this.forceUpdate();
            this.props.forceRendering();
        }
    }

    setDataPayment = (e) => {
        e.preventDefault();
        this.setState({ view: 'confirm'});
        this.props.setDataPayment();
    }

    setEfectivo = () => {
        this.props.setDataPayment();
    }

    setCashPayment = (index, cantidad) => {
        const { factura } = this.props
        const { dataPayments } = factura || {}
        let total = Number(cantidad)
        total += Number(dataPayments.deposit_total)
        total += Number(dataPayments.cards_total)
        total += Number(dataPayments.dues_total)
        if(total > this.state.restante){
            Swal.fire(
                'La cantidad que paga excede el total de la venta',
                'Por favor verifique',
                'question'
            )
            cantidad = 0
        }
        this.props.setCashPayment(index, cantidad);
        this.forceUpdate();
        this.props.forceRendering();
    }

    changeView = (view) => {
        this.setState({ view });
    }

    setRetention = (index) => {
        this.props.setRetention(index, !this.props.factura.dataPayments.retention);
        this.forceUpdate();
        this.props.forceRendering();
    }

    chargePayments = () => {
        this.props.chargePayments(this.props.factura.dataBilling.id);
    }

    render(){
        const {
            factura,
            index,
        } = this.props;

        return(
            <React.Fragment>
                <div className="table-responsive">
                    {/* <form onSubmit={(e) => this.setDataPayment(e)}> */}
                        <table className="table table-sm" id="table-payment">
                            <thead>
                                <tr>
                                    <th></th>
                                    <th></th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <th scope="row">TOTAL A PAGAR</th>
                                    <td>
                                        {/*<RenderCurrency value={factura.calculate.totalLabel} />*/}
                                        <RenderCurrency value={factura.calculate.total} />
                                    </td>
                                    <td>falta: <RenderCurrency value={(factura.calculate.resto <= 0) ? 0 : factura.calculate.resto} /></td>
                                </tr>
                                <tr>
                                    <th  scope="row">EFECTIVO</th>
                                    <td>
                                        <NumberFormat className="form-control w-75"
                                            value={factura.dataPayments.cash}
                                            decimalScale={2} fixedDecimalScale={true}
                                            thousandSeparator={true} prefix="Q "
                                            onValueChange={(values) => {
                                                const {value} = values;
                                                const cash = value === '' ? 0 : parseFloat(value);
                                                this.setCashPayment(index,cash);
                                            }}
                                            autoFocus
                                            onFocus={(e) => {
                                                e.target.setSelectionRange(0,7);
                                            }}
                                        />
                                    </td>
                                    <td></td>
                                </tr>
                                <tr>
                                    <th scope="row">DEPÓSITO</th>
                                    <td >
                                        {<RenderCurrency value={factura.dataPayments.deposit_total} />}
                                    </td>
                                    <td >
                                    <MyModal
                                        isOpen={false}
                                        title="Pagos con depósito"
                                        formId="frm-deposito"
                                        textButton="Pagar"
                                        classToggleButton="btn btn-sm pay-button"
                                        size={'lg'}
                                        body={
                                            <React.Fragment>
                                                <DepositForm onSubmit={this.depositPayment} index={index} />
                                            </React.Fragment>
                                        }
                                    />
                                    </td>
                                </tr>
                                <tr>
                                    <th scope="row">TARJETA</th>
                                    <td >
                                        <RenderCurrency value={factura.dataPayments.cards_total} />
                                    </td>
                                    <td >
                                    <MyModal
                                        isOpen={false}
                                        title="Pagos con tarjeta"
                                        formId="selectCard"
                                        textButton="Pagar"
                                        classToggleButton="btn btn-sm pay-button"
                                        //aceptar={this.cardPayments}
                                        body={
                                            <React.Fragment>
                                                <Form onSubmit={this.cardPayments} listCards={this.props.card_list} index={index} />
                                            </React.Fragment>
                                        }
                                    />
                                    </td>
                                </tr>
                                <tr>
                                    <th scope="row">CUOTAS</th>
                                    <td>
                                        <RenderCurrency value={factura.dataPayments.dues_total} />
                                         {!!(factura.calculate && factura.dataPayments.dues_total && factura.calculate.monto_extra )&&
                                        <span style={{fontSize: '14px', color: 'black'}}>
                                            &nbsp;&nbsp;&nbsp;(Total cuota + Recargo:
                                            <RenderCurrency value={(parseFloat(factura.dataPayments.dues_total) + parseFloat(factura.calculate.monto_extra) - parseFloat(this.props.extra_amount_initial))} />
                                            )
                                        </span>}
                                    </td>
                                    <td>
                                        <MyModal
                                            isOpen={false}
                                            title="Pagos con cuota"
                                            formId="selectDue"
                                            textButton="Pagar"
                                            classToggleButton="btn btn-sm pay-button"
                                            size={'lg'}
                                            body={
                                                <React.Fragment>
                                                    <DueForm
                                                        onSubmit={this.duePayments}
                                                        listDues={this.props.dues_list}
                                                        index={index}
                                                        size={"lg"}
                                                        />
                                                </React.Fragment>
                                            }
                                        />
                                    </td>
                                </tr>
                                {factura.calculate.totalLabel >= 2800 &&
                                <tr>
                                    <th scope="row">RETENCIÓN</th>
                                    <td>
                                        {/*  {((factura.calculate.totalLabel / 1.12) * 0.05).toFixed(2)}  */}
                                        <Switch
                                            onChange={() => this.setRetention(index)}
                                            checked={factura.dataPayments.retention}
                                            onColor="#0a5995"
                                            offColor="#ccc"
                                        />
                                    </td>

                                        <td>
                                            {factura.dataPayments.retention &&
                                                <RenderCurrency value={factura.dataPayments.retention_amount} />
                                            }
                                        </td>
                                </tr>
                                }
                            </tbody>
                        </table>
                </div>
            </React.Fragment>
        );
    }
}
