import React from 'react';
import { api } from "api";
import trim from 'lodash/trim';
import { Link } from 'react-router-dom';
import validarNit from 'validar-nit-gt';
import { Field, reduxForm } from 'redux-form';
import { validate, validators } from 'validate-redux-form';
import { renderField, renderSearchSelect, renderSelectCustom, renderDatePicker } from 'Utils/renderField/renderField';

let institutions = []

export const isEmpty = value => value === undefined || value === null || value === '';

export function NIT(value) {
    if (!isEmpty(value)) {
        value = trim(value.toUpperCase())
        return validarNit(value) ? null : value === 'CF' ? null : 'Escriba un nit válido o CF'
    }
    return null
}



const getInstitutions = (search)  =>{
    return api.get("institutions",{search}).catch((error) => {})
            .then((data) => {
                if (data){
                    institutions = [];
                    data.results.forEach(x=>{
                        institutions.push({value: x.id, label: x.name})
                    })
                    return institutions
                }
                else{
                    return []
                }
            })
            .catch(e => {
                return []
            })
}

const Form = props => {
    const {
        handleSubmit
    } = props;
//    let fecha= new Date();
//    let `fecha= ${fecha.getDate(data.birthday)};

    const view = window.location.href.includes("view");

    return(
        <form onSubmit={handleSubmit} className="py-2 px-5">
            <div className="px-md-5 d-flex justify-content-around flex-column flex-sm-row align-items-stretch align-items-sm-center">
                <div className="m-1">
                    <div className="d-flex justify-content-start flex-column flex-sm-row align-items-stretch align-items-sm-center">
                        <label htmlFor="name" className="">Nombre</label>
                    </div>
                    <div className="d-flex justify-content-start flex-column flex-sm-row align-items-stretch align-items-sm-center">
                        <Field name="name" autofocus disabled={view} component={renderField} type="text" letras={true} className="form-control"/>
                    </div>
                </div>
                <div className="m-21">
                    <div className="d-flex justify-content-start flex-column flex-sm-row align-items-stretch align-items-sm-center">
                        <label htmlFor="address" className="">Dirección</label>
                    </div>
                    <div className="d-flex justify-content-start flex-column flex-sm-row align-items-stretch align-items-sm-center">
                        <Field name="address" disabled={view} component={renderField} type="text" className="form-control"/>
                    </div>
                </div>
            </div>
            <div className="px-md-5 row-fluid d-flex justify-content-around flex-column flex-sm-row align-items-stretch align-items-sm-center">
                <div className="m-1">
                    <div className="d-flex justify-content-start flex-column flex-sm-row align-items-stretch align-items-sm-center">
                        <label htmlFor="phone" className="">Teléfono</label>
                    </div>
                    <div className="d-flex justify-content-start flex-column flex-sm-row align-items-stretch align-items-sm-center">
                        <Field name="phone" disabled={view} component={renderField} type="number" className="form-control"/>
                    </div>
                </div>
                <div className="m-1">
                    <div className="d-flex justify-content-start flex-column flex-sm-row align-items-stretch align-items-sm-center">
                        <label htmlFor="email" className="">Correo</label>
                    </div>
                    <div className="d-flex justify-content-start flex-column flex-sm-row align-items-stretch align-items-sm-center">
                        <Field name="email" disabled={view} component={renderField} type="email" className="form-control"/>
                    </div>
                </div>
                {/*
                <div className="m-1">
                    <div className="d-flex justify-content-start flex-column flex-sm-row align-items-stretch align-items-sm-center">
                        <label htmlFor="birthdate" className="">Fecha De Nacimiento</label>
                    </div>
                    <div className="d-flex justify-content-start flex-column flex-sm-row align-items-stretch align-items-sm-center">
                        <Field name="birthdate" isDisabled={view} component={renderDatePicker} type="text" className="form-control" />
                    </div>
                </div>
                */}
            </div>
            <div className="px-md-5 row-fluid d-flex justify-content-around flex-column flex-sm-row align-items-stretch align-items-sm-center">
                {/*
                <div className="m-1">
                    <div className="d-flex justify-content-start flex-column flex-sm-row align-items-stretch align-items-sm-center">
                        <label htmlFor="email" className="">Correo</label>
                    </div>
                    <div className="d-flex justify-content-start flex-column flex-sm-row align-items-stretch align-items-sm-center">
                        <Field name="email" disabled={view} component={renderField} type="email" className="form-control"/>
                    </div>
                </div>
                */}
                <div className="m-1">
                    <div className="d-flex justify-content-start flex-column flex-sm-row align-items-stretch align-items-sm-center">
                        <label htmlFor="nit" className="">Nit</label>
                    </div>
                    <div className="d-flex justify-content-start flex-column flex-sm-row align-items-stretch align-items-sm-center">
                        <Field name="nit" disabled={view} component={renderField} type="text" className="form-control" validate={NIT}/>
                    </div>
                </div>

                <div className="m-1">
                    <div className="d-flex justify-content-start flex-column flex-sm-row align-items-stretch align-items-sm-center">
                        <label htmlFor="role" className="">Nombre del trabajador Social</label>
                    </div>
                    <div className="d-flex justify-content-start flex-column flex-sm-row align-items-stretch align-items-sm-center">
                        <Field name="social_worker_name" disabled={view} component={renderField} type="text" letras={true} className="form-control"/>                        
                    </div>
                </div>

                {/*
                <div className="m-1">
                    <div className="d-flex justify-content-start flex-column flex-sm-row align-items-stretch align-items-sm-center">
                        <label htmlFor="role" className="">Tipo De Referente</label>
                    </div>
                    <div className="d-flex justify-content-start flex-column flex-sm-row align-items-stretch align-items-sm-center">
                        <Field name="referent_type" disabled={view} component={renderSelectCustom} options={[
                            {value:1, label:'Médico Referente'},
                            {value:2, label:'Trabajador Social'},
                        ]}/>
                    </div>
                </div>
                */}
            </div>
            <div className="px-md-5 row-fluid d-flex justify-content-around flex-column flex-sm-row align-items-stretch align-items-sm-center">
                {/*
                <div className="m-1">
                    <div className="d-flex justify-content-start flex-column flex-sm-row align-items-stretch align-items-sm-center">
                        <label htmlFor="role" className="">Tipo De Referente</label>
                    </div>
                    <div className="d-flex justify-content-start flex-column flex-sm-row align-items-stretch align-items-sm-center">
                        <Field name="referent_type" disabled={view} component={renderSelectCustom} options={[
                            {value:1, label:'Médico Referente'},
                            {value:2, label:'Trabajador Social'},
                        ]}/>
                    </div>
                </div>
                */}
                <div className="m-1">
                    <div className="d-flex justify-content-start flex-column flex-sm-row align-items-stretch align-items-sm-center">
                        <label htmlFor="institution" className="m-0">Institución</label>
                    </div>
                    <div className=" d-flex justify-content-end flex-column flex-sm-row align-items-stretch align-items-sm-center">
                        <Field
                            name="institution"
                            valueKey="value"
                            labelKey="label"
                            label="name"
                            component={renderSearchSelect}
                            loadOptions={getInstitutions}
                            className="no-border"
                            disabled={view}
                        />
                    </div>
                </div>
                <div className="m-1">
                    <div className="d-flex justify-content-start flex-column flex-sm-row align-items-stretch align-items-sm-center">
                        <label htmlFor="bank_name" className="">Banco</label>
                    </div>
                    <div className="d-flex justify-content-start flex-column flex-sm-row align-items-stretch align-items-sm-center">
                        <Field name="bank_name" disabled={view} component={renderField} type="text" letras={true} className="form-control"/>
                    </div>
                </div>
            </div>
            <div className="px-md-5 row-fluid d-flex justify-content-around flex-column flex-sm-row align-items-stretch align-items-sm-center">
                {/*
                <div className="m-1">
                    <div className="d-flex justify-content-start flex-column flex-sm-row align-items-stretch align-items-sm-center">
                        <label htmlFor="bank_name" className="">Banco</label>
                    </div>
                    <div className="d-flex justify-content-start flex-column flex-sm-row align-items-stretch align-items-sm-center">
                        <Field name="bank_name" disabled={view} component={renderField} type="text" className="form-control"/>
                    </div>
                </div>
                */}
                <div className="m-1">
                    <div className="d-flex justify-content-start flex-column flex-sm-row align-items-stretch align-items-sm-center">
                        <label htmlFor="account_type" className="">Tipo de cuenta</label>
                    </div>
                    <div className="d-flex justify-content-start flex-column flex-sm-row align-items-stretch align-items-sm-center">
                        <Field name="account_type" disabled={view} component={renderSelectCustom} options={[
                            {value:1, label:'Cuenta Monetaria'},
                            {value:2, label:'Cuenta De Ahorro'},
                        ]}/>
                    </div>
                </div>
                <div className="m-1">
                    <div className="d-flex justify-content-start flex-column flex-sm-row align-items-stretch align-items-sm-center">
                        <label htmlFor="account" className="">No. De Cuenta Bancaria</label>
                    </div>
                    <div className="d-flex justify-content-start flex-column flex-sm-row align-items-stretch align-items-sm-center">
                        <Field name="account" disabled={view} component={renderField} type="number" className="form-control"/>
                    </div>
                </div>
            </div>
            {/*
            <div className="px-md-5 row-fluid d-flex justify-content-around flex-column flex-sm-row align-items-stretch align-items-sm-center">
                <div className="m-1">
                    <div className="d-flex justify-content-start flex-column flex-sm-row align-items-stretch align-items-sm-center">
                        <label htmlFor="account" className="">No. De Cuenta Bancaria</label>
                    </div>
                    <div className="d-flex justify-content-start flex-column flex-sm-row align-items-stretch align-items-sm-center">
                        <Field name="account" disabled={view} component={renderField} type="number" className="form-control"/>
                    </div>
                </div>
                <div className="m-1 invisible">
                    <div className="d-flex justify-content-start flex-column flex-sm-row align-items-stretch align-items-sm-center">
                        <Field name="account" disabled={view} component={renderField} type="text" className="form-control"/>
                    </div>
                </div>
            </div>
            */}
            <div className="row-fluid">
                <div className="d-flex justify-content-center flex-column flex-sm-row align-items-stretch align-items-sm-center">
                    <Link className="btn btn-secondary m-3" to="/referents">{!view ? ("Cancelar") : ("Regresar")}</Link>
                    {!view && (<button type="submit" className="btn btn-primary m-3">Guardar</button>)}
                </div>
            </div>

        </form>
    )
};

const ReferentForm = reduxForm({
   form: 'referents',
   validate: data => {
       const error = {};
        if (!data.name) error.name = "Campo requerido.";
        if (!data.address) error.address = "Campo requerido.";
        if (!data.phone) error.phone = "Campo requerido.";
        if (!data.birthdate) error.birthdate = "Campo requerido.";
        if (!data.email) error.email = "Campo requerido.";
        if (!data.nit) error.nit = "Campo requerido.";
        if (!data.bank_name) error.bank_name = "Campo requerido.";
        if (!data.account_type) error.account_type = "Campo requerido.";
        if (!data.account) error.account = "Campo requerido.";
        if (!data.role) error.role = "Campo requerido.";
        if (data.phone){
            if (data.phone .length < 8) error.phone="El telefono debe contener al menos 8 dígitos";
        }
        return error;
   }
})(Form);

export default ReferentForm
