import Swal from 'sweetalert2';
import { api } from 'api';
import moment from "moment";
import { createActions, handleActions } from 'redux-actions'
import { goBack } from 'react-router-redux'
import { initialize as initializeForm } from 'redux-form'
import { push } from 'react-router-redux';
import { INCOME, EXPENDITURY } from './banks'
const url = 'pending_transactions';

const LOADER_PENDING = 'LOADER_PENDING';
const SET_DATA_PENDING = 'SET_DATA_PENDING';
const SET_PAGE_PENDING = 'SET_PAGE_PENDING';
const SET_UPDATE_DATA_PENDING ='SET_UPDATE_DATA_PENDING';
const SET_BUSCADOR_PENDING = 'SET_BUSCADOR_PENDING';
const SET_FILTRO_PENDING = 'SET_FILTRO_PENDING';

const listar = (page = 1) =>  (dispatch, getStore) => {
    dispatch({type: LOADER_PENDING, cargando: true});
    const store = getStore();
    const search = store.pendings.buscador;
    const filtro = store.pendings.filtro_pendings;
    let params = {page, search};

    if(filtro){
        params[''] = filtro
    }
    api.get(`${url}`, params).catch((error) => {
        dispatch({type: LOADER_PENDING, cargando: false});
        Swal(
            'Error',
            error.detail || 'Ha ocurrido un error, por favor vuelva a intentar.',
            'error'
        );
    }).then((data) => {
        if(data){
            dispatch({type: SET_DATA_PENDING, data});
            dispatch({type: SET_PAGE_PENDING, page});
        }
        dispatch({type: LOADER_PENDING, cargando: false});
    })
};
const detail = id => async (dispatch, getState) =>{
    dispatch({type: LOADER_PENDING, cargando: true});
    const data = await api.get(`${url}/${id}`).catch((error) => {
        dispatch({type: LOADER_PENDING, cargando: false});
        if(error.statusCode  === 404){
            dispatch(push('/pendings'))
        }
    })

    dispatch({type: LOADER_PENDING, cargando: false});
    if(data){
        dispatch({type: SET_UPDATE_DATA_PENDING, updateData: data});
        if(data.transaction_type == INCOME){
            data.amount = data.debe
            delete data.debe
            delete data.haber
        } else if(data.transaction_type == EXPENDITURY){
            data.amount = data.haber
            delete data.debe
            delete data.haber
        }
        data.date = moment(data.date)
        data.date = moment(data.data).format("YYYY-MM-DD")
        data.hour = moment()
        dispatch(initializeForm('pendingTransaction', data))
    }
}

const create = () => (dispatch, getStore) => {
    const formData = getStore().form.pendings.values;
    dispatch({type: LOADER_PENDING, cargando: true})
    api.post(`${url}`, formData).then((data) => {
        dispatch({type: LOADER_PENDING, cargando: false})
        Swal('Éxito', 'Se ha creado la pendings.', 'success')
        .then(() => {
            dispatch(push('/pendings'))
        })
    }).catch((error) => {
        dispatch({type: LOADER_PENDING, cargando: false})
        Swal(
            'Error',
             error.detail || 'Ha ocurrido un error, por favor vuelva a intentar.',
            'error'
        );
    }).finally(() => {
        dispatch({type: LOADER_PENDING, cargando: false})
    });
};

const update = () => (dispatch, getStore) => {
    const formData = _.cloneDeep(getStore().form.pendingTransaction.values);
    if (formData.transaction_type == INCOME ){
        formData.debe = formData.amount
        formData.haber = 0
        delete formData.amount
    } else if (formData.transaction_type == EXPENDITURY) {
        formData.haber = formData.amount
        formData.debe = 0
        delete formData.amount
    }
    formData.pending = 0
    formData.date = formData.date + 'T' + formData.hour.format("HH:mm:ss")
    delete formData.hour
    formData.bank_account = formData.bank_account.value
    console.log(formData)
    dispatch({type: LOADER_PENDING, cargando: true});
    api.put(`bank_transaction/${formData.id}`, formData)
    .then((data) => {
        dispatch({type: LOADER_PENDING, cargando: false});
        if(data){
            Swal(
                'Éxito',
                'Datos editados correctamente.',
                'success'
            ).then(() => {
                dispatch(listar())
            })
        }
    })
    .catch((error) =>{
        dispatch({type: LOADER_PENDING, cargando: false});
        Swal(
            'Error',
             error.detail || 'Ha ocurrido un error, verifique los datos.',
            'error'
        )
        if(error.statusCode === 404){
            dispatch(push('/pending'));
            dispatch(listar())
        }
    })
}
const destroy = (id) => (dispatch, getStore) => {
    dispatch({type: LOADER_PENDING, cargando: true});
    api.eliminar(`${url}/${id}`).catch((error) => {
        dispatch({type: LOADER_PENDING, cargando: false});
        Swal('Error', 'No se ha logrado borrar el registro.', 'error')
            .then(() => {
                dispatch(listar(1));
        })
    }).then((data) => {
        dispatch({type: LOADER_PENDING, cargando: false});
        Swal('Éxito', 'Se ha eliminado correctamente.', 'success')
            .then(() => {
                dispatch(listar(1));
            })
    })
}

const search = (search) => (dispatch, getStore) => {
    dispatch({type: SET_BUSCADOR_PENDING, buscador: search});
    dispatch(listar(1));
};
const filtro = (filtro) => (dispatch, getStore) => {
    if (filtro == 0){
        filtro = null
    }
    dispatch({type: SET_FILTRO_PENDING, filtro_pendings: filtro});
    dispatch(listar(1));
};
export const actions = {
    listar,
    detail,
    create,
    update,
    destroy,
    search,
    filtro
};
export const reducer = {
    [LOADER_PENDING]: (state, { cargando }) => {
        return {...state, cargando }
    },
    [SET_DATA_PENDING]: (state, { data }) => {
        return {...state, data }
    },
    [SET_PAGE_PENDING]: (state, { page }) => {
        return {...state, page }
    },
    [SET_UPDATE_DATA_PENDING]: (state, { updateData }) => {
        return {...state, updateData }
    },
    [SET_BUSCADOR_PENDING]: (state, { buscador }) => {
        return {...state, buscador }
    },
    [SET_FILTRO_PENDING]: (state, { filtro_pendings }) => {
        return {...state, filtro_pendings }
    },
}
export const initialState = {
    cargando: false,
    page: 1,
    data: {
        count: 0,
        next: null,
        previous: null,
        results: [],
    },
    buscador: '',
    filtro_pendings: null,
    updateData: {},
};
export default handleActions(reducer, initialState)