import React, {Component} from 'react';
import Modal from 'react-responsive-modal';
import classNames from 'classnames';
import "../notations";

export class Esf extends Component {
    constructor(props) {
        super(props);
        this.state = { open: false, symbol: ''};
    }

    openModal = () => {
        this.setState({ open: true, symbol: '' });
    };

    closeModal = () => {
        this.setState({ open: false, symbol: '' });
    };

    selectOption = (input, value) => {
        //const final_value = value > 0 ? '+' + value : value;
        const final_value = `${this.state.symbol}${value}`;
        input.onChange(final_value.replace('+',''));
        this.closeModal();
    };

    render() {
        const { input, meta: { touched, error }, title, jaeger, view } = this.props;
        const { open } = this.state;
        const invalid = touched && error;

        
        let c = 0;
        const columnas = []
        for(c; c <= 20; c=c+2 ){
          const fila = []
          let i = 0;
          for(i; i <= 1.75; i=i+ 0.25){
            const cantidad = i + c;
            fila.push(cantidad.toFixed(2));
          }
          columnas.push(fila)
        }

        return (
            <React.Fragment>
                <Modal open={open} onClose={this.closeModal} >
                    <div className="text-center">
                        <h2 className="border-bottom">{title}</h2>
                    </div>
                    <div className="text-center mb-2">
                      <div 
                        style={{ display: "flex", justifyContent: "center", gap: "10px"}}
                      >
                        <button
                          onClick={() => this.setState({ symbol: '+' })}
                          style={{
                            background: this.state.symbol == '+' ? "green" : "unset",
                            color: this.state.symbol == '+' ? "white" : "inherit",
                            borderRadius: "25px",
                            paddingInline: "10px",
                            fontSize: "18px"
                          }}
                        > + </button>
                        <button
                          onClick={() => this.setState({ symbol: '-' })}
                          style={{
                            background: this.state.symbol == '-' ? "green" : "unset",
                            color: this.state.symbol == '-' ? "white" : "inherit",                            
                            borderRadius: "25px",
                            paddingInline: "10px",
                            fontSize: "18px"
                          }}                        
                        > - </button>
                      </div>
                    </div>

                    <div className='d-flex flex-row'>
                        <table id="notations-table-modal">
                            <tbody>
                            {columnas.map(columna=>(
                                <tr key={columna}>
                                    {columna.map(fila=>{                                        
                                        return(
                                        <React.Fragment key={fila}>   
                                        {fila <= 20 &&                                      
                                          <td>
                                              <div className="radio">
                                                  <label
                                                      htmlFor="service_type"
                                                      className={`radio-inline mr-5 d-flex align-items-center`}
                                                      onClick={() => {
                                                        if(this.state.symbol == "+" || this.state.symbol == "-" ){
                                                          this.selectOption(input, fila)
                                                        }
                                                      }}>
                                                      
                                                      <input                                                          
                                                          type={"radio"}
                                                          value={fila}
                                                          {...input}
                                                          onChange={() => {
                                                            if(this.state.symbol == "+" || this.state.symbol == "-" ){
                                                              this.selectOption(input, fila)
                                                            }
                                                          }}
                                                          className={classNames('form-control', { 'is-invalid': invalid })} />
                                                      <span>&nbsp;{fila}</span>
                                                  </label>
                                              </div>
                                          </td>
                                        }       
                                        </React.Fragment>
                                        );
                                    })}
                                </tr>
                            ))}
                            </tbody>
                        </table>                      
                    </div>

                    <div className="d-flex justify-content-center align-items-center mt-4">
                        <div
                            className="btn btn-secondary mx-4"
                            onClick={() => {
                                input.onChange(undefined);
                                this.closeModal();
                            }}>
                            Cancelar
                        </div>
                    </div>
                </Modal>
                <span className="form-control text-center" onClick={this.openModal} >{input.value}</span>
            </React.Fragment>
        );
    }
}
