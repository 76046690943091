import Table from "../../Utils/Grid";
import React, { Component } from 'react';
import { BreakLine } from '../../Utils/tableOptions';
import DayPickerInput from 'react-day-picker/DayPickerInput';

import MomentLocaleUtils, {
    formatDate,
    parseDate,
} from 'react-day-picker/moment';

import CustomToolbar from '../../Utils/Toolbar/CustomToolbar';
import moment from "moment";
import FiltroProductos from "./filtroProductos";
import LoadMask from "../../Utils/LoadMask/LoadMask";
import NumberFormat from "react-number-format";
import './estilo_tabla';

const SUPERUSER = 'superuser';

class CuentaAbierta extends Component{
    componentWillUnmount() {
        this.props.resetStore();
    }
    existeFecha(fecha){
        var fechaf = fecha.split("/");
        var d = fechaf[0];
        var m = fechaf[1];
        var y = fechaf[2];
        return m > 0 && m < 13 && y > 1900 && y < 32768 && d > 0 && d <= (new Date(y, m, 0)).getDate();
    }
    render() {
        const {
            listar,
            setInitialDate,
            setFinalDate,
            setFiltroServicio,
            quitarFiltro,
        } = this.props;

        const {
            cargando,
            page,
            data,
            initial_date,
            final_date,
            filtro_servicios,
            categorias,
        } = this.props;

        let lista_totales=[];
        lista_totales.push({
            id_categoria: 0,
            nombre: "PRECIO A",
            suma_cantidad: 0,
            suma_precio: 0,
            suma_total: 0,
            suma_payback:0,
            suma_payback_social: 0,
        });

        categorias.forEach((categoria, fm)=>{
            lista_totales.push({
                id_categoria: categoria.id,
                nombre: categoria.name,
                suma_cantidad: 0,
                suma_precio: 0,
                suma_descuento: 0,
                suma_total: 0,
                suma_payback: 0,
                suma_payback_social: 0,
            })
        });

        console.log("lista_totales: ", lista_totales);

        return (
            <div className="content pt-3">
                <div className="m-auto row-fluid w-100">
                    <div className="table-decoration">
                        <div className="d-flex flex-row justify-content-around align-items-center">

                        <div className="top-title-toolbar mt-3 d-flex">
                            <span className="ml-2 text-uppercase text-titulo"><strong>CONSOLIDADO acá</strong></span>
                        </div>

                        <div className="d-flex flex-row justify-content-end align-items-center w-50">
                            <NumberFormat format="##/##/####" placeholder="DD/MM/YYYY" mask={['D', 'D', 'M', 'M', 'Y', 'Y', 'Y', 'Y']}
                                className="form-control w-20"
                                value={initial_date}
                                onValueChange={(values) => {
                                    if(this.existeFecha(values.formattedValue)){
                                        setInitialDate(values.formattedValue)
                                    }
                                }}
                                autoFocus
                                onFocus={(e) => {
                                    e.target.setSelectionRange(0,10);
                                }}
                                onKeyUp={(ev)=>{
                                    if(ev.keyCode==13){
                                        listar()
                                    }
                                }}
                            />

                            <NumberFormat format="##/##/####" placeholder="DD/MM/YYYY" mask={['D', 'D', 'M', 'M', 'Y', 'Y', 'Y', 'Y']}
                                className="form-control w-20 ml-2 mr-2"
                                value={final_date}
                                onValueChange={(values) => {
                                    if (this.existeFecha(values.formattedValue)){
                                        setFinalDate(values.formattedValue)
                                    }

                                }}

                                onFocus={(e) => {
                                    e.target.setSelectionRange(0,10);
                                }}
                                onKeyUp={(ev)=>{
                                    if(ev.keyCode==13){
                                        listar()
                                    }
                                }}
                            />
                            {/*
                            <div className="top-title-toolbar mt-3">
                                <CustomToolbar
                                    buscar={this.props.search}
                                    buscador={this.props.buscador}
                                />
                            </div>
                            */}
                        </div>

                    </div>
                    <div className='d-flex flex-row justify-content-around align-items-center pr-5'>
                        <FiltroProductos
                            setFiltroServicio={setFiltroServicio}
                            filtro_servicios={filtro_servicios}
                            quitarFiltro={quitarFiltro}
                        />

                        <button
                            onClick={()=>this.props.generarExcel()}
                            className='btn btn-success btn-sm'
                        >
                            Generar Excel
                        </button>
                    </div>

                    <LoadMask loading={cargando}>
                    <div className="d-flex flex-row table-responsive" style={{maxHeight: 400}}>
                        <table className='table'>
                            <thead>
                                <tr style={{background: 'gray'}}>
                                    <th colSpan={10}></th>
                                    <th colSpan={5} className='text-center'>PRECIO A</th>
                                    {categorias.map((categoria, i) => (
                                        <React.Fragment key={categoria.id}>
                                            <th colSpan={5} className='text-center'>{categoria.name}</th>
                                        </React.Fragment>
                                    ))}
                                </tr>
                                <tr style={{background: 'gray'}}>
                                    <th>Fecha</th>
                                    <th>Código</th>
                                    <th>Documento</th>
                                    <th>NIT</th>
                                    <th>Nombre Cliente</th>
                                    <th>Número Certificación</th>
                                    <th>Nombre Paciente</th>
                                    <th>Refractado Por</th>
                                    <th>Atendido Por</th>
                                    <th>Producto</th>
                                    <th>Referente</th>
                                    <th>Trabajador Social</th>

                                    <th>Cant</th>
                                    <th>Precio</th>
                                    <th>Total</th>
                                    <th>Payback</th>
                                    <th>Payback Social</th>
                                    {categorias.map((a, b) => (
                                        <React.Fragment key={`2${b}`}>
                                            <th>Cant</th>
                                            <th>Precio</th>
                                            <th>Descuento</th>
                                            <th>Total</th>
                                            <th>Payback</th>
                                            <th>Payback Social</th>
                                        </React.Fragment>
                                    ))}
                                </tr>
                            </thead>
                            <tbody>
                                {data.list && data.list.map((detalle, d)=>{
                                    return(
                                    <tr key={`3${d}`}>
                                        <td>{moment(detalle.movement.made).format("DD/MM/YYYY")}</td>
                                        <td>{detalle.codigo}</td>
                                        <td>{detalle.movement.correlative}</td>
                                        <td>{detalle.movement.nit}</td>
                                        <td>{detalle.movement.name}</td>
                                        <td>{detalle.movement.numero}</td>
                                        <td>{detalle.movement.patient.first_name} {detalle.movement.patient.last_name}</td>
                                        <td>{detalle.movement.refracted ? detalle.movement.refracted.name : "---"}</td>
                                        <td>{detalle.movement.doctor ? detalle.movement.doctor.name : "---"}</td>
                                        <td style={{minWidth: 100, whiteSpace:'normal'}}>
                                            <a
                                                className='font-weight-bold cursor-pointer'
                                                href={`/#/report-movements/view-bill/${detalle.movement.id}/movimiento`}
                                                target='_blank'
                                            >
                                                {detalle.producto}
                                            </a>
                                        </td>
                                        <td>{detalle.referente}</td>
                                        <td>{detalle.trabajador_social}</td>

                                        {detalle.movement.referent.institution === null ?
                                            <React.Fragment>
                                                {
                                                    lista_totales.forEach((lt) => {
                                                        if(lt.id_categoria === 0){
                                                            lt.suma_cantidad += detalle.cantidad;
                                                            lt.suma_precio += detalle.precio;
                                                            lt.suma_total += detalle.total;
                                                            lt.suma_descuento += detalle.descuento;
                                                            lt.suma_payback += detalle.payback_calculado;
                                                            lt.suma_payback_social += detalle.payback_social_calculado;
                                                        }
                                                    })
                                                }
                                            <React.Fragment>
                                                <td>{detalle.cantidad}</td>
                                                <td>Q{detalle.precio}</td>
                                                <td>Q{detalle.descuento}</td>
                                                <td>Q{detalle.total}</td>
                                                <td>Q{detalle.payback_calculado}</td>
                                                <td>Q{detalle.payback_social_calculado}</td>

                                                {categorias.map((cat, c) => {
                                                    return(
                                                    <React.Fragment key={`5${c}_${d}`}>
                                                        <td>{0}</td>
                                                        <td>Q{0.00}</td>
                                                        <td>Q{0.00}</td>
                                                        <td>Q{0.00}</td>
                                                        <td>Q{0.00}</td>
                                                    </React.Fragment>
                                                    );
                                                })}
                                            </React.Fragment>
                                            </React.Fragment>
                                        :
                                            <React.Fragment>
                                            <td>{0}</td>
                                            <td>Q{0.00}</td>
                                            <td>Q{0.00}</td>
                                            <td>Q{0.00}</td>
                                            <td>Q{0.00}</td>
                                            {categorias.map((cat, ck) => {
                                                if(cat.id === detalle.movement.referent.institution.referent_category.id){
                                                    lista_totales.forEach((lt) => {
                                                        if(cat.id === lt.id_categoria){
                                                            lt.suma_cantidad += detalle.cantidad;
                                                            lt.suma_precio += detalle.precio;
                                                            lt.suma_total += detalle.total;
                                                            lt.suma_descuento += detalle.descuento;
                                                            lt.suma_payback += detalle.payback_calculado;
                                                            lt.suma_payback_social += detalle.payback_social_calculado;
                                                        }
                                                    })

                                                    return(
                                                        <React.Fragment key={`m_${ck}_${d}`}>
                                                            <td>{detalle.cantidad}</td>
                                                            <td>Q{detalle.precio}</td>
                                                            <td>Q{detalle.descuento}</td>
                                                            <td>Q{detalle.total}</td>
                                                            <td>Q{detalle.payback_calculado}</td>
                                                            <td>Q{detalle.payback_social_calculado}</td>
                                                        </React.Fragment>
                                                    );
                                                }else{
                                                    return(
                                                        <React.Fragment key={`n_${ck}_${d}`}>
                                                            <td>{0}</td>
                                                            <td>Q{0.00}</td>
                                                            <td>Q{0.00}</td>
                                                            <td>Q{0.00}</td>
                                                            <td>Q{0.00}</td>
                                                            <td>Q{0.00}</td>
                                                        </React.Fragment>
                                                    );
                                                }
                                            })}
                                            </React.Fragment>
                                        }
                                    </tr>
                                    );
                                })}


                                <tr>
                                    <td
                                        colSpan={11}
                                        className='text-dark font-weight-bold text-center'
                                    >Totales</td>
                                    {lista_totales.map((lt, k) => {
                                        return(
                                            <React.Fragment key={k}>
                                                <td className='text-primary font-weight-bold'>{lt.suma_cantidad}</td>
                                                <td className='text-primary font-weight-bold'>Q{lt.suma_precio.toFixed(2)}</td> 
                                                {k>0 && <td className='text-primary font-weight-bold'>Q{lt.suma_descuento.toFixed(2)}</td>}
                                                <td className='text-primary font-weight-bold'>Q{lt.suma_total.toFixed(2)}</td>
                                                <td className='text-primary font-weight-bold'>Q{lt.suma_payback.toFixed(2)}</td>
                                                <td className='text-primary font-weight-bold'>Q{lt.suma_payback_social.toFixed(2)}</td>
                                            </React.Fragment>
                                        );
                                    })}
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    {/*
                    <div className="d-flex flex-row table-responsive">
                        <table className='table'>
                            <tbody>
                            <tr>
                                <td
                                    className='text-dark font-weight-bold text-center'
                                >03/03/2020</td>
                                <td>
                                    vit-2
                                </td>
                                <td style={{minWidth: 100, whiteSpace:'normal'}} />
                                    {lista_totales.map((lt, k) => {
                                        return(
                                            <React.Fragment key={k}>
                                                <td className='text-primary font-weight-bold'>{lt.suma_cantidad}</td>
                                                <td className='text-primary font-weight-bold'>Q{lt.suma_precio.toFixed(2)}</td>
                                                <td className='text-primary font-weight-bold'>Q{lt.suma_total.toFixed(2)}</td>
                                                <td className='text-primary font-weight-bold'>Q{lt.suma_payback.toFixed(2)}</td>
                                            </React.Fragment>
                                        );
                                    })}
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    */}
                    </LoadMask>
                    </div>
                </div>
            </div>
        )
    }
}

export default CuentaAbierta;
