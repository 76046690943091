import React, { Component } from 'react';
import Form from './InformationSheet';

export default class InformationSheetForm extends Component {
    state = {
        archivos: [], //Este debe llamarse files (porque en la api así lo llama)
        cargando: false,
    }

    setFiles = (archivos) => {
        this.setState({ archivos });
    }

    submit = async(data) => {
        if(data && !this.state.cargando){
            await this.setState({cargando:true});
            this.props.saveScan(this.state.archivos);
        }
    };

    render() {
        const {patient_id, appointment_id} = this.props;

        return (
            <Form
                initialValues={{
                    patient_id: patient_id,
                    appointment_id: appointment_id,
                }}
                onSubmit={this.submit}
                setFiles={this.setFiles}
                openModalScan={this.props.openModalScan}
                cargando={this.state.cargando}
            />
        );
    }
}
