import React, { Component } from 'react';
import { Field, reduxForm,  formValueSelector } from 'redux-form';
import { renderField, renderSearchSelect, renderCurrency, dateInput } from 'Utils/renderField/renderField';

import { api } from "api";

const getBankAccounts = (search)  =>{
    return api.get("bank_accounts",{search})
        .then((data) => {
            if (data){
                const bank_accounts = data.results.map(x => (
                    {value: x.id, label: x.owner, number: x.account_number}
                ));
                return bank_accounts
            }
            else{
                return []
            }
        })
        .catch(e => {
            return []
        })
}

export const renderDepositRow =({ fields, meta: {error, submitFailed}, edit, view}) => (

    <React.Fragment>
        {
            fields.map((field, index) => (
                <tr key={index} >
                    <td className="d-none">
                    <Field
                        component="input"
                        name={`${field}.id`}
                        type="hidden"
                    />
                    </td>
                    <td>
                        <Field
                            disabled={false}
                            name={`${field}.amount`}
                            component={renderCurrency}
                            type="text"
                            addClass="w-75"
                        />
                    </td>
                    {/*
                    <td>
                    <Field
                        name={`${field}.bank_account`}
                        valueKey="value"
                        labelKey="number"
                        label="bank_account"
                        component={renderSearchSelect}
                        loadOptions={getBankAccounts}
                        className="no-border"
                        addClass=""
                    />
                    </td>
                    <td>
                    <Field
                        name={`${field}.bank_account`}
                        valueKey="value"
                        labelKey="label"
                        label="bank_account"
                        component={renderSearchSelect}
                        loadOptions={getBankAccounts}
                        className="no-border"
                        addClass=""
                    />
                    </td>
                    */}
                    <td>
                        <Field
                            disabled={false}
                            name={`${field}.document_number`}
                            component={renderField}
                            type="text"
                            addClass="w-75"
                        />
                    </td>
                    <td>
                        <Field
                            name={`${field}.date`}
                            component={dateInput}
                            className="w-50"
                            />
                    </td>
                    <td className="text-center">
                        <img className="action-img delete-button-r2"
                            src={require("../../../../../assets/img/icons/delete-icon.png")}
                            onClick={() => {fields.remove(index)}}
                            alt="Eliminar" />
                    </td>
                </tr>
                )
            )
        }
                <tr id="add-row">
                    <td colSpan={3}></td>
                    <td className="text-center">
                            <img onClick={() => fields.push()} className="action-img add-button-r2" src={require("../../../../../assets/img/icons/add-icon.png")} alt="Agregar" />
                    </td>
                </tr>
        {/* row for the add button */}
        {
            submitFailed && (
                <div className="invalid-feedback d-flex justify-content-center">
                    {error}
                </div>
            )
        }
    </React.Fragment>
);
