import React, { Component } from 'react';
import classNames from 'classnames';

import { RenderCurrency } from 'Utils/renderField/renderReadField';
import { PrintContainer, actions as printActions } from 'Utils/Print';
import BoxDetail from './BoxDetail';

import './cashBox.css';
class CloseBox extends Component {
    componentWillUnmount() {
        this.props.resetStore();
    }
    componentWillMount() {
        this.props.checkCashBox();
    }

    componentDidMount() {
        this.props.checkCashBox();
    }

    decimalAdjust = (type, value, exp) => {
        if (typeof exp === 'undefined' || +exp === 0) {
          return Math[type](value);
        }
        value = +value;
        exp = +exp;
        if (isNaN(value) || !(typeof exp === 'number' && exp % 1 === 0)) {
          return NaN;
        }
        value = value.toString().split('e');
        value = Math[type](+(value[0] + 'e' + (value[1] ? (+value[1] - exp) : -exp)));
        value = value.toString().split('e');
        console.log("ADJUST", +(value[0] + 'e' + (value[1] ? (+value[1] + exp) : exp)));
        return +(value[0] + 'e' + (value[1] ? (+value[1] + exp) : exp));
    }

    state = {
        billete200: 0,
        billete100: 0,
        billete50: 0,
        billete20: 0,
        billete10: 0,
        billete5: 0,
        billete1: 0,
        moneda1: 0,
        moneda1c:0,
        moneda50: 0,
        moneda25: 0,
        moneda10: 0,
        moneda5: 0,
    }

    logChange = (e) => {
        const value = ((parseFloat(e.target.value) <= 0) || e.target.value == '') ? 0 : (parseFloat(e.target.value) * parseFloat(e.target.id)).toFixed(2);
        this.setState({ [e.target.name] : value } );
    }

    render(){
        let {
            openCashBox,
            addCash,
            removeCash,
            closeBox,
            listarMovimientos,
            sales_open_box,
            summary_boxes,
            isNavBarOpen,

            //add
            openAccount,
            summary_category,
            movements,
            page,
            modal,
        } = this.props;
        const id = sales_open_box && sales_open_box.data ? sales_open_box.data.id : null;
        openCashBox = openCashBox || {data: {opening_amount : 0, id}};

        let totalFísico = (parseFloat(this.state.billete200) + parseFloat(this.state.billete100) + parseFloat(this.state.billete50) + parseFloat(this.state.billete20) + parseFloat(this.state.billete10) + parseFloat(this.state.billete5) + parseFloat(this.state.billete1) + parseFloat(this.state.moneda1) + parseFloat(this.state.moneda1c) + parseFloat(this.state.moneda50) + parseFloat(this.state.moneda25) + parseFloat(this.state.moneda10) + parseFloat(this.state.moneda5)).toFixed(2);


        let cuadre = 0;
        let total_efectivo = 0;
        let total_tarjeta = 0;
        let total_ingreso = 0;
        let total_abonos = 0;
        let total_abonos_completo = 0;
        let total_deposito = 0;

        if (summary_boxes){
            summary_boxes.forEach((box, i)=>{
                if(box.ventas.totales.total_efectivo !== null){
                    cuadre += parseFloat(parseFloat(box.ventas.totales.total_efectivo).toFixed(2));
                }
                if(box.total_ingreso !== null){
                    cuadre += parseFloat(parseFloat(box.total_ingreso).toFixed(2));
                }
                if(box.total_efectivo !== null){
                    total_efectivo += parseFloat(parseFloat(box.total_efectivo).toFixed(2));
                }
                if(box.total_tarjeta !== null){
                    total_tarjeta += parseFloat(parseFloat(box.total_tarjeta).toFixed(2));
                }
                if(box.total_ingreso !== null){
                    total_ingreso += parseFloat(parseFloat(box.total_ingreso).toFixed(2));
                }
                if(box.total_abonos !== null){
                    total_abonos += parseFloat(parseFloat(box.total_abonos).toFixed(2));
                    total_efectivo += parseFloat(parseFloat(box.total_abonos).toFixed(2)); //Se agregó a la suma del total ingresado en efectivo
                }
                if (box.total_abonos_efectivo !== null) {
                    cuadre += parseFloat(parseFloat(box.total_abonos_efectivo).toFixed(2));
                }
                if(box.total_abonos_completo !== null){
                    total_abonos_completo += parseFloat(parseFloat(box.total_abonos_completo).toFixed(2));
                }
                if(box.total_deposito !== null){
                    total_deposito += parseFloat(parseFloat(box.total_deposito).toFixed(2));
                }
            });
        }
        //agregado para considerar el monto de apertura
        cuadre += (openCashBox) ? parseFloat(openCashBox.data.opening_amount) : 0;
        totalFísico = parseFloat(totalFísico);

        let caja_cuadre = (Math.abs(cuadre - totalFísico) <= 0.05 ) ? true : false;
        let classTotal = (caja_cuadre) ? "dinero-de-mas" : "dinero-faltante";
        let habilitar = (caja_cuadre) ? " " : "disabled";

        console.log("SUMMARY BOXES: ", summary_boxes);

        return(
            <div className={classNames('content-with-tabs', { 'content-with-tabs': isNavBarOpen })}>
                {openCashBox != null &&
                <div>
                <div className="d-inline-flex w-100">
                    <div className="row-fluid w-50 tab-background">
                        <a href="/#/oppening-closing" className="d-flex justify-content-center align-items-center tab tab-br-br">
                            VENTA
                        </a>
                    </div>
                    <div className="row-fluid w-50 tab-background-blue">
                        <a href="/#/oppening-closing" className="d-flex justify-content-center align-items-center tab-active tab-br-tl">
                            CAJA
                        </a>
                    </div>
                </div>

                {/* <div className="table-decoration mx-5 mt-5 mb-3 d-flex flex-column"> */}
                    <BoxDetail { ...openCashBox.data }
                        addCash={addCash}
                        closeBox={closeBox}
                        dataMovimientos={movements}
                        listarMovimientos={listarMovimientos}
                        page={page}
                        modal={modal}
                        summary_boxes={summary_boxes}
                        summary_category={summary_category}
                        openAccount={openAccount}
                        paso_final={true}
                    />
                        {/*
                        <div className="fields">
                            <div className="top-title">
                                CIERRE DE CAJA
                            </div>
                            <div className="d-flex">
                                <PrintContainer name='cierre_caja' className="w-100 d-flex flex-row">
                                <div className="w-50 secciones">
                                    <div className="w-100 justify-content-center">
                                        <table className="table table-sm table-resume">
                                            <thead>
                                                <tr>
                                                    <th>Resumen Por Entidad Financiera</th>
                                                    <th>Total</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                            {summary_boxes && summary_boxes.length > 0 ?
                                                <React.Fragment>
                                                {summary_boxes.map((detalle, i) =>
                                                    <React.Fragment key={i}>
                                                    {(detalle.total_efectivo + detalle.total_abonos) > 0 &&
                                                        <tr key={i}>
                                                            <th>{detalle.usuario_fel}</th>
                                                            <td className="text-right"><RenderCurrency value={detalle.total_efectivo + detalle.total_abonos} /></td>
                                                        </tr>
                                                    }
                                                    </React.Fragment>
                                                )}
                                                </React.Fragment>
                                                : null
                                            }
                                            </tbody>
                                        </table>
                                    </div>

                                    <div className="w-100 justify-content-center">
                                        <table className="table table-sm table-resume">
                                            <thead>
                                                <tr>
                                                    <th colSpan={2}>Resumen general</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <th> Apertura </th>
                                                    <td className="text-right">
                                                        <RenderCurrency value={openCashBox.data.opening_amount} />
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th> Ventas en Efectivo </th>
                                                    <td className="text-right">
                                                        <RenderCurrency value={total_efectivo} />
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th> Ventas con tarjeta de credito</th>
                                                    <td className="text-right">
                                                        <RenderCurrency value={total_tarjeta} />
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th> Abonos y Anticipos</th>
                                                    <td className="text-right">
                                                        <RenderCurrency value={total_abonos_completo} />
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th> Depositos </th>
                                                    <td className="text-right">
                                                        <RenderCurrency value={total_deposito} />
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th>Cuanto has agregado en efectivo</th>
                                                    <td className="text-right">
                                                        <RenderCurrency value={total_ingreso} />
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>

                                <div className="w-50 secciones">
                                    <div className="w-100 justify-content-center">
                                        <ul className="list-group">
                                            <li className="list-group-item active active-cashbox font-weight-bold">
                                                Cuadre de Efectivo
                                            </li>
                                            <li className="list-group-item">
                                                <span className="pull-left subtitle">Registrado en caja</span>
                                                <span className="pull-right subtitle">Total en efectivo</span>
                                            </li>
                                            <li className="list-group-item">
                                                <span className="pull-left"><RenderCurrency value={cuadre} /></span>
                                                <span className={"pull-right " + classTotal}><RenderCurrency value={totalFísico} /></span>
                                            </li>

                                            <li className="list-group-item text-right"></li>
                                            <li className="list-group-item text-right"></li>

                                            <li className="list-group-item text-right">
                                                <button
                                                    type="button"
                                                    className='btn btn-sm btn-primary'
                                                    onClick={(e) => {
                                                        e.preventDefault();
                                                        printActions.print('cierre_caja');
                                                    }}
                                                >Imprimir</button>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                </PrintContainer>
                            </div>
                            <div className="flex contenedor-seccion my-5">
                                <div className="w-100 seccion">
                                    <div className="w-100 justify-content-center">
                                        <table className="table tabla-cuadre">
                                            <thead>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td className="text-center">
                                                    <img src={require("../../../../assets/img/icons/billete200.png")} alt="CashBox image" className="icono-moneda" />
                                                        <input name="billete200" id="200" onChange={(e) => this.logChange(e)} type="number" className="input-match" placeholder="0"/>
                                                    </td>
                                                    <td className="text-center">
                                                    <img src={require("../../../../assets/img/icons/billete100.png")} alt="CashBox image" className="icono-moneda" />
                                                        <input name="billete100" id="100" onChange={(e) => this.logChange(e)} type="number" className="input-match" placeholder="0"/>
                                                    </td>
                                                    <td className="text-center">
                                                        <img src={require("../../../../assets/img/icons/billete50.png")} alt="CashBox image" className="icono-moneda" />
                                                        <input  name="billete50" id="50" onChange={(e) => this.logChange(e)} type="number" className="input-match" placeholder="0"/>
                                                    </td>
                                                    <td className="text-center">
                                                        <img src={require("../../../../assets/img/icons/billete20.png")} alt="CashBox image" className="icono-moneda" />
                                                        <input  name="billete20" id="20" onChange={(e) => this.logChange(e)} type="number" className="input-match" placeholder="0"/>
                                                    </td>
                                                    <td className="text-center">
                                                        <img src={require("../../../../assets/img/icons/billete10.png")} alt="CashBox image" className="icono-moneda" />
                                                        <input  name="billete10" id="10" onChange={(e) => this.logChange(e)} type="number" className="input-match" placeholder="0"/>
                                                    </td>
                                                    <td className="text-center">
                                                        <img src={require("../../../../assets/img/icons/billete5.png")} alt="CashBox image" className="icono-moneda" />
                                                        <input  name="billete5" id="5" onChange={(e) => this.logChange(e)} type="number" className="input-match" placeholder="0"/>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td className="text-center">
                                                        <img src={require("../../../../assets/img/icons/billete1.png")} alt="CashBox image" className="icono-moneda" />
                                                        <input  name="billete1" id="1" onChange={(e) => this.logChange(e)} type="number" className="input-match" placeholder="0"/>
                                                    </td>
                                                    <td className="text-center">
                                                        <img src={require("../../../../assets/img/icons/moneda1.png")} alt="CashBox image" className="icono-moneda" />
                                                        <input  name="moneda1" id="1" onChange={(e) => this.logChange(e)} type="number" className="input-match" placeholder="0"/>
                                                    </td>
                                                    <td className="text-center">
                                                        <img src={require("../../../../assets/img/icons/moneda50.png")} alt="CashBox image" className="icono-moneda" />
                                                        <input name="moneda50" id="0.50" onChange={(e) => this.logChange(e)} type="number" className="input-match" placeholder="0"/>
                                                    </td>
                                                    <td className="text-center">
                                                        <img src={require("../../../../assets/img/icons/moneda25.png")} alt="CashBox image" className="icono-moneda" />
                                                        <input name="moneda25" id="0.25" onChange={(e) => this.logChange(e)} type="number" className="input-match" placeholder="0"/>
                                                    </td>
                                                    <td className="text-center">
                                                        <img src={require("../../../../assets/img/icons/moneda10.png")} alt="CashBox image" className="icono-moneda" />
                                                        <input  name="moneda10" id="0.10" onChange={(e) => this.logChange(e)} type="number" className="input-match" placeholder="0"/>
                                                    </td>
                                                    <td className="text-center">
                                                        <img src={require("../../../../assets/img/icons/moneda5.png")} alt="CashBox image" className="icono-moneda" />
                                                        <input  name="moneda5" id="0.05" onChange={(e) => this.logChange(e)} type="number" className="input-match" placeholder="0"/>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>

                                <div className="w-100 seccion-button-closeBox">
                                    <div className="w-100 justify-content-center">
                                        <a href="/#/oppening-closing/box" className="btn btn-secondary btn-sm">Cancelar</a>
                                        &nbsp;&nbsp;&nbsp;
                                        <button className={"btn btn-primary btn-sm " + habilitar} onClick={(caja_cuadre) ? ()=>closeBox(openCashBox.data.id) : null}>Realizar Cierre</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    */}
                {/*</div>*/}


                <div className="flex contenedor-seccion my-5">

                                <div className="w-50 secciones">
                                    <div className="w-100 justify-content-center">
                                        <ul className="list-group">
                                            <li className="list-group-item active active-cashbox font-weight-bold">
                                                Cuadre de Efectivo
                                            </li>
                                            <li className="list-group-item">
                                                <span className="pull-left subtitle">Registrado en caja</span>
                                                <span className="pull-right subtitle">Total en efectivo</span>
                                            </li>
                                            <li className="list-group-item">
                                                <span className="pull-left"><RenderCurrency value={cuadre} /></span>
                                                <span className={"pull-right " + classTotal}><RenderCurrency value={totalFísico} /></span>
                                            </li>
                                        </ul>
                                    </div>
                                </div>

                                <div className="w-100 seccion">
                                    <div className="w-100 justify-content-center">
                                        <table className="table tabla-cuadre">
                                            <thead>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td className="text-center">
                                                    <img src={require("../../../../assets/img/icons/billete200.png")} alt="CashBox image" className="icono-moneda" />
                                                        <input name="billete200" id="200" onChange={(e) => this.logChange(e)} type="number" className="input-match" placeholder="0"/>
                                                    </td>
                                                    <td className="text-center">
                                                    <img src={require("../../../../assets/img/icons/billete100.png")} alt="CashBox image" className="icono-moneda" />
                                                        <input name="billete100" id="100" onChange={(e) => this.logChange(e)} type="number" className="input-match" placeholder="0"/>
                                                    </td>
                                                    <td className="text-center">
                                                        <img src={require("../../../../assets/img/icons/billete50.png")} alt="CashBox image" className="icono-moneda" />
                                                        <input  name="billete50" id="50" onChange={(e) => this.logChange(e)} type="number" className="input-match" placeholder="0"/>
                                                    </td>
                                                    <td className="text-center">
                                                        <img src={require("../../../../assets/img/icons/billete20.png")} alt="CashBox image" className="icono-moneda" />
                                                        <input  name="billete20" id="20" onChange={(e) => this.logChange(e)} type="number" className="input-match" placeholder="0"/>
                                                    </td>
                                                    <td className="text-center">
                                                        <img src={require("../../../../assets/img/icons/billete10.png")} alt="CashBox image" className="icono-moneda" />
                                                        <input  name="billete10" id="10" onChange={(e) => this.logChange(e)} type="number" className="input-match" placeholder="0"/>
                                                    </td>
                                                    <td className="text-center">
                                                        <img src={require("../../../../assets/img/icons/billete5.png")} alt="CashBox image" className="icono-moneda" />
                                                        <input  name="billete5" id="5" onChange={(e) => this.logChange(e)} type="number" className="input-match" placeholder="0"/>
                                                    </td>
                                                    <td className="text-center">
                                                        <img src={require("../../../../assets/img/icons/billete1.png")} alt="CashBox image" className="icono-moneda" />
                                                        <input  name="billete1" id="1" onChange={(e) => this.logChange(e)} type="number" className="input-match" placeholder="0"/>
                                                    </td>
                                                </tr>
                                                <tr>                                                    
                                                    <td className="text-center">
                                                        <img src={require("../../../../assets/img/icons/moneda1.png")} alt="CashBox image" className="icono-moneda" />
                                                        <input  name="moneda1" id="1" onChange={(e) => this.logChange(e)} type="number" className="input-match" placeholder="0"/>
                                                    </td>
                                                    <td className="text-center">
                                                        <img src={require("../../../../assets/img/icons/moneda50.png")} alt="CashBox image" className="icono-moneda" />
                                                        <input name="moneda50" id="0.50" onChange={(e) => this.logChange(e)} type="number" className="input-match" placeholder="0"/>
                                                    </td>
                                                    <td className="text-center">
                                                        <img src={require("../../../../assets/img/icons/moneda25.png")} alt="CashBox image" className="icono-moneda" />
                                                        <input name="moneda25" id="0.25" onChange={(e) => this.logChange(e)} type="number" className="input-match" placeholder="0"/>
                                                    </td>
                                                    <td className="text-center">
                                                        <img src={require("../../../../assets/img/icons/moneda10.png")} alt="CashBox image" className="icono-moneda" />
                                                        <input  name="moneda10" id="0.10" onChange={(e) => this.logChange(e)} type="number" className="input-match" placeholder="0"/>
                                                    </td>
                                                    <td className="text-center">
                                                        <img src={require("../../../../assets/img/icons/moneda5.png")} alt="CashBox image" className="icono-moneda" />
                                                        <input  name="moneda5" id="0.05" onChange={(e) => this.logChange(e)} type="number" className="input-match" placeholder="0"/>
                                                    </td>
                                                    <td className="text-center">
                                                        <img src={require("../../../../assets/img/icons/moneda1c.png")} alt="CashBox image" className="icono-moneda" />
                                                        <input  name="moneda1c" id="0.01" type="number" min="0" max="99" onChange={(e) => this.logChange(e)}  className="input-match" placeholder="0"/>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>

                                <div className="w-100 seccion-button-closeBox">
                                    <div className="w-100 justify-content-center">
                                        <a href="/#/oppening-closing/box" className="btn btn-secondary btn-sm">Cancelar</a>
                                        &nbsp;&nbsp;&nbsp;
                                        <button className={"btn btn-primary btn-sm " + habilitar} onClick={(caja_cuadre) ? ()=>closeBox(openCashBox.data.id) : null}>Realizar Cierre</button>
                                    </div>
                                </div>
                            </div>
                </div>

                }
            </div>
        );
    }
}

export default CloseBox;
