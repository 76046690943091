import Login from './Login';
import { connect } from 'react-redux';
import {actions, resetStore} from '../../../redux/modules/login';


const ms2p = (state) => {
    return {
        ...state.login,
    };
};

const md2p = { ...actions, resetStore };

export default connect(ms2p, md2p)(Login);
