import React from 'react';
import { api } from "api";
import { Link } from 'react-router-dom';
import { Field, reduxForm, formValueSelector } from 'redux-form';
import validate from './validation';
import { renderField } from 'Utils/renderField/renderField';
import { renderDatePicker, renderSelectCustomSmall, renderSearchSelect } from 'Utils/renderField/renderField';
import { connect } from 'react-redux';

let persons = []


const getPersons = (search) => {
    return api.get("referents/listDoctors", {search}).catch((error) => {})
        .then((data) => {
            if (data){
                persons = [];
                data.results.forEach(x=>{
                    persons.push({value: x.id, label: x.name})
                })
                return persons
            }
            else{
                return []
            }
        })
        .catch(e => {
            return []
        })
}

const Form = props => {

    const {
        cancel,
        handleSubmit,
    } = props;

    return(
        <form onSubmit={handleSubmit} className="w-100 d-flex flex-column justify-content-center">
            <div className="mt-2">
                <div className="d-flex justify-content-start flex-column flex-sm-row align-items-stretch align-items-sm-center">
                    <label htmlFor="date" style={{fontSize: 'small'}}>Fecha y hora</label>
                </div>
                <div className=" d-flex justify-content-start flex-column flex-sm-row align-items-stretch align-items-sm-center">
                    <Field id="tab-datepicker" name="date" autofocus component={renderDatePicker} type="text" addClass="w-100 slim-input-height"/>
                </div>
                <div className="mt-3 d-flex justify-content-between align-items-center flex-column flex-sm-row align-items-stretch align-items-sm-center">
                    <Field name="start" addClass="slim-input-height hour-small-tab-input" component={renderSelectCustomSmall} options={[
                        // {value:0.0, label:'00:00'}, {value:0.5, label:'00:30'},
                        // {value:1.0, label:'01:00'}, {value:1.5, label:'01:30'},
                        // {value:2.0, label:'02:00'}, {value:2.5, label:'02:30'},
                        // {value:3.0, label:'03:00'}, {value:3.5, label:'03:30'},
                        // {value:4.0, label:'04:00'}, {value:4.5, label:'04:30'},
                        // {value:5.0, label:'05:00'}, {value:5.5, label:'05:30'},
                        // {value:6.0, label:'06:00'}, {value:6.5, label:'06:30'},
                        {value:7.0, label:'07:00'}, {value:7.5, label:'07:30'},
                        {value:8.0, label:'08:00'}, {value:8.5, label:'08:30'},
                        {value:9.0, label:'09:00'}, {value:9.5, label:'09:30'},

                        {value:10.0, label:'10:00'}, {value:10.5, label:'10:30'},
                        {value:11.0, label:'11:00'}, {value:11.5, label:'11:30'},
                        {value:12.0, label:'12:00'}, {value:12.5, label:'12:30'},
                        {value:13.0, label:'13:00'}, {value:13.5, label:'13:30'},
                        {value:14.0, label:'14:00'}, {value:14.5, label:'14:30'},
                        {value:15.0, label:'15:00'}, {value:15.5, label:'15:30'},
                        {value:16.0, label:'16:00'}, {value:16.5, label:'16:30'},
                        {value:17.0, label:'17:00'}, {value:17.5, label:'17:30'},
                        {value:18.0, label:'18:00'}, {value:18.5, label:'18:30'},
                        // {value:19.0, label:'19:00'}, {value:19.5, label:'19:30'},
                        // {value:20.0, label:'20:00'}, {value:20.5, label:'20:30'},
                        // {value:21.0, label:'21:00'}, {value:21.5, label:'21:30'},
                        // {value:22.0, label:'22:00'}, {value:22.5, label:'22:30'},
                        // {value:23.0, label:'23:00'}, {value:23.5, label:'23:30'},
                    ]}
                    cambio={(start) => {
                        const end_value = start.value + 0.5;
                        const end_label = end_value % 1 === 0 ? end_value.toString() + ':00' : start.value.toString() + ':30';
                        props.change('end', {value: end_value, label: end_label});
                    }}
                    />
                    <label htmlFor="start_from" style={{fontSize: 'x-small', margin: '0'}}>A</label>
                    <Field name="end" addClass="slim-input-height hour-small-tab-input" component={renderSelectCustomSmall} options={[
                        // {value:0.5, label:'00:30'},
                        // {value:1.0, label:'01:00'}, {value:1.5, label:'01:30'},
                        // {value:2.0, label:'02:00'}, {value:2.5, label:'02:30'},
                        // {value:3.0, label:'03:00'}, {value:3.5, label:'03:30'},
                        // {value:4.0, label:'04:00'}, {value:4.5, label:'04:30'},
                        // {value:5.0, label:'05:00'}, {value:5.5, label:'05:30'},
                        // {value:6.0, label:'06:00'}, {value:6.5, label:'06:30'},
                        // {value:7.0, label:'07:00'},

                        {value:7.5, label:'07:30'},
                        {value:8.0, label:'08:00'}, {value:8.5, label:'08:30'},
                        {value:9.0, label:'09:00'}, {value:9.5, label:'09:30'},

                        {value:10.0, label:'10:00'}, {value:10.5, label:'10:30'},
                        {value:11.0, label:'11:00'}, {value:11.5, label:'11:30'},
                        {value:12.0, label:'12:00'}, {value:12.5, label:'12:30'},
                        {value:13.0, label:'13:00'}, {value:13.5, label:'13:30'},
                        {value:14.0, label:'14:00'}, {value:14.5, label:'14:30'},
                        {value:15.0, label:'15:00'}, {value:15.5, label:'15:30'},
                        {value:16.0, label:'16:00'}, {value:16.5, label:'16:30'},
                        {value:17.0, label:'17:00'}, {value:17.5, label:'17:30'},
                        {value:18.0, label:'18:00'}, {value:18.5, label:'18:30'},
                        {value:19.0, label:'19:00'},

                        // {value:19.5, label:'19:30'},
                        // {value:20.0, label:'20:00'}, {value:20.5, label:'20:30'},
                        // {value:21.0, label:'21:00'}, {value:21.5, label:'21:30'},
                        // {value:22.0, label:'22:00'}, {value:22.5, label:'22:30'},
                        // {value:23.0, label:'23:00'}, {value:23.5, label:'23:30'},
                        // {value:24.0, label:'24:00'}
                    ]}/>
                </div>
            </div>
            <div className="mt-2">
                <div className="d-flex justify-content-start flex-column flex-sm-row align-items-stretch align-items-sm-center">
                    <label htmlFor="doctor" style={{fontSize: 'small'}}>Médico para la cita</label>
                </div>
                <div className="d-flex justify-content-start flex-column flex-sm-row align-items-stretch align-items-sm-center no-error-message">
                    <Field
                        id="doctor-tab-input"
                        name="doctor"
                        valueKey="value"
                        labelKey="label"
                        label="name"
                        component={renderSearchSelect}
                        loadOptions={getPersons}
                        special="w-100"
                    />
                </div>
            </div>

            <div className="mt-2">
                <div className="d-flex justify-content-start flex-column flex-sm-row align-items-stretch align-items-sm-center">
                    <label htmlFor="appointment_holder" style={{fontSize: 'small'}}>Nombre de quien agenda</label>
                </div>
                <div className=" d-flex justify-content-start flex-column flex-sm-row align-items-stretch align-items-sm-center">
                    <Field name="appointment_holder" component={renderField} type="text" addClass="w-100 slim-input-height"/>
                </div>
            </div>
            <div className="mt-2">
                <div className="d-flex justify-content-start flex-column flex-sm-row align-items-stretch align-items-sm-center">
                    <label htmlFor="patient_name" style={{fontSize: 'small'}}>Nombre del paciente</label>
                </div>
                <div className=" d-flex justify-content-start flex-column flex-sm-row align-items-stretch align-items-sm-center">
                    <Field name="patient_name" component={renderField} type="text" addClass="w-100 slim-input-height"/>
                </div>
            </div>
            <div className="mt-2">
                <div className="d-flex justify-content-start flex-column flex-sm-row align-items-stretch align-items-sm-center">
                    <label htmlFor="phone_holder" style={{fontSize: 'small'}}>Teléfono</label>
                </div>
                <div className=" d-flex justify-content-start flex-column flex-sm-row align-items-stretch align-items-sm-center">
                    <Field name="phone_holder" component={renderField} type="number" addClass="w-100 slim-input-height"/>
                </div>
            </div>
            <div className="mt-2">
                <div className="d-flex justify-content-start flex-column flex-sm-row align-items-stretch align-items-sm-center">
                    <label htmlFor="cellphone_holder" style={{fontSize: 'small'}}>Celular</label>
                </div>
                <div className=" d-flex justify-content-start flex-column flex-sm-row align-items-stretch align-items-sm-center">
                    <Field name="cellphone_holder" component={renderField} type="number" addClass="w-100 slim-input-height"/>
                </div>
            </div>
            <div className="mt-2">
                <div className="d-flex justify-content-start flex-column flex-sm-row align-items-stretch align-items-sm-center">
                    <label htmlFor="cellphone_holder" style={{fontSize: 'small'}}>Descripción</label>
                </div>
                <div className=" d-flex justify-content-start flex-column flex-sm-row align-items-stretch align-items-sm-center">
                    <Field name="note" component={renderField} type="text" placeholder='' addClass="w-100 slim-input-height"/>
                </div>
            </div>            
            <div className="mt-2">
                <div className="d-flex justify-content-start flex-column flex-sm-row align-items-stretch align-items-sm-center">
                    <label htmlFor="cellphone_holder" style={{fontSize: 'small'}}>Requerimientos</label>
                </div>
                <div className=" d-flex justify-content-start flex-column flex-sm-row align-items-stretch align-items-sm-center">
                    <Field name="requirements" component={renderField} type="text" placeholder='Ej. debe venir en ayunas.' addClass="w-100 slim-input-height"/>
                </div>
            </div>                                     
            <div className="row-fluid my-3">
                <div className="d-flex justify-content-center flex-column flex-sm-row align-items-stretch align-items-sm-center justify-content-around">
                    <a className="btn btn-small btn-secondary" to="/#/schedule/" onClick={cancel}>Cancelar</a>
                    <button type="submit" className="btn btn-small btn-primary">Guardar</button>
                </div>
            </div>
        </form>
    )
};

let ScheduleForm = reduxForm({
   form: 'schedule',
   validate
})(Form);

export default ScheduleForm;
