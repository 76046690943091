const url = 'reporte/consolidado';
import { api } from 'api';
import Swal from 'sweetalert2';
import { push } from 'react-router-redux';
import { goBack } from 'react-router-redux';
import { initialize as initializeForm } from 'redux-form';
import { createActions, handleActions } from 'redux-actions';
import moment from "moment";

const LOADER_REPORTE_CONSOLIDADO = 'LOADER_REPORTE_CONSOLIDADO';
const SET_DATA_REPORTE_CONSOLIDADO = 'SET_DATA_REPORTE_CONSOLIDADO';
const SET_PAGE_REPORTE_CONSOLIDADO = 'SET_PAGE_REPORTE_CONSOLIDADO';
const SET_BUSCADOR_REPORTE_CONSOLIDADO = 'SET_BUSCADOR_REPORTE_CONSOLIDADO';
const SET_FILTRO_REPORTE_CONSOLIDADO = 'SET_FILTRO_REPORTE_CONSOLIDADO';
const SET_INICIAL_DATE_REPORTE_CONSOLIDADO = 'SET_INICIAL_DATE_REPORTE_CONSOLIDADO';
const SET_FINAL_DATE_REPORTE_CONSOLIDADO = 'SET_FINAL_DATE_REPORTE_CONSOLIDADO';
const SET_FILTRO_SERVICIO = 'SET_FILTRO_SERVICIO';
const SET_CATEGORIES = 'SET_CATEGORIES';

const generarExcel = () =>  (dispatch, getStore) => {
    const store = getStore();
    const search = store.reporte_consolidado.buscador;

    let filtro_valores = []
    store.reporte_consolidado.filtro_servicios.forEach(item => {
        filtro_valores.push(item.value);
    });

    const initial_date = store.reporte_consolidado.initial_date;
    const final_date = store.reporte_consolidado.final_date;

    const url_api = `/api/${url}/generarExcelConsolidado?initial_date=${initial_date}&final_date=${final_date}&filtro_valores=${filtro_valores}`

    fetch(url_api, {
        method: "GET",
        headers: {
            Authorization: `Token ${localStorage.getItem("token")}`,
        },
    })
    .then((response) => {
        console.log("responsito :: ", response);
        Swal(
            'success',
            "Se está generando el archivo, puede ir a la sección de tareas para ver el estado del reporte",
            'success'
        );        
    })
    .catch(() => {
        Swal(
            'Error',
            error.detail || 'Ha ocurrido un error, por favor vuelva a intentar.',
            'error'
        );        
    })
    .finally(() => {
        //dispatch(setLoaderExcel(false));
    });

    /*
    window.location.replace(`/api/${url}/generarExcelConsolidado?initial_date=${initial_date}&final_date=${final_date}&filtro_valores=${filtro_valores}&auth_token=${localStorage.getItem("token")}`);
    */
};

const listar = () =>  (dispatch, getStore) => {
    const store = getStore();
    const search = store.reporte_consolidado.buscador;
    const filtro = store.reporte_consolidado.filtro;

    let filtro_valores = []
    store.reporte_consolidado.filtro_servicios.forEach(item => {
        console.log("value: ", item.value)
        filtro_valores.push(item.value);
    });

    const initial_date = store.reporte_consolidado.initial_date;
    const final_date = store.reporte_consolidado.final_date;

    let params = {search, initial_date, final_date, filtro_valores};
    if(filtro){
        params[''] = filtro
    }

    api.get(`${url}/getCategories`, params).catch((error) => {
        console.log("Error ", error);
    }).then((response) => {
        dispatch({type: SET_CATEGORIES, categorias: response});
        dispatch({type: LOADER_REPORTE_CONSOLIDADO, cargando: true});
        if(response){
            api.get(`${url}/getConsolidado`, params).catch((error) => {
                dispatch({type: LOADER_REPORTE_CONSOLIDADO, cargando: false});
                Swal(
                    'Error',
                    error.detail || 'Ha ocurrido un error, por favor vuelva a intentar.',
                    'error'
                );
            }).then((data) => {
                if(data){
                    dispatch({type: SET_DATA_REPORTE_CONSOLIDADO, data});
                    //dispatch({type: SET_PAGE_REPORTE_CONSOLIDADO, page});
                }
                dispatch({type: LOADER_REPORTE_CONSOLIDADO, cargando: false});
            })
        }
    })
};

const search = (search) => (dispatch) => {
    dispatch({type: SET_BUSCADOR_REPORTE_CONSOLIDADO, buscador: search});
    dispatch(listar());
};

const filtro = (filtro) => (dispatch, getStore) => {
    if (filtro == 0){
        filtro = null
    }
    dispatch({type: SET_FILTRO_REPORTE_CONSOLIDADO, filtro});
    dispatch(listar());
};
 
const setFinalDate = (value) => (dispatch) => {
    dispatch({type: SET_FINAL_DATE_REPORTE_CONSOLIDADO, final_date: value});
}

const setInitialDate = (value) => (dispatch) => {
    dispatch({type: SET_INICIAL_DATE_REPORTE_CONSOLIDADO, initial_date: value});
}

const setFiltroServicio = (item) => (dispatch, getStore) => {
    console.log("Item recibido: ", item);
    let repetido = false;
    let servicios = _.cloneDeep(getStore().reporte_consolidado.filtro_servicios)
    servicios.forEach(servicio => {
        if(item.value === servicio.value){
            repetido = true;
        }
    });

    if(!repetido){
        servicios.push(item);
    }

    dispatch({type: SET_FILTRO_SERVICIO, filtro_servicios: servicios});
    dispatch(listar());
}

const quitarFiltro = (value) => (dispatch, getStore) => {
    console.log("Quitar filtro: ", value);
    let servicios = _.cloneDeep(getStore().reporte_consolidado.filtro_servicios)
    const arreglo = servicios.filter(servicio => {
        return value !== servicio.value;
    });

    dispatch({type: SET_FILTRO_SERVICIO, filtro_servicios: arreglo});
    dispatch(listar());
}

export const actions = {
    listar,
    search,
    filtro,
    setFinalDate,
    setInitialDate,
    setFiltroServicio,
    quitarFiltro,
    generarExcel,
};

export const reducer = {
    [LOADER_REPORTE_CONSOLIDADO]: (state, { cargando }) => {
        return {...state, cargando }
    },
    [SET_DATA_REPORTE_CONSOLIDADO]: (state, { data }) => {
        return {...state, data }
    },
    [SET_PAGE_REPORTE_CONSOLIDADO]: (state, { page }) => {
        return {...state, page }
    },
    [SET_BUSCADOR_REPORTE_CONSOLIDADO]: (state, { buscador }) => {
        return {...state, buscador }
    },
    [SET_FILTRO_REPORTE_CONSOLIDADO]: (state, { filtro }) => {
        return {...state, filtro }
    },
    [SET_INICIAL_DATE_REPORTE_CONSOLIDADO]: (state, { initial_date }) => {
        return{
            ...state,
            initial_date
        }
    },
    [SET_FINAL_DATE_REPORTE_CONSOLIDADO]: (state, { final_date }) => {
        return{
            ...state,
            final_date
        }
    },
    [SET_FILTRO_SERVICIO]: (state, { filtro_servicios }) => {
        return{
            ...state,
            filtro_servicios
        }
    },
    [SET_CATEGORIES]: (state, { categorias }) => {
        return{
            ...state,
            categorias
        }
    },
};

export const initialState = {
    cargando: false,
    page: 1,
    /*
    data: {
        count: 0,
        next: null,
        previous: null,
        results: [],
    },
    */
    data: [],
    buscador: '',
    filtro: null,

    initial_date: moment().date(1).format("DD/MM/YYYY"),
    final_date: moment().format("DD/MM/YYYY"),
    filtro_servicios: [],
    categorias: [],
};

export default handleActions(reducer, initialState)
