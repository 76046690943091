import Swal from 'sweetalert2';
import { api } from "api";
import { createActions, handleActions } from 'redux-actions'
import { goBack } from 'react-router-redux'
import { initialize as initializeForm } from 'redux-form'
import { push } from 'react-router-redux';
const url = '';

const LOADER_LOCKS = 'LOADER_LOCKS';
const SET_DATA_LOCKS = 'SET_DATA_LOCKS';
const SET_PAGE_LOCKS = 'SET_PAGE_LOCKS';
const SET_UPDATE_DATA_LOCKS ='SET_UPDATE_DATA_LOCKS';
const SET_BUSCADOR_LOCKS = 'SET_BUSCADOR_LOCKS';
const SET_FILTRO_LOCKS = 'SET_FILTRO_LOCKS';

const hours = [
    {value:0.0, label:'0:00 AM', hrs:'00:00'}, {value:0.5, label:'0:30 AM', hrs:'00:30'},
    {value:1.0, label:'1:00 AM', hrs:'01:00'}, {value:1.5, label:'1:30 AM', hrs:'01:30'},
    {value:2.0, label:'2:00 AM', hrs:'02:00'}, {value:2.5, label:'2:30 AM', hrs:'02:30'},
    {value:3.0, label:'3:00 AM', hrs:'03:00'}, {value:3.5, label:'3:30 AM', hrs:'03:30'},
    {value:4.0, label:'4:00 AM', hrs:'04:00'}, {value:4.5, label:'4:30 AM', hrs:'04:30'},
    {value:5.0, label:'5:00 AM', hrs:'05:00'}, {value:5.5, label:'5:30 AM', hrs:'05:30'},
    {value:6.0, label:'6:00 AM', hrs:'06:00'}, {value:6.5, label:'6:30 AM', hrs:'06:30'},
    {value:7.0, label:'7:00 AM', hrs:'07:00'}, {value:7.5, label:'7:30 AM', hrs:'07:30'},
    {value:8.0, label:'8:00 AM', hrs:'08:00'}, {value:8.5, label:'8:30 AM', hrs:'08:30'},
    {value:9.0, label:'9:00 AM', hrs:'09:00'}, {value:9.5, label:'9:30 AM', hrs:'09:30'},

    {value:10.0, label:'10:00 AM', hrs:'10:00'}, {value:10.5, label:'10:30 AM', hrs:'10:30'},
    {value:11.0, label:'11:00 AM', hrs:'11:00'}, {value:11.5, label:'11:30 AM', hrs:'11:30'},
    {value:12.0, label:'12:00 PM', hrs:'12:00'}, {value:12.5, label:'12:30 PM', hrs:'12:30'},
    {value:13.0, label:'1:00 PM', hrs:'13:00'}, {value:13.5, label:'1:30 PM', hrs:'13:30'},
    {value:14.0, label:'2:00 PM', hrs:'14:00'}, {value:14.5, label:'2:30 PM', hrs:'14:30'},
    {value:15.0, label:'3:00 PM', hrs:'15:00'}, {value:15.5, label:'3:30 PM', hrs:'15:30'},
    {value:16.0, label:'4:00 PM', hrs:'16:00'}, {value:16.5, label:'4:30 PM', hrs:'16:30'},
    {value:17.0, label:'5:00 PM', hrs:'17:00'}, {value:17.5, label:'5:30 PM', hrs:'17:30'},
    {value:18.0, label:'6:00 PM', hrs:'18:00'}, {value:18.5, label:'6:30 PM', hrs:'18:30'},
    {value:19.0, label:'7:00 PM', hrs:'19:00'}, {value:19.5, label:'7:30 PM', hrs:'19:30'},
    {value:20.0, label:'8:00 PM', hrs:'20:00'}, {value:20.5, label:'8:30 PM', hrs:'20:30'},
    {value:21.0, label:'9:00 PM', hrs:'21:00'}, {value:21.5, label:'9:30 PM', hrs:':2130'},
    {value:22.0, label:'10:00 PM', hrs:'22:00'}, {value:22.5, label:'10:30 PM', hrs:'22:30'},
    {value:23.0, label:'11:00 PM', hrs:'23:00'}, {value:23.5, label:'11:30 PM', hrs:'23:30'},
]

const listar = (doctor_id) => (dispatch, getStore) => {
    dispatch({type: LOADER_LOCKS, cargando: true});
    // const store = getStore();
    // const search = store.scheduleLock.buscador;
    // const filtro = store.scheduleLock.filtro_lock;
    const current_user = getStore().login.me;

    const subsidiary = current_user.person_user.subsidiary;

    let params = {doctor_id, subsidiary};
    // if(filtro){
    //     params[''] = filtro
    // }
    api.get(`locked_appointments/all`, params).catch((error) => {
        dispatch({type: LOADER_LOCKS, cargando: false});
        Swal(
            'Error',
            error.detail || 'Ha ocurrido un error, por favor vuelva a intentar.',
            'error'
        );
    }).then((data) => {
        if(data){
            let locks = []
            for (let index = 0; index < data.length; index++) {
                locks.push({
                    id: data[index].id,
                    date: data[index].date,
                    start: {value: hours[parseInt(data[index].start_value * 2)].value, label: hours[parseInt(data[index].start_value * 2)].hrs},
                    end: {value: hours[parseInt(data[index].end_value * 2)].value, label: hours[parseInt(data[index].end_value * 2)].hrs},
                    doctor: data[index].doctor.id
                })
            }
            dispatch({type: SET_DATA_LOCKS, data: data});
            dispatch(initializeForm('scheduleLock', {'locks': locks}))
        }
        dispatch({type: LOADER_LOCKS, cargando: false});
    })
};

// const detail = id => (dispatch, getState) =>{
//     dispatch({type: LOADER_LOCKS, cargando: true});
//     api.get(`url/id`).catch((error) => {
//         dispatch({type: LOADER_LOCKS, cargando: false});
//         if(error.statusCode  === 404){
//             dispatch(push('/'))
//         }
//     }).then((data) => {
//         dispatch({type: LOADER_LOCKS, cargando: false});
//         if(data){
//             dispatch({type: SET_UPDATE_DATA_LOCKS, updateData: data});
//             dispatch(initializeForm('bodega', data))
//         }
//     })
// }

const daily_create = (data, doctor_id) => (dispatch, getStore) => {
    const formData = _.cloneDeep(getStore().form.scheduleLock.values);
    const current_user = getStore().login.me;

    formData.subsidiary = current_user.person_user.subsidiary;
    formData.user = current_user.id;

    formData.doctor_id = doctor_id
    formData.appointment_type = 0
    for (let index = 0; index < formData['locks'].length; index++) {
        formData['locks'][index]['start']['label'] = formData['locks'][index]['start']['label'] + ':00'
        formData['locks'][index]['end']['label'] = formData['locks'][index]['end']['label'] + ':00'
    }

    console.log("FORMDATA: ",formData)

    dispatch({type: LOADER_LOCKS, cargando: true})
    api.post('/locked_appointments', formData).then((data) => {
        dispatch({type: LOADER_LOCKS, cargando: false})
        Swal('Éxito', 'Se han puesto los bloqueos.', 'success')
        .then(() => {
            dispatch(push('/appointments'))
        })
    }).catch((error) => {
        dispatch({type: LOADER_LOCKS, cargando: false})
        Swal(
            'Error',
             error.detail || 'Ha ocurrido un error, por favor vuelva a intentar.',
            'error'
        );
    }).finally(() => {
        dispatch({type: LOADER_LOCKS, cargando: false})
    });
};

const week_create = (data, doctor_id) => (dispatch, getStore) => {
    const formData = _.cloneDeep(getStore().form.scheduleLock.values);
    formData.doctor_id = doctor_id
    formData.appointment_type = 0

    const current_user = getStore().login.me;

    formData.subsidiary = current_user.person_user.subsidiary;
    formData.user = current_user.id;
    
    for (let index = 0; index < formData['locks'].length; index++) {
        formData['locks'][index]['start']['label'] = formData['locks'][index]['start']['label'] + ':00'
        formData['locks'][index]['end']['label'] = formData['locks'][index]['end']['label'] + ':00'
    }

    console.log("FORMDATA: ",formData)

    dispatch({type: LOADER_LOCKS, cargando: true})
    api.post('/locked_appointments', formData).then((data) => {
        dispatch({type: LOADER_LOCKS, cargando: false})
        Swal('Éxito', 'Se han puesto los bloqueos.', 'success')
        .then(() => {
            dispatch(push('/schedule'))
        })
    }).catch((error) => {
        dispatch({type: LOADER_LOCKS, cargando: false})
        Swal(
            'Error',
             error.detail || 'Ha ocurrido un error, por favor vuelva a intentar.',
            'error'
        );
    }).finally(() => {
        dispatch({type: LOADER_LOCKS, cargando: false})
    });
};

// const update = () => (dispatch, getStore) => {
//     const formData = _.cloneDeep(getStore().form.bodega.values);
//     dispatch({type: LOADER_LOCKS, cargando: true});
//     api.put(`url/${formData.id}`, formData)
//     .then((data) => {
//         dispatch({type: LOADER_LOCKS, cargando: false});
//         if(data){
//             Swal(
//                 'Éxito',
//                 'Datos editados correctamente.',
//                 'success'
//             ).then(() => {
//                 dispatch(push('/'));
//             })
//         }
//     })
//     .catch((error) =>{
//         dispatch({type: LOADER_LOCKS, cargando: false});
//         Swal(
//             'Error',
//              error.detail || 'Ha ocurrido un error, verifique los datos.',
//             'error'
//         )
//         if(error.statusCode === 404){
//             dispatch(push('/'));
//         }
//     })
// }

// const destroy = (id) => (dispatch, getStore) => {
//     dispatch({type: LOADER_LOCKS, cargando: true});
//     api.eliminar(`url/id`).catch((error) => {
//         dispatch({type: LOADER_LOCKS, cargando: false});
//         Swal('Error', 'No se ha logrado borrar el registro.', 'error')
//             .then(() => {
//                 dispatch(listar(1));
//         })
//     }).then((data) => {
//         dispatch({type: LOADER_LOCKS, cargando: false});
//         Swal('Éxito', 'Se ha eliminado correctamente.', 'success')
//             .then(() => {
//                 dispatch(listar(1));
//             })
//     })
// }

// const search = (search) => (dispatch, getStore) => {
//     dispatch({type: SET_BUSCADOR_LOCKS, buscador: search});
//     dispatch(listar(1));
// };

// const filtro = (filtro) => (dispatch, getStore) => {
//     if (filtro == 0){
//         filtro = null
//     }
//     dispatch({type: SET_FILTRO_LOCKS, filtro_lock: filtro});
//     dispatch(listar(1));
// };

export const actions = {
    listar,
    daily_create,
    week_create,
    // detail,
    // update,
    // destroy,
    // search,
    // filtro
};
export const reducer = {
    [LOADER_LOCKS]: (state, { cargando }) => {
        return {...state, cargando }
    },
    [SET_DATA_LOCKS]: (state, { data }) => {
        return {...state, data }
    },
    [SET_PAGE_LOCKS]: (state, { page }) => {
        return {...state, page }
    },
    [SET_UPDATE_DATA_LOCKS]: (state, { updateData }) => {
        return {...state, updateData }
    },
    [SET_BUSCADOR_LOCKS]: (state, { buscador }) => {
        return {...state, buscador }
    },
    [SET_FILTRO_LOCKS]: (state, { filtro_lock }) => {
        return {...state, filtro_lock }
    },
}
export const initialState = {
    cargando: false,
    page: 1,
    data: [],
    buscador: '',
    filtro_lock: null,
    updateData: {},
};
export default handleActions(reducer, initialState)