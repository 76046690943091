import React from 'react';
import { renderField, renderSelectCustom } from '../Utils/renderField/renderField';
import { Field, reduxForm, formValueSelector } from 'redux-form';
import { validate, validators } from 'validate-redux-form';

let initialValues = {};


let FrmCoupon = (props) => {
    const { handleSubmit, index } = props;
    initialValues.index = index;

    // if(index){console.log("Sí tiene index");} else {console.log("No tiene index");}

    return (
        <form onSubmit={handleSubmit} id="setCouponCode" className="d-flex justify-content-center flex-column flex-sm-row align-items-stretch align-items-sm-center">
            <Field name="index" component={"input"} type="hidden"/>
            <div className="form-group">
                <label>Ingrese el código del cupón</label>
                <Field name="code" component={renderField} type="text" className="form-control"/>
            </div>
        </form>
    );
}

FrmCoupon = reduxForm({
    form: 'frmModalCoupon',
    initialValues,
    validate: data => {
         return validate(data, {
             'code': validators.exists()('Campo requerido.'),
        })
    }
 })(FrmCoupon);

export default FrmCoupon;
