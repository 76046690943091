import React from 'react';


function CardFormulario(props) {
    return (
            <div className="row d-flex justify-content-center">

                    <div className="card col-11 ">
                        
                        <div className="card-body d-flex justify-content-center">
                                <div className="col-md-9">
                                    {props.children}
                                </div>
                        </div>
                    </div>
            </div>

    )
}

export default CardFormulario;
