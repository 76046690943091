import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import Venta from './TabVenta';
import Caja from './TabCaja';
import OpenBox from './OpenBox';
import BoxDetail from './BoxDetail';
import LoadMask from "../Utils/LoadMask/LoadMask";

import './tab.css';

class OppeningClosingBox extends Component {
    componentWillUnmount() {
        this.props.resetStore();
    }

    state = {
        tab : "venta",
        mostrar: false,
    }

    componentDidMount = () => {
        this.props.checkCashBox();
        setTimeout(() => {
            this.props.listarMovimientos();
            this.props.getSumaryPerCategory();
            this.setState({mostrar: true});
        }, 200)
    }

    changeTab = (e) => {
        const tab = e.target.id.toLowerCase();
        this.setState({ tab: tab });

        /* When user select box tab, the index sub-tab is selected */
        if(tab == "caja"){
            this.props.changeSubTab('Caja');
        }

    }

    render() {
        const {
            movements,
            page,
            modal,
            addCash,
            closeBox,
            listarMovimientos,
            openAccount,
            openCashBox,
            summary_boxes,
            summary_category,
            isNavBarOpen,
        } = this.props;

        let component = null;
        component = (openCashBox != null && openCashBox.isOpen) ?
                            <BoxDetail { ...openCashBox.data }
                                        addCash={addCash}
                                        closeBox={closeBox}
                                        dataMovimientos={movements}
                                        listarMovimientos={listarMovimientos}
                                        page={page}
                                        modal={modal}
                                        summary_boxes={summary_boxes}
                                        summary_category={summary_category}
                                        openAccount={openAccount}
                                        paso_final={false}
                                    />
                        :
                            <React.Fragment>
                                {openCashBox !== null &&
                                    <Caja {... this.props}/>
                                }
                            </React.Fragment>

        return (
            <div className={classNames('content-with-tabs', { 'content-with-tabs-min': isNavBarOpen })}>
                <div className="d-inline-flex w-100">
                    <div className="row-fluid w-50 tab-background">
                        <a href="/#/oppening-closing" className="d-flex justify-content-center align-items-center tab tab-br-br">
                            VENTA
                        </a>
                    </div>
                    <div className="row-fluid w-50 tab-background-blue">
                        <a href="/#/oppening-closing/box" className="d-flex justify-content-center align-items-center tab-active tab-br-tl">
                            CAJA
                        </a>
                    </div>
                </div>
                <LoadMask loading={this.props.cargando}>
                {(this.props.cargando===false && this.state.mostrar===true && component != null) && component}
                </LoadMask>
            </div>
        )
    }
}

export default OppeningClosingBox;
