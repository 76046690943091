import React, { Component } from 'react';
import CanvasDraw from "react-canvas-draw";
import { render } from './renderField';


export class Canvas extends Component {
    state = {
        loaded: false,
        imgSrc: '', //add
    };


    constructor(props) {
        super(props);
    }

    componentDidUpdate() {
        const {imgSrc} = this.props; //add...

        if (!this.state.loaded && !!this.props.input.value) {
            this.setState({loaded: true});
            this.odCanvas.loadSaveData(this.props.input.value, false);
            this.odCanvas.imgSrc=imgSrc;
        }
    }

    render () {
        const { input, meta: { touched, error }, imgSrc, view, width=125, height=125 } = this.props;

        console.log("IMGSRC: ", imgSrc);

        return (
            <React.Fragment>
                <div>
                    <img src={imgSrc} style={{position: 'absolute'}} />
                    <CanvasDraw
                        style={{border: 'dotted 1px gray'}}
                        gridColor="rgba(150,150,150,0.17)"
                        canvasWidth={width}
                        canvasHeight={height}
                        hideGrid= {true}
                        //imgSrc={imgSrc}
                        brushRadius={1}
                        lazyRadius={2}
                        disabled={view}
                        ref={canvasDraw => (this.odCanvas = canvasDraw)}
                        onChange={()=>{
                            input.onChange(this.odCanvas.getSaveData());
                        }}
                    />
                </div>
                {!view &&
                    <div className='d-flex flex-row justify-content-around flex-wrap mt-5'>
                        <a
                            className='content-canvas-button'
                            onClick={()=>this.odCanvas.undo()}
                        >
                            <img
                                src={require('../../../../../assets/img/icons/undo-icon-white.png')}
                                className='icon-canvas-button'
                            />
                        </a>
                        <a
                            className='content-canvas-button ml-2'
                            onClick={()=>this.odCanvas.clear()}
                        >
                            <img
                                src={require('../../../../../assets/img/icons/clear-icon-white.png')}
                                className='icon-canvas-button'
                            />
                        </a>
                    </div>
                }
            </React.Fragment>
        );
    }
}
