import { connect } from 'react-redux';
import CashBoxes from './CashBoxesCrear';
import { actions } from '../../../redux/modules/cashBoxes';
import {resetStore} from "../../../redux/modules/login";


const ms2p = (state) => {
    return {
        ...state.cashBoxes,
    };
};

const md2p = { ...actions, resetStore };

export default connect(ms2p, md2p)(CashBoxes);
