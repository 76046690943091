import Table from '../../Utils/Grid';
import React, {Component} from 'react';
import {BreakLine} from '../../Utils/tableOptions';
import HeaderSimple from '../../Utils/Header/HeaderSimple';
import { PENDING } from "../../../../utility/variables";
import {Link} from "react-router-dom";
import Modal from 'react-responsive-modal';
import Step from "../Exam/Step";
import Question from "../Exam/Pregunta";
import Swal from 'sweetalert2';

import {
    VISUAL_ACUITY,
    TONOMETRY,
    REFRACTOR,
    MUSCLES,
    LENSOMETRY,
    KERATOMERY,
    INDIRECT_EYE,
    AUTOREFRACTOR,
    LAMP,
    RECORD,
    RECONSULT,
    DIAGNOSTIC_PLAN,
    EDUCATIONAL_PLAN,
    OPTICS_ORDER,
} from "../../../../utility/variables";

class ConsultList extends Component {
    state = {
        open: false,
        open_question: false,
        appointment_id : 0,
        link: '',
    }

    setStep = (step) => {
        this.setState({open_question: false});

        const { setStep } = this.props;
        if (step) {
            Swal({
                title: '¿Está seguro de continuar?',
                text: 'No puedes deshacer esta acción',
                type: 'warning',
                showCancelButton: true,
                confirmButtonText: 'Sí, continuar',
                cancelButtonText: 'No, cancelar',
                reverseButtons: true
            }).then((result) => {
                if (result.value) {
                    setStep(step, this.state.appointment_id, 0);
                }
            })
        }
    };

    componentWillMount = () => {
        const {listar, show_list} = this.props;
        listar(show_list);

        let link = '';
        if(show_list === VISUAL_ACUITY){
            link = 'visual_acuity';
        }else if(show_list === TONOMETRY){
            link = 'tonometry';
        }else if(show_list === REFRACTOR){
            link = 'refraction';
        }else if(show_list === MUSCLES){
            link = 'muscle';
        }else if(show_list === LENSOMETRY){
            link = 'lensometry';
        }else if(show_list === LAMP){
            link = 'lamp';
        }else if(show_list === KERATOMERY){
            link = 'keratometry';
        }else if(show_list === INDIRECT_EYE){
            link = 'indirect_eye';
        }else if(show_list === AUTOREFRACTOR){
            link = 'autorefractor';
        }else if(show_list === RECORD){
            link = 'record';
        }else if(show_list === RECONSULT){
            link = 'reconsult';
        }else if(show_list === DIAGNOSTIC_PLAN){
            link = 'diagnostic-plan';
        }else if(show_list === EDUCATIONAL_PLAN){
            link = 'educational-plan';
        }else if(show_list === OPTICS_ORDER){
            link = 'optics-order';
        }else{
            link = 'query-queue'
        }

        this.setState({link});
    }

    submitForm = (data) => {
        if(this.state.appointment_id > 0){
            this.props.setStep(data, this.state.appointment_id, this.props.show_list); //add...
            this.setState({open:false, appointment_id: 0});
        }
    };

    render() {
        const {
            listar,
            titulo,
            show_list,
        } = this.props;

        const {
            cargando,
            page,
            data,
        } = this.props;

        const {open, open_question, link} = this.state;

        return (
            <div className="content">

                <Modal open={open} onClose={() => this.setState({ open: false, appointment_id: 0 })} >
                    <Step
                        nextStep={this.submitForm}
                        cancelar={() => this.setState({open:false, appointment_id: 0})}
                        //setStep={this.props.setStep} //add..
                    />
                </Modal>

                <Modal open={open_question} onClose={() => this.setState({ open_question: false, appointment_id: 0 })} >
                    <Question
                        consulta={()=>this.setStep(RECORD)}
                        reconsulta={()=>this.setStep(RECONSULT)}
                        cancelar={()=>this.setState({open_question:false, appointment_id: 0})}
                    />
                </Modal>

                <div className={`m-auto row-fluid w-100 ${show_list === PENDING ? ' pt-0' : 'pt-5'}`}>
                    {show_list === PENDING ?
                    (<HeaderSimple
                        texto={"Nueva consulta"}
                        ruta={"/today-appointment"}
                    />) : <div className="" />}
                    <div className="table-decoration">
                        <div className="top-title">
                            {titulo}
                        </div>
                        <Table
                            pagination={false}
                            onPageChange={listar}
                            data={data}
                            loading={cargando}
                            page={page}
                        >
                            <TableHeaderColumn
                                tdStyle={BreakLine}
                                thStyle={BreakLine}
                                dataField="patient"
                                dataFormat={(cell, row) => cell ? cell.first_name : row.patient_name}
                                dataSort>Nombres</TableHeaderColumn>
                            <TableHeaderColumn
                                tdStyle={BreakLine}
                                thStyle={BreakLine}
                                dataField="patient"
                                dataFormat={(cell) => cell ? cell.last_name : "---"}
                                dataSort>Apellidos</TableHeaderColumn>
                            <TableHeaderColumn
                                tdStyle={BreakLine}
                                thStyle={BreakLine}
                                dataField="patient"
                                dataFormat={(cell) => cell ? cell.birthdate : "---"}
                                dataSort>Fecha de nacimiento</TableHeaderColumn>
                            <TableHeaderColumn
                                dataField="id"
                                isKey={true}
                                dataAlign="center"
                                dataFormat={(cell, row) => {
                                    console.log("ROW ----> ", row);
                                    return (<div className="row">
                                        <div className="col-12">
                                        {row.show_list === 0 ?
                                            <React.Fragment>
                                                {row.patient === null ?
                                                    <button className="btn bth-primary" disabled>Completar datos</button>
                                                :
                                                    <React.Fragment>
                                                        {/*<button className="btn bth-primary" onClick={() => this.setState({open_question:true, appointment_id: cell})}>Iniciar Procedimiento</button>*/}
                                                        <button
                                                            type='button'
                                                            onClick={()=>this.props.setStep(RECORD, cell)}
                                                            className='btn btn-primary btn-sm'
                                                        >Consulta</button>
                                                        <button
                                                            type='button'
                                                            onClick={()=>this.props.setStep(RECONSULT, cell)}
                                                            className='ml-1 btn btn-success btn-sm'
                                                        >Reconsulta</button>
                                                    </React.Fragment>
                                                }
                                            </React.Fragment>
                                        :
                                            <React.Fragment>
                                                {row.patient === null ?
                                                    <Link to={`${link}/${cell}`} className="btn btn-sm btn-success" disabled>Completar datos</Link>
                                                :
                                                    <React.Fragment>
                                                        <Link
                                                            to={`${link}/${cell}`}
                                                            className="btn btn-sm btn-success"
                                                        >Iniciar Procedimiento</Link>
                                                    </React.Fragment>
                                                }
                                            </React.Fragment>
                                        }
                                            {/*<button className="btn bth-primary" onClick={() => this.setState({open:true, appointment_id: cell})}>Iniciar Consulta</button>*/}
                                        </div>
                                    </div>)
                                }}>Acciones</TableHeaderColumn>
                        </Table>
                    </div>
                </div>
            </div>
        )
    }
}

export default ConsultList;
