import {api} from 'api';
import Swal from 'sweetalert2';
import {handleActions} from 'redux-actions';
import { actions as login_actions } from './login';
import {initialize as initializeForm} from 'redux-form';
import { push } from 'react-router-redux';

// Constantes
const LOADER_PROFILE = 'LOADER_PROFILE';

const carga_inicial = () => (dispatch, getState) => {
    const data_me = getState().login.me;
    dispatch(initializeForm('profile', {
        id: data_me.id,
        first_name: data_me.first_name,
        username: data_me.username,
    }));
};

const update_profile = (data = {}) => (dispatch, getState) => {
    data.change_password = (data.change_password ? data.change_password : false);
    dispatch({type: LOADER_PROFILE, cargando: true});
    api.post('users/update_profile', data).then((data_response) => {
        Swal('Éxito', (data_response && data_response.detail) ? data_response.detail : '', 'success')
            .then(() => {
                dispatch(push('/home'));
            });
        dispatch(login_actions.getInfo());
    }).catch((error) => {
        Swal('Error', (error && error.detail) ? error.detail : '', 'error');
    }).finally(() => {
        dispatch({type: LOADER_PROFILE, cargando: false});
    });
}
export const actions = {
    carga_inicial,
    update_profile,
}

export const reducer = {
    [LOADER_PROFILE]: (state, {cargando}) => {
        return {...state, cargando};
    },
};

export const initialState = {
    cargando: false
};

export default handleActions(reducer, initialState);
