import PropTypes from 'prop-types';
import React, { Component } from 'react';
import Table from '../Utils/Grid';
import ToolbarTab from '../Utils/Toolbar/ToolbarTab';
import { BreakLine } from '../Utils/tableOptions';
import { RenderMoneda }  from 'Utils/renderField/renderTableField';
import { RenderCurrency } from 'Utils/renderField/renderReadField';
import { Link } from 'react-router-dom';
import MyModal from '../Utils/myModal';
import './sales.css';

import Form from './formInter';
import FormCoupon from './formCoupon';
import BillingForm from './payment_methods/FormBillingInformation';

import ProductInCart from './productCar';
import CuponInCart from './cuponCar';
class Service extends Component {
    componentWillUnmount() {
        this.props.resetStore();
    }
    state = {
        defaultFinancialEntity: null,
    }

    thClassName = () => {
        return 'my-custom-class';
    }

    componentDidMount = () => {
        if(this.props.patientInfo.referent != null){
            this.setState({ defaultFinancialEntity: this.props.patientInfo.referent});
            this.props.getOpenAccounts();
        }else{
            this.props.noDataPatient();
        }

    }

    componentWillMount(){
        this.props.listar()
        this.props.retrievePatientEmail();
    }

    addToCar = (data, row, cell) => {
        let options=[];

        if(this.state.defaultFinancialEntity != null && this.state.defaultFinancialEntity != undefined){
            //console.log(" DEFAULT FINANCIAL ENTITY .....::::: ", this.state.defaultFinancialEntity);
            options.push({
                label: this.state.defaultFinancialEntity.usuario_fel,
                value: this.state.defaultFinancialEntity.financial_entity,
                membership: this.state.defaultFinancialEntity.afiliacion_fel
            });
        }

        if(!!row.financial_entities){
            row.financial_entities.forEach(entity => {
            if(this.state.defaultFinancialEntity !== null && (this.state.defaultFinancialEntity.financial_entity !== entity.financial_entity.id)){
                options.push(
                    {
                        label: entity.financial_entity.usuario_fel,
                        value: entity.financial_entity.id,
                        membership: entity.financial_entity.afiliacion_fel,
                        //acronimo: entity.financial_entity.acronimo
                    }
                );
            }
            });
        }

        return(
            <React.Fragment>
            { (options.length > 1) ?
                <MyModal isOpen={false}
                    title=""
                    formId="selectEntity"
                    imageButton={<img className="card-img-top d-block" src={require("../../../../assets/img/icons/car-shop-icon.png")} alt="Car Shop icon" />}
                    classToggleButton="circle-button-car"
                    body={
                            <Form
                                onSubmit={this.props.addProductToCartDiff}
                                options={options}
                                id={row.id}
                                name={row.name}
                                price={row.price}
                                discount={row.discount}
                                total={row.price}
                                barcode={row.barcode}
                            />
                    }
                />
            :
                <button onClick={() => {this.props.addProductToCart(row)}} className="circle-button-car">
                    <img className="card-img-top d-block" src={require("../../../../assets/img/icons/car-shop-icon.png")} alt="Car Shop icon" />
                </button>
            }
            </React.Fragment>
        );
    }

    upDownCant = (index, cant) => {
        return(
            <React.Fragment>
                <div className="group-button-up-down">
                    <button className="button-content left-tab" onClick={() => {this.props.upDownCant(index, false)}}>
                        <img className="card-img-top d-block arrow-button" id="arrow-button-toggle" src={require("../../../../assets/img/icons/down-arrow-white-icon.png")} alt="CashBox image" />
                    </button>
                    <input
                        value={cant}
                        type="number"
                        className='input-cant text-right'
                        onChange={(e)=>{
                            const valor = e.target.value === '' ? 0 : parseFloat(e.target.value);
                            this.props.setCant(index, valor);
                        }}
                    />
                    <button className="button-content right-tab" onClick={() => {this.props.upDownCant(index, true)}}>
                        <img className="card-img-top d-block arrow-button" id="arrow-button-toggle" src={require("../../../../assets/img/icons/up-arrow-white-icon.png")} alt="CashBox image" />
                    </button>
                </div>
            </React.Fragment>
        );
    }

    render() {

        const {
            page,
            cargando,
            data,
            totalInCar,
            totalPagado,
            patientInfo,
            open_accounts,
        } = this.props;

        const {
            cancelSale2,
            applyDiscount,
            applyDiscountValue,
            setCouponCode,
            listar,
            openAccountRegister,
            openAccount,
            billing_email
        } = this.props;

        const idPaciente = patientInfo.patient.value

        // console.log("PROPS IN SERVICES: ", this.props);
        // console.log("Is open account: ", this.props.is_open_account);
        return (
            <div className="content-with-tabs flex-wrap">
                <div className="d-inline-flex w-100">
                    <div className="row-fluid w-50 tab-background-blue">
                        <a href="/#/oppening-closing" className="d-flex justify-content-center align-items-center tab-active tab-br-tr">
                            VENTA
                        </a>
                    </div>
                    <div className="row-fluid w-50 tab-background">
                        <a href="/#/oppening-closing/box" className="d-flex justify-content-center align-items-center tab tab-br-bl">
                            CAJA
                        </a>
                    </div>
                </div>

                <div className="table-decoration w-50 h-100 mx-5 mt-3 mb-3 d-flex flex-column sales-services" id="left-tab">
                    <div className="fields">
                        <div className="m-auto row-fluid w-100 h-100">
                            <div className="table-decoration rcp-table-decoration" id="comodin-table">
                                <div className="pl-3 d-flex flex-wrap h-25 justify-content-center align-items-center">
                                    <div className="d-flex flex-column w-50 justify-content-center align-items-center">
                                        <div className="d-inline-flex w-100 service-title">
                                            LISTADO DE SERVICIOS
                                        </div>
                                        <ToolbarTab
                                            id="toolbar-tab"
                                            buscar={this.props.search}
                                            buscador={this.props.buscador}
                                            autofocus
                                        />
                                    </div>

                                    <div className="d-flex w-50 justify-content-start align-items-center">
                                        <table className="table table-sm" id="table-patient">
                                            <tbody>
                                                <tr>
                                                    <th className="text-right">
                                                        Nombre:
                                                    </th>
                                                    <td className="pl-3 gray-color">
                                                        {this.props.patientInfo.name}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th className="text-right">
                                                        Nit:
                                                    </th>
                                                    <td className="pl-3 gray-color">
                                                        {this.props.patientInfo.nit}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th className="text-right">
                                                        Referido:
                                                    </th>
                                                    <td className="pl-3 gray-color">
                                                        {this.props.patientInfo.referent != null ? this.props.patientInfo.referent.usuario_fel : ''}
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>

                                </div>

                                <Table
                                    onPageChange={listar}
                                    data={data}
                                    loading={cargando}
                                    page={page}
                                >

                                    <TableHeaderColumn
                                        tdStyle={BreakLine}
                                        thStyle={BreakLine}
                                        dataField="name"
                                        dataSort
                                        columnClassName={"sales-cell-service"}
                                        >Servicio</TableHeaderColumn>

                                    <TableHeaderColumn
                                        tdStyle={BreakLine}
                                        thStyle={BreakLine}
                                        dataFormat={(cell, row) => <RenderMoneda monto={cell} />}
                                        dataField="price"
                                        columnClassName={"sales-cell-service"}
                                        dataSort>Costo</TableHeaderColumn>

                                    <TableHeaderColumn
                                        tdStyle={BreakLine}
                                        thStyle={BreakLine}
                                        dataField="barcode"
                                        columnClassName={"sales-cell-service"}
                                        dataSort>Código de Barras</TableHeaderColumn>

                                    {/*
                                    <TableHeaderColumn
                                        tdStyle={BreakLine}
                                        thStyle={BreakLine}
                                        dataField="cantidad"
                                        columnClassName={"sales-cell-service"}
                                        dataSort>Existencia</TableHeaderColumn>
                                    */}

                                    <TableHeaderColumn
                                        dataField="id"
                                        isKey={true}
                                        dataAlign="center"
                                        dataFormat={this.addToCar}
                                        columnClassName={"sales-cell-service"}
                                        ></TableHeaderColumn>
                                </Table>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="table-decoration w-25 h-100 mx-5 mt-3 mb-3 d-flex flex-column sales-services" id="right-tab">


                    <div className="description-table">
                        <table className="table table-sm" id="description-table">
                            <tbody>
                            {this.props.productsInCar.filter(item => !item.is_charge).map((product, i) =>
                            <React.Fragment key={i}>
                                {product.cupon === false ?
                                    <ProductInCart
                                        i={i}
                                        product={product}
                                        removeProductToCart={this.props.removeProductToCart}
                                        upDownCant={this.upDownCant}
                                        applyDiscount={applyDiscount}
                                        applyDiscountValue={applyDiscountValue}
                                        removeCoupon={this.props.removeCoupon}
                                        setCouponCode={this.props.setCouponCode}
                                    />
                                    :
                                    <CuponInCart
                                        i={i}
                                        product={product}
                                        removeCoupon={this.props.removeCoupon}
                                    />
                                }
                            </React.Fragment>
                            )}
                            </tbody>
                        </table>
                    </div>
                    <div className="d-flex flex-wrap flex-column justify-content-center total-sales">

                        <div className="d-flex justify-content-end">
                            <div className="d-flex sales-tab"><span className="element-type">Cuanto debe:</span></div>
                            <div className="d-flex sales-tab ml-4"><span className="element-type"><RenderCurrency value={totalInCar}/></span></div>
                        </div>

                        <div className="d-flex justify-content-end">

                            <div className="d-flex sales-tab"><span className="element-type">Cuanto ha pagado:</span></div>
                            <div className="d-flex sales-tab ml-4"><span className="element-type"><RenderCurrency value={totalPagado}/></span></div>

                        </div>

                        <div className="d-flex justify-content-end">

                            <div className="d-flex sales-tab font-weight-bold"><span className="element-type">Total a pagar:</span></div>
                            <div className="d-flex sales-tab  font-weight-bold ml-4"><span className="element-type"><RenderCurrency value={parseFloat((totalInCar - totalPagado).toFixed(2))}/></span></div>

                        </div>

                    </div>
                    <div className="d-flex flex-wrap justify-content-around p-2">
                        <div className="d-flex">
                                {/*
                            <Link className="btn btn-primary btn-block button-charge d-flex justify-content-center align-items-center" to="/oppening-closing/payment/">Cobrar</Link>
                            */}

                            {/*this.props.productsInCarOrdered.length > 0 && this.props.productsInCarOrdered[0].dataBilling.state !== 2 && */}
                            {this.props.productsInCarOrdered.length > 0 && !this.props.is_open_account &&
                                <React.Fragment>
                                {(this.props.productsInCar.length > 0) ?
                                    <MyModal
                                        isOpen={false}
                                        size="lg"
                                        title="Confirmar Datos de Facturación"
                                        formId="billing_information"
                                        textButton="Cobrar y Facturar"
                                        classToggleButton="btn btn-primary btn-block button-charge d-flex justify-content-center align-items-center"
                                        body={
                                            idPaciente ?
                                            <BillingForm
                                                onSubmit={this.props.confirmDataBilling}
                                                validateNit={this.props.validateNit}
                                                total_pagar={parseFloat((totalInCar - totalPagado).toFixed(2))}
                                                patientID={idPaciente}
                                                changeFieldFormBillingInformation={this.props.changeFieldFormBillingInformation}
                                                setDataName={this.props.setDataName}
                                                billingEmail={billing_email.email}/>
                                            :
                                            null
                                        }
                                    />
                                    :
                                    <a className="btn btn-primary btn-block button-charge d-flex justify-content-center align-items-center" disabled>Cobrar</a>
                                }
                                </React.Fragment>
                            }

                            {/* this.props.productsInCarOrdered.length > 0 && this.props.productsInCarOrdered[0].dataBilling.state === 2 && */}
                            {this.props.productsInCarOrdered.length > 0 && this.props.is_open_account &&
                                <div className="d-flex flex-column">
                                <Link className="btn btn-info btn-block button-charge-small d-flex justify-content-center align-items-center fuente-boton-deposito" to="/oppening-closing/deposit/">Agregar Anticipo</Link>
                                {(this.props.productsInCar.length > 0) ?
                                    <MyModal
                                        isOpen={false}
                                        size="lg"
                                        title="Confirmar Datos de Facturación"
                                        formId="billing_information"
                                        textButton="Cobrar y facturar"
                                        classToggleButton="btn btn-primary btn-block button-charge-small d-flex justify-content-center align-items-center pb-4 fuente-boton-deposito"
                                        body={
                                            idPaciente ?
                                            <BillingForm
                                                onSubmit={this.props.confirmDataBilling}
                                                validateNit={this.props.validateNit}
                                                total_pagar={parseFloat((totalInCar - totalPagado).toFixed(2))}
                                                patientID={idPaciente}
                                                changeFieldFormBillingInformation={this.props.changeFieldFormBillingInformation}
                                                setDataName={this.props.setDataName}
                                                billingEmail={billing_email.email}/>
                                            :
                                            null
                                        }
                                    />
                                    :
                                    <a className="btn btn-primary btn-block button-charge-small d-flex justify-content-center align-items-center fuente-boton-deposito" disabled>Cobrar</a>
                                }
                                </div>
                            }
                        </div>
                        <div className="d-flex flex-wrap text-center vertical-align-middle">
                            {/*<Link id="enlace" className="d-flex align-items-center p-1 button-down font-weight-bold" to="/oppening-closing/">Cancelar</Link>*/}

                            {(this.props.productsInCar.length > 0) ?
                                    <div className="button-down d-flex flex-column justify-content-center align-items-center p-1" onClick={()=>openAccountRegister()}>
                                        <span>Cuenta Abierta</span>

                                        {this.props.is_open_account ?
                                            <img className="icon-service-button" src={require("../../../../assets/img/icons/save-icon-gris.png")} alt="Guardar" />
                                        :
                                            <img className="icon-service-button" src={require("../../../../assets/img/icons/cuenta-abierta-gris.png")} alt="Cuenta abierta" />
                                        }
                                    </div>
                                :
                                <div disabled className="button-down d-flex flex-column justify-content-center align-items-center p-1">
                                    <span>Cuenta Abierta</span>
                                    {this.props.is_open_account ?
                                        <img className="icon-service-button" src={require("../../../../assets/img/icons/save-icon-gris.png")} alt="Guardar" />
                                    :
                                        <img className="icon-service-button" src={require("../../../../assets/img/icons/cuenta-abierta-gris.png")} alt="Agregar" />
                                    }
                                </div>
                            }

                            <div className="button-down d-flex flex-column justify-content-center align-items-center p-1">
                                <span>Cupón</span>
                                <MyModal isOpen={false}
                                    title=""
                                    formId="setCouponCode"
                                    imageButton={<img className="icon-service-button" src={require("../../../../assets/img/icons/cupon-icon-gris.png")} alt="Cupon icon" />}
                                    classToggleButton="d-block h-100 button-service-icon"
                                    body={
                                            <FormCoupon onSubmit={setCouponCode}  />
                                    }
                                />
                            </div>

                            <div className="button-down d-flex flex-column justify-content-center align-items-center p-1" onClick={()=>cancelSale2()}>
                                <span>Cancelar</span>
                                <img className="icon-service-button" src={require("../../../../assets/img/icons/cancel-sale-icon-gris.png")} alt="Cancelar" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default Service;
