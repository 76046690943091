import { connect } from 'react-redux';
import ServiceCategory from './ServiceCategory';
import { actions } from '../../../redux/modules/serviceCategory';
import {resetStore} from "../../../redux/modules/login";

const ms2p = (state) => {
    return {
        ...state.serviceCategory,
    };
};

const md2p = { ...actions, resetStore };

export default connect(ms2p, md2p)(ServiceCategory);
