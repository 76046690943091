const url = 'reporte/pago-grupal';
import { api } from 'api';
import Swal from 'sweetalert2';
import { push } from 'react-router-redux';
import { goBack } from 'react-router-redux';
import { initialize as initializeForm } from 'redux-form';
import { createActions, handleActions } from 'redux-actions';
import moment from "moment";

const LOADER_PAGO_GRUPAL = 'LOADER_PAGO_GRUPAL';
const SET_DATA_PAGO_GRUPAL = 'SET_DATA_PAGO_GRUPAL';
const SET_PAGE_PAGO_GRUPAL = 'SET_PAGE_PAGO_GRUPAL';
const SET_BUSCADOR_PAGO_GRUPAL = 'SET_BUSCADOR_PAGO_GRUPAL';
const SET_FILTRO_PAGO_GRUPAL = 'SET_FILTRO_PAGO_GRUPAL';
const SET_INICIAL_DATE_PAGO_GRUPAL = 'SET_INICIAL_DATE_PAGO_GRUPAL';
const SET_FINAL_DATE_PAGO_GRUPAL = 'SET_FINAL_DATE_PAGO_GRUPAL';

const listar = (page = 1) =>  (dispatch, getStore) => {
    dispatch({type: LOADER_PAGO_GRUPAL, cargando: true});
    const store = getStore();
    const search = store.reporte_pago_grupal.buscador;
    const filtro = store.reporte_pago_grupal.filtro;
    const initial_date = store.reporte_pago_grupal.initial_date;
    const final_date = store.reporte_pago_grupal.final_date;

    let params = {page, search, initial_date, final_date};
    if(filtro){
        params[''] = filtro
    }

    api.get(`${url}/getPagos`, params).catch((error) => {
        dispatch({type: LOADER_OPEN_ACCOUNTS, cargando: false});
        Swal(
            'Error',
            error.detail || 'Ha ocurrido un error, por favor vuelva a intentar.',
            'error'
        );
    }).then((data) => {
        if(data){
            console.log("Pagos grupales: ", data);
            dispatch({type: SET_DATA_PAGO_GRUPAL, data});
            dispatch({type: SET_PAGE_PAGO_GRUPAL, page});
        }
        dispatch({type: LOADER_PAGO_GRUPAL, cargando: false});
    })
};

const search = (search) => (dispatch) => {
    dispatch({type: SET_BUSCADOR_PAGO_GRUPAL, buscador: search});
    dispatch(listar(1));
};

const filtro = (filtro) => (dispatch, getStore) => {
    if (filtro == 0){
        filtro = null
    }
    dispatch({type: SET_FILTRO_PAGO_GRUPAL, filtro});
    dispatch(listar(1));
};

const setFinalDate = (value) => (dispatch) => {
    dispatch({type: SET_FINAL_DATE_PAGO_GRUPAL, final_date: value});
    // dispatch(listar(1));
}

const setInitialDate = (value) => (dispatch) => {
    dispatch({type: SET_INICIAL_DATE_PAGO_GRUPAL, initial_date: value});
    // dispatch(listar(1));
}

export const actions = {
    listar,
    search,
    filtro,
    setFinalDate,
    setInitialDate,
};

export const reducer = {
    [LOADER_PAGO_GRUPAL]: (state, { cargando }) => {
        return {...state, cargando }
    },
    [SET_DATA_PAGO_GRUPAL]: (state, { data }) => {
        return {...state, data }
    },
    [SET_PAGE_PAGO_GRUPAL]: (state, { page }) => {
        return {...state, page }
    },
    [SET_BUSCADOR_PAGO_GRUPAL]: (state, { buscador }) => {
        return {...state, buscador }
    },
    [SET_FILTRO_PAGO_GRUPAL]: (state, { filtro }) => {
        return {...state, filtro }
    },
    [SET_INICIAL_DATE_PAGO_GRUPAL]: (state, { initial_date }) => {
        return{
            ...state,
            initial_date
        }
    },
    [SET_FINAL_DATE_PAGO_GRUPAL]: (state, { final_date }) => {
        return{
            ...state,
            final_date
        }
    },
};

export const initialState = {
    cargando: false,
    page: 1,
    data: {
        count: 0,
        next: null,
        previous: null,
        results: [],
    },
    buscador: '',
    filtro: null,

    initial_date: moment().date(1).format("DD/MM/YYYY"),
    final_date: moment().format("DD/MM/YYYY"),
    //initial_date: null,
    //final_date: null,
};

export default handleActions(reducer, initialState)
