import React, { Component } from 'react';

class Spoiler extends Component {
    state = {
        show: this.props.show || false,
    }

    toggle = (event) => {
        if (event && typeof event.preventDefault === 'function') {
            event.preventDefault();
        }
        this.setState({ show: !this.state.show});
    }

    render(){
        return (
            <div className="mb-5">
                <div className="row-fluid d-flex justify-content-center flex-column flex-sm-row align-items-stretch align-items-sm-center  mx-auto w-100">
                    <div className="patient-label mr-md-3">
                        <div className="d-flex justify-content-start flex-column flex-sm-row align-items-stretch align-items-sm-center">
                            <label className="m-0">{this.props.label}</label>
                        </div>
                    </div>


                    <div className="line-patient mr-3 d-flex justify-content-start flex-column flex-sm-row align-items-stretch align-items-sm-center w-100">
                    {/* This empty div is important to show the line among each category-prices row  */}
                    </div>
                    <div onClick={(e)=> this.toggle(e)} className={`${this.state.show == true ? 'patient-up-icon' : 'patient-down-icon'} my-2`}>
                        <div></div>
                    </div>
                </div>
                { (this.state.show) &&
                    this.props.children
                }
            </div>
        );
    }
}

export default Spoiler;
