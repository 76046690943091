export const colourOptions = [
    { value: "#211915", label: "", color: "#211915" },
    { value: "#ff0f01", label: "", color: "#ff0f01" },
    { value: "#00991d", label: "", color: "#00991d" },
    { value: "#f25e0e", label: "", color: "#f25e0e"  },
    { value: "#01b0f0", label: "", color: "#01b0f0"  },
    { value: "#7030a0", label: "", color: "#7030a0" },
    { value: "#ba305f", label: "", color: "#ba305f" },
    { value: "#ffb600", label: "", color: "#ffb600" },
    { value: "#0a5995", label: "", color: "#0a5995" },
    { value: "#336535", label: "", color: "#336535" },
    { value: "#a3bd31", label: "", color: "#a3bd31" },
    { value: "#f7ec45", label: "", color: "#f7ec45" },
    { value: "#6e5128", label: "", color: "#6e5128" },
    { value: "#2cff00", label: "", color: "#2cff00" },
    { value: "#ff2ef8", label: "", color: "#ff2ef8" },
    { value: "#850f5b", label: "", color: "#850f5b" },
    { value: "#001a5a", label: "", color: "#001a5a" },
    { value: "#61eeee", label: "", color: "#61eeee" },
    { value: "#ff4648", label: "", color: "#ff4648" },
    { value: "#895fff", label: "", color: "#895fff" },
    { value: "#2c2c2c", label: "", color: "#2c2c2c" },
    { value: "#fff", label: "", color: "#fff" },
  ];
