import React from 'react';
import { api } from "api";
import { Link } from 'react-router-dom';
import { Field, reduxForm, formValueSelector } from 'redux-form';
import { validate, validators } from 'validate-redux-form';
import { renderField, renderDatePicker, renderSelectCustom, renderSearchSelect, dateInput, renderFieldCheck, renderFieldDPI, renderFieldNIT} from 'Utils/renderField/renderField';
import { departamentos } from 'variables';
import { connect } from 'react-redux'

import trim from 'lodash/trim';
import validarNit from 'validar-nit-gt';
import { renderSelectField } from '../Utils/renderField/renderField';

import newDatePicker from '../Utils/NewDatepicker/index';
import { generateShowHourMinuteSecond } from 'antd/lib/time-picker';
import moment from 'moment';

export const isEmpty = value => value === undefined || value === null || value === '';
export function NIT(value) {
    if (!isEmpty(value)) {
        value = trim(value.toUpperCase())
        return validarNit(value) ? null : value === 'CF' ? null : 'Escriba un nit válido o CF'
    }
    return null
}

const cuiIsValid = (cui) => {
    if (!cui) {
      return true;
    }
    var cuiRegExp = /^[0-9]{4}\s?[0-9]{5}\s?[0-9]{4}$/;
    if (!cuiRegExp.test(cui)) {
      return false;
    }
    cui = cui.replace(/\s/, "");
    cui = cui.replace(" ", "");
    cui = cui.trim();
    var depto = parseInt(cui.substring(9, 11), 10);
    var muni = parseInt(cui.substring(11, 13));
    var numero = cui.substring(0, 8);
    var verificador = parseInt(cui.substring(8, 9));
    let munisPorDepto = [ 17, 8, 16, 16, 13, 14, 19, 8, 24, 21, 9, 30, 32, 21, 8, 17, 14, 5, 11, 11, 7, 17 ]
    if (depto === 0 || muni === 0) {
      return false;
    }
    if (depto > munisPorDepto.length) {
      return false;
    }
    if (muni > munisPorDepto[depto - 1]) {
      return false;
    }
    var total = 0;
    for (var i = 0; i < numero.length; i++) {
      total += numero[i] * (i + 2);
    }
    var modulo = total % 11;
    return modulo === verificador;
  };

export function DPI(value) {
    if (!isEmpty(value)) {
        value = toString(value)
        return !cuiIsValid(value) ? null : value === '' ? null : 'Escriba un dpi válido'
    }
    return null
}

let persons = []

const getPersons = (search) => {
    return api.get("referents", {search}).catch((error) => {})
        .then((data) => {
            if (data){
                persons = [];
                data.results.forEach(x=>{
                    persons.push({value: x.id, label: x.name})
                })
                return persons
            }
            else{
                return []
            }
        })
        .catch(e => {
            return []
        })
}

const mayuscula = value => value.toUpperCase();

let Form = (props) => {
    const {
        handleSubmit,
        municipios,
        validateNit,
        es_igss,
        afiliacion_dpi,
        gender,
        setDataName,
        getAfiliationData,
        created
    } = props;

    const view = window.location.href.includes("view");

    return(
        <form onSubmit={handleSubmit} id="patients" className="py-3 px-5">
            {created &&
                <div className='row-fluid'>                
                    <label>Registrado el {moment(created).format('DD/MM/YYYY') }</label>                
                </div>
            }
            <div className="px-md-5 d-flex justify-content-around flex-column flex-sm-row align-items-stretch align-items-sm-center">
                <div className="m-2">
                    <div className=" d-flex justify-content-start flex-column flex-sm-row align-items-stretch align-items-sm-center">
                        <label htmlFor="es_igss" className="">¿Es IGSS?</label>
                    </div>
                    <div className=" d-flex justify-content-center flex-column flex-sm-row align-items-stretch align-items-sm-center">
                        <Field name="es_igss" disabled={view} component={renderFieldCheck} className="form-control"/>
                    </div>
                </div>
                {!es_igss &&
                    <div className="m-2 invisible">
                        <div className="d-flex justify-content-start flex-column flex-sm-row align-items-stretch align-items-sm-center">
                            <label htmlFor="comodin1" className="">Comodin</label>
                        </div>
                        <div className=" d-flex justify-content-end flex-column flex-sm-row align-items-stretch align-items-sm-center">
                            <Field name="comodin1" disabled={view} component={renderFieldCheck} className="form-control"/>
                        </div>
                    </div>
                }
                {es_igss &&
                    <div className="m-2">
                        <div className=" d-flex justify-content-start flex-column flex-sm-row align-items-stretch align-items-sm-center">
                            <label htmlFor="is_dpi" className="">¿El número de afiliación es el DPI?</label>
                        </div>
                        <div className=" d-flex justify-content-end flex-column flex-sm-row align-items-stretch align-items-sm-center">
                            <Field name="is_dpi" disabled={view} component={renderFieldCheck} className="form-control"/>
                        </div>
                    </div>
                }
            </div>

            {es_igss && 
                <div className="px-md-5 d-flex justify-content-around flex-column flex-sm-row align-items-stretch align-items-sm-center">
                    <div className="m-2">
                        <div className="d-flex justify-content-start flex-column flex-sm-row align-items-stretch align-items-sm-center">
                            <label htmlFor="no_afiliacion" className="">Número de afiliación*</label>
                        </div>
                        <div className=" d-flex justify-content-start flex-column flex-sm-row align-items-stretch align-items-sm-center">
                            <Field name={afiliacion_dpi ? "dpi" : "no_afiliacion"} disabled={view} component={renderField} type="text" className="form-control"
                                onChange={(e)=> getAfiliationData(e.target.value)}
                                //onBlurs={(e)=> isAfiliationReady(e)}
                            />
                        </div>
                    </div>
                    <div className="m-2 invisible">
                        <div className=" d-flex justify-content-start flex-column flex-sm-row align-items-stretch align-items-sm-center">
                            <label htmlFor="comodin" className="">Comodin</label>
                        </div>
                        <div className=" d-flex justify-content-end flex-column flex-sm-row align-items-stretch align-items-sm-center">
                            <Field name="comodi" disabled={view} component={renderField} type="text" className="form-control"/>
                        </div>
                    </div>
                </div>
            }

            <hr></hr>
            <div className='px-md-5 d-flex justify-content-around flex-column flex-sm-row align-items-stretch align-items-sm-center'>
                <div className="m-2">
                    <div className="d-flex justify-content-start flex-column flex-sm-row align-items-stretch align-items-sm-center">
                        <label htmlFor="married_last_name" className="">ID DE PACIENTE</label>
                    </div>
                    <div className=" d-flex justify-content-start flex-column flex-sm-row align-items-stretch align-items-sm-center">
                        <Field name="id" disabled={true} component={renderField} type="text" letras={false} className="form-control"/>
                    </div>
                </div>                
                <div className="m-2">
                    <div className="d-flex justify-content-start flex-column flex-sm-row align-items-stretch align-items-sm-center">
                        <label htmlFor="married_last_name" className="">No. Expediente</label>
                    </div>
                    <div className=" d-flex justify-content-start flex-column flex-sm-row align-items-stretch align-items-sm-center">
                        <Field name="file_number" disabled={view} component={renderField} type="text" letras={false} className="form-control"/>
                    </div>
                </div>                
            </div>
            <div className="px-md-5 d-flex justify-content-around flex-column flex-sm-row align-items-stretch align-items-sm-center">
                <div className="m-2">
                    <div className=" d-flex justify-content-start flex-column flex-sm-row align-items-stretch align-items-sm-center">
                        <label htmlFor="referred_by" className="">Referente</label>
                    </div>
                    <div className=" d-flex justify-content-end flex-column flex-sm-row align-items-stretch align-items-sm-center">
                        <Field
                            disabled={view}
                            name="referred_by"
                            valueKey="value"
                            labelKey="label"
                            label="name"
                            component={renderSearchSelect}
                            loadOptions={getPersons}
                            className="no-border"
                            autofocus
                        />
                    </div>
                </div>
                <div className="m-2">
                    <div className="d-flex justify-content-start flex-column flex-sm-row align-items-stretch align-items-sm-center">
                        <label htmlFor="nit" className="m-0">Nit</label>
                    </div>
                    <div className=" d-flex justify-content-start flex-column flex-sm-row align-items-stretch align-items-sm-center">
                        <Field
                            name="nit"
                            disabled={view}
                            component={renderField}
                            type="text"
                            className="form-control"
                            validate={NIT}
                            onBlurs={validateNit}
                            onKeyDownEnter={validateNit}
                        />
                    </div>
                </div>
            </div>
            <div className="px-md-5 d-flex justify-content-around flex-column flex-sm-row align-items-stretch align-items-sm-center">
                <div className="m-2">
                    <div className="d-flex justify-content-start flex-column flex-sm-row align-items-stretch align-items-sm-center">
                        <label htmlFor="first_name" className="">Nombre*</label>
                    </div>
                    <div className=" d-flex justify-content-start flex-column flex-sm-row align-items-stretch align-items-sm-center">
                        <Field name="first_name" disabled={view} component={renderField} normalize={mayuscula} type="text" letras={true} className="form-control"/>
                    </div>
                </div>
                <div className="m-2">
                    <div className=" d-flex justify-content-start flex-column flex-sm-row align-items-stretch align-items-sm-center">
                        <label htmlFor="last_name" className="">Apellidos*</label>
                    </div>
                    <div className=" d-flex justify-content-end flex-column flex-sm-row align-items-stretch align-items-sm-center">
                        <Field name="last_name" disabled={view} component={renderField} normalize={mayuscula} type="text" letras={true} className="form-control"/>
                    </div>
                </div>
            </div>


            <div className="px-md-5 d-flex justify-content-around flex-column flex-sm-row align-items-stretch align-items-sm-center">               
                <div className="m-2">
                    <div className="d-flex justify-content-start flex-column flex-sm-row align-items-stretch align-items-sm-center">
                        <label htmlFor="nickname" className="">Nombre preferencial</label>
                    </div>
                    <div className=" d-flex justify-content-start flex-column flex-sm-row align-items-stretch align-items-sm-center">
                        <Field name="nickname" disabled={view} component={renderField} type="text" letras={true} className="form-control"/>
                    </div>
                </div>
                {gender && gender.value==2 ?
                    <div className="m-2">
                        <div className="d-flex justify-content-start flex-column flex-sm-row align-items-stretch align-items-sm-center">
                            <label htmlFor="married_last_name" className="">Apellido de casada</label>
                        </div>
                        <div className=" d-flex justify-content-start flex-column flex-sm-row align-items-stretch align-items-sm-center">
                            <Field name="married_last_name" disabled={view} component={renderField} type="text" letras={true} className="form-control"/>
                        </div>
                    </div>
                :
                    <div className='m-2' style={{ visibility: 'visible' }}>
                        <div className="d-flex justify-content-start flex-column flex-sm-row align-items-stretch align-items-sm-center"
                            style={{ width: 272 }}
                        >                            
                        </div>                               
                    </div>
                }                
            </div>


            <div className="px-md-5 d-flex justify-content-around flex-column flex-sm-row align-items-stretch align-items-sm-center">
                <div className="m-2">
                    <div className=" d-flex justify-content-start flex-column flex-sm-row align-items-stretch align-items-sm-center">
                        <label htmlFor="birthdate" className="">Fecha de nacimiento*</label>
                    </div>
                    <div className=" d-flex justify-content-end flex-column flex-sm-row align-items-stretch align-items-sm-center">
                        <Field name="birthdate" isDisabled={view} component={dateInput} type="text" className="form-control" />
                    </div>
                </div>
                <div className="m-2">
                    <div className="d-flex justify-content-start flex-column flex-sm-row align-items-stretch align-items-sm-center">
                        <label htmlFor="gender" className="">Género*</label>
                    </div>
                    <div className=" d-flex justify-content-start flex-column flex-sm-row align-items-stretch align-items-sm-center">
                        <Field name="gender" disabled={view} component={renderSelectCustom} options={[
                            {value:1, label:'Masculino'},
                            {value:2, label:'Femenino'},
                        ]}/>
                    </div>
                </div>
            </div>



            <div className="px-md-5 d-flex justify-content-around flex-column flex-sm-row align-items-stretch align-items-sm-center">
                <div className="m-2">
                    <div className=" d-flex justify-content-start flex-column flex-sm-row align-items-stretch align-items-sm-center">
                        <label htmlFor="nationality" className="">Nacionalidad*</label>
                    </div>
                    <div className=" d-flex justify-content-end flex-column flex-sm-row align-items-stretch align-items-sm-center">
                        <Field name="nationality" disabled={view} component={renderField} type="text" className="form-control"/>
                    </div>
                </div>
                <div className="m-2">
                    <div className="d-flex justify-content-start flex-column flex-sm-row align-items-stretch align-items-sm-center">
                        <label htmlFor="departamento" className="">Departamento</label>
                    </div>
                    <div className=" d-flex justify-content-start flex-column flex-sm-row align-items-stretch align-items-sm-center">
                        <Field name="departamento" disabled={view} component={renderSelectCustom} options={departamentos}/>
                    </div>
                </div>
            </div>


            <div className="px-md-5 d-flex justify-content-around flex-column flex-sm-row align-items-stretch align-items-sm-center">
                <div className="m-2">
                    <div className=" d-flex justify-content-start flex-column flex-sm-row align-items-stretch align-items-sm-center">
                        <label htmlFor="municipio" className="">Municipio</label>
                    </div>
                    <div className=" d-flex justify-content-end flex-column flex-sm-row align-items-stretch align-items-sm-center">

                        <Field name="municipio" disabled={view} component={renderSelectCustom} options={municipios}/>
                    </div>
                </div>
                <div className="m-2">
                    <div className="d-flex justify-content-start flex-column flex-sm-row align-items-stretch align-items-sm-center">
                        <label htmlFor="address" className="">Dirección</label>
                    </div>
                    <div className=" d-flex justify-content-start flex-column flex-sm-row align-items-stretch align-items-sm-center">
                        <Field name="address" disabled={view} component={renderField} type="text" className="form-control"/>
                    </div>
                </div>
            </div>



            <div className="px-md-5 d-flex justify-content-around flex-column flex-sm-row align-items-stretch align-items-sm-center">
                <div className="m-2">
                    <div className=" d-flex justify-content-start flex-column flex-sm-row align-items-stretch align-items-sm-center">
                        <label htmlFor="dpi" className="">DPI</label>
                    </div>
                    <div className=" d-flex justify-content-end flex-column flex-sm-row align-items-stretch align-items-sm-center">
                        <Field
                            name="dpi"
                            disabled={view}
                            component={renderFieldDPI}
                            type="number"
                            className="form-control"
                            validate={DPI}
                            onBlurs={true}
                            onKeyDownEnter={setDataName}
                        />
                    </div>
                </div>
                <div className="m-2">
                    <div className="d-flex justify-content-start flex-column flex-sm-row align-items-stretch align-items-sm-center">
                        <label htmlFor="profession" className="">Profesión</label>
                    </div>
                    <div className=" d-flex justify-content-start flex-column flex-sm-row align-items-stretch align-items-sm-center">
                        <Field name="profession" disabled={view} component={renderField} type="text" letras={true} className="form-control"/>
                    </div>
                </div>
                {/*
                <div className="m-2">
                    <div className=" d-flex justify-content-start flex-column flex-sm-row align-items-stretch align-items-sm-center">
                        <label htmlFor="family_member_of" className="">Médico o familiar de médico</label>
                    </div>
                    <div className=" d-flex justify-content-end flex-column flex-sm-row align-items-stretch align-items-sm-center">
                        <Field
                            disabled={view}
                            name="family_member_of"
                            valueKey="value"
                            labelKey="label"
                            label="name"
                            component={renderSearchSelect}
                            loadOptions={getPersons}
                            className="no-border"
                        />
                    </div>
                </div>
                */}
            </div>



            <div className="px-md-5 d-flex justify-content-around flex-column flex-sm-row align-items-stretch align-items-sm-center">
                <div className="m-2">
                    <div className=" d-flex justify-content-start flex-column flex-sm-row align-items-stretch align-items-sm-center">
                        <label htmlFor="family_doctor" className="">¿Es familiar de un médico?</label>
                    </div>
                    <div className=" d-flex justify-content-end flex-column flex-sm-row align-items-stretch align-items-sm-center">
                        <Field name="family_doctor" disabled={view} component={renderSelectCustom} options={[
                            {value:1, label:'Sí'},
                            {value:2, label:'No'},
                        ]}/>
                    </div>
                </div>
                <div className="m-2">
                    <div className="d-flex justify-content-start flex-column flex-sm-row align-items-stretch align-items-sm-center">
                        <label htmlFor="email" className="">Correo electrónico</label>
                    </div>
                    <div className=" d-flex justify-content-start flex-column flex-sm-row align-items-stretch align-items-sm-center">
                        <Field name="email" disabled={view} component={renderField} type="email" className="form-control"/>
                    </div>
                </div>
            </div>


            <div className="px-md-5 d-flex justify-content-around flex-column flex-sm-row align-items-stretch align-items-sm-center">
                <div className="m-2">
                    <div className=" d-flex justify-content-start flex-column flex-sm-row align-items-stretch align-items-sm-center">
                        <label htmlFor="phone" className="">Teléfono de línea fija</label>
                    </div>
                    <div className=" d-flex justify-content-end flex-column flex-sm-row align-items-stretch align-items-sm-center">
                        <Field name="phone" disabled={view} component={renderField} type="number" className="form-control"/>
                    </div>
                </div>
                <div className="m-2">
                    <div className="d-flex justify-content-start flex-column flex-sm-row align-items-stretch align-items-sm-center">
                        <label htmlFor="cellphone" className="">Teléfono celular</label>
                    </div>
                    <div className=" d-flex justify-content-start flex-column flex-sm-row align-items-stretch align-items-sm-center">
                        <Field name="cellphone" disabled={view} component={renderField} type="number" className="form-control"/>
                    </div>
                </div>
            </div>

            <div className="row-fluid">
                <div className="d-flex justify-content-center flex-column flex-sm-row align-items-stretch align-items-sm-center">
                    <Link className="btn btn-secondary m-3" to="/patients">{!view ? ("Cancelar") : ("Regresar")}</Link>
                   {!view && (<button type="submit" className="btn btn-primary m-3">Guardar</button>)}
                </div>
            </div>            
        </form>
    )
};

Form = reduxForm({
    form: 'patients',
    validate: data => {
        const error = {};
        if (!data.first_name) error.first_name = "Campo requerido.";
        if (!data.last_name) error.last_name = "Campo requerido.";
        if (!data.gender) error.gender = "Campo requerido.";
        if (!data.birthdate) error.birthdate = "Campo requerido.";
        if (!data.nationality) error.nationality = "Campo requerido.";
        
        if (data.es_igss && data.es_igss == true){
            if (data.is_dpi && data.is_dpi == true){
                if (!data.dpi) error.dpi = "Campo requerido.";
            } else {
                if (!data.no_afiliacion) error.no_afiliacion = "Campo requerido.";
            }
        }
        if (data.dpi){
            if (data.dpi.length < 13) error.dpi="El DPI debe contener 13 dígitos";
        }
        if (data.phone){
            if (data.phone .length < 8) error.phone="El telefono debe contener al menos 8 dígitos";
        }
        if (data.cellphone){
            if (data.cellphone.length < 8) error.cellphone="El telefono debe contener al menos 8 dígitos";
        }
        return error;
    },
})(Form);

const selector = formValueSelector('patients');

Form = connect(state=>{
    const es_igss = selector(state, "es_igss")
    const afiliacion_dpi = selector(state, "is_dpi")
    const gender = selector(state, "gender")
    const created = selector(state, "created")
    return {
        es_igss,
        afiliacion_dpi,
        gender,
        created
    }
})(Form)

export default Form
