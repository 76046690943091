import React, {Component} from 'react';
import Modal from 'react-responsive-modal';
import { AsyncCreatable } from 'react-select';
import classNames from 'classnames';
import "./notations";
import ColumnGroup from 'antd/lib/table/ColumnGroup';
import {api} from "../../../../api/api";
import {PL_TR} from "../../../../utility/variables";


const getRefillable = (search) => {
    let opciones = [];
    return api.get('refillable/getRefillable', {search, record_type: PL_TR}).then((data) => {
        console.log("Data: ", data);
        data.results.forEach(item => {
            opciones.push({
                value: item.id,
                label: item.texto
            });
        });
        return opciones;
    }).catch(()=>[]);
}

const completeRow = async (commercial_name) => {
    try{
        const treatment = await api.get('treatment/getTreatmentRow', {commercial_name});
        return treatment;
    }catch(error){
        return null;
    }

    /*
    api.get('treatment', {commercial_name}).then((data) => {
        if(data){
            treatment = data;
        }
    }).catch(()=>[]);
    */

    //return treatment;
}

export class Tratamiento extends Component {
    constructor(props) {
        super(props);
        this.state = { open: false, tratamientos: [], loaded: false};
    }

    openModal = () => {
        this.setState({ open: true });
    };

    closeModal = () => {
        this.setState({ open: false });
    };

    selectOption = (input, value) => {
        input.onChange(value);
        this.closeModal();
    };

    deleteItem = (item) => {
        const { input } = this.props;
        const tratamientos = _.filter(this.state.tratamientos, tratamiento => {return tratamiento !== item});
        this.setState({tratamientos});
        input.onChange(tratamientos);
    }

    updateItem = (item, field, value) => {
        if(field === 'commercial_name'){
                completeRow(value).then(response => {
                if(response.commercial_name !== ''){
                    item.quantity = response.quantity;
                    item.active_principle = response.active_principle;
                    item.presentation = response.presentation;
                    item.dose = response.dose;
                    item.frecuency = response.frecuency;
                    item.application = response.application;
                    item.time_duration = response.time_duration;
                }
                const indice = this.state.tratamientos.indexOf(item);
                item[field] = value;
                const tratamientos = this.state.tratamientos;
                tratamientos.splice(indice, 1, item);
                this.setState({tratamientos});
                const { input } = this.props;
                input.onChange(this.state.tratamientos);
            }).catch(error =>{
                const indice = this.state.tratamientos.indexOf(item);
                item[field] = value;
                const tratamientos = this.state.tratamientos;
                tratamientos.splice(indice, 1, item);
                this.setState({tratamientos});
                const { input } = this.props;
                input.onChange(this.state.tratamientos);
            });
        }else{
            const indice = this.state.tratamientos.indexOf(item);
            item[field] = value;
            const tratamientos = this.state.tratamientos;
            tratamientos.splice(indice, 1, item);
            this.setState({tratamientos});
            const { input } = this.props;
            input.onChange(this.state.tratamientos);
        }
        /*
        const indice = this.state.tratamientos.indexOf(item);
        item[field] = value;
        const tratamientos = this.state.tratamientos;
        tratamientos.splice(indice, 1, item);
        this.setState({tratamientos});
        const { input } = this.props;
        input.onChange(this.state.tratamientos);
        */
    }

    componentDidUpdate() {
        if (!this.state.loaded && !!this.props.input.value) {
            console.log("VALUE EN TRATAMIENTO: ", this.props.input.value);
            this.setState({loaded: true, tratamientos: this.props.input.value});
        }
    }

    render() {
        const { input, meta: { touched, error }, title, jaeger, view } = this.props;
        const { open } = this.state;
        const invalid = touched && error;

        return (
            <React.Fragment>
                <Modal open={open} onClose={this.closeModal}
                    styles={{
                        modal: {
                            maxWidth: 1200,
                        }
                    }}
                >
                    <div className="text-center">
                        <h3 className="border-bottom mt-4">{title}</h3>
                    </div>

                    <table className='table table-sm w-100'>
                        <thead>
                            <tr style={{background:'gray', height:'30px'}}>
                                <th className='text-center'>Nombre comercial</th>
                                <th className='text-center'>Cantidad</th>
                                <th className='text-center'>Principio activo</th>
                                <th className='text-center'>Presentación</th>
                                <th className='text-center'>Dosis</th>
                                <th className='text-center'>Frecuencia</th>
                                <th className='text-center'>Aplicación</th>
                                <th className='text-center'>Tiempo de duración</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            {this.state.tratamientos.map((item, index) => {
                                return (
                                    <tr key={index}>
                                        <td>
                                            <AsyncCreatable
                                                className={'form-control select-pd-modal p-0 border-trasparent'}
                                                onChange={
                                                    (e) => this.updateItem(item, "commercial_name", e ? e.label.toUpperCase() : e)
                                                }
                                                value={{value: item.commercial_name, label: item.commercial_name}}
                                                classNamePrefix="react-select"
                                                cacheOptions
                                                defaultOptions
                                                theme={(theme) => {
                                                    return {
                                                        ...theme,
                                                        borderRadius: '1em',
                                                        background: 'white',
                                                        colors: {
                                                            ...theme.colors,
                                                            text: '#415362',
                                                            primary25: '#ebebeb',
                                                            primary: '#a8ba3a',
                                                        },
                                                    }
                                                }}

                                                searchPromptText="Escriba para buscar"
                                                loadOptions={getRefillable}
                                                promptTextCreator={(label) => {
                                                    return `Crear opción ${label}` }}
                                                isDisabled={view}
                                            />
                                        </td>
                                        <td><input disabled={view} value={item.quantity} className="input-pd-modal" type="text" onChange={(e) => {this.updateItem(item, "quantity", e.target.value)}} /></td>
                                        <td><input disabled={view} value={item.active_principle} className="input-pd-modal" type="text" onChange={(e) => {this.updateItem(item, "active_principle", e.target.value)}} /></td>
                                        <td><input disabled={view} value={item.presentation} className="input-pd-modal" type="text" onChange={(e) => {this.updateItem(item, "presentation", e.target.value)}} /></td>
                                        <td><input disabled={view} value={item.dose} className="input-pd-modal" type="text" onChange={(e) => {this.updateItem(item, "dose", e.target.value)}} /></td>
                                        <td><input disabled={view} value={item.frecuency} className="input-pd-modal" type="text" onChange={(e) => {this.updateItem(item, "frecuency", e.target.value)}} /></td>
                                        <td><input disabled={view} value={item.application} className="input-pd-modal" type="text" onChange={(e) => {this.updateItem(item, "application", e.target.value)}} /></td>
                                        <td><input disabled={view} value={item.time_duration} className="input-pd-modal" type="text" onChange={(e) => {this.updateItem(item, "time_duration", e.target.value)}} /></td>
                                        <td>
                                            {!view &&
                                                <img
                                                    onClick={()=>{
                                                        this.deleteItem(item);
                                                    }}
                                                    className="action-img delete"
                                                    title="agregar"
                                                    src={require("../../../../../assets/img/icons/delete-icon.png")}
                                                    alt="agregar"
                                                />
                                            }
                                        </td>
                                    </tr>
                                )
                            })}
                            <tr>
                                <td colSpan="8"></td>
                                <td>
                                    {!view &&
                                        <img
                                            className="action-img watch"
                                            onClick={() => {
                                                this.setState({tratamientos: [...this.state.tratamientos, {
                                                    quantity: 1,
                                                    commercial_name: "",
                                                    active_principle: "",
                                                    presentation: "",
                                                    dose: "",
                                                    frecuency: "",
                                                    application: "",
                                                    time_duration: "",
                                                }]})
                                            }}
                                            title="agregar"
                                            src={require("../../../../../assets/img/icons/add-icon-white.png")}
                                            alt="agregar"
                                        />
                                    }

                                    {/*
                                    <button type="button" className="watch" onClick={() => {
                                        this.setState({tratamientos: [...this.state.tratamientos, {
                                            commercial_name: "",
                                            active_principle: "",
                                            presentation: "",
                                            dose: "",
                                            frecuency: "",
                                            application: "",
                                            time_duration: "",
                                        }]})
                                    }}>+</button>
                                    */}
                                </td>
                            </tr>

                        </tbody>
                    </table>
                    <div className="d-flex justify-content-center align-items-center mt-4">
                        <div
                            className="btn btn-secondary mx-4"
                            onClick={() => {
                                input.onChange(undefined);
                                this.closeModal();
                            }}>
                            Cancelar
                        </div>
                        <div
                            className="btn btn-primary mx-4"
                            onClick={() => {
                                this.closeModal();
                            }}>
                            Guardar
                        </div>
                    </div>
                </Modal>
                <span className="form-control text-center" onClick={this.openModal} > {input.value && input.value.length ? input.value[0].commercial_name : ""}</span>
            </React.Fragment>
        );
    }
}
