import React, { Component } from 'react';
import './toolbar.css';

class Search extends Component {

    constructor(props){
        super(props);
    }

    render() {
        const {
            buscar,
            buscador,
            date
        } = this.props;

        return (
            <div className="toolbar-search p-0 w-100">
                {
                    (buscador !== undefined && buscar !== undefined) && (
                        <div className="contenedor-search">
                        <input id="buscar" type="text" name="buscar" placeholder="Buscar..."
                            ref={node => {
                                this.buscar = node;
                                    if (this.buscar) {
                                        this.buscar.value = buscador;
                                    }
                                }}
                            onKeyPress={(event) => {
                                if (event.key === 'Enter') {
                                    this.props.buscar(this.buscar.value);
                                }
                            }}
                            autoComplete="off" className="form-control"
                            autoFocus={this.props.autofocus ? true : false}
                        />
                        <button
                            className="form-control-feedback boton-busqueda"
                            onClick={(event) => {
                                event.preventDefault();
                                this.props.buscar(this.buscar.value);
                                date.fechaInicial && date.setInitialDate(date.fechaInicial)
                                date.fechaFinal && date.setFinalDate(date.fechaFinal)
                                }}
                                
                        >
                        <img src={require("../../../../../assets/img/icons/search-icon.png")} alt="Buscar_icon"/>
                    </button>
                </div>
            )}
            </div>
        );
    }
}

export default Search;
