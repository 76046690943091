const url = 'bank_accounts';
import { api } from "api";
import moment from "moment";
import Swal from 'sweetalert2';
import { push } from 'react-router-redux';
import { goBack } from 'react-router-redux';
import { initialize as initializeForm } from 'redux-form';
import { createActions, handleActions } from 'redux-actions';

const LOADER_ACCOUNT = 'LOADER_ACCOUNT';
const SET_DATA_ACCOUNT = 'SET_DATA_ACCOUNT';
const SET_PAGE_ACCOUNT = 'SET_PAGE_ACCOUNT';
const SET_UPDATE_DATA_ACCOUNT ='SET_UPDATE_DATA_ACCOUNT';
const SET_UPDATE_DATA_TRANSACTION ='SET_UPDATE_DATA_TRANSACTION'
const SET_BUSCADOR_ACCOUNT = 'SET_BUSCADOR_ACCOUNT';
const SET_FILTRO_ACCOUNT = 'SET_FILTRO_ACCOUNT';
const SET_MONTH = 'SET_MONTH';
const SET_YEAR = 'SET_YEAR';
const SET_TRANSACTION_DATA_ACCOUNT = 'SET_TRANSACTION_DATA_ACCOUNT';
const SET_TRANSACTION_PAGE_ACCOUNT = 'SET_TRANSACTION_PAGE_ACCOUNT';

export const INCOME = 1
export const EXPENDITURY = 2

moment.locale('es')

const listar = (page = 1) => (dispatch, getStore) => {
    dispatch({type: LOADER_ACCOUNT, cargando: true});
    const store = getStore();
    const search = store.banks.buscador;
    const filtro = store.banks.filtro_banks;
    let params = {page, search};

    if(filtro){
        params[''] = filtro
    }
    api.get(`${url}`, params).catch((error) => {
        dispatch({type: LOADER_ACCOUNT, cargando: false});
        Swal(
            'Error',
            error.detail || 'Ha ocurrido un error, por favor vuelva a intentar.',
            'error'
        );
    }).then((data) => {
        if(data){
            dispatch({type: SET_DATA_ACCOUNT, data});
            dispatch({type: SET_PAGE_ACCOUNT, page});
        }
        dispatch({type: LOADER_ACCOUNT, cargando: false});
    })
};

const listar_transactions = (page = 1, bank = undefined) => (dispatch, getStore) => {
    dispatch({type: LOADER_ACCOUNT, cargando: true});
    const store = getStore();
    const filtro = store.banks.filtro_banks;
    const date_string = '15/' + store.banks.month.value + '/' + store.banks.year.value
    const date_moment = moment(date_string, "DD/MM/YYYY")
    const initial_date = date_moment.startOf('month').format('DD/MM/YYYY')
    const final_date = date_moment.endOf('month').format('DD/MM/YYYY')
    const pending = 0;
    let bank_account = ''
    if (bank) {
        bank_account = bank;
    } else {
        bank_account = store.banks.updateData.id;
    }
    let params = {page, initial_date, final_date, bank_account, pending};
    if(filtro){
        params[''] = filtro
    }
    api.get(`bank_transaction`, params).catch((error) => {
        dispatch({type: LOADER_ACCOUNT, cargando: false});
        Swal(
            'Error',
            error.detail || 'Ha ocurrido un error, por favor vuelva a intentar.',
            'error'
        );
    }).then((data) => {
        if(data){
            console.log("RESULTS:", data);
            let results = data.results;
            /*results.forEach(result => {
                if(result.expense_bank_transaction.length != 0) {
                    if(result.expense_bank_transaction[0].provider != null){
                        result.provider = result.expense_bank_transaction[0].provider.name;
                    }
                    else result.provider = '';
                }
                else {
                    result.provider = '';
                }
            })*/
            data.results = results;
            dispatch({type: SET_TRANSACTION_DATA_ACCOUNT, data});
            dispatch({type: SET_TRANSACTION_PAGE_ACCOUNT, page});
        }
        dispatch({type: LOADER_ACCOUNT, cargando: false});
    })
};

const generarExcelMovBancarios = (page = 1, bank = undefined) => (dispatch, getStore) => {
    const store = getStore();
    const filtro = store.banks.filtro_banks;
    const date_string = '15/' + store.banks.month.value + '/' + store.banks.year.value
    const date_moment = moment(date_string, "DD/MM/YYYY")
    const initial_date = date_moment.startOf('month').format('DD/MM/YYYY')
    const final_date = date_moment.endOf('month').format('DD/MM/YYYY')
    const pending = 0;
    let bank_account = ''
    if (bank) {
        bank_account = bank;
    } else {
        bank_account = store.banks.updateData.id;
    }
    let params = {page, initial_date, final_date, bank_account, pending};
    if(filtro){
        params[''] = filtro
    }

    window.location.replace(`/api/bank_transaction/generarExcelMovBancarios?initial_date=${initial_date}&final_date=${final_date}&id=${bank_account}&auth_token=${localStorage.getItem("token")}`);
}

const detail = id => async (dispatch, getState) =>{
    dispatch({type: LOADER_ACCOUNT, cargando: true});
    api.get(`${url}/${id}`).catch((error) => {
        dispatch({type: LOADER_ACCOUNT, cargando: false});
        if(error.statusCode === 404){
            dispatch(push('/bank-accounts'))
        }
    }).then((data) => {
        dispatch({type: LOADER_ACCOUNT, cargando: false});
        if(data){
            dispatch(listar_transactions(1, data.id));
            console.log(data.id)
            dispatch({type: SET_UPDATE_DATA_ACCOUNT, updateData: data});
        }
    })
}

const detail_transaction = id => async (dispatch, getState) =>{
    dispatch({type: LOADER_ACCOUNT, cargando: true});
    try {
        const data = await api.get(`bank_transaction/${id}`)

        dispatch({type: LOADER_ACCOUNT, cargando: false});
        if(data){
            dispatch({type: SET_UPDATE_DATA_TRANSACTION, updateTransactionData: data});
            if(data.transaction_type == INCOME){
                data.amount = data.debe
                delete data.debe
                delete data.haber
            } else if(data.transaction_type == EXPENDITURY){
                data.amount = data.haber
                delete data.debe
                delete data.haber
            }
            console.log(data)
            console.log("FECHA DE LA T4RANSACCION: ", data.date)
            console.log("FECHA DE LA T4RANSACCION: ", data.hour)
            //data.date = moment(data.hour, "YYYY-MM-DD:HH:mm").format("YYYY-MM-DD")
            data.date = moment(data.hour, "YYYY-MM-DD:HH:mm").format("DD-MM-YYYY")
            data.hour = moment(data.hour, "YYYY-MM-DD:HH:mm")

            //add Ricky
            data.no_document = (data.no_document === '') ? ' ' : data.no_document;
            //...

            dispatch(initializeForm('bankTransaction', data))
        }
    } catch(err) {
        dispatch({type: LOADER_ACCOUNT, cargando: false});
        if(error.statusCode === 404){
            // dispatch(push('/pendings'))
        }
        console.error('Error while making detail transaction', err)
    }
}

const update_transaction = () => (dispatch, getStore) => {
    const store = getStore();
    const formData = _.cloneDeep(getStore().form.bankTransaction.values);


    if (formData.transaction_type == INCOME ){
        formData.debe = formData.amount
        formData.haber = 0
        delete formData.amount
    } else if (formData.transaction_type == EXPENDITURY) {
        formData.haber = formData.amount
        formData.debe = 0
        delete formData.amount
    }
    // formData.pending = 0
    const data ={
        ...formData,
        movement_date: formData.date + 'T' + formData.hour.format("HH:mm:ss"),
    }
    dispatch({type: LOADER_ACCOUNT, cargando: true});
    api.put(`bank_transaction/${formData.id}`, data)
    .then((data) => {
        dispatch({type: LOADER_ACCOUNT, cargando: false});

        if(data){
            Swal(
                'Éxito',
                'Datos editados correctamente.',
                'success'
            ).then(() => {
                dispatch(listar_transactions(store.banks.transactions_page, store.banks.updateData.id));
            })
        }
    })
    .catch((error) =>{
        dispatch({type: LOADER_ACCOUNT, cargando: false});
        Swal(
            'Error',
             error.detail || 'Ha ocurrido un error, verifique los datos.',
            'error'
        )
        if(error.statusCode === 404){
            // dispatch(listar())
        }
    })
}

const create_transaction = () => (dispatch, getStore) => {
    const formData = _.cloneDeep(getStore().form.bankTransaction.values);
    const store = getStore()
    console.log("STORE: ", store)
    if (formData.transaction_type == INCOME) {
        formData.debe = formData.amount
        formData.haber = 0
        delete formData.amount
    } else if (formData.transaction_type == EXPENDITURY) {
        formData.debe = 0
        formData.haber = formData.amount
        delete formData.amount
    }
    formData.date = formData.date + 'T' + formData.hour.format("HH:mm:ss")
    delete formData.hour
    console.log("CREACIÓN DE UNA TRANSACCIÓN: ", formData)
    dispatch({type: LOADER_ACCOUNT, cargando: true})
    api.post(`bank_transaction`, formData).then((data) => {
        dispatch({type: LOADER_ACCOUNT, cargando: false})
        Swal('Éxito', 'Se ha creado la cuenta bancaria.', 'success')
        .then(() => {
            dispatch(listar_transactions(store.banks.transactions_page, store.banks.updateData.id));
        })
    }).catch((error) => {
        dispatch({type: LOADER_ACCOUNT, cargando: false})
        Swal(
            'Error',
             error.detail || 'Ha ocurrido un error, por favor vuelva a intentar.',
            'error'
        );
    }).finally(() => {
        dispatch({type: LOADER_ACCOUNT, cargando: false})
    });
};

const update = () => (dispatch, getStore) => {
    const formData = _.cloneDeep(getStore().form.bankAccount.values);
    dispatch({type: LOADER_ACCOUNT, cargando: true});
    api.put(`${url}/${formData.id}`, formData)
    .then((data) => {
        dispatch({type: LOADER_ACCOUNT, cargando: false});
        if(data){
            Swal(
                'Éxito',
                'Datos editados correctamente.',
                'success'
            ).then(() => {
                dispatch(push('/bank-accounts'));
            })
        }
    })
    .catch((error) =>{
        dispatch({type: LOADER_ACCOUNT, cargando: false});
        Swal(
            'Error',
             error.detail || 'Ha ocurrido un error, verifique los datos.',
            'error'
        )
        if(error.statusCode === 404){
            dispatch(push('/bank-accounts/create'));
        }
    })
}

const destroy = (id) => (dispatch, getStore) => {
    dispatch({type: LOADER_ACCOUNT, cargando: true});
    api.eliminar(`${url}/${id}`).catch((error) => {
        dispatch({type: LOADER_ACCOUNT, cargando: false});
        Swal('Error', 'No se ha logrado borrar el registro.', 'error')
            .then(() => {
                dispatch(listar(1));
        })
    }).then((data) => {
        dispatch({type: LOADER_ACCOUNT, cargando: false});
        Swal('Éxito', 'Se ha eliminado correctamente.', 'success')
            .then(() => {
                dispatch(listar(1));
            })
    })
}

const search = (search) => (dispatch, getStore) => {
    dispatch({type: SET_BUSCADOR_ACCOUNT, buscador: search});
    dispatch(listar(1));
};
const filtro = (filtro) => (dispatch, getStore) => {
    if (filtro == 0){
        filtro = null
    }
    dispatch({type: SET_FILTRO_ACCOUNT, filtro_banks: filtro});
    dispatch(listar(1));
};

const set_month = (value) => (dispatch, getStore) => {
    const store = getStore()
    dispatch({type: SET_MONTH, month: value})
    dispatch({type: SET_TRANSACTION_PAGE_ACCOUNT, page: 1});
    dispatch(listar_transactions(1, store.banks.updateData.id))
}

const set_year = (value) => (dispatch, getStore) => {
    const store = getStore()
    dispatch({type: SET_YEAR, year: value})
    dispatch({type: SET_TRANSACTION_PAGE_ACCOUNT, page: 1});
    dispatch(listar_transactions(1, store.banks.updateData.id))
}

export const actions = {
    listar,
    listar_transactions,
    detail,
    create_transaction,
    update,
    destroy,
    search,
    filtro,
    set_year,
    set_month,
    detail_transaction,
    update_transaction,
    generarExcelMovBancarios
};

export const reducer = {
    [LOADER_ACCOUNT]: (state, { cargando }) => {
        return {...state, cargando }
    },
    [SET_DATA_ACCOUNT]: (state, { data }) => {
        return {...state, data }
    },
    [SET_PAGE_ACCOUNT]: (state, { page }) => {
        return {...state, page }
    },
    [SET_UPDATE_DATA_ACCOUNT]: (state, { updateData }) => {
        return {...state, updateData }
    },
    [SET_UPDATE_DATA_TRANSACTION]: (state, { updateTransactionData }) => {
        return {...state, updateTransactionData}
    },
    [SET_BUSCADOR_ACCOUNT]: (state, { buscador }) => {
        return {...state, buscador }
    },
    [SET_FILTRO_ACCOUNT]: (state, { filtro_banks }) => {
        return {...state, filtro_banks }
    },
    [SET_TRANSACTION_DATA_ACCOUNT]: (state, { data }) => {
        return {...state, transactions_data: data }
    },
    [SET_TRANSACTION_PAGE_ACCOUNT]: (state, { page }) => {
        return {...state, transactions_page: page }
    },
    [SET_MONTH]: (state, { month }) => {
        return {...state, month }
    },
    [SET_YEAR]: (state, { year }) => {
        return {...state, year }
    }
}
export const initialState = {
    cargando: false,
    page: 1,
    data: {
        count: 0,
        next: null,
        previous: null,
        results: [],
    },
    transactions_page: 1,
    transactions_data: {
        count: 0,
        next: null,
        previous: null,
        results: [],
        total: 0
    },
    buscador: '',
    filtro_banks: null,
    updateData: {},
    updateTransactionData: {},
    month: { value: moment().format("MM"), label: moment().format("MMMM").charAt(0).toUpperCase() + moment().format("MMMM").slice(1)},
    year: { value: moment().format('YYYY'), label: moment().format('YYYY')},
};
export default handleActions(reducer, initialState)
