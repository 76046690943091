import Form from './BankAccountsForm';
import React, { Component } from 'react';
import LoadMask from 'Utils/LoadMask/LoadMask';

export default class BankAccountCrear extends Component {

    state = {
        editar: false,
        mensaje: 'Agregar cuenta bancaria',
        password: false,
    }

    componentWillMount(){
        if(this.props.match.params.id){
            this.props.detail(this.props.match.params.id);
            this.setState({
                    editar: true,
                    mensaje: 'Editar cuenta bancaria'
                });
        }
    }

    render() {

        const {
            create,
            update,
        } = this.props;

        const {
            updateData,
            cargando,
        } = this.props;

        return (
            <div className="content">
                <div className="m-auto row-fluid card-form">
                    <div className="table-decoration">
                        <LoadMask loading={cargando} blur_1>
                            {
                                this.state.editar ?
                                    <div className="fields">
                                        <div className="top-title">
                                            CUENTA BANCARIA
                                        </div>
                                        <Form
                                            editando={true}
                                            onSubmit={update}
                                            updateData={updateData}
                                        />
                                    </div>
                            :
                                <div className="fields">
                                    <div className="top-title">
                                        CREAR CUENTA BANCARIA
                                    </div>
                                    <Form onSubmit={create}/>
                                </div>
                            }
                        </LoadMask>
                    </div>
                </div>
            </div>
        )
    }
}
