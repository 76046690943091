import React from 'react';
import { Link } from 'react-router-dom';
import { Field, reduxForm } from 'redux-form';
import { validate, validators } from 'validate-redux-form';
import { renderField } from 'Utils/renderField/renderField';

const Form = props => {

   const {
       handleSubmit
    } = props;

    const view = window.location.href.includes("view");

    return(
        <form onSubmit={handleSubmit} className="py-3 px-5">
            <div className="px-md-5 d-flex justify-content-around flex-column flex-sm-row align-items-stretch align-items-sm-center">
                <div className="m-2">
                    <div className="d-flex justify-content-start flex-column flex-sm-row align-items-stretch align-items-sm-center">
                        <label htmlFor="bank_name" className="">Nombre del banco</label>
                    </div>
                    <div className=" d-flex justify-content-start flex-column flex-sm-row align-items-stretch align-items-sm-center">
                        <Field name="bank_name" autofocus disabled={view} component={renderField} type="text" className="form-control"/>
                    </div>
                </div>
                <div className="m-2">
                    <div className=" d-flex justify-content-start flex-column flex-sm-row align-items-stretch align-items-sm-center">
                        <label htmlFor="account_number" className="">Número de cuenta</label>
                    </div>
                    <div className=" d-flex justify-content-end flex-column flex-sm-row align-items-stretch align-items-sm-center">
                        <Field name="account_number" disabled={view} component={renderField} type="number" className="form-control"/>
                    </div>
                </div>
            </div>
            <div className="px-md-5 row-fluid d-flex justify-content-around flex-column flex-sm-row align-items-stretch align-items-sm-center">
                <div className="m-2">
                    <div className="d-flex justify-content-start flex-column flex-sm-row align-items-stretch align-items-sm-center">
                        <label htmlFor="owner" className="">Nombre la cuenta</label>
                    </div>
                    <div className="d-flex justify-content-start flex-column flex-sm-row align-items-stretch align-items-sm-center">
                        <Field name="owner" disabled={view} component={renderField} type="text" className="form-control"/>
                    </div>
                </div>
                <div className="m-2 invisible">
                    <div className="d-flex justify-content-start flex-column flex-sm-row align-items-stretch align-items-sm-center">
                        <Field name="owner" disabled={view}component={renderField} type="text" className="form-control"/>
                    </div>
                </div>
            </div>
            <div className="row-fluid">
                <div className="d-flex justify-content-center flex-column flex-sm-row align-items-stretch align-items-sm-center">
                    <Link className="btn btn-secondary m-3" to="/bank-accounts">{!view ? ("Cancelar") : ("Regresar")}</Link>
                   {!view && (<button type="submit" className="btn btn-primary m-3">Guardar</button>)}
                </div>
            </div>
        </form>
    )
};

const BankAccountForm = reduxForm({
   form: 'bankAccount',
   validate: data => {
       return validate(data, {
           'bank_name': validators.exists()('Campo requerido.'),
           'account_number': validators.exists()('Campo requerido.'),
           'owner': validators.exists()('Campo requerido.'),
       })
   }
})(Form);

export default BankAccountForm
