const url = 'referents';
const urlDoctors = 'doctors';
import { api } from "api";
import moment from "moment";
import Swal from 'sweetalert2';
import { push } from 'react-router-redux';
import { goBack } from 'react-router-redux';
import { initialize as initializeForm } from 'redux-form';
import { createActions, handleActions } from 'redux-actions';

const LOADER_DOCTORS = 'LOADER_DOCTORS';
const SET_DATA_DOCTORS = 'SET_DATA_DOCTORS';
const SET_PAGE_DOCTORS = 'SET_PAGE_DOCTORS';
const SET_UPDATE_DATA_DOCTORS ='SET_UPDATE_DATA_DOCTORS';
const SET_BUSCADOR_DOCTORS = 'SET_BUSCADOR_DOCTORS';
const SET_FILTRO_DOCTORS = 'SET_FILTRO_DOCTORS';

const MONETARY_ACCOUNT = 1;
const SAVING_ACCOUNT = 2;

const REFERENT_MEDIC = 1;
const SOCIAL_WORKER = 2;
const PROLASER_MEDIC = 3;

const referents_types = {
    medical: {value:REFERENT_MEDIC, label:'Médico Referente'},
    social: {value:SOCIAL_WORKER, label:'Trabajador Social'},
    prolaser: {value:PROLASER_MEDIC, label:'Médico Prolaser'}
};

const accounts_types = {
    monetary: {value:MONETARY_ACCOUNT, label:'Cuenta Monetaria'},
    saving: {value:SAVING_ACCOUNT, label:'Cuenta De Ahorro'}
};

const listar = (page = 1) =>  (dispatch, getStore) => {
    dispatch({type: LOADER_DOCTORS, cargando: true});
    const store = getStore();
    const search = store.doctor.buscador;
    const filtro = store.doctor.filtro_doctor;
    let params = {page, search};

    if(filtro){
        params[''] = filtro
    }


    api.get(`${url}/listDoctors/`, params).catch((error) => {
        dispatch({type: LOADER_DOCTORS, cargando: false});
        Swal(
            'Error',
            error.detail || 'Ha ocurrido un error, por favor vuelva a intentar.',
            'error'
        );
    }).then((data) => {

        if(data){
            dispatch({type: SET_DATA_DOCTORS, data});
            dispatch({type: SET_PAGE_DOCTORS, page});
        }
        dispatch({type: LOADER_DOCTORS, cargando: false});
    })
};


const detail = id => (dispatch, getState) =>{
    dispatch({type: LOADER_DOCTORS, cargando: true});
    api.get(`${url}/${id}`).catch((error) => {
        dispatch({type: LOADER_DOCTORS, cargando: false});
        if(error.statusCode  === 404){
            dispatch(push('/doctors'))
        }
    }).then((data) => {
        dispatch({type: LOADER_DOCTORS, cargando: false});
        if(data){

            const color = {value: data.color, label:"" , color: data.color }
            data.color = color;

            dispatch({type: SET_UPDATE_DATA_DOCTORS, updateData: data});
            dispatch(initializeForm('doctors', data))
        }
    })
}


const createInitialize = () => (dispatch) => {
    dispatch(initializeForm('referents', {'birthdate': moment(new Date()).format("YYYY-MM-DD")}))
}

const create = () => (dispatch, getStore) => {

    const formData = getStore().form.doctors.values;
    formData.referent_type = PROLASER_MEDIC;
    formData.color = formData.color.color;

    if(formData.partner === undefined){
        formData.partner = false;
    }

    dispatch({type: LOADER_DOCTORS, cargando: true})

    api.post(`${url}`, formData).then((data) => {

        dispatch({type: LOADER_DOCTORS, cargando: false})
        Swal('Éxito', 'Se ha creado el doctor.', 'success')
        .then(() => {
            dispatch(push('/doctors'))
        })
    }).catch((error) => {
        dispatch({type: LOADER_DOCTORS, cargando: false})
        Swal(
            'Error',
             error.detail || 'Ha ocurrido un error, por favor vuelva a intentar.',
            'error'
        );
    }).finally(() => {
        dispatch({type: LOADER_DOCTORS, cargando: false})
    });
};

const update = () => (dispatch, getStore) => {
    const formData = _.cloneDeep(getStore().form.doctors.values);
    formData.color = formData.color.color;

    if(formData.partner === undefined){
        formData.partner = false;
    }

    /*
    if(formData.partner === false){
        formData.fee = 0;
    }
    */

    if(formData.fee === undefined){
        formData.fee = 0;
    }

    dispatch({type: LOADER_DOCTORS, cargando: true});
    api.put(`${url}/${formData.id}`, formData)
    .then((data) => {
        dispatch({type: LOADER_DOCTORS, cargando: false});
        if(data){
            Swal(
                'Éxito',
                'Datos editados correctamente.',
                'success'
            ).then(() => {
                dispatch(push('/doctors'));
            })
        }
    })
    .catch((error) =>{
        dispatch({type: LOADER_DOCTORS, cargando: false});
        Swal(
            'Error',
             error.detail || 'Ha ocurrido un error, verifique los datos.',
            'error'
        )
        if(error.statusCode === 404){
            dispatch(push('/referents'));
        }
    })
}

const destroy = (id) => (dispatch) => {
    dispatch({type: LOADER_DOCTORS, cargando: true});
    //api.eliminar(`${url}/${id}`).catch((error) => {
    api.eliminar(`${urlDoctors}/${id}`).catch((error) => {
        dispatch({type: LOADER_DOCTORS, cargando: false});
        Swal('Error', 'No se ha logrado borrar el registro.', 'error')
            .then(() => {
                dispatch(listar(1));
        })
    }).then((data) => {
        dispatch({type: LOADER_DOCTORS, cargando: false});
        Swal('Éxito', 'Se ha eliminado correctamente.', 'success')
            .then(() => {
                dispatch(listar(1));
            })
    })
}

const search = (search) => (dispatch, getStore) => {
    dispatch({type: SET_BUSCADOR_DOCTORS, buscador: search});
    dispatch(listar(1));
};

const filtro = (filtro) => (dispatch, getStore) => {
    if (filtro == 0){
        filtro = null
    }
    dispatch({type: SET_FILTRO_DOCTORS, filtro_doctors: filtro});
    dispatch(listar(1));
};

export const actions = {
    listar,
    detail,
    create,
    update,
    destroy,
    search,
    filtro,
    createInitialize,
};

export const reducer = {
    [LOADER_DOCTORS]: (state, { cargando }) => {
        return {...state, cargando }
    },
    [SET_DATA_DOCTORS]: (state, { data }) => {
        return {...state, data }
    },
    [SET_PAGE_DOCTORS]: (state, { page }) => {
        return {...state, page }
    },
    [SET_UPDATE_DATA_DOCTORS]: (state, { updateData }) => {
        return {...state, updateData }
    },
    [SET_BUSCADOR_DOCTORS]: (state, { buscador }) => {
        return {...state, buscador }
    },
    [SET_FILTRO_DOCTORS]: (state, { filtro_referents }) => {
        return {...state, filtro_doctores }
    },
}
export const initialState = {
    cargando: false,
    page: 1,
    data: {
        count: 0,
        next: null,
        previous: null,
        results: [],
    },
    buscador: '',
    filtro_doctors: null,
    updateData: {},
};

export default handleActions(reducer, initialState)
