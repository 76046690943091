import React, { Component } from 'react';
import "./toolbar.css";
import SearchChange from "./SearchChange"
import SearchSelect from "./Select";

export default class ToolbarSearchSelect extends Component {
    render() {
        return (
            <div className="w-100 d-flex flex-row justify-content-between flex-wrap">
                {this.props.titulo &&                    
                    <div className="d-flex flex-row w-25">
                        <div className="titulo d-flex align-items-center">
                            <span className="ml-2 text-uppercase text-titulo"><strong>{this.props.titulo}</strong></span>
                        </div>
                    </div>                    
                }

                <div className='d-flex flex-row flex-wrap justify-content-end'>
                    <div className={this.props.titulo ? "d-flex flex-row w-50 col-4 text-right search" : "d-flex flex-row w-50 text-right search"}>
                        {(this.props.buscar !== undefined) && (
                            <SearchChange 
                                buscar={this.props.buscar} 
                                buscador={this.props.buscador}
                                autofocus={this.props.autofocus}
                                date={this.props.date}
                            />
                        )}
                    </div>

                    <div className="d-flex flex-row align-items-center justify-content-around w-50">
                        {(this.props.buscarSelect !== undefined) && (
                            <React.Fragment>
                                <span className="mr-2">filtrar por:</span>
                                <div className="d-flex flex-row w-100 row-fluid">                                                            
                                    <SearchSelect 
                                        { ...this.props} 
                                        buscar={this.props.buscarSelect}
                                        buscador={this.props.buscadorSelect}
                                        autofocus={this.props.autofocus}
                                    />                                
                                </div>
                            </React.Fragment>
                        )}
                    </div>
                </div>
            </div>
        )
    }
}
