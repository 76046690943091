import { api } from 'api';
import Swal from 'sweetalert2';
import { push } from 'react-router-redux';
import { handleActions } from 'redux-actions';
import moment from "moment";

const LOADER_BITACORA = 'LOADER_BITACORA';
const SET_DATA_BITACORA = 'SET_DATA_BITACORA';
const SET_PAGE_BITACORA = 'SET_PAGE_BITACORA';
const SET_UPDATE_DATA_BITACORA = 'SET_UPDATE_DATA_BITACORA';
const SET_BUSCADOR_BITACORA = 'SET_BUSCADOR_BITACORA';
const SET_FILTRO_BITACORA = 'SET_FILTRO_BITACORA';
const SET_INICIAL_DATE = 'SET_INICIAL_DATE_BITACORA';
const SET_FINAL_DATE = 'SET_FINAL_DATE_BITACORA';

const url = 'bitacora';

const listar = (page = 1) =>  (dispatch, getStore) => {
    dispatch({type: LOADER_BITACORA, cargando: true});
    const store = getStore();
    const action = store.bitacora.buscador;
    const filtro = store.bitacora.filtro_roles;
    const initial_date = store.bitacora.initial_date;
    const final_date = store.bitacora.final_date;
    let params = {page, action, initial_date, final_date };
    if(filtro){
        params[''] = filtro
    }

    api.get(`${url}`, params).catch((error) => {
        dispatch({type: LOADER_BITACORA, cargando: false});
        Swal(
            'Error',
            error.detail || 'Ha ocurrido un error, por favor vuelva a intentar.',
            'error'
        );
    }).then((data) => {
        if(data){
            dispatch({type: SET_DATA_BITACORA, data});
            dispatch({type: SET_PAGE_BITACORA, page});
        }
        dispatch({type: LOADER_BITACORA, cargando: false});
    })
};

const detail = id => (dispatch, getState) =>{
    dispatch({type: LOADER_BITACORA, cargando: true});
    api.get(`${url}/${id}`).catch((error) => {
        dispatch({type: LOADER_BITACORA, cargando: false});
        if(error.statusCode === 404){
            dispatch(push('/bitacora'))
        }
    }).then((data) => {
        dispatch({type: LOADER_BITACORA, cargando: false});
        if(data){
            dispatch({type: SET_UPDATE_DATA_BITACORA, updateData: data});
        }
    })
};

const search = (search) => (dispatch) => {
    dispatch({type: SET_BUSCADOR_BITACORA, buscador: search});
    dispatch(listar(1));
};

const set_initial_date = (value) => (dispatch) => {
    dispatch({type: SET_INICIAL_DATE, initial_date: value});
    //dispatch(listar());
};

const set_final_date = (value) => (dispatch) => {
    dispatch({type: SET_FINAL_DATE, final_date: value});
    //dispatch(listar());
};


export const actions = {
    listar,
    detail,
    search,
    set_initial_date,
    set_final_date
};

export const reducer = {
    [LOADER_BITACORA]: (state, { cargando }) => {
        return {...state, cargando }
    },
    [SET_DATA_BITACORA]: (state, { data }) => {
        return {...state, data }
    },
    [SET_PAGE_BITACORA]: (state, { page }) => {
        return {...state, page }
    },
    [SET_UPDATE_DATA_BITACORA]: (state, { updateData }) => {
        return {...state, updateData }
    },
    [SET_BUSCADOR_BITACORA]: (state, { buscador }) => {
        return {...state, buscador }
    },
    [SET_FILTRO_BITACORA]: (state, { filtro_rol }) => {
        return {...state, filtro_rol }
    },
    [SET_INICIAL_DATE]: (state, { initial_date }) => {
        return{
            ...state,
            initial_date
        }
    },
    [SET_FINAL_DATE]: (state, { final_date }) => {
        return{
            ...state,
            final_date
        }
    },
};

export const initialState = {
    cargando: false,
    page: 1,
    data: {
        count: 0,
        next: null,
        previous: null,
        results: [],
    },
    buscador: '',
    filtro_roles: null,
    updateData: {
        action: "",
        detail: "{}",
        prev_detail: "{}",
    },
    initial_date: moment().date(1).format("DD/MM/YYYY"),
    final_date: moment().format("DD/MM/YYYY"),
};

export default handleActions(reducer, initialState)
