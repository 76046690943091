import React, {Component} from 'react';
import { Link } from 'react-router-dom';
import {departamentos, municipios} from "../../../../utility/variables";
import './patient';
export default class Patient extends Component {
    state = {
        tab: 'data',
    }

    changeTab = (tab) => {
        this.setState({tab});
    }

    render() {
        const {patient, last_record} = this.props;

        const left_tab = this.state.tab === 'data' ? 'patient-section-tab' : '';
        const right_tab = this.state.tab === 'history' ? 'patient-section-tab' : '';

        console.log("LAST RECORD: ", last_record);

        return (
            <div 
                //className="d-flex flex-column"
                className='d-flex flex-column' style={{ width: '100%' }}
            >
                <div 
                    //className='d-flex flex-row'
                    className='d-flex flex-row'
                >
                    <div className="text-primary font-weight-bold my-3" onClick={()=>this.changeTab('data')}>
                        <button className={'patient-tab-left ' + left_tab}>DATOS DEL PACIENTE</button>
                    </div>
                    <div className="text-primary font-weight-bold my-3" onClick={()=>this.changeTab('history')}>
                        <button className={'patient-tab-right '  + right_tab}>HISTORIAL CLÍNICO</button>
                    </div>
                </div>
                <div className='d-flex' style={{ width: '100%', justifyContent: "space-between" }}>
                {this.state.tab === 'data' ?
                    <React.Fragment>
                        <div className='d-flex flex-column'>
                            <div className="pb-1">
                                <strong className='mt-3'>Nombre:</strong>
                                {patient.first_name} {patient.last_name}
                            </div>                            
                            <div className="pb-1">
                                <strong className='mt-3'>Nacimiento:</strong>
                                {patient.birthdate ? patient.birthdate : "---"}
                            </div>
                        </div>
                        <center className='mt-2'>
                            <a 
                                href={`/#/medical-history/${patient.id}`}
                                target="_blank"
                                className="btn btn-success mb-3 align-self-start px-1 py-0"
                            >
                                Ver historial clínico
                            </a>                            
                        </center>

                        {/*
                        <strong className='mt-3'>Género:</strong>
                        <div className="border-bottom pb-1">{patient.gender === 1 ? "Masculino" : "Femenino"}</div>
                        <strong className='mt-3'>Profesión:</strong>
                        <div className="border-bottom pb-1">{patient.profession ? patient.profession : "---"}</div>
                        <strong className='mt-3'>Nacionalidad:</strong>
                        <div className="border-bottom pb-1">{patient.nationality ? patient.nationality : "---"}</div>
                        <strong className='mt-3'>Departamento:</strong>
                        <div className="border-bottom pb-1">{patient.departamento ? _.find(departamentos, {value: patient.departamento}).label : "---"}</div>
                        <strong className='mt-3'>Municipio:</strong>
                        <div className="border-bottom pb-1">{patient.municipio ? _.find(municipios, {value: patient.municipio}).label : "---"}</div>
                        <strong className='mt-3'>Dirección:</strong>
                        <div className="border-bottom pb-1">{patient.address ? patient.address : "---"}</div>
                        <strong className='mt-3'>DPI:</strong>
                        <div className="border-bottom pb-1">{patient.dpi ? patient.dpi : "---"}</div>
                        <strong className='mt-3'>Correo:</strong>
                        <div className="border-bottom pb-1">{patient.email ? patient.email : "---"}</div>
                        <strong className='mt-3'>Teléfono:</strong>
                        <div className="border-bottom pb-1">{patient.phone ? patient.phone : "---"}</div>
                        <strong className='mt-3'>Referente:</strong>
                        <div className="border-bottom pb-1">{patient.referred_by ? patient.referred_by.name : ""}</div>
                        */}
                    </React.Fragment>
                :
                    <React.Fragment>
                        {last_record !== null ?
                        <React.Fragment>
                            <strong className='text-primary pt-3'>MD</strong>
                            {last_record.md.map((md) => (
                                <React.Fragment>
                                <strong>Diagnostico</strong>
                                <div className='border-bottom pb-1'>{md.diagnostico.label}</div>
                                <strong>Tratamiento</strong>
                                <div className='border-bottom pb-1'>{md.tratamiento.label}</div>
                                </React.Fragment>
                            ))}

                            <strong className='text-primary pt-3'>QX</strong>
                            {last_record.qx.map((qx) => (
                                <React.Fragment>
                                    <strong>Diagnostico</strong>
                                    <div className='border-bottom pb-1'>{qx.cirugia.label}</div>
                                </React.Fragment>
                            ))}
                            <strong>Notas</strong>
                            <div className='border-bottom pb-1'>{last_record.qx_note}</div>

                            <strong className='text-primary pt-3'>ALG</strong>
                            {last_record.alg.map((alg) => (
                                <React.Fragment>
                                    <strong>Diagnostico</strong>
                                    <div className='border-bottom pb-1'>{alg.alergia.label}</div>
                                </React.Fragment>
                            ))}

                            <strong>Notas</strong>
                            <div className='border-bottom pb-1'>{last_record.alg_note}</div>

                            <strong className='text-primary pt-3'>TRX</strong>
                            {last_record.trx.map((trx) => (
                                <React.Fragment>
                                    <strong>Diagnostico</strong>
                                    <div className='border-bottom pb-1'>{trx.trauma.label}</div>
                                </React.Fragment>
                            ))}

                            <strong>Notas</strong>
                            <div className='border-bottom pb-1'>{last_record.trx_note}</div>

                            <strong className='text-primary pt-3'>FAM</strong>
                            {last_record.fam.map((fam) => (
                                <React.Fragment>
                                    <strong>Diagnostico</strong>
                                    <div className='border-bottom pb-1'>{fam.diagnostico.label}</div>
                                    <strong>Familiar</strong>
                                    <div className='border-bottom pb-1'>{fam.tratamiento.label}</div>
                                </React.Fragment>
                            ))}

                            <strong>Notas</strong>
                            <div className='border-bottom pb-1'>{last_record.fam_note}</div>
                        </React.Fragment>
                        :
                                <span>No tiene historial previo en el sistema</span>
                        }
                    </React.Fragment>
                }
                </div>
            </div>
        )
    }
}
