const url_ingresos = 'reportes/bodega';
import { api } from 'api';
import Swal from 'sweetalert2';
import { push } from 'react-router-redux';
import { goBack } from 'react-router-redux';
import { initialize as initializeForm } from 'redux-form';
import { createActions, handleActions } from 'redux-actions';
import moment from "moment";

const LOADER_REPORTES = 'LOADER_REPORTES';

// Reporte ingresos
const SET_DATA_INGRESOS = 'SET_DATA_INGRESOS';
const SET_PAGE_INGRESOS = 'SET_PAGE_INGRESOS';
const SET_BUSCADOR_INGRESOS = 'SET_BUSCADOR_INGRESOS';
const SET_FILTRO_INGRESOS = 'SET_FILTRO_INGRESOS';
const SET_INICIAL_DATE_INGRESOS = 'SET_INICIAL_DATE_INGRESOS';
const SET_FINAL_DATE_INGRESOS = 'SET_FINAL_DATE_INGRESOS';

// Reporte bajas
const SET_DATA_BAJAS = 'SET_DATA_BAJAS';
const SET_PAGE_BAJAS = 'SET_PAGE_BAJAS';
const SET_BUSCADOR_BAJAS = 'SET_BUSCADOR_BAJAS';
const SET_FILTRO_BAJAS = 'SET_FILTRO_BAJAS';
const SET_INICIAL_DATE_BAJAS = 'SET_INICIAL_DATE_BAJAS';
const SET_FINAL_DATE_BAJAS = 'SET_FINAL_DATE_BAJAS';

// Reporte AJUSTES
const SET_DATA_AJUSTES = 'SET_DATA_AJUSTES';
const SET_PAGE_AJUSTES = 'SET_PAGE_AJUSTES';
const SET_BUSCADOR_AJUSTES = 'SET_BUSCADOR_AJUSTES';
const SET_FILTRO_AJUSTES = 'SET_FILTRO_AJUSTES';
const SET_INICIAL_DATE_AJUSTES = 'SET_INICIAL_DATE_AJUSTES';
const SET_FINAL_DATE_AJUSTES = 'SET_FINAL_DATE_AJUSTES';

// PARA EL REPORTE DE INGRESOS 
const listarIngresos = (page = 1) =>  (dispatch, getStore) => {
    dispatch({type: LOADER_REPORTES, cargando: true});
    const store = getStore();
    const search = store.reporte_bodega.buscador_ingresos;
    const filtro = store.reporte_bodega.filtro_ingresos;

    const initial_date = store.reporte_bodega.initial_date_ingresos;
    const final_date = store.reporte_bodega.final_date_ingresos;

    let params = {page, search, initial_date, final_date};
    if(filtro){
        params[''] = filtro
    }

    api.get(`${url_ingresos}/ingresos`, params).catch((error) => {
        dispatch({type: LOADER_REPORTES, cargando: false});
        Swal(
            'Error',
            error.detail || 'Ha ocurrido un error, por favor vuelva a intentar.',
            'error'
        );
    }).then((data) => {
        if(data){
            dispatch({type: SET_DATA_INGRESOS, data_ingresos: data});
            dispatch({type: SET_PAGE_INGRESOS, page_ingresos: page});
        }
        dispatch({type: LOADER_REPORTES, cargando: false});
    })
};

const searchIngresos = (search) => (dispatch) => {
    dispatch({type: SET_BUSCADOR_INGRESOS, buscador_ingresos: search});
    dispatch(listarIngresos(1));
};

const filtroIngresos = (filtro) => (dispatch, getStore) => {
    if (filtro == 0){
        filtro = null
    }
    dispatch({type: SET_FILTRO_INGRESOS, filtro_ingresos: filtro});
    dispatch(listarIngresos(1));
};

const setFinalDateIngresos = (value) => (dispatch) => {    
    dispatch({type: SET_FINAL_DATE_INGRESOS, final_date_ingresos: value});
    dispatch(listarIngresos(1));
}

const setInitialDateIngresos = (value) => (dispatch) => {    
    dispatch({type: SET_INICIAL_DATE_INGRESOS, initial_date_ingresos: value});
    dispatch(listarIngresos(1));
}
// ...

// PARA EL REPORTE DE BAJAS
const listarBajas = (page = 1) =>  (dispatch, getStore) => {
    dispatch({type: LOADER_REPORTES, cargando: true});
    const store = getStore();
    const search = store.reporte_bodega.buscador_bajas;
    const filtro = store.reporte_bodega.filtro_bajas;

    const initial_date = store.reporte_bodega.initial_date_bajas;
    const final_date = store.reporte_bodega.final_date_bajas;

    let params = {page, search, initial_date, final_date};
    if(filtro){
        params[''] = filtro
    }

    api.get(`${url_ingresos}/bajas`, params).catch((error) => {
        dispatch({type: LOADER_REPORTES, cargando: false});
        Swal(
            'Error',
            error.detail || 'Ha ocurrido un error, por favor vuelva a intentar.',
            'error'
        );
    }).then((data) => {
        if(data){
            dispatch({type: SET_DATA_BAJAS, data_bajas: data});
            dispatch({type: SET_PAGE_BAJAS, page_bajas: page});
        }
        dispatch({type: LOADER_REPORTES, cargando: false});
    })
};

const searchBajas = (search) => (dispatch) => {
    dispatch({type: SET_BUSCADOR_BAJAS, buscador_bajas: search});
    dispatch(listarBajas(1));
};

const filtroBajas = (filtro) => (dispatch, getStore) => {
    if (filtro == 0){
        filtro = null
    }
    dispatch({type: SET_FILTRO_BAJAS, filtro_bajas: filtro});
    dispatch(listarBajas(1));
};

const setFinalDateBajas = (value) => (dispatch) => {    
    dispatch({type: SET_FINAL_DATE_BAJAS, final_date_bajas: value});
    dispatch(listarBajas(1));
}

const setInitialDateBajas = (value) => (dispatch) => {    
    dispatch({type: SET_INICIAL_DATE_BAJAS, initial_date_bajas: value});
    dispatch(listarBajas(1));
}
// ...

// PARA EL REPORTE DE AJUSTES
const listarAjustes = (page = 1) =>  (dispatch, getStore) => {
    dispatch({type: LOADER_REPORTES, cargando: true});
    const store = getStore();
    const search = store.reporte_bodega.buscador_ajustes;
    const filtro = store.reporte_bodega.filtro_ajustes;

    const initial_date = store.reporte_bodega.initial_date_ajustes;
    const final_date = store.reporte_bodega.final_date_ajustes;

    let params = {page, search, initial_date, final_date};
    if(filtro){
        params[''] = filtro
    }

    api.get(`${url_ingresos}/ajustes`, params).catch((error) => {
        dispatch({type: LOADER_REPORTES, cargando: false});
        Swal(
            'Error',
            error.detail || 'Ha ocurrido un error, por favor vuelva a intentar.',
            'error'
        );
    }).then((data) => {
        if(data){
            dispatch({type: SET_DATA_AJUSTES, data_ajustes: data});
            dispatch({type: SET_PAGE_AJUSTES, page_ajustes: page});
        }
        dispatch({type: LOADER_REPORTES, cargando: false});
    })
};

const searchAjustes = (search) => (dispatch) => {
    dispatch({type: SET_BUSCADOR_AJUSTES, buscador_ajustes: search});
    dispatch(listarAjustes(1));
};

const filtroAjustes = (filtro) => (dispatch, getStore) => {
    if (filtro == 0){
        filtro = null
    }
    dispatch({type: SET_FILTRO_AJUSTES, filtro_ajustes: filtro});
    dispatch(listarAjustes(1));
};

const setFinalDateAjustes = (value) => (dispatch) => {    
    dispatch({type: SET_FINAL_DATE_AJUSTES, final_date_ajustes: value});
    dispatch(listarAjustes(1));
}

const setInitialDateAjustes = (value) => (dispatch) => {    
    dispatch({type: SET_INICIAL_DATE_AJUSTES, initial_date_ajustes: value});
    dispatch(listarAjustes(1));
}
// ...

export const actions = {
    // Ingresos
    listarIngresos,    
    searchIngresos,
    filtroIngresos,
    setFinalDateIngresos,
    setInitialDateIngresos,
    // Bajas
    listarBajas,    
    searchBajas,
    filtroBajas,
    setFinalDateBajas,
    setInitialDateBajas,
    // Ajustes
    listarAjustes,    
    searchAjustes,
    filtroAjustes,
    setFinalDateAjustes,
    setInitialDateAjustes,
};

export const reducer = {
    [LOADER_REPORTES]: (state, { cargando }) => {
        return {...state, cargando }
    },

    // PARA EL REPORTE DE INGRESOS A BODEGA
    [SET_DATA_INGRESOS]: (state, { data_ingresos }) => {
        return {...state, data_ingresos }
    },
    [SET_PAGE_INGRESOS]: (state, { page_ingresos }) => {
        return {...state, page_ingresos }
    },    
    [SET_BUSCADOR_INGRESOS]: (state, { buscador_ingresos }) => {
        return {...state, buscador_ingresos }
    },
    [SET_FILTRO_INGRESOS]: (state, { filtro_ingresos }) => {
        return {...state, filtro_ingresos }
    },
    [SET_INICIAL_DATE_INGRESOS]: (state, { initial_date_ingresos }) => {
        return{
            ...state,
            initial_date_ingresos
        }
    },
    [SET_FINAL_DATE_INGRESOS]: (state, { final_date_ingresos }) => {
        return{
            ...state,
            final_date_ingresos
        }
    },    
    //....
    // PARA EL REPORTE DE BAJAS A BODEGA
    [SET_DATA_BAJAS]: (state, { data_bajas }) => {
        return {...state, data_bajas }
    },
    [SET_PAGE_BAJAS]: (state, { page_bajas }) => {
        return {...state, page_bajas }
    },    
    [SET_BUSCADOR_BAJAS]: (state, { buscador_bajas }) => {
        return {...state, buscador_bajas }
    },
    [SET_FILTRO_BAJAS]: (state, { filtro_bajas }) => {
        return {...state, filtro_bajas }
    },
    [SET_INICIAL_DATE_BAJAS]: (state, { initial_date_bajas }) => {
        return{
            ...state,
            initial_date_bajas
        }
    },
    [SET_FINAL_DATE_BAJAS]: (state, { final_date_bajas }) => {
        return{
            ...state,
            final_date_bajas
        }
    },    
    //....
    // PARA EL REPORTE DE AJUSTES A BODEGA
    [SET_DATA_AJUSTES]: (state, { data_ajustes }) => {
        return {...state, data_ajustes }
    },
    [SET_PAGE_AJUSTES]: (state, { page_ajustes }) => {
        return {...state, page_ajustes }
    },    
    [SET_BUSCADOR_AJUSTES]: (state, { buscador_ajustes }) => {
        return {...state, buscador_ajustes }
    },
    [SET_FILTRO_AJUSTES]: (state, { filtro_ajustes }) => {
        return {...state, filtro_ajustes }
    },
    [SET_INICIAL_DATE_AJUSTES]: (state, { initial_date_ajustes }) => {
        return{
            ...state,
            initial_date_ajustes
        }
    },
    [SET_FINAL_DATE_AJUSTES]: (state, { final_date_ajustes }) => {
        return{
            ...state,
            final_date_ajustes
        }
    },    
    //....
};

export const initialState = {
    cargando: false,
    // Para el reporte de ingresos a bodega
    page_ingresos: 1,
    data_ingresos: {
        count: 0,
        next: null,
        previous: null,
        results: [],
    },
    buscador_ingresos: '',
    filtro_ingresos: null,    
    //...
    initial_date_ingresos: moment().date(1).format("DD/MM/YYYY"),
    final_date_ingresos: moment().format("DD/MM/YYYY"),
    // Para el reporte de bajas a bodega
    page_bajas: 1,
    data_bajas: {
        count: 0,
        next: null,
        previous: null,
        results: [],
    },
    buscador_bajas: '',
    filtro_bajas: null,    
    //...
    initial_date_bajas: moment().date(1).format("DD/MM/YYYY"),
    final_date_bajas: moment().format("DD/MM/YYYY"),
    // Para el reporte de ajustes a bodega
    page_ajustes: 1,
    data_ajustes: {
        count: 0,
        next: null,
        previous: null,
        results: [],
    },
    buscador_ajustes: '',
    filtro_bajas: null,    
    //...
    initial_date_ajustes: moment().date(1).format("DD/MM/YYYY"),
    final_date_ajustes: moment().format("DD/MM/YYYY"),
};

export default handleActions(reducer, initialState)