import React, { Component } from 'react';
import classNames from 'classnames';

import Form from './PatientForm';
import LoadMask from 'Utils/LoadMask/LoadMask';

export default class BankAccountCrear extends Component {

    state = {
        editar: false,
        mensaje: 'Agregar cuenta bancaria',
        password: false,
    };

    componentWillMount(){

        const {
            createInitialize
        } = this.props;

        createInitialize();

        if (this.props.match.params.id) {
            this.props.detail(this.props.match.params.id);
            this.setState({
                editar: true,
                mensaje: 'Editar cuenta bancaria',
            });
        }
    }

    render() {
        const {
            create,
            update,
            cargando,
            updateData,
            municipios,
            isNavBarOpen,
            validateNit,
            setDataName,
            getAfiliationData
        } = this.props;

        return (
            <div className={classNames('content', { 'content-min': isNavBarOpen })}>
                <div className="m-auto row-fluid card-form">
                    <div className="table-decoration">
                        <LoadMask loading={cargando} blur_1>
                            {
                                this.state.editar ?
                                    <div className="fields">
                                        <div className="top-title">
                                            PACIENTES
                                        </div>
                                        <Form
                                            getAfiliationData={getAfiliationData}
                                            municipios={municipios}
                                            onSubmit={update}
                                            updateData={updateData}
                                            validateNit={validateNit}
                                            setDataName={setDataName}
                                            editando={true}
                                        />
                                    </div>
                                :
                                    <div className="fields">
                                        <div className="top-title">
                                            CREAR PACIENTE
                                        </div>
                                        <Form
                                            getAfiliationData={getAfiliationData}
                                            municipios={municipios}
                                            onSubmit={create}
                                            validateNit={validateNit}
                                            setDataName={setDataName}
                                        />
                                    </div>
                            }
                        </LoadMask>
                    </div>
                </div>
            </div>
        )
    }
}
