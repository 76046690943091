import React from 'react';
import { Link } from 'react-router-dom';
import { Field, reduxForm } from 'redux-form';
import { validate, validators } from 'validate-redux-form';
import { renderField, renderSearchSelect } from 'Utils/renderField/renderField';
import { api } from "api";

let entities=[];
const getEntity = (search) =>{
    return api.get("financial_entities", {search}).catch((error) => {})
        .then((data) => {
            if (data){
                entities = [];
                data.results.forEach(x=>{
                    entities.push({value: x.id, label: x.usuario_fel});
                })

                return entities;
            }
            else{
                return []
            }
        })
        .catch(e => {
            return []
        })
}

const getSubsidiaries = (search)  =>{
    return api.get("subsidiaries",{search})
        .then((data) => {
            if (data){
                const subsidiaries = data.results.map(x => (
                    {value: x.id, label: x.name}
                ));
                return subsidiaries
            }
            else{
                return []
            }
        })
        .catch(e => {
            return []
        })
}

const Form = props => {

   const {
       handleSubmit
    } = props;

    const view = window.location.href.includes("view");

    return(
        <form onSubmit={handleSubmit} className="py-3 px-5">
            <div className="px-md-5 d-flex justify-content-around flex-column flex-sm-row align-items-stretch align-items-sm-center">
                <div className="m-2">
                    <div className="d-flex justify-content-start flex-column flex-sm-row align-items-stretch align-items-sm-center">
                        <label htmlFor="name" className="">Nombre</label>
                    </div>
                    <div className=" d-flex justify-content-start flex-column flex-sm-row align-items-stretch align-items-sm-center">
                        <Field name="name" disabled={view} autofocus component={renderField} type="text" className="form-control"/>
                    </div>
                </div>
                <div className="m-2">
                    <div className=" d-flex justify-content-start flex-column flex-sm-row align-items-stretch align-items-sm-center">
                        <label htmlFor="description" className="">Descripción</label>
                    </div>
                    <div className=" d-flex justify-content-end flex-column flex-sm-row align-items-stretch align-items-sm-center">
                        <Field name="description" disabled={view} component={renderField} type="text" className="form-control"/>
                    </div>
                </div>
            </div>
            <div className="px-md-5 mb-2 d-flex justify-content-around flex-column flex-sm-row align-items-stretch align-items-sm-center">
                <div className="m-0">
                    <div className="d-flex justify-content-start flex-column flex-sm-row align-items-stretch align-items-sm-center">
                        <label htmlFor="subsidiary" className="">Sucursal</label>
                    </div>
                    <div className=" d-flex justify-content-start flex-column flex-sm-row align-items-stretch align-items-sm-center">
                        <Field
                            disabled={view}
                            name="subsidiary"
                            valueKey="value"
                            labelKey="label"
                            label="referent"
                            component={renderSearchSelect}
                            loadOptions={getSubsidiaries}
                            className="no-border"
                        />
                    </div>
                </div>
                <div className="">
                    <div className="d-flex justify-content-start flex-column flex-sm-row align-items-stretch align-items-sm-center">
                        <label htmlFor="financial_entity" className="">Entidad financiera</label>
                    </div>
                    <div className=" d-flex justify-content-start flex-column flex-sm-row align-items-stretch align-items-sm-center">
                        <Field
                            name="financial_entity"
                            valueKey="value"
                            labelKey="label"
                            label="financial_entity"
                            component={renderSearchSelect}
                            loadOptions={getEntity}
                            className="no-border"
                            disabled={view}
                            className="caregory-referent-switch"
                        />
                    </div>
                </div>
            </div>
            <div className="row-fluid">
                <div className="d-flex justify-content-center flex-column flex-sm-row align-items-stretch align-items-sm-center">
                    <Link className="btn btn-secondary m-3" to="/pos">{!view ? ("Cancelar") : ("Regresar")}</Link>
                   {!view && (<button type="submit" className="btn btn-primary m-3">Guardar</button>)}
                </div>
            </div>
        </form>
    )
};

const PosForm = reduxForm({
    form: 'pos',
    validate: data => {
        return validate(data, {
            'name': validators.exists()('Campo requerido.'),
            'description': validators.exists()('Campo requerido.'),
            'subsidiary': validators.exists()('Campo requerido.'),
            'financial_entity': validators.exists()('Campo requerido.'),
       })
    }
})(Form);

export default PosForm
