import React, { Component } from 'react';
import LoadMask from 'Utils/LoadMask/LoadMask';
import Form from './UsersForms';

export default class UsersCrear extends Component {

    state = {
        editar: false,
        mensaje: 'Agregar usuario',
        password: false,
    }

    componentWillMount(){
        this.props.getDoctors();
        if(this.props.match.params.id){
            this.props.detail(this.props.match.params.id);
            this.setState({
                editar: true,
                mensaje: 'Editar usuario'
            });
        }
    }

    verPass = () => {
        this.setState({
            password: !this.state.password
        })
    }

    render() {

        const {
            create,
            update,
            deleteReferente,
        } = this.props;

        const {
            updateData,
            cargando,
            doctors,
        } = this.props;

        return (
            <div className="content">
                <div className="m-auto row-fluid card-form">
                    <div className="table-decoration">
                        <LoadMask loading={cargando} blur_1>
                            {
                                this.state.editar ?
                                    <div>
                                        <div className="top-title">
                                            USUARIO
                                        </div>
                                        <Form
                                            verPass={this.verPass}
                                            mostrar_pass={this.state.password}
                                            onSubmit={update}
                                            updateData={updateData}
                                            editando={true}
                                            doctors={doctors}
                                            deleteReferente={deleteReferente}
                                        />
                                    </div>
                            :
                                <div>
                                    <div className="top-title">
                                        CREAR USUARIO
                                    </div>
                                    <Form onSubmit={create} mostrar_pass create doctors={doctors}/>
                                </div>
                            }
                        </LoadMask>
                    </div>
                </div>
            </div>
        )
    }
}
