import React, { Component } from 'react';
import {renderEntityRow, renderCommissionRow, renderItemRow, renderCategoryPrices, getOptions, getItems, getUsers, getEntity} from './FormComp';
import { Link } from 'react-router-dom';
import { Field, reduxForm, FieldArray, formValueSelector } from 'redux-form';
import { renderField, renderSearchSelect, renderNumber, renderSwitch, renderCurrency } from 'Utils/renderField/renderField';
import validate from './validation';
import { connect } from 'react-redux';

const TYPE_PRODUCT = 1
const TYPE_SERVICE = 2
const TYPE_PACKAGE = 3

const SERVICE = 'service'
const PRODUCT = 'product'
const FRACTION = 'fraction'

const PACKAGE_STR = 'package'
const SERVICE_STR = 'service/product'

class tabsComponent extends Component{
    state = {
        tab_active: "general"
    }

    changeTab(tabName){
        this.setState({tab_active: tabName});
    }

    render(){
        const {
            alert,
            service_type,
        } = this.props;

        const edit = window.location.href.includes("edit");
        const view = window.location.href.includes("view");

        const {
            type,
            updateData,
            handleSubmit
        } = this.props;

        console.log("TYPE IN TABAS COMPONENTE: ", type);

        let tgeneral = (this.state.tab_active === "general") ? "tp-active-2" : "tp-inactive-2";
        let tentidad = (this.state.tab_active === "entidad") ? "tp-active-2" : "tp-inactive-2";
        let tcomision = (this.state.tab_active === "comision") ? "tp-active-2" : "tp-inactive-2";
        let tprecio = (this.state.tab_active === "precio") ? "tp-active-2" : "tp-inactive-2";
        let tservicio = (this.state.tab_active === "servicio") ? "tp-active-2" : "tp-inactive-2";

        let vgeneral = (this.state.tab_active === "general") ? "" : " hidden";
        let ventidad = (this.state.tab_active === "entidad") ? "" : " hidden";
        let vcomision = (this.state.tab_active === "comision") ? "" : " hidden";
        let vprecio = (this.state.tab_active === "precio") ? "" : " hidden";
        let vservicio = (this.state.tab_active === "servicio") ? "" : " hidden";

        return(
            <div className="ml-4 mr-4 d-flex flex-column h-75 justify-content-center">
                <div className={"d-flex flex-wrap flex-row justify-content-center align-items-end w-100 b-white"}>
                    <div className={"d-flex w-15 tab-payment tp2 justify-content-center align-items-center " + tgeneral} onClick={() => this.changeTab("general")}>DATOS GENERALES</div>
                    {
                        type == PACKAGE_STR &&
                        <div className={"d-flex w-15 tab-payment tp2 justify-content-center align-items-center " + tservicio} onClick={() => this.changeTab("servicio")}>SERVICIOS</div>

                    }


                    <div className={"d-flex w-15 tab-payment tp2 justify-content-center align-items-center " + tentidad} onClick={() => this.changeTab("entidad")}>ENTIDADES</div>
                    <div className={"d-flex w-15 tab-payment tp2 justify-content-center align-items-center " + tcomision} onClick={() => this.changeTab("comision")}>COMISIONES</div>
                    <div className={"d-flex w-15 tab-payment tp2 justify-content-center align-items-center " + tprecio} onClick={() => this.changeTab("precio")}>PRECIOS</div>
                </div>
                <form onSubmit={handleSubmit} className="my-3 ml-3 mr-3 inside-card">
                <div className={"d-flex flex-column flex-wrap justify-content-center align-items-center p-3"}>
                    {
                        //(this.state.tab_active === "general") &&
                        <React.Fragment>
                        <div className={"d-flex flex-column justify-content-center align-items-center w-100" + vgeneral}>
                            <div className="d-flex flex-row justify-content-start" style={{width:"65%"}}>
                                {
                                type !== PACKAGE_STR &&

                                <div className="d-flex justify-content-start">
                                    <div className="radio d-flex">
                                        <label htmlFor="service_type" className="radio-inline mr-5 d-flex align-items-center">
                                            <Field name="service_type" disabled={ edit || view } component={renderField} type="radio" value={SERVICE}/>
                                            &nbsp;Servicio
                                        </label>
                                    </div>
                                    <div className="radio">
                                        <label htmlFor="service_type" className="radio-inline mr-5 d-flex align-items-center">
                                            <Field name="service_type" disabled={ edit || view } component={renderField} type="radio" value={PRODUCT}/>
                                            &nbsp;Producto
                                        </label>
                                    </div>

                                    {/*
                                    <div className="radio">
                                        <label htmlFor="service_type" className="radio-inline mr-5 d-flex align-items-center">
                                            <Field name="service_type" disabled={ edit || view } component={renderField} type="radio" value={FRACTION}/>
                                            &nbsp;Producto Fraccionado
                                        </label>
                                    </div>
                                    */}
                                </div>
                                }
                            </div>

                            <div className="d-flex flex-row justify-content-center align-items-center w-100">
                                <div className="">
                                    <div className="px-md-5 pt-2 d-flex flex-column flex-sm-row align-items-stretch align-items-sm-center">
                                        <div className="my-2 mr-5">
                                            <div className="d-flex justify-content-start flex-column flex-sm-row align-items-stretch align-items-sm-center">
                                                <label htmlFor="name" className="m-0">Nombre del servicio</label>
                                            </div>
                                            <div className=" d-flex justify-content-start flex-column flex-sm-row align-items-stretch align-items-sm-center">
                                                <Field name="name" disabled={view} autofocus component={renderField} type="text" className="form-control"/>
                                            </div>
                                        </div>
                                        <div className="m-2 ml-5 service-price-discount d-flex justify-content-between">
                                            <div>
                                                <div className="service-price-input d-flex justify-content-start flex-column flex-sm-row align-items-stretch align-items-sm-center">
                                                    <label htmlFor="price" className="m-0">Precio</label>
                                                </div>
                                                <div className="service-price-input d-flex justify-content-end flex-column flex-sm-row align-items-stretch align-items-sm-center">
                                                    <Field name="price" disabled={view} component={renderField} type="text" className="form-control"/>
                                                </div>
                                            </div>
                                            <div>
                                                <div className="service-discount-input d-flex justify-content-start flex-column flex-sm-row align-items-stretch align-items-sm-center">
                                                    <label htmlFor="discount" className="m-0">Descuento (%)</label>
                                                </div>
                                                <div className="service-discount-input d-flex justify-content-end flex-column flex-sm-row align-items-stretch align-items-sm-center">
                                                    <Field name="discount" disabled={view} component={renderField} type="text" className="form-control"/>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="px-md-5 row-fluid d-flex flex-column flex-sm-row align-items-stretch align-items-sm-center">
                                        <div className="mr-5 mb-3">
                                            <div className="d-flex justify-content-start flex-column flex-sm-row align-items-stretch align-items-sm-center">
                                                <label htmlFor="cost" className="m-0">Precio Costo</label>
                                            </div>
                                            <div className="d-flex justify-content-end flex-column flex-sm-row align-items-stretch align-items-sm-center">
                                                <Field name="cost" disabled={view} component={renderCurrency} type="text" className="form-control"/>
                                            </div>
                                        </div>
                                        <div className="ml-5 invisible">
                                            <div className="service-price-input d-flex justify-content-start flex-column flex-sm-row align-items-stretch align-items-sm-center">
                                                <label htmlFor="comodin" className="m-0">Comodín</label>
                                            </div>
                                            <div className="service-price-input d-flex justify-content-end flex-column flex-sm-row align-items-stretch align-items-sm-center">
                                                <Field name="comodin" disabled={view} component={renderField} type="text" className="form-control"/>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="px-md-5 row-fluid d-flex flex-column flex-sm-row align-items-stretch align-items-sm-center">
                                        <div className="mr-5">
                                            <div className="d-flex justify-content-start flex-column flex-sm-row align-items-stretch align-items-sm-center">
                                                <label htmlFor="payback" className="m-0">Payback</label>
                                            </div>
                                            <div className="d-flex justify-content-start flex-column flex-sm-row align-items-stretch align-items-sm-center">
                                                <Field name="payback" disabled={view} component={renderField} type="text" className="form-control"/>
                                            </div>
                                        </div>
                                        <div className="ml-5">
                                            <div className="d-flex justify-content-start flex-column flex-sm-row align-items-stretch align-items-sm-center">
                                                <label htmlFor="service_category" className="m-0">Categoría</label>
                                            </div>
                                            <div className="d-flex justify-content-start flex-column flex-sm-row align-items-stretch align-items-sm-center">
                                                <Field
                                                    name="service_category"
                                                    valueKey="value"
                                                    labelKey="label"
                                                    label="name"
                                                    component={renderSearchSelect}
                                                    loadOptions={getOptions}
                                                    className="no-border"
                                                    disabled={view}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="px-md-5 row-fluid d-flex flex-column flex-sm-row align-items-stretch align-items-sm-center">
                                        <div className="my-2 mr-5">
                                            <div className="d-flex justify-content-start flex-column flex-sm-row align-items-stretch align-items-sm-center">
                                                <label htmlFor="barcode" className="m-0">Código de Barras</label>
                                            </div>
                                            <div className=" d-flex justify-content-start flex-column flex-sm-row align-items-stretch align-items-sm-center">
                                                <Field name="barcode" disabled={view} component={renderField} type="text" className="form-control"/>
                                            </div>
                                        </div>
                                        {(service_type === PRODUCT) &&
                                        <div className="my-2 ml-5 ml-1">
                                            <div className="d-flex justify-content-start flex-column flex-sm-row align-items-stretch align-items-sm-center">
                                                <label htmlFor="minimal existence" className="m-0">¿Descuenta de Inventario?</label>
                                            </div>
                                            <div>
                                                <Field
                                                    name="inventory_discount"
                                                    component={renderSwitch}
                                                    className="form-control"
                                                    disabled={view}
                                                />
                                            </div>
                                        </div>
                                        }
                                    </div>

                                    {
                                        (type !== PACKAGE_STR  && service_type === PRODUCT) &&
                                    <div className="px-md-5 pt-2 d-flex flex-column flex-sm-row align-items-stretch align-items-sm-center">

                                        <div className="my-2 mr-5 ml-1">
                                            <div className="d-flex justify-content-start flex-column flex-sm-row align-items-stretch align-items-sm-center">
                                                <label htmlFor="minimal existence" className="m-0">Alerta</label>
                                            </div>
                                            <div>
                                                <Field
                                                    name="alert"
                                                    component={renderSwitch}
                                                    className="form-control"
                                                    disabled={view}
                                                />
                                            </div>
                                        </div>


                                        <div className="my-2 invisible">
                                            <div className="d-flex justify-content-start flex-column flex-sm-row align-items-stretch align-items-sm-center">
                                                <label htmlFor="minimal existence" className="m-0">Comodín</label>
                                            </div>
                                            <div className="d-flex justify-content-start flex-column flex-sm-row align-items-stretch align-items-sm-center">
                                                <Field name="comodin" component={renderNumber} type="text" className="form-control"/>
                                            </div>
                                        </div>
                                        {alert &&
                                        <div className="my-2">
                                            <div className="d-flex justify-content-start flex-column flex-sm-row align-items-stretch align-items-sm-center">
                                                <label htmlFor="minimal existence" className="m-0">Mínimo de Existencia</label>
                                            </div>
                                            <div className="d-flex justify-content-start flex-column flex-sm-row align-items-stretch align-items-sm-center">
                                                <Field name="minimal_existence" disabled={view} component={renderNumber} type="text" className="form-control"/>
                                            </div>
                                        </div>
                                        }

                                    </div>
                                    }

                                    {/* agregado */}
                                    {
                                        (type !== PACKAGE_STR  && service_type === PRODUCT) &&
                                    <div className="px-md-5 pt-2 d-flex flex-column flex-sm-row align-items-stretch align-items-sm-center">

                                        <div className="my-2 mr-5 ml-1">
                                            <div className="d-flex justify-content-start flex-column flex-sm-row align-items-stretch align-items-sm-center">
                                                <label htmlFor="minimal existence" className="m-0">Eliminación automática</label>
                                            </div>
                                            <div>
                                                <Field
                                                    name="automatic_delete"
                                                    component={renderSwitch}
                                                    className="form-control"
                                                    disabled={view}
                                                />
                                            </div>
                                        </div>


                                        <div className="my-2 invisible">
                                            <div className="d-flex justify-content-start flex-column flex-sm-row align-items-stretch align-items-sm-center">
                                                <label htmlFor="minimal existence" className="m-0">Comodin</label>
                                            </div>
                                            <div className="d-flex justify-content-start flex-column flex-sm-row align-items-stretch align-items-sm-center">
                                                <Field name="comodin" component={renderNumber} type="text" className="form-control"/>
                                            </div>
                                        </div>
                                    </div>
                                    }
                                    {/* agregado... */}
                                </div>
                            </div>
                        </div>
                        </React.Fragment>
                    }

                    {
                        (this.state.tab_active === "servicio") &&
                        <div className={"w-100" + vservicio}>
                                <div className="package-item-row-container">
                                    <div className="package-items-title-row d-flex pl-md-3">
                                        <label htmlFor="service-name" className="package-item-title my-0">Nombre del servicio</label>
                                        <label htmlFor="service-code" className="package-item-title my-0">Código</label>
                                        <label htmlFor="service-quantity" className="package-small-item-title my-0">Cantidad</label>
                                        <label htmlFor="service-action" className="package-small-item-title my-0">Eliminar</label>
                                    </div>
                                    <div>
                                        <FieldArray
                                            name="package_items"
                                            component={renderItemRow}
                                            view={view}
                                            edit={edit}
                                        />
                                    </div>
                                </div>
                        </div>
                    }

                    {
                        //(this.state.tab_active === "entidad") &&
                        <React.Fragment>
                            <div className={"w-75" + ventidad}>
                                <div className="package-items-title-row d-flex pl-md-3">
                                    <label htmlFor="service-name" className="package-item-title-c my-0">Nombre</label>
                                    <label htmlFor="service-action" className="package-small-item-title-c my-0"></label>
                                    <label htmlFor="service-action" className="package-small-item-title-c my-0"></label>
                                    <label htmlFor="service-action" className="package-small-item-title-c my-0">Acciones</label>
                                </div>
                                <div>
                                    <FieldArray
                                        name="financial_entities"
                                        component={renderEntityRow}
                                        view={view}
                                        edit={edit}
                                    />
                                </div>
                            </div>
                        </React.Fragment>

                    }
                    {
                        //(this.state.tab_active === "comision") &&
                        <div className={"w-75" + vcomision}>
                            <div className="package-item-row-container">
                                <div className="package-items-title-row d-flex pl-md-3">
                                    <label htmlFor="service-name" className="package-item-title-c my-0">Nombre del usuario</label>
                                    <label htmlFor="service-code" className="package-item-title-c my-0">Comisión</label>
                                    <label htmlFor="service-action" className="package-small-item-title-c my-0">Eliminar</label>
                                </div>
                                <div>
                                    <FieldArray
                                        name="commissions"
                                        component={renderCommissionRow}
                                        view={view}
                                        edit={edit}
                                    />
                                </div>
                            </div>
                        </div>
                    }
                    {
                        //(this.state.tab_active === "precio") &&
                        <div className={"pr-5 w-100" + vprecio}>
                            <div className="">
                                <FieldArray
                                    name="categories_prices"
                                    component={renderCategoryPrices}
                                    view={view}
                                    edit={edit}
                                />
                            </div>
                        </div>
                    }

                </div>
                <div className="row-fluid">
                    <div className="d-flex justify-content-center flex-column flex-sm-row align-items-stretch align-items-sm-center mb-3">
                        {
                            window.location.href.includes("services_conf") &&
                            <Link className="btn btn-secondary mx-4" to="/services_conf">{!view ? ("Cancelar") : ("Regresar")}</Link>
                        }
                        {!view && (<button type="submit" className="btn btn-primary mx-4">Guardar</button>)}
                    </div>
                    <div className="d-flex justify-content-center">*Antes de guardar recuerde verificar que haya llenado todos los campos necesarios en todos los tabs.</div>
                </div>
                </form>
            </div>
        );
    }
}

let ServicesForm = reduxForm({
    form: 'services',
    validate,
})(tabsComponent);


const selector = formValueSelector('services');

ServicesForm = connect(state => {
    let alert = selector(state, "alert");
    let service_type = selector(state, "service_type");

    return {
        alert,
        service_type
    };

})(ServicesForm);

export default ServicesForm;
