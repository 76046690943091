import Swal from 'sweetalert2';
import { api } from 'api';
import { createActions, handleActions } from 'redux-actions'
import { goBack } from 'react-router-redux'
import { initialize as initializeForm } from 'redux-form'
import { push } from 'react-router-redux';
const url = 'patients';
import moment from "moment";
import { departamentos, municipios } from 'variables';

const LOADER_PATIENTS = 'LOADER_PATIENTS';
const SET_DATA_PATIENTS = 'SET_DATA_PATIENTS';
const SET_PAGE_PATIENTS = 'SET_PAGE_PATIENTS';
const SET_UPDATE_DATA_PATIENTS ='SET_UPDATE_DATA_PATIENTS';
const SET_BUSCADOR_PATIENTS = 'SET_BUSCADOR_PATIENTS';
const SET_FILTRO_PATIENTS = 'SET_FILTRO_PATIENTS';

const MALE = 1
const FEMALE = 2

const translateKey = (key="") => {
    key = key.toLowerCase()
    const keys = {
        'cellphone': 'teléfono celular',
        'phone': 'teléfono de línea fija',
        'departamento': 'departamento',
        'municipio': 'municipio',
        'first_name': 'nombre',
        'last_name': 'apellido',
        'married_last_name': 'apellido de casada',
        'nickname': 'nombre preferencial',
        'dpi': 'dpi',
        'birthdate': 'fecha de nacimiento',
        'address': 'dirección',
        'nationality': 'nacionalidad',
        'gender': 'género',
        'referred_by': 'referente',
        'family_member_of': 'familiar de',
        'family_doctor': 'familiar de médico',
        'nit': 'nit',
        'profession': 'profesión'
    }

    return keys[key] ? keys[key] : key
}

const getAfiliationData = (value) => (dispatch, getState) => {
    if(value && value.length === 13){   
        const url = `https://igss-service.onrender.com/getAfiliateData?number=${value}`; 

        fetch(url)
        .then(response => {
            // Verificar si la respuesta fue exitosa (código de estado 200-299)
            if (!response.ok) {
            throw new Error('Network response was not ok ' + response.statusText);
            }
            // Parsear la respuesta JSON
            return response.json();
        })
        .then(data => {
            const state = getState().form.patients.values;
            if(data.nombre && data.apellidos){
                state.first_name = data.nombre;
                state.last_name = data.apellidos;
                dispatch(initializeForm('patients', state));
            }        
        })
        .catch(error => {
            // Manejo de errores
            console.error('Hubo un problema con la petición fetch:', error);
        });
    }
}

const listar = (page = 1) =>  (dispatch, getStore) => {
    dispatch({type: LOADER_PATIENTS, cargando: true});
    const store = getStore();
    const search = store.patients.buscador;
    const filtro = store.patients.filtro_patients;
    let params = {page, search};

    if(filtro){
        params[''] = filtro
    }
    api.get(`${url}`, params).catch((error) => {
        dispatch({type: LOADER_PATIENTS, cargando: false});
        Swal(
            'Error',
            error.detail || 'Ha ocurrido un error, por favor vuelva a intentar.',
            'error'
        );
    }).then((data) => {
        if(data){
            dispatch({type: SET_DATA_PATIENTS, data});
            dispatch({type: SET_PAGE_PATIENTS, page});
        }
        dispatch({type: LOADER_PATIENTS, cargando: false});
    })
};

const validateNit = nit => (dispatch, getState) => {
    dispatch({ type: LOADER_PATIENTS, cargando: true });
    const params = { nit };
    api.get(`${url}/validateNit`, params).catch((error) => {
        Swal('Error', error.detail || 'Ha ocurrido un error, por favor vuelva a intentar.', 'error');
    }).then((response) => {
        if (response.detail) {
            const data = getState().form.patients.values;
            if (response.detail.first_name !== '') data.first_name = response.detail.first_name;
            if (response.detail.last_name !== '') data.last_name = response.detail.last_name;
            if (response.detail.married_last_name !== '') data.married_last_name = response.detail.married_last_name;
            dispatch(initializeForm('patients', data));
        }
    }).finally(() => dispatch({ type: LOADER_PATIENTS, cargando: false }));
};

const setDataName = (data) => (dispatch, getState) => {
    dispatch({ type: LOADER_PATIENTS, cargando: true });
    const state = getState().form.patients.values;
    // console.log("setDataName: ", data);
    if (data.first_name !== '') state.first_name = data.first_name;
    if (data.last_name !== '') state.last_name = data.last_name;
    dispatch(initializeForm('patients', state));
    dispatch({ type: LOADER_PATIENTS, cargando: false });
};

const detail = id => (dispatch, getState) => {
    dispatch({type: LOADER_PATIENTS, cargando: true});
    api.get(`${url}/${id}`).catch((error) => {
        dispatch({type: LOADER_PATIENTS, cargando: false});
        if(error.statusCode  === 404){
            dispatch(push('/patients'))
        }
    }).then((data) => {
        console.log("Patient detail: ", data);
        dispatch({type: LOADER_PATIENTS, cargando: false});
        if(data){
            if(data.gender == MALE){
                data.gender = {value: MALE, label: 'Masculino'}
            } else {
                data.gender = {value: FEMALE, label: 'Femenino'}
            }
            for (let index = 0; index < departamentos.length; index++) {
                if(departamentos[index].value == data.departamento){
                    data.departamento = departamentos[index];
                }
            }
            for (let index = 0; index < municipios.length; index++) {
                if(municipios[index].value == data.municipio){
                    data.municipio = municipios[index];
                }
            }
            if(data.family_member_of){
                data.family_member_of.value = data.family_member_of.id;
                data.family_member_of.label = data.family_member_of.name;
            }
            if(data.referred_by){
                data.referred_by.value = data.referred_by.id;
                data.referred_by.label = data.referred_by.name;
            }

            if(data.family_doctor === 1){
                data.family_doctor = {value: 1, label: "Sí"};
            }else{
                data.family_doctor = {value: 2, label: "No"};
            }

            data.birthdate = moment(data.birthdate).format("DD/MM/YYYY");

            dispatch({type: SET_UPDATE_DATA_PATIENTS, updateData: data});
            dispatch(initializeForm('patients', data))
        }
    })
}

const createInitialize = () => (dispatch) => {
    dispatch(initializeForm('patients', {'birthdate': moment(new Date()).format("DD/MM/YYYY"), 'nationality': 'guatemalteca'}))
}

const create = () => (dispatch, getStore) => {
    const formData = _.cloneDeep(getStore().form.patients.values);
    formData.gender = formData.gender.value;
    if(formData.departamento){
        formData.departamento = formData.departamento.value;
    }
    if(formData.municipio){
        formData.municipio = formData.municipio.value;
    }
    if(formData.family_member_of){
        formData.family_member_of = formData.family_member_of.value;
    }
    if(formData.referred_by){
        formData.referred_by = formData.referred_by.value;
    }

    if(formData.family_doctor){
        formData.family_doctor = formData.family_doctor.value;
    }


    dispatch({type: LOADER_PATIENTS, cargando: true})
    api.post(`${url}`, formData).then((data) => {
        dispatch({type: LOADER_PATIENTS, cargando: false})
        Swal('Éxito', 'Se ha creado el paciente.', 'success')
        .then(() => {
            dispatch(push('/patients'))
        })
    }).catch((error) => {
        dispatch({type: LOADER_PATIENTS, cargando: false})
        let msj = 'Ha ocurrido un error, por favor vuelva a intentar.'
        if(error){
            msj += '<br>'
            const keys = Object.keys(error)
            keys.forEach(key => {
                msj += `<br>${translateKey(key)}: ${error[key]}`
            });
        }
        Swal({
            title: 'Error',
            type: 'error',
            html : msj
        });
    }).finally(() => {
        dispatch({type: LOADER_PATIENTS, cargando: false})
    });
};

const update = () => (dispatch, getStore) => {
    const formData = _.cloneDeep(getStore().form.patients.values);
    formData.gender = formData.gender.value;
    if(formData.departamento){
        formData.departamento = formData.departamento.value;
    }
    if(formData.municipio){
        formData.municipio = formData.municipio.value;
    }
    if(formData.family_member_of){
        formData.family_member_of = formData.family_member_of.value;
    }
    if(formData.referred_by){
        formData.referred_by = formData.referred_by.value;
    }

    if(formData.family_doctor){
        formData.family_doctor = formData.family_doctor.value;
    }

    dispatch({type: LOADER_PATIENTS, cargando: true});
    api.put(`${url}/${formData.id}`, formData)
    .then((data) => {
        dispatch({type: LOADER_PATIENTS, cargando: false});
        if(data){
            Swal(
                'Éxito',
                'Datos editados correctamente.',
                'success'
            ).then(() => {
                dispatch(push('/patients'));
            })
        }
    })
    .catch((error) =>{
        dispatch({type: LOADER_PATIENTS, cargando: false});
        let msj = 'Ha ocurrido un error, verifique los datos.'
        if(error){
            msj += '<br>'
            const keys = Object.keys(error)
            keys.forEach(key => {
                msj += `<br>${translateKey(key)}: ${error[key]}`
            });
        }
        Swal({
            title: 'Error',
            type: 'error',
            html : msj
        });
        if(error.statusCode === 404){
            dispatch(push('/patients/create'));
        }
    })
}

const destroy = (id) => (dispatch, getStore) => {
    dispatch({type: LOADER_PATIENTS, cargando: true});
    api.eliminar(`${url}/${id}`).catch((error) => {
        dispatch({type: LOADER_PATIENTS, cargando: false});
        Swal('Error', 'No se ha logrado borrar el registro.', 'error')
            .then(() => {
                dispatch(listar(1));
        })
    }).then((data) => {
        dispatch({type: LOADER_PATIENTS, cargando: false});
        Swal('Éxito', 'Se ha eliminado correctamente.', 'success')
            .then(() => {
                dispatch(listar(1));
            })
    })
}

const search = (search) => (dispatch, getStore) => {
    dispatch({type: SET_BUSCADOR_PATIENTS, buscador: search});
    dispatch(listar(1));
};
const filtro = (filtro) => (dispatch, getStore) => {
    if (filtro == 0){
        filtro = null
    }
    dispatch({type: SET_FILTRO_PATIENTS, filtro_patients: filtro});
    dispatch(listar(1));
};
export const actions = {
    listar,
    detail,
    create,
    update,
    destroy,
    search,
    filtro,
    createInitialize,
    validateNit,
    setDataName,
    getAfiliationData
};
export const reducer = {
    [LOADER_PATIENTS]: (state, { cargando }) => {
        return {...state, cargando }
    },
    [SET_DATA_PATIENTS]: (state, { data }) => {
        return {...state, data }
    },
    [SET_PAGE_PATIENTS]: (state, { page }) => {
        return {...state, page }
    },
    [SET_UPDATE_DATA_PATIENTS]: (state, { updateData }) => {
        return {...state, updateData }
    },
    [SET_BUSCADOR_PATIENTS]: (state, { buscador }) => {
        return {...state, buscador }
    },
    [SET_FILTRO_PATIENTS]: (state, { filtro_patients }) => {
        return {...state, filtro_patients }
    },
}
export const initialState = {
    cargando: false,
    page: 1,
    data: {
        count: 0,
        next: null,
        previous: null,
        results: [],
    },
    buscador: '',
    filtro_patients: null,
    updateData: {},
};
export default handleActions(reducer, initialState)
