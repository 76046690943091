import React, {Component} from 'react';
import Modal from 'react-responsive-modal';
import classNames from 'classnames';
import "../notations";

export class Esf extends Component {
    constructor(props) {
        super(props);
        this.state = { open: false};
    }

    openModal = () => {
        this.setState({ open: true });
    };

    closeModal = () => {
        this.setState({ open: false });
    };

    selectOption = (input, value) => {
        const final_value = value > 0 ? '+' + value : value;        
        input.onChange(final_value);
        this.closeModal();
    };

    render() {
        const { input, meta: { touched, error }, title, jaeger, view } = this.props;
        const { open } = this.state;
        const invalid = touched && error;
        
        let fi = 0.00
        const ff = 10.00
        
        let ci = 0
        let cf = 3
        
        let columnas = [];

        for(fi;fi<=ff;fi=fi+0.25){
            ci = 0;
            let fila = [];
            for(ci;ci<=cf;ci++){                
                const cantidad = fi + (ci*10.25);
                fila.push(cantidad.toFixed(2));
            }
            columnas.push(fila);
        }
        

       let fi2 = -20.75
       const ff2 = -30.75
       
       let ci2 = 0
       let cf2 = 2
       
       let columnasNegativas = [];
        
       for(fi2;fi2>=ff2;fi2=fi2-0.25){
           ci2 = 0;
           let fi2la = [];
           for(ci2;ci2<=cf2;ci2++){                
               const cantidad = fi2 + (ci2*10.25);
               fi2la.push(cantidad.toFixed(2));
           }
           columnasNegativas.push(fi2la);
       }
       
        return (
            <React.Fragment>
                <Modal open={open} onClose={this.closeModal} >
                    <div className="text-center">
                        <h2 className="border-bottom">{title}</h2>
                    </div>

                    <div className='d-flex flex-row'>
                        <table id="notations-table-modal">
                            <tbody>
                            {columnasNegativas.map(columna=>(

                                <tr key={columna}>
                                    {columna.map(fila=>{
                                        const color_label = fila >= 0 ? 'color-verde' : 'color-rojo';
                                        return(
                                        <React.Fragment key={fila}>
                                        {fila >= -30 ? 
                                            <td>
                                                <div className="radio">
                                                    <label
                                                        htmlFor="service_type"
                                                        className={`radio-inline mr-5 d-flex align-items-center ${color_label}`}
                                                        onClick={() => this.selectOption(input, fila)}>

                                                        <input
                                                            type={"radio"}
                                                            value={fila}
                                                            {...input}
                                                            onChange={() => this.selectOption(input, fila)}
                                                            className={classNames('form-control', { 'is-invalid': invalid })} />
                                                        <span>&nbsp;{fila > 0 ? '+'+fila : fila}</span>
                                                    </label>
                                                </div>
                                            </td>
                                        :
                                            <td>
                                            </td>
                                        }
                                        </React.Fragment>
                                        );
                                    })}
                                </tr>
                            ))}
                            </tbody>
                        </table>

                        <table id="notations-table-modal">
                            <tbody>
                            {columnas.map(columna=>(

                                <tr key={columna}>
                                    {columna.map(fila=>{
                                        const color_label = fila >= 0 ? 'color-verde' : 'color-rojo';
                                        return(
                                        <React.Fragment key={fila}>
                                        {fila <= 30 ?
                                            <td>
                                                <div className="radio">
                                                    <label
                                                        htmlFor="service_type"
                                                        className={`radio-inline mr-5 d-flex align-items-center ${color_label}`}
                                                        onClick={() => this.selectOption(input, fila)}>

                                                        <input
                                                            type={"radio"}
                                                            value={fila}
                                                            {...input}
                                                            onChange={() => this.selectOption(input, fila)}
                                                            className={classNames('form-control', { 'is-invalid': invalid })} />
                                                        <span>&nbsp;{fila > 0 ? '+'+fila : fila}</span>
                                                    </label>
                                                </div>
                                            </td>
                                        :
                                            <td>
                                            </td>
                                        }
                                        </React.Fragment>
                                        );
                                    })}
                                </tr>
                            ))}
                            </tbody>
                        </table>                        
                    </div>

                    <div className="d-flex justify-content-center align-items-center mt-4">
                        <div
                            className="btn btn-secondary mx-4"
                            onClick={() => {
                                input.onChange(undefined);
                                this.closeModal();
                            }}>
                            Cancelar
                        </div>
                    </div>
                </Modal>
                <span className="form-control text-center" onClick={this.openModal} >{input.value}</span>
            </React.Fragment>
        );
    }
}
