import React from 'react';
import { Link } from 'react-router-dom';
import { Field, reduxForm } from 'redux-form';
import { validate, validators } from 'validate-redux-form';
import { renderField } from 'Utils/renderField/renderField';

const Form = props => {

    const view = window.location.href.includes("view");

    const {
        handleSubmit,
    } = props;

    return(
        <form onSubmit={handleSubmit} className="py-2 px-5">
           <div className="px-md-5 d-flex justify-content-around flex-column flex-sm-row align-items-stretch align-items-sm-center">
               <div className="m-2">
                    <div className="d-flex justify-content-start flex-column flex-sm-row align-items-stretch align-items-sm-center">
                        <label htmlFor="name" className="m-0">Nombre</label>
                    </div>
                    <div className="d-flex justify-content-start flex-column flex-sm-row align-items-stretch align-items-sm-center">
                        <Field name="name" autofocus disabled={view} component={renderField} type="text" className="form-control"/>
                    </div>
                </div>
                <div className="m-2">
                    <div className="d-flex justify-content-start flex-column flex-sm-row align-items-stretch align-items-sm-center">
                        <label htmlFor="address" className="m-0">Dirección</label>
                    </div>
                    <div className="d-flex justify-content-start flex-column flex-sm-row align-items-stretch align-items-sm-center">
                        <Field name="address" disabled={view} component={renderField} type="text" className="form-control"/>
                    </div>
                </div> 
            </div> 
            <div className="px-md-5 d-flex justify-content-around flex-column flex-sm-row align-items-stretch align-items-sm-center">
               <div className="m-2">
                    <div className="d-flex justify-content-start flex-column flex-sm-row align-items-stretch align-items-sm-center">
                        <label htmlFor="phone" className="m-0">Teléfono</label>
                    </div>
                    <div className="d-flex justify-content-start flex-column flex-sm-row align-items-stretch align-items-sm-center">
                        <Field name="phone" disabled={view} component={renderField} type="number" className="form-control"/>
                    </div>
                </div>
                <div className="m-2 invisible">
                    <div className="d-flex justify-content-start flex-column flex-sm-row align-items-stretch align-items-sm-center">
                        <Field name="phone" disabled={view} component={renderField} type="text" className="form-control"/>
                    </div>
                </div>
            </div>
            <div className="row-fluid">
                <div className="d-flex justify-content-center flex-column flex-sm-row align-items-stretch align-items-sm-center">
                    <Link className="btn btn-secondary m-3" to="/subsidiaries">{!view ? ("Cancelar") : ("Regresar")}</Link>
                    {!view && (<button type="submit" className="btn btn-primary m-3">Guardar</button>)}
                </div>
            </div>
       </form>
    )
};

const SubsidiaryForm = reduxForm({
    form: 'subsidiary',
    validate: data => {
        return validate(data, {
            'name':validators.exists()('Campo Requerido'),
            'address':validators.exists()('Campo Requerido'),
            'phone':validators.exists()('Campo Requerido'),
        })
    }
})(Form);

export default SubsidiaryForm
