import Form from './DuesForm';
import React, { Component } from 'react';

export default class DuesCrear extends Component {
    state = {
        editar: false,
        mensaje: 'Crear Cuota'
    }

    componentDidMount = () =>{
        //this.props.getConfiguration();
    }

    componentWillMount(){
        if(this.props.match.params.id){
            this.props.detail(this.props.match.params.id);
            this.setState({
                editar: true,
                mensaje: 'Editar Cuota'
            });
        }
    }

    render(){
        const {
            save,
            update,
            removeRow,
        } = this.props;

        const {
            updateData,
            cargando,
        } = this.props;

        return (
            <div className="content-with-tabs">
                <div className="mx-5 py-5">
                    <div className="table-decoration">
                            {

                            this.state.editar ?
                                <div>
                                    <div className="top-title">
                                        Cuota
                                    </div>
                                    <Form
                                    onSubmit={update}
                                    updateData={updateData}
                                    editando={true}
                                    removeRow={removeRow}
                                     />
                                </div>
                            :
                            <React.Fragment>
                                <div className="top-title">
                                    Cuotas
                                </div>
                                <Form onSubmit={save} removeRow={removeRow} />
                                </React.Fragment>
                            }
                        </div>
                 </div>

            </div>
        );
    }
}
