import React, { Component } from 'react';
import { RenderCurrency } from 'Utils/renderField/renderReadField';
import NumberFormat from 'react-number-format';

class CuponInCar extends Component {
    render(){
        return(
            <React.Fragment>
            <tr className="row">
                <td className="col-sm-1 " style={{width:"1% !important"}}>

                </td>
                <td colSpan={2} className="col-sm-7 cupon-row">
                    Descuento por cupón &nbsp;
                    <span className="font-weight-bold">{this.props.product.cupon_code}</span>
                </td>
                <td className="col-sm-2 cupon-row">
                    <span><RenderCurrency value={this.props.product.total} /></span>
                </td>
                <td className="col-sm-2">
                    <button onClick={()=>this.props.removeCoupon(this.props.product.cupon_code, this.props.i)} className="circle-button-delete">
                        <img className="card-img-top d-block" src={require("../../../../assets/img/icons/delete-patient-icon.png")} alt="Delete icon" />
                    </button>
                </td>
            </tr>
            </React.Fragment>
        );
    }
}

export default CuponInCar;
