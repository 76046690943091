const url = 'expense';
import { api } from 'api';
import moment from "moment";
import Swal from 'sweetalert2';
import { push } from 'react-router-redux';
import { initialize as initializeForm } from 'redux-form';
import { handleActions } from 'redux-actions';
import bankAccount from './bankAccount';

const SET_FINAL_DATE = 'SET_FINAL_DATE';
const SET_INICIAL_DATE = 'SET_INICIAL_DATE';
const LOADER_EXPENCE = 'LOADER_EXPENCE';
const SET_DATA_EXPENCE = 'SET_DATA_EXPENCE';
const SET_PAGE_EXPENCE = 'SET_PAGE_EXPENCE';
const SET_UPDATE_DATA_EXPENCE = 'SET_UPDATE_DATA_EXPENCE';
const SET_BUSCADOR_EXPENCE = 'SET_BUSCADOR_EXPENCE';
const SET_FILTRO_EXPENCE = 'SET_FILTRO_EXPENCE';
const SET_SMALLBOX_LIMIT = 'SET_SMALLBOX_LIMIT';
const SET_USE_SMALL_BOX = 'SET_USE_SMALL_BOX';

const listar = (page = 1) => async (dispatch, getStore) => {
    dispatch({type: LOADER_EXPENCE, cargando: true});
    const store = getStore();
    const search = store.expense.buscador;
    const filtro = store.expense.filtro_gasto;
    const initial_date = store.expense.initial_date;
    const final_date = store.expense.final_date;
    let params = {page, search, initial_date, final_date };
    if(filtro){
        params[''] = filtro
    }

    api.get(`${url}`, params).catch((error) => {
        dispatch({type: LOADER_EXPENCE, cargando: false});
        Swal(
            'Error',
            error.detail || 'Ha ocurrido un error, por favor vuelva a intentar.',
            'error'
        );
    }).then((data) => {
        if(data){
            dispatch({type: SET_DATA_EXPENCE, data});
            dispatch({type: SET_PAGE_EXPENCE, page});
        }
        dispatch({type: LOADER_EXPENCE, cargando: false});
    })

};

const getSmallBoxLimit = () => (dispatch, getState) => {
    api.get(`configuration/getConfiguration`).then((response) => {
        const smallbox_limit = parseFloat(response.small_box_limit);
        dispatch({type: SET_SMALLBOX_LIMIT, smallbox_limit});
    })
}

const detail = id => (dispatch, getState) =>{
    dispatch({type: LOADER_EXPENCE, cargando: true});
    api.get(`${url}/${id}`).catch((error) => {
        dispatch({type: LOADER_EXPENCE, cargando: false});
        if(error.statusCode === 404){
            dispatch(push('/spending-types'))
        }
    }).then((data) => {
        dispatch({type: LOADER_EXPENCE, cargando: false});
        if(data){
            const document_format_payment = moment(data.document_date_payment).format('DD/MM/YYYY')
            const payment_type = {value: data.payment_type, label: data.payment_type_label};
            data.document_date_payment = document_format_payment
            data.payment_type = payment_type;
            data.spending_type = {value: data.spending_type.id, label: data.spending_type.name};

            if(!!data.provider){
                data.provider = {value: data.provider.id, label: data.provider.name, retention: data.provider.retention, retention_iva : data.provider.retention_iva};
            }

            if(data.payment_type.value === 1){
                data.small_box = {value: data.small_box.id, label: data.small_box.name, nit: data.small_box.nit}
                dispatch({type: SET_USE_SMALL_BOX, use_small_box: true});
            }else if(data.payment_type.value === 2){
                dispatch({type: SET_USE_SMALL_BOX, use_small_box: false});
                data.bank_account = {
                    value: data.bank_transaction.bank_account.id,
                    label:  data.bank_transaction.bank_account.owner,
                    number:   data.bank_transaction.bank_account.account_number
                }
            }
            dispatch({type: SET_UPDATE_DATA_EXPENCE, updateData: data});
            dispatch(initializeForm('expenseForm', data))
        }
    })
}

const create = () => (dispatch, getStore) => {
    const formData = getStore().form.expenseForm.values;
    formData.payment_type = formData.payment_type.value;
    formData.spending_type = formData.spending_type.value;

    let endpoint = url;
    if(formData.to_pay && formData.to_pay === true){
        endpoint = `${endpoint}/registrarCuentaPorPagar`;
    }

    if(formData.small_box){
        formData.small_box = formData.small_box.value;
    }

    if(formData.bank_account){
        formData.bank_account = formData.bank_account.value;
    }

    if(formData.provider){
        formData.provider = formData.provider.value;
    }

    console.log("Form data: ", formData);

    dispatch({type: LOADER_EXPENCE, cargando: true})

    api.post(`${endpoint}`, formData).then((data) => {
        dispatch({type: LOADER_EXPENCE, cargando: false})
        Swal('Éxito', data.detail, 'success')
        .then(() => {
            dispatch(push('/income-expense'))
        })
    }).catch((error) => {
        dispatch({type: LOADER_EXPENCE, cargando: false})
        Swal(
            'Error',
             error.detail || 'Ha ocurrido un error, por favor vuelva a intentar.',
            'error'
        );
    }).finally(() => {
        dispatch({type: LOADER_EXPENCE, cargando: false})
    });
};

/*
const update = () => (dispatch, getStore) => {
    const formData = _.cloneDeep(getStore().form.spendingType.values);
    dispatch({type: LOADER_SPENDING_TYPE, cargando: true});
    api.put(`${url}/${formData.id}`, formData)
    .then((data) => {
        dispatch({type: LOADER_SPENDING_TYPE, cargando: false});
        if(data){
            Swal(
                'Éxito',
                'Datos editados correctamente.',
                'success'
            ).then(() => {
                dispatch(push('/spending-types'));
            })
        }
    })
    .catch((error) =>{
        dispatch({type: LOADER_SPENDING_TYPE, cargando: false});
        Swal(
            'Error',
             error.detail || 'Ha ocurrido un error, verifique los datos.',
            'error'
        )
        if(error.statusCode === 404){
            dispatch(push('/roles/create'));
        }
    })
}*/

const destroy = (id) => (dispatch, getStore) => {
    dispatch({type: LOADER_EXPENCE, cargando: true});
    api.eliminar(`${url}/${id}`).catch((error) => {
        dispatch({type: LOADER_EXPENCE, cargando: false});
        Swal('Error', 'No se ha logrado borrar el registro.', 'error')
            .then(() => {
                dispatch(listar(1));
            })
    }).then((data) => {
        dispatch({type: LOADER_EXPENCE, cargando: false});
        Swal('Éxito', 'Se ha eliminado correctamente.', 'success')
            .then(() => {
                dispatch(push("/income-expense"))
            })
    })
}


const search = (search) => (dispatch) => {
    dispatch({type: SET_BUSCADOR_EXPENCE, buscador: search});
    dispatch(listar(1));
};

const filtro = (filtro) => (dispatch, getStore) => {
    if (filtro == 0){
        filtro = null
    }
    dispatch({type: SET_FILTRO_EXPENCE, filtro_rol: filtro});
    dispatch(listar(1));
};

const set_initial_date = (value) => (dispatch) => {
    dispatch({type: SET_INICIAL_DATE, initial_date: value});
    //dispatch(listar());
}
const set_final_date = (value) => (dispatch) => {
    dispatch({type: SET_FINAL_DATE, final_date: value});
    //dispatch(listar());
}


export const actions = {
    listar,
    create,
    detail,
    /*
    update,
    destroy,
    */
    search,
    destroy,
    filtro,
    getSmallBoxLimit,
    set_initial_date,
    set_final_date
};

export const reducer = {
    [SET_INICIAL_DATE]: (state, { initial_date }) => {
        return{
            ...state,
            initial_date
        }
    },
    [SET_FINAL_DATE]: (state, { final_date }) => {
        return{
            ...state,
            final_date
        }
    },
    [SET_SMALLBOX_LIMIT]: (state, { smallbox_limit }) => {
        return { ...state, smallbox_limit}
    },
    [LOADER_EXPENCE]: (state, { cargando }) => {
        return {...state, cargando }
    },
    [SET_DATA_EXPENCE]: (state, { data }) => {
        return {...state, data }
    },
    [SET_PAGE_EXPENCE]: (state, { page }) => {
        return {...state, page }
    },
    [SET_UPDATE_DATA_EXPENCE]: (state, { updateData }) => {
        return {...state, updateData }
    },
    [SET_BUSCADOR_EXPENCE]: (state, { buscador }) => {
        return {...state, buscador }
    },
    [SET_FILTRO_EXPENCE]: (state, { filtro_tipo_gasto}) => {
        return {...state, filtro_tipo_gasto }
    }
};

export const initialState = {
    cargando: false,
    page: 1,
    data: {
        count: 0,
        next: null,
        previous: null,
        results: [],
    },
    buscador: '',
    filtro_gasto: null,
    updateData: {},
    smallbox_limit: null,
    initial_date: moment().date(1).format("DD/MM/YYYY"),
    final_date: moment().format("DD/MM/YYYY"),
};

export default handleActions(reducer, initialState)
