import React, { PureComponent } from 'react';
import SideNav, {
    Toggle,
    Nav,
    NavItem,
    NavIcon,
    NavText,
} from '@trendmicro/react-sidenav';
import ReactHoverObserver from 'react-hover-observer';


import Icon from './Icon';

import '@trendmicro/react-sidenav/dist/react-sidenav.css';

const SUPERUSER = 'superuser';

class Menu extends PureComponent {
    constructor(props) {
        super(props);

        this.state = {

            /*
                All items that are going to contain a submenu must to be regitered here ("the id of the element"),
                all options must be setted to false
            */
            // 'puntoVenta-acordeon': false,
            'bodega-acordeon': false,
            'referents-acordeon': false,
            'services-acordeon': false,
            'configuration-acordeon': false,
            'schedule-acordeon': false,
            'sales-acordeon': false,
            // 'categories-acordeon': false,
            'other-acordeon': false,

            // "no_option" is to indicate that no option in the sidebar with a submenu is currently opened
            no_option: true,

            // "last" is to indicate which element in the sidebar was recently opened
            last: 'no_option',
        };
    }

    render() {
        const {
            me,
            history,
            location,
            isNavBarOpen,
            setNavBarClass,
        } = this.props;

        if (!!me) {
            if (!me.permisos)
                me.permisos = {};
        }

        const [rootLocation = '/home'] = location.pathname.split('/');

        return (
            <ReactHoverObserver
                onMouseEnter={() => setNavBarClass(true)}
                onMouseLeave={() => setNavBarClass(false)}
            >
                <SideNav
                    id="side-nav"
                    expanded={isNavBarOpen}
                    onToggle={(isOpen) => {
                        setNavBarClass(!isNavBarOpen);
                    }}
                    onSelect={(selected) => {
                        const parsedRoute = selected.split('/');
                        const to = '/' + parsedRoute[parsedRoute.length - 1];
                        if (location.pathname !== to) {
                            history.push(to);
                        }
                    }}
                >
                    <Toggle />
                    {
                        me && me.role_name == SUPERUSER
                            ? null
                            : me && me.role_name != SUPERUSER && me.permisos == true
                            && null
                    }
                    <Nav defaultSelected="/home">
                        <NavItem eventKey="home">
                            <NavIcon className="d-flex justify-content-center">
                                <Icon className="home-icon" />
                            </NavIcon>
                            <NavText>
                                <span className="side-nav__text">Inicio</span>
                            </NavText>
                        </NavItem>

                        {me && ((me.role_name == SUPERUSER) || (me && me.role_name != SUPERUSER && me.permisos.pacientes_menu == true)) && (
                            <NavItem eventKey="patients">
                                <NavIcon>
                                    <Icon className="patients-icon" />
                                </NavIcon>
                                <NavText>
                                    <span className="side-nav__text">Pacientes</span>
                                </NavText>
                            </NavItem>
                        )}

                        {me && ((me.role_name == SUPERUSER) || (me.permisos.venta_caja_menu == true)) && (
                            <NavItem eventKey="igss">
                                <NavIcon className="d-flex justify-content-center">
                                    <Icon className="igss-icon" />
                                </NavIcon>
                                <NavText>
                                    <span className="side-nav__text">IGSS</span>
                                </NavText>

                                {(me.role_name == SUPERUSER || me.permisos.listado_diagnostico) && (
                                    <NavItem eventKey="report-open-accounts-igss">
                                        <NavText>
                                            <span className="side-nav__text">Reporte cuentas abiertas</span>
                                        </NavText>
                                    </NavItem>
                                )}                           
                            </NavItem>
                        )}

                        {me && ((me.role_name == SUPERUSER) || (me.permisos.venta_caja_menu == true)) && (
                            <NavItem eventKey="sales">
                                <NavIcon className="d-flex justify-content-center">
                                    <Icon className="sales-icon" />
                                </NavIcon>
                                <NavText>
                                    <span className="side-nav__text">Venta / Caja</span>
                                </NavText>

                                {(me.role_name == SUPERUSER || me.permisos.listado_diagnostico) && (
                                    <NavItem eventKey="diagnostic-order">
                                        <NavText>
                                            <span className="side-nav__text">Orden de diagnóstico</span>
                                        </NavText>
                                    </NavItem>
                                )}
                                {(me.role_name == SUPERUSER || me.permisos.cuentas_por_cobrar == true) && (
                                    <NavItem eventKey="sales/receivable">
                                        <NavText>
                                            <span className="side-nav__text">Ctas. Por Cobrar</span>
                                        </NavText>
                                    </NavItem>
                                )}
                                {(me.role_name == SUPERUSER || me.permisos.punto_de_venta == true) && (
                                    <NavItem eventKey="sales/oppening-closing">
                                        <NavText>
                                            <span className="side-nav__text">Punto de Venta</span>
                                        </NavText>
                                    </NavItem>
                                )}
                            </NavItem>
                        )}

                        {me && ((me.role_name == SUPERUSER) || (me.role_name != SUPERUSER && me.permisos.bancos_menu == true)) && (
                            <NavItem eventKey="banks">
                                <NavIcon>
                                    <Icon className="bank-icon" />
                                </NavIcon>
                                <NavText>
                                    <span className="side-nav__text">Bancos</span>
                                </NavText>
                            </NavItem>
                        )}

                        {me && ((me.role_name == SUPERUSER) || (me.role_name != SUPERUSER && me.permisos.bancos_menu == true)) && (
                            <NavItem eventKey="tasks">
                                <NavIcon>
                                    <Icon className="task-icon" />
                                </NavIcon>
                                <NavText>
                                    <span className="side-nav__text">Tareas</span>
                                </NavText>
                            </NavItem>
                        )}

                        {me && (me.role_name === SUPERUSER || me.permisos.referente_menu == true) && (
                            <NavItem eventKey="referents">
                                <NavIcon>
                                    <Icon className="referents-icon" />
                                </NavIcon>
                                <NavText>
                                    <span className="side-nav__text">Referentes</span>
                                </NavText>
                                {(me.role_name === SUPERUSER || me.permisos.referentes) && (
                                    <NavItem eventKey="referents/referents">
                                        <NavText>
                                            <span className="side-nav__text">Referentes</span>
                                        </NavText>
                                    </NavItem>
                                )}
                                {(me.role_name === SUPERUSER || me.permisos.instituciones) && (
                                    <NavItem eventKey="referents/institutions">
                                        <NavText>
                                            <span className="side-nav__text">Instituciones</span>
                                        </NavText>
                                    </NavItem>
                                )}
                                {(me.role_name === SUPERUSER || me.permisos.categorias_referente) && (
                                    <NavItem eventKey="referents/referent-categories">
                                        <NavText>
                                            <span className="side-nav__text">Categorías</span>
                                        </NavText>
                                    </NavItem>
                                )}
                            </NavItem>
                        )}

                        {me && (me.role_name === SUPERUSER || me.permisos.servicios_menu === true) && (
                            <NavItem eventKey="services">
                                <NavIcon>
                                    <Icon className="services-icon" />
                                </NavIcon>
                                <NavText>
                                    <span className="side-nav__text">Servicios</span>
                                </NavText>
                                <NavItem eventKey="services/products">
                                    <NavText>
                                        <span className="side-nav__text">Productos y servicios</span>
                                    </NavText>
                                </NavItem>
                            </NavItem>
                        )}

                        {me && (me.role_name === SUPERUSER || me.permisos.agenda_menu === true) && (
                            <NavItem eventKey="schedule">
                                <NavIcon>
                                    <Icon className="schedule-icon" />
                                </NavIcon>
                                <NavText>
                                    <span className="side-nav__text">Agenda</span>
                                </NavText>
                                {(me.role_name === SUPERUSER || me.permisos.citas) && (
                                    <NavItem eventKey="schedule/schedule">
                                        <NavText>
                                            <span className="side-nav__text">Consultas</span>
                                        </NavText>
                                    </NavItem>
                                )}
                                {(me.role_name === SUPERUSER || me.permisos.cirugias) && (
                                    <NavItem eventKey="schedule/surgeries">
                                        <NavText>
                                            <span className="side-nav__text">Cirugías</span>
                                        </NavText>
                                    </NavItem>
                                )}
                                {(me.role_name === SUPERUSER || me.permisos.cirugias_hoy) && (
                                    <NavItem eventKey="schedule/cirugias-de-hoy">
                                        <NavText>
                                            <span className="side-nav__text">Cirugías de Hoy</span>
                                        </NavText>
                                    </NavItem>
                                )}
                            </NavItem>
                        )}

                        {me && (me.role_name === SUPERUSER || me.permisos.oc_menu === true) && (
                            <NavItem eventKey="oc">
                                <NavIcon>
                                    <Icon className="oc-icon" />
                                </NavIcon>
                                <NavText>
                                    <span className="side-nav__text">Orden de compra</span>
                                </NavText>
                                {(me.role_name == SUPERUSER || me.permisos.solicitud_oc) && (
                                    <NavItem eventKey="oc/ordenes">
                                        <NavText>
                                            <span className="side-nav__text">Listado de ordenes</span>
                                        </NavText>
                                    </NavItem>
                                )}
                                {(me.role_name == SUPERUSER || me.permisos.ingreso_oc) && (
                                    <NavItem eventKey="oc/pending-orders">
                                        <NavText>
                                            <span className="side-nav__text">Recepción</span>
                                        </NavText>
                                    </NavItem>
                                )}
                                {(me.role_name == SUPERUSER || me.permisos.autorizacion_oc) && (
                                    <NavItem eventKey="oc/orders-adm">
                                        <NavText>
                                            <span className="side-nav__text">Autorizaciones pendientes</span>
                                        </NavText>
                                    </NavItem>
                                )}
                                {(me.role_name == SUPERUSER || me.permisos.cuentas_por_pagar) && (
                                    <NavItem eventKey="oc/to-pay">
                                        <NavText>
                                            <span className="side-nav__text">Ctas. por pagar</span>
                                        </NavText>
                                    </NavItem>
                                )}
                            </NavItem>
                        )}

                        {me && (me.role_name == SUPERUSER || me.permisos.gastos_menu) && (
                            <NavItem eventKey="gastos">
                                <NavIcon>
                                    <Icon className="gastos-icon" />
                                </NavIcon>
                                <NavText>
                                    <span className="side-nav__text">Gastos</span>
                                </NavText>
                                {(me.role_name == SUPERUSER || me.permisos.caja_chica) && (
                                    <NavItem eventKey="gastos/small-box">
                                        <NavText>
                                            <span className="side-nav__text">Caja Chica</span>
                                        </NavText>
                                    </NavItem>
                                )}
                                {(me.role_name == SUPERUSER || me.permisos.admin_caja_chica) && (
                                    <NavItem eventKey="gastos/adm-smbx">
                                        <NavText>
                                            <span className="side-nav__text">Adm Caja Chica</span>
                                        </NavText>
                                    </NavItem>
                                )}
                                {(me.role_name == SUPERUSER || me.permisos.tipo_gasto) && (
                                    <NavItem eventKey="gastos/spending-types">
                                        <NavText>
                                            <span className="side-nav__text">Tipos de gastos</span>
                                        </NavText>
                                    </NavItem>
                                )}
                                {(me.role_name == SUPERUSER || me.permisos.ingreso_gasto) && (
                                    <NavItem eventKey="gastos/income-expense">
                                        <NavText>
                                            <span className="side-nav__text">Ingreso de gastos</span>
                                        </NavText>
                                    </NavItem>
                                )}
                                {(me.role_name == SUPERUSER || me.permisos.retenciones) && (
                                    <NavItem eventKey="gastos/retention-payment">
                                        <NavText>
                                            <span className="side-nav__text">Retenciones por Pagar</span>
                                        </NavText>
                                    </NavItem>
                                )}
                            </NavItem>
                        )}

                        {me && (me.role_name === SUPERUSER || me.permisos.consulta_menu === true) && (
                            <NavItem eventKey="consult">
                                <NavIcon>
                                    <Icon className="consult-icon" />
                                </NavIcon>
                                <NavText>
                                    <span className="side-nav__text">Consulta</span>
                                </NavText>

                                {(me.role_name == SUPERUSER || me.permisos.cola_consulta) && (
                                    <NavItem eventKey="query-queue">
                                        <NavText>
                                            <span className="side-nav__text">General</span>
                                        </NavText>
                                    </NavItem>
                                )}

                                <NavItem eventKey="record">
                                    <NavText>
                                        <span className="side-nav__text">Antecedentes</span>
                                    </NavText>
                                </NavItem>

                                {(me.role_name == SUPERUSER || me.permisos.lensometry) && (
                                    <NavItem eventKey="lensometry">
                                        <NavText>
                                            <span className="side-nav__text">Lensometría</span>
                                        </NavText>
                                    </NavItem>
                                )}
                                {(me.role_name == SUPERUSER || me.permisos.visual_acuity) && (
                                    <NavItem eventKey="visual_acuity">
                                        <NavText>
                                            <span className="side-nav__text">Agudeza Visual</span>
                                        </NavText>
                                    </NavItem>
                                )}
                                {(me.role_name == SUPERUSER || me.permisos.autorefractor) && (
                                    <NavItem eventKey="autorefractor">
                                        <NavText>
                                            <span className="side-nav__text">Autorefractómetro</span>
                                        </NavText>
                                    </NavItem>
                                )}
                                {(me.role_name == SUPERUSER || me.permisos.keratometry) && (
                                    <NavItem eventKey="keratometry">
                                        <NavText>
                                            <span className="side-nav__text">Queratómetro</span>
                                        </NavText>
                                    </NavItem>
                                )}
                                {(me.role_name == SUPERUSER || me.permisos.refraction) && (
                                    <NavItem eventKey="refraction">
                                        <NavText>
                                            <span className="side-nav__text">Refracción</span>
                                        </NavText>
                                    </NavItem>
                                )}
                                {(me.role_name == SUPERUSER || me.permisos.tonometry) && (
                                    <NavItem eventKey="tonometry">
                                        <NavText>
                                            <span className="side-nav__text">Tonometría</span>
                                        </NavText>
                                    </NavItem>
                                )}
                                {(me.role_name == SUPERUSER || me.permisos.muscle) && (
                                    <NavItem eventKey="muscle">
                                        <NavText>
                                            <span className="side-nav__text">Músculos Extraoculares</span>
                                        </NavText>
                                    </NavItem>
                                )}
                                {(me.role_name == SUPERUSER || me.permisos.lamp) && (
                                    <NavItem eventKey="lamp">
                                        <NavText>
                                            <span className="side-nav__text">Lámpara de hendidura</span>
                                        </NavText>
                                    </NavItem>
                                )}
                                {(me.role_name == SUPERUSER || me.permisos.indirect_eye) && (
                                    <NavItem eventKey="indirect_eye">
                                        <NavText>
                                            <span className="side-nav__text">Fondo de ojo indirecto</span>
                                        </NavText>
                                    </NavItem>
                                )}
                                {(me.role_name == SUPERUSER || me.permisos.diagnostic_plan) && (
                                    <NavItem eventKey="diagnostic-plan">
                                        <NavText>
                                            <span className="side-nav__text">Plan Dx Plan Tx Plan Educ</span>
                                        </NavText>
                                    </NavItem>
                                )}
                                {(me.role_name == SUPERUSER || me.permisos.educational_plan) && (
                                    <NavItem eventKey="educational-plan">
                                        <NavText>
                                            <span className="side-nav__text">Estudios y procedimientos</span>
                                        </NavText>
                                    </NavItem>
                                )}
                                {(me.role_name == SUPERUSER || me.permisos.educational_plan) && (
                                    <NavItem eventKey="optics-order">
                                        <NavText>
                                            <span className="side-nav__text">Orden de óptica</span>
                                        </NavText>
                                    </NavItem>
                                )}

                            </NavItem>
                        ) }

                        {me && (me.role_name == SUPERUSER || me.permisos.diagnostico_menu) && (
                            <NavItem eventKey="diagnostic-order">
                                <NavIcon>
                                    <Icon className="diagnostic-icon" />
                                </NavIcon>
                                <NavText>
                                    <span className="side-nav__text">Orden diagnóstico</span>
                                </NavText>
                                {(me.role_name == SUPERUSER || me.permisos.cola_diagnostico) && (
                                    <NavItem eventKey="cola-diagnosticos">
                                        <NavText>
                                            <span className="side-nav__text">Cola de diagnósticos</span>
                                        </NavText>
                                    </NavItem>
                                )}
                                {(me.role_name == SUPERUSER || me.permisos.diagnosticos_completados) && (
                                    <NavItem eventKey="examenes-completados">
                                        <NavText>
                                            <span className="side-nav__text">Diagnósticos completados</span>
                                        </NavText>
                                    </NavItem>
                                )}

                            </NavItem>
                        )}

                        {me && (me.role_name == SUPERUSER || me.permisos.plan_menu) && (
                            <NavItem eventKey="plan">
                                <NavIcon>
                                    <Icon className="plan-icon" />
                                </NavIcon>
                                <NavText>
                                    <span className="side-nav__text">Planes</span>
                                </NavText>
                                {(me.role_name == SUPERUSER || me.permisos.plan_examen) && (
                                    <NavItem eventKey="plan-examen">
                                        <NavText>
                                            <span className="side-nav__text">Examenes</span>
                                        </NavText>
                                    </NavItem>
                                )}
                                {(me.role_name == SUPERUSER || me.permisos.plan_procedimiento) && (
                                    <NavItem eventKey="plan-procedimiento">
                                        <NavText>
                                            <span className="side-nav__text">Procedimiento</span>
                                        </NavText>
                                    </NavItem>
                                )}
                                {(me.role_name == SUPERUSER || me.permisos.plan_paquete) && (
                                    <NavItem eventKey="plan-paquete">
                                        <NavText>
                                            <span className="side-nav__text">Paquete</span>
                                        </NavText>
                                    </NavItem>
                                )}
                                <NavItem eventKey="/records/1" disabled
                                         onClick={() => window.location.replace("/#/records/1")}>
                                    <NavText>
                                        <span className="side-nav__text">Record Medicos</span>
                                    </NavText>
                                </NavItem>
                                <NavItem eventKey="/records/2" disabled
                                         onClick={() => window.location.replace("/#/records/2")}>
                                    <NavText>
                                        <span className="side-nav__text">Record Quirúrgicos</span>
                                    </NavText>
                                </NavItem>
                                <NavItem eventKey="treatments">
                                    <NavText>
                                        <span className="side-nav__text">Tratamientos</span>
                                    </NavText>
                                </NavItem>
                            </NavItem>
                        )}

                        {me && (me.role_name == SUPERUSER || me.permisos.bodega_menu) && (
                            <NavItem eventKey="bodega">
                                <NavIcon>
                                    <Icon className="bodega-icon" />
                                </NavIcon>
                                <NavText>
                                    <span className="side-nav__text">Bodega</span>
                                </NavText>
                                {(me.role_name == SUPERUSER || me.permisos.listado_bodegas) && (
                                    <NavItem eventKey="bodega/warehouse">
                                        <NavText>
                                            <span className="side-nav__text">Listado Bodegas</span>
                                        </NavText>
                                    </NavItem>
                                )}
                                {(me.role_name == SUPERUSER || me.permisos.administracion_bodegas) && (
                                    <NavItem eventKey="bodega/administracion-bodega">
                                        <NavText>
                                            <span className="side-nav__text">Administración</span>
                                        </NavText>
                                    </NavItem>
                                )}
                                {(me.role_name == SUPERUSER || me.permisos.reporte_ingresos) && (
                                    <NavItem eventKey="reports/reporte-ingresos">
                                        <NavText>
                                            <span className="side-nav__text">Reporte de ingresos</span>
                                        </NavText>
                                    </NavItem>
                                )}
                                {(me.role_name == SUPERUSER || me.permisos.reporte_bajas) && (
                                    <NavItem eventKey="reports/reporte-bajas">
                                        <NavText>
                                            <span className="side-nav__text">Reporte de bajas</span>
                                        </NavText>
                                    </NavItem>
                                )}
                                {(me.role_name == SUPERUSER || me.permisos.reporte_ajustes) && (
                                    <NavItem eventKey="reports/reporte-ajustes">
                                        <NavText>
                                            <span className="side-nav__text">Reporte de ajustes</span>
                                        </NavText>
                                    </NavItem>
                                )}
                            </NavItem>
                        )}

                        {me && ((me.role_name == SUPERUSER) || (me.permisos.proveedores_menu == true)) && (
                            <NavItem eventKey="providers">
                                <NavIcon>
                                    <Icon className="providers-icon" />
                                </NavIcon>
                                <NavText>
                                    <span className="side-nav__text">Proveedores</span>
                                </NavText>
                            </NavItem>
                        )}

                        {me && (me.role_name == SUPERUSER ||me.permisos.reportes_menu) && (
                            <NavItem eventKey="reports">
                                <NavIcon>
                                    <Icon className="reports-icon" />
                                </NavIcon>
                                <NavText>
                                    <span className="side-nav__text">Reportes</span>
                                </NavText>
                                {(me.role_name == SUPERUSER || me.permisos.movimientos) && (
                                    <NavItem eventKey="reports/report-movements">
                                        <NavText>
                                            <span className="side-nav__text">Movimientos</span>
                                        </NavText>
                                    </NavItem>
                                )}
                                {(me.role_name == SUPERUSER || me.permisos.payback) && (
                                    <NavItem eventKey="reports/payback">
                                        <NavText>
                                            <span className="side-nav__text">Payback</span>
                                        </NavText>
                                    </NavItem>
                                )}
                                {(me.role_name == SUPERUSER || me.permisos.arqueos) && (
                                    <NavItem eventKey="reports/arqueos">
                                        <NavText>
                                            <span className="side-nav__text">Arqueos</span>
                                        </NavText>
                                    </NavItem>
                                )}
                                {(me.role_name == SUPERUSER || me.permisos.cirugia) && (
                                    <NavItem eventKey="reports/reporte-cirugias">
                                        <NavText>
                                            <span className="side-nav__text">Cirugías</span>
                                        </NavText>
                                    </NavItem>
                                )}
                                {(me.role_name == SUPERUSER || me.permisos.honorarios) && (
                                    <NavItem eventKey="reports/fee">
                                        <NavText>
                                            <span className="side-nav__text">Honorarios de cirugía</span>
                                        </NavText>
                                    </NavItem>
                                )}
                                {(me.role_name == SUPERUSER || me.permisos.reporte_consultas) && (
                                    <NavItem eventKey="reports/reporte-consultas">
                                        <NavText>
                                            <span className="side-nav__text">Reporte de consultas</span>
                                        </NavText>
                                    </NavItem>
                                )}
                                {(me.role_name == SUPERUSER || me.permisos.reporte_tiempos_consultas) && (
                                    <NavItem eventKey="reports/reporte-tiempos-consultas">
                                        <NavText>
                                            <span className="side-nav__text">Tiempos de consultas</span>
                                        </NavText>
                                    </NavItem>
                                )}
                                {(me.role_name == SUPERUSER || me.permisos.reporte_consignacion) && (
                                    <NavItem eventKey="reports/reporte-consignacion">
                                        <NavText>
                                            <span className="side-nav__text">Reporte consignación</span>
                                        </NavText>
                                    </NavItem>
                                )}
                                {(me.role_name == SUPERUSER || me.permisos.reporte_cuenta_abierta) && (
                                    <NavItem eventKey="reports/reporte-cuentas-abiertas">
                                        <NavText>
                                            <span className="side-nav__text">Reporte Cuentas Abiertas</span>
                                        </NavText>
                                    </NavItem>
                                )}
                                {(me.role_name == SUPERUSER || me.permisos.reporte_consolidado) && (
                                    <NavItem eventKey="reports/reporte-consolidado">
                                        <NavText>
                                            <span className="side-nav__text">Reporte Consolidado</span>
                                        </NavText>
                                    </NavItem>
                                )}
                                {(me.role_name == SUPERUSER || me.permisos.reporte_consolidado) && (
                                    <NavItem eventKey="reports/reporte-consolidado-gastos">
                                        <NavText>
                                            <span className="side-nav__text">Reporte Consolidado Gastos</span>
                                        </NavText>
                                    </NavItem>
                                )}
                                {(me.role_name == SUPERUSER || me.permisos.reporte_gastos) && (
                                    <NavItem eventKey="reports/reporte-gastos">
                                        <NavText>
                                            <span className="side-nav__text">Reporte Gastos</span>
                                        </NavText>
                                    </NavItem>
                                )}
                                {(me.role_name == SUPERUSER || me.permisos.reporte_gastos) && (
                                    <NavItem eventKey="reports/promedio-procedimientos">
                                        <NavText>
                                            <span className="side-nav__text">Promedio procedimientos</span>
                                        </NavText>
                                    </NavItem>
                                )}
                                {(me.role_name == SUPERUSER || me.permisos.reporte_pago_grupal) && (
                                    <NavItem eventKey="reports/reporte-pagos-grupales">
                                        <NavText>
                                            <span className="side-nav__text">Pagos Grupales</span>
                                        </NavText>
                                    </NavItem>
                                )}
                                {me.role_name == SUPERUSER && (
                                    <NavItem eventKey="reports/bitacora">
                                        <NavText>
                                            <span className="side-nav__text">Bitacora</span>
                                        </NavText>
                                    </NavItem>
                                )}
                            </NavItem>
                        )}

                        {me && (me.role_name == SUPERUSER || me.permisos.configuracion_menu) && (
                            <NavItem eventKey="config">
                                <NavIcon>
                                    <Icon className="configuration-icon" />
                                </NavIcon>
                                <NavText>
                                    <span className="side-nav__text">Configuración</span>
                                </NavText>
                                {(me.role_name == SUPERUSER || me.permisos.configuracion_general) && (
                                    <NavItem eventKey="reports/configuration">
                                        <NavText>
                                            <span className="side-nav__text">Conf. general</span>
                                        </NavText>
                                    </NavItem>
                                )}
                                {(me.role_name == SUPERUSER || me.permisos.productos_conf) && (
                                    <NavItem eventKey="reports/services_conf">
                                        <NavText>
                                            <span className="side-nav__text">Productos y servicios</span>
                                        </NavText>
                                    </NavItem>
                                )}
                                {(me.role_name == SUPERUSER || me.permisos.categorias_producto) && (
                                    <NavItem eventKey="reports/service-categories">
                                        <NavText>
                                            <span className="side-nav__text">Categorías de Servicios</span>
                                        </NavText>
                                    </NavItem>
                                )}
                                {(me.role_name == SUPERUSER || me.permisos.cupones) && (
                                    <NavItem eventKey="reports/coupons">
                                        <NavText>
                                            <span className="side-nav__text">Cupones</span>
                                        </NavText>
                                    </NavItem>
                                )}
                                {(me.role_name == SUPERUSER || me.permisos.pos) && (
                                    <NavItem eventKey="reports/pos">
                                        <NavText>
                                            <span className="side-nav__text">POS&apos;s</span>
                                        </NavText>
                                    </NavItem>
                                )}
                                {(me.role_name == SUPERUSER || me.permisos.cuotas) && (
                                    <NavItem eventKey="reports/dues">
                                        <NavText>
                                            <span className="side-nav__text">Cuotas</span>
                                        </NavText>
                                    </NavItem>
                                )}
                                {(me.role_name == SUPERUSER || me.permisos.entes_financieros) && (
                                    <NavItem eventKey="reports/financial_entity">
                                        <NavText>
                                            <span className="side-nav__text">Entidades financieras</span>
                                        </NavText>
                                    </NavItem>
                                )}
                                {(me.role_name == SUPERUSER || me.permisos.cajas) && (
                                    <NavItem eventKey="reports/cash-boxes">
                                        <NavText>
                                            <span className="side-nav__text">Cajas</span>
                                        </NavText>
                                    </NavItem>
                                )}
                                {(me.role_name == SUPERUSER || me.permisos.roles) && (
                                    <NavItem eventKey="reports/roles">
                                        <NavText>
                                            <span className="side-nav__text">Roles</span>
                                        </NavText>
                                    </NavItem>
                                )}
                                {(me.role_name == SUPERUSER || me.permisos.usuarios) && (
                                    <NavItem eventKey="reports/users">
                                        <NavText>
                                            <span className="side-nav__text">Usuarios</span>
                                        </NavText>
                                    </NavItem>
                                )}
                                {(me.role_name == SUPERUSER || me.permisos.interpreter) && (
                                    <NavItem eventKey="reports/interpreter">
                                        <NavText>
                                            <span className="side-nav__text">Intérprete</span>
                                        </NavText>
                                    </NavItem>
                                )}
                                {(me.role_name == SUPERUSER || me.permisos.usuarios) && (
                                    <NavItem eventKey="reports/doctors">
                                        <NavText>
                                            <span className="side-nav__text">Doctores</span>
                                        </NavText>
                                    </NavItem>
                                )}
                                {(me.role_name == SUPERUSER || me.permisos.sucursales) && (
                                    <NavItem eventKey="reports/subsidiaries">
                                        <NavText>
                                            <span className="side-nav__text">Sucursales</span>
                                        </NavText>
                                    </NavItem>
                                )}
                                {(me.role_name == SUPERUSER || me.permisos.cuentas_bancarias) && (
                                    <NavItem eventKey="reports/bank-accounts">
                                        <NavText>
                                            <span className="side-nav__text">Cuentas bancarias</span>
                                        </NavText>
                                    </NavItem>
                                )}
                            </NavItem>
                        )}
                    </Nav>
                </SideNav>
            </ReactHoverObserver>
        );
    }
}

export default Menu;
