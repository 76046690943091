import React, { Component } from 'react';
import classNames from 'classnames';
import Table from '../Utils/Grid';
import { BreakLine } from '../Utils/tableOptions';
import HeaderSimple from '../Utils/Header/HeaderSimple';
import { activeFormatter } from '../Utils/Acciones/Acciones';
import CustomToolbar from '../Utils/Toolbar/CustomToolbar';

function formatInstitution(cell) {
    if (cell) {
        return (<span>{cell.name}</span>);
    }

    return (<span />);
}

class Referent extends Component {
    componentWillMount() {
        this.props.listar()
    }
    componentWillUnmount() {
        this.props.resetStore();
    }
   
    render() {
        const {
            page,
            data,
            listar,
            detail,
            create,
            update,
            destroy,
            cargando,
            isNavBarOpen,
        } = this.props;

        return (
            <div className={classNames('content', { 'content-min': isNavBarOpen })}>
                <div className="m-auto row-fluid w-100">
                    <HeaderSimple
                        texto="Agregar referente"
                        ruta="/referents/create"
                    />
                    <div className="table-decoration">
                        <div className="d-flex flex-row justify-content-between align-items-center">
                            <div className="top-title-toolbar mt-3 d-flex">
                                <span className="ml-4 text-uppercase text-titulo">
                                    <strong>LISTADO DE REFERENTES</strong>
                                </span>
                            </div>                        

                            <div className="top-title-toolbar mt-3">
                                <CustomToolbar
                                    buscar={this.props.search}
                                    buscador={this.props.buscador}
                                    autofocus
                                />
                            </div>
                        </div>

                        <Table
                            onPageChange={listar}
                            data={data}
                            loading={cargando}
                            page={page}
                        >
                            <TableHeaderColumn
                                tdStyle={BreakLine}
                                thStyle={BreakLine}
                                dataField="name" dataSort>Nombre</TableHeaderColumn>
                            <TableHeaderColumn
                                tdStyle={BreakLine}
                                thStyle={BreakLine}
                                dataField="phone" dataSort>Teléfono</TableHeaderColumn>
                            <TableHeaderColumn
                                tdStyle={BreakLine}
                                thStyle={BreakLine}
                                dataFormat= {formatInstitution}
                                dataField="institution" dataSort>Institución</TableHeaderColumn>
                            <TableHeaderColumn
                                tdStyle={BreakLine}
                                thStyle={BreakLine}
                                dataField="tipo" dataSort>Tipo</TableHeaderColumn>
                            <TableHeaderColumn
                                tdStyle={BreakLine}
                                thStyle={BreakLine}
                                dataField="id" dataSort>Código</TableHeaderColumn>
                            <TableHeaderColumn
                                dataField="id"
                                isKey={true}
                                dataAlign="center"
                                dataFormat={activeFormatter({ ver: '/referents', editar: '/referents', eliminar: destroy })}>Acciones</TableHeaderColumn>
                        </Table>
                    </div>
                </div>
            </div>
        )
    }
}

export default Referent;
