import React, {Component} from 'react';
import { api } from "api";
import { Link } from 'react-router-dom';
import { Field, reduxForm, formValueSelector } from 'redux-form';
import { validate, validators } from 'validate-redux-form';
import { renderField, renderSearchSelect, renderSelectCustom }  from 'Utils/renderField/renderField';
import { connect } from 'react-redux'

const edit = window.location.href.includes("view");

let subsidiaries = []
let persons = []
let roles=[]

function formatProfile(cell){
    if (cell){
        return <span>{cell.role}</span>
    } else {
        return <span></span>
    }
}

const getPersons = (search)  =>{
    return api.get("referents/listAvailableReferences", {search}).catch((error) => {})
        .then((data) => {
            if (data){

                persons = [];
                //data.results.forEach(x=>{
                data.forEach(x=>{
                    persons.push({value: x.id, label: x.name})
                })
                return persons
            }
            else{
                return []
            }
        })
        .catch(e => {
            return []
        })
}

const getSubsidiaries = (search)  =>{
    return api.get("subsidiaries",{search}).catch((error) => {})
        .then((data) => {
            if (data){
                subsidiaries = [];
                data.results.forEach(x=>{
                    subsidiaries.push({value: x.id, label: x.name})
                })
                return subsidiaries
            }
            else{
                return []
            }
        })
        .catch(e => {
            return []
        })
}

const getRoles = (search)  =>{
    return api.get("roles",{search}).catch((error) => {})
        .then((data) => {

            if (data){
                roles = [];
                data.results.forEach(x=>{
                    roles.push({value: x.id, label: x.name})
                })

                return roles
            }
            else{
                return []
            }
        })
        .catch(e => {
            return []
        })
}

/* this shit here ugly, but then again everything else here is ugly so meh */
class Doctors extends Component {
    isChecked = (id) => {
        const { input } = this.props;
        if (input.value) {
            return input.value.split(",").indexOf(id.toString()) > -1;
        }
        return false;
    };

    checkDoctor = (id) => {
        const { input } = this.props;
        if (input.value) {
            const ids = input.value.split(",");
            const indice = ids.indexOf(id.toString());
            if (indice > -1) {
                ids.splice(indice, 1);
            } else {
                ids.push(id)
            }
            input.onChange(ids.toString());
        } else {
            input.onChange(`${id}`);
        }
    };

    render() {
        const { doctors, disabled } = this.props;
        return (
            <React.Fragment>
                {doctors.map((item) => (
                    <div className="pl-4 d-flex flex-row" key={item.id}>
                        <div className="checkbox c-checkbox">
                            <label className="needsclick m-0">
                                <input
                                    type="checkbox"
                                    value={item.id}
                                    checked={this.isChecked(item.id)}
                                    onChange={() => this.checkDoctor(item.id)}
                                    disabled={disabled}
                                />
                                <span className="fa fa-check" />
                            </label>
                        </div>
                        <label htmlFor="" className="m-0">{item.name}</label>
                    </div>
                ))}
            </React.Fragment>
        );
    }
}

let InterpreterForm = props => {
    const view = window.location.href.includes("view");

    const {
        handleSubmit,
        is_referent,
        doctors,
        no_superuser
    } = props;

    const referente = (!props.create && props.updateData.hasOwnProperty("person") && props.updateData.person != null) ? props.updateData.person : null;

    return(
        <form onSubmit={handleSubmit} className="py-3 px-5">
            <div className="px-md-5 d-flex justify-content-around flex-column flex-sm-row align-items-stretch align-items-sm-center">
                <div className="m-2">
                    <div className="d-flex justify-content-start flex-column flex-sm-row align-items-stretch align-items-sm-center">
                        <label htmlFor="first_name" className="m-0">Nombre</label>
                    </div>
                    <div className=" d-flex justify-content-start flex-column flex-sm-row align-items-stretch align-items-sm-center">
                        <Field name="first_name" disabled={view} component={renderField} type="text" className="form-control"/>
                    </div>
                </div>
                <div className="m-2">
                    <div className=" d-flex justify-content-startr flex-column flex-sm-row align-items-stretch align-items-sm-center">
                        <label htmlFor="username" className="m-0">Username</label>
                    </div>
                    <div className=" d-flex justify-content-end flex-column flex-sm-row align-items-stretch align-items-sm-center">
                        <Field name="username" disabled={view} component={renderField} type="text" className="form-control"/>
                    </div>
                </div>
            </div>
            <div className="px-md-5 d-flex justify-content-around flex-column flex-sm-row align-items-stretch align-items-sm-center">
                {
                    props.mostrar_pass && (
                        <div className="m-2">
                            <div className="d-flex justify-content-start flex-column flex-sm-row align-items-stretch align-items-sm-center">
                                <label htmlFor="password" className="m-0">Contraseña</label>
                            </div>
                            <div className="d-flex justify-content-start flex-column flex-sm-row align-items-stretch align-items-sm-center">
                                <Field name="password" disabled={view} component={renderField} className="form-control"/>
                            </div>
                        </div>
                    )
                }
                <div className="m-2">
                    <div className="d-flex justify-content-start flex-column flex-sm-row align-items-stretch align-items-sm-center">
                        <label htmlFor="role" className="m-0">Rol</label>
                    </div>
                    <div className=" d-flex justify-content-end flex-column flex-sm-row align-items-stretch align-items-sm-center">
                        <Field name="role"
                        component={renderSearchSelect}
                        valueKey="value"
                        labelKey="label"
                        label="rol"
                        loadOptions={getRoles}
                        className="no-border"
                        disabled={view}
                        />
                    </div>
                </div>
            </div>
            {
                // This check if the role selected is not superuser to render fields like 'subsidiary' or 'referent (medical or social)' | All user must to have a subsidiary assigned
                no_superuser &&
                <div className="px-md-5 d-flex justify-content-around flex-column flex-sm-row align-items-stretch align-items-sm-center">
                    <div className="m-2">
                        <div className="d-flex justify-content-start flex-column flex-sm-row align-items-stretch align-items-sm-center">
                            <label htmlFor="subsidiary" className="m-0">Sucursal</label>
                        </div>
                        <div className=" d-flex justify-content-end flex-column flex-sm-row align-items-stretch align-items-sm-center">
                            <Field
                                name="subsidiary"
                                valueKey="value"
                                labelKey="label"
                                label="name"
                                component={renderSearchSelect}
                                loadOptions={getSubsidiaries}
                                className="no-border"
                                disabled={view}
                            />
                        </div>
                    </div>
                    {
                        is_referent ? // This check if the role is equal to 6 and this form must to select a referent already registered
                            <div className="m-2">
                                <div className="d-flex justify-content-start flex-column flex-sm-row align-items-stretch align-items-sm-center">
                                    <label htmlFor="person" className="m-0">Referente</label>
                                </div>
                                <div className=" d-flex justify-content-end flex-column flex-sm-row align-items-stretch align-items-sm-center">
                                    <Field
                                        name="person"
                                        valueKey="value"
                                        labelKey="label"
                                        label="name"
                                        component={renderSearchSelect}
                                        loadOptions={getPersons}
                                        className="no-border"
                                        disabled={view}
                                        />
                                    {/* <span onClick={()=>props.deleteReferente(referente.value)}>{referente != null ? referente.value : null}</span> */}
                                </div>
                            </div>
                    :
                        <div className="m-2 invisible">
                            <div className="d-flex justify-content-start flex-column flex-sm-row align-items-stretch align-items-sm-center">
                                <Field name="first_name" disabled={view} component={renderField} type="text" className="form-control"/>
                            </div>
                        </div>
                    }
                </div>
            }
            { no_superuser && (
                <div className="px-md-5 d-flex flex-column">
                    <div className="d-flex justify-content-start flex-column flex-sm-row align-items-stretch align-items-sm-center">
                        <label htmlFor="doctors" className="m-0">Doctores para agenda</label>
                    </div>
                    <Field name="doctors" disabled={view} component={Doctors} doctors={doctors} />
                </div>
            )}            
            <div className="row-fluid">
                <div className="d-flex justify-content-center flex-column flex-sm-row align-items-stretch align-items-sm-center">
                    <Link className="btn btn-secondary m-4" to="/interpreter">{!view ? ("Cancelar") : ("Regresar")}</Link>
                    {!view && (<button type="submit" className="btn btn-primary m-3">Guardar</button>)}
                </div>
            </div>
        </form>
    )
};

InterpreterForm = reduxForm({
   form: 'interpreter',
   validate: data => {
        return validate(data, {
            'first_name': validators.exists()('Campo requerido.'),
            'username': validators.exists()('Campo requerido.'),
            'password': validators.exists()('Campo requerido.'),
            'role': validators.exists()('Campo requerido.'),
            'subsidiary': validators.exists()('Compo requerido'),
            'person': validators.exists()('Compo requerido'),
       })
   }
})(InterpreterForm);

const selector = formValueSelector('interpreter');

InterpreterForm = connect(state => {
    //const role = selector(state, "role.value");
    const role_value = selector(state, "role.label");

    //const role = selector(state, "role.value")
    let is_referent = false;
    let no_superuser = false;

    /*
    if(role == referent_role){
        is_referent = true
    }
    */

    //if(role > 4){

    if (role_value && role_value !== undefined && role_value.toLowerCase() !== "superuser" && role_value.toLowerCase() !== "superusuario"){
        no_superuser = true;
        is_referent = true;
    }

    return {
        no_superuser,
        is_referent
    };
})(InterpreterForm);

export default InterpreterForm
