import React, {Component} from "react";
import './tag.css';

class TagsInput extends Component {
    state = {
        tags: [],
    }

    addTags = event => {
        if (event.key === "Enter" && event.target.value !== "") {
            let temp_tags = this.state.tags;
            temp_tags.push(event.target.value)
            this.setState({tags: temp_tags});
            event.target.value = "";
        }
    };

    removeTags = index => {
        const temp_tags = this.state.tags.filter((tag) => {
            return this.state.tags.indexOf(tag) !== index;
        });

        this.setState({tags: temp_tags});
    };

    render(){
        return (
            <React.Fragment>
                <div className="tag-r2-container">
                    <div className='d-flex flex-row flex-wrap'>
                        {this.state.tags.map((tag, index) => (
                            <div
                                className='tag-r2-element d-flex flex-row justify-content-center align-items-center'
                                key={index}
                            >
                                <span>{tag}</span>
                                <span
                                    className="tag-r2-close d-flex justify-content-center align-items-center"
                                    onClick={() => this.removeTags(index)}
                                >
                                    x
                                </span>
                            </div>
                        ))}
                    </div>
                    <input
                        className='tag-r2-input'
                        type="text"
                        placeholder={this.props.placeholder}
                        onKeyUp={event => event.key === "Enter" ? this.addTags(event) : null}
                    />
                </div>

                <div className='d-flex flex-row justify-content-center mt-3 mb-3'>
                    <button
                        className='btn btn-sm btn-secondary'
                        onClick={()=>{
                            this.props.cancelar();
                        }}
                    >Cancelar</button>
                    <button
                        className='ml-2 btn btn-sm btn-primary'
                        onClick={()=>{
                            this.props.aceptar(this.state.tags);
                        }}
                    >Aceptar</button>
                </div>
            </React.Fragment>
        );
    }
};

export default TagsInput;
