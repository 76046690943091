import React, { Component } from 'react';
import { RenderCurrency } from "../Utils/renderField/renderReadField";
import { api } from 'api'

class Factura extends Component {
    state = {
        recharge_item: {},
        flag: null,
    }

    componentDidMount() {
        const params = {
            is_charge: true,
        }
        api.get('services/getByFilter', params)
        .then(response => {
            if(response.length > 0) {
                this.setState({ recharge_item: response[0] })
            }
        })
    }

    componentDidUpdate(prevProps, prevState) {
        if(prevProps.factura && this.props.factura && prevProps.factura.calculate && this.props.factura.calculate) {
            const prevExtra = prevProps.factura.calculate.monto_extra ? prevProps.factura.calculate.monto_extra : 0
            const actualExtra = this.props.factura.calculate.monto_extra ? this.props.factura.calculate.monto_extra : 0
            if(prevExtra != actualExtra) {
                const { factura } = this.props
                const item = {
                    quantity: 1,
                    coupons: [],
                    discount: 0,
                    is_charge: true,
                    price: actualExtra,
                    subtotal: actualExtra,
                    accumulated_discount: 0,
                    discount_authorization: false,
                    name: this.state.recharge_item.name,
                    service: this.state.recharge_item.id,
                    service_name: this.state.recharge_item.name,
                }
                factura.services = factura.services.filter(item => !item.is_charge)
                if(actualExtra > 0) {
                    factura.services.push(item)
                }
                this.setState({ flag: actualExtra })
            }
        }
    }

    render(){
        const {
            factura
        } = this.props;

        let totalFactura = 0
        if(typeof factura.dataBilling.retention === 'boolean'){
            totalFactura = (parseFloat(parseFloat(factura.calculate.total).toFixed(2))).toFixed(2);
        }else{
            totalFactura = parseFloat(factura.calculate.total) + parseFloat(factura.dataBilling.retention);
        }
        let monto_extra = parseFloat(factura.calculate.monto_extra) ? parseFloat(factura.calculate.monto_extra) : 0
        totalFactura = parseFloat(totalFactura) + monto_extra

        return(
            <div className="d-flex p-2 flex-column justify-content-center align-items-center w-100 fac">
                <span className="fac-title mb-3">
                    {factura.financial_entity.factura === 'recibo' || factura.financial_entity.factura === 'exento' || factura.financial_entity.factura === 'EXE' ?
                        `${factura.dataBilling.fel_error !== "ÉXITO EN LA CREACIÓN DE LA NOTA DE CRÉDITO" ? "" : "Nota de crédito"}`
                        :
                        `${factura.dataBilling.fel_error !== "ÉXITO EN LA CREACIÓN DE LA NOTA DE CRÉDITO" ? "Factura" : "Nota de crédito"}`
                    }
                </span>

                {factura.financial_entity.factura === 'recibo' || factura.financial_entity.factura === 'exento' || factura.financial_entity.factura === 'EXE' ?
                    <React.Fragment>
                        <span className='negro text-center'>ASOCIACIÓN DE EDUCACIÓN EN SALUD C'ASLEN (VIDA)</span>

                        <span className='negro text-center mt-2 mb-2'>Recibo por Donación a Asociaciones y Fundaciones sin Fines de Lucro</span>
                        <span className='negro text-center mt-2 mb-2'>NIT: 7796412-8</span>
                    </React.Fragment>
                :
                    <React.Fragment>
                        <img
                            className='w-75 mb-2'
                            src={require('../../../../assets/img/prolaser-logo-dark.png')}
                        />
                        <span className='negro text-center mt-2 mb-2'>NIT: 6769142-0</span>
                    </React.Fragment>
                }
                <span className='negro'>Teléfonos: 7736-8712 y 7767-6590</span>
                <span className='negro'>Whatsapp 4220-3735</span>

                <div className="mb-2 d-flex justify-content-center fac-number negro mt-1">
                    {factura.dataBilling.correlative !== null && factura.dataBilling.correlative !== undefined
                        ?
                            factura.dataBilling.correlative
                        :
                         <React.Fragment>
                            { factura.financial_entity.factura === 'recibo' || factura.financial_entity.factura === 'exento'  || factura.financial_entity.factura === 'EXE'
                                ? "REC-XXXXXXX" : "FAC-XXXXXXX"
                            }
                        </React.Fragment>
                    }
                </div>

                    {/*
                    {factura.dataBilling.fel_status === 3 ?
                        <React.Fragment>
                            <span className="authorization-number"><b>Serie:</b> {factura.dataBilling.serie}</span>
                            <span className="authorization-number"><b>Numero:</b> {factura.dataBilling.numero}</span>
                            <span className="authorization-number"><b>Numero de Autorizacion:</b></span>
                            <span className="authorization-number">{factura.dataBilling.numeroAutorizacion}</span>
                        </React.Fragment>
                        :
                        <span>Esto no es una factura</span>
                    }
                    */}

                    {factura.numeroAutorizacion !== null &&
                        <React.Fragment>
                            <span className="authorization-number negro">Serie:{factura.dataBilling.serie}</span>
                            <span className="authorization-number negro">Numero: {factura.dataBilling.numero}</span>
                            <span className="authorization-number negro">Numero de Autorizacion:</span>
                            <span className="authorization-number negro">{factura.dataBilling.numeroAutorizacion}</span>
                        </React.Fragment>
                    }

                    {factura.numeroAutorizacion === null &&
                        <span>Esto no es una factura</span>
                    }

                    {factura.dataBilling.fel_status == 4 || factura.dataBilling.active === false ?
                        <span>ANULADO</span>
                        :
                        null
                    }


                <div className="d-flex flex-column w-100 justify-content-start mt-1 negro">
                    {this.props.datosFacturacion ?
                        <div><span className="">Nombre: </span> {this.props.datosFacturacion.name}</div>
                        :
                        <div><span className="">Nombre: </span> {factura.dataBilling.name}</div>
                    }

                    {this.props.datosFacturacion ?
                        <div><span className="">Nit: </span> {this.props.datosFacturacion.nit}</div>
                        :
                        <div><span className="">Nit: </span> {factura.dataBilling.nit}</div>
                    }
                </div>

                <table className="table table-sm table-preview">
                    <thead>
                        <tr className="fac-title-detail">
                            <td className="text-left" style={{borderTop:"solid 1px white"}}><span>  PRODUCTO</span></td>
                            <td className="text-right" style={{borderTop:"solid 1px white"}}><span>TOTAL</span></td>
                        </tr>
                    </thead>
                    <tbody>
                        {factura.services.map((product, i) =>
                            <React.Fragment key={i}>

                            <tr className="fac-row-detail">
                                <td className="space-none"><span>{product.quantity}&nbsp;&nbsp;</span><span>{product.service_name}</span>
                                    {product.discount > 0 && <em className="fac-row-discount">descuento</em> }
                                </td>
                                <td className="text-right space-none"><span><RenderCurrency value={product.price} /></span>
                                    {product.discount > 0 && <em className="fac-row-discount"><RenderCurrency value={product.discount} /></em>}
                                </td>
                            </tr>
                            {product.coupons.map((coupon,j) =>
                                <tr className="fac-row-detail" key={j}>
                                    <td className="space-none"><em className="fac-row-discount">Descuento cupón {coupon.code}</em></td>
                                    <td className="text-right space-none"><em className="fac-row-discount"><RenderCurrency value={coupon.amount_label} /></em>
                                    </td>
                                </tr>
                            )}
                            </React.Fragment>
                        )}

                        <tr className="fac-row-detail">
                            <td colSpan={2}><hr className="fac-division-line" /></td>
                        </tr>

                        {factura.calculate.descuento > 0 &&
                        <tr className="fac-row-descuento">
                            <td><span>Total de descuento</span></td>
                            <td className="text-right"><span><RenderCurrency value={factura.calculate.descuento} /></span></td>
                        </tr>
                        }

                        {parseFloat(factura.dataBilling.retention) > 0 &&
                        <tr className="fac-row-total">
                            <td><span>Pagado con retención</span></td>
                            <td className="text-right"><span><RenderCurrency value={parseFloat(factura.dataBilling.retention)}/></span></td>
                        </tr>
                        }
                        {/* {parseFloat(factura.calculate.monto_extra) > 0 &&

                        <tr className="fac-row-total">
                            {console.log('===============', factura.calculate)}
                            <td><span>Subtotal</span></td>
                            <td className="text-right"><span><RenderCurrency value={parseFloat(totalFactura) - parseFloat(factura.calculate.monto_extra)}/></span></td>
                        </tr>
                        } */}
                        <tr className="fac-row-total">
                            <td>
                                {factura.financial_entity.factura === 'recibo' || factura.financial_entity.factura === 'exento' || factura.financial_entity.factura === 'EXE'
                                    ? <span>TOTAL</span>
                                    : <span>TOTAL (IVA Incluido)</span>
                                }
                            </td>
                            {/*<td className="text-right"><span>Q {parseFloat(factura.calculate.total) + (parseFloat(factura.dataBilling.retention_amount) > 0) ? parseFloat(factura.dataBilling.retention_amount) : 0}</span></td>*/}
                            <td className="text-right"><span><RenderCurrency value={totalFactura} /></span></td>
                        </tr>

                        {/*
                        <tr className="fac-row-iva">
                            <td colSpan="2">
                                <div className="d-flex justify-content-around align-items-center">
                                <span>IVA</span>
                                <span>12.00%</span>
                                <span>de</span>
                                <span>7.45</span>
                                <span>=</span>
                                <span>0.75</span>
                                </div>
                            </td>
                        </tr>
                        */}

                        <tr className="fac-row-detail mt-2">
                            <td colSpan={2}><span>PX {factura.dataBilling.patient}</span></td>
                        </tr>

                    </tbody>
                </table>

                {factura.financial_entity.factura === 'recibo' || factura.financial_entity.factura === 'exento' || factura.financial_entity.factura === 'EXE'
                    ? <span></span>
                    :
                    <React.Fragment>
                        <span className="fac-footer text-center">SUJETO A RETENCIÓN DEFINITIVA</span>
                        <span className="fac-footer text-center">AGENTE DE RETENCIÓN DEL IVA</span>
                    </React.Fragment>
                }

                <span className="fac-footer text-center">{factura.dataBilling.fecha}</span>

                <span className="fac-footer text-center">{factura.financial_entity.name}</span>
                {factura.financial_entity.factura === 'recibo' || factura.financial_entity.factura === 'exento' || factura.financial_entity.factura === 'EXE' ?
                    <React.Fragment>
                        <span className="fac-footer text-center">Edificio Las Tapias, 5 Calle 14-35 zona 3, Oficina 106 Quetzaltenango</span>
                        <br />
                        <span className='negro text-center'>EXENTA DEL IVA (ART. 7 NUM. 9 LEY DEL IVA)</span>

                        <br/>
                        <div className="fac-footer text-center div-round">
                            {/* eslint-disable-next-line max-len */}
                            Inscrita en la Superintendencia de Administración Tributaria SAT como Persona Jurídica no afecta.
                            <br />
                            Resolución SAT-GRC-DRG R-2012-03-01-000661 de fecha 08 de mayo de 2012.
                        </div>
                    </React.Fragment>
                :
                    <span className="fac-footer text-center">Edificio Las Tapias, 5 Calle 14-35 zona 3, Oficina 107 Quetzaltenango</span>
                }
            </div>
        );
    }
}

export default Factura;
