import React, { Component } from 'react';
import { api } from "api";
import validate from './validation';
import { Link } from 'react-router-dom';
import { departamentos } from 'variables';
import { Field, reduxForm, formValueSelector } from 'redux-form';
import { renderField, renderDatePicker, renderSearchSelect, renderSelectCustom, renderSelectCustomSmall, renderSearchSelectPatient, renderSwitch } from 'Utils/renderField/renderField';
import { connect } from 'react-redux'
import newDatePicker from '../Utils/NewDatepicker/';

let persons = []
let patients = []

const getPersons = (search) => {
    return api.get("referents/listDoctors", {search}).catch((error) => {})
        .then((data) => {
            if (data){
                persons = [];
                data.results.forEach(x=>{
                    persons.push({value: x.id, label: x.name})
                })
                return persons
            }
            else{
                return []
            }
        })
        .catch(e => {
            return []
        })
}

const getPatients = (search) => {
    return api.get("patients", {search}).catch((error) => {})
        .then((data) => {
            if (data){
                patients = [];
                data.results.forEach(x=>{
                    patients.push({value: x.id, label: x.first_name + " " + x.last_name})
                })
                return patients
            }
            else{
                return []
            }
        })
        .catch(e => {
            return []
        })
}

const mayuscula = value => value.toUpperCase();

const Form = props => {
    const {
        municipios,
        handleSubmit,
        search_patient,
        showPatientSearch,
        no_patient_options,
        showPatientOptions,
        detailPatient,
        setUpdateData,
        initializePatientBirthdate,
    } = props;

    const view = window.location.href.includes("view");

    return(
        <form onSubmit={handleSubmit} className="pb-3 pt-5 d-flex justify-content-center">
            <div className="form-body-width">
                <div className="d-flex justify-content-between flex-column flex-sm-row align-items-stretch align-items-sm-center">
                    <div className="m-2">
                        <div className="d-flex justify-content-start flex-column flex-sm-row align-items-stretch align-items-sm-center">
                            <label htmlFor="appointment_holder" className="">Nombre de quien agenda</label>
                        </div>
                        <div className=" d-flex justify-content-start flex-column flex-sm-row align-items-stretch align-items-sm-center">
                            <Field name="appointment_holder" autofocus disabled={view} component={renderField} type="text" className="form-control" normalize={mayuscula} />
                        </div>
                    </div>
                    <div className="m-2">
                        <div className=" d-flex justify-content-start flex-column flex-sm-row align-items-stretch align-items-sm-center">
                            <label htmlFor="patient_name" className="">Nombre del paciente</label>
                        </div>
                        <div className=" d-flex justify-content-end flex-column flex-sm-row align-items-stretch align-items-sm-center">
                            <Field name="patient_name" disabled={view} component={renderField} type="text" className="form-control"/>
                        </div>
                    </div>
                </div>
                <div className="row-fluid d-flex justify-content-between flex-column flex-sm-row align-items-stretch align-items-sm-center">
                    <div className="m-2">
                        <div className="d-flex justify-content-start flex-column flex-sm-row align-items-stretch align-items-sm-center">
                            <label htmlFor="phone_holder" className="">Teléfono</label>
                        </div>
                        <div className="d-flex justify-content-start flex-column flex-sm-row align-items-stretch align-items-sm-center">
                            <Field name="phone_holder" disabled={view} component={renderField} type="number" className="form-control"/>
                        </div>
                    </div>
                    <div className="m-2">
                        <div className="d-flex justify-content-start flex-column flex-sm-row align-items-stretch align-items-sm-center">
                            <label htmlFor="cellphone_holder" className="">Celular</label>
                        </div>
                        <div className="d-flex justify-content-start flex-column flex-sm-row align-items-stretch align-items-sm-center">
                            <Field name="cellphone_holder" disabled={view} component={renderField} type="number" className="form-control"/>
                        </div>
                    </div>                  
                </div>
                <div className="d-flex justify-content-between flex-column flex-sm-row align-items-stretch align-items-sm-center">
                    <div className="m-2">
                        <div className="d-flex justify-content-start flex-column flex-sm-row align-items-stretch align-items-sm-center">
                            <label htmlFor="cellphone_holder" className="">Descripción</label>
                        </div>
                        <div className="d-flex justify-content-start flex-column flex-sm-row align-items-stretch align-items-sm-center">
                            <Field name="note" disabled={view} component={renderField} type="text" className="form-control"/>
                        </div>
                    </div>                     
                    <div className="m-2">
                        <div className="d-flex justify-content-start flex-column flex-sm-row align-items-stretch align-items-sm-center">
                            <label htmlFor="cellphone_holder" className="">Requerimientos</label>
                        </div>
                        <div className="d-flex justify-content-start flex-column flex-sm-row align-items-stretch align-items-sm-center">
                            <Field name="requirements" disabled={view} component={renderField} type="text" className="form-control" placeholder='Ej. debe venir en ayunas.' />
                        </div>
                    </div>  
                </div>
                <div className="d-flex justify-content-between flex-column flex-sm-row align-items-stretch align-items-sm-center">
                    <div className="m-2">
                        <div className="d-flex justify-content-start flex-column flex-sm-row align-items-stretch align-items-sm-center">
                            <label htmlFor="doctor" className="">Médico para consulta</label>
                        </div>
                        <div className=" d-flex justify-content-end flex-column flex-sm-row align-items-stretch align-items-sm-center">
                            <Field
                                name="doctor"
                                valueKey="value"
                                labelKey="label"
                                label="name"
                                component={renderSearchSelect}
                                loadOptions={getPersons}
                                className="no-border"
                            />
                        </div>
                    </div>
                    <div className="m-2 invisible">
                        <div className=" d-flex justify-content-start flex-column flex-sm-row align-items-stretch align-items-sm-center">
                            <label htmlFor="patient_name" className="">Nombre del paciente</label>
                        </div>
                        <div className=" d-flex justify-content-end flex-column flex-sm-row align-items-stretch align-items-sm-center">
                            <Field name="patient_name" disabled={view} component={renderField} type="text" className="form-control"/>
                        </div>
                    </div>
                </div>

                <div className="row-fluid d-flex justify-content-between flex-column flex-sm-row align-items-stretch align-items-sm-center">
                    <div className="mx-2 mt-2 mb-4 w-100">
                        <div className="d-flex justify-content-start flex-column flex-sm-row align-items-stretch align-items-sm-center">
                            <label htmlFor="" className="mr-3">Fecha de consulta</label>
                        </div>
                        <div className="d-flex justify-content-between flex-column flex-sm-row align-items-stretch align-items-sm-center">
                            <div className="mr-3">
                                {/*<Field name="date" isDisabled={view} component={renderDatePicker} type="text"/>*/}
                                <Field name="date" isDisabled={view} component={newDatePicker} type="text"/>
                            </div>
                            <div className="d-flex flex-inline align-items-center">
                                <div className="">
                                    <Field name="start" disabled={view} component={renderSelectCustomSmall} options={[
                                        // {value:0.0, label:'00:00'}, {value:0.5, label:'00:30'},
                                        // {value:1.0, label:'01:00'}, {value:1.5, label:'01:30'},
                                        // {value:2.0, label:'02:00'}, {value:2.5, label:'02:30'},
                                        // {value:3.0, label:'03:00'}, {value:3.5, label:'03:30'},
                                        // {value:4.0, label:'04:00'}, {value:4.5, label:'04:30'},
                                        // {value:5.0, label:'05:00'}, {value:5.5, label:'05:30'},
                                        // {value:6.0, label:'06:00'}, {value:6.5, label:'06:30'},
                                        {value:7.0, label:'07:00'}, {value:7.5, label:'07:30'},
                                        {value:8.0, label:'08:00'}, {value:8.5, label:'08:30'},
                                        {value:9.0, label:'09:00'}, {value:9.5, label:'09:30'},

                                        {value:10.0, label:'10:00'}, {value:10.5, label:'10:30'},
                                        {value:11.0, label:'11:00'}, {value:11.5, label:'11:30'},
                                        {value:12.0, label:'12:00'}, {value:12.5, label:'12:30'},
                                        {value:13.0, label:'13:00'}, {value:13.5, label:'13:30'},
                                        {value:14.0, label:'14:00'}, {value:14.5, label:'14:30'},
                                        {value:15.0, label:'15:00'}, {value:15.5, label:'15:30'},
                                        {value:16.0, label:'16:00'}, {value:16.5, label:'16:30'},
                                        {value:17.0, label:'17:00'}, {value:17.5, label:'17:30'},
                                        {value:18.0, label:'18:00'}, {value:18.5, label:'18:30'},
                                        // {value:19.0, label:'19:00'}, {value:19.5, label:'19:30'},
                                        // {value:20.0, label:'20:00'}, {value:20.5, label:'20:30'},
                                        // {value:21.0, label:'21:00'}, {value:21.5, label:'21:30'},
                                        // {value:22.0, label:'22:00'}, {value:22.5, label:'22:30'},
                                        // {value:23.0, label:'23:00'}, {value:23.5, label:'23:30'},
                                    ]}

                                    cambio={(start) => {
                                        const end_value = start.value + 0.5;
                                        const end_label = end_value % 1 === 0 ? end_value.toString() + ':00' : start.value.toString() + ':30';
                                        props.change('end', {value: end_value, label: end_label});
                                    }}
                                    />
                                </div>
                                <div className="ml-2 mr-2">
                                    <label htmlFor="hour" className="m-auto switch-label">Hrs.</label>
                                </div>
                                <div className="mx-2">
                                    <label htmlFor="hour" className="m-auto switch-label">A</label>
                                </div>
                                <div className="ml-2">
                                    <Field name="end" disabled={view} component={renderSelectCustomSmall} options={[
                                        // {value:0.5, label:'00:30'},
                                        // {value:1.0, label:'01:00'}, {value:1.5, label:'01:30'},
                                        // {value:2.0, label:'02:00'}, {value:2.5, label:'02:30'},
                                        // {value:3.0, label:'03:00'}, {value:3.5, label:'03:30'},
                                        // {value:4.0, label:'04:00'}, {value:4.5, label:'04:30'},
                                        // {value:5.0, label:'05:00'}, {value:5.5, label:'05:30'},
                                        // {value:6.0, label:'06:00'}, {value:6.5, label:'06:30'},
                                        // {value:7.0, label:'07:00'},

                                        {value:7.5, label:'07:30'},
                                        {value:8.0, label:'08:00'}, {value:8.5, label:'08:30'},
                                        {value:9.0, label:'09:00'}, {value:9.5, label:'09:30'},

                                        {value:10.0, label:'10:00'}, {value:10.5, label:'10:30'},
                                        {value:11.0, label:'11:00'}, {value:11.5, label:'11:30'},
                                        {value:12.0, label:'12:00'}, {value:12.5, label:'12:30'},
                                        {value:13.0, label:'13:00'}, {value:13.5, label:'13:30'},
                                        {value:14.0, label:'14:00'}, {value:14.5, label:'14:30'},
                                        {value:15.0, label:'15:00'}, {value:15.5, label:'15:30'},
                                        {value:16.0, label:'16:00'}, {value:16.5, label:'16:30'},
                                        {value:17.0, label:'17:00'}, {value:17.5, label:'17:30'},
                                        {value:18.0, label:'18:00'}, {value:18.5, label:'18:30'},
                                        {value:19.0, label:'19:00'},

                                        // {value:19.5, label:'19:30'},
                                        // {value:20.0, label:'20:00'}, {value:20.5, label:'20:30'},
                                        // {value:21.0, label:'21:00'}, {value:21.5, label:'21:30'},
                                        // {value:22.0, label:'22:00'}, {value:22.5, label:'22:30'},
                                        // {value:23.0, label:'23:00'}, {value:23.5, label:'23:30'},
                                        // {value:24.0, label:'24:00'}
                                    ]}/>
                                </div>
                                <div className="ml-2">
                                    <label htmlFor="hour" className="m-auto switch-label">Hrs.</label>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="mt-3 mx-2 mb-4 row-fluid d-flex justify-content-center flex-column flex-sm-row align-items-stretch align-items-sm-center">
                    <div className="patient-label mr-md-3">
                        <div className="d-flex justify-content-start flex-column flex-sm-row align-items-stretch align-items-sm-center">
                            <label className="m-0">DATOS DEL PACIENTE</label>
                        </div>
                    </div>
                    <div className="line-patient mr-3 d-flex justify-content-start flex-column flex-sm-row align-items-stretch align-items-sm-center w-100">
                        {/* This empty div is important to show the line among each category-prices row  */}
                    </div>
                    <div onClick={showPatientOptions} className={`${no_patient_options == true ? 'patient-up-icon' : 'patient-down-icon'} my-2`}>
                        <div></div>
                    </div>
                </div>
                {
                    !no_patient_options &&
                        <React.Fragment>
                            {
                                search_patient ?
                                    <React.Fragment>
                                        <div className="px-md-5 mt-md-5 mb-md-4 row-fluid d-flex justify-content-around flex-column flex-sm-row align-items-stretch align-items-sm-center">
                                            <div className="m-2">
                                                <div className="d-flex justify-content-start flex-column flex-sm-row align-items-stretch align-items-sm-center">
                                                    <label htmlFor="person" className="">Buscar y seleccionar paciente</label>
                                                </div>
                                                <div className=" d-flex justify-content-end flex-column flex-sm-row align-items-stretch align-items-sm-center">
                                                    <Field
                                                        name="petient_search"
                                                        valueKey="value"
                                                        labelKey="label"
                                                        label="name"
                                                        component={renderSearchSelectPatient}
                                                        loadOptions={getPatients}
                                                        className="no-border"
                                                        onChange={(e) => { showPatientSearch(); detailPatient(e.value);}}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="px-md-5 mb-md-5 row-fluid d-flex justify-content-around flex-column flex-sm-row align-items-stretch align-items-sm-center">
                                            <div className="m-2">
                                                <div className="mb-md-5 d-flex justify-content-start flex-column flex-sm-row align-items-stretch align-items-sm-center">
                                                    <div className="mx-4 line-price d-flex justify-content-start flex-column flex-sm-row align-items-stretch align-items-sm-center w-100">
                                                        {/* This empty div is important to show the line among each category-prices row  */}
                                                    </div>
                                                    <label htmlFor="line" className="m-0">O</label>
                                                    <div className="mx-4 line-price d-flex justify-content-start flex-column flex-sm-row align-items-stretch align-items-sm-center w-100">
                                                        {/* This empty div is important to show the line among each category-prices row  */}
                                                    </div>
                                                </div>
                                                <div className="d-flex justify-content-start flex-column flex-sm-row align-items-stretch align-items-sm-center">
                                                    <button onClick={initializePatientBirthdate} className="btn btn-primary mb-md-2 search-patient-button">Crear paciente</button>
                                                </div>
                                            </div>
                                        </div>
                                    </React.Fragment>
                            :
                                <React.Fragment>
                                    <div className="d-flex justify-content-between flex-column flex-sm-row align-items-stretch align-items-sm-center">
                                        <div className="m-2">
                                            <div className="d-flex justify-content-start flex-column flex-sm-row align-items-stretch align-items-sm-center">
                                                <label htmlFor="first_name" className="">Nombre*</label>
                                            </div>
                                            <div className=" d-flex justify-content-start flex-column flex-sm-row align-items-stretch align-items-sm-center">
                                                <Field name="first_name" disabled={view} component={renderField} type="text" className="form-control"/>
                                            </div>
                                        </div>
                                        <div className="m-2">
                                            <div className=" d-flex justify-content-start flex-column flex-sm-row align-items-stretch align-items-sm-center">
                                                <label htmlFor="last_name" className="">Apellidos*</label>
                                            </div>
                                            <div className=" d-flex justify-content-end flex-column flex-sm-row align-items-stretch align-items-sm-center">
                                                <Field name="last_name" disabled={view} component={renderField} type="text" className="form-control"/>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="d-flex justify-content-between flex-column flex-sm-row align-items-stretch align-items-sm-center">
                                        <div className="m-2">
                                            <div className="d-flex justify-content-start flex-column flex-sm-row align-items-stretch align-items-sm-center">
                                                <label htmlFor="married_last_name" className="">Apellido de casada</label>
                                            </div>
                                            <div className=" d-flex justify-content-start flex-column flex-sm-row align-items-stretch align-items-sm-center">
                                                <Field name="married_last_name" disabled={view} component={renderField} type="text" className="form-control"/>
                                            </div>
                                        </div>
                                        <div className="m-2">
                                            <div className=" d-flex justify-content-start flex-column flex-sm-row align-items-stretch align-items-sm-center">
                                                <label htmlFor="birthdate" className="">Fecha de nacimiento*</label>
                                            </div>
                                            <div className=" d-flex justify-content-end flex-column flex-sm-row align-items-stretch align-items-sm-center">
                                                <Field name="birthdate" isDisabled={view} component={renderDatePicker} type="text" className="form-control" />
                                            </div>
                                        </div>
                                    </div>

                                    <div className="d-flex justify-content-between flex-column flex-sm-row align-items-stretch align-items-sm-center">
                                        <div className="m-2">
                                            <div className="d-flex justify-content-start flex-column flex-sm-row align-items-stretch align-items-sm-center">
                                                <label htmlFor="gender" className="">Género*</label>
                                            </div>
                                            <div className=" d-flex justify-content-start flex-column flex-sm-row align-items-stretch align-items-sm-center">
                                                <Field name="gender" disabled={view} component={renderSelectCustom} options={[
                                                    {value:1, label:'Masculino'},
                                                    {value:2, label:'Femenino'},
                                                ]}/>
                                            </div>
                                        </div>
                                        <div className="m-2">
                                            <div className=" d-flex justify-content-start flex-column flex-sm-row align-items-stretch align-items-sm-center">
                                                <label htmlFor="nationality" className="">Nacionalidad*</label>
                                            </div>
                                            <div className=" d-flex justify-content-end flex-column flex-sm-row align-items-stretch align-items-sm-center">
                                                <Field name="nationality" disabled={view} component={renderField} type="text" className="form-control"/>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="d-flex justify-content-between flex-column flex-sm-row align-items-stretch align-items-sm-center">
                                        <div className="m-2">
                                            <div className="d-flex justify-content-start flex-column flex-sm-row align-items-stretch align-items-sm-center">
                                                <label htmlFor="departamento" className="">Departamento</label>
                                            </div>
                                            <div className=" d-flex justify-content-start flex-column flex-sm-row align-items-stretch align-items-sm-center">
                                                <Field name="departamento" disabled={view} component={renderSelectCustom} options={departamentos}/>
                                            </div>
                                        </div>
                                        <div className="m-2">
                                            <div className=" d-flex justify-content-start flex-column flex-sm-row align-items-stretch align-items-sm-center">
                                                <label htmlFor="municipio" className="">Municipio</label>
                                            </div>
                                            <div className=" d-flex justify-content-end flex-column flex-sm-row align-items-stretch align-items-sm-center">

                                                <Field name="municipio" disabled={view} component={renderSelectCustom} options={municipios}/>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="d-flex justify-content-between flex-column flex-sm-row align-items-stretch align-items-sm-center">
                                        <div className="m-2">
                                            <div className="d-flex justify-content-start flex-column flex-sm-row align-items-stretch align-items-sm-center">
                                                <label htmlFor="address" className="">Dirección</label>
                                            </div>
                                            <div className=" d-flex justify-content-start flex-column flex-sm-row align-items-stretch align-items-sm-center">
                                                <Field name="address" disabled={view} component={renderField} type="text" className="form-control"/>
                                            </div>
                                        </div>
                                        <div className="m-2">
                                            <div className=" d-flex justify-content-start flex-column flex-sm-row align-items-stretch align-items-sm-center">
                                                <label htmlFor="dpi" className="">DPI</label>
                                            </div>
                                            <div className=" d-flex justify-content-end flex-column flex-sm-row align-items-stretch align-items-sm-center">
                                                <Field name="dpi" disabled={view} component={renderField} type="text" className="form-control"/>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="d-flex justify-content-between flex-column flex-sm-row align-items-stretch align-items-sm-center">
                                        <div className="m-2">
                                            <div className="d-flex justify-content-start flex-column flex-sm-row align-items-stretch align-items-sm-center">
                                                <label htmlFor="profession" className="">Profesión</label>
                                            </div>
                                            <div className=" d-flex justify-content-start flex-column flex-sm-row align-items-stretch align-items-sm-center">
                                                <Field name="profession" disabled={view} component={renderField} type="text" className="form-control"/>
                                            </div>
                                        </div>
                                        <div className="m-2">
                                            <div className=" d-flex justify-content-start flex-column flex-sm-row align-items-stretch align-items-sm-center">
                                                <label htmlFor="family_member_of" className="">Médico o familiar de médico</label>
                                            </div>
                                            <div className=" d-flex justify-content-end flex-column flex-sm-row align-items-stretch align-items-sm-center">
                                                <Field
                                                disabled={view}
                                                    name="family_member_of"
                                                    valueKey="value"
                                                    labelKey="label"
                                                    label="name"
                                                    component={renderSearchSelect}
                                                    loadOptions={getPersons}
                                                    className="no-border"
                                                />
                                            </div>
                                        </div>
                                    </div>

                                    <div className="d-flex justify-content-between flex-column flex-sm-row align-items-stretch align-items-sm-center">
                                        <div className="m-2">
                                            <div className="d-flex justify-content-start flex-column flex-sm-row align-items-stretch align-items-sm-center">
                                                <label htmlFor="email" className="">Correo electrónico</label>
                                            </div>
                                            <div className=" d-flex justify-content-start flex-column flex-sm-row align-items-stretch align-items-sm-center">
                                                <Field name="email" disabled={view} component={renderField} type="text" className="form-control"/>
                                            </div>
                                        </div>
                                        <div className="m-2">
                                            <div className=" d-flex justify-content-start flex-column flex-sm-row align-items-stretch align-items-sm-center">
                                                <label htmlFor="phone" className="">Teléfono de línea fija</label>
                                            </div>
                                            <div className=" d-flex justify-content-end flex-column flex-sm-row align-items-stretch align-items-sm-center">
                                                <Field name="phone" disabled={view} component={renderField} type="text" className="form-control"/>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="mb-md-4 d-flex justify-content-between flex-column flex-sm-row align-items-stretch align-items-sm-center">
                                        <div className="m-2">
                                            <div className="d-flex justify-content-start flex-column flex-sm-row align-items-stretch align-items-sm-center">

                                                <label htmlFor="cellphone" className="">Teléfono celular</label>
                                            </div>
                                            <div className=" d-flex justify-content-start flex-column flex-sm-row align-items-stretch align-items-sm-center">
                                                <Field name="cellphone" disabled={view} component={renderField} type="text" className="form-control"/>
                                            </div>
                                        </div>
                                        <div className="m-2">
                                            <div className=" d-flex justify-content-start flex-column flex-sm-row align-items-stretch align-items-sm-center">
                                                <label htmlFor="referred_by" className="">Referente</label>
                                            </div>
                                            <div className=" d-flex justify-content-end flex-column flex-sm-row align-items-stretch align-items-sm-center">
                                                <Field
                                                    disabled={view}
                                                    name="referred_by"
                                                    valueKey="value"
                                                    labelKey="label"
                                                    label="name"
                                                    component={renderSearchSelect}
                                                    loadOptions={getPersons}
                                                    className="no-border"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </React.Fragment>
                            }
                        </React.Fragment>
                }
                <div className="row-fluid">
                    <div className="d-flex justify-content-center flex-column flex-sm-row align-items-stretch align-items-sm-center">
                        <Link className="btn btn-secondary m-3" onClick={() => {if(setUpdateData) setUpdateData({})}} to="/schedule">{!view ? ("Cancelar") : ("Regresar")}</Link>
                    {!view && (<button type="submit" onClick={() => {if(setUpdateData) setUpdateData({})}} className="btn btn-primary m-3">Guardar</button>)}
                    </div>
                </div>
            </div>
        </form>
    )
};

let AppointmentsForm = reduxForm({
    form: 'appointments',
    validate,
})(Form);

export default AppointmentsForm;
