import React from 'react';
import {Link} from 'react-router-dom';
import {Field, reduxForm} from 'redux-form';
import {validate} from 'validate-redux-form';
import {modalTextArea, renderField, render} from "../../Utils/renderField/renderField";
import {Notations} from "../../Utils/Notations";


const Form = (props) => {
    const {handleSubmit} = props;
    const view = window.location.href.includes("reporte");

    return (
        <form onSubmit={handleSubmit} className="d-flex flex-column">
            {!view ? <h3><strong>AGUDEZA VISUAL</strong></h3> : null}
            <div className="d-flex flex-column justify-content-center px-0 px-sm-2 px-md-3 px-lg-5">
                <table className="table-input">
                    <tbody>
                    <tr>
                        <th />
                        <th>
                            <span className='avl-azul'>AVL sc</span>
                        </th>
                        <th>
                            <span className='avl-azul'>AVL cc</span>
                        </th>
                        <th>
                            <span className='avl-verde'>AVC sc</span>
                        </th>
                        <th>
                            <span className='avl-verde'>AVC cc</span>
                        </th>
                        <th>
                            <span className='avl-rojo'>AVL ph</span>
                        </th>
                    </tr>
                    <tr>
                        <td>OD</td>
                        <td>
                            <Field name="od_avlsc" jaeger component={!view ? Notations : renderField} disabled={view} title={"AVL sc"} className="form-control"/>
                        </td>
                        <td>
                            <Field name="od_avlcc" jaeger component={!view ? Notations : renderField} disabled={view} title={"AVL cc"} className="form-control color-verde"/>
                        </td>
                        <td>
                            <Field name="od_avcsc" jaeger component={!view ? Notations : renderField} disabled={view} title={"AVC sc"} className="form-control"/>
                        </td>
                        <td>
                            <Field name="od_avccc" jaeger component={!view ? Notations : renderField} disabled={view} title={"AVC cc"} className="form-control"/>
                        </td>
                        <td>
                            <Field name="od_avlph" jaeger component={!view ? Notations : renderField} disabled={view} title={"AVL ph"} className="form-control"/>
                        </td>
                    </tr>
                    <tr>
                        <td>OS</td>
                        <td>
                            <Field name="os_avlsc" jaeger component={!view ? Notations : renderField} disabled={view} title={"AVL sc"} className="form-control"/>
                        </td>
                        <td>
                            <Field name="os_avlcc" jaeger component={!view ? Notations : renderField} disabled={view} title={"AVL cc"} className="form-control"/>
                        </td>
                        <td>
                            <Field name="os_avcsc" jaeger component={!view ? Notations : renderField} disabled={view} title={"AVC sc"} className="form-control"/>
                        </td>
                        <td>
                            <Field name="os_avccc" jaeger component={!view ? Notations : renderField} disabled={view} title={"AVC cc"} className="form-control"/>
                        </td>
                        <td>
                            <Field name="os_avlph" jaeger component={!view ? Notations : renderField} disabled={view} title={"AVL ph"} className="form-control"/>
                        </td>
                    </tr>
                    </tbody>
                </table>
                <div className='d-flex flex-row justify-content-center'>
                    <div className="mb-md-4 d-flex justify-content-between flex-column flex-sm-row align-items-stretch align-items-sm-center mt-4 w-50">
                        <div className="m-2 d-flex flex-column w-100">
                            <div className="d-flex justify-content-start flex-column flex-sm-row align-items-stretch align-items-sm-center">
                                <label htmlFor="notes">Notas</label>
                            </div>
                            <div className='d-flex flex-row'>
                                <label className="radio-inline mr-3 d-flex align-items-center">
                                    OD&nbsp;
                                    <Field
                                        name="type"
                                        component={renderField}
                                        type="radio"
                                        value={"1"}
                                        disabled={view}
                                    />
                                </label>
                                <label className="radio-inline mr-3 d-flex align-items-center">
                                    OS&nbsp;
                                    <Field
                                        name="type"
                                        component={renderField}
                                        type="radio"
                                        value={"2"}
                                        disabled={view}
                                    />
                                </label>
                                <label className="radio-inline mr-3 d-flex align-items-center">
                                    OU&nbsp;
                                    <Field
                                        name="type"
                                        component={renderField}
                                        type="radio"
                                        value={"3"}
                                        disabled={view}
                                    />
                                </label>
                            </div>
                            <div className="d-flex flex-row w-100">
                                <div className="d-flex flex-row w-75">
                                <Field
                                    name="notes"
                                    component={modalTextArea}
                                    className="annotations"
                                    disabled={view}
                                />
                                </div>
                                <div className='d-flex flex-row w-25 ml-3'>
                                    <button
                                        type="button"
                                        className='btn btn-danger'
                                        onClick={()=>{props.change('notes', 'DIFERIDO');}}
                                    >DIFERIDO</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    )
};

const CrearForm = reduxForm({
    // a unique name for the form
    form: 'VisualAcuity',
    validate: (data) => {
        return validate(data, {
        });
    },
})(Form);

export default CrearForm;
