import React, {Component} from 'react';
import Modal from 'react-responsive-modal';
import classNames from 'classnames';
import "./notations.css";

export class Notations extends Component {
    constructor(props) {
        super(props);
        this.state = { open: false, symbol: '', number: 0};
    }

    openModal = () => {
        this.setState({ open: true, symbol: '', number: 0});
    };

    closeModal = () => {
        this.setState({ open: false, symbol: '', number: 0 });
    };

    selectOption = (input, value) => {
        
        if(this.state.symbol !== '' && this.state.number > 0){            
            input.onChange(`${value} ${this.state.symbol} ${this.state.number}`);
        }else{
            input.onChange(value);
        }
        
        this.closeModal();
    };

    render() {
        const { input, meta: { touched, error }, title, jaeger } = this.props;
        const { open } = this.state;
        const invalid = touched && error;

        let classColor = '';
        if(title === "AVL ph"){
            classColor = 'avl-rojo';
        }else if(title.includes('AVL')){
            classColor = 'avl-azul';
        }else if(title.includes('AVC')){
            classColor = 'avl-verde';
        }

        return (
            <React.Fragment>
                <Modal open={open} onClose={this.closeModal} >
                    <div className="text-center">
                        <h2 className="border-bottom">{title}</h2>
                    </div>
                    <div className="text-center">
                        <strong className="">Notaciones equivalentes</strong>
                        <div className="d-flex flex-row justify-content-center my-3">
                            <div className="radio">
                                <label htmlFor="service_type" className="radio-inline mr-5 d-flex align-items-center"
                                       onClick={() => this.selectOption(input, "(NPL)")}>
                                    <input type={"radio"} value={"(NPL)"} {...input} onChange={() => this.selectOption(input, "(NPL)")}
                                           className={classNames('form-control', { 'is-invalid': invalid })} />
                                    &nbsp;(NPL)
                                </label>
                            </div>
                            <div className="radio">
                                <label htmlFor="service_type" className="radio-inline mr-5 d-flex align-items-center"
                                       onClick={() => this.selectOption(input, "(PL)")}>
                                    <input type={"radio"} value={"(PL)"} {...input} onChange={() => this.selectOption(input, "(PL)")}
                                           className={classNames('form-control', { 'is-invalid': invalid })} />
                                    &nbsp;(PL)
                                </label>
                            </div>
                            <div className="radio">
                                <label htmlFor="service_type" className="radio-inline mr-5 d-flex align-items-center"
                                       onClick={() => this.selectOption(input, "(MM)")}>
                                    <input type={"radio"} value={"(MM)"} {...input} onChange={() => this.selectOption(input, "(MM)")}
                                           className={classNames('form-control', { 'is-invalid': invalid })} />
                                    &nbsp;(MM)
                                </label>
                            </div>
                        </div>
                    </div>
                    <div>
                        
                    <div 
                        style={{ 
                            display: "flex",
                            gap: "8px",
                            height: "40px",
                            justifyContent: "center",
                            marginBottom: "8px"
                        }}>
                        <button                                
                            style={{
                                fontSize: "20px",
                                paddingInline: '10px',
                                borderRadius: '25px',
                                border: this.state.symbol == '-' ? 'none' : 'solid 1px gray', 
                                background: this.state.symbol == '-' ? 'green' : '',
                                color: this.state.symbol == '-' ? 'white' : '',
                                boxSizing: "border-box"
                            }}
                            onClick={()=>{
                                this.setState({ symbol: '-' })
                            }}
                        > - </button>
                        <input 
                            max={10}
                            min={0}
                            onChange={(e) => this.setState({ number: e.target.value })}
                            type="number" 
                            style={{ width: "75px", padding: "5px", fontSize: "20px",
                            boxSizing: "border-box" }}
                        />
                        <button
                            style={{
                                fontSize: "20px",
                                paddingInline: '10px',
                                borderRadius: '25px',
                                border: this.state.symbol == '+' ? 'none' : 'solid 1px gray', 
                                background: this.state.symbol == '+' ? 'green' : '',
                                color: this.state.symbol == '+' ? 'white' : '',
                                boxSizing: "border-box"
                            }}
                            onClick={()=>{
                                this.setState({ symbol: '+' })
                            }
                        }> + </button>                        
                    </div>
                        
                    </div>
                    <table id="notations-table-modal">
                        <tr>
                            <th>Decimal</th>
                            <th>US</th>
                            <th>CD</th>
                            <th>X/200</th>
                            {jaeger && <th>JAEGER</th>}
                        </tr>
                        <tbody>
                        <tr>
                            <td>
                                {/*
                                <div className="radio">
                                    <label htmlFor="service_type" className="radio-inline mr-5 d-flex align-items-center"
                                           onClick={() => this.selectOption(input, "1.6")}>
                                        <input type={"radio"} value={"1.6"} {...input} onChange={() => this.selectOption(input, "1.6")}
                                               className={classNames('form-control', { 'is-invalid': invalid })} />
                                        &nbsp;1.6
                                    </label>
                                </div>
                                */}
                                <div className="radio">
                                    <label htmlFor="service_type" className="radio-inline mr-5 d-flex align-items-center"
                                           onClick={() => this.selectOption(input, "0.05")}>
                                        <input type={"radio"} value={"0.05"} {...input} onChange={() => this.selectOption(input, "0.05")}
                                               className={classNames('form-control', { 'is-invalid': invalid })} />
                                        &nbsp;0.05
                                    </label>
                                </div>
                            </td>
                            <td>
                                <div className="radio">
                                    <label htmlFor="service_type" className="radio-inline mr-5 d-flex align-items-center"
                                           onClick={() => this.selectOption(input, "20/12.5")}>
                                        <input type={"radio"} value={"20/12.5"} {...input} onChange={() => this.selectOption(input, "20/12.5")}
                                               className={classNames('form-control', { 'is-invalid': invalid })} />
                                        &nbsp;20/12.5
                                    </label>
                                </div>
                            </td>
                            <td>
                                <div className="radio">
                                    <label htmlFor="service_type" className="radio-inline mr-5 d-flex align-items-center"
                                           onClick={() => this.selectOption(input, "0.1m")}>
                                        <input type={"radio"} value={"0.1m"} {...input} onChange={() => this.selectOption(input, "0.1m")}
                                               className={classNames('form-control', { 'is-invalid': invalid })} />
                                        &nbsp;0.1m
                                    </label>
                                </div>
                            </td>
                            <td>
                                <div className="radio">
                                    <label htmlFor="service_type" className="radio-inline mr-5 d-flex align-items-center"
                                           onClick={() => this.selectOption(input, "1/200")}>
                                        <input type={"radio"} value={"1/200"} {...input} onChange={() => this.selectOption(input, "1/200")}
                                               className={classNames('form-control', { 'is-invalid': invalid })} />
                                        &nbsp;1/200
                                    </label>
                                </div>
                            </td>
                            <td>
                                {jaeger && <div className="radio">
                                    <label htmlFor="service_type" className="radio-inline mr-5 d-flex align-items-center"
                                           onClick={() => this.selectOption(input, "J16")}>
                                        <input type={"radio"} value={"J16"} {...input} onChange={() => this.selectOption(input, "J16")}
                                               className={classNames('form-control', { 'is-invalid': invalid })} />
                                        &nbsp;J16
                                    </label>
                                </div>}
                            </td>
                        </tr>
                        <tr>
                            <td>
                                {/*
                                <div className="radio">
                                    <label htmlFor="service_type" className="radio-inline mr-5 d-flex align-items-center"
                                           onClick={() => this.selectOption(input, "1.25")}>
                                        <input type={"radio"} value={"1.25"} {...input} onChange={() => this.selectOption(input, "1.25")}
                                               className={classNames('form-control', { 'is-invalid': invalid })} />
                                        &nbsp;1.25
                                    </label>
                                </div>
                                */}
                                <div className="radio">
                                    <label htmlFor="service_type" className="radio-inline mr-5 d-flex align-items-center"
                                           onClick={() => this.selectOption(input, "0.1")}>
                                        <input type={"radio"} value={"0.1"} {...input} onChange={() => this.selectOption(input, "0.1")}
                                               className={classNames('form-control', { 'is-invalid': invalid })} />
                                        &nbsp;0.1
                                    </label>
                                </div>
                            </td>
                            <td>
                                <div className="radio">
                                    <label htmlFor="service_type" className="radio-inline mr-5 d-flex align-items-center"
                                           onClick={() => this.selectOption(input, "20/16")}>
                                        <input type={"radio"} value={"20/16"} {...input} onChange={() => this.selectOption(input, "20/16")}
                                               className={classNames('form-control', { 'is-invalid': invalid })} />
                                        &nbsp;20/16
                                    </label>
                                </div>
                            </td>
                            <td>
                                <div className="radio">
                                    <label htmlFor="service_type" className="radio-inline mr-5 d-flex align-items-center"
                                           onClick={() => this.selectOption(input, "0.2m")}>
                                        <input type={"radio"} value={"0.2m"} {...input} onChange={() => this.selectOption(input, "0.2m")}
                                               className={classNames('form-control', { 'is-invalid': invalid })} />
                                        &nbsp;0.2m
                                    </label>
                                </div>
                            </td>
                            <td>
                                <div className="radio">
                                    <label htmlFor="service_type" className="radio-inline mr-5 d-flex align-items-center"
                                           onClick={() => this.selectOption(input, "2/200")}>
                                        <input type={"radio"} value={"2/200"} {...input} onChange={() => this.selectOption(input, "2/200")}
                                               className={classNames('form-control', { 'is-invalid': invalid })} />
                                        &nbsp;2/200
                                    </label>
                                </div>
                            </td>
                            <td>
                                {jaeger && <div className="radio">
                                    <label htmlFor="service_type" className="radio-inline mr-5 d-flex align-items-center"
                                           onClick={() => this.selectOption(input, "J10")}>
                                        <input type={"radio"} value={"J10"} {...input} onChange={() => this.selectOption(input, "J10")}
                                               className={classNames('form-control', { 'is-invalid': invalid })} />
                                        &nbsp;J10
                                    </label>
                                </div>}
                            </td>
                        </tr>
                        <tr>
                            <td>
                                {/*
                                <div className="radio">
                                    <label htmlFor="service_type" className="radio-inline mr-5 d-flex align-items-center"
                                           onClick={() => this.selectOption(input, "1.0")}>
                                        <input type={"radio"} value={"1.0"} {...input} onChange={() => this.selectOption(input, "1.0")}
                                               className={classNames('form-control', { 'is-invalid': invalid })} />
                                        &nbsp;1.0
                                    </label>
                                </div>
                                */}
                                <div className="radio">
                                    <label htmlFor="service_type" className="radio-inline mr-5 d-flex align-items-center"
                                           onClick={() => this.selectOption(input, "0.15")}>
                                        <input type={"radio"} value={"0.15"} {...input} onChange={() => this.selectOption(input, "0.15")}
                                               className={classNames('form-control', { 'is-invalid': invalid })} />
                                        &nbsp;0.15
                                    </label>
                                </div>
                            </td>
                            <td>
                                <div className="radio">
                                    <label htmlFor="service_type" className="radio-inline mr-5 d-flex align-items-center"
                                           onClick={() => this.selectOption(input, "20/20")}>
                                        <input type={"radio"} value={"20/20"} {...input} onChange={() => this.selectOption(input, "20/20")}
                                               className={classNames('form-control', { 'is-invalid': invalid })} />
                                        &nbsp;20/20
                                    </label>
                                </div>
                            </td>
                            <td>
                                <div className="radio">
                                    <label htmlFor="service_type" className="radio-inline mr-5 d-flex align-items-center"
                                           onClick={() => this.selectOption(input, "0.3m")}>
                                        <input type={"radio"} value={"0.3m"} {...input} onChange={() => this.selectOption(input, "0.3m")}
                                               className={classNames('form-control', { 'is-invalid': invalid })} />
                                        &nbsp;0.3m
                                    </label>
                                </div>
                            </td>
                            <td>
                                <div className="radio">
                                    <label htmlFor="service_type" className="radio-inline mr-5 d-flex align-items-center"
                                           onClick={() => this.selectOption(input, "3/200")}>
                                        <input type={"radio"} value={"3/200"} {...input} onChange={() => this.selectOption(input, "3/200")}
                                               className={classNames('form-control', { 'is-invalid': invalid })} />
                                        &nbsp;3/200
                                    </label>
                                </div>
                            </td>
                            <td>
                                {jaeger && <div className="radio">
                                    <label htmlFor="service_type" className="radio-inline mr-5 d-flex align-items-center"
                                           onClick={() => this.selectOption(input, "J7")}>
                                        <input type={"radio"} value={"J7"} {...input} onChange={() => this.selectOption(input, "J7")}
                                               className={classNames('form-control', { 'is-invalid': invalid })} />
                                        &nbsp;J7
                                    </label>
                                </div>}
                            </td>
                        </tr>
                        <tr>
                            <td>
                                {/*
                                <div className="radio">
                                    <label htmlFor="service_type" className="radio-inline mr-5 d-flex align-items-center"
                                           onClick={() => this.selectOption(input, "0.8")}>
                                        <input type={"radio"} value={"0.8"} {...input} onChange={() => this.selectOption(input, "0.8")}
                                               className={classNames('form-control', { 'is-invalid': invalid })} />
                                        &nbsp;0.8
                                    </label>
                                </div>
                                */}
                                <div className="radio">
                                    <label htmlFor="service_type" className="radio-inline mr-5 d-flex align-items-center"
                                           onClick={() => this.selectOption(input, "0.2")}>
                                        <input type={"radio"} value={"0.2"} {...input} onChange={() => this.selectOption(input, "0.2")}
                                               className={classNames('form-control', { 'is-invalid': invalid })} />
                                        &nbsp;0.2
                                    </label>
                                </div>
                            </td>
                            <td>
                                <div className="radio">
                                    <label htmlFor="service_type" className="radio-inline mr-5 d-flex align-items-center"
                                           onClick={() => this.selectOption(input, "20/25")}>
                                        <input type={"radio"} value={"20/25"} {...input} onChange={() => this.selectOption(input, "20/25")}
                                               className={classNames('form-control', { 'is-invalid': invalid })} />
                                        &nbsp;20/25
                                    </label>
                                </div>
                            </td>
                            <td>
                                <div className="radio">
                                    <label htmlFor="service_type" className="radio-inline mr-5 d-flex align-items-center"
                                           onClick={() => this.selectOption(input, "0.4m")}>
                                        <input type={"radio"} value={"0.4m"} {...input} onChange={() => this.selectOption(input, "0.4m")}
                                               className={classNames('form-control', { 'is-invalid': invalid })} />
                                        &nbsp;0.4m
                                    </label>
                                </div>
                            </td>
                            <td>
                                <div className="radio">
                                    <label htmlFor="service_type" className="radio-inline mr-5 d-flex align-items-center"
                                           onClick={() => this.selectOption(input, "4/200")}>
                                        <input type={"radio"} value={"4/200"} {...input} onChange={() => this.selectOption(input, "4/200")}
                                               className={classNames('form-control', { 'is-invalid': invalid })} />
                                        &nbsp;4/200
                                    </label>
                                </div>
                            </td>
                            <td>
                                {jaeger && <div className="radio">
                                    <label htmlFor="service_type" className="radio-inline mr-5 d-flex align-items-center"
                                           onClick={() => this.selectOption(input, "J5")}>
                                        <input type={"radio"} value={"J5"} {...input} onChange={() => this.selectOption(input, "J5")}
                                               className={classNames('form-control', { 'is-invalid': invalid })} />
                                        &nbsp;J5
                                    </label>
                                </div>}
                            </td>
                        </tr>
                        <tr>
                            <td>
                                {/*
                                <div className="radio">
                                    <label htmlFor="service_type" className="radio-inline mr-5 d-flex align-items-center"
                                           onClick={() => this.selectOption(input, "0.63")}>
                                        <input type={"radio"} value={"0.63"} {...input} onChange={() => this.selectOption(input, "0.63")}
                                               className={classNames('form-control', { 'is-invalid': invalid })} />
                                        &nbsp;0.63
                                    </label>
                                </div>
                                */}
                                <div className="radio">
                                    <label htmlFor="service_type" className="radio-inline mr-5 d-flex align-items-center"
                                           onClick={() => this.selectOption(input, "0.3")}>
                                        <input type={"radio"} value={"0.3"} {...input} onChange={() => this.selectOption(input, "0.3")}
                                               className={classNames('form-control', { 'is-invalid': invalid })} />
                                        &nbsp;0.3
                                    </label>
                                </div>
                            </td>
                            <td>
                                <div className="radio">
                                    <label htmlFor="service_type" className="radio-inline mr-5 d-flex align-items-center"
                                           onClick={() => this.selectOption(input, "20/30")}>
                                        <input type={"radio"} value={"20/30"} {...input} onChange={() => this.selectOption(input, "20/30")}
                                               className={classNames('form-control', { 'is-invalid': invalid })} />
                                        &nbsp;20/30
                                    </label>
                                </div>
                            </td>
                            <td>
                                <div className="radio">
                                    <label htmlFor="service_type" className="radio-inline mr-5 d-flex align-items-center"
                                           onClick={() => this.selectOption(input, "0.5m")}>
                                        <input type={"radio"} value={"0.5m"} {...input} onChange={() => this.selectOption(input, "0.5m")}
                                               className={classNames('form-control', { 'is-invalid': invalid })} />
                                        &nbsp;0.5m
                                    </label>
                                </div>
                            </td>
                            <td>
                                <div className="radio">
                                    <label htmlFor="service_type" className="radio-inline mr-5 d-flex align-items-center"
                                           onClick={() => this.selectOption(input, "5/200")}>
                                        <input type={"radio"} value={"5/200"} {...input} onChange={() => this.selectOption(input, "5/200")}
                                               className={classNames('form-control', { 'is-invalid': invalid })} />
                                        &nbsp;5/200
                                    </label>
                                </div>
                            </td>
                            <td>
                                {jaeger && <div className="radio">
                                    <label htmlFor="service_type" className="radio-inline mr-5 d-flex align-items-center"
                                           onClick={() => this.selectOption(input, "J3")}>
                                        <input type={"radio"} value={"J3"} {...input} onChange={() => this.selectOption(input, "J3")}
                                               className={classNames('form-control', { 'is-invalid': invalid })} />
                                        &nbsp;J3
                                    </label>
                                </div>}
                            </td>
                        </tr>
                        <tr>
                            <td>
                                {/*
                                <div className="radio">
                                    <label htmlFor="service_type" className="radio-inline mr-5 d-flex align-items-center"
                                           onClick={() => this.selectOption(input, "0.5")}>
                                        <input type={"radio"} value={"0.5"} {...input} onChange={() => this.selectOption(input, "0.5")}
                                               className={classNames('form-control', { 'is-invalid': invalid })} />
                                        &nbsp;0.5
                                    </label>
                                </div>
                                */}
                                <div className="radio">
                                    <label htmlFor="service_type" className="radio-inline mr-5 d-flex align-items-center"
                                           onClick={() => this.selectOption(input, "0.4")}>
                                        <input type={"radio"} value={"0.4"} {...input} onChange={() => this.selectOption(input, "0.4")}
                                               className={classNames('form-control', { 'is-invalid': invalid })} />
                                        &nbsp;0.4
                                    </label>
                                </div>
                            </td>
                            <td>
                                <div className="radio">
                                    <label htmlFor="service_type" className="radio-inline mr-5 d-flex align-items-center"
                                           onClick={() => this.selectOption(input, "20/40")}>
                                        <input type={"radio"} value={"20/40"} {...input} onChange={() => this.selectOption(input, "20/40")}
                                               className={classNames('form-control', { 'is-invalid': invalid })} />
                                        &nbsp;20/40
                                    </label>
                                </div>
                            </td>
                            <td>
                                <div className="radio">
                                    <label htmlFor="service_type" className="radio-inline mr-5 d-flex align-items-center"
                                           onClick={() => this.selectOption(input, "0.6m")}>
                                        <input type={"radio"} value={"0.6m"} {...input} onChange={() => this.selectOption(input, "0.6m")}
                                               className={classNames('form-control', { 'is-invalid': invalid })} />
                                        &nbsp;0.6m
                                    </label>
                                </div>
                            </td>
                            <td>
                                <div className="radio">
                                    <label htmlFor="service_type" className="radio-inline mr-5 d-flex align-items-center"
                                           onClick={() => this.selectOption(input, "6/200")}>
                                        <input type={"radio"} value={"6/200"} {...input} onChange={() => this.selectOption(input, "6/200")}
                                               className={classNames('form-control', { 'is-invalid': invalid })} />
                                        &nbsp;6/200
                                    </label>
                                </div>
                            </td>
                            <td>
                                {jaeger && <div className="radio">
                                    <label htmlFor="service_type" className="radio-inline mr-5 d-flex align-items-center"
                                           onClick={() => this.selectOption(input, "J2")}>
                                        <input type={"radio"} value={"J2"} {...input} onChange={() => this.selectOption(input, "J2")}
                                               className={classNames('form-control', { 'is-invalid': invalid })} />
                                        &nbsp;J2
                                    </label>
                                </div>}
                            </td>
                        </tr>
                        <tr>
                            <td>
                                {/*
                                <div className="radio">
                                    <label htmlFor="service_type" className="radio-inline mr-5 d-flex align-items-center"
                                           onClick={() => this.selectOption(input, "0.4")}>
                                        <input type={"radio"} value={"0.4"} {...input} onChange={() => this.selectOption(input, "0.4")}
                                               className={classNames('form-control', { 'is-invalid': invalid })} />
                                        &nbsp;0.4
                                    </label>
                                </div>
                                */}
                                <div className="radio">
                                    <label htmlFor="service_type" className="radio-inline mr-5 d-flex align-items-center"
                                           onClick={() => this.selectOption(input, "0.5")}>
                                        <input type={"radio"} value={"0.5"} {...input} onChange={() => this.selectOption(input, "0.5")}
                                               className={classNames('form-control', { 'is-invalid': invalid })} />
                                        &nbsp;0.5
                                    </label>
                                </div>
                            </td>
                            <td>
                                <div className="radio">
                                    <label htmlFor="service_type" className="radio-inline mr-5 d-flex align-items-center"
                                           onClick={() => this.selectOption(input, "20/50")}>
                                        <input type={"radio"} value={"20/50"} {...input} onChange={() => this.selectOption(input, "20/50")}
                                               className={classNames('form-control', { 'is-invalid': invalid })} />
                                        &nbsp;20/50
                                    </label>
                                </div>
                            </td>
                            <td>
                                <div className="radio">
                                    <label htmlFor="service_type" className="radio-inline mr-5 d-flex align-items-center"
                                           onClick={() => this.selectOption(input, "0.7m")}>
                                        <input type={"radio"} value={"0.7m"} {...input} onChange={() => this.selectOption(input, "0.7m")}
                                               className={classNames('form-control', { 'is-invalid': invalid })} />
                                        &nbsp;0.7m
                                    </label>
                                </div>
                            </td>
                            <td>
                                <div className="radio">
                                    <label htmlFor="service_type" className="radio-inline mr-5 d-flex align-items-center"
                                           onClick={() => this.selectOption(input, "7/200")}>
                                        <input type={"radio"} value={"7/200"} {...input} onChange={() => this.selectOption(input, "7/200")}
                                               className={classNames('form-control', { 'is-invalid': invalid })} />
                                        &nbsp;7/200
                                    </label>
                                </div>
                            </td>
                            <td>
                                {jaeger && <div className="radio">
                                    <label htmlFor="service_type" className="radio-inline mr-5 d-flex align-items-center"
                                           onClick={() => this.selectOption(input, "J1")}>
                                        <input type={"radio"} value={"J1"} {...input} onChange={() => this.selectOption(input, "J1")}
                                               className={classNames('form-control', { 'is-invalid': invalid })} />
                                        &nbsp;J1
                                    </label>
                                </div>}
                            </td>
                        </tr>
                        <tr>
                            <td>
                                {/*
                                <div className="radio">
                                    <label htmlFor="service_type" className="radio-inline mr-5 d-flex align-items-center"
                                           onClick={() => this.selectOption(input, "0.32")}>
                                        <input type={"radio"} value={"0.32"} {...input} onChange={() => this.selectOption(input, "0.32")}
                                               className={classNames('form-control', { 'is-invalid': invalid })} />
                                        &nbsp;0.32
                                    </label>
                                </div>
                                */}
                                <div className="radio">
                                    <label htmlFor="service_type" className="radio-inline mr-5 d-flex align-items-center"
                                           onClick={() => this.selectOption(input, "0.6")}>
                                        <input type={"radio"} value={"0.6"} {...input} onChange={() => this.selectOption(input, "0.6")}
                                               className={classNames('form-control', { 'is-invalid': invalid })} />
                                        &nbsp;0.6
                                    </label>
                                </div>
                            </td>
                            <td>
                                <div className="radio">
                                    <label htmlFor="service_type" className="radio-inline mr-5 d-flex align-items-center"
                                           onClick={() => this.selectOption(input, "20/60")}>
                                        <input type={"radio"} value={"20/60"} {...input} onChange={() => this.selectOption(input, "20/60")}
                                               className={classNames('form-control', { 'is-invalid': invalid })} />
                                        &nbsp;20/60
                                    </label>
                                </div>
                            </td>
                            <td>
                                <div className="radio">
                                    <label htmlFor="service_type" className="radio-inline mr-5 d-flex align-items-center"
                                           onClick={() => this.selectOption(input, "0.8m")}>
                                        <input type={"radio"} value={"0.8m"} {...input} onChange={() => this.selectOption(input, "0.8m")}
                                               className={classNames('form-control', { 'is-invalid': invalid })} />
                                        &nbsp;0.8m
                                    </label>
                                </div>
                            </td>
                            <td>
                                <div className="radio">
                                    <label htmlFor="service_type" className="radio-inline mr-5 d-flex align-items-center"
                                           onClick={() => this.selectOption(input, "8/200")}>
                                        <input type={"radio"} value={"8/200"} {...input} onChange={() => this.selectOption(input, "8/200")}
                                               className={classNames('form-control', { 'is-invalid': invalid })} />
                                        &nbsp;8/200
                                    </label>
                                </div>
                            </td>
                            <td>
                                {jaeger && <div className="radio">
                                    <label htmlFor="service_type" className="radio-inline mr-5 d-flex align-items-center"
                                           onClick={() => this.selectOption(input, "J1+")}>
                                        <input type={"radio"} value={"1+"} {...input} onChange={() => this.selectOption(input, "J1+")}
                                               className={classNames('form-control', { 'is-invalid': invalid })} />
                                        &nbsp;J1+
                                    </label>
                                </div>}
                            </td>
                        </tr>
                        <tr>
                            <td>
                                {/*
                                <div className="radio">
                                    <label htmlFor="service_type" className="radio-inline mr-5 d-flex align-items-center"
                                           onClick={() => this.selectOption(input, "0.25")}>
                                        <input type={"radio"} value={"0.25"} {...input} onChange={() => this.selectOption(input, "0.25")}
                                               className={classNames('form-control', { 'is-invalid': invalid })} />
                                        &nbsp;0.25
                                    </label>
                                </div>
                                */}
                                <div className="radio">
                                    <label htmlFor="service_type" className="radio-inline mr-5 d-flex align-items-center"
                                           onClick={() => this.selectOption(input, "0.7")}>
                                        <input type={"radio"} value={"0.7"} {...input} onChange={() => this.selectOption(input, "0.7")}
                                               className={classNames('form-control', { 'is-invalid': invalid })} />
                                        &nbsp;0.7
                                    </label>
                                </div>
                            </td>
                            <td>
                                <div className="radio">
                                    <label htmlFor="service_type" className="radio-inline mr-5 d-flex align-items-center"
                                           onClick={() => this.selectOption(input, "20/80")}>
                                        <input type={"radio"} value={"20/80"} {...input} onChange={() => this.selectOption(input, "20/80")}
                                               className={classNames('form-control', { 'is-invalid': invalid })} />
                                        &nbsp;20/80
                                    </label>
                                </div>
                            </td>
                            <td>
                                <div className="radio">
                                    <label htmlFor="service_type" className="radio-inline mr-5 d-flex align-items-center"
                                           onClick={() => this.selectOption(input, "0.9m")}>
                                        <input type={"radio"} value={"0.9m"} {...input} onChange={() => this.selectOption(input, "0.9m")}
                                               className={classNames('form-control', { 'is-invalid': invalid })} />
                                        &nbsp;0.9m
                                    </label>
                                </div>
                            </td>
                            <td>
                                <div className="radio">
                                    <label htmlFor="service_type" className="radio-inline mr-5 d-flex align-items-center"
                                           onClick={() => this.selectOption(input, "9/200")}>
                                        <input type={"radio"} value={"9/200"} {...input} onChange={() => this.selectOption(input, "9/200")}
                                               className={classNames('form-control', { 'is-invalid': invalid })} />
                                        &nbsp;9/200
                                    </label>
                                </div>
                            </td>
                            <td />
                        </tr>
                        <tr>
                            <td>
                                {/*
                                <div className="radio">
                                    <label htmlFor="service_type" className="radio-inline mr-5 d-flex align-items-center"
                                           onClick={() => this.selectOption(input, "0.20")}>
                                        <input type={"radio"} value={"0.20"} {...input} onChange={() => this.selectOption(input, "0.20")}
                                               className={classNames('form-control', { 'is-invalid': invalid })} />
                                        &nbsp;0.20
                                    </label>
                                </div>
                                */}
                                <div className="radio">
                                    <label htmlFor="service_type" className="radio-inline mr-5 d-flex align-items-center"
                                           onClick={() => this.selectOption(input, "0.8")}>
                                        <input type={"radio"} value={"0.8"} {...input} onChange={() => this.selectOption(input, "0.8")}
                                               className={classNames('form-control', { 'is-invalid': invalid })} />
                                        &nbsp;0.8
                                    </label>
                                </div>
                            </td>
                            <td>
                                <div className="radio">
                                    <label htmlFor="service_type" className="radio-inline mr-5 d-flex align-items-center"
                                           onClick={() => this.selectOption(input, "20/100")}>
                                        <input type={"radio"} value={"20/100"} {...input} onChange={() => this.selectOption(input, "20/100")}
                                               className={classNames('form-control', { 'is-invalid': invalid })} />
                                        &nbsp;20/100
                                    </label>
                                </div>
                            </td>
                            <td>
                                <div className="radio">
                                    <label htmlFor="service_type" className="radio-inline mr-5 d-flex align-items-center"
                                           onClick={() => this.selectOption(input, "1.0m")}>
                                        <input type={"radio"} value={"1.0m"} {...input} onChange={() => this.selectOption(input, "1.0m")}
                                               className={classNames('form-control', { 'is-invalid': invalid })} />
                                        &nbsp;1.0m
                                    </label>
                                </div>
                            </td>
                            <td>
                                <div className="radio">
                                    <label htmlFor="service_type" className="radio-inline mr-5 d-flex align-items-center"
                                           onClick={() => this.selectOption(input, "10/200")}>
                                        <input type={"radio"} value={"10/200"} {...input} onChange={() => this.selectOption(input, "10/200")}
                                               className={classNames('form-control', { 'is-invalid': invalid })} />
                                        &nbsp;10/200
                                    </label>
                                </div>
                            </td>
                            <td />
                        </tr>
                        <tr>
                            <td>
                                {/*
                                <div className="radio">
                                    <label htmlFor="service_type" className="radio-inline mr-5 d-flex align-items-center"
                                           onClick={() => this.selectOption(input, "0.16")}>
                                        <input type={"radio"} value={"0.16"} {...input} onChange={() => this.selectOption(input, "0.16")}
                                               className={classNames('form-control', { 'is-invalid': invalid })} />
                                        &nbsp;0.16
                                    </label>
                                </div>
                                */}
                                <div className="radio">
                                    <label htmlFor="service_type" className="radio-inline mr-5 d-flex align-items-center"
                                           onClick={() => this.selectOption(input, "0.9")}>
                                        <input type={"radio"} value={"0.9"} {...input} onChange={() => this.selectOption(input, "0.9")}
                                               className={classNames('form-control', { 'is-invalid': invalid })} />
                                        &nbsp;0.9
                                    </label>
                                </div>
                            </td>
                            {/*
                            <td>
                                <div className="radio">
                                    <label htmlFor="service_type" className="radio-inline mr-5 d-flex align-items-center"
                                           onClick={() => this.selectOption(input, "20/125")}>
                                        <input type={"radio"} value={"20/125"} {...input} onChange={() => this.selectOption(input, "20/125")}
                                               className={classNames('form-control', { 'is-invalid': invalid })} />
                                        &nbsp;20/125
                                    </label>
                                </div>
                            </td>
                            */}
                            <td>
                                <div className="radio">
                                    <label htmlFor="service_type" className="radio-inline mr-5 d-flex align-items-center"
                                           onClick={() => this.selectOption(input, "1.5m")}>
                                        <input type={"radio"} value={"1.5m"} {...input} onChange={() => this.selectOption(input, "1.5m")}
                                               className={classNames('form-control', { 'is-invalid': invalid })} />
                                        &nbsp;1.5m
                                    </label>
                                </div>
                            </td>
                            <td >
                                <div className="radio">
                                    <label htmlFor="service_type" className="radio-inline mr-5 d-flex align-items-center"
                                           onClick={() => this.selectOption(input, "2.5m")}>
                                        <input type={"radio"} value={"2.5m"} {...input} onChange={() => this.selectOption(input, "2.5m")}
                                               className={classNames('form-control', { 'is-invalid': invalid })} />
                                        &nbsp;2.5m
                                    </label>
                                </div>
                            </td>
                            <td />
                        </tr>
                        <tr>
                            <td>
                                {/*
                                <div className="radio">
                                    <label htmlFor="service_type" className="radio-inline mr-5 d-flex align-items-center"
                                           onClick={() => this.selectOption(input, "0.125")}>
                                        <input type={"radio"} value={"0.125"} {...input} onChange={() => this.selectOption(input, "0.125")}
                                               className={classNames('form-control', { 'is-invalid': invalid })} />
                                        &nbsp;0.125
                                    </label>
                                </div>
                                */}
                                <div className="radio">
                                    <label htmlFor="service_type" className="radio-inline mr-5 d-flex align-items-center"
                                           onClick={() => this.selectOption(input, "1.0")}>
                                        <input type={"radio"} value={"1.0"} {...input} onChange={() => this.selectOption(input, "1.0")}
                                               className={classNames('form-control', { 'is-invalid': invalid })} />
                                        &nbsp;1.0
                                    </label>
                                </div>
                            </td>
                            {/*
                            <td>
                                <div className="radio">
                                    <label htmlFor="service_type" className="radio-inline mr-5 d-flex align-items-center"
                                           onClick={() => this.selectOption(input, "20/160")}>
                                        <input type={"radio"} value={"20/160"} {...input} onChange={() => this.selectOption(input, "20/160")}
                                               className={classNames('form-control', { 'is-invalid': invalid })} />
                                        &nbsp;20/160
                                    </label>
                                </div>
                            </td>
                            */}
                            <td>
                                <div className="radio">
                                    <label htmlFor="service_type" className="radio-inline mr-5 d-flex align-items-center"
                                           onClick={() => this.selectOption(input, "2.0m")}>
                                        <input type={"radio"} value={"2.0m"} {...input} onChange={() => this.selectOption(input, "2.0m")}
                                               className={classNames('form-control', { 'is-invalid': invalid })} />
                                        &nbsp;2.0m
                                    </label>
                                </div>
                            </td>
                            <td>
                                <div className="radio">
                                    <label htmlFor="service_type" className="radio-inline mr-5 d-flex align-items-center"
                                           onClick={() => this.selectOption(input, "3.0m")}>
                                        <input type={"radio"} value={"3.0m"} {...input} onChange={() => this.selectOption(input, "3.0m")}
                                               className={classNames('form-control', { 'is-invalid': invalid })} />
                                        &nbsp;3.0m
                                    </label>
                                </div>
                            </td>
                            <td />
                        </tr>
                        <tr>
                            <td>
                                <div className="radio">
                                    <label htmlFor="service_type" className="radio-inline mr-5 d-flex align-items-center"
                                           onClick={() => this.selectOption(input, "1.2")}>
                                        <input type={"radio"} value={"1.2"} {...input} onChange={() => this.selectOption(input, "1.2")}
                                               className={classNames('form-control', { 'is-invalid': invalid })} />
                                        &nbsp;1.2
                                    </label>
                                </div>
                            </td>
                            <td>
                                <div className="radio">
                                    <label htmlFor="service_type" className="radio-inline mr-5 d-flex align-items-center"
                                           onClick={() => this.selectOption(input, "20/200")}>
                                        <input type={"radio"} value={"20/200"} {...input} onChange={() => this.selectOption(input, "20/200")}
                                               className={classNames('form-control', { 'is-invalid': invalid })} />
                                        &nbsp;20/200
                                    </label>
                                </div>
                            </td>
                            <td>
                                {/*
                                <div className="radio">
                                    <label htmlFor="service_type" className="radio-inline mr-5 d-flex align-items-center"
                                           onClick={() => this.selectOption(input, "2.5m")}>
                                        <input type={"radio"} value={"2.5m"} {...input} onChange={() => this.selectOption(input, "2.5m")}
                                               className={classNames('form-control', { 'is-invalid': invalid })} />
                                        &nbsp;2.5m
                                    </label>
                                </div>
                                */}
                            </td>
                            <td />
                            <td />
                        </tr>
                        {/*
                        <tr>
                            <td>
                                <div className="radio">
                                    <label htmlFor="service_type" className="radio-inline mr-5 d-flex align-items-center"
                                           onClick={() => this.selectOption(input, "0.08")}>
                                        <input type={"radio"} value={"0.08"} {...input} onChange={() => this.selectOption(input, "0.08")}
                                               className={classNames('form-control', { 'is-invalid': invalid })} />
                                        &nbsp;0.08
                                    </label>
                                </div>
                            </td>
                            <td>
                                <div className="radio">
                                    <label htmlFor="service_type" className="radio-inline mr-5 d-flex align-items-center"
                                           onClick={() => this.selectOption(input, "20/250")}>
                                        <input type={"radio"} value={"20/250"} {...input} onChange={() => this.selectOption(input, "20/250")}
                                               className={classNames('form-control', { 'is-invalid': invalid })} />
                                        &nbsp;20/250
                                    </label>
                                </div>
                            </td>
                            <td>
                                <div className="radio">
                                    <label htmlFor="service_type" className="radio-inline mr-5 d-flex align-items-center"
                                           onClick={() => this.selectOption(input, "3.0m")}>
                                        <input type={"radio"} value={"3.0m"} {...input} onChange={() => this.selectOption(input, "3.0m")}
                                               className={classNames('form-control', { 'is-invalid': invalid })} />
                                        &nbsp;3.0m
                                    </label>
                                </div>
                            </td>
                            <td />
                            <td />
                        </tr>
                        */}
                        <tr>
                            <td>
                               <div className="radio">
                                    <label htmlFor="service_type" className="radio-inline mr-5 d-flex align-items-center"
                                           onClick={() => this.selectOption(input, "1.5")}>
                                        <input type={"radio"} value={"1.5"} {...input} onChange={() => this.selectOption(input, "1.5")}
                                               className={classNames('form-control', { 'is-invalid': invalid })} />
                                        &nbsp;1.5
                                    </label>
                                </div>
                            </td>
                            <td>
                                {/*
                                <div className="radio">
                                    <label htmlFor="service_type" className="radio-inline mr-5 d-flex align-items-center"
                                           onClick={() => this.selectOption(input, "20/320")}>
                                        <input type={"radio"} value={"20/320"} {...input} onChange={() => this.selectOption(input, "20/320")}
                                               className={classNames('form-control', { 'is-invalid': invalid })} />
                                        &nbsp;20/320
                                    </label>
                                </div>
                                */}
                                <div className="radio">
                                    <label htmlFor="service_type" className="radio-inline mr-5 d-flex align-items-center"
                                           onClick={() => this.selectOption(input, "20/400")}>
                                        <input type={"radio"} value={"20/400"} {...input} onChange={() => this.selectOption(input, "20/400")}
                                               className={classNames('form-control', { 'is-invalid': invalid })} />
                                        &nbsp;20/400
                                    </label>
                                </div>
                            </td>
                            <td />
                            <td />
                            <td />
                        </tr>
                        <tr>
                            <td>
                                {/*
                                <div className="radio">
                                    <label htmlFor="service_type" className="radio-inline mr-5 d-flex align-items-center"
                                           onClick={() => this.selectOption(input, "0.05")}>
                                        <input type={"radio"} value={"0.05"} {...input} onChange={() => this.selectOption(input, "0.05")}
                                               className={classNames('form-control', { 'is-invalid': invalid })} />
                                        &nbsp;0.05
                                    </label>
                                </div>
                                */}
                            </td>
                            <td>
                                {/*
                                <div className="radio">
                                    <label htmlFor="service_type" className="radio-inline mr-5 d-flex align-items-center"
                                           onClick={() => this.selectOption(input, "20/400")}>
                                        <input type={"radio"} value={"20/400"} {...input} onChange={() => this.selectOption(input, "20/400")}
                                               className={classNames('form-control', { 'is-invalid': invalid })} />
                                        &nbsp;20/400
                                    </label>
                                </div>
                                */}
                                <div className="radio">
                                    <label htmlFor="service_type" className="radio-inline mr-5 d-flex align-items-center"
                                           onClick={() => this.selectOption(input, "20/800")}>
                                        <input type={"radio"} value={"20/800"} {...input} onChange={() => this.selectOption(input, "20/800")}
                                               className={classNames('form-control', { 'is-invalid': invalid })} />
                                        &nbsp;20/800
                                    </label>
                                </div>
                            </td>
                            <td />
                            <td />
                            <td />
                        </tr>
                        {/*
                        <tr>
                            <td>
                                <div className="radio">
                                    <label htmlFor="service_type" className="radio-inline mr-5 d-flex align-items-center"
                                           onClick={() => this.selectOption(input, "0.04")}>
                                        <input type={"radio"} value={"0.04"} {...input} onChange={() => this.selectOption(input, "0.04")}
                                               className={classNames('form-control', { 'is-invalid': invalid })} />
                                        &nbsp;0.04
                                    </label>
                                </div>
                            </td>
                            <td>
                                <div className="radio">
                                    <label htmlFor="service_type" className="radio-inline mr-5 d-flex align-items-center"
                                           onClick={() => this.selectOption(input, "20/500")}>
                                        <input type={"radio"} value={"20/500"} {...input} onChange={() => this.selectOption(input, "20/500")}
                                               className={classNames('form-control', { 'is-invalid': invalid })} />
                                        &nbsp;20/500
                                    </label>
                                </div>
                            </td>
                            <td />
                            <td />
                            <td />
                        </tr>
                        */}
                        {/*
                        <tr>
                            <td>
                                <div className="radio">
                                    <label htmlFor="service_type" className="radio-inline mr-5 d-flex align-items-center"
                                           onClick={() => this.selectOption(input, "0.03")}>
                                        <input type={"radio"} value={"0.03"} {...input} onChange={() => this.selectOption(input, "0.03")}
                                               className={classNames('form-control', { 'is-invalid': invalid })} />
                                        &nbsp;0.03
                                    </label>
                                </div>
                            </td>
                            <td>
                                <div className="radio">
                                    <label htmlFor="service_type" className="radio-inline mr-5 d-flex align-items-center"
                                           onClick={() => this.selectOption(input, "20/630")}>
                                        <input type={"radio"} value={"20/630"} {...input} onChange={() => this.selectOption(input, "20/630")}
                                               className={classNames('form-control', { 'is-invalid': invalid })} />
                                        &nbsp;20/630
                                    </label>
                                </div>
                            </td>
                            <td />
                            <td />
                            <td />
                        </tr>
                        */}
                        {/*
                        <tr>
                            <td>
                                <div className="radio">
                                    <label htmlFor="service_type" className="radio-inline mr-5 d-flex align-items-center"
                                           onClick={() => this.selectOption(input, "0.025")}>
                                        <input type={"radio"} value={"0.025"} {...input} onChange={() => this.selectOption(input, "0.025")}
                                               className={classNames('form-control', { 'is-invalid': invalid })} />
                                        &nbsp;0.025
                                    </label>
                                </div>
                            </td>
                            <td>
                                <div className="radio">
                                    <label htmlFor="service_type" className="radio-inline mr-5 d-flex align-items-center"
                                           onClick={() => this.selectOption(input, "20/800")}>
                                        <input type={"radio"} value={"20/800"} {...input} onChange={() => this.selectOption(input, "20/800")}
                                               className={classNames('form-control', { 'is-invalid': invalid })} />
                                        &nbsp;20/800
                                    </label>
                                </div>
                            </td>
                            <td />
                            <td />
                            <td />
                        </tr>
                        */}
                        {/*
                        <tr>
                            <td>
                                <div className="radio">
                                    <label htmlFor="service_type" className="radio-inline mr-5 d-flex align-items-center"
                                           onClick={() => this.selectOption(input, "0.02")}>
                                        <input type={"radio"} value={"0.02"} {...input} onChange={() => this.selectOption(input, "0.02")}
                                               className={classNames('form-control', { 'is-invalid': invalid })} />
                                        &nbsp;0.02
                                    </label>
                                </div>
                            </td>
                            <td>
                                <div className="radio">
                                    <label htmlFor="service_type" className="radio-inline mr-5 d-flex align-items-center"
                                           onClick={() => this.selectOption(input, "20/1000")}>
                                        <input type={"radio"} value={"20/1000"} {...input} onChange={() => this.selectOption(input, "20/1000")}
                                               className={classNames('form-control', { 'is-invalid': invalid })} />
                                        &nbsp;20/1000
                                    </label>
                                </div>
                            </td>

                            <td />
                            <td />
                            <td />
                        </tr>
                        */}
                        {/*
                        <tr>
                            <td>
                                <div className="radio">
                                    <label htmlFor="service_type" className="radio-inline mr-5 d-flex align-items-center"
                                           onClick={() => this.selectOption(input, "0.016")}>
                                        <input type={"radio"} value={"0.016"} {...input} onChange={() => this.selectOption(input, "0.016")}
                                               className={classNames('form-control', { 'is-invalid': invalid })} />
                                        &nbsp;0.016
                                    </label>
                                </div>
                            </td>
                            <td>
                                <div className="radio">
                                    <label htmlFor="service_type" className="radio-inline mr-5 d-flex align-items-center"
                                           onClick={() => this.selectOption(input, "20/1250")}>
                                        <input type={"radio"} value={"20/1250"} {...input} onChange={() => this.selectOption(input, "20/1250")}
                                               className={classNames('form-control', { 'is-invalid': invalid })} />
                                        &nbsp;20/1250
                                    </label>
                                </div>
                            </td>
                            <td />
                            <td />
                            <td />
                        </tr>
                        */}
                        {/*
                        <tr>
                            <td>
                                <div className="radio">
                                    <label htmlFor="service_type" className="radio-inline mr-5 d-flex align-items-center"
                                           onClick={() => this.selectOption(input, "0.0125")}>
                                        <input type={"radio"} value={"0.0125"} {...input} onChange={() => this.selectOption(input, "0.0125")}
                                               className={classNames('form-control', { 'is-invalid': invalid })} />
                                        &nbsp;0.0125
                                    </label>
                                </div>
                            </td>
                            <td>
                                <div className="radio">
                                    <label htmlFor="service_type" className="radio-inline mr-5 d-flex align-items-center"
                                           onClick={() => this.selectOption(input, "20/1600")}>
                                        <input type={"radio"} value={"20/1600"} {...input} onChange={() => this.selectOption(input, "20/1600")}
                                               className={classNames('form-control', { 'is-invalid': invalid })} />
                                        &nbsp;20/1600
                                    </label>
                                </div>
                            </td>
                            <td />
                            <td />
                            <td />
                        </tr>
                        */}
                        {/*
                        <tr>
                            <td>
                                <div className="radio">
                                    <label htmlFor="service_type" className="radio-inline mr-5 d-flex align-items-center"
                                           onClick={() => this.selectOption(input, "0.01")}>
                                        <input type={"radio"} value={"0.01"} {...input} onChange={() => this.selectOption(input, "0.01")}
                                               className={classNames('form-control', { 'is-invalid': invalid })} />
                                        &nbsp;0.01
                                    </label>
                                </div>
                            </td>
                            <td>
                                <div className="radio">
                                    <label htmlFor="service_type" className="radio-inline mr-5 d-flex align-items-center"
                                           onClick={() => this.selectOption(input, "20/2000")}>
                                        <input type={"radio"} value={"20/2000"} {...input} onChange={() => this.selectOption(input, "20/2000")}
                                               className={classNames('form-control', { 'is-invalid': invalid })} />
                                        &nbsp;20/2000
                                    </label>
                                </div>
                            </td>
                            <td />
                            <td />
                            <td />
                        </tr>
                        */}
                        </tbody>
                    </table>
                    <div className="d-flex justify-content-center align-items-center mt-4">
                        <div
                            className="btn btn-secondary mx-4"
                            onClick={() => {
                                input.onChange(undefined);
                                this.closeModal();
                            }}>
                            Cancelar
                        </div>
                    </div>
                </Modal>
                <span className={`form-control text-center ${classColor}`} onClick={this.openModal} >{input.value}</span>
            </React.Fragment>
        );
    }
}
