import classNames from 'classnames';
import Form from './ConfigurationForm';
import React, { Component } from 'react';
import LoadMask from 'Utils/LoadMask/LoadMask';

export default class ConfigurationCrear extends Component {
    componentWillUnmount() {
        this.props.resetStore();
    }
    componentDidMount = () =>{
        this.props.getConfiguration();
    }

    render(){
        const {
            save,
            removeCard,
            cargando,
            removeNoOrderIgss,
            isOrderIgssModalOpen,
            setOrderIgssModal,
            isNavBarOpen,
        } = this.props;

        return (
            <div className={classNames('content-with-tabs', { 'content-with-tabs-min': isNavBarOpen })}>
                <div className="mx-5 py-4">
                    <div className="table-decoration">
                                <div className="top-title">
                                    CONFIGURACIONES
                                </div>
                                <Form 
                                    onSubmit={save} 
                                    removeCard={removeCard} 
                                    removeNoOrderIgss={removeNoOrderIgss} 
                                    cargando={cargando} 
                                    isOrderIgssModalOpen={isOrderIgssModalOpen}
                                    setOrderIgssModal={setOrderIgssModal}
                                />
                        </div>
                 </div>
            </div>
        );
    }
}
