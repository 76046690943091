const url = 'users';
import { api } from 'api';
import Swal from 'sweetalert2';
import { push } from 'react-router-redux';
import { goBack } from 'react-router-redux';
import { initialize as initializeForm } from 'redux-form';
import { createActions, handleActions } from 'redux-actions';


const LOADER_USER = 'LOADER_USER';
const SET_DATA_USER = 'SET_DATA_USER';
const SET_PAGE_USER = 'SET_PAGE_USER';
const SET_UPDATE_DATA_USER ='SET_UPDATE_DATA_USER';
const SET_BUSCADOR_USER = 'SET_BUSCADOR_USER';
const SET_FILTRO_USER = 'SET_FILTRO_USER';
const SET_DOCTORS = 'SET_DOCTORS';
//const SUPERUSER = 4;
const SUPERUSER = "superuser"
const SUPERUSUARIO = "superusuario"
const REFERENT = 9;

const listar = (page = 1) =>  (dispatch, getStore) => {
    dispatch({type: LOADER_USER, cargando: true});
    const store = getStore();
    const search = store.users.buscador;
    const filtro = store.users.filtro_user;
    let params = {page, search};
    if(filtro){
        params[''] = filtro
    }
    api.get(`${url}`, params).catch((error) => {
        dispatch({type: LOADER_USER, cargando: false});
        Swal(
            'Error',
            error.detail || 'Ha ocurrido un error, por favor vuelva a intentar.',
            'error'
        );
    }).then((data) => {
        if(data){
            dispatch({type: SET_DATA_USER, data});
            dispatch({type: SET_PAGE_USER, page});
        }
        dispatch({type: LOADER_USER, cargando: false});
    })
};

const getDoctors = () => (dispatch, getStore) => {
    api.get('referents/listAllDoctors').then((result) => {
        dispatch({type: SET_DOCTORS, doctors: result.results});
    }).catch(() => {
    }).finally(() => {
    });
};

const detail = id => (dispatch, getState) =>{
    dispatch({type: LOADER_USER, cargando: true});
    api.get(`${url}/${id}`).catch((error) => {
        dispatch({type: LOADER_USER, cargando: false});
        if(error.statusCode === 404){
            dispatch(push('/users'))
        }
    }).then((data) => {
        dispatch({type: LOADER_USER, cargando: false});
        if(data){


            console.log("data en detail user: ", data);


            if(data.hasOwnProperty("person_user") && data["person_user"] != null)
            {
                const role = {value: data.person_user.role.id, label: data.person_user.role.name}
                data.role = role;

                //if(data.person_user.role.id != SUPERUSER)
                if(data.role.label.toLowerCase() !== SUPERUSER && data.role.label.toLowerCase() !== SUPERUSUARIO)
                {
                    const element = {value: data.person_user.subsidiary.id, label: data.person_user.subsidiary.name};
                    data.subsidiary = element;
                }

                //agregado
                const person = {value: data.person_user.id, label: data.person_user.name};
                data.person = person;
                data.doctors = data.person_user.doctors;
            }

            dispatch({type: SET_UPDATE_DATA_USER, updateData: data});
            dispatch(initializeForm('users', data))

            console.log(data)
        }
    })
}

const create = () => (dispatch, getStore) => {
    const formData = getStore().form.users.values;
    const doctors = _.cloneDeep(formData.doctors);
    dispatch({type: LOADER_USER, cargando: true})

    //let objPerson_user = {role: formData.role.value}
    let objPerson_user = {role: formData.role}
    /*
        if(formData.role.value > SUPERUSER){
            objPerson_user["subsidiary"] = formData.subsidiary.value;
            objPerson_user.user = formData.person.value;
        }
        if(formData.role.value == REFERENT){
            objPerson_user["subsidiary"] = formData.subsidiary.value;
            objPerson_user.user = formData.person.value;
        }
    */
    if(formData.role.label.toLowerCase() !== SUPERUSER && formData.role.label.toLowerCase() !== SUPERUSUARIO){
        objPerson_user["subsidiary"] = formData.subsidiary.value;
        objPerson_user.user = formData.person.value;
    }

    objPerson_user.doctors = doctors;

    formData.person_user = objPerson_user;

    api.post(`${url}`, formData).then((data) => {
        dispatch({type: LOADER_USER, cargando: false})
        Swal('Éxito', 'Se ha creado el usuario.', 'success')
            .then(() => {
                dispatch(push('/users'))
            })
    }).catch((error) => {
        dispatch({type: LOADER_USER, cargando: false})
        Swal(
            'Error',
            error.detail || 'Ha ocurrido un error, por favor vuelva a intentar.',
            'error'
        );
    }).finally(() => {
        dispatch({type: LOADER_USER, cargando: false})
    });
};

const update = () => (dispatch, getStore) => {
    const formData = _.cloneDeep(getStore().form.users.values);
    console.log("formData",formData);
    const doctors = _.cloneDeep(formData.doctors);

    dispatch({type: LOADER_USER, cargando: true});

    let objPerson_user = {role: formData.role};

    objPerson_user["subsidiary"] = (formData.role.label.toLowerCase() === SUPERUSER || formData.role.label.toLowerCase() === SUPERUSUARIO) ? null : formData.subsidiary.value;

    if (formData.role.label.toLowerCase() !== SUPERUSER || formData.role.label.toLowerCase() !== SUPERUSUARIO) {
        /* Si la persona relacionada con el usuario es referente, pero el referente dato en la actualización está en blanco,
        entonces se está desasignando la persona del referente */
        if(formData.person == null && formData.hasOwnProperty('person_user') && formData.person_user.referent){
            const delete_referent = {referentId: formData.person_user.id}
            formData.delete_referent = delete_referent;
        }else{
            objPerson_user.user = parseInt(formData.person.value);
        }

    }

    objPerson_user.doctors = doctors;

    formData.person_user = objPerson_user;


    api.put(`${url}/${formData.id}`, formData)
        .then((data) => {
            dispatch({type: LOADER_USER, cargando: false});
            if(data){
                Swal(
                    'Éxito',
                    'Datos editados correctamente.',
                    'success'
                ).then(() => {
                    dispatch(push('/users'));
                })
            }
        })
        .catch((error) =>{
            dispatch({type: LOADER_USER, cargando: false});
            Swal(
                'Error',
                error.detail || 'Ha ocurrido un error, verifique los datos.',
                'error'
            )
            if(error.statusCode === 404){
                dispatch(push('/users/create'));
            }
        })
}

const destroy = (id) => (dispatch, getStore) => {
    dispatch({type: LOADER_USER, cargando: true});
    api.eliminar(`${url}/${id}`).catch((error) => {
        dispatch({type: LOADER_USER, cargando: false});
        Swal('Error', 'No se ha logrado borrar el registro.', 'error')
            .then(() => {
                dispatch(listar(1));
            })
    }).then((data) => {
        dispatch({type: LOADER_USER, cargando: false});
        Swal('Éxito', 'Se ha eliminado correctamente.', 'success')
            .then(() => {
                dispatch(listar(1));
            })
    })
}

const search = (search) => (dispatch, getStore) => {
    dispatch({type: SET_BUSCADOR_USER, buscador: search});
    dispatch(listar(1));
};

const filtro = (filtro) => (dispatch, getStore) => {
    if (filtro == 0){
        filtro = null
    }
    dispatch({type: SET_FILTRO_USER, filtro_user: filtro});
    dispatch(listar(1));
};

export const actions = {
    listar,
    detail,
    create,
    update,
    destroy,
    search,
    filtro,
    getDoctors,
};
export const reducer = {
    [LOADER_USER]: (state, { cargando }) => {
        return {...state, cargando }
    },
    [SET_DATA_USER]: (state, { data }) => {
        return {...state, data }
    },
    [SET_PAGE_USER]: (state, { page }) => {
        return {...state, page }
    },
    [SET_UPDATE_DATA_USER]: (state, { updateData }) => {
        return {...state, updateData }
    },
    [SET_BUSCADOR_USER]: (state, { buscador }) => {
        return {...state, buscador }
    },
    [SET_FILTRO_USER]: (state, { filtro_user }) => {
        return {...state, filtro_user }
    },
    [SET_DOCTORS]: (state, { doctors }) => {
        return {...state, doctors }
    },
}
export const initialState = {
    cargando: false,
    page: 1,
    data: {
        count: 0,
        next: null,
        previous: null,
        results: [],
    },
    buscador: '',
    filtro_user: null,
    updateData: {},
    doctors: [],
};
export default handleActions(reducer, initialState)
