import React, { Component } from 'react';
import Table from '../Utils/Grid';

const Caja = () => {
    return(
        <div className="table-decoration row mx-5 mt-5 mb-3 d-flex">
            <div className="w-100 text-center mt-5">
                    <img className="" src={require("../../../../assets/img/icons/cash-box.png")} alt="CashBox image" />
            </div>
            <div className="w-100 text-center mt-5">
                <h3 className="">No has abierto una caja</h3>
            </div>
            <div className="w-100 text-center ">
                <h3><b>¿Deseas abrir una caja?</b></h3>
            </div>
            <div className="w-100 text-center mt-5 mb-5">
                <a href="/#/oppening-closing/open-box" className="btn btn-primary btn-sm">Abrir</a>
            </div>
        </div>
    );
}

export default Caja;
