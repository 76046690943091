import { api } from 'api';
import Swal from 'sweetalert2';
import { push } from 'react-router-redux';
import { handleActions } from 'redux-actions'
import { initialize as initializeForm } from 'redux-form';

const SET_URL = 'SET_URL';
const SET_PAGE = 'SET_PAGE';
const CARGANDO = 'CARGANDO';
const URL_API = 'model_records';
const RECORD_LIST = 'RECORD_LIST';
const SET_RECORD_DATA = 'SET_RECORD_DATA';

const setUrl = (id) => (dispatch) => {
    let titulares = {};
    let texto = '';
    switch (id){
        case '1':
            texto = 'Médico';
            break;
        case '2':
            texto = 'Quirúrgicos';
            break;
        case '3':
            texto = 'Alérgicos';
            break;
        case '4':
            texto = 'Traumáticos';
            break;
        case '5':
            texto = 'Familiares';
            break;
        case '6':
            texto = 'Tratamiento';
            break;
        default:
            dispatch(push('/home'));
            break;
    }
    titulares.listar = `Listado de records ${texto}`;
    titulares.button = `Agregar records ${texto}`;
    titulares.crear = `Crear nuevo records ${texto}`;
    dispatch({
        type: SET_URL,
        titulares: titulares,
        id: id,
    });
};

const listarRecords = (record_type, page = 1) => (dispatch, getStore) => {
    const params = { record_type, page }
    dispatch({ type: CARGANDO, cargando: true });
    api.get(`${URL_API}`, params).then((response) => {
        if(response.results)
            dispatch({type: RECORD_LIST, data: response});
            dispatch({type: SET_PAGE, page})
    }).finally(() => {
        dispatch({ type: CARGANDO, cargando: false });
    })
};

const getRecordDetail = (id) => (dispatch, getStore) => {
    dispatch({type: CARGANDO, cargando: true});
    api.get(`${URL_API}/${id}`).then((data) => {
        dispatch({type: SET_RECORD_DATA, record_data: data});
        dispatch(initializeForm('recordForm', {
            name: data.name,
            fam_family: data.fam_family,
            md_treatment: data.md_treatment
        }));
    }).catch((error) => {
        dispatch(push('/home'));
    }).finally(() => {
        dispatch({type: CARGANDO, cargando: false});
    });
};
const create = (values) => (dispatch, getStore) => {
    dispatch({ type: CARGANDO, cargando: true });
    api.post(`${URL_API}`, values).then((data) => {
        Swal('Éxito', 'Registro agregado correctamente.', 'success')
        .then(() => {
            dispatch(push(`/records/${values.record_type}`));
        })
    }).catch((error) => {
        Swal('Error', error.detail || 'Ha ocurrido un error, por favor vuelva a intentar.','error');
    }).finally(() => {
        dispatch({ type: CARGANDO, cargando: false });
    });
};

const putData = (values) => (dispatch, getStore) => {
    dispatch({ type: CARGANDO, cargando: true });
    api.put(`${URL_API}/${values.id}`, values).then((data) => {
        Swal('Éxito', 'Registro agregado correctamente.', 'success')
        .then(() => {
            dispatch(push(`/records/${data.record_type}`));
        })
    }).catch(error => {
        Swal('Error', error.detail || 'Ha ocurrido un error, por favor vuelva a intentar.', 'error');
    }).finally(() => {
        dispatch({ type: CARGANDO, cargando: false });
    })
}

const deleteData = (row, page) => (dispatch, getStore) => {
    dispatch({ type: CARGANDO, cargando: false });
    api.eliminar(`${URL_API}/${row.id}`).catch((error) => {
        Swal('Error', 'No se ha logrado borrar el registro.', 'error').then(() => {
            dispatch(listarRecords(row.record_type, page));
        });
    }).then((data) => {
        Swal('Éxito', 'Se ha eliminado correctamente.', 'success')
            .then(() => {
                dispatch(listarRecords(row.record_type, page));
        });
    }).finally(() => {
        dispatch({ type: CARGANDO, cargando: false });
    })
}


export const actions = {
    setUrl,
    create,
    listarRecords,
    getRecordDetail,
    putData,
    deleteData
};
export const reducer = {
    [SET_URL]: (state, { titulares, id }) => {
        return {...state, titulares, id }
    },
    [CARGANDO]: (state, { cargando}) => {
        return {...state, cargando}
    },
    [RECORD_LIST]: (state, { data }) => {
        return {...state, data}
    },
    [SET_PAGE]: (state, { page }) => {
        return {...state, page }
    },
    [SET_RECORD_DATA]: (state, {record_data}) => {
        return {...state, record_data}
    }
}
export const initialState = {
    id: 0,
    titulares: {},
    cargando: false,
    data: {
        count: 0,
        next: null,
        previous: null,
        results: [],
    },
    page: 1,
    record_data: {}
};
export default handleActions(reducer, initialState)
