import Swal from 'sweetalert2';
import { api } from 'api';
import { handleActions } from 'redux-actions'
import { initialize as initializeForm } from 'redux-form'
import { push } from 'react-router-redux';
import moment from "moment";
const url = 'diagnostic_order';
import { departamentos, municipios } from 'variables';

/* Loader */
const SET_LOADER = 'SET_LOADER'

/* Para los servicios del modal */
const SET_BUSCADOR_SERVICE = 'SET_BUSCADOR_SERVICE';
const LOADER_SERVICE = 'LOADER_SERVICE';
const SET_DATA_SERVICE = 'SET_DATA_SERVICE';
const SET_PAGE_SERVICE = 'SET_PAGE_SERVICE';
const SET_MODAL_SERVICE = 'SET_MODAL_SERVICE';
const SET_SERVICES_LIST = 'SET_SERVICES_LIST';
/* ... */

const LOADER_SURGERY = 'LOADER_SURGERY';
const SET_DATA_SURGERY= 'SET_DATA_SURGERY';
const SET_BUSCADOR_SURGERY = 'SET_BUSCADOR_SURGERY';
const SET_FILTRO_SURGERY = 'SET_FILTRO_SURGERY';
const GROUP_BILL = 'GROUP_BILL';

/** ..... */
const SET_DATA_DIAGNOSTIC = 'SET_DATA_DIAGNOSTIC';
const SET_PAGE_DIAGNOSTIC = 'SET_PAGE_DIAGNOSTIC';
/** ........... */

/* De la cola de diagnóstico */
const SET_DATA_DIAGNOSTIC_QUEUE = 'SET_DATA_DIAGNOSTIC_QUEUE';
const SET_PAGE_DIAGNOSTIC_QUEUE = 'SET_PAGE_DIAGNOSTIC_QUEUE';
const SET_BUSCADOR_DIAGNOSTIC_QUEUE = 'SET_BUSCADOR_DIAGNOSTIC_QUEUE';
/** ........... */

/* De los diagnósticos completados */
const SET_DATA_DIAGNOSTIC_COMPLETED = 'SET_DATA_DIAGNOSTIC_COMPLETED';
const SET_PAGE_DIAGNOSTIC_COMPLETED = 'SET_PAGE_DIAGNOSTIC_COMPLETED';
const SET_BUSCADOR_DIAGNOSTIC_COMPLETED = 'SET_BUSCADOR_DIAGNOSTIC_COMPLETED';
/* ............ */

const SET_RESUME_DIAGNOSTIC = 'SET_RESUME_DIAGNOSTIC';
const SET_DATA_PROCEDIMIENTO = 'SET_DATA_PROCEDIMIENTO';

const SET_SHOW_MODAL_CORREO = 'SET_SHOW_MODAL_CORREO';
const SET_SHOW_MODAL_CORREO_RESUME = 'SET_SHOW_MODAL_CORREO_RESUME';
const SET_FINAL_DATE = 'SET_FINAL_DATE';
const SET_INICIAL_DATE = 'SET_INICIAL_DATE';

const groupBy = (xs, f) => {
    return xs.reduce((r, v, i, a, k = f(v)) => ((r[k] || (r[k] = [])).push(v), r), {});
}

const listar = (page = 1) => (dispatch, getStore) => {
    dispatch({type: LOADER_SURGERY, cargando: true});
    const store = getStore();
    const search = store.diagnostic_orden.buscador;
    const filtro = store.payback.filtro_payback_paid;
    const initial_date = store.diagnostic_orden.initial_date;
    const final_date = store.diagnostic_orden.final_date;

    let params = { page: page, search, initial_date, final_date };

    if(filtro){
        params[''] = filtro
    }

    //api.get(`${url}`, params).catch((error) => {
    api.get(`${url}/getDiagnosticOrders`, params).catch((error) => {
        dispatch({type: LOADER_SURGERY, cargando: false});
        Swal(
            'Error',
            error.detail || 'Ha ocurrido un error, por favor vuelva a intentar.',
            'error'
        );
    }).then((data) => {
        if(data){
            console.log("ordenes realizadas: ", data);
            dispatch({type: SET_DATA_DIAGNOSTIC, data: data});
            dispatch({type: SET_PAGE_DIAGNOSTIC, page});
        }

        dispatch({type: LOADER_SURGERY, cargando: false});
    });
}

const create = () => (dispatch, getStore) => {
    const diagnostic = _.cloneDeep(getStore().diagnostic_orden);
    const bills = diagnostic.services_perform_surgery_ordered;
    const services_list = diagnostic.services_list;

    const formData = _.cloneDeep(getStore().form.diagnostic.values);
    const current_user = getStore().login.me;

    const referent = formData.referent.value
    const patient = formData.patient.value;

    formData.referent = referent;
    formData.patient = patient;

    formData.bills = bills;
    formData.services_list = services_list;
    formData.subsidiary = current_user.person_user.subsidiary;
    formData.user = current_user.id;

    console.log("Enviando: ", formData);

    dispatch({type: LOADER_SURGERY, cargando: true})
    api.post(`${url}`, formData).then((data) => {
        dispatch({type: LOADER_SURGERY, cargando: false})
        dispatch({type: SET_SERVICES_LIST, services_list: []}); // agregado
        Swal('Éxito', 'Se ha creado la orden de diagnóstico.', 'success')
        .then(() => {
            dispatch(push('/diagnostic-order'))
        })
    }).catch((error) => {
        dispatch({type: LOADER_SURGERY, cargando: false})
        Swal(
            'Error',
             error.detail || 'Ha ocurrido un error, por favor vuelva a intentar.',
            'error'
        );
    }).finally(() => {
        dispatch({type: LOADER_SURGERY, cargando: false})
    });
};


const search = (search) => (dispatch, getStore) => {
    dispatch({type: SET_BUSCADOR_SURGERY, buscador: search});
    dispatch(listar(1));
};

const filtro = (filtro) => (dispatch, getStore) => {
    if (filtro == 0){
        filtro = null
    }
    dispatch({type: SET_FILTRO_SURGERY, filtro_appointments: filtro});
    dispatch(listar(1));
};

const listarServicios = (page = 1) =>  (dispatch, getStore) => {
    const referent = _.cloneDeep(getStore().form.diagnostic.values.referent);
    const idReferentCategory = referent !== undefined && referent !== null ? referent.idReferentCategory : 0;

    dispatch({type: LOADER_SERVICE, cargando: true});
    const store = getStore();
    const search = store.diagnostic_orden.buscador_service;
    const filtro = store.diagnostic_orden.filtro_service;
    let params = {page, search, idReferentCategory};

    if(filtro){
        params[''] = filtro
    }
    api.get(`services/getOnlyServicesForSale`, params).catch((error) => {
        dispatch({type: LOADER_SERVICE, cargando: false});
        Swal(
            'Error',
            error.detail || 'Ha ocurrido un error, por favor vuelva a intentar.',
            'error'
        );
    }).then((data) => {
        const search2 = getStore().surgery.buscador_service;
        if(search2 == search){
            if(data){
                dispatch({type: SET_DATA_SERVICE, data});
                dispatch({type: SET_PAGE_SERVICE, page});
                dispatch({type: SET_MODAL_SERVICE, modal_service: true});
            }
            dispatch({type: LOADER_SERVICE, cargando: false});
            //dispatch({type: SET_UPDATE_DATA_SERVICE, updateData: {}});
        }
    })
};

const searchService = (search) => (dispatch, getStore) => {
    dispatch({type: SET_BUSCADOR_SERVICE, buscador_service: search});
    dispatch(listarServicios(1));
};

/**
 *  Este se utiliza cuando se agenda la cirugía y se registran los productos, servicios y paquetes que utilizará
 * @param {*} add_service
 */
const addServiceToList = (add_service) => (dispatch, getStore) => {
    console.log("ADD service: ", add_service);

    const services_list = _.cloneDeep(getStore().surgery.services_list);
    const service = services_list.find(item => item.id === add_service.id);
    const index = services_list.findIndex(item => item.id === add_service.id)

    if(service && (index !== -1)){
        services_list[index].quantity += 1;
    }else{
        services_list.push({
            id: add_service.id,
            name: add_service.name,
            quantity: 1,

            price: add_service.price,
            financial_entity: add_service.financial_entity.value,
        });
    }

    dispatch({type: SET_SERVICES_LIST, services_list});
    dispatch({type: GROUP_BILL}); //add
}

const addServiceToListDiff = () => (dispatch, getStore) => {
    const formData = _.cloneDeep(getStore().form.frmInterSurgery.values);

    const services_list = _.cloneDeep(getStore().surgery.services_list);
    const service = services_list.find(item => item.id === formData.id);
    const index = services_list.findIndex(item => item.id === formData.id)

    if(service && (index !== -1)){
        services_list[index].quantity += 1;
    }else{
        services_list.push({
            id: formData.id,
            name: formData.name,
            quantity: 1,

            price: formData.price,
            financial_entity: formData.financial_entity.value,
        });
    }

    dispatch({type: SET_SERVICES_LIST, services_list});
    dispatch({type: SET_MODAL_SERVICE, modal_service: false});
    dispatch({type: GROUP_BILL}); //add

}

/**
 * Este se utiliza cuando se agenda la cirugía y se registran los productos, servicios y paquetes que utilizará
 * @param {*} id
 * @param {*} up_down
 */
const upDownCant = (id, up_down) => (dispatch, getStore) => {
    const services_list = _.cloneDeep(getStore().surgery.services_list);
    const index = services_list.findIndex(item => item.id === id)

    if(up_down){
        services_list[index].quantity += 1;
    }else{
        if(services_list[index].quantity - 1 >= 0){
            services_list[index].quantity -= 1;
        }else{
            services_list[index].quantity = 0;
        }
    }

    dispatch({type: SET_SERVICES_LIST, services_list});
    dispatch({type: SET_MODAL_SERVICE, modal_service: false});
    dispatch({type: GROUP_BILL}); //add
}

const removeServiceToList = (id) => (dispatch, getStore) => {
    const services_list = _.cloneDeep(getStore().surgery.services_list);

    let arreglo = services_list.filter((item) => {
        return item.id !== id;
    });

    dispatch({type: SET_SERVICES_LIST, services_list: arreglo });
    dispatch({type: SET_MODAL_SERVICE, modal_service: false});
    dispatch({type: GROUP_BILL}); //add
}


const toggleModalServicios = (estado) => (dispatch) =>{
    dispatch({type: SET_MODAL_SERVICE, modal_service: estado});
}

const iniciarDiagnostico = (id) => (dispatch, getStore) => {
    Swal.fire({
        title: '¿Está seguro(a) que desea iniciar el diagnóstico?',
        type: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#aaa',
        confirmButtonText: 'Aceptar',
        cancelButtonText: 'Cancelar',
        reverseButtons: true
    }).then((result) => {
        if (result.value) {
            api.post(`${url}/iniciarDiagnostico`, {id}).catch((error) => {
                Swal(
                    'Error',
                    error.detail || 'Ha ocurrido un error, por favor vuelva a intentar.',
                    'error'
                );
            }).then((response) => {
                Swal('Éxito', response.detail , 'success')
                .then(() => {
                    dispatch(getColaDiagnostico(1));
                })
            })
        }
    });
}

const startDiagnostic = (id) => (dispatch, getStore) => {
    api.post(`${url}/startDiagnostic`, {id}).catch((error) => {
        Swal(
            'Error',
            error.detail || 'Ha ocurrido un error, por favor vuelva a intentar.',
            'error'
        );
    }).then((response) => {
        Swal('Éxito', response.detail , 'success')
        .then(() => {
            dispatch(push('/examorder/' + id));
        })
    })

    /*
    Swal.fire({
        title: '¿Está seguro(a) que desea finalizar el diagnóstico?',
        type: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#aaa',
        confirmButtonText: 'Aceptar',
        cancelButtonText: 'Cancelar',
        reverseButtons: true
    }).then((result) => {
        if (result.value) {
            api.post(`${url}/startDiagnostic`, {id}).catch((error) => {
                Swal(
                    'Error',
                    error.detail || 'Ha ocurrido un error, por favor vuelva a intentar.',
                    'error'
                );
            }).then((response) => {
                Swal('Éxito', response.detail , 'success')
                .then(() => {
                    dispatch(push('/examorder/' + id));
                })
            })
        }
    });
    */
}

const endDiagnostic = (files) => (dispatch, getStore) => {
    console.log("files: ", files)

    Swal.fire({
        title: '¿Está seguro(a) que desea finalizar el diagnóstico?, no podrá editar ésta acción',
        type: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#aaa',
        confirmButtonText: 'Aceptar',
        cancelButtonText: 'Cancelar',
        reverseButtons: true
    }).then((result) => {
        if (result.value) {
            dispatch({type:SET_LOADER, loader: true})
            let formData = getStore().form.examorder.values;
            formData.files = undefined;
            if(formData.interpreter){
                formData.interpreter = formData.interpreter.value;
            }

            api.postAttachments(`${url}/endDiagnostic`, formData, files).catch((error) => {
                Swal(
                    'Error',
                    error.detail || 'Ha ocurrido un error, por favor vuelva a intentar.',
                    'error'
                );
            }).then((response) => {
                Swal('Éxito', response.detail , 'success')
                .then(() => {
                    dispatch(push('/cola-diagnosticos'))
                })
            }).finally(()=>{ dispatch({type:SET_LOADER, loader:false})  })
        }
    });
}

const getOneDiagnostic = (id) => (dispatch, getStore) => {
    api.get(`${url}/getOneDiagnostic`, {id}).catch((error) => {
        Swal(
            'Error',
            error.detail || 'Ha ocurrido un error, por favor vuelva a intentar.',
            'error'
        );
    }).then((data) => {
        const nombre_completo = data.diagnostic.patient.first_name + ' ' + data.diagnostic.patient.last_name;
        const referent = {value: data.diagnostic.referent.id, label: data.diagnostic.referent.name};
        const nombre_servicio = data.service.name;
        const date = data.created;

        const formulario = {
            nombre_completo,
            referent,
            nombre_servicio,
            date,
            id,
        }

        dispatch(initializeForm('examorder', formulario));
    })
}

const getColaDiagnostico = (page = 1) => (dispatch, getStore) => {
    dispatch({type: LOADER_SURGERY, cargando: true});
    const store = getStore();
    const search = store.diagnostic_orden.buscador_diagnostic_queue;
    const filtro = store.payback.filtro_payback_paid;
    const initial_date = store.diagnostic_orden.initial_date_diagnostic_queue;
    const final_date = store.diagnostic_orden.final_date_diagnostic_queue;

    let params = { page: page, search, initial_date, final_date };

    if(filtro){
        params[''] = filtro
    }

    api.get(`${url}/getDiagnosticQueue`, params).catch((error) => {
        dispatch({type: LOADER_SURGERY, cargando: false});
        Swal(
            'Error',
            error.detail || 'Ha ocurrido un error, por favor vuelva a intentar.',
            'error'
        );
    }).then((data) => {
        if(data){
            console.log("cola de diagnóstico", data);
            dispatch({type: SET_DATA_DIAGNOSTIC_QUEUE, diagnostic_queue: data});
            dispatch({type: SET_PAGE_DIAGNOSTIC_QUEUE, page_diagnostic_queue: page});
        }

        dispatch({type: LOADER_SURGERY, cargando: false});
    });
}

const getDiagnosticosFinalizados = (page = 1) => (dispatch, getStore) => {
    dispatch({type: LOADER_SURGERY, cargando: true});
    const store = getStore();
    const search = store.diagnostic_orden.buscador_diagnostic_completed;
    const filtro = store.payback.filtro_payback_paid;
    const initial_date = store.diagnostic_orden.initial_date;
    const final_date = store.diagnostic_orden.final_date;

    let params = { page: page, search, initial_date, final_date };

    if(filtro){
        params[''] = filtro
    }

    api.get(`${url}/getCompleteDiagnostic`, params).catch((error) => {
        dispatch({type: LOADER_SURGERY, cargando: false});
        Swal(
            'Error',
            error.detail || 'Ha ocurrido un error, por favor vuelva a intentar.',
            'error'
        );
    }).then((data) => {
        if(data){
            dispatch({type: SET_DATA_DIAGNOSTIC_COMPLETED, diagnostic_completed: data});
            dispatch({type: SET_PAGE_DIAGNOSTIC_COMPLETED, page_diagnostic_completed: page});
        }

        dispatch({type: LOADER_SURGERY, cargando: false});
    });
}

const cancelarColaDiagnostico = () => (dispatch, getStore) => {
    let formData = _.cloneDeep(getStore().form.frmCancelDiagnosticProcedure.values);
    formData.reason_cancellation = formData.reason_cancellation.label;

    api.post(`${url}/cancelDiagnosticQueue`, { formData }).catch((error) => {
        dispatch({type: LOADER_SURGERY, cargando: false});
        Swal(
            'Error',
            error.detail || 'Ha ocurrido un error, por favor vuelva a intentar.',
            'error'
        );
    }).then((data) => {
        Swal('Éxito', 'diagnóstico anulado correctamente' , 'success')
        .then(() => {
            dispatch(getColaDiagnostico());
        })
        dispatch({type: LOADER_SURGERY, cargando: false});
    });
}

const searchColaDiagnostico = (search) => (dispatch) => {
    dispatch({type: SET_BUSCADOR_DIAGNOSTIC_QUEUE, buscador_diagnostic_queue: search});
    dispatch(getColaDiagnostico(1));
};

const searchDiagnosticCompleted = (search) => (dispatch) => {
    dispatch({type: SET_BUSCADOR_DIAGNOSTIC_COMPLETED, buscador_diagnostic_completed: search});
    dispatch(getDiagnosticosFinalizados(1));
};

const getResumeDiagnostic = (diagnostic_id) => (dispatch) => {
    api.get(`${url}/getResumeDiagnostic`, { diagnostic_id }).catch((error) => {
        dispatch({type: LOADER_SURGERY, cargando: false});
        Swal(
            'Error',
            error.detail || 'Ha ocurrido un error, por favor vuelva a intentar.',
            'error'
        );
    }).then((data) => {
        console.log("data diagnóstico: ", data);

        try{
            const formulario = {
                nombre_completo: data.patient.first_name + ' ' + data.patient.last_name,
                referent: {value: data.referent.id, label: data.referent.name},
                date: data.created,
                //nombre_servicio:
            }

            dispatch(initializeForm('showexamorder', formulario));
        }catch(error){
            console.log("Error al inicializar el formulario: ", error)
        }

        dispatch({type: SET_RESUME_DIAGNOSTIC, resume_diagnostic: data});
    });
}

const quitDiagnosticOrder = (diagnostic_id) => (dispatch) => {
    Swal.fire({
        title: '¿Está seguro(a) que desea quitar el diagnóstico?, no podrá deshacer ésta acción',
        type: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#aaa',
        confirmButtonText: 'Aceptar',
        cancelButtonText: 'Cancelar',
        reverseButtons: true
    }).then((result) => {
        if (result.value) {
            api.post(`${url}/quitDiagnosticOrder`, { diagnostic_id }).catch((error) => {
                dispatch({type: LOADER_SURGERY, cargando: false});
                Swal(
                    'Error',
                    error.detail || 'Ha ocurrido un error, por favor vuelva a intentar.',
                    'error'
                );
            }).then((data) => {
                Swal('Éxito', 'El diagnóstico se ha quitado de la lista' , 'success')
                .then(() => {
                    dispatch(listar());
                })
                dispatch({type: LOADER_SURGERY, cargando: false});
            });
        }
    });
}

const getProcedimiento = (procedimiento_id) => (dispatch) => {
    api.get(`${url}/getOneDiagnostic`, { id: procedimiento_id }).catch((error) => {
        dispatch({type: LOADER_SURGERY, cargando: false});
        Swal(
            'Error',
            error.detail || 'Ha ocurrido un error, por favor vuelva a intentar.',
            'error'
        );
    }).then((data) => {
        console.log("Datos del diagnóstico: ", data);
        dispatch({type: SET_DATA_PROCEDIMIENTO, data_procedimiento: data});
        // Agregamos el id al formulario
        dispatch(initializeForm('diagnosticTranscriptionForm', {id: data.id, descripcion: data.transcription }));
    });
}

const updateOneDiagnostic = (data) => (dispatch) => {
    const diagnostic_id = data.diagnostic_id;

    api.put(`${url}/updateOneDiagnostic`, {...data}).catch((error) => {
        Swal(
            'Error',
            error.detail || 'Ha ocurrido un error, por favor vuelva a intentar.',
            'error'
        );
    }).then((data) => {
        Swal('Éxito', 'Datos guardados correctamente' , 'success')
        .then(() => {
            dispatch(getResumeDiagnostic(diagnostic_id));
        });
    })
}

const deliveredOneDiagnostic = (procedimiento_id, diagnostic_id) => (dispatch) => {
    Swal.fire({
        title: '¿Está seguro(a) que desea marcar el diagnóstico como entregado al paciente?, no podrá deshacer ésta acción',
        type: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#aaa',
        confirmButtonText: 'Aceptar',
        cancelButtonText: 'Cancelar',
        reverseButtons: true
    }).then((result) => {
        if (result.value) {
            api.put(`${url}/deliveredOneDiagnostic`, {id: procedimiento_id}).catch((error) => {
                Swal(
                    'Error',
                    error.detail || 'Ha ocurrido un error, por favor vuelva a intentar.',
                    'error'
                );
            }).then((data) => {
                Swal('Éxito', 'Datos guardados correctamente' , 'success')
                .then(() => {
                    dispatch(getResumeDiagnostic(diagnostic_id));
                });
            })
        }
    });
}

const sentOneDiagnostic = (id) => (dispatch) => {
    Swal.fire({
        title: '¿Está seguro(a) que desea marcar como enviado el diagnóstico?, no podrá deshacer ésta acción',
        type: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#aaa',
        confirmButtonText: 'Aceptar',
        cancelButtonText: 'Cancelar',
        reverseButtons: true
    }).then((result) => {
        if (result.value) {
            api.put(`${url}/sentOneDiagnostic`, {id}).catch((error) => {
                Swal(
                    'Error',
                    error.detail || 'Ha ocurrido un error, por favor vuelva a intentar.',
                    'error'
                );
            }).then((data) => {
                Swal('Éxito', 'Datos guardados correctamente' , 'success')
                .then(() => {
                    //dispatch(getProcedimiento(id));
                    dispatch(getResumeDiagnostic(id));
                });
            });
        }
    });
}

const cerrarModalCorreo = () => (dispatch) => {
    dispatch({type: SET_SHOW_MODAL_CORREO, show_modal_correos: false});
}

const abrirModalCorreo = () => (dispatch) => {
    dispatch({type: SET_SHOW_MODAL_CORREO, show_modal_correos: true});
}

const enviarCorreo = (correos, id) => (dispatch, getStore) => {
    if(correos.length === 0){
        Swal(
            'Error',
            'Debe ingresar al menos un destinatario',
            'error'
        );
    }else{
        api.put(`${url}/sentOneDiagnostic`, {correos, id}).then((data) => {
            Swal('Éxito', 'La transcripción ha sido enviada' , 'success')
            .then(() => {
                dispatch({type: SET_SHOW_MODAL_CORREO, show_modal_correos: false});
                dispatch(getResumeDiagnostic(id));
            });
        }).catch((error) => {
            Swal(
                'Error',
                error.detail || 'Ha ocurrido un error, por favor vuelva a intentar.',
                'error'
            );
        });
    }
}

const cerrarModalCorreoResume = () => (dispatch) => {
    dispatch({type: SET_SHOW_MODAL_CORREO_RESUME, show_modal_correos_resume: false});
}

const abrirModalCorreoResume = () => (dispatch) => {
    dispatch({type: SET_SHOW_MODAL_CORREO_RESUME, show_modal_correos_resume: true});
}

const enviarCorreoResume = (correos, id) => (dispatch, getStore) => {
    console.log("Enviar resumen de correo [correo] : ", correos);
    console.log("Enviar resumen de correo [id] : ", id);

    if(correos.length === 0){
        Swal(
            'Error',
            'Debe ingresar al menos un destinatario',
            'error'
        );
    }else{
        api.post(`${url}/setDiagnosticFilesInterpreter`, {correos, id}).then((data) => {
            Swal('Éxito', data.detail , 'success')
            .then(() => {
                dispatch({type: SET_SHOW_MODAL_CORREO_RESUME, show_modal_correos_resume: false});
                //dispatch(getResumeDiagnostic(id));
            });
        }).catch((error) => {
            Swal(
                'Error',
                error.detail || 'Ha ocurrido un error, por favor vuelva a intentar.',
                'error'
            );
        });
    }
}

const set_initial_date = (value) => (dispatch) => {
    dispatch({type: SET_INICIAL_DATE, initial_date: value});
    dispatch(getDiagnosticosFinalizados());
}
const set_final_date = (value) => (dispatch) => {
    dispatch({type: SET_FINAL_DATE, final_date: value});
    dispatch(getDiagnosticosFinalizados());
}

/* ADD */
const setPaciente = (patient) => (dispatch, getStore) => {
    const form = getStore().form.diagnostic;
    let nit = (patient) ? patient.nit : null;

    if(patient.label === undefined){
        dispatch(initializeForm('diagnostic', {...form.values, patient: patient, referent: null}));
        dispatch({type: SET_SERVICES_LIST, services_list: []});
    }
    else{
    api.get(`configuration/getConfiguration`).then((response)=> {
        const default_financial_entity = response.default_financial_entity;

        if(patient.id_referent !== null && patient.id_referent !== undefined){
            api.get("referents/getOneReferent", {referent_id: patient.id_referent}).then(data => {
                let referente = {};

                let idFinancialEntity = 0;
                let usuarioFel = "";
                let idReferentCategory = 0;
                let receipt = false;
                let afiliacion_fel = ""; //add

                if(data.institution !== null){
                    idFinancialEntity = data.institution.referent_category.financial_entity.id;
                    usuarioFel = data.institution.referent_category.financial_entity.usuario_fel;
                    idReferentCategory = data.institution.referent_category.id;
                    receipt = data.institution.referent_category.issue_receipt;
                    afiliacion_fel =  data.institution.referent_category.financial_entity.afiliacion_fel; //add
                }else{
                    idFinancialEntity = default_financial_entity.id;
                    usuarioFel = default_financial_entity.usuario_fel;
                    idReferentCategory = 0;
                    receipt = default_financial_entity.afiliacion_fel === "exento" ? true : false;
                    afiliacion_fel = default_financial_entity.usuario_fel; //add
                }

                referente = {
                    value: data.id,
                    label: data.name,
                    usuario_fel: usuarioFel,
                    financial_entity: idFinancialEntity,
                    idReferentCategory: idReferentCategory,
                    receipt,
                    afiliacion_fel, //add
                };

                dispatch(initializeForm('diagnostic', {...form.values, patient: patient, referent: referente}));
                dispatch({type: SET_SERVICES_LIST, services_list: []});
            })
        }else{
            dispatch(initializeForm('diagnostic', {...form.values, patient: patient, referent: null}));
            dispatch({type: SET_SERVICES_LIST, services_list: []});
        }
    });
    }
}
/* ADD ... */

export const actions = {
    listar,
    create,
    search,
    filtro,
    listarServicios,
    searchService,
    addServiceToList,
    removeServiceToList,
    upDownCant,
    toggleModalServicios,
    endDiagnostic,
    addServiceToListDiff,

    /* De la cola de diagnóstico */
    getColaDiagnostico,
    cancelarColaDiagnostico,
    searchColaDiagnostico,
    startDiagnostic,
    getOneDiagnostic,

    /* De los diagnósticos finalizados */
    getDiagnosticosFinalizados,
    iniciarDiagnostico,
    searchDiagnosticCompleted,

    getResumeDiagnostic,

    quitDiagnosticOrder,
    getProcedimiento,
    updateOneDiagnostic,

    sentOneDiagnostic,
    deliveredOneDiagnostic,

    cerrarModalCorreo,
    abrirModalCorreo,
    enviarCorreo,

    cerrarModalCorreoResume,
    abrirModalCorreoResume,
    enviarCorreoResume,
    set_final_date,
    set_initial_date,

    setPaciente, //add...
};

export const reducer = {
    [SET_INICIAL_DATE]: (state, { initial_date }) => {
        return{
            ...state,
            initial_date
        }
    },
    [SET_FINAL_DATE]: (state, { final_date }) => {
        return{
            ...state,
            final_date
        }
    },
    [GROUP_BILL]: (state) => {
        const arreglo = state.services_list;
        const result = groupBy(arreglo, (a) => a.financial_entity);
        let facturas = [];
        let cont = 0;
        Object.entries(result).forEach((element) => {
            let financial_entity = { id: 0 , name: element[0] }
            let dataBilling = {
                    nit: "",
                    name: "",
                    correlative: null,
                    code: "",
                    state: 0,
                    id: 0,
                    fel_status:0,
                    fel_error: '',
                    serie:'',
                    numero:'',
                    numeroAutorizacion:'',
                    total_movement:0,
                    total_paid: 0,
                    retention: false,
                    retention_amount: 0,
                };

            let verify_datos_facturacion = false;

            let services = [];
            element[1].forEach((service, key) => {
                if(key === 0){
                    financial_entity.id = service.financial_entity;
                }

                if(!verify_datos_facturacion && service.verify_datos_facturacion !== null){
                    dataBilling.name = '';
                    dataBilling.nit = 'CF';
                    verify_datos_facturacion = true;
                }


                let obj = {
                    service_name: service.name,
                    service: service.id,
                    price: service.price,
                    quantity : service.quantity,
                    discount : 0,
                    used: service.used,
                    initial: service.initial,
                    discount_authorization: false,
                    accumulated_discount: 0,//ADD
                    //subtotal : service.total,
                };

                services.push(obj);
            });

            const calculate = { }

            const dataPayments = { };
            facturas.push({ financial_entity, services, dataPayments, calculate, dataBilling });
            cont++;
        })

        return{
            ...state,
            services_perform_surgery_ordered: facturas
        }
    },
    [SET_SERVICES_LIST]: (state, { services_list }) => {
        return {...state, services_list }
    },
    [LOADER_SURGERY]: (state, { cargando }) => {
        return {...state, cargando }
    },
    [SET_DATA_SURGERY]: (state, { data }) => {
        return {...state, data }
    },
    [SET_BUSCADOR_SURGERY]: (state, { buscador }) => {
        return {...state, buscador }
    },
    [SET_FILTRO_SURGERY]: (state, { filtro_appointments }) => {
        return {...state, filtro_appointments }
    },
    [SET_BUSCADOR_SERVICE]: (state, { buscador_service }) => {
        return {...state, buscador_service }
    },
    [LOADER_SERVICE]: (state, { cargando }) => {
        return {...state, cargando_services: cargando }
    },
    [SET_DATA_SERVICE]: (state, { data }) => {
        return {...state, data_service : data }
    },
    [SET_PAGE_SERVICE]: (state, { page }) => {
        return {...state, page_service: page }
    },
    [SET_MODAL_SERVICE]: (state, { modal_service }) => {
        return {...state, modal_service }
    },

    /* del diagnostico (general) */
    [SET_DATA_DIAGNOSTIC]: (state, { data }) => {
        return {...state, data }
    },
    [SET_PAGE_DIAGNOSTIC]: (state, { page }) => {
        return {...state, page }
    },

    /* de la cola de diagnóstico */
    [SET_DATA_DIAGNOSTIC_QUEUE]: (state, { diagnostic_queue }) => {
        return {...state, diagnostic_queue }
    },
    [SET_PAGE_DIAGNOSTIC_QUEUE]: (state, { page_diagnostic_queue }) => {
        return {...state, page_diagnostic_queue }
    },
    [SET_BUSCADOR_DIAGNOSTIC_QUEUE]: (state, { buscador_diagnostic_queue }) => {
        return {...state, buscador_diagnostic_queue };
    },

    /* de los diagnósticos completados */
    [SET_DATA_DIAGNOSTIC_COMPLETED]: (state, { diagnostic_completed }) => {
        return {...state, diagnostic_completed }
    },
    [SET_PAGE_DIAGNOSTIC_COMPLETED]: (state, { page_diagnostic_completed }) => {
        return {...state, page_diagnostic_completed }
    },
    [SET_BUSCADOR_DIAGNOSTIC_COMPLETED]: (state, { buscador_diagnostic_completed }) => {
        return {...state, buscador_diagnostic_completed };
    },

    [SET_RESUME_DIAGNOSTIC]: (state, { resume_diagnostic }) => {
        return {...state, resume_diagnostic };
    },
    [SET_DATA_PROCEDIMIENTO]: (state, { data_procedimiento }) => {
        return {...state, data_procedimiento };
    },

    [SET_SHOW_MODAL_CORREO]: (state, { show_modal_correos }) => {
        return {...state, show_modal_correos };
    },

    [SET_SHOW_MODAL_CORREO_RESUME]: (state, { show_modal_correos_resume }) => {
        return {...state, show_modal_correos_resume };
    },
    [SET_LOADER]: (state, { loader }) => {
        return {...state, loader };
    }
}

export const initialState = {
    loader:  false,
    cargando: false,
    page: 1,
    // result: [],
    //data: [],
    data: {
        count: 0,
        next: null,
        previous: null,
        results: [],
    },

    buscador: '',


    services_list: [],

    data_service: {
        count: 0,
        next: null,
        previous: null,
        results: [],
    },

    cargando_services: false,
    page_service: 1,
    buscador_service: '',
    filtro_service: null,
    modal_service: false,

    services_perform_surgery: [],

    surgery_info: null,
    observations: '',

    openCashBox: null,
    services_perform_surgery_ordered: null,

    initial_date: moment().date(1).format("DD/MM/YYYY"),
    final_date: moment().format("DD/MM/YYYY"),

    /* COLA DE DIAGNÓSTICO */
    diagnostic_queue: {
        count: 0,
        next: null,
        previous: null,
        results: [],
    },
    buscador_diagnostic_queue: '',
    page_diagnostic_queue:1,
    initial_date_diagnostic_queue: moment().date(1).format("DD/MM/YYYY"),
    final_date_diagnostic_queue: moment().format("DD/MM/YYYY"),
    /* COLA DE DIAGNÓSTICO */


    /* DIAGNÓSTICOS TERMINADOS */
    diagnostic_completed: {
        count: 0,
        next: null,
        previous: null,
        results: [],
    },
    buscador_diagnostic_completed: '',
    page_diagnostic_completed:1,
    initial_date_diagnostic_completed: moment().date(1).format("DD/MM/YYYY"),
    final_date_diagnostic_completed: moment().format("DD/MM/YYYY"),
    /* DIAGNÓSTICOS TERMINADOS */

    resume_diagnostic: null,
    data_procedimiento: null,

    show_modal_correos: false,
    show_modal_correos_resume: false,
};

export default handleActions(reducer, initialState)
