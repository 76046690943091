const url = 'surgery';
import { api } from 'api';
import Swal from 'sweetalert2';
import { push } from 'react-router-redux';
import { reset, initialize as initializeForm } from 'redux-form';
import { handleActions } from 'redux-actions';
import moment from "moment";

const LOADER_TODAYS_SURGERY = 'LOADER_TODAYS_SURGERY';
const SET_DATA_TODAYS_SURGERY = 'SET_DATA_TODAYS_SURGERY';
const SET_PAGE_TODAYS_SURGERY = 'SET_PAGE_TODAYS_SURGERY';
const SET_UPDATE_DATA_TODAYS_SURGERY = 'SET_UPDATE_DATA_TODAYS_SURGERY';
const SET_BUSCADOR_TODAYS_SURGERY = 'SET_BUSCADOR_TODAYS_SURGERY';
const SET_FILTRO_TODAYS_SURGERY = 'SET_FILTRO_TODAYS_SURGERY';
const SET_UPDATE_DATA = 'SET_UPDATE_DATA';
const SET_COUPON_TODAYS_SURGERY = 'SET_COUPON_TODAYS_SURGERY';
const SET_INICIAL_DATE_TODAYS_SURGERY = 'SET_INICIAL_DATE_TODAYS_SURGERY';
const SET_FINAL_DATE_TODAYS_SURGERY = 'SET_FINAL_DATE_TODAYS_SURGERY';
const SET_DATA_REPORT_TODAYS_SURGERY = 'SET_DATA_REPORT_TODAYS_SURGERY';
const SET_PAGE_REPORT_TODAYS_SURGERY = 'SET_PAGE_REPORT_TODAYS_SURGERY';

const listar = (page = 1) =>  (dispatch, getStore) => {
    dispatch({type: LOADER_TODAYS_SURGERY, cargando: true});
    const store = getStore();
    const search = store.todays_surgery.buscador;
    const filtro = store.todays_surgery.filtro_todays_surgery;

    let params = {page, search};
    if(filtro){
        params['state'] = filtro
    }

    api.get(`${url}/todaysSurgery`, params).catch((error) => {
        dispatch({type: LOADER_TODAYS_SURGERY, cargando: false});
        Swal(
            'Error',
            error.detail || 'Ha ocurrido un error, por favor vuelva a intentar.',
            'error'
        );
    }).then((data) => {
        console.log("data coupons: ", data);
        if(data){
            dispatch({type: SET_DATA_TODAYS_SURGERY, data});
            dispatch({type: SET_PAGE_TODAYS_SURGERY, page});
        }
        dispatch({type: LOADER_TODAYS_SURGERY, cargando: false});
    })
};

const listarReporte = (page = 1) =>  (dispatch, getStore) => {
    dispatch({type: LOADER_TODAYS_SURGERY, cargando: true});
    const store = getStore();
    const search = store.todays_surgery.buscador;
    const filtro = store.todays_surgery.filtro_todays_surgery;

    const initial_date = store.todays_surgery.initial_date;
    const final_date = store.todays_surgery.final_date;

    let params = {page, search, initial_date, final_date};

    if(filtro){
        params['state'] = filtro
    }

    api.get(`${url}/surgeryReport`, params).catch((error) => {
        dispatch({type: LOADER_TODAYS_SURGERY, cargando: false});
        Swal(
            'Error',
            error.detail || 'Ha ocurrido un error, por favor vuelva a intentar.',
            'error'
        );
    }).then((data) => {
        console.log("data coupons: ", data);
        if(data){
            dispatch({type: SET_DATA_REPORT_TODAYS_SURGERY, data_report: data});
            dispatch({type: SET_PAGE_REPORT_TODAYS_SURGERY, page_report: page});
        }
        dispatch({type: LOADER_TODAYS_SURGERY, cargando: false});
    })
};

const anularRegistroCirugia = (surgery_id) => (dispatch, getStore) => {
    Swal.fire({
        title: '¿Está seguro(a) que desea anular el registro de Cirugía?',
        type: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#aaa',
        confirmButtonText: 'Aceptar',
        cancelButtonText: 'Cancelar',
        reverseButtons: true
    }).then((result) => {
        if (result.value) {
            dispatch({type: LOADER_TODAYS_SURGERY, cargando: true});
            api.post(`${url}/cancelSurgeryRegister`, { surgery_id }).then((data) => {
                dispatch({type: LOADER_TODAYS_SURGERY, cargando: false});
                Swal('Éxito', 'El Registro de cirugía se ha anulado correctamente.', 'success')
                    .then(() => {
                        dispatch(listarReporte(1));
                    })
            }).catch((error) => {
                dispatch({type: LOADER_TODAYS_SURGERY, cargando: false});
                Swal('Error', error.detail + ' No se ha logrado el registro de criguría', 'error')
                    .then(() => {
                        dispatch(listarReporte(1));
                    })
            })
        }
    });
}
/*
const anularCupon = (coupon_id) => (dispatch, getStore) => {
    Swal.fire({
        title: '¿Está seguro(a) que desea anular el cupon?',
        type: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#aaa',
        confirmButtonText: 'Aceptar',
        cancelButtonText: 'Cancelar',
        reverseButtons: true
    }).then((result) => {
        if (result.value) {
            dispatch({type: LOADER_COUPON, cargando: true});
            api.post(`${url}/cancelCoupon`, { coupon_id }).then((data) => {
                dispatch({type: LOADER_COUPON, cargando: false});
                Swal('Éxito', 'El cupon se ha anulado correctamente.', 'success')
                    .then(() => {
                        dispatch(listar(1));
                    })
            }).catch((error) => {
                dispatch({type: LOADER_COUPON, cargando: false});
                Swal('Error', error.detail + ' No se ha logrado anular el cupon', 'error')
                    .then(() => {
                        dispatch(listar(1));
                    })
            })
        }
    });
}
*/

const search = (search) => (dispatch) => {
    dispatch({type: SET_BUSCADOR_TODAYS_SURGERY, buscador: search});
    dispatch(listar(1));
};

const filtro = (filtro) => (dispatch, getStore) => {
    if (filtro == 0){
        filtro = null
    }
    dispatch({type: SET_FILTRO_TODAYS_SURGERY, filtro_todays_surgery: filtro});
    dispatch(listar(1));
};

const searchReport = (search) => (dispatch) => {
    dispatch({type: SET_BUSCADOR_TODAYS_SURGERY, buscador: search});
    dispatch(listarReporte(1));
};

const filtroReporte = (filtro) => (dispatch, getStore) => {
    if (filtro == 0){
        filtro = null
    }
    dispatch({type: SET_FILTRO_TODAYS_SURGERY, filtro_todays_surgery: filtro});
    dispatch(listarReporte(1));
};

const set_final_date = (value) => (dispatch) => {
    dispatch({type: SET_FINAL_DATE_TODAYS_SURGERY, final_date: value});
    // dispatch(listarReporte(1));
}

const set_initial_date = (value) => (dispatch) => {
    dispatch({type: SET_INICIAL_DATE_TODAYS_SURGERY, initial_date: value});
    // dispatch(listarReporte(1));
}

export const actions = {
    listar,
    search,
    filtro,
    //anularCupon,
    set_final_date,
    set_initial_date,
    listarReporte,

    searchReport,
    filtroReporte,
    anularRegistroCirugia,
};

export const reducer = {
    [SET_COUPON_TODAYS_SURGERY]: (state, { buscador }) => {
        return {...state, buscador }
    },
    [LOADER_TODAYS_SURGERY]: (state, { cargando }) => {
        return {...state, cargando }
    },
    [SET_DATA_TODAYS_SURGERY]: (state, { data }) => {
        return {...state, data }
    },
    [SET_DATA_REPORT_TODAYS_SURGERY]: (state, { data_report }) => {
        return {...state, data_report }
    },
    [SET_PAGE_TODAYS_SURGERY]: (state, { page }) => {
        return {...state, page }
    },
    [SET_PAGE_REPORT_TODAYS_SURGERY]: (state, { page_report }) => {
        return {...state, page_report }
    },
    [SET_UPDATE_DATA_TODAYS_SURGERY]: (state, { updateData }) => {
        return {...state, updateData }
    },
    [SET_BUSCADOR_TODAYS_SURGERY]: (state, { buscador }) => {
        return {...state, buscador }
    },
    [SET_FILTRO_TODAYS_SURGERY]: (state, { filtro_todays_surgery }) => {
        return {...state, filtro_todays_surgery }
    },
    [SET_UPDATE_DATA]: (state) => {
        return { ...state, updateData: {}}
    },
    [SET_INICIAL_DATE_TODAYS_SURGERY]: (state, { initial_date }) => {
        return{
            ...state,
            initial_date
        }
    },
    [SET_FINAL_DATE_TODAYS_SURGERY]: (state, { final_date }) => {
        return{
            ...state,
            final_date
        }
    },
};

export const initialState = {
    cargando: false,
    page: 1,
    data: {
        count: 0,
        next: null,
        previous: null,
        results: [],
    },

    page_report: 1,
    data_report: {
        count: 0,
        next: null,
        previous: null,
        results: [],
    },

    buscador: '',
    filtro_todays_surgery: null,
    updateData: {},
    initial_date: moment().date(1).format("DD/MM/YYYY"),
    final_date: moment().format("DD/MM/YYYY"),
};

export default handleActions(reducer, initialState)
