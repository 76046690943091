import React from 'react';
import { Link } from 'react-router-dom';
import { Field, reduxForm } from 'redux-form';
import { validate, validators } from 'validate-redux-form';
import {renderSearchCreateSelect, renderField, renderSelectCustom} from "../../Utils/renderField";
import {api} from "../../../../api/api";
import {modalTextArea} from "../../Utils/renderField/renderField";

import { Esf } from "../../Utils/Notations/lensometry/esf";
import { Cil } from "../../Utils/Notations/lensometry/cil";
import { Ejz } from "../../Utils/Notations/lensometry/eje";
import { Add } from "../../Utils/Notations/lensometry/add";

const getLenstypes = (search) => {
    return api.get("record", {search, record_type: 25})
        .then(results => results.results.map(item => {return {label: item.name, value: item.id}}))
        .catch(() => []);
};


const Form = (props) => {
    const {handleSubmit} = props;
    const view = window.location.href.includes("reporte");

    return (
        <form onSubmit={handleSubmit} className="d-flex flex-column">
            <div className='mt-3 mb-3' style={{borderBottom:'solid 1px black'}}>
                {!view ? <h4><strong>MÚSCULOS EXTRAOCULARES</strong></h4> : null}
            </div>

            <div className='d-flex flex-row justify-content-center mt-5'>
                <div className="d-flex flex-column justify-content-center px-0 px-sm-2 px-md-3 px-lg-5 w-100">
                    <div className='d-flex flex-column align-items-center'>
                        <div className='d-flex flex-row justify-content-center'>
                            <label className="radio-inline mr-3 d-flex align-items-center">
                                OD&nbsp;
                            </label>
                            <label className="radio-inline mr-3 d-flex align-items-center">
                                OK&nbsp;
                                <Field
                                    name="od_state"
                                    component={renderField}
                                    type="radio"
                                    value={"True"}
                                    disabled={view}
                                />
                            </label>
                            <label className="radio-inline mr-3 d-flex align-items-center">
                                Diferido&nbsp;
                                <Field
                                    name="od_state"
                                    component={renderField}
                                    type="radio"
                                    value={"False"}
                                    disabled={view}
                                />
                            </label>

                            <button type="button" 
                                className='btn btn-sm'
                            onClick={()=>{
                                props.change('od_state', null);
                            }}>Restablecer</button>
                        </div>

                        <div style={{border: 'solid 1px #AAA', marginTop: 25, marginBottom: 25}} className='w-50'></div>

                        <div className='d-flex flex-row justify-content-center'>
                            <label className="radio-inline mr-3 d-flex align-items-center">
                                OS&nbsp;
                            </label>
                            <label className="radio-inline mr-3 d-flex align-items-center">
                                OK&nbsp;
                                <Field
                                    name="os_state"
                                    component={renderField}
                                    type="radio"
                                    value={"True"}
                                    disabled={view}
                                />
                            </label>
                            <label className="radio-inline mr-3 d-flex align-items-center">
                                Diferido&nbsp;
                                <Field
                                    name="os_state"
                                    component={renderField}
                                    type="radio"
                                    value={"False"}
                                    disabled={view}
                                />
                            </label>
                            <button type="button" 
                            className='btn btn-sm'
                            onClick={()=>{
                                props.change('os_state', null);
                            }}>Restablecer</button>
                        </div>
                    </div>


                    <div className='d-flex flex-row justify-content-center'>
                        <div className="mb-md-4 d-flex justify-content-between flex-column flex-sm-row align-items-stretch align-items-sm-center mt-4 w-50">
                            <div className="m-2 d-flex flex-column w-100">
                                <div className="d-flex justify-content-start flex-column flex-sm-row align-items-stretch align-items-sm-center">
                                    <label htmlFor="notes">Notas</label>
                                </div>
                                <div className='d-flex flex-row'>
                                    <label className="radio-inline mr-3 d-flex align-items-center">
                                        OD&nbsp;
                                        <Field
                                            name="type"
                                            component={renderField}
                                            type="radio"
                                            value={"1"}
                                            disabled={view}
                                        />
                                    </label>
                                    <label className="radio-inline mr-3 d-flex align-items-center">
                                        OS&nbsp;
                                        <Field
                                            name="type"
                                            component={renderField}
                                            type="radio"
                                            value={"2"}
                                            disabled={view}
                                        />
                                    </label>
                                    <label className="radio-inline mr-3 d-flex align-items-center">
                                        OU&nbsp;
                                        <Field
                                            name="type"
                                            component={renderField}
                                            type="radio"
                                            value={"3"}
                                            disabled={view}
                                        />
                                    </label>
                                </div>
                                <div className="d-flex flex-row w-100">
                                    <div className="d-flex flex-row w-75">
                                        <Field
                                            name="notes"
                                            component={modalTextArea}
                                            className="annotations"
                                            disabled={view}
                                        />
                                    </div>
                                    <div className='d-flex flex-row w-25 ml-3'>
                                        <button
                                            type="button"
                                            className='btn btn-danger'
                                            onClick={()=>{props.change('notes', 'DIFERIDO');}}
                                        >DIFERIDO</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>



                </div>
            </div>
        </form>
    )
};

const CrearForm = reduxForm({
    // a unique name for the form
    form: 'Muscle',
    validate: (data) => {
        return validate(data, {
        });
    },
})(Form);

export default CrearForm;
