import React, { Component } from 'react';
import classNames from 'classnames';

import { RenderCurrency } from 'Utils/renderField/renderReadField';

import './cashBox.css';

class OpeningBox extends Component {
    componentWillMount() {
        this.props.checkCashBox();
        this.props.getConfig();
        this.props.getCashBox(this.props.match.params.id);
    }

    decimalAdjust = (type, value, exp) => {
        if (typeof exp === 'undefined' || +exp === 0) {
          return Math[type](value);
        }
        value = +value;
        exp = +exp;
        if (isNaN(value) || !(typeof exp === 'number' && exp % 1 === 0)) {
          return NaN;
        }
        value = value.toString().split('e');
        value = Math[type](+(value[0] + 'e' + (value[1] ? (+value[1] - exp) : -exp)));
        value = value.toString().split('e');
        return +(value[0] + 'e' + (value[1] ? (+value[1] + exp) : exp));
    }

    state = {
        billete200: 0,
        billete100: 0,
        billete50: 0,
        billete20: 0,
        billete10: 0,
        billete5: 0,
        billete1: 0,
        moneda1: 0,
        moneda1c:0,
        moneda50: 0,
        moneda25: 0,
        moneda10: 0,
        moneda5: 0,
    }

    logChange = (e) => {
        const value = ((parseFloat(e.target.value) <= 0) || e.target.value == '') ? 0 : (parseFloat(e.target.value) * parseFloat(e.target.id)).toFixed(2);
        this.setState({ [e.target.name] : value } );
    }

    render(){
        const {
            openCashBox,
            openBox,
            isNavBarOpen,
        } = this.props;


        let totalFísico = (parseFloat(this.state.billete200) + parseFloat(this.state.billete100) + parseFloat(this.state.billete50) + parseFloat(this.state.billete20) + parseFloat(this.state.billete10) + parseFloat(this.state.billete5) + parseFloat(this.state.billete1) + parseFloat(this.state.moneda1) + parseFloat(this.state.moneda1c)  + parseFloat(this.state.moneda50) + parseFloat(this.state.moneda25) + parseFloat(this.state.moneda10) + parseFloat(this.state.moneda5)).toFixed(2);
        let cuadre = (this.props.configuration != null) ? this.props.configuration.opening_amount : 0;
        let caja_cuadre = (this.decimalAdjust('floor',cuadre,-1) == totalFísico || this.decimalAdjust('ceil',cuadre,-1) == totalFísico) ? true : false;
        let classTotal = (caja_cuadre) ? "dinero-de-mas" : "dinero-faltante";
        let habilitar = (caja_cuadre) ? " " : "disabled";

        return(
            <div className={classNames('content-with-tabs', { 'content-with-tabs-min': isNavBarOpen })}>
                {openCashBox != null &&
                <div>
                <div className="d-inline-flex w-100">
                    <div className="row-fluid w-50 tab-background">
                        <a href="/#/oppening-closing/" className="d-flex justify-content-center align-items-center tab tab-br-br">
                            VENTA
                        </a>
                    </div>
                    <div className="row-fluid w-50 tab-background-blue">
                        <a href="/#/oppening-closing/box" className="d-flex justify-content-center align-items-center tab-active tab-br-tl">
                            CAJA
                        </a>
                    </div>
                </div>

                <div className="table-decoration mx-5 mt-5 mb-3 d-flex flex-column">
                        <div className="fields">
                            <div className="top-title">
                                APERTURA DE CAJA
                            </div>
                            <div className="d-flex">
                                    <div className="w-50 list-group-center">
                                        <ul className="list-group">
                                            <li className="list-group-item active active-cashbox">
                                                Cuadre de Efectivo
                                            </li>
                                            <li className="list-group-item">
                                                <span className="pull-left subtitle">Monto de Apertura</span>
                                                <span className="pull-right subtitle">Total en efectivo</span>
                                            </li>
                                            <li className="list-group-item">
                                                <span className="pull-left"><RenderCurrency value={cuadre} /></span>
                                                <span className={"pull-right " + classTotal}><RenderCurrency value={totalFísico} /></span>
                                            </li>
                                        </ul>
                                    </div>
                            </div>
                            <div className="flex contenedor-seccion my-5">
                                <div className="w-100 seccion">
                                    <div className="w-100 justify-content-center">
                                        <table className="table tabla-cuadre">
                                            <thead>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td className="text-center">
                                                    <img src={require("../../../../assets/img/icons/billete200.png")} alt="CashBox image" className="icono-moneda" />
                                                        <input name="billete200" id="200" onChange={(e) => this.logChange(e)} type="number" className="input-match" placeholder="0"/>
                                                    </td>
                                                    <td className="text-center">
                                                    <img src={require("../../../../assets/img/icons/billete100.png")} alt="CashBox image" className="icono-moneda" />
                                                        <input name="billete100" id="100" onChange={(e) => this.logChange(e)} type="number" className="input-match" placeholder="0"/>
                                                    </td>
                                                    <td className="text-center">
                                                        <img src={require("../../../../assets/img/icons/billete50.png")} alt="CashBox image" className="icono-moneda" />
                                                        <input  name="billete50" id="50" onChange={(e) => this.logChange(e)} type="number" className="input-match" placeholder="0"/>
                                                    </td>
                                                    <td className="text-center">
                                                        <img src={require("../../../../assets/img/icons/billete20.png")} alt="CashBox image" className="icono-moneda" />
                                                        <input  name="billete20" id="20" onChange={(e) => this.logChange(e)} type="number" className="input-match" placeholder="0"/>
                                                    </td>
                                                    <td className="text-center">
                                                        <img src={require("../../../../assets/img/icons/billete10.png")} alt="CashBox image" className="icono-moneda" />
                                                        <input  name="billete10" id="10" onChange={(e) => this.logChange(e)} type="number" className="input-match" placeholder="0"/>
                                                    </td>
                                                    <td className="text-center">
                                                        <img src={require("../../../../assets/img/icons/billete5.png")} alt="CashBox image" className="icono-moneda" />
                                                        <input  name="billete5" id="5" onChange={(e) => this.logChange(e)} type="number" className="input-match" placeholder="0"/>
                                                    </td>
                                                    <td className="text-center">
                                                        <img src={require("../../../../assets/img/icons/billete1.png")} alt="CashBox image" className="icono-moneda" />
                                                        <input  name="billete1" id="1" onChange={(e) => this.logChange(e)} type="number" className="input-match" placeholder="0"/>
                                                    </td>
                                                </tr>
                                                <tr style={{justifyContent:"center"}}>                                                    
                                                    <td className="text-center">
                                                        <img src={require("../../../../assets/img/icons/moneda1.png")} alt="CashBox image" className="icono-moneda" />
                                                        <input  name="moneda1" id="1" onChange={(e) => this.logChange(e)} type="number" className="input-match" placeholder="0"/>
                                                    </td>                                                    
                                                    <td className="text-center">
                                                        <img src={require("../../../../assets/img/icons/moneda50.png")} alt="CashBox image" className="icono-moneda" />
                                                        <input name="moneda50" id="0.50" onChange={(e) => this.logChange(e)} type="number" className="input-match" placeholder="0"/>
                                                    </td>
                                                    <td className="text-center">
                                                        <img src={require("../../../../assets/img/icons/moneda25.png")} alt="CashBox image" className="icono-moneda" />
                                                        <input name="moneda25" id="0.25" onChange={(e) => this.logChange(e)} type="number" className="input-match" placeholder="0"/>
                                                    </td>
                                                    <td className="text-center">
                                                        <img src={require("../../../../assets/img/icons/moneda10.png")} alt="CashBox image" className="icono-moneda" />
                                                        <input  name="moneda10" id="0.10" onChange={(e) => this.logChange(e)} type="number" className="input-match" placeholder="0"/>
                                                    </td>
                                                    <td className="text-center">
                                                        <img src={require("../../../../assets/img/icons/moneda5.png")} alt="CashBox image" className="icono-moneda" />
                                                        <input  name="moneda5" id="0.05" onChange={(e) => this.logChange(e)} type="number" className="input-match" placeholder="0"/>
                                                    </td>
                                                    <td className="text-center">
                                                        <img src={require("../../../../assets/img/icons/moneda1c.png")} alt="CashBox image" className="icono-moneda" />
                                                        <input  name="moneda1c" id="0.01" type="number" min="0" max="99" onChange={(e) => this.logChange(e)}  className="input-match" placeholder="0"/>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>

                                <div className="w-100 seccion-button-closeBox">
                                    <div className="w-100 justify-content-center">
                                        <a href="/#/oppening-closing/open-box/" className="btn btn-secondary btn-sm">Cancelar</a>
                                        &nbsp;&nbsp;&nbsp;
                                        <button className={"btn btn-primary btn-sm " + habilitar}
                                                onClick={(caja_cuadre) ? () => openBox(this.props.cashBox) : null}
                                        >Aperturar Caja</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                </div>
                </div>
                }
            </div>
        );
    }
}

export default OpeningBox;
