import React from 'react';
import {Link} from 'react-router-dom';
import {Field, reduxForm, formValueSelector} from 'redux-form';
import { connect } from 'react-redux';
import {validate} from 'validate-redux-form';
import {renderField} from "../../Utils/renderField";
import {modalTextArea, renderFieldCheck} from "../../Utils/renderField/renderField";
import { Esf } from "../../Utils/Notations/lensometry/esf";
import { Cil } from "../../Utils/Notations/lensometry/cil";
import { Ejz } from "../../Utils/Notations/lensometry/eje";
import { Dip } from "../../Utils/Notations/autorefractor/dip";

const Form = (props) => {
    const {handleSubmit} = props;
    const {
        od_esf,
        os_esf,
        od_cil,
        os_cil,
    } = props;

    const view = window.location.href.includes("reporte");

    return (
        <form onSubmit={handleSubmit} className="d-flex flex-column">
            {!view ? <h3><strong>Autorefractómetro</strong></h3> : null }
            <div className="d-flex flex-column justify-content-center px-0 px-sm-2 px-md-3 px-lg-5">
                <table className="table-input">
                    <tbody>
                    <tr>
                        <th />
                        <th>
                            ESF
                        </th>
                        <th />
                        <th>
                            CIL
                        </th>
                        <th />
                        <th>
                            EJE
                        </th>
                        <th>
                            No marca
                        </th>
                        <th>
                            DIP
                        </th>
                    </tr>
                    <tr>
                        <td>OD</td>
                        <td className={od_esf >= 0 ? 'color-verde' : 'color-rojo'}>
                            <Field name="od_esf" component={!view ? Esf : renderField} disabled={view} title={'OD (ESF)'} className="form-control"/>
                        </td>
                        <td><strong>-</strong></td>
                        <td className={od_cil >= 0 ? 'color-verde' : 'color-rojo'}>
                            <Field name="od_cil" component={!view ? Cil : renderField} disabled={view} title={'OD (CIL)'} className="form-control"/>
                        </td>
                        <td><strong>x</strong></td>
                        <td className='font-weight-bold'>
                            <Field name="od_eje" component={!view ? Ejz : renderField} disabled={view} title={'OD (EJE)'} className="form-control"/>
                        </td>
                        <td>
                            <Field name="od_no_marca" component={renderFieldCheck} disabled={view} type="text" className="form-control m-0"/>
                        </td>
                        <td />
                    </tr>
                    <tr>
                        <td>OS</td>
                        <td className={os_esf >= 0 ? 'color-verde' : 'color-rojo'}>
                            <Field name="os_esf" component={!view ? Esf : renderField} disabled={view} title={'OS (ESF)'} className="form-control"/>
                        </td>
                        <td><strong>-</strong></td>
                        <td className={os_cil >= 0 ? 'color-verde' : 'color-rojo'}>
                            <Field name="os_cil" component={!view ? Cil : renderField} disabled={view} title={'OS (CIL)'} className="form-control"/>
                        </td>
                        <td><strong>x</strong></td>
                        <td className='font-weight-bold'>
                            <Field name="os_eje" component={!view ? Ejz : renderField} disabled={view} title={'OS (EJE)'} className="form-control"/>
                        </td>
                        <td>
                            <Field name="os_no_marca" component={renderFieldCheck} disabled={view} type="text" className="form-control m-0"/>
                        </td>
                        <td className='font-weight-bold'>
                            <Field name="dip" component={!view ? Dip : renderField} disabled={view} title={" DIP "} disabled={view} className="form-control"/>
                        </td>
                    </tr>
                    </tbody>
                </table>
                <div className='d-flex flex-row justify-content-center'>
                    <div className="mb-md-4 d-flex justify-content-between flex-column flex-sm-row align-items-stretch align-items-sm-center mt-4 w-50">
                        <div className="m-2 d-flex flex-column w-100">
                            <div className="d-flex justify-content-start flex-column flex-sm-row align-items-stretch align-items-sm-center">
                                <label htmlFor="notes">Notas</label>
                            </div>
                            <div className='d-flex flex-row'>
                                <label className="radio-inline mr-3 d-flex align-items-center">
                                    OD&nbsp;
                                    <Field
                                        name="type"
                                        component={renderField}
                                        type="radio"
                                        value={"1"}
                                        disabled={view}
                                    />
                                </label>
                                <label className="radio-inline mr-3 d-flex align-items-center">
                                    OS&nbsp;
                                    <Field
                                        name="type"
                                        component={renderField}
                                        type="radio"
                                        value={"2"}
                                        disabled={view}
                                    />
                                </label>
                                <label className="radio-inline mr-3 d-flex align-items-center">
                                    OU&nbsp;
                                    <Field
                                        name="type"
                                        component={renderField}
                                        type="radio"
                                        value={"3"}
                                        disabled={view}
                                    />
                                </label>
                            </div>
                            <div className="d-flex flex-row w-100">
                                <div className="d-flex flex-row w-75">
                                <Field
                                    name="notes"
                                    component={modalTextArea}
                                    className="annotations"
                                    disabled={view}
                                />
                                </div>
                                <div className='d-flex flex-row w-25 ml-3'>
                                    <button
                                        type="button"
                                        className='btn btn-danger'
                                        onClick={()=>{props.change('notes', 'DIFERIDO');}}
                                    >DIFERIDO</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    )
};

let CrearForm = reduxForm({
    // a unique name for the form
    form: 'Autorefractor',
    validate: (data) => {
        return validate(data, {
        });
    },
})(Form);

const selector = formValueSelector('Autorefractor');

CrearForm = connect(state => {
    const od_esf = selector(state, 'od_esf');
    const os_esf = selector(state, 'os_esf');
    const od_cil = selector(state, 'od_cil');
    const os_cil = selector(state, 'os_cil');

    return {
        od_esf,
        os_esf,
        od_cil,
        os_cil,
    }
})(CrearForm);

export default CrearForm;
