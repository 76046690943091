import React, { Component } from 'react';
import "./toolbar.css";
import SearchChange from "./SearchChange";

export default class ToolbarTab extends Component {
    render() {
        return (
            <div className="w-100 d-inline-flex justify-content-left" id={this.props.id}>
                <div className="text-left search">
                    {(this.props.buscar !== undefined) && (
                        <SearchChange
                            buscar={this.props.buscar}
                            buscador={this.props.buscador}
                            autofocus={this.props.autofocus}
                        />
                    )}
                </div>
            </div>
        )
    }
}
