const url = 'coupons';
import { api } from 'api';
import Swal from 'sweetalert2';
import { push } from 'react-router-redux';
import { reset, initialize as initializeForm } from 'redux-form';
import { handleActions } from 'redux-actions';
import moment from "moment";

const LOADER_COUPON = 'LOADER_COUPON';
const SET_DATA_COUPON = 'SET_DATA_COUPON';
const SET_PAGE_COUPON = 'SET_PAGE_COUPON';
const SET_UPDATE_DATA_COUPON = 'SET_UPDATE_DATA_COUPON';
const SET_BUSCADOR_COUPON = 'SET_BUSCADOR_COUPON';
const SET_FILTRO_COUPON = 'SET_FILTRO_COUPON';
const SET_UPDATE_DATA = 'SET_UPDATE_DATA';
const SET_COUPON_FILTER = 'SET_COUPON_FILTER';

const setUpdateData = () => (dispatch) => {
    dispatch({type: SET_UPDATE_DATA});
}

const listar = (page = 1) =>  (dispatch, getStore) => {
    dispatch({type: LOADER_COUPON, cargando: true});
    const store = getStore();
    const search = store.coupon.buscador;
    const filtro = store.coupon.filtro_roles;
    let params = {page, search};
    if(filtro){
        params[''] = filtro
    }

    api.get(`${url}`, params).catch((error) => {
        dispatch({type: LOADER_COUPON, cargando: false});
        Swal(
            'Error',
            error.detail || 'Ha ocurrido un error, por favor vuelva a intentar.',
            'error'
        );
    }).then((data) => {
        console.log("data coupons: ", data);
        if(data){
            dispatch({type: SET_DATA_COUPON, data});
            dispatch({type: SET_PAGE_COUPON, page});
        }
        dispatch({type: LOADER_COUPON, cargando: false});
    })
};

const detail = id => (dispatch, getState) =>{
    dispatch({type: LOADER_COUPON, cargando: true});
    api.get(`${url}/${id}`).catch((error) => {
        dispatch({type: LOADER_COUPON, cargando: false});
        if(error.statusCode === 404){
            dispatch(push('/coupons'))
        }
    }).then((data) => {
        dispatch({type: LOADER_COUPON, cargando: false});
        if(data){
            const type_coupon = { value: data.type_coupon, label: data.type_coupon_label };
            data.type_coupon = type_coupon;
            if(data.service !== null){
                const service = { value: data.service.id, label: data.service.name };
                data.service = service;
            }

            dispatch({type: SET_UPDATE_DATA_COUPON, updateData: data});
            dispatch(initializeForm('frmCoupon', data));
        }
    })
}

const create = () => (dispatch, getStore) => {
    const formData = getStore().form.frmCoupon.values;
    const type_coupon = formData.type_coupon.value;
    formData.type_coupon = type_coupon;

    if(formData.hasOwnProperty("service")){
        const service = formData.service.value;
        formData.service = service;
    }

    formData.copy_amount = formData.amount;
    formData.copy_percentage = formData.percentage;

    dispatch({type: LOADER_COUPON, cargando: true})

    api.post(`${url}`, formData).then((data) => {
        dispatch({type: LOADER_COUPON, cargando: false})

        Swal('Éxito', 'Cupones creados correctamente.', 'success')
        .then(() => {
            dispatch({type: SET_UPDATE_DATA_COUPON, updateData: data});
            dispatch(push('/coupons/0/view'));
        })
    }).catch((error) => {
        dispatch({type: LOADER_COUPON, cargando: false})
        Swal(
            'Error',
             error.detail || 'Ha ocurrido un error, por favor vuelva a intentar.',
            'error'
        );
    }).finally(() => {
        dispatch({type: LOADER_COUPON, cargando: false})
    });
};

const update = () => (dispatch, getStore) => {
    const formData = _.cloneDeep(getStore().form.frmCoupon.values);

    const type_coupon = formData.type_coupon.value;
    formData.type_coupon = type_coupon;

    if(formData.hasOwnProperty("service")){
        const service = formData.service.value;
        formData.service = service;
    }

    dispatch({type: LOADER_COUPON, cargando: true});
    api.put(`${url}/${formData.id}`, formData)
    .then((data) => {
        dispatch({type: LOADER_COUPON, cargando: false});
        if(data){
            Swal(
                'Éxito',
                'Datos editados correctamente.',
                'success'
            ).then(() => {
                dispatch(push('/coupons'));
            })
        }
    })
    .catch((error) =>{
        dispatch({type: LOADER_COUPON, cargando: false});
        Swal(
            'Error',
             error.detail || 'Ha ocurrido un error, verifique los datos.',
            'error'
        )
        if(error.statusCode === 404){
            dispatch(push('/roles/create'));
        }
    })
}

const destroy = (id) => (dispatch, getStore) => {
    dispatch({type: LOADER_COUPON, cargando: true});
    api.eliminar(`${url}/${id}`).catch((error) => {
        dispatch({type: LOADER_COUPON, cargando: false});
        Swal('Error', 'No se ha logrado borrar el registro.', 'error')
            .then(() => {
                dispatch(listar(1));
            })
    }).then((data) => {
        dispatch({type: LOADER_COUPON, cargando: false});
        Swal('Éxito', 'Se ha eliminado correctamente.', 'success')
            .then(() => {
                dispatch(listar(1));
            })
    })
}

const anularCupon = (coupon_id) => (dispatch, getStore) => {
    Swal.fire({
        title: '¿Está seguro(a) que desea anular el cupon?',
        type: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#aaa',
        confirmButtonText: 'Aceptar',
        cancelButtonText: 'Cancelar',
        reverseButtons: true
    }).then((result) => {
        if (result.value) {
            dispatch({type: LOADER_COUPON, cargando: true});
            api.post(`${url}/cancelCoupon`, { coupon_id }).then((data) => {
                dispatch({type: LOADER_COUPON, cargando: false});
                Swal('Éxito', 'El cupon se ha anulado correctamente.', 'success')
                    .then(() => {
                        dispatch(listar(1));
                    })
            }).catch((error) => {
                dispatch({type: LOADER_COUPON, cargando: false});
                Swal('Error', error.detail + ' No se ha logrado anular el cupon', 'error')
                    .then(() => {
                        dispatch(listar(1));
                    })
            })
        }
    });
}

const search = (search) => (dispatch) => {
    dispatch({type: SET_BUSCADOR_COUPON, buscador: search});
    dispatch(listar(1));
};

const filtro = (filtro) => (dispatch, getStore) => {
    if (filtro == 0){
        filtro = null
    }
    dispatch({type: SET_FILTRO_COUPON, filtro_coupon: filtro});
    dispatch(listar(1));
};

const createInitialize = () => (dispatch, getStore) => {
    const date = new Date();
    const ultimoDia = new Date(date.getFullYear(), date.getMonth() + 1, 0);
    const ultimo_dia = ultimoDia.getFullYear() + "-" + (ultimoDia.getMonth() + 1)  + "-" + ultimoDia.getDate()
    dispatch(initializeForm('frmCoupon',
        {'expiration': ultimo_dia }
    ))
}

const filtrarCupones = (search) => (dispatch) => {
    dispatch({type: SET_COUPON_FILTER, buscador: search});
    dispatch(listar(1));
};

export const actions = {
    listar,
    create,
    update,
    detail,
    destroy,
    search,
    filtro,
    setUpdateData,
    createInitialize,
    anularCupon,
    filtrarCupones,
};

export const reducer = {
    [SET_COUPON_FILTER]: (state, { buscador }) => {
        return {...state, buscador }
    },
    [LOADER_COUPON]: (state, { cargando }) => {
        return {...state, cargando }
    },
    [SET_DATA_COUPON]: (state, { data }) => {
        return {...state, data }
    },
    [SET_PAGE_COUPON]: (state, { page }) => {
        return {...state, page }
    },
    [SET_UPDATE_DATA_COUPON]: (state, { updateData }) => {
        return {...state, updateData }
    },
    [SET_BUSCADOR_COUPON]: (state, { buscador }) => {
        return {...state, buscador }
    },
    [SET_FILTRO_COUPON]: (state, { filtro_coupon }) => {
        return {...state, filtro_coupon }
    },
    [SET_UPDATE_DATA]: (state) => {
        return { ...state, updateData: {}}
    },
};

export const initialState = {
    cargando: false,
    page: 1,
    data: {
        count: 0,
        next: null,
        previous: null,
        results: [],
    },
    buscador: '',
    filtro_coupon: null,
    updateData: {},
    show_expired: false,
};

export default handleActions(reducer, initialState)
