import React, { Component } from 'react';
import { Modal, ModalBody, ModalHeader, ModalFooter } from 'reactstrap';

class MyModal extends Component{
    state={
        isOpen: false,
        showCloseIcon: false,
        disabled: this.props.disabled | false
    }

    toggleModal = () => {
        this.setState({ isOpen: !this.state.isOpen });
    }

    componentWillReceiveProps = (nextProps) => {
        this.setState(nextProps);
    }

    whenModalOpen = (input_focus_id) => {
        console.log("El modal está abierto");
        document.getElementById('firstFocus');
        (this.props.searchService && this.state.isOpen === true) && this.props.searchService('');
    }

    render(){

        const mystyle = {
            color: "transparent",
            backgroundColor: "transparent",
            padding: "0px",
            border: "none",
          };
        //const buttonContent = this.props.textButton ? this.props.textButton : this.props.imageButton;

        let image = false;
        let buttonContent = null;

        /*
        buttonContent = this.props.textButton ? this.props.textButton : null;
        buttonContent = this.props.imageButton ? this.props.imageButton : null;
        */

        if(this.props.image){
            buttonContent = this.props.image ? this.props.image : null;
            image = true;
        }else if(this.props.textButton){
            buttonContent = this.props.textButton;
        }else if(this.props.imageButton){
            buttonContent = this.props.imageButton;
        }

        const size = this.props.size ? this.props.size : '';

        return(
            <div>
                {
                    this.props.initialize ?
                        <button type="button" className={this.props.classToggleButton} disabled={this.state.disabled} onClick={() => {this.toggleModal(); this.props.initialize() }}>{buttonContent}</button>
                    :
                        !image ?
                            <button type="button" className={this.props.classToggleButton} disabled={this.state.disabled} onClick={this.toggleModal}>{buttonContent}</button>
                            :
                            <button style={mystyle} disabled={this.state.disabled} onClick={this.toggleModal}>{buttonContent}</button>
                }
                <Modal isOpen={this.state.isOpen} size={size} onOpened={() => this.whenModalOpen(this.props.focusId)}>
                    <ModalHeader toggle={this.state.showCloseIcon ? this.toggleModal : null}>{this.props.title}</ModalHeader>
                    <ModalBody>
                        {this.props.body}
                    </ModalBody>
                    <ModalFooter className="text-center">
                        <button className="btn btn-secondary btn-modal" onClick={this.toggleModal}>Cancelar</button>
                        {this.props.aceptar ?
                            <button type="button" className="btn btn-primary btn-modal" onClick={() => this.props.aceptar()}>Aceptar</button>
                            :
                            <button type="submit" className="btn btn-primary btn-modal" form={this.props.formId}>Aceptar</button>
                        }
                    </ModalFooter>
                </Modal>
            </div>
        );
    }
}

export default MyModal;
