const url = 'tasks';
import { api } from 'api';
import Swal from 'sweetalert2';
import { push } from 'react-router-redux';
import { goBack } from 'react-router-redux';
import { initialize as initializeForm } from 'redux-form';
import { createActions, handleActions } from 'redux-actions';


const LOADER_TASK = 'LOADER_USER';
const SET_DATA_TASK = 'SET_DATA_USER';
const SET_PAGE_TASK = 'SET_PAGE_USER';

const listar = (page = 1) =>  (dispatch, getStore) => {
    dispatch({type: LOADER_TASK, cargando: true});
    const store = getStore();
    const search = store.tasks.buscador;
    const filtro = store.tasks.filtro_user;
    let params = {page, search};
    
    if(filtro){
        params[''] = filtro
    }

    api.get(`${url}/listar`, params).catch((error) => {
        dispatch({type: LOADER_TASK, cargando: false});
        Swal(
            'Error',
            error.detail || 'Ha ocurrido un error, por favor vuelva a intentar.',
            'error'
        );
    }).then((data) => {
        console.log("data response ::: ", data);
        if(data){          
          dispatch({type: SET_DATA_TASK, data});
          dispatch({type: SET_PAGE_TASK, page});
        }
        dispatch({type: LOADER_TASK, cargando: false});
    })
};

export const descargarArchivo = (taskId) => (dispatch, getStore) => {
    //dispatch(setLoaderExcel(true));
 
    let endpoint = `/api/${url}/descargarArchivo/?taskId=${taskId}`;
   
    fetch(endpoint, {
        method: "GET",
        headers: {
            Authorization: `Token ${localStorage.getItem("token")}`,
        },
    })
        .then((response) => {
            if (response.status === 200) {
                response.blob().then((blob) => {
                    let url = window.URL.createObjectURL(blob);
                    let a = document.createElement("a");
                    a.href = url;
                    a.download = "Consolidado_de_gastos.xlsx";
                    a.click();
                });
            }
        })
        .catch(() => {
            NotificationManager.error(
                "Ocurrió un error al exportar el reporte",
                "ERROR",
                0
            );
        })
        .finally(() => {
            //dispatch(setLoaderExcel(false));
        });
};

const destroy = (taskId) => (dispatch, getStore) => {   
    api.post(`${url}/eliminarTarea`, {taskId}).catch((error) => {        
        Swal(
            'Error',
            error.detail || 'Ha ocurrido un error, por favor vuelva a intentar.',
            'error'
        );
    }).then((data) => {
        Swal(
            'success',
            "La tarea se ha eliminado correctamente.",
            'success'
        );
        dispatch(listar(1));
    }).finally(()=>{
        dispatch({type: LOADER_TASK, cargando: false});
    });
}

export const actions = {
  listar,
  descargarArchivo,
  destroy
};

export const reducer = {
  [LOADER_TASK]: (state, { cargando }) => {
      return {...state, cargando }
  },
  [SET_DATA_TASK]: (state, { data }) => {
      return {...state, data }
  },
  [SET_PAGE_TASK]: (state, { page }) => {
      return {...state, page }
  }
}

export const initialState = {
  cargando: false,
  page: 1,
  data: {
      count: 0,
      next: null,
      previous: null,
      results: [],
  },
  buscador: '',
  filtro_user: null,  
  tasks: [],
};

export default handleActions(reducer, initialState);