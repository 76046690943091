import React, { Component } from 'react';
import "./toolbar.css";
import SearchSelect from "./Select";
import SearchChange from "./SearchChange";

export default class ToolbarSimple extends Component {
    render() {
        const ancho = this.props.ancho ? this.props.ancho : "w-25";

        return (
            <div className="w-100 d-flex justify-content-between">
                <div className="col-md-6 row">
                    <div className="titulo d-flex align-items-center">
                        <span className="ml-2 text-uppercase text-titulo"><strong>{this.props.titulo}</strong></span>
                    </div>
                </div>

                <div className="d-flex flex-row align-items-center justify-content-end w-50">
                    <span className="mr-2">filtrar por:</span>
                    <div className={`d-flex flex-row ${ancho} float-right justify-content-end"`}>
                        {(this.props.buscar !== undefined) && (
                            <React.Fragment>
                                    <SearchSelect 
                                        { ...this.props} 
                                        buscar={this.props.buscar} 
                                        buscador={this.props.buscador}
                                        autofocus = {this.props.autofocus}
                                    />
                            </React.Fragment>
                        )}
                    </div>
                </div>
            </div>
        )
    }
}
