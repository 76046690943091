import React from 'react';
import { api } from "api";
import moment from "moment";
import { Field, reduxForm } from 'redux-form';
import { validate, validators } from 'validate-redux-form';
import { renderField, renderDatePicker, modalTextArea, renderAntTimeField, renderCurrency, renderSearchSelect } from 'Utils/renderField/renderField';



const getBankAccounts = (search)  =>{
    return api.get("bank_accounts",{search})
        .then((data) => {
            if (data){
                const bank_accounts = data.results.map(x => (
                    {value: x.id, label: x.owner, number: x.account_number}
                ));
                console.log(bank_accounts)

                return bank_accounts
            }
            else{
                return []
            }
        })
        .catch(e => {
            return []
        })
}

const Form = props => {

   const {
       handleSubmit
    } = props;

    const format = 'HH:mm';

    return(
        <form id="pending_transaction_form" onSubmit={handleSubmit} className="p-0">

            <div className="px-md-5 mb-2 d-flex justify-content-between flex-column flex-sm-row align-items-stretch align-items-sm-center">
                <div className="">
                    <div className="d-flex justify-content-start flex-column flex-sm-row align-items-stretch align-items-sm-center">
                        <label htmlFor="bank_account" className="">Nombre de la cuenta</label>
                    </div>
                    <div className=" d-flex justify-content-start flex-column flex-sm-row align-items-stretch align-items-sm-center">
                        <Field
                            name="bank_account"
                            valueKey="value"
                            labelKey="label"
                            label="referent"
                            component={renderSearchSelect}
                            loadOptions={getBankAccounts}
                            className="no-border"
                        />
                    </div>
                </div>
                <div className="m-0">
                    <div className=" d-flex justify-content-start flex-column flex-sm-row align-items-stretch align-items-sm-center">
                        <label htmlFor="account_number" className="">Número de cuenta</label>
                    </div>
                    <div className=" d-flex justify-content-end flex-column flex-sm-row align-items-stretch align-items-sm-center">
                        <Field
                            name="bank_account"
                            valueKey="value"
                            labelKey="number"
                            label="referent"
                            component={renderSearchSelect}
                            loadOptions={getBankAccounts}
                            className="no-border"
                        />
                    </div>
                </div>
            </div>

            <div className="px-md-5 my-2 d-flex justify-content-between flex-column flex-sm-row align-items-stretch align-items-sm-center">
                <div className="m-0">
                    <div className=" d-flex justify-content-start flex-column flex-sm-row align-items-stretch align-items-sm-center">
                        <label htmlFor="account_number" className="">No. de documento</label>
                    </div>
                    <div className=" d-flex justify-content-end flex-column flex-sm-row align-items-stretch align-items-sm-center">
                    <Field name="no_document" component={renderField} type="text" className="form-control"/>
                    </div>
                </div>
                <div className="m-0">
                    <div className="d-flex justify-content-start flex-column flex-sm-row align-items-stretch align-items-sm-center">
                        <label htmlFor="amount" className="">Monto</label>
                    </div>
                    <div className="d-flex justify-content-start flex-column flex-sm-row align-items-stretch align-items-sm-center">
                        <Field name="amount" component={renderCurrency} type="text" className="form-control"/>
                    </div>
                </div>
            </div>

            <div className="px-md-5 d-flex justify-content-between flex-column flex-sm-row align-items-stretch align-items-sm-center">
                <div className="m-0">
                    <div className=" d-flex justify-content-start flex-column flex-sm-row align-items-stretch align-items-sm-center">
                        <label htmlFor="date" className="">Fecha</label>
                    </div>
                    <div className=" d-flex justify-content-end flex-column flex-sm-row align-items-stretch align-items-sm-center">
                    <Field name="date" component={renderDatePicker} type="text" className="form-control" />
                    </div>
                </div>
                <div className="m-0">
                    <div className="d-flex justify-content-start flex-column flex-sm-row align-items-stretch align-items-sm-center">
                        <label htmlFor="hour" className="">Hora</label>
                    </div>
                    <div className="d-flex justify-content-start flex-column flex-sm-row align-items-stretch align-items-sm-center">
                        <Field name="hour" component={renderAntTimeField} type="text" className="form-control p-0"/>
                    </div>
                </div>
            </div>

            <div className="px-md-5 d-flex justify-content-center flex-column flex-sm-row align-items-stretch align-items-sm-center pt-3">
                <div className="m-0 justification-message-input-full-width">
                    <div className=" d-flex justify-content-start flex-column flex-sm-row align-items-stretch align-items-sm-center">
                        <label htmlFor="trasanction_jutification" className="modal-label-width">Motivo</label>
                    </div>
                    <div className=" d-flex justify-content-end flex-column flex-sm-row align-items-stretch align-items-sm-center">
                        <Field name="transanction_justification" component={modalTextArea} type="text" className="form-control" />
                    </div>
                </div>
            </div>

        </form>
    )
};

const PendingTransactionForm = reduxForm({
   form: 'pendingTransaction',
   validate: data => {
       return validate(data, {
           'amount': validators.exists()('Campo requerido.'),
           'no_document': validators.exists()('Campo requerido.'),
           'date': validators.exists()('Campo requerido.'),
           'hour': validators.exists()('Campo requerido.'),
           'transanction_justification': validators.exists()('Campo requerido.'),
           'bank_account': validators.exists()('Campo requerido.'),
       })
   }
})(Form);

export default PendingTransactionForm
