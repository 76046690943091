import React, { Component } from 'react';
import Select from 'react-select';

import './toolbar.css';

class SearchSelect extends Component {

    constructor(props){
        super(props);
    }

    render() {
        const {
            buscar,
            buscador,
            valueKey,
            labelKey,
            options,
            autofocus,
        } = this.props;

        return (
            <React.Fragment>
                <div className={"w-100 float-right"}
                style={{minWidth: 150}}
                >
                {
                    (!!buscador !== undefined && buscar !== undefined) && (

                        <Select
                            className={""}
                            onChange={(e) => {
                                this.props.buscar(e.value);
                            }}
                            classNamePrefix="react-select"
                            defaultOptions
                            theme={(theme) => {
                                return {
                                    ...theme,
                                    borderRadius: '1em',
                                    background: 'white',
                                    colors: {
                                        ...theme.colors,
                                        text: '#415362',
                                        primary25: '#ebebeb',
                                    primary: '#0a5995',
                                    },
                                }
                            }}
                            autoFocus = {autofocus ? true: false}
                            searchPromptText="Escriba para buscar" valueKey={valueKey} labelKey={labelKey}
                            options={options}
                        />
                )}
                </div>
            </React.Fragment>
        );
    }
}

export default SearchSelect;
