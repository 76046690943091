import './login.css';
import PropTypes from 'prop-types';
import LoginForm from './LoginForm';
import { Redirect } from 'react-router-dom';
import React, { PureComponent } from 'react';

class Login extends PureComponent {
    
    static propTypes = {
        nameError: PropTypes.bool.isRequired,
        passError: PropTypes.bool.isRequired,
        onSubmit: PropTypes.func.isRequired,
        hasNameError: PropTypes.func.isRequired,
        hasPassError: PropTypes.func.isRequired,
    };

    componentDidMount(props) {
        this.state = { prueba: true };
    }

    render() {

        const {
            onSubmit
        
        } = this.props;
        
        if (localStorage.getItem('token')) {
            return (<Redirect to="/home" />);
        }

        return (
            <div className="row background">
                <div className="logo col-lg-8 col-md-12 d-none d-sm-noned-md-flex d-lg-flex justify-content-center">
                    <img src={require("../../../../assets/img/logo_prolaser.png")} alt="Prolaser_logo"/>
                </div>
                <div className="logo-sm col-12 d-flex d-sm-flex d-md-none justify-content-center">
                    <img src={require("../../../../assets/img/logo_prolaser.png")} alt="Prolaser_logo"/>
                </div>
                <div className="login-form col-md-12 col-lg-3 d-flex justify-content-center">
                    <LoginForm onSubmit={onSubmit} initialValues={{input_type:'password'}} />
                </div>
            </div>
        )
    }
}

export default Login;
