const url = 'referents';
import { api } from "api";
import moment from "moment";
import Swal from 'sweetalert2';
import { push } from 'react-router-redux';
import { goBack } from 'react-router-redux';
import { initialize as initializeForm } from 'redux-form';
import { createActions, handleActions } from 'redux-actions';

const LOADER_REFERENTS = 'LOADER_REFERENTS';
const SET_DATA_REFERENTS = 'SET_DATA_REFERENTS';
const SET_PAGE_REFERENTS = 'SET_PAGE_REFERENTS';
const SET_UPDATE_DATA_REFERENTS ='SET_UPDATE_DATA_REFERENTS';
const SET_BUSCADOR_REFERENTS = 'SET_BUSCADOR_REFERENTS';
const SET_FILTRO_REFERENTS = 'SET_FILTRO_REFERENTS';

const MONETARY_ACCOUNT = 1;
const SAVING_ACCOUNT = 2;

const MEDICAL_REFERENT = 9;
const SOCIAL_REFERENT = 10;


const REFERENT_MEDIC = 1;
const SOCIAL_WORKER = 2;
const PROLASER_MEDIC = 3;


/*
const referents_types = {
    medical: {value:MEDICAL_REFERENT, label:'Médico Referente'},
    social: {value:SOCIAL_REFERENT, label:'Trabajador Social'}
};
*/

const referents_types = {
    medical: {value:REFERENT_MEDIC, label:'Médico Referente'},
    social: {value:SOCIAL_WORKER, label:'Trabajador Social'},
    prolaser: {value:PROLASER_MEDIC, label:'Médico Prolaser'}
};



const accounts_types = {
    monetary: {value:MONETARY_ACCOUNT, label:'Cuenta Monetaria'},
    saving: {value:SAVING_ACCOUNT, label:'Cuenta De Ahorro'}
};

const listar = (page = 1) =>  (dispatch, getStore) => {
    dispatch({type: LOADER_REFERENTS, cargando: true});
    const store = getStore();
    const search = store.referents.buscador;
    const filtro = store.referents.filtro_referent;
    let params = {page, search};

    if(filtro){
        params[''] = filtro
    }
    api.get(`${url}`, params).catch((error) => {
        dispatch({type: LOADER_REFERENTS, cargando: false});
        Swal(
            'Error',
            error.detail || 'Ha ocurrido un error, por favor vuelva a intentar.',
            'error'
        );
    }).then((data) => {

        if(data){
            dispatch({type: SET_DATA_REFERENTS, data});
            dispatch({type: SET_PAGE_REFERENTS, page});
        }
        dispatch({type: LOADER_REFERENTS, cargando: false});
    })
};

const detail = id => (dispatch, getState) =>{
    dispatch({type: LOADER_REFERENTS, cargando: true});
    api.get(`${url}/${id}`).catch((error) => {
        dispatch({type: LOADER_REFERENTS, cargando: false});
        if(error.statusCode  === 404){
            dispatch(push('/referents'))
        }
    }).then((data) => {
        dispatch({type: LOADER_REFERENTS, cargando: false});


        if(data){
            if(data.institution !== null){
                data.institution.value = data.institution.id;
                data.institution.label = data.institution.name;
            }

            if(data.referent_type == REFERENT_MEDIC){
                data.referent_type = referents_types.medical
            }else if(data.referent_type == SOCIAL_WORKER){
                data.referent_type = referents_types.social
            }else if(data.referent_type == PROLASER_MEDIC){
                data.referent_type = referents_types.prolaser
            }

            if(data.account_type == MONETARY_ACCOUNT){
                data.account_type = accounts_types.monetary;
            }
            else if (data.account_type == SAVING_ACCOUNT) {
                data.account_type = accounts_types.saving;
            }

            dispatch({type: SET_UPDATE_DATA_REFERENTS, updateData: data});
            dispatch(initializeForm('referents', data))
        }
    })
}

const createInitialize = () => (dispatch) => {
    dispatch(initializeForm('referents', {'birthdate': moment(new Date()).format("YYYY-MM-DD")}))
}

const create = () => (dispatch, getStore) => {
    const formData = getStore().form.referents.values;

    if(formData.institution !== undefined  && formData.institution !== null){
        console.log("INSTITUCIÓN: ", formData.institution);
        formData.institution = formData.institution.value;
    }
    
    formData.account_type = formData.account_type.value;
    //formData.role = formData.role.value;

    if(formData.referent_type !== undefined && formData.referent_type !== null){
        formData.referent_type = formData.referent_type.value;
    }

    dispatch({type: LOADER_REFERENTS, cargando: true})
    api.post(`${url}`, formData).then((data) => {
        dispatch({type: LOADER_REFERENTS, cargando: false})
        Swal('Éxito', 'Se ha creado el referente.', 'success')
        .then(() => {
            dispatch(push('/referents'))
        })
    }).catch((error) => {
        dispatch({type: LOADER_REFERENTS, cargando: false})
        Swal(
            'Error',
             error.detail || 'Ha ocurrido un error, por favor vuelva a intentar.',
            'error'
        );
    }).finally(() => {
        dispatch({type: LOADER_REFERENTS, cargando: false})
    });
};

const update = () => (dispatch, getStore) => {
    const formData = _.cloneDeep(getStore().form.referents.values);
    dispatch({type: LOADER_REFERENTS, cargando: true});

    if(formData.institution !== undefined && formData.institution !== null){
        console.log("INSTITUCIÓN: ", formData.institution);

        formData.institution = formData.institution.value;
    }
    //formData.role = formData.role.value;

    formData.referent_type = formData.referent_type.value
    //formData.referent_type = formData.referent_type.value;

    formData.account_type = formData.account_type.value;
    api.put(`${url}/${formData.id}`, formData)
    .then((data) => {
        dispatch({type: LOADER_REFERENTS, cargando: false});
        if(data){
            Swal(
                'Éxito',
                'Datos editados correctamente.',
                'success'
            ).then(() => {
                dispatch(push('/referents'));
            })
        }
    })
    .catch((error) =>{
        dispatch({type: LOADER_REFERENTS, cargando: false});
        Swal(
            'Error',
             error.detail || 'Ha ocurrido un error, verifique los datos.',
            'error'
        )
        if(error.statusCode === 404){
            dispatch(push('/referents'));
        }
    })
}

const destroy = (id) => (dispatch, getStore) => {
    dispatch({type: LOADER_REFERENTS, cargando: true});
    api.eliminar(`${url}/${id}`).catch((error) => {
        dispatch({type: LOADER_REFERENTS, cargando: false});
        Swal('Error', 'No se ha logrado borrar el registro.', 'error')
            .then(() => {
                dispatch(listar(1));
        })
    }).then((data) => {
        dispatch({type: LOADER_REFERENTS, cargando: false});
        Swal('Éxito', 'Se ha eliminado correctamente.', 'success')
            .then(() => {
                dispatch(listar(1));
            })
    })
}

const search = (search) => (dispatch, getStore) => {
    dispatch({type: SET_BUSCADOR_REFERENTS, buscador: search});
    dispatch(listar(1));
};
const filtro = (filtro) => (dispatch, getStore) => {
    if (filtro == 0){
        filtro = null
    }
    dispatch({type: SET_FILTRO_REFERENTS, filtro_referents: filtro});
    dispatch(listar(1));
};
export const actions = {
    listar,
    detail,
    create,
    update,
    destroy,
    search,
    filtro,
    createInitialize
};
export const reducer = {
    [LOADER_REFERENTS]: (state, { cargando }) => {
        return {...state, cargando }
    },
    [SET_DATA_REFERENTS]: (state, { data }) => {
        return {...state, data }
    },
    [SET_PAGE_REFERENTS]: (state, { page }) => {
        return {...state, page }
    },
    [SET_UPDATE_DATA_REFERENTS]: (state, { updateData }) => {
        return {...state, updateData }
    },
    [SET_BUSCADOR_REFERENTS]: (state, { buscador }) => {
        return {...state, buscador }
    },
    [SET_FILTRO_REFERENTS]: (state, { filtro_referents }) => {
        return {...state, filtro_referents }
    },
}
export const initialState = {
    cargando: false,
    page: 1,
    data: {
        count: 0,
        next: null,
        previous: null,
        results: [],
    },
    buscador: '',
    filtro_referents: null,
    updateData: {},
};
export default handleActions(reducer, initialState)
