const url = 'interpreter';
import { api } from 'api';
import Swal from 'sweetalert2';
import { push } from 'react-router-redux';
import { initialize as initializeForm } from 'redux-form';
import { handleActions } from 'redux-actions';


const LOADER_INTERPRETER = 'LOADER_INTERPRETER';
const SET_DATA_INTERPRETER = 'SET_DATA_INTERPRETER';
const SET_PAGE_INTERPRETER = 'SET_PAGE_INTERPRETER';
const SET_UPDATE_DATA_INTERPRETER ='SET_UPDATE_DATA_INTERPRETER';
const SET_BUSCADOR_INTERPRETER = 'SET_BUSCADOR_INTERPRETER';
const SET_FILTRO_INTERPRETER = 'SET_FILTRO_INTERPRETER';
const SUPERUSER = "superuser"
const SUPERUSUARIO = "superusuario"
const SET_DOCTORS_INTERPRETER = 'SET_DOCTORS_INTERPRETER';

const listar = (page = 1) =>  (dispatch, getStore) => {
    dispatch({type: LOADER_INTERPRETER, cargando: true});
    const store = getStore();
    const search = store.users.buscador;
    const filtro = store.users.filtro_user;
    let params = {page, search};
    if(filtro){
        params[''] = filtro
    }
    api.get(`${url}`, params).catch((error) => {
        dispatch({type: LOADER_INTERPRETER, cargando: false});
        Swal(
            'Error',
            error.detail || 'Ha ocurrido un error, por favor vuelva a intentar.',
            'error'
        );
    }).then((data) => {
        if(data){
            dispatch({type: SET_DATA_INTERPRETER, data});
            dispatch({type: SET_PAGE_INTERPRETER, page});
        }
        dispatch({type: LOADER_INTERPRETER, cargando: false});
    })
};

const detail = id => (dispatch, getState) =>{
    dispatch({type: LOADER_INTERPRETER, cargando: true});
    api.get(`${url}/${id}`).catch((error) => {
        dispatch({type: LOADER_INTERPRETER, cargando: false});
        if(error.statusCode === 404){
            dispatch(push('/users'))
        }
    }).then((data) => {
        dispatch({type: LOADER_INTERPRETER, cargando: false});
        if(data){

            if(data.hasOwnProperty("person_user") && data["person_user"] != null)
            {
                const role = {value: data.person_user.role.id, label: data.person_user.role.name}
                data.role = role;

                //if(data.person_user.role.id != SUPERUSER)
                if(data.role.label.toLowerCase() !== SUPERUSER && data.role.label.toLowerCase() !== SUPERUSUARIO)
                {
                    const element = {value: data.person_user.subsidiary.id, label: data.person_user.subsidiary.name};
                    data.subsidiary = element;
                }

                //agregado
                const person = {value: data.person_user.id, label: data.person_user.name};
                data.person = person;
                data.doctors = data.person_user.doctors;
            }

            console.log("Detalle de intérprete: ", data);

            dispatch({type: SET_UPDATE_DATA_INTERPRETER, updateData: data});
            dispatch(initializeForm('interpreter', data))
        }
    })
}

const create = () => (dispatch, getStore) => {
    const formData = getStore().form.interpreter.values;
    const doctors = _.cloneDeep(formData.doctors);
    
    dispatch({type: LOADER_INTERPRETER, cargando: true})

    //let objPerson_user = {role: formData.role.value}
    let objPerson_user = {role: formData.role}
/*
    if(formData.role.value > SUPERUSER){
        objPerson_user["subsidiary"] = formData.subsidiary.value;
        objPerson_user.user = formData.person.value;
    }
    if(formData.role.value == REFERENT){
        objPerson_user["subsidiary"] = formData.subsidiary.value;
        objPerson_user.user = formData.person.value;
    }
*/
    if(formData.role.label.toLowerCase() !== SUPERUSER && formData.role.label.toLowerCase() !== SUPERUSUARIO){
        objPerson_user["subsidiary"] = formData.subsidiary.value;
        objPerson_user.user = formData.person.value;
    }

    objPerson_user.doctors = doctors;
    formData.person_user = objPerson_user;

    api.post(`${url}`, formData).then((data) => {
        dispatch({type: LOADER_INTERPRETER, cargando: false})
        Swal('Éxito', 'Se ha creado el intérprete.', 'success')
        .then(() => {
            dispatch(push('/interpreter'))
        })
    }).catch((error) => {
        dispatch({type: LOADER_INTERPRETER, cargando: false})
        Swal(
            'Error',
             error.detail || 'Ha ocurrido un error, por favor vuelva a intentar.',
            'error'
        );
    }).finally(() => {
        dispatch({type: LOADER_INTERPRETER, cargando: false})
    });
};

const update = () => (dispatch, getStore) => {
    const formData = _.cloneDeep(getStore().form.interpreter.values);
    const doctors = _.cloneDeep(formData.doctors);

    dispatch({type: LOADER_INTERPRETER, cargando: true});

    let objPerson_user = {role: formData.role};

    objPerson_user["subsidiary"] = (formData.role.label.toLowerCase() === SUPERUSER || formData.role.label.toLowerCase() === SUPERUSUARIO) ? null : formData.subsidiary.value;

    //if(formData.role.value > SUPERUSER){
    if(formData.role.label.toLowerCase() !== SUPERUSER || formData.role.label.toLowerCase() !== SUPERUSUARIO){
        /* Si la persona relacionada con el usuario es referente, pero el referente dato en la actualización está en blanco,
        entonces se está desasignando la persona del referente */
        if(formData.person == null && formData.hasOwnProperty('person_user') && formData.person_user.referent){
            const delete_referent = {referentId: formData.person_user.id}
            formData.delete_referent = delete_referent;
        }else{
            objPerson_user.user = parseInt(formData.person.value);
        }
    }
    objPerson_user.doctors = doctors;    
    formData.person_user = objPerson_user;
    
    api.put(`${url}/${formData.id}`, formData)
    .then((data) => {
        dispatch({type: LOADER_INTERPRETER, cargando: false});
        if(data){
            Swal(
                'Éxito',
                'Datos editados correctamente.',
                'success'
            ).then(() => {
                dispatch(push('/interpreter'));
            })
        }
    })
    .catch((error) =>{
        dispatch({type: LOADER_INTERPRETER, cargando: false});
        Swal(
            'Error',
             error.detail || 'Ha ocurrido un error, verifique los datos.',
            'error'
        )
        if(error.statusCode === 404){
            dispatch(push('/interpreter/create'));
        }
    })
}

const destroy = (id) => (dispatch, getStore) => {
    dispatch({type: LOADER_INTERPRETER, cargando: true});
    api.eliminar(`${url}/${id}`).catch((error) => {
        dispatch({type: LOADER_INTERPRETER, cargando: false});
        Swal('Error', 'No se ha logrado borrar el registro.', 'error')
            .then(() => {
                dispatch(listar(1));
            })
    }).then((data) => {
        dispatch({type: LOADER_INTERPRETER, cargando: false});
        Swal('Éxito', 'Se ha eliminado correctamente.', 'success')
            .then(() => {
                dispatch(listar(1));
            })
    })
}

const search = (search) => (dispatch, getStore) => {
    dispatch({type: SET_BUSCADOR_INTERPRETER, buscador: search});
    dispatch(listar(1));
};

const filtro = (filtro) => (dispatch, getStore) => {
    if (filtro == 0){
        filtro = null
    }
    dispatch({type: SET_FILTRO_INTERPRETER, filtro_user: filtro});
    dispatch(listar(1));
};

const getDoctors = () => (dispatch, getStore) => {
    api.get('referents/listDoctors').then((result) => {
        dispatch({type: SET_DOCTORS_INTERPRETER, doctors: result.results});
    }).catch(() => {
    }).finally(() => {
    });
};

export const actions = {
    listar,
    detail,
    create,
    update,
    destroy,
    search,
    filtro,
    getDoctors,
};
export const reducer = {
    [LOADER_INTERPRETER]: (state, { cargando }) => {
        return {...state, cargando }
    },
    [SET_DATA_INTERPRETER]: (state, { data }) => {
        return {...state, data }
    },
    [SET_PAGE_INTERPRETER]: (state, { page }) => {
        return {...state, page }
    },
    [SET_UPDATE_DATA_INTERPRETER]: (state, { updateData }) => {
        return {...state, updateData }
    },
    [SET_BUSCADOR_INTERPRETER]: (state, { buscador }) => {
        return {...state, buscador }
    },
    [SET_FILTRO_INTERPRETER]: (state, { filtro_user }) => {
        return {...state, filtro_user }
    },
    [SET_DOCTORS_INTERPRETER]: (state, { doctors }) => {
        return {...state, doctors }
    },    
}
export const initialState = {
    cargando: false,
    page: 1,
    data: {
        count: 0,
        next: null,
        previous: null,
        results: [],
    },
    buscador: '',
    filtro_user: null,
    updateData: {},
    doctors: [],
};
export default handleActions(reducer, initialState)
