import React, { Component } from 'react';
import LoadMask from 'Utils/LoadMask/LoadMask';
import classNames from 'classnames';

import ServiceForm from './ServicesForm';
import Tabs from './tabsComponent';
import './services.css';

const PACKAGE_TYPE = 3;

const PACKAGE = 'package';
const SERVICE = 'service/product';

export default class ServicesCrear extends Component {
    state = {
        editar: false,
        mensaje: 'Agregar servicios',
        type: SERVICE,
    }

    /*
    componentWillReceiveProps(nextProps) {
        if (nextProps.updateData.service_type == PACKAGE_TYPE && this.state.type !== PACKAGE) {
            this.setState({ type: PACKAGE });
        } else {
            this.setState({ type: SERVICE });
        }
    }
    */

   componentWillReceiveProps(nextProps) {  
    const edit = window.location.href.includes('edit')
    const view = window.location.href.includes('view');

    if(edit || view){
        if (nextProps.updateData.service_type == PACKAGE_TYPE && this.props.type !== PACKAGE) {
            this.props.setServiceType(PACKAGE);
        } else {        
            this.props.setServiceType(SERVICE);
        }
    }
}

    componentWillMount() {
        this.props.createCategories();
        if (this.props.match.params.id) {
            this.props.detail(this.props.match.params.id);
            this.setState({
                editar: true,
                mensaje: 'Editar servicios',
            });
        }
    }

    radioOnClick = (value) => {        
        const { loadCategories } = this.props;
        loadCategories();
        //this.setState({ type: value });
        this.props.setServiceType(value);
    }

    render() {
        const {
            create,
            update,
            editar,
            cargando,
            updateData,
            isNavBarOpen,
        } = this.props;

        const {type} = this.props;

        const view = window.location.href.includes('view');
        const edit = window.location.href.includes('edit');

        return (
            <div className={classNames('content', { 'content-min': isNavBarOpen })}>
                <div className="row-fluid w-100 py-5 mb-1">
                    <div className="table-decoration">
                        <LoadMask loading={cargando} blur_1>
                            {edit
                                ? (
                                    <div className="fields">
                                        <div className="top-title">
                                            SERVICIO
                                        </div>

                                        <div className="d-flex flex-row justify-content-center w-100 mt-3 mb-3">
                                            <div className="d-flex w-25 justify-content-end mr-3">
                                                <div className="d-flex w-25justify-content-end">
                                                    {
                                                        type == SERVICE
                                                            ? <img src={require('../../../../assets/img/icons/service-icon-green.png')} className="image-tab" alt="Icono_de_servicio" />
                                                            : <img src={require('../../../../assets/img/icons/service-icon.png')} className="image-tab" alt="Icono_de_servicio" />
                                                    }
                                                </div>
                                                <div className="d-flex w-75 justify-content-start">
                                                    <label className="d-flex d-flex align-items-center">
                                                        <input
                                                            type="radio"
                                                            onChange={(e) => {
                                                                this.radioOnClick(e.target.value);
                                                            }}
                                                            id="service"
                                                            name="optradio"
                                                            value={SERVICE}
                                                            checked={type === 'service/product'}
                                                            className="ml-4 mr-3"
                                                            disabled={edit || view}
                                                        />
                                                    Servicio / Producto
                                                    </label>
                                                </div>
                                            </div>
                                            <div className="d-flex w-25  justify-content-start">
                                                <div className="d-flex w-25 justify-content-end">
                                                    {
                                                        type == SERVICE
                                                            ? <img src={require('../../../../assets/img/icons/package-icon.png')} className="image-tab" alt="Icono_de_paquete" />
                                                            : <img src={require('../../../../assets/img/icons/package-icon-green.png')} className="image-tab" alt="Icono_de_paquete" />
                                                    }
                                                </div>
                                                <div className="d-flex w-75 justify-content-start">
                                                    <label className="d-flex d-flex align-items-center">
                                                        <input
                                                            type="radio"
                                                            onChange={(e) => {
                                                                this.radioOnClick(e.target.value);
                                                            }}
                                                            checked={type === 'package'}
                                                            id="package"
                                                            name="optradio"
                                                            value={PACKAGE}
                                                            className="ml-4 mr-3"
                                                            disabled={edit || view}
                                                        />
                                                    Paquete
                                                    </label>
                                                </div>
                                            </div>
                                        </div>

                                        <hr className="division-line" />   

                                        <Tabs onSubmit={update} updateData={this.props.updateData} type={type} />

                                    </div>
                                )
                                : (
                                    <div className="fields">
                                        <div className="top-title">
                                        CREAR SERVICIO
                                        </div>

                                        <div className="d-flex flex-row justify-content-center w-100 mt-3 mb-3">
                                            <div className="d-flex w-25 justify-content-end mr-3">
                                                <div className="d-flex w-25justify-content-end">
                                                    {
                                                        type == SERVICE || type == undefined
                                                            ? <img src={require('../../../../assets/img/icons/service-icon-green.png')} className="image-tab" alt="Icono_de_servicio" />
                                                            : <img src={require('../../../../assets/img/icons/service-icon.png')} className="image-tab" alt="Icono_de_servicio" />
                                                    }
                                                </div>
                                                <div className="d-flex w-75 justify-content-start">
                                                    <label className="d-flex d-flex align-items-center">
                                                        <input
                                                            type="radio"
                                                            onChange={(e) => {
                                                                this.radioOnClick(e.target.value);
                                                            }}
                                                            id="service"
                                                            name="optradio"
                                                            value={SERVICE}
                                                            checked={type === 'service/product'|| type===undefined}
                                                            className="ml-4 mr-3"
                                                        />
                                                    Servicio / Producto
                                                    </label>
                                                </div>
                                            </div>
                                            <div className="d-flex w-25  justify-content-start">
                                                <div className="d-flex w-25 justify-content-end">
                                                    {
                                                        type == SERVICE
                                                            ? <img src={require('../../../../assets/img/icons/package-icon.png')} className="image-tab" alt="Icono_de_paquete" />
                                                            : <img src={require('../../../../assets/img/icons/package-icon-green.png')} className="image-tab" alt="Icono_de_paquete" />
                                                    }
                                                </div>
                                                <div className="d-flex w-75 justify-content-start">
                                                    <label className="d-flex d-flex align-items-center">
                                                        <input
                                                            type="radio"
                                                            onChange={(e) => {
                                                                this.radioOnClick(e.target.value);
                                                            }}
                                                            checked={type === 'package'}
                                                            id="package"
                                                            name="optradio"
                                                            value={PACKAGE}
                                                            className="ml-4 mr-3"
                                                        />
                                                    Paquete
                                                    </label>
                                                </div>
                                            </div>
                                        </div>

                                        <hr className="division-line" />

                                        <Tabs type={type} onSubmit={create}/>

                                        <div className="d-flex">
                                        </div>

                                    </div>
                                )
                            }
                        </LoadMask>
                    </div>
                </div>
            </div>
        );
    }
}
