import React from 'react';
import { api } from "api";
import { Link } from 'react-router-dom';
import { Field, reduxForm, formValueSelector } from 'redux-form';
import { validate, validators } from 'validate-redux-form';
import { renderField, renderSearchSelect, renderSelectCustom, renderFieldCheck }  from 'Utils/renderField/renderField';
import { connect } from 'react-redux'

const referent_role = 6

function formatProfile(cell){
    if (cell){
        return <span>{cell.role}</span>
    } else {
        return <span></span>
    }
}


let RolesForm = props => {

    const view = window.location.href.includes("view");

    const {
        handleSubmit,
    } = props;

    return(
        <form onSubmit={handleSubmit} className="py-3">
            <div className="px-md-5 d-flex justify-content-around flex-column flex-sm-row align-items-stretch align-items-sm-center">
                <div className="m-2">
                    <div className="d-flex justify-content-start flex-column flex-sm-row align-items-stretch align-items-sm-center">
                        <label htmlFor="rol_name" className="m-0">Nombre del Rol</label>
                    </div>
                    <div className=" d-flex justify-content-start flex-column flex-sm-row align-items-stretch align-items-sm-center">
                        <Field name="name" disabled={view} autofocus component={renderField} type="text" className="form-control"/>
                    </div>
                </div>
            </div>


            <div className="px-md-5 d-flex justify-content-around flex-row flex-sm-row align-items-stretch">
                <label htmlFor="rol_name" className="py-4">PERMISOS</label>
            </div>
            <div className="px-md-5 d-flex justify-content-around flex-row flex-sm-row align-items-stretch mb-5">
                <div className="d-flex flex-column">
                    <label htmlFor="rol_name" className="my-2">Generales</label>
                    <div className="pl-4 d-flex flex-row">
                        <Field name="dashboard" component={renderFieldCheck} disabled={view} type="text" className="form-control m-0"/>
                        <label htmlFor="dashboard" className="m-0">Dashboard</label>
                    </div>
                    <div className="pl-4 d-flex flex-row">
                        <Field name="pacientes_menu" component={renderFieldCheck} disabled={view} type="text" className="form-control m-0"/>
                        <label htmlFor="pacientes" className="m-0">Pacientes</label>
                    </div>
                    <div className="pl-4 d-flex flex-row">
                        <Field name="bancos_menu" component={renderFieldCheck} disabled={view} type="text" className="form-control m-0"/>
                        <label htmlFor="bancos" className="m-0">Bancos</label>
                    </div>
                    <div className="pl-4 d-flex flex-row">
                        <Field name="proveedores_menu" component={renderFieldCheck} disabled={view} type="text" className="form-control m-0"/>
                        <label htmlFor="proveedores" className="m-0">Proveedores</label>
                    </div>
                </div>

                <div className="d-flex flex-column">
                    <div className="d-inline-flex my-2">
                        <Field name="venta_caja_menu" component={renderFieldCheck} disabled={view} type="text" className="form-control"/>
                        <label htmlFor="rol_name" className="m-0">Venta / Caja</label>
                    </div>
                    <div className="pl-4 d-flex flex-row">
                        <Field name="listado_diagnostico" component={renderFieldCheck} disabled={view} type="text" className="form-control m-0"/>
                        <label htmlFor="listado_diagnostico" className="m-0">Listado diagnóstico</label>
                    </div>
                    <div className="pl-4 d-flex flex-row">
                        <Field name="cuentas_por_cobrar" component={renderFieldCheck} disabled={view} type="text" className="form-control m-0"/>
                        <label htmlFor="cash" className="m-0">Ctas. Por Cobrar</label>
                    </div>
                    <div className="pl-4 d-flex flex-row">
                        <Field name="punto_de_venta" component={renderFieldCheck} disabled={view} type="text" className="form-control m-0"/>
                        <label htmlFor="cash" className="m-0">Punto de Venta</label>
                    </div>
                </div>

                <div className="d-flex flex-column">
                    <div className="d-inline-flex my-2">
                        <Field name="referente_menu" component={renderFieldCheck} disabled={view} type="text" className="form-control"/>
                        <label htmlFor="rol_name" className="m-0">Referentes</label>
                    </div>
                    <div className="pl-4 d-flex flex-row">
                        <Field name="referentes" component={renderFieldCheck} disabled={view} type="text" className="form-control m-0"/>
                        <label htmlFor="cash" className="m-0">Referentes</label>
                    </div>
                    <div className="pl-4 d-flex flex-row">
                        <Field name="instituciones" component={renderFieldCheck} disabled={view} type="text" className="form-control m-0"/>
                        <label htmlFor="cash" className="m-0">Instituciones</label>
                    </div>
                    <div className="pl-4 d-flex flex-row">
                        <Field name="categorias_referente" component={renderFieldCheck} disabled={view} type="text" className="form-control m-0"/>
                        <label htmlFor="cash" className="m-0">Categorias</label>
                    </div>
                </div>

                <div className="d-flex flex-column">
                    <div className="d-inline-flex my-2">
                        <Field name="servicios_menu" component={renderFieldCheck} disabled={view} type="text" className="form-control"/>
                        <label htmlFor="rol_name" className="m-0">Servicios</label>
                    </div>
                    <div className="pl-4 d-flex flex-row">
                        <Field name="productos" component={renderFieldCheck} disabled={view} type="text" className="form-control m-0"/>
                        <label htmlFor="cash" className="m-0">Productos y servicos (Listado)</label>
                    </div>
                </div>
            </div>

            <div className="px-md-5 d-flex justify-content-around flex-row flex-sm-row align-items-stretch pb-3">
                <div className="d-flex flex-column">
                    <div className="d-inline-flex my-2">
                        <Field name="agenda_menu" component={renderFieldCheck} disabled={view} type="text" className="form-control"/>
                        <label htmlFor="rol_name" className="m-0">Agenda</label>
                    </div>
                    <div className="pl-4 d-flex flex-row">
                        <Field name="citas" component={renderFieldCheck} disabled={view} type="text" className="form-control m-0"/>
                        <label htmlFor="citas" className="m-0">Citas</label>
                    </div>
                    <div className="pl-4 d-flex flex-row">
                        <Field name="consultas_hoy" component={renderFieldCheck} disabled={view} type="text" className="form-control m-0"/>
                        <label htmlFor="consultas_hoy" className="m-0">Consultas de hoy</label>
                    </div>
                    <div className="pl-4 d-flex flex-row">
                        <Field name="cirugias" component={renderFieldCheck} disabled={view} type="text" className="form-control m-0"/>
                        <label htmlFor="cirugias" className="m-0">Cirugias</label>
                    </div>
                    <div className="pl-4 d-flex flex-row">
                        <Field name="cirugias_hoy" component={renderFieldCheck} disabled={view} type="text" className="form-control m-0"/>
                        <label htmlFor="cirugias_hoy" className="m-0">Cirugias de hoy</label>
                    </div>
                </div>
                <div className="d-flex flex-column">
                    <div className="d-inline-flex my-2">
                        <Field name="bodega_menu" component={renderFieldCheck} disabled={view} type="text" className="form-control"/>
                        <label htmlFor="rol_name" className="m-0">Bodega</label>
                    </div>
                    <div className="pl-4 d-flex flex-row">
                        <Field name="listado_bodegas" component={renderFieldCheck} disabled={view} type="text" className="form-control m-0"/>
                        <label htmlFor="cash" className="m-0">Listado Bodegas</label>
                    </div>
                    <div className="pl-4 d-flex flex-row">
                        <Field name="administracion_bodegas" component={renderFieldCheck} disabled={view} type="text" className="form-control m-0"/>
                        <label htmlFor="cash" className="m-0">Administración</label>
                    </div>
                </div>
                <div className="d-flex flex-column">
                    <div className="d-inline-flex my-2">
                        <Field name="reportes_menu" component={renderFieldCheck} disabled={view} type="text" className="form-control"/>
                        <label htmlFor="rol_name" className="m-0">Reportes</label>
                    </div>
                    <div className="pl-4 d-flex flex-row">
                        <Field name="movimientos" component={renderFieldCheck} disabled={view} type="text" className="form-control m-0"/>
                        <label htmlFor="cash" className="m-0">Movimientos</label>
                    </div>
                    <div className="pl-4 d-flex flex-row">
                        <Field name="payback" component={renderFieldCheck} disabled={view} type="text" className="form-control m-0"/>
                        <label htmlFor="payback" className="m-0">Payback</label>
                    </div>
                    <div className="pl-4 d-flex flex-row">
                        <Field name="arqueos" component={renderFieldCheck} disabled={view} type="text" className="form-control m-0"/>
                        <label htmlFor="arqueos" className="m-0">Arqueos</label>
                    </div>
                    <div className="pl-4 d-flex flex-row">
                        <Field name="cirugia" component={renderFieldCheck} disabled={view} type="text" className="form-control m-0"/>
                        <label htmlFor="payback" className="m-0">Cirugías</label>
                    </div>
                    <div className="pl-4 d-flex flex-row">
                        <Field name="honorarios" component={renderFieldCheck} disabled={view} type="text" className="form-control m-0"/>
                        <label htmlFor="arqueos" className="m-0">Honorarios de Cirugías</label>
                    </div>
                    <div className="pl-4 d-flex flex-row">
                        <Field name="reporte_ingresos" component={renderFieldCheck} disabled={view} type="text" className="form-control m-0"/>
                        <label htmlFor="reporte_ingresos" className="m-0">Reporte de ingresos</label>
                    </div>
                    <div className="pl-4 d-flex flex-row">
                        <Field name="reporte_bajas" component={renderFieldCheck} disabled={view} type="text" className="form-control m-0"/>
                        <label htmlFor="reporte_bajas" className="m-0">Reporte de bajas</label>
                    </div>
                    <div className="pl-4 d-flex flex-row">
                        <Field name="reporte_ajustes" component={renderFieldCheck} disabled={view} type="text" className="form-control m-0"/>
                        <label htmlFor="reporte_ajustes" className="m-0">Reporte de ajustes</label>
                    </div>
                    <div className="pl-4 d-flex flex-row">
                        <Field name="reporte_consultas" component={renderFieldCheck} disabled={view} type="text" className="form-control m-0"/>
                        <label htmlFor="reporte_consultas" className="m-0">Reporte de consultas</label>
                    </div>
                    <div className="pl-4 d-flex flex-row">
                        <Field name="reporte_tiempos_consultas" component={renderFieldCheck} disabled={view} type="text" className="form-control m-0"/>
                        <label htmlFor="reporte_tiempos_consultas" className="m-0">Tiempos de consultas</label>
                    </div>
                    <div className="pl-4 d-flex flex-row">
                        <Field name="reporte_consignacion" component={renderFieldCheck} disabled={view} type="text" className="form-control m-0"/>
                        <label htmlFor="reporte_consignacion" className="m-0">Reporte de productos en consignación</label>
                    </div>
                    <div className="pl-4 d-flex flex-row">
                        <Field name="reporte_cuenta_abierta" component={renderFieldCheck} disabled={view} type="text" className="form-control m-0"/>
                        <label htmlFor="reporte_cuenta_abierta" className="m-0">Reporte de cuentas abiertas</label>
                    </div>
                    <div className="pl-4 d-flex flex-row">
                        <Field name="reporte_consolidado" component={renderFieldCheck} disabled={view} type="text" className="form-control m-0"/>
                        <label htmlFor="reporte_consolidado" className="m-0">Reporte consolidado</label>
                    </div>
                    <div className="pl-4 d-flex flex-row">
                        <Field name="reporte_gastos" component={renderFieldCheck} disabled={view} type="text" className="form-control m-0"/>
                        <label htmlFor="reporte_gastos" className="m-0">Reporte gastos</label>
                    </div>
                    <div className="pl-4 d-flex flex-row">
                        <Field name="reporte_pago_grupal" component={renderFieldCheck} disabled={view} type="text" className="form-control m-0"/>
                        <label htmlFor="reporte_gastos" className="m-0">Reporte de Pagos Grupales</label>
                    </div>                    
                </div>

                <div className="d-flex flex-column">
                    <div className="d-inline-flex my-2">
                        <Field name="oc_menu" component={renderFieldCheck} disabled={view} type="text" className="form-control"/>
                        <label htmlFor="rol_name" className="m-0">Orden de Compra</label>
                    </div>
                    <div className="pl-4 d-flex flex-row">
                        <Field name="solicitud_oc" component={renderFieldCheck} disabled={view} type="text" className="form-control m-0"/>
                        <label htmlFor="solicitud_oc" className="m-0">Solicitud</label>
                    </div>
                    <div className="pl-4 d-flex flex-row">
                        <Field name="autorizacion_oc" component={renderFieldCheck} disabled={view} type="text" className="form-control m-0"/>
                        <label htmlFor="autorizacion_oc" className="m-0">Autorización</label>
                    </div>
                    <div className="pl-4 d-flex flex-row">
                        <Field name="ingreso_oc" component={renderFieldCheck} disabled={view} type="text" className="form-control m-0"/>
                        <label htmlFor="ingreso_oc" className="m-0">Recepción</label>
                    </div>
                    <div className="pl-4 d-flex flex-row">
                        <Field name="cuentas_por_pagar" component={renderFieldCheck} disabled={view} type="text" className="form-control m-0"/>
                        <label htmlFor="cuentas_por_pagar" className="m-0">Ctas. por Pagar</label>
                    </div>
                </div>
            </div>

            <div className="px-md-5 d-flex justify-content-around flex-row flex-sm-row align-items-stretch pb-3">

                <div className="d-flex flex-column">
                    <div className="d-inline-flex my-2">
                        <Field name="gastos_menu" component={renderFieldCheck} disabled={view} type="text" className="form-control"/>
                        <label htmlFor="rol_name" className="m-0">Gastos</label>
                    </div>
                    <div className="pl-4 d-flex flex-row">
                        <Field name="caja_chica" component={renderFieldCheck} disabled={view} type="text" className="form-control m-0"/>
                        <label htmlFor="cash" className="m-0">Caja Chica</label>
                    </div>
                    <div className="pl-4 d-flex flex-row">
                        <Field name="admin_caja_chica" component={renderFieldCheck} disabled={view} type="text" className="form-control m-0"/>
                        <label htmlFor="cash" className="m-0">Admin. Caja Chica</label>
                    </div>
                    <div className="pl-4 d-flex flex-row">
                        <Field name="tipo_gasto" component={renderFieldCheck} disabled={view} type="text" className="form-control m-0"/>
                        <label htmlFor="cash" className="m-0">Tipos de gasto</label>
                    </div>
                    <div className="pl-4 d-flex flex-row">
                        <Field name="ingreso_gasto" component={renderFieldCheck} disabled={view} type="text" className="form-control m-0"/>
                        <label htmlFor="cash" className="m-0">Ingreso de gastos</label>
                    </div>
                    <div className="pl-4 d-flex flex-row">
                        <Field name="retenciones" component={renderFieldCheck} disabled={view} type="text" className="form-control m-0"/>
                        <label htmlFor="cash" className="m-0">Retenciones por pagar</label>
                    </div>
                </div>

                <div className="d-flex flex-column">
                    <div className="d-inline-flex my-2">
                        <Field name="diagnostico_menu" component={renderFieldCheck} disabled={view} type="text" className="form-control"/>
                        <label htmlFor="diagnostico_menu" className="m-0">Orden Diagnóstico</label>
                    </div>
                    {/*
                    <div className="pl-4 d-flex flex-row">
                        <Field name="listado_diagnostico" component={renderFieldCheck} disabled={view} type="text" className="form-control m-0"/>
                        <label htmlFor="listado_diagnostico" className="m-0">Listado diagnostico</label>
                    </div>
                    */}
                    <div className="pl-4 d-flex flex-row">
                        <Field name="cola_diagnostico" component={renderFieldCheck} disabled={view} type="text" className="form-control m-0"/>
                        <label htmlFor="cola_diagnostico" className="m-0">Cola de diagnóstico</label>
                    </div>
                    <div className="pl-4 d-flex flex-row">
                        <Field name="diagnosticos_completados" component={renderFieldCheck} disabled={view} type="text" className="form-control m-0"/>
                        <label htmlFor="diagnosticos_completados" className="m-0">Diagnósticos completados</label>
                    </div>
                </div>

                <div className="d-flex flex-column">
                    <div className="d-inline-flex my-2">
                        <Field name="configuracion_menu" component={renderFieldCheck} disabled={view} type="text" className="form-control"/>
                        <label htmlFor="rol_name" className="m-0">Configuración</label>
                    </div>
                    <div className="pl-4 d-flex flex-row">
                        <Field name="configuracion_general" component={renderFieldCheck} disabled={view} type="text" className="form-control m-0"/>
                        <label htmlFor="configuracion_general" className="m-0">General</label>
                    </div>
                    <div className="pl-4 d-flex flex-row">
                        <Field name="productos_conf" component={renderFieldCheck} disabled={view} type="text" className="form-control m-0"/>
                        <label htmlFor="productos_conf" className="m-0">Productos y Servicios</label>
                    </div>
                    <div className="pl-4 d-flex flex-row">
                        <Field name="categorias_producto" component={renderFieldCheck} disabled={view} type="text" className="form-control m-0"/>
                        <label htmlFor="categorias_producto" className="m-0">Categorías de Producto</label>
                    </div>
                    <div className="pl-4 d-flex flex-row">
                        <Field name="cupones" component={renderFieldCheck} disabled={view} type="text" className="form-control m-0"/>
                        <label htmlFor="cupones" className="m-0">Cupones</label>
                    </div>
                    <div className="pl-4 d-flex flex-row">
                        <Field name="pos" component={renderFieldCheck} disabled={view} type="text" className="form-control m-0"/>
                        <label htmlFor="categorias_producto" className="m-0">POS's</label>
                    </div>
                    <div className="pl-4 d-flex flex-row">
                        <Field name="cuotas" component={renderFieldCheck} disabled={view} type="text" className="form-control m-0"/>
                        <label htmlFor="cuotas" className="m-0">Cuotas</label>
                    </div>
                    <div className="pl-4 d-flex flex-row">
                        <Field name="entes_financieros" component={renderFieldCheck} disabled={view} type="text" className="form-control m-0"/>
                        <label htmlFor="entes_financieros" className="m-0">Entes Financieros</label>
                    </div>
                    <div className="pl-4 d-flex flex-row">
                        <Field name="cajas" component={renderFieldCheck} disabled={view} type="text" className="form-control m-0"/>
                        <label htmlFor="cajas" className="m-0">Cajas</label>
                    </div>
                    <div className="pl-4 d-flex flex-row">
                        <Field name="roles" component={renderFieldCheck} disabled={view} type="text" className="form-control m-0"/>
                        <label htmlFor="roles" className="m-0">Roles</label>
                    </div>
                    <div className="pl-4 d-flex flex-row">
                        <Field name="usuarios" component={renderFieldCheck} disabled={view} type="text" className="form-control m-0"/>
                        <label htmlFor="usuarios" className="m-0">Usuarios</label>
                    </div>
                    <div className="pl-4 d-flex flex-row">
                        <Field name="interprete" component={renderFieldCheck} disabled={view} type="text" className="form-control m-0"/>
                        <label htmlFor="interpretes" className="m-0">Intérpretes</label>
                    </div>
                    <div className="pl-4 d-flex flex-row">
                        <Field name="doctores" component={renderFieldCheck} disabled={view} type="text" className="form-control m-0"/>
                        <label htmlFor="doctores" className="m-0">Doctores</label>
                    </div>
                    <div className="pl-4 d-flex flex-row">
                        <Field name="sucursales" component={renderFieldCheck} disabled={view} type="text" className="form-control m-0"/>
                        <label htmlFor="sucursales" className="m-0">Sucursales</label>
                    </div>
                    <div className="pl-4 d-flex flex-row">
                        <Field name="cuentas_bancarias" component={renderFieldCheck} disabled={view} type="text" className="form-control m-0"/>
                        <label htmlFor="cuenta_bancarias" className="m-0">Cuenta Bancarias</label>
                    </div>
                </div>

                <div className="d-flex flex-column">
                    <div className="d-inline-flex my-2">
                        <Field name="consulta_menu" component={renderFieldCheck} disabled={view} type="text" className="form-control"/>
                        <label htmlFor="consulta_menu" className="m-0">Consulta</label>
                    </div>
                    <div className="pl-4 d-flex flex-row">
                        <Field name="cola_consulta" component={renderFieldCheck} disabled={view} type="text" className="form-control m-0"/>
                        <label htmlFor="cola_consulta" className="m-0">General</label>
                    </div>
                    <div className="pl-4 d-flex flex-row">
                        <Field name="lensometry" component={renderFieldCheck} disabled={view} type="text" className="form-control m-0"/>
                        <label htmlFor="lensometry" className="m-0">Lensometría</label>
                    </div>
                    <div className="pl-4 d-flex flex-row">
                        <Field name="visual_acuity" component={renderFieldCheck} disabled={view} type="text" className="form-control m-0"/>
                        <label htmlFor="visual_acuity" className="m-0">Agudeza Visual</label>
                    </div>
                    <div className="pl-4 d-flex flex-row">
                        <Field name="autorefractor" component={renderFieldCheck} disabled={view} type="text" className="form-control m-0"/>
                        <label htmlFor="autorefractor" className="m-0">Autorefractómetro</label>
                    </div>
                    <div className="pl-4 d-flex flex-row">
                        <Field name="keratometry" component={renderFieldCheck} disabled={view} type="text" className="form-control m-0"/>
                        <label htmlFor="keratometry" className="m-0">Queratometría</label>
                    </div>
                    <div className="pl-4 d-flex flex-row">
                        <Field name="refraction" component={renderFieldCheck} disabled={view} type="text" className="form-control m-0"/>
                        <label htmlFor="refraction" className="m-0">Refracción</label>
                    </div>
                    <div className="pl-4 d-flex flex-row">
                        <Field name="tonometry" component={renderFieldCheck} disabled={view} type="text" className="form-control m-0"/>
                        <label htmlFor="tonometry" className="m-0">Tonometría</label>
                    </div>
                    <div className="pl-4 d-flex flex-row">
                        <Field name="muscle" component={renderFieldCheck} disabled={view} type="text" className="form-control m-0"/>
                        <label htmlFor="muscle" className="m-0">Músculos extraoculares</label>
                    </div>
                    <div className="pl-4 d-flex flex-row">
                        <Field name="lamp" component={renderFieldCheck} disabled={view} type="text" className="form-control m-0"/>
                        <label htmlFor="lamp" className="m-0">Lámpara de hedidura</label>
                    </div>
                    <div className="pl-4 d-flex flex-row">
                        <Field name="indirect_eye" component={renderFieldCheck} disabled={view} type="text" className="form-control m-0"/>
                        <label htmlFor="indirect_eye" className="m-0">Fondo de ojo indirecto</label>
                    </div>
                    <div className="pl-4 d-flex flex-row">
                        <Field name="diagnostic_plan" component={renderFieldCheck} disabled={view} type="text" className="form-control m-0"/>
                        <label htmlFor="diagnostic_plan" className="m-0">Plan Diagnóstico</label>
                    </div>
                    <div className="pl-4 d-flex flex-row">
                        <Field name="educational_plan" component={renderFieldCheck} disabled={view} type="text" className="form-control m-0"/>
                        <label htmlFor="educational_plan" className="m-0">Plan Educacional</label>
                    </div>
                </div>
            </div>

            <div className="px-md-5 d-flex justify-content-around flex-row flex-sm-row align-items-stretch pb-3">
                <div className="d-flex flex-column">
                    <div className="d-inline-flex my-2">
                        <Field name="plan_menu" component={renderFieldCheck} disabled={view} type="text" className="form-control"/>
                        <label htmlFor="plan_menu" className="m-0">Planes</label>
                    </div>
                    <div className="pl-4 d-flex flex-row">
                        <Field name="plan_examen" component={renderFieldCheck} disabled={view} type="text" className="form-control m-0"/>
                        <label htmlFor="plan_examen" className="m-0">Examen</label>
                    </div>
                    <div className="pl-4 d-flex flex-row">
                        <Field name="plan_procedimiento" component={renderFieldCheck} disabled={view} type="text" className="form-control m-0"/>
                        <label htmlFor="plan_procedimiento" className="m-0">Procedimiento</label>
                    </div>
                    <div className="pl-4 d-flex flex-row">
                        <Field name="plan_paquete" component={renderFieldCheck} disabled={view} type="text" className="form-control m-0"/>
                        <label htmlFor="plan_paquete" className="m-0">Paquete</label>
                    </div>
                </div>
            </div>

            <div className="row-fluid">
                <div className="d-flex justify-content-center flex-column flex-sm-row align-items-stretch align-items-sm-center">
                    <Link className="btn btn-secondary m-3" to="/roles">{!view ? ("Cancelar") : ("Regresar")}</Link>
                    {!view && (<button type="submit" className="btn btn-primary m-3">Guardar</button>)}
                </div>
            </div>
        </form>
    )
};

RolesForm = reduxForm({
   form: 'roles',
   validate: data => {
        return validate(data, {
            'name': validators.exists()('Campo requerido.'),
       })
   }
})(RolesForm);

const selector = formValueSelector('roles');

RolesForm = connect(state => {

    const role = selector(state, "role.value");
    let is_referent = false;
    let no_superuser = false;

    if(role == referent_role){
        is_referent = true
    }

    if(role > 1){
        no_superuser = true;
    }
    return {
        no_superuser,
        is_referent
    };

})(RolesForm);

export default RolesForm
