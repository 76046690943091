import React from 'react';
import classNames from 'classnames'
import { SingleDatePicker } from 'react-dates'
import 'react-dates/initialize';
import moment from 'moment';
import es from 'moment/locale/es';
import 'react-dates/lib/css/_datepicker.css';
import './newdpstyle';


export const renderMonthElement = ({ month, onMonthSelect, onYearSelect }) => {
    let i;
    let years = [];
    for (i = moment()
        .year(); i >= moment()
        .year() - 100; i--) {
        years.push(<option value={i} key={`year-${i}`}>{i}</option>);
    }
    return (
        <div style={{
            display: 'flex',
            justifyContent: 'center',
        }}>
            <div>
                <select
                    value={month.month()}
                    onChange={(e) => onMonthSelect(month, e.target.value)}
                >
                    {moment.months()
                        .map((label, value) => (
                            <option key={value} value={value}>{label}</option>
                        ))}
                </select>
            </div>
            <div>
                <select value={month.year()} onChange={(e) => onYearSelect(month, e.target.value)}>
                    {years}
                </select>
            </div>
        </div>);
};

export default class renderDatePicker extends React.Component {
  constructor(props) {
      super(props);
      this.state = {
          dpFocused: false
      };
      this.onFocusChange = this.onFocusChange.bind(this)
  }
  onFocusChange({ focused }) {
      this.setState({ dpFocused: focused })
  }
  render() {
      const { input, isDisabled, className, placeholder, id, meta: { touched, error } } = this.props;
      const invalid = touched && error;
        moment.locale('es');
      return (
          <div className={classNames(`${className}`, { 'is-invalid': invalid })}>
              <SingleDatePicker
                    disabled={isDisabled}
                    placeholder={placeholder}
                    date={input.value ? moment(input.value) : null}
                    focused={this.state.dpFocused}
                    isOutsideRange={() => false}
                    onDateChange={(value) => {
                        //input.onChange( value );
                        input.onChange(moment(value).format("YYYY-MM-DD"));
                    }}
                    onFocusChange={this.onFocusChange}
                    numberOfMonths={1}
                    id={id ? id : "unique"}
                  renderMonthElement={renderMonthElement}
                />
              {invalid && <div className="invalid-feedback">
                  {error}
              </div>}
          </div>
      )
  }
}
