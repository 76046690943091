import React, {Component} from 'react';
import ReactAudioPlayer from 'react-audio-player';
import './audio_style';

class Audio extends Component {
    state = {
        estado: 'espera',
        pause: false,
        tiempoTranscurrido: '00:00:00',
    }

    onPlay = () => {
        this.setState({
            estado: 'reproduciendo',
            pause: false
        });
    }

    onPause = () => {
        this.setState({pause: true});
    }

    onEnded = () => {
        this.setState({
            estado: 'espera',
            pause: false,
            tiempoTranscurrido: '00:00:00',
        });
    }

    stop = () => {
        this.rap.audioEl.pause();
        this.rap.audioEl.currentTime=0;
        this.setState({
            estado: 'espera',
            pause: false,
            tiempoTranscurrido: '00:00:00',
        });
    }

    onListen = (data) => {
        const segundos = parseFloat(Math.round(data).toFixed(0));
        const h = parseInt(segundos/(60*60));
        const m = parseInt(segundos%(60*60)/60);
        const s = parseInt(segundos%60);
        const tiempoTranscurrido =  ((h<10?'0'+h:h) + ':' + (m<10?'0'+m:m) + ':' + (s<10?'0'+s:s));
        this.setState({ tiempoTranscurrido });
    }

    render(){
        return(
            <React.Fragment>
                {/*
                <ReactAudioPlayer
                    ref={(element) => { this.rap = element; }}
                    src={this.props.path}
                    onPlay={this.onPlay}
                    onPause={this.onPause}
                    onEnded={this.onEnded}
                    listenInterval={999}
                    onListen={this.onListen}
                    //autoPlay
                    //controls
                />
                */}

                <ReactAudioPlayer
                    src={this.props.path}
                    controls
                />

            {/*this.state.estado === 'espera' ?
                <div className="d-flex flex-row align-items-center">
                    <div className='d-flex flex-row align-items-center audio-content'>
                        <div className='d-flex flex-column w-75'>
                            <span className='audio-interpreter-label'>Interpretación.mp3</span>
                            <span className='audio-fecha'>09/01/2020</span>
                        </div>
                        <div className="d-flex flex-row justify-content-end align-items-end text-right w-25">
                            <img
                                onClick={()=>{
                                    this.rap.audioEl.play();
                                }}
                                className="action-img edit"
                                title="Ver"
                                src={require("../../../../../../assets/img/icons/start-icon-white.png")}
                                alt="Ver"
                            />
                        </div>
                    </div>
                </div>
            :
                <div className='d-flex flex-row align-items-center reproductor-content'>
                    <div className='d-flex flex-row w-25'>
                        <span className='reproductor-interpreter-label'>{this.state.tiempoTranscurrido}</span>
                    </div>
                    <div className='d-flex flex-row w-25'>
                        <span className='reproductor-interpreter-label'>Reproduciendo</span>
                    </div>
                    <div className="d-flex flex-row justify-content-end align-items-end text-right w-50">
                        <img
                            onClick={()=>{
                                this.stop();
                            }}

                            className="action-img add"
                            title="Ver"
                            src={require("../../../../../../assets/img/icons/stop-icon-blue.png")}
                            alt="Ver"
                        />

                        {!this.state.pause ?
                            <img
                                onClick={()=>{
                                    this.rap.audioEl.pause();
                                }}
                                className="action-img add"
                                title="Ver"
                                src={require("../../../../../../assets/img/icons/pausa-icon-blue.png")}
                                alt="Ver"
                            />
                        :
                            <img
                                onClick={()=>{
                                    this.rap.audioEl.play();
                                }}
                                className="action-img add"
                                title="Ver"
                                src={require("../../../../../../assets/img/icons/start-icon-blue.png")}
                                alt="Ver"
                            />
                        }
                    </div>
                </div>
            */}
            </React.Fragment>
        );
    }
}

export default Audio;
