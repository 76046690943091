import Table from '../../Utils/Grid';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { BreakLine } from '../../Utils/tableOptions';
import HeaderSimple from '../../Utils/Header/HeaderSimple';
import { activeFormatter } from '../../Utils/Acciones/Acciones';

function formatColor(cell){
    if (cell){
        return <span className="color-list" style={{background:cell}}></span>
    } else {
        return <span style={{background:"white"}}></span>
    }
}

class Plan extends Component {
    componentWillUnmount() {
        this.props.resetStore();
    }
    componentWillMount() {
        const {listar, page, tipo_plan} = this.props;
        listar(page, tipo_plan);
    }

    terminaEnVocal = cadena => {
        if(!cadena || !cadena.length) return false;
        let ultimoCaracter = cadena[cadena.length - 1].toLowerCase();
        return ["a", "e", "i", "o", "u"].indexOf(ultimoCaracter) !== -1;
    }

    pageChange = (page) => {
        const {listar, tipo_plan} = this.props;
        listar(page, tipo_plan);
    }

    render() {
        const {
            listar,
            detail,
            create,
            update,
            destroy,
        } = this.props;

        const {
            cargando,
            page,
            data
        } = this.props;

        let enlace=''; let titulo='';
        if(this.props.tipo_plan === 1){ enlace = 'examen'; titulo='Examen';};
        if(this.props.tipo_plan === 2){ enlace = 'procedimiento'; titulo='Procedimiento';};
        if(this.props.tipo_plan === 3){ enlace = 'paquete'; titulo='Paquete';};

        return (
            <div className="content">
                <div className="m-auto row-fluid w-100">
                    <HeaderSimple
                        texto={`Agregar ${titulo}`}
                        ruta={`/plan-${enlace}/create`}
                    />
                    <div className="table-decoration">
                        <div className="top-title">
                            LISTADO DE {titulo.toUpperCase()}{this.terminaEnVocal(titulo) ? 'S' : 'ES'}
                        </div>
                        <Table
                            //onPageChange={listar}
                            onPageChange={this.pageChange}
                            data={data}
                            loading={cargando}
                            page={page}
                        >
                            <TableHeaderColumn
                                tdStyle={BreakLine}
                                thStyle={BreakLine}
                                dataField="nombre" dataSort>Nombre</TableHeaderColumn>
                            <TableHeaderColumn
                                tdStyle={BreakLine}
                                thStyle={BreakLine}
                                dataField="created" dataSort>Fecha de registro</TableHeaderColumn>
                            <TableHeaderColumn
                                dataField="id"
                                isKey={true}
                                dataAlign="center"
                                dataFormat={activeFormatter({ ver: `/plan-${enlace}`, editar: `/plan-${enlace}`, eliminar_extra: destroy })}>Acciones</TableHeaderColumn>
                        </Table>
                    </div>
                </div>
            </div>
        )
    }
}

export default Plan;
