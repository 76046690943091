import { connect } from 'react-redux';
import { actions } from '../../../redux/modules/sales';

import Payment from './Payment';
import {resetStore} from "../../../redux/modules/login";

const ms2p = (state) => {
    return {
        ...state.sales,
    };
};

const md2p = { ...actions, resetStore };
export default connect(ms2p, md2p)(Payment);
