import { connect } from 'react-redux';
import Schedule from './Schedule';
import { actions } from '../../../redux/modules/appointments';
import {resetStore} from "../../../redux/modules/login";

const ms2p = state => ({
    ...state.appointments,
});

const md2p = { ...actions, resetStore };

export default connect(ms2p, md2p)(Schedule);
