import React, { Component } from 'react';
import classNames from 'classnames';

import Table from '../Utils/Grid';
import { BreakLine } from '../Utils/tableOptions';
import HeaderSimple from '../Utils/Header/HeaderSimple';
import { activeFormatter } from '../Utils/Acciones/Acciones';
import CustomToolbar from '../Utils/Toolbar/CustomToolbar';

function formatReferentCategory(cell) {
    if (cell) {
        return (<span>{cell.name}</span>);
    }

    return (<span />);
}

class Institution extends Component {
    componentWillMount() {
        this.props.listar()
    }
    componentWillUnmount() {
        this.props.resetStore();
    }

    render() {
        const {
            page,
            data,
            listar,
            detail,
            create,
            update,
            destroy,
            cargando,
            isNavBarOpen,
        } = this.props;
        return (
            <div className={classNames('content', { 'content-min': isNavBarOpen })}>
                <div className="m-auto row-fluid w-100">
                    <HeaderSimple
                        texto="Crear institución"
                        ruta="/institutions/create"
                    />
                    <div className="table-decoration">
                        <div className="d-flex flex-row justify-content-between align-items-center">
                            <div className="top-title-toolbar mt-3 d-flex">
                                <span className="ml-4 text-uppercase text-titulo">
                                    <strong>LISTADO DE INSTITUCIONES</strong>
                                </span>
                            </div>                        

                            <div className="top-title-toolbar mt-3">
                                <CustomToolbar
                                    buscar={this.props.search}
                                    buscador={this.props.buscador}
                                    autofocus
                                />
                            </div>
                        </div>

                        <Table
                            onPageChange={listar}
                            data={data}
                            loading={cargando}
                            page={page}
                        >
                            <TableHeaderColumn
                                tdStyle={BreakLine}
                                thStyle={BreakLine}
                                dataField="name" dataSort>Nombre</TableHeaderColumn>
                            <TableHeaderColumn
                                tdStyle={BreakLine}
                                thStyle={BreakLine}
                                dataFormat={formatReferentCategory}
                                dataField="referent_category"
                                dataSort
                            >
                                Categoría
                            </TableHeaderColumn>
                            <TableHeaderColumn
                                isKey
                                dataField="id"
                                dataAlign="center"
                                dataFormat={activeFormatter({ ver: '/institutions', editar: '/institutions', eliminar: destroy })}
                            >
                                    Acciones
                            </TableHeaderColumn>
                        </Table>
                    </div>
                </div>
            </div>
        );
    }
}

export default Institution;
