import React from 'react';
import { api } from "api";
import { Link } from 'react-router-dom';
import { Field, reduxForm, FieldArray } from 'redux-form';
import { validate, validators } from 'validate-redux-form';
import { renderField, renderSearchSelect, renderSelectCustom, renderFieldCheck }  from 'Utils/renderField/renderField';


export const renderFrases =({ fields, meta: {error, submitFailed}, view}) => (
    <React.Fragment>
        {
            fields.map((field, index) => (
                <div className="px-md-5 d-flex justify-content-around flex-column flex-sm-row align-items-stretch align-items-sm-center">
                    <div className="m-2">
                        {(index === 0) && (<div className="d-flex justify-content-start flex-column flex-sm-row align-items-stretch align-items-sm-center">
                            <label htmlFor={`${field}.tipo_frase`} className="m-0">Tipo Frase</label>
                        </div>)}
                        <div className=" d-flex justify-content-start flex-column flex-sm-row align-items-stretch align-items-sm-center">
                            <Field placeholder="Tipo Frase" name={`${field}.tipo_frase`} disabled={view} component={renderField} type="text" className="form-control"/>
                        </div>
                    </div>
                    <div className="m-2">
                        {(index === 0) && (<div className="d-flex justify-content-start flex-column flex-sm-row align-items-stretch align-items-sm-center">
                            <label htmlFor={`${field}.codigo_frase`} className="m-0">Código Frase</label>
                        </div>)}
                        <div className=" d-flex justify-content-start flex-column flex-sm-row align-items-stretch align-items-sm-center">
                            <Field placeholder="Codigo Frase" name={`${field}.codigo_frase`} disabled={view} component={renderField} type="text" className="form-control"/>
                        </div>
                    </div>
                    {
                        !view &&
                            <div className="d-flex flex-row">
                                <img className="record-action-img delete-create-button" src={require("assets/img/icons/delete-icon.png")} onClick={() => fields.remove(index)}  alt="Eliminar" />
                            </div>
                    }
                </div>
                )
            )
        }
        {/* row for the add button */}
        {
            !view &&
                <div className="d-flex flex-row justify-content-center">
                    <div className='d-flex flex-row'>
                        <img onClick={() => fields.push()} className="record-action-img add-create-button" src={require("assets/img/icons/add-icon.png")} alt="Agregar" />
                    </div>
                </div>
        }
        {
            submitFailed && (
                <div className="invalid-feedback d-flex justify-content-center">
                    {error}
                </div>
            )
        }
    </React.Fragment>
);

const getBankAccounts = (search)  =>{
    return api.get("bank_accounts",{search})
        .then((data) => {
            if (data){
                const bank_accounts = data.results.map(x => (
                    {value: x.id, label: x.owner, number: x.account_number}
                ));
                return bank_accounts
            }
            else{
                return []
            }
        })
        .catch(e => {
            return []
        })
}

let EntityForm = props => {

    const view = window.location.href.includes("view");

    const {
        handleSubmit,
    } = props;

    return(
        <form onSubmit={handleSubmit} className="pb-2 px-5">
            <div className="mt-3 px-md-5 d-flex justify-content-around flex-column flex-sm-row align-items-stretch align-items-sm-center">
                <div className="m-2">
                    <div className="d-flex justify-content-start flex-column flex-sm-row align-items-stretch align-items-sm-center">
                        <label htmlFor="entity_name" className="m-0">Nombre de la entidad</label>
                    </div>
                    <div className=" d-flex justify-content-start flex-column flex-sm-row align-items-stretch align-items-sm-center">
                        <Field name="usuario_fel" autofocus disabled={view} component={renderField} type="text" className="form-control"/>
                    </div>
                </div>
                <div className="m-2">
                    <div className="d-flex justify-content-start flex-column flex-sm-row align-items-stretch align-items-sm-center">
                        <label htmlFor="nit_fel" className="m-0">Nit</label>
                    </div>
                    <div className=" d-flex justify-content-start flex-column flex-sm-row align-items-stretch align-items-sm-center">
                        <Field name="nit_fel" disabled={view} component={renderField} type="text" className="form-control"/>
                    </div>
                </div>
            </div>

            <div className="mt-3 px-md-5 d-flex justify-content-around flex-column flex-sm-row align-items-stretch align-items-sm-center">
                <div className="m-2">
                    <div className="d-flex justify-content-start flex-column flex-sm-row align-items-stretch align-items-sm-center">
                        <label htmlFor="entity_name" className="m-0">Correo</label>
                    </div>
                    <div className=" d-flex justify-content-start flex-column flex-sm-row align-items-stretch align-items-sm-center">
                        <Field name="correo" disabled={view} component={renderField} type="mail" className="form-control"/>
                    </div>
                </div>
                <div className="m-2">
                    <div className="d-flex justify-content-start flex-column flex-sm-row align-items-stretch align-items-sm-center">
                        <label htmlFor="nit_fel" className="m-0">Codigo de Establecimiento</label>
                    </div>
                    <div className=" d-flex justify-content-start flex-column flex-sm-row align-items-stretch align-items-sm-center">
                        <Field name="codigo_establecimiento" disabled={view} component={renderField} type="text" className="form-control"/>
                    </div>
                </div>
            </div>

            <div className="px-md-5 mb-2 d-flex justify-content-around flex-column flex-sm-row align-items-stretch align-items-sm-center">
                <div className="">
                    <div className="d-flex justify-content-start flex-column flex-sm-row align-items-stretch align-items-sm-center">
                        <label htmlFor="bank_account" className="">Nombre de la cuenta</label>
                    </div>
                    <div className=" d-flex justify-content-start flex-column flex-sm-row align-items-stretch align-items-sm-center">
                        <Field
                            disabled={view}
                            name="bank_account"
                            valueKey="value"
                            labelKey="label"
                            label="referent"
                            component={renderSearchSelect}
                            loadOptions={getBankAccounts}
                            className="no-border"
                        />
                    </div>
                </div>
                <div className="m-0">
                    <div className=" d-flex justify-content-start flex-column flex-sm-row align-items-stretch align-items-sm-center">
                        <label htmlFor="account_number" className="">Número de cuenta</label>
                    </div>
                    <div className=" d-flex justify-content-end flex-column flex-sm-row align-items-stretch align-items-sm-center">
                        <Field
                            disabled={view}
                            name="bank_account"
                            valueKey="value"
                            labelKey="number"
                            label="referent"
                            component={renderSearchSelect}
                            loadOptions={getBankAccounts}
                            className="no-border"
                        />
                    </div>
                </div>
            </div>

            <div className="px-md-5 d-flex justify-content-around flex-column flex-sm-row align-items-stretch align-items-sm-center">
                <div className="m-2">
                    <div className="d-flex justify-content-start flex-column flex-sm-row align-items-stretch align-items-sm-center">
                        <label htmlFor="unique_key" className="m-0">Llave firma (Token)</label>
                    </div>
                    <div className=" d-flex justify-content-start flex-column flex-sm-row align-items-stretch align-items-sm-center">
                        <Field name="unique_key" disabled={view} component={renderField} type="text" className="form-control"/>
                    </div>
                </div>

                <div className="m-2">
                    <div className="d-flex justify-content-start flex-column flex-sm-row align-items-stretch align-items-sm-center">
                        <label htmlFor="entity_nit" className="m-0">Llave de Certificación</label>
                    </div>
                    <div className=" d-flex justify-content-start flex-column flex-sm-row align-items-stretch align-items-sm-center">
                        <Field name="certification_key" disabled={view} component={renderField} type="text" className="form-control"/>
                    </div>
                </div>
            </div>

            <div className="px-md-5 d-flex justify-content-around flex-column flex-sm-row align-items-stretch align-items-sm-center">
                <div className="m-2">
                    <div className="d-flex justify-content-start flex-column flex-sm-row align-items-stretch align-items-sm-center">
                        <label htmlFor="entity_name" className="m-0">Afiliación FEL</label>
                    </div>
                    <div className=" d-flex justify-content-start flex-column flex-sm-row align-items-stretch align-items-sm-center">
                        <Field name="afiliacion_fel" disabled={view} component={renderSelectCustom} options={[
                            {value: "GEN", label:'General'},
                            {value: "EXE", label:'Exento'},
                            {value: "PEQ", label:'Factura Pequeño Contribuyente'},
                        ]}/>
                    </div>
                </div>
                <div className="m-2">
                    <div className="d-flex justify-content-start flex-column flex-sm-row align-items-stretch align-items-sm-center">
                        <label htmlFor="entity_name" className="m-0">País</label>
                    </div>
                    <div className=" d-flex justify-content-start flex-column flex-sm-row align-items-stretch align-items-sm-center">
                    <Field name="pais" disabled={view} component={renderField} type="text" className="form-control"/>
                    </div>
                </div>
            </div>

            <div className="px-md-5 d-flex justify-content-around flex-column flex-sm-row align-items-stretch align-items-sm-center">
                <div className="m-2">
                    <div className="d-flex justify-content-start flex-column flex-sm-row align-items-stretch align-items-sm-center">
                        <label htmlFor="entity_name" className="m-0">Código Postal</label>
                    </div>
                    <div className=" d-flex justify-content-start flex-column flex-sm-row align-items-stretch align-items-sm-center">
                        <Field name="codigo_postal" disabled={view} component={renderField} type="text" className="form-control"/>
                    </div>
                </div>
                <div className="m-2">
                    <div className="d-flex justify-content-start flex-column flex-sm-row align-items-stretch align-items-sm-center">
                        <label htmlFor="entity_name" className="m-0">Departamento</label>
                    </div>
                    <div className=" d-flex justify-content-start flex-column flex-sm-row align-items-stretch align-items-sm-center">
                        <Field name="departamento" disabled={view} component={renderField} type="text" className="form-control"/>
                    </div>
                </div>
            </div>

            <div className="px-md-5 d-flex justify-content-around flex-column flex-sm-row align-items-stretch align-items-sm-center">
                <div className="m-2">
                    <div className="d-flex justify-content-start flex-column flex-sm-row align-items-stretch align-items-sm-center">
                        <label htmlFor="entity_name" className="m-0">Municipio</label>
                    </div>
                    <div className=" d-flex justify-content-start flex-column flex-sm-row align-items-stretch align-items-sm-center">
                        <Field name="municipio" disabled={view} component={renderField} type="text" className="form-control"/>
                    </div>
                </div>
                <div className="m-2">
                    <div className="d-flex justify-content-start flex-column flex-sm-row align-items-stretch align-items-sm-center">
                        <label htmlFor="entity_name" className="m-0">Dirección</label>
                    </div>
                    <div className=" d-flex justify-content-start flex-column flex-sm-row align-items-stretch align-items-sm-center">
                        <Field name="direccion" disabled={view} component={renderField} type="text" className="form-control"/>
                    </div>
                </div>
            </div>

            <div className="px-md-5 d-flex justify-content-around flex-column flex-sm-row align-items-stretch align-items-sm-center">
                <div className="m-2">
                    <div className="d-flex justify-content-start flex-column flex-sm-row align-items-stretch align-items-sm-center">
                        <label htmlFor="usuario" className="m-0">Alias</label>
                    </div>
                    <div className=" d-flex justify-content-start flex-column flex-sm-row align-items-stretch align-items-sm-center">
                        <Field name="usuario" disabled={view} component={renderField} type="text" className="form-control"/>
                    </div>
                </div>
                <div className="m-2 invisible">
                    <div className="d-flex justify-content-start flex-column flex-sm-row align-items-stretch align-items-sm-center">
                        <label htmlFor="comodin" className="m-0">Comodin</label>
                    </div>
                    <div className=" d-flex justify-content-start flex-column flex-sm-row align-items-stretch align-items-sm-center">
                        <Field name="comodin" disabled={view} component={renderField} type="text" className="form-control"/>
                    </div>
                </div>
            </div>
            <div className="mt-3 d-flex justify-content-around flex-column flex-sm-row align-items-stretch align-items-sm-center">
                <div className="m-2">
                    <div className="d-flex justify-content-start flex-column flex-sm-row align-items-stretch align-items-sm-center">
                        <label htmlFor="frases" className="m-0"><a href="/static/fel-reglas-y-validaciones-v1-5.pdf" target="_blank">Frases </a><small>Referirse a su asesor de infile.</small></label>
                    </div>
                </div>
            </div>
            <FieldArray
                name="frases"
                disabled={view}
                component={renderFrases}
                type="text"
                className="form-control"
            />

            <div className="row-fluid my-2">
                <div className="d-flex justify-content-center flex-column flex-sm-row align-items-stretch align-items-sm-center">
                    <Link className="btn btn-secondary my-1 mx-2" to="/financial_entity">{!view ? ("Cancelar") : ("Regresar")}</Link>
                    {!view && (<button type="submit" className="btn btn-primary my-1 mx-2">Guardar</button>)}
                </div>
            </div>
        </form>
    )
};

EntityForm = reduxForm({
   form: 'entity',
   validate: data => {
        return validate(data, {
            'usuario_fel': validators.exists()('Campo requerido.'),
            'afiliacion_fel': validators.exists()('Campo requerido.'),
            'nit_fel': validators.exists()('Campo requerido.'),
            'login_key': validators.exists()('Campo requerido.'),
            'certification_key': validators.exists()('Campo requerido.'),
       })
   }
})(EntityForm);

export default EntityForm
