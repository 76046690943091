import React from 'react';
import { api } from "api";
import { Field, reduxForm, FieldArray } from 'redux-form';
import { renderField, renderSearchSelect } from 'Utils/renderField/renderField';
import './services.css';

let items = []
let persons = []
let options = []

// Load options to select a service category in the renderSearchSelect field

export const getOptions = (search)  =>{
    return api.get("service_categories",{search}).catch((error) => {})
        .then((data) => {
            if (data){
                options = [];
                data.results.forEach(x=>{
                    options.push({value: x.id, label: x.name})
                })
                return options
            }
            else{
                return []
            }
        })
        .catch(e => {
            return []
        })
}

// Load options to select a new item to add to a package in the renderSearchSelect field

export const getItems = (search)  =>{
    return api.get("services",{search}).catch((error) => {})
        .then((data) => {
            if (data){
                items = [];
                data.results.forEach(x=>{
                    items.push({value: x.id, label: x.name, code: x.code})
                })
                return items
            }
            else{
                return []
            }
        })
        .catch(e => {
            return []
        })
}

export const getUsers = (search)  =>{
    return api.get("users",{search}).catch((error) => {})
        .then((data) => {
            if (data){
                persons = [];
                data.results.forEach(x=>{
                    persons.push({value: x.id, label: x.first_name})
                })
                return persons
            }
            else{
                return []
            }
        })
        .catch(e => {
            return []
        })
}

// Load financial_entities
let entities=[];
export const getEntity = (search)  =>{
    return api.get("financial_entities", {search}).catch((error) => {})
        .then((data) => {
            if (data){
                entities = [];
                data.results.forEach(x=>{
                    entities.push({value: x.id, label: x.usuario_fel});
                })

                return entities;
            }
            else{
                return []
            }
        })
        .catch(e => {
            return []
        })
}

/* Agregado por (Ricky) */
export const renderEntityRow =({ fields, meta: {error, submitFailed}, edit, view}) => (
    <React.Fragment>
        {
            fields.map((field, index) => (
                <div className="package-item-row pl-md-3" key={index}>
                    <div className="package-item-c d-inline-flex justify-content-center flex-sm-row align-items-stretch align-items-sm-center">
                        <Field
                            name={`${field}.financial_entity`}
                            valueKey="value"
                            labelKey="label"
                            label="name"
                            component={renderSearchSelect}
                            loadOptions={getEntity}
                            className="no-border"
                            disabled={view}
                        />
                    </div>

                    <div className="package-item-c d-inline-flex justify-content-center flex-sm-row align-items-stretch align-items-sm-center">

                    </div>

                    {
                        !view &&
                            <div className="package-small-item-c d-inline-flex justify-content-center">
                                <img className="action-img delete-button-r2" src={require("../../../../assets/img/icons/delete-icon.png")} onClick={() => fields.remove(index)}  alt="Eliminar" title="eliminar" />
                            </div>
                    }
                </div>
                )
            )
        }
        {/* row for the add button */}
        {
            !view &&
                <div className="package-item-row pl-md-3 no-border d-flex justify-content-end">
                    <div className="package-small-item-c d-inline-flex justify-content-center">
                        <img onClick={() => fields.push()} className="action-img add-button-r2" src={require("../../../../assets/img/icons/add-icon.png")} alt="Agregar" title="agregar"/>
                    </div>
                </div>
        }
        {
            submitFailed && (
                <div className="invalid-feedback d-flex justify-content-center">
                    {error}
                </div>
            )
        }
    </React.Fragment>
)

export const renderCommissionRow =({ fields, meta: {error, submitFailed}, edit, view}) => (
    <React.Fragment>
        {
            fields.map((field, index) => (
                <div className="package-item-row pl-md-3" key={index}>
                    <div className="package-item-c d-inline-flex justify-content-center flex-sm-row align-items-stretch align-items-sm-center">
                        <Field
                            name={`${field}.user`}
                            valueKey="value"
                            labelKey="label"
                            label="name"
                            component={renderSearchSelect}
                            loadOptions={getUsers}
                            className="no-border"
                            disabled={view}
                        />
                    </div>
                    <div className="package-item-c d-inline-flex justify-content-center flex-sm-row align-items-stretch align-items-sm-center">
                        <Field name={`${field}.commission`} disabled={view} component={renderField} type="text" className="form-control"/>
                    </div>
                    {
                        !view &&
                            <div className="package-small-item-c d-inline-flex justify-content-center">
                                <img className="action-img delete-item-package" src={require("../../../../assets/img/icons/delete-icon.png")} onClick={() => fields.remove(index)}  alt="Eliminar" />
                            </div>
                    }
                </div>
                )
            )
        }
        {/* row for the add button */}
        {
            !view &&
                <div className="package-item-row pl-md-3 no-border d-flex justify-content-end">
                    <div className="package-small-item-c d-inline-flex justify-content-center">
                        <img onClick={() => fields.push()} className="action-img add" src={require("../../../../assets/img/icons/add-icon.png")} alt="Agregar" />
                    </div>
                </div>
        }
        {
            submitFailed && (
                <div className="invalid-feedback d-flex justify-content-center">
                    {error}
                </div>
            )
        }
    </React.Fragment>
)

export const renderItemRow =({ fields, meta: {error, submitFailed}, edit, view}) => (
    <React.Fragment>
        {
            fields.map((field, index) => (
                <div className="package-item-row pl-md-3" key={index}>
                    <div className="package-item d-inline-flex justify-content-center flex-sm-row align-items-stretch align-items-sm-center">
                        <Field
                            name={`${field}.item`}
                            valueKey="value"
                            labelKey="label"
                            label="name"
                            component={renderSearchSelect}
                            loadOptions={getItems}
                            className="no-border"
                            disabled={ view }
                        />
                    </div>
                    <div className="package-item d-inline-flex justify-content-center flex-sm-row align-items-stretch align-items-sm-center">
                        <Field
                            name={`${field}.item`}
                            valueKey="value"
                            labelKey="code"
                            label="name"
                            component={renderSearchSelect}
                            loadOptions={getItems}
                            className="no-border"
                            disabled={ view }
                        />
                    </div>
                    <div className="package-small-item d-inline-flex justify-content-center flex-sm-row align-items-stretch align-items-sm-center">
                        <Field name={`${field}.quantity`} disabled={ view } component={renderField}  type="text" addClass="w-75 m-auto quantity-input"/>
                    </div>
                    {
                        !view &&
                            <div className="package-small-item d-inline-flex justify-content-center">
                                <img className="action-img delete-item-package" src={require("../../../../assets/img/icons/delete-icon.png")} onClick={() => fields.remove(index)}  alt="Eliminar" />
                            </div>
                    }
                </div>
                )
            )
        }
        {/* row for the add button */}
        {
            !view &&
                <div className="package-item-row pl-md-3 no-border d-flex justify-content-end">
                    <div className="package-small-item d-inline-flex justify-content-center">
                        <img onClick={() => fields.push({quantity:1 })} className="action-img add" src={require("../../../../assets/img/icons/add-icon.png")} alt="Agregar" />
                    </div>
                </div>
        }
        {
            submitFailed && (
                <div className="invalid-feedback d-flex justify-content-center">
                    {error}
                </div>
            )
        }
    </React.Fragment>
)

export const renderCategoryPrices = ({ fields, meta: {error, submitFailed}, edit, view}) => (
    <div>
        {
            fields.map((categoryPrice, index) => (
                <div key={index} className="category-prices px-md-5 mt-2">
                    <div className=" row-fluid d-flex justify-content-between flex-column flex-sm-row align-items-stretch align-items-sm-center">
                        <div className="m-0">
                            <div className="category-label-width d-flex justify-content-start flex-column flex-sm-row align-items-stretch align-items-sm-center">
                                <label htmlFor="category-name" className="m-0">{(fields.get(index).referent_category) ? fields.get(index).referent_category.name : fields.get(index).name}</label>
                            </div>
                        </div>
                        <div className="line-price mr-5 d-flex justify-content-start flex-column flex-sm-row align-items-stretch align-items-sm-center w-100">
                            {/* This empty div is important to show the line among each category-prices row  */}
                        </div>
                    </div>
                    <div className="category-prices-labels row-fluid d-flex justify-content-between flex-column flex-sm-row align-items-stretch align-items-sm-center">
                        <div className="m-0">
                            <div className="d-flex justify-content-start flex-column flex-sm-row align-items-stretch align-items-sm-center">
                                <label htmlFor={`${categoryPrice}.price`} className="mb-1">Precio de {(fields.get(index).referent_category) ? fields.get(index).referent_category.name : fields.get(index).name}</label>
                            </div>
                            <div className="d-flex justify-content-start flex-column flex-sm-row align-items-stretch align-items-sm-center">
                                <Field name={`${categoryPrice}.price`} disabled={view} component={renderField} type="text" className="form-control"/>
                            </div>
                        </div>
                        <div className="m-0 ">
                            <div className="d-flex justify-content-start flex-column flex-sm-row align-items-stretch align-items-sm-center">
                                <label htmlFor={`${categoryPrice}.payback_1`} className="mb-1">Payback médico</label>
                            </div>
                            <div className="d-flex justify-content-start flex-column flex-sm-row align-items-stretch align-items-sm-center">
                                <Field name={`${categoryPrice}.payback_1`} disabled={view} component={renderField} type="text" className="form-control"/>
                            </div>
                        </div>
                        <div className="m-0 ">
                            <div className="d-flex justify-content-start flex-column flex-sm-row align-items-stretch align-items-sm-center">
                                <label htmlFor={`${categoryPrice}.payback_2`} className="mb-1">Payback trabajador social</label>
                            </div>
                            <div className="d-flex justify-content-start flex-column flex-sm-row align-items-stretch align-items-sm-center">
                                <Field name={`${categoryPrice}.payback_2`} disabled={view} component={renderField} type="text" className="form-control"/>
                            </div>
                        </div>
                    </div>
                </div>
                )
            )
        }
    </div>
);
