import React, { Component } from 'react';
import '../sales/payment.css';
import Factura from '../sales/Factura';
import { PrintContainer, actions as printActions } from "Utils/Print";
import LoadMask from "../../components/Utils/LoadMask/LoadMaskBill";

import MyModal from '../Utils/myModal';
import Form from './ModalConfirm';

class ViewBill extends Component {
    componentWillUnmount() {
        this.props.resetStore();
    }
    componentWillMount = () => {
        if(this.props.match.params.id){
            this.props.viewOneSale(this.props.match.params.id);
        }
    }

    componentDidMount = () => {
        if(window.location.href.includes("print")){
            setTimeout(() => {
                printActions.print('facturas');
            }, 500);
        }else if(window.location.href.includes("cancel")){
            /*
            setTimeout(() => {
                this.props.cancelBill(this.props.match.params.id);
            }, 500);
            */
        }
    }

    render(){
        const { productsInCarOrdered, cancel_mask } = this.props;
        return(
            <div className="content-with-tabs">
                <LoadMask loading={cancel_mask} dark blur>
                <div className="d-inline-flex w-100">
                    <div className="row-fluid w-50 tab-background-blue">
                        <a href="/#/oppening-closing" className="d-flex justify-content-center align-items-center tab-active tab-br-tr">
                            VENTA
                        </a>
                    </div>
                    <div className="row-fluid w-50 tab-background">
                        <a href="/#/oppening-closing/box" className="d-flex justify-content-center align-items-center tab tab-br-bl">
                            CAJA
                        </a>
                    </div>
                </div>

                <div className="table-decoration w-40 h-100 justify-content-center mx-5 mt-4 mb-3 d-flex flex-column sales-services no-print">

                    <div className="d-flex justify-content-around align-items-center title-print-view p-4">
                        <button type="button" className="btn btn-sm btn-success"  onClick={(e) => {
                                e.preventDefault();
                                printActions.print('facturas');
                            }}>Imprimir</button>
                        <a href="/#/oppening-closing/box" className="btn btn-primary btn-sm">Regresar</a>
                        {(productsInCarOrdered.length > 0 && productsInCarOrdered[0].dataBilling.fel_cancel_status !== 3) &&
                        <React.Fragment>
                        <MyModal isOpen={false}
                            title="Anulación de factura"
                            formId="formulario_anulacion"
                            textButton="Anular"
                            classToggleButton="btn btn-danger btn-sm"
                            body={
                                    <Form onSubmit={this.props.cancelBill} movement_id={this.props.match.params.id}/>
                            }
                        />
                    </React.Fragment>
                        }
                    </div>


                    <div className="d-flex flex-column p-5 fac-content">
                        <PrintContainer name='facturas' className="impresion-venta">
                            {productsInCarOrdered.map((factura, i) =>
                                <Factura
                                    key={i}
                                    factura={factura}
                                />
                            )}
                        </PrintContainer>
                    </div>
                </div>
                </LoadMask>
            </div>
        );
    }
}

export default ViewBill;
