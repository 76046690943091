import { connect } from 'react-redux';
import ReferentCategory from './ReferentCategoryCrear';
import { actions } from '../../../redux/modules/referentCategory';
import {resetStore} from "../../../redux/modules/login";

const ms2p = state => ({
    ...state.referentCategory,
});

const md2p = { ...actions, resetStore };

export default connect(ms2p, md2p)(ReferentCategory);
