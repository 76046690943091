import _ from 'lodash';
const url = 'services';
import { api } from "api";
import Swal from 'sweetalert2';
import { push } from 'react-router-redux';
import { handleActions } from 'redux-actions';
import { reset, initialize as initializeForm, change as changeForm} from 'redux-form';
import { actions as printActions } from "../../common/components/Utils/Print";
import { v4 as uuidv4 } from 'uuid';

const LOADER_SERVICE = 'LOADER_SERVICE';
const SET_DATA_SERVICE = 'SET_DATA_SERVICE';
const SET_PAGE_SERVICE = 'SET_PAGE_SERVICE';
const SET_UPDATE_DATA_SERVICE ='SET_UPDATE_DATA_SERVICE';
const SET_BUSCADOR_SERVICE = 'SET_BUSCADOR_SERVICE';
const SET_FILTRO_SERVICE = 'SET_FILTRO_SERVICE';

const SET_CATEGORY_PRICES = 'SET_CATEGORY_PRICES';
const ADD_PRODUCT_TO_CAR = 'ADD_PRODUCT_TO_CART';
const REMOVE_PRODUCT_TO_CAR = 'REMOVE_PRODUCT_TO_CAR';
const UP_DOWN_CANT = 'UP_DOWN_CANT';
const TOTAL_CALCULATE = 'TOTAL_CALCULATE';
const SET_PATIENT_INFO = 'SET_PATIENT_INFO';
const APPLY_DISCOUNT = 'APPLY_DISCOUNT';
const APPLY_DISCOUNT_VALUE = 'APPLY_DISCOUNT_VALUE';

const SET_CARD_LIST = 'SET_CARD_LIST';
const SET_CARD_PAYMENT = 'SET_CARD_PAYMENT';

const SET_DUES_LIST = 'SET_DUES_LIST';
const SET_DUE_PAYMENT = 'SET_DUE_PAYMENT';

const GROUP_FACTURE = 'GROUP_FACTURE';

const ADD_PRODUCT_COUPON = 'ADD_PRODUCT_COUPON';
const REMOVE_PRODUCT_COUPON = 'REMOVE_PRODUCT_COUPON';


const TOGGLE_CONF_COUPON_MODAL = 'TOGGLE_CONF_COUPON_MODAL';

const SET_BILLING_EMAIL = 'SET_BILLING_EMAIL';
const SET_INTIAIL_EXTRA_AMOUNT_SALES = 'SET_INTIAIL_EXTRA_AMOUNT_SALES';

// Para los eventos de cancelar
const CANCEL_SALE_2 = 'CANCEL_SALE_2';
const CANCEL_SALE_1 = 'CANCEL_SALE_1';
const RUTA_ANTERIOR = 'RUTA_ANTERIOR';

const RECALCULATE_COUPON = 'RECALCULATE_COUPON';

const SET_CASH = 'SET_CASH';
const SET_TOTAL_DIF = 'SET_TOTAL_DIF';

const SET_CHECK_CASHBOX = 'SET_CHECK_CASHBOX';

const UPDATE_FACTURAS = 'UPDATE_FACTURAS';

const CONFIRM_DATA_BILLING = 'CONFIRM_DATA_BILLING';
const SET_DATA_SALE = 'SET_DATA_SALE';

const SET_OPEN_ACCOUNTS = 'SET_OPEN_ACCOUNTS';

const UPP_SALE_INFO = 'UPP_SALE_INFO';
const BLANK_SERVICE_INFO = 'BLANK_SERVICE_INFO';

const SET_RETENTION = 'SET_RETENTION'
const SET_BILL_MASK = 'SET_BILL_MASK';

const SET_DATA_PAYMENTS = 'SET_DATA_PAYMENTS';
const ADD_PAYMENT_DESCRIPTION = 'ADD_PAYMENT_DESCRIPTION';

const FORCE_UPDATE = 'FORCE_UPDATE';

const SET_ALL_DATA_SALE = 'SET_ALL_DATA_SALE';

const SET_DEFAULT_ENTITY = 'SET_DEFAULT_ENTITY';
const COPY_DATA_SALE = 'COPY_DATA_SALE';

const SET_PAGE_OPEN_ACCOUNT = 'SET_PAGE_OPEN_ACCOUNT';
const LOADER_OPEN_ACCOUNT = 'LOADER_OPEN_ACCOUNT';
const SET_BUSCADOR_OPEN_ACCOUNT = 'SET_BUSCADOR_OPEN_ACCOUNT';

const SET_SEARCH_SERVICE_IN_SALE = 'SET_SEARCH_SERVICE_IN_SALE';
const SET_IS_OPEN_ACCOUNT = 'SET_IS_OPEN_ACCOUNT';
const SET_ID_ACCOUNT = 'SET_ID_ACCOUNT';

const SET_ALL_DATA_SALE_NO_PATIENT = 'SET_ALL_DATA_SALE_NO_PATIENT';
const UPDATE_PRODUCT_TO_CAR_SALE = 'UPDATE_PRODUCT_TO_CAR_SALE';

const SET_CANT = 'SET_CANT';
const SET_ACCOUNT_REFERENT_FILTER = 'SET_ACCOUNT_REFERENT_FILTER';

export const isEmpty = value => value === undefined || value === null || value === '' || value === NaN;

const groupBy = (xs, f) => {
    return xs.reduce((r, v, i, a, k = f(v)) => ((r[k] || (r[k] = [])).push(v), r), {});
}

const findNit = (nit) => (dispatch) => {
    const params = { nit };
    api.get(`${url}/findNit`, params).catch((error) => {
    }).then((data) => {
        if(data){

        }
    })
}

const cancelDataPayment = (index, tabName) => (dispatch, getStore) => {
    let facturas = _.cloneDeep(getStore().sales.productsInCarOrdered);
    facturas.forEach((factura, i) => {
        if(index === i){
            factura.dataPayments = {

                                        card_payments:[],
                                        due_payments: [],
                                        deposit_payments: [],
                                        cash: 0,
                                        cards_total: 0,
                                        dues_total: 0,
                                        deposit_total: 0,

                                        index: index,
                                        type: tabName, //acá se determina si es venta o por cobrar...


                                        retention_amount: 0,
                                        retention: false,

                                    };

            //add
            factura.calculate.resto = parseFloat(factura.calculate.total).toFixed(2);
            factura.calculate.totalLabel = parseFloat((factura.calculate.total).toFixed(2)) + factura.calculate.descuento;
            //add...

            dispatch(setRetention(index,false));
        }
    });

    dispatch({type:UPDATE_FACTURAS, facturas });
}

const totalDif = (index, cantidad, tipo) => (dispatch, getStore) => {
    let facturas = _.cloneDeep(getStore().sales.productsInCarOrdered);
    let isValid = false;

    facturas.forEach((factura, i) => {
        if(index === i){
            //const total = factura.calculate.totalLabel;
            //const total = factura.calculate.total_sin_descuento - factura.calculate.pagado;
            const total = factura.calculate.total_sin_descuento - factura.calculate.pagado - factura.dataPayments.retention_amount;

            const efectivo = (tipo === 'efectivo') ? cantidad : factura.dataPayments.cash;
            const tarjeta = (tipo === 'tarjeta') ? cantidad : factura.dataPayments.cards_total;
            const cuotas = (tipo === 'cuota') ?  cantidad : factura.dataPayments.dues_total;
            const depositos = (tipo === 'deposito') ?  cantidad : factura.dataPayments.deposit_total;

            const totalPagado = parseFloat(efectivo) + parseFloat(tarjeta) + parseFloat(cuotas) + parseFloat(depositos);

            const total_dif = total - parseFloat((totalPagado).toFixed(2));
            if(parseFloat(total_dif) >= 0){
                factura.calculate.resto = parseFloat(total_dif).toFixed(2);
                isValid = true;
                //return true;
            }else{
                //factura.calculate.resto = 0;
                //return false;
            }
        }
    });

    dispatch({type:UPDATE_FACTURAS, facturas });
    return isValid;
}

const setRetention = (index, value) => (dispatch, getStore) => {

    let facturas = _.cloneDeep(getStore().sales.productsInCarOrdered);
    //console.log("FACTURAS RETENCIÓN: ", facturas);
    let sobre_pago = false;
    facturas.forEach((factura, i) => {
        if(index === i){
            if(value){
                factura.dataPayments.retention = value;
                const ntotal = parseFloat((parseFloat(factura.calculate.totalLabel) - parseFloat(factura.calculate.descuento)).toFixed(2));
                const retencion = parseFloat(((ntotal / 1.12) * 0.05).toFixed(2));
                factura.dataPayments.retention_amount = retencion;

                //const total = (factura.calculate.total - factura.calculate.pagado)
                const total = factura.calculate.total;

                factura.calculate.total = total;

                const resto = (factura.calculate.total - factura.calculate.pagado) - retencion;
                const resta = resto - factura.dataPayments.cards_total - factura.dataPayments.dues_total - factura.dataPayments.deposit_total - factura.dataPayments.cash ;

                console.log("Total Label: ", factura.calculate.totalLabel, " Total: ", total, " retención: ", retencion);

                factura.calculate.totalLabel = parseFloat(parseFloat(total - retencion).toFixed(2));


                factura.calculate.resto = resta.toFixed(2);
                if(resta < 0){
                    sobre_pago = true;
                }
            }else{
                factura.dataPayments.retention = value;
                factura.dataPayments.retention_amount = 0;

                factura.calculate.totalLabel = parseFloat((factura.calculate.total).toFixed(2)) + factura.calculate.descuento;

                const resto = (factura.calculate.total - factura.calculate.pagado);
                const resta = resto - factura.dataPayments.cards_total - factura.dataPayments.dues_total - factura.dataPayments.deposit_total - factura.dataPayments.cash;
                factura.calculate.resto = resta.toFixed(2);
            }
        }
    });

    if(sobre_pago){
        Swal.fire(
            'El total en los metodos de pago sobrepasa el total a pagar',
            'Corrija',
            'question'
        )
    }else{
        dispatch({type:UPDATE_FACTURAS, facturas});
    }
}

const setCashPayment = (index, value) => (dispatch, getStore) => {
    const efectivo = isEmpty(value) ? 0 : parseFloat(value);
    const verificarCantidad = dispatch(totalDif(index, efectivo, 'efectivo'));

    let facturas = getStore().sales.productsInCarOrdered;
    facturas.forEach((factura, i) => {
        if(index === i){
            factura.dataPayments.cash = efectivo;
        }
    });

    dispatch({type:UPDATE_FACTURAS, facturas});
}

/**
 * Ésta función se utiliza para cambiar la bandera de la variable que indica si
 * se está regresando del mismo componente
 */
const setRutaAnterior = () => (dispatch) => {
    dispatch({type:RUTA_ANTERIOR, value: false});
}

const cancelSale1 = () => (dispatch) => {
    const patientInfo = {
        name : '',
        nit : '',
        referent: null,
        doctor: null,
    };

    dispatch({type:CANCEL_SALE_1, patientInfo});
    dispatch(initializeForm('patient', {}));
}

const cancelSale2 = () => (dispatch) => {
    Swal.fire({
        title: '¿Está seguro(a) que desea cancelar la venta?',
        type: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#aaa',
        confirmButtonText: 'Aceptar',
        cancelButtonText: 'Cancelar',
        reverseButtons: true
    }).then((result) => {
        if (result.value) {
            dispatch({type:RUTA_ANTERIOR, value: true});
            dispatch({type:CANCEL_SALE_2});
            dispatch(push('/oppening-closing'));
        }
    });
}

const validateNit = nit => (dispatch, getState) => {
    //dispatch({ type: LOADER_PATIENTS, cargando: true });
    const form = getState().form.frmBillingInformation || {};
    const data = form.values || {};
    data.name = undefined;
    dispatch(initializeForm('frmBillingInformation', data));
    const params = { nit };
    api.get(`patients/validateNit`, params).catch((error) => {
        Swal('Error', error.detail || 'Ha ocurrido un error, por favor vuelva a intentar.', 'error');
    }).then((response) => {
        if (response.detail) {
            const data1 = form.values || {};
            response.detail.nombre !== '' && (data1.name = response.detail.nombre);
            //response.detail.nombre === '' && delete data1.nit;
            response.detail.nombre === '' && delete data1.name;
            dispatch(initializeForm('frmBillingInformation', data1));
        }
    }).finally(() => { /* dispatch({ type: LOADER_PATIENTS, cargando: false }) */ });
};

const checkCashBox = (chargeOpenAccounts=false) => (dispatch, getStore) =>{
    const state = _.cloneDeep(getStore().sales)

    api.get("cash_box_op/checkCashBoxGeneral").catch((error) => {
        Swal(
            'Error',
            error.detail || 'Ha ocurrido un error, por favor vuelva a intentar.',
            'error'
        );
    }).then((data) => {
        if(data){
            let openCashBox = Object()
            if(data == "THERE_IS_NO_OPEN_CASHBOX"){
                openCashBox.isOpen = false;
                openCashBox.data = null;
            }else{
                openCashBox.isOpen = true;
                openCashBox.data = data;
            }
            dispatch({type: SET_CHECK_CASHBOX, openCashBox});

            if(chargeOpenAccounts){
                dispatch(getOpenAccounts(state.page_open_accounts));
            }
        }
    })
}

const confirmDataBilling = () => (dispatch, getStore) => {
    // data.Billing.option
    //  a -> Facturar con los datos del paciente
    //  b -> Ingreso de nuevos datos
    //  c -> CF

    let datosFacturacion = { name: '', nit: '', email: '', is_rental: false, tipo_documento: ''};
    const dataBilling = getStore().form.frmBillingInformation.values;
    if(dataBilling.option === 'a'){
        //Por paciente
        const patientInfo = getStore().sales.patientInfo;
        datosFacturacion.name = patientInfo.name;
        datosFacturacion.nit = dataBilling.documento_paciente; //patientInfo.nit;
        datosFacturacion.tipo_documento = dataBilling.tipo_documento;

    }else if(dataBilling.option === 'b'){
        //Por datos nuevos
        datosFacturacion.name = dataBilling.name;
        datosFacturacion.nit = dataBilling.nit;
        datosFacturacion.tipo_documento = dataBilling.tipo_documento

    }else if(dataBilling.option === 'c'){
        datosFacturacion.nit = 'CF'
        //agregado...
        const patientInfo = getStore().sales.patientInfo;
        datosFacturacion.name = patientInfo.name;
    }
    if(dataBilling.email != undefined) {
        datosFacturacion.email = dataBilling.email;
    }
    else {
        // Al no proporcionarse un email al cual enviar la factura electrónica
        // Se usará el del paciente
        api.get(`patients/${getStore().sales.patientInfo.patient.value}/get_email/`)
            .then(response => {
                console.log('El correo del paciente:', response);
                datosFacturacion.email = response.email;
            })
            .catch(error => {
                console.log(error);
            })
    }
    if(dataBilling.is_rental != undefined) {
        datosFacturacion.is_rental = dataBilling.is_rental;
    }

    if(datosFacturacion.is_rental == false && dataBilling.email != undefined) {
        // Debe actualizarse el correo del paciente si se proporciona un correo nuevo
        // Y se especifica que no se trata de un alquiler de sala o clínica
        const dataPatientInfo = getStore().sales.patientInfo;
        const data = {
            email: dataBilling.email
        }
        api.put(`patients/${dataPatientInfo.patient.value}/update_email/`, data)
            .then(response => {
                console.log(response);
            })
            .catch(error => {
                console.log(error);
            })
    }

    dispatch({type: CONFIRM_DATA_BILLING, datosFacturacion });

    dispatch({type:SET_SEARCH_SERVICE_IN_SALE});

    dispatch(push('/oppening-closing/payment'))

}

const retrievePatientEmail = () => (dispatch, getStore) => {
    const patient_id = getStore().sales.patientInfo.patient.value
    api.get(`patients/${patient_id}/get_email/`)
        .then(response => {
            if(response){
                dispatch({
                    type: SET_BILLING_EMAIL,
                    billing_email: response
                })
            }
        })
        .catch(error => {
            console.log('Error:', error);
        })
}

const openAccount = (code, redirect=true, redireccion=null) => (dispatch) => {
    if(redirect){
        Swal.fire({
            title: '¿Está seguro(a) que desea abrir la cuenta? Perdera los datos de la venta actual',
            type: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#aaa',
            confirmButtonText: 'Aceptar',
            cancelButtonText: 'Cancelar',
            reverseButtons: true
        }).then((result) => {
            if (result.value) {
                api.get("movements/openAccount", {code}).then((data) => {
                    try{
                        dispatch({type: SET_INTIAIL_EXTRA_AMOUNT_SALES, extra_amount_initial: data[0].extra_amount? data[0].extra_amount: 0});
                        dispatch(isOpenAccount(true)); //add Ricky

                        console.log("TIPO DE LA VARIABLE CODE: ", typeof code);

                        if(typeof code === "number"){ //add Ricky
                            dispatch(setIdAccount(code));
                        }

                        dispatch({type: SET_DATA_SALE, data});
                        dispatch({type: GROUP_FACTURE});
                        dispatch({type:RUTA_ANTERIOR, value: true});
                        dispatch(push('/oppening-closing/sales/'));
                    }catch(e){
                        console.error("Error in open account --> ", e);
                    }

                }).catch((error) => {
                    console.error("Error al obtener la venta ---> ", error);
                });
            }
        });
    }else{
        api.get("movements/openAccount", {code}).then((data) => {
            dispatch(isOpenAccount(true)); //add Ricky
            console.log(">> TIPO DE LA VARIABLE CODE: ", typeof code);

            if(typeof code === "number"){ //add Ricky
                dispatch(setIdAccount(code));
            }
            dispatch({type: SET_INTIAIL_EXTRA_AMOUNT_SALES, extra_amount_initial: data[0].extra_amount? data[0].extra_amount: 0});
            dispatch({type: SET_DATA_SALE, data});
            dispatch({type: GROUP_FACTURE});
            dispatch({type:RUTA_ANTERIOR, value: true});

            if(redireccion !== null){
                dispatch(push('/oppening-closing/sales/'));
            }else{
                dispatch(push('/oppening-closing/deposit/'));
            }
            //dispatch(push('/oppening-closing/deposit/'))

        }).catch((error) => {
            console.error("Error al obtener la venta");
        });
    }
}

const cancelOpenAccount = (code ,redirect=true,redireccion=null) => (dispatch) => {
    Swal.fire({
        title: '¿Está seguro(a) que desea anular la cuenta? Perdera los datos de la cuenta',
        type: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#aaa',
        confirmButtonText: 'Aceptar',
        cancelButtonText: 'Cancelar',
        reverseButtons: true
    }).then((result) => {
        if (result.value) {
            api.post("movements/cancelOpenAccount", {code}).then((data) => {
                Swal.fire(
                    '¡Exito!',
                    'La cuenta fue anulada correctamente',
                    'success'
                ).then(()=>{
                    dispatch(getOpenAccounts());
                });

            }).catch((error) => {
                Swal(
                    'Error',
                    error.detail || 'Ha ocurrido un error, por favor vuelva a intentar.',
                    'error'
                );
            });
        }
    });
}

const getOpenAccounts = (page = 1) => (dispatch, getStore) => {
    const data_sale = getStore().sales; 
    if(data_sale.openCashBox !== null && data_sale.openCashBox.data !== null){
    const oppeging_closing_box = data_sale.openCashBox.data.id;
    dispatch({type: LOADER_OPEN_ACCOUNT, charge_open_account: true});
    const search = data_sale.buscador_cuenta_abierta;    
    const accout_referent_filter = data_sale.accout_referent_filter
    //const filtro = store.roles.filtro_roles;
    let params = {page, search, oppeging_closing_box, accout_referent_filter };

    api.get("movements/getOpenAccounts", params).then((data) => {
        console.log("Data cuentas abiertas: ", data);
        dispatch({type: SET_OPEN_ACCOUNTS, data});
        dispatch({type: SET_PAGE_OPEN_ACCOUNT, page_open_accounts: page})
        dispatch({type: LOADER_OPEN_ACCOUNT, charge_open_account: false});
    }).catch((error) => {
        console.error("Error al obtener cuentas abiertas...");
    });

    }
}

const openAccountRegister = () => (dispatch, getStore) => {
    const patientInfo = getStore().sales.patientInfo; //ADD
    const data_sale = getStore().sales;
    const datosFacturacion = {nit: patientInfo.nit};
    const oppeging_closing_box = data_sale.openCashBox.data.id;
    const movement_type = 1;
    const concept = "venta";
    const movement = { oppeging_closing_box, movement_type, concept, patientInfo, sale_state: "open_account" }; //MOD

    //add ricky
    const id_account = data_sale.id_account;

    const data = { movement, datosFacturacion, facturas: data_sale.productsInCarOrdered, id_account } //MOD



    console.log("DATA OPEN ACCOUNT: ", data);
    data.facturas.forEach(factura => {
        factura.calculate.totalLabel = parseFloat(factura.calculate.totalLabel.toFixed(2));
    })
    api.post("movements/registerOpenAccount", data).then((data) => {

        Swal('Éxito', 'Se ha registrado la cuenta abierta.', 'success')
        .then(() => {
            dispatch(getOpenAccounts());
            dispatch(openAccount(data.id, false));
            dispatch(push('/oppening-closing/deposit/'))
        })
    }).catch((error) => {
        Swal(
            'Error',
             error.detail || 'Ha ocurrido un error, por favor vuelva a intentar.',
            'error'
        );
    });
}

const chargeFinishSale = (codigo_venta, attempts=false) => (dispatch, getStore) => {
    api.get("movements/getSaleInfo", { codigo_venta }).then((data) => {
        const { datosFacturacion } = getStore().sales;
        datosFacturacion.name = data[0].name;
        console.log("Dataaaa", datosFacturacion);
        dispatch({type: CONFIRM_DATA_BILLING, datosFacturacion });
        dispatch({type:SET_DATA_SALE, data});
        dispatch({type: GROUP_FACTURE});
        dispatch({type: COPY_DATA_SALE}); //add Ricky...

        Swal('Éxito', `Se ha registrado la venta${attempts ? ', pero no se pudo certificar luego de 15 intentos, por favor certifique manualmente.' : '.'}`, 'success')
        .then(() => {
            setTimeout(() => {
                printActions.print('facturas')
                //dispatch({type:BLANK_SERVICE_INFO});
                dispatch({type:SET_ALL_DATA_SALE}); //add Ricky...
                dispatch(push('/oppening-closing/view-sale'))

                /*
                setTimeout(() => {
                    dispatch({type:SET_ALL_DATA_SALE}); //add Ricky...
                  }, 500);
                */
              }, 1000);
        })
    }).catch((error) => {
        Swal(
            'Error',
             error.detail || 'Ha ocurrido un error, por favor vuelva a intentar.',
            'error'
        );
    });
}

const verifyFel = (codigo_venta, attempts=0) => (dispatch) => {
    //comentar para habilitar fel automático

    dispatch({ type: SET_BILL_MASK, bill_mask: false });
    dispatch(chargeFinishSale(codigo_venta));


    // DESCOMENTAR PARA HABILITAR FEL AUTOMÁTICO
    /*
    api.get("movements/verifyFel", {codigo_venta, attempts}).then((data) => {
        status = data.status_fel;
        console.log("data in verifyFel: ", data.status_fel);
        if(status === 'waiting'){
            setTimeout(() => {
                dispatch(verifyFel(codigo_venta, attempts + 1));
            }, 1000);
        }else if(status === 'finished'){
            dispatch({type:SET_BILL_MASK, bill_mask: false});
            dispatch(chargeFinishSale(codigo_venta));
        }else if(status === 'attempts'){
            dispatch({type:SET_BILL_MASK, bill_mask: false});
            dispatch(chargeFinishSale(codigo_venta, true));
        }else{
            dispatch({type:SET_BILL_MASK, bill_mask: false});
            Swal(
                'Error',
                 status,
                'error'
            ).then(()=>{
                //add Ricky...
                dispatch({type:SET_ALL_DATA_SALE});
                dispatch(push('/oppening-closing/box'));
            });
        }
    }).catch((error)=>{
        dispatch({type:SET_BILL_MASK, bill_mask: false});
        Swal(
            'Error',
             error.detail || 'Ha ocurrido un error, por favor vuelva a intentar.',
            'error'
        );
    });
    */
}

const finishSale = () => (dispatch, getStore) => { //FACTURAR
    const patientInfo = getStore().sales.patientInfo; //ADD
    const data_sale = getStore().sales;
    const datosFacturacion = data_sale.datosFacturacion;
    const oppeging_closing_box = data_sale.openCashBox.data.id;
    const movement_type = 1;
    const concept = "venta";
    //const movement = { oppeging_closing_box, movement_type, concept };
    const movement = { oppeging_closing_box, movement_type, concept, patientInfo, sale_state: "sale" }; //MOD
    //add ricky
    const id_account = data_sale.id_account;

    const data = { movement, datosFacturacion, facturas: data_sale.productsInCarOrdered, id_account, formDocumentos: datosFacturacion} //Mod

    console.log("DATA DE FACTURACIÓN: ", data);
    data.facturas.forEach(factura => {
        factura.calculate.totalLabel = parseFloat(factura.calculate.totalLabel.toFixed(2));
    })

    api.post("movements/registerOpenAccount", data).then((data) => {
        dispatch({type:SET_BILL_MASK, bill_mask: true});
        const codigo = data[0].codigo;
        api.get('movements/restoreCorrelativeNumber').then(
            ()=>{
                dispatch({ type: SET_BILL_MASK, bill_mask: false });
                setTimeout(() => {
                    //dispatch(verifyFel(codigo));

                    // Ésto se agregó                    
                    if(Array.isArray(data)){
                        console.log("final res in finish sale ::: ", data);                        
                        const mov_id = data[0].id
                        dispatch(push(`/report-movements/view-bill/${mov_id}/caja`));
                    }else{
                        dispatch(verifyFel(codigo));
                    }
                    // Ésto se agregó...
                }, 500);
            }
        ).catch((error)=>{
            Swal(
                'Error',
                 error.detail || 'Ha ocurrido un error, por favor vuelva a intentar.',
                'error'
            );
        })
    }).catch((error) => {
        Swal(
            'Error',
             error.detail || 'Ha ocurrido un error, por favor vuelva a intentar.',
            'error'
        );
    });
}

const addDeposit = () => (dispatch, getStore) => {
    const data_sale = getStore().sales;
    const facturas = data_sale.productsInCarOrdered;
    const oppeging_closing_box = data_sale.openCashBox.data.id;

    const codigo = facturas[0].dataBilling.code;

    api.post("movements/addPayment", {facturas, oppeging_closing_box}).then((data) => {
        Swal('Éxito', 'Deposito registrado correctamente.', 'success')
        .then(() => {
            dispatch(limpiarMetodosPago());
            //dispatch(push('/oppening-closing/sales/'));
            dispatch(openAccount(codigo, false, '/oppening-closing/sales/'));
        })
    }).catch((error) => {
        Swal(
            'Error',
             error.detail || 'Ha ocurrido un error, por favor vuelva a intentar.',
            'error'
        );
    });
}

const recalculateCoupon = () => (dispatch) => {
    dispatch({type: RECALCULATE_COUPON, data:null });
}

const setCouponCode = () => (dispatch, getStore) => {
    const listaProductos = getStore().sales.productsInCar;
    //Se comprueba que haya al menos un producto agregado a la venta
    if(listaProductos.length == 0){
        Swal.fire(
            '',
            'Debe agregar al menos un producto a la venta para agregar un cupón',
            'question'
        );
    }else{
        const code = getStore().form.frmModalCoupon.values.code;
        const index_key = getStore().form.frmModalCoupon.values.index; //add

        //Cuando no viene el index es Undefined...

        const params = { code };

        api.get('coupons/verifyCoupon', params).then((data) => {
            if(data){
                        let existe = false // Variable utilizada para controlar la existencia del cupón en la factura...
                        let index = 0; // Variable utilizada para almacenar el índice del producto/servicio al que corresonde el cupón
                        let isServiceInCar = false; // Variable utilizada para el controlar si el cupón utilizado corresponde
                                                    // a un producto/servicio que se está facturando
                        let precioServicio = 0; // Variable utilizada para almacenar el precio del servicio, por si fuera necesario
                                                // cacular un porcentaje del cupón a partir de su precio

                        let subTotal = 0;
                        let totalCoupons = 0; // Variable utilizada para almacenar el total de cupones correspondientes a un producto

                        const productsInCar = getStore().sales.productsInCar;
                        productsInCar.forEach((productos, key) => {
                            //if(data.service === productos.id){
                            if(data.service === productos.id || key === index_key){ //add
                                index = key;
                                isServiceInCar = true;

                                // Se verifica itera la lista de cupones del producto para verificar si ya está agregado
                                // a través de la variable booleana [existe]

                                productos.coupons.forEach(coupon => {
                                    if(coupon.code === data.code){
                                        existe = true;
                                    }
                                    totalCoupons += coupon.amount;
                                });

                                subTotal = productos.total;
                                precioServicio = productos.price; // Se almacena el precio del servicio, por si fuera necesario
                                                                  // cacular un porcentaje del cupón a partir de su precio
                                //financial_entity_id = productos.financial_entity;
                                //financial_entity_name = productos.financial_entity_name;
                            }
                        });

                        if(!isServiceInCar){ // Si el cupón no corresponde a un producto/servicio que se está facturando
                            Swal(
                                'Error',
                                'El cupón corresponde a un producto que no se está facturando',
                                'error'
                            );
                        }else if(existe){ // Sí el cupón ya está incluido
                            Swal.fire(
                                '¿El cupón ya está agregado a la factura?',
                                'Por favor verifique',
                                'question'
                            )
                        }else{
                            // Si existe una cantidad la almacenamos en la variable si no, se deja en cero
                            let cantidad = data.amount !== null ? data.amount : 0;

                            //Sí no existe un monto por descuento, etonces se calcula la cantidad equivalente al porcentaje
                            //respecto al precio de producto/servicio
                            if(data.amount === null){
                                const factor = (parseFloat(data.percentage) / 100).toFixed(2);
                                cantidad = (precioServicio * factor).toFixed(2);
                                data.amount = cantidad; //add
                            }

                            // Se calcula la diferencia entre el subtotal y el total a descontar
                            const resto = (subTotal - totalCoupons).toFixed(2);
                            //Si el total a descontar excede el monto, entonces se reemplaza la cantida del cupon por el del resto
                            if(parseFloat(cantidad) > parseFloat(resto)){
                                cantidad = resto;
                                //data.amount = resto;
                                data.amount_label = resto;
                            }else{
                                //data.amount = cantidad; /* se guarda la cantidad en la propiedad [amount] del objeto coupon
                                data.amount_label = cantidad;
                            }

                            const data_coupon = {coupon: data, index: index}; //Creamos un objeto con los datos correspondientes al reducer
                            dispatch({type: ADD_PRODUCT_COUPON, data: data_coupon}); // Se ejecuta el reducer
                            dispatch(totalCalculate()); //Se vuelve a calcular el total
                            dispatch({type: GROUP_FACTURE});
                        }
            }
        }).catch((error) => {

            Swal(
                'Error',
                error.body.detail || 'Ha ocurrido un error, por favor vuelva a intentar.',
                'error'
            );
        });
    }
}

const depositPayment = () => (dispatch, getStore) => {
    const formData = getStore().form.depositForm.values;
    const deposit_payments = formData.deposit_payments;
    const index = formData.index;
    let depositPayments = []; //add

    let total = 0;
    deposit_payments.forEach(element => {
        total += parseFloat(element.amount);
        let obj = element; //add
        obj.quantity = element.amount; //add
        obj.document_number = element.document_number; //add
        obj.uuid = uuidv4(), //agregado por uuid

        depositPayments.push(obj);//add
    });
    total = total.toFixed(2);

    const verificarCantidad = dispatch(totalDif(index, total, 'deposito'));

    if(verificarCantidad){
        let facturas = getStore().sales.productsInCarOrdered;
        facturas.forEach((factura, i) => {
            if(index === i){
                factura.dataPayments.deposit_payments = depositPayments; //add
                factura.dataPayments.deposit_total = total;
            }
        });

        dispatch({type:UPDATE_FACTURAS, facturas});
        return true;
    }else{
        Swal.fire(
            'La cantidad que paga excede el total de la venta',
            'Por favor verifique',
            'question'
        )
        return false;
    }
}


/**
 * Función utilizada para obtener los registros de las tarjetas y sus cuotas.
 */
const getDues = () => (dispatch) => {
    api.get('dues').catch((error) => {
        dispatch({type: LOADER_SERVICE, cargando: false});
        Swal(
            'Error',
            error.detail || 'Ha ocurrido un error, por favor vuelva a intentar.',
            'error'
        );
    }).then((data) => {
        if(data){

            let dues_list = [];
            data.results.forEach(element => {
                //dues_list.push({ value: element.id, label: element.name, due_detail: element.due_detail });
                dues_list.push({ value: element.id, label: element.due_description, due_detail: element.due_detail });
            });

            dispatch({type: SET_DUES_LIST, dues_list});
        }
    })
}

const duesPayment = () => (dispatch, getStore) => {
    const formData = getStore().form.duesForm.values;
    const due_payments = formData.due_payments;
    const index = formData.index;
    const duePayments = []; //agregado
    let monto_extra_factura = getStore().sales.extra_amount_initial;
    if (monto_extra_factura == null){monto_extra_factura=0}
    let total = 0;
    let monto_extra=0;
    due_payments.forEach(element => {
        total += parseFloat(element.amount);
        let recargo=0;
        element.dues.due_detail.forEach(cuota =>{
            if(element.due_detail.value==cuota.id){
              // se agrego el 1.12 de iva
              recargo= (element.amount * (cuota.custumer_rate / 100 ))
            }
        })
        monto_extra += parseFloat(recargo.toFixed(2)) // recargo extra total
        let obj = element;
        obj.quantity = element.amount;
        obj.monto_extra= parseFloat(recargo.toFixed(2)); // recargo extra por cuota
        obj.due = element.dues.value;
        //obj.dues_quantity = element.due_detail.value;
        obj.dues_quantity = element.due_detail.label; //MOD
        obj.uuid = uuidv4();
        duePayments.push(obj);
    });
    total = total.toFixed(2);
    if(due_payments.length ==0 ){
        monto_extra_factura = 0
    }
    const verificarCantidad = dispatch(totalDif(index, total, 'cuota'));

    if(verificarCantidad){
        let facturas = getStore().sales.productsInCarOrdered;
        facturas.forEach((factura, i) => {
            if(index === i){
                //factura.dataPayments.due_payments = due_payments;
                factura.dataPayments.due_payments = duePayments; //agregado
                factura.dataPayments.dues_total = total;
                if( due_payments.length > 0){
                    factura.calculate.monto_extra = parseFloat(monto_extra_factura) + monto_extra
                }
                else{
                    factura.calculate.monto_extra = parseFloat(monto_extra_factura)
                }
            }
        });

        dispatch({type:UPDATE_FACTURAS, facturas});
        return true;
    }else{
        Swal.fire(
            'La cantidad que paga excede el total de la venta',
            'Por favor verifique',
            'question'
        )

        return false;
    }
}

const getCards = () => (dispatch) => {
    api.get('cards').catch((error) => {
        dispatch({type: LOADER_SERVICE, cargando: false});
        Swal(
            'Error',
            error.detail || 'Ha ocurrido un error, por favor vuelva a intentar.',
            'error'
        );
    }).then((data) => {
        if(data){
            let card_list = [];
            data.results.forEach(element => {
                //card_list.push({ value: element.id, label: element.name });
                card_list.push({ value: element.id, label: element.card_description });
            });

            dispatch({type: SET_CARD_LIST, card_list});
        }
    })
}

const cardsPayment = () => (dispatch, getStore) => {
    const formData = getStore().form.cardsForm.values;
    const card_payments = formData.card_payments;
    const index = formData.index;
    let cardPayments = []; //add

    let total = 0;
    card_payments.forEach(element => {
        total += parseFloat(element.amount);
        let obj = element; //add
        obj.card = element.cards.value; //add
        obj.quantity = element.amount; //add
        obj.uuid = uuidv4(); //add por UUID

        cardPayments.push(obj);//add
    });
    total = total.toFixed(2);

    const verificarCantidad = dispatch(totalDif(index, total, 'tarjeta'));

    if(verificarCantidad){
        let facturas = getStore().sales.productsInCarOrdered;
        facturas.forEach((factura, i) => {
            if(index === i){
                //factura.dataPayments.card_payments = card_payments;
                factura.dataPayments.card_payments = cardPayments; //add
                factura.dataPayments.cards_total = total;
            }
        });

        dispatch({type:UPDATE_FACTURAS, facturas});
        return true;
    }else{
        Swal.fire(
            'La cantidad que paga excede el total de la venta',
            'Por favor verifique',
            'question'
        )
        return false;
    }
}

const noDataPatient = () => (dispatch) => {
    dispatch(push('/oppening-closing'));
}

const dataPatient = () => (dispatch, getStore) => {
    const formData = getStore().form.patient.values;

    name = formData.patient.label;
    formData.name = name;

    if(formData.referent === null){
        formData.referent = "default";
    }

    dispatch({type: SET_PATIENT_INFO, formData});
    dispatch(push('/oppening-closing/sales/'));
}

const applyDiscount = (e, index) => (dispatch) => {
    if(e.target.value !== ''){
        //const value = parseFloat(e.target.value);
        const value = parseFloat(e.target.value.trim().replace('%',''));
        const data = { index: index, value: value};
        dispatch({type: APPLY_DISCOUNT, data});
        dispatch(totalCalculate());
        dispatch(recalculateCoupon());
        dispatch({type: GROUP_FACTURE});
    }else{
        const data = { index: index, value: 0}
        dispatch({type: APPLY_DISCOUNT, data})
        dispatch(totalCalculate());
        dispatch(recalculateCoupon());
        dispatch({type: GROUP_FACTURE});
    }
}

const applyDiscountValue = (e, index) => (dispatch) => {
    if(e.target.value !== ''){
        const value = parseFloat(e.target.value.trim().replace('Q',''));
        const data = { index: index, value: value};
        dispatch({type: APPLY_DISCOUNT_VALUE, data});
        dispatch(totalCalculate());
        dispatch(recalculateCoupon());
        dispatch({type: GROUP_FACTURE});
    }else{
        const data = { index: index, value: 0}
        dispatch({type: APPLY_DISCOUNT_VALUE, data})
        dispatch(totalCalculate());
        dispatch(recalculateCoupon());
        dispatch({type: GROUP_FACTURE});
    }

}

const listar = (page = 1) =>  (dispatch, getStore) => {
    const Data = getStore().sales;

    let idReferentCategory = 0;
    if(!!Data.patientInfo && Data.patientInfo.referent !== "default"){
        if(!!Data.patientInfo.referent)
        idReferentCategory = Data.patientInfo.referent.idReferentCategory;
    }else{
        console.error("No se reconoció un referente");
    }

    dispatch({type: LOADER_SERVICE, cargando: true});
    const store = getStore();
    //const search = store.services.buscador;
    const search = store.sales.buscador;

    const filtro = store.services.filtro_services;
    let params = {page, search, idReferentCategory };

    if(filtro){
        params[''] = filtro
    }

    api.get(`${url}/getServiceForSale`, params).catch((error) => {
        dispatch({type: LOADER_SERVICE, cargando: false});
        Swal(
            'Error',
            error.detail || 'Ha ocurrido un error, por favor vuelva a intentar.',
            'error'
        );
    }).then((data) => {
        const search2 = getStore().sales.buscador;
        if(search2 == search){
            if(data){
                dispatch({type: SET_DATA_SERVICE, data});
                dispatch({type: SET_PAGE_SERVICE, page});
            }
            dispatch({type: LOADER_SERVICE, cargando: false});
            dispatch({type: SET_UPDATE_DATA_SERVICE, updateData: {}});
        }
    })
};

const search = (search) => (dispatch, getStore) => {
    dispatch({type: SET_BUSCADOR_SERVICE, buscador: search});
    dispatch(listar(1));
};

const filtro = (filtro) => (dispatch, getStore) => {
    if (filtro == 0){
        filtro = null
    }
    dispatch({type: SET_FILTRO_SERVICE, filtro_services: filtro});
    dispatch(listar(1));
};

const searchOpenAccounts = (search) => (dispatch, getStore) => {
    dispatch({type: SET_BUSCADOR_OPEN_ACCOUNT, buscador_cuenta_abierta: search});
    dispatch(getOpenAccounts(1));
};

/**
 * This function use to add product to cart when is necesary select the financial entity...
 */
const addProductToCartDiff = () => (dispatch, getStore) => {
    document.getElementById('search_change').focus(); //Para devolver el foco al input de búsqueda
    const formData = _.cloneDeep(getStore().form.frmInter.values);
    let factura = formData.financial_entity.membership;

    const products_list = _.cloneDeep(getStore().sales.productsInCar);
    const product = products_list.find(item => item.id === formData.id);
    const index = products_list.findIndex(item => item.id === formData.id);

    if(product && (index !== -1)){
        products_list[index].cant += 1;
        products_list[index].total = products_list[index].cant * products_list[index].price;
    }else{
        products_list.push({
            id: formData.id,
            name: formData.name,
            cant: 1,
            price:formData.price,
            discount_authorization: false,
            descuento_limite: formData.discount,
            descuento: 0,
            descuentoValor: 0,
            total:formData.price,
            totalStatic: formData.price,
            financial_entity:formData.financial_entity.value,
            financial_entity_name: formData.financial_entity.label,
            cupon: false,
            cupon_code: null,
            coupons: [],
            factura,
            correlativo:null,
            movement_id:0,
            code:'',
            //medic: null,//add
            //patient: null, //add
            //acronimo
            total_movement: 0,
            total_paid: 0,

            retention_amount: 0,
            retention: false,
            barcode: formData.barcode,
            datos_facturacion: {name: "", nit: ""},
        });
    }

    dispatch({type: UPDATE_PRODUCT_TO_CAR_SALE, products_list});
    dispatch({type: GROUP_FACTURE});
    dispatch(totalCalculate());
}

const setPaciente = (patient) => (dispatch, getStore) => {
    const form = getStore().form.patient;
    let nit = (patient) ? patient.nit : null;

    if(patient.label === undefined){
        dispatch(initializeForm('patient', {...form.values, patient: null, nit: null, referent: null}));
    }
    else{
    api.get(`configuration/getConfiguration`).then((response)=> {
        const default_financial_entity = response.default_financial_entity;

        if(patient.id_referent !== null && patient.id_referent !== undefined){
            api.get("referents/getOneReferent", {referent_id: patient.id_referent}).then(data => {
                let referente = {};

                let idFinancialEntity = 0;
                let usuarioFel = "";
                let idReferentCategory = 0;
                let receipt = false;
                let afiliacion_fel = ""; //add

                if(data.institution !== null){
                    idFinancialEntity = data.institution.referent_category.financial_entity.id;
                    usuarioFel = data.institution.referent_category.financial_entity.usuario_fel;
                    idReferentCategory = data.institution.referent_category.id;
                    receipt = data.institution.referent_category.issue_receipt;
                    afiliacion_fel =  data.institution.referent_category.financial_entity.afiliacion_fel; //add
                }else{
                    idFinancialEntity = default_financial_entity.id;
                    usuarioFel = default_financial_entity.usuario_fel;
                    idReferentCategory = 0;
                    receipt = default_financial_entity.afiliacion_fel === "exento" ? true : false;
                    afiliacion_fel = default_financial_entity.usuario_fel; //add
                }

                referente = {
                    value: data.id,
                    label: data.name,
                    usuario_fel: usuarioFel,
                    financial_entity: idFinancialEntity,
                    idReferentCategory: idReferentCategory,
                    receipt,
                    afiliacion_fel, //add
                };

                dispatch(initializeForm('patient', {...form.values, patient, nit, referent: referente}));
            })
        }else{
            dispatch(initializeForm('patient', {...form.values, patient, nit, referent: null}));
        }
    });
    }
}

const addProductToCart = (producto_servicio) => (dispatch, getStore) => {
    document.getElementById('search_change').focus(); //Para devolver el foco al input de búsqueda

    const sale_store = getStore().sales;
    const financial_entity_id = sale_store.patientInfo.referent.financial_entity;
    const financial_entity_name = sale_store.patientInfo.referent.usuario_fel;
    const factura = (sale_store.patientInfo.referent.receipt) ? 'recibo' : 'factura';
    //const acronimo = sale_store.patientInfo.referent.acronimo

    const products_list = _.cloneDeep(getStore().sales.productsInCar);
    const product = products_list.find(item => item.id === producto_servicio.id);
    const index = products_list.findIndex(item => item.id === producto_servicio.id);

    if(product && (index !== -1)){
        products_list[index].cant += 1;
        products_list[index].total = products_list[index].cant * products_list[index].price;
    }else{
        products_list.push({
            id: producto_servicio.id,
            name: producto_servicio.name,
            cant: 1,
            price:producto_servicio.price,
            discount_authorization: false,
            descuento_limite: producto_servicio.discount,
            descuento: 0,
            descuentoValor: 0,
            total:producto_servicio.price,
            totalStatic: producto_servicio.price,
            financial_entity: financial_entity_id,
            financial_entity_name: financial_entity_name,
            cupon: false,
            cupon_code: null,
            coupons:[],
            factura,
            correlativo:null,
            movement_id:0,
            code: '',
            //medic: null,//add
            //patient: null, //add
            //acronimo
            total_movement: 0,
            total_paid: 0,

            retention_amount: 0,
            retention: false,
            barcode:producto_servicio.barcode,
            datos_facturacion: {name: "", nit: ""},
        });
    }

    dispatch({type: UPDATE_PRODUCT_TO_CAR_SALE, products_list});
    dispatch({type: GROUP_FACTURE});
    dispatch(totalCalculate());
}

const removeCoupon = (index, coupon_code) => (dispatch) => {
    const data = { index, coupon_code }; /* Se crea el objeto correspondiente al reducer */
    dispatch({type: REMOVE_PRODUCT_COUPON, data}); /* Se ejecuta el reducer */
    dispatch(totalCalculate()); /* Se vuelve a calcular el total */
    dispatch({type: GROUP_FACTURE});
}

const removeProductToCart = (index) => (dispatch, getStore) => {
    dispatch({type: REMOVE_PRODUCT_TO_CAR, index});
    dispatch(totalCalculate());
    dispatch({type: GROUP_FACTURE});
}

const upDownCant = (index, up_down) => (dispatch) => {
    const data = { index, up_down };
    dispatch({type: UP_DOWN_CANT, data})
    dispatch(totalCalculate());
    dispatch(recalculateCoupon());
    dispatch({type: GROUP_FACTURE});
}

/* add ... */
const setCant = (index, cant) => (dispatch, getStore) => {
    const data = {index, cant};
    dispatch({type: SET_CANT, data})
    dispatch(totalCalculate());
    dispatch(recalculateCoupon());
    dispatch({type: GROUP_FACTURE});
}

/* ... */

const totalCalculate = () => (dispatch) => {
    dispatch({type: TOTAL_CALCULATE, data:null });
}

const limpiarMetodosPago = () => (dispatch, getStore) => {
    let facturas = getStore().sales.productsInCarOrdered;
    facturas.forEach((factura) => {
            factura.dataPayments.card_payments = [];
            factura.dataPayments.cards_total = 0;
            factura.dataPayments.due_payments = [];
            factura.dataPayments.dues_total = 0;
            factura.dataPayments.deposit_payments = [];
            factura.dataPayments.deposit_total = 0;
            factura.dataPayments.cash = 0;


            factura.calculate.resto = 0;

            /*

            total: total_sin_descuento,
            pagado: dataBilling.total_paid,
            total_sin_descuento,
            descuento: parseFloat(descuento.toFixed(2)),
            resto: diferencia,
            retention: dataBilling.retention,

            totalLabel: total,
            resto: diferencia
            */
    });

    dispatch({type:UPDATE_FACTURAS, facturas});
}

const crearNuevaVenta = () => (dispatch) => {
    dispatch({type:SET_ALL_DATA_SALE});
    dispatch(push('/oppening-closing'));
}

const getDefaultEntity = () => (dispatch) => {
    api.get(`configuration/getConfiguration`).then((response) => {
        const default_financial_entity = response.default_financial_entity;
        dispatch({type:SET_DEFAULT_ENTITY, default_financial_entity});
    })
}

const isOpenAccount = (is_open_account) => (dispatch) => {
    dispatch({type: SET_IS_OPEN_ACCOUNT, is_open_account});
}

const setIdAccount = (id_account) => (dispatch) => {
    dispatch({type: SET_ID_ACCOUNT, id_account});
}

/**
 * Función utilizada para borrar los datos al crear una cuenta abierta... o venta todo menos los datos del paciente
 */
const setDataSaleNoPatient = () => (dispatch) => {
    dispatch({type: SET_ALL_DATA_SALE_NO_PATIENT});
}

const setDefaultValueExtraAmount = ()=>(dispatch)=>{
    dispatch({type: SET_INTIAIL_EXTRA_AMOUNT_SALES, extra_amount_initial: 0});
}

export const changeFieldFormBillingInformation = (field, value) => (dispatch, getState) => {
    dispatch(changeForm("frmBillingInformation", field, value));
}

const setDataName = (data) => (dispatch, getState) => {
    let nameNew = data.first_name + " " + data.last_name;

    console.log("nameNew", nameNew);
    if (nameNew.trim() === "") {
        dispatch(changeForm("frmBillingInformation", "name", ""));
    }
    else {
        dispatch(changeForm("frmBillingInformation", "name", nameNew));
    }
};

const changeReferentFilter = (filter) => (dispatch, getStore) => {
    dispatch({type: SET_ACCOUNT_REFERENT_FILTER, accout_referent_filter: filter});
    dispatch(getOpenAccounts(1));
};

export const actions = {
    isOpenAccount,
    setIdAccount,
    setPaciente,
    setCashPayment,
    setRutaAnterior,
    cancelSale1,
    cancelSale2,
    finishSale,
    setCouponCode,
    removeCoupon,
    getCards,
    getDues,
    cardsPayment,
    duesPayment,
    dataPatient,
    noDataPatient,
    listar,
    addProductToCart,
    addProductToCartDiff,
    removeProductToCart,
    upDownCant,
    applyDiscount,
    applyDiscountValue,
    totalCalculate,
    search,
    filtro,
    checkCashBox,
    findNit,
    cancelDataPayment,
    confirmDataBilling,
    depositPayment,
    openAccountRegister,
    openAccount,
    getOpenAccounts,
    setRetention,
    addDeposit,
    limpiarMetodosPago,
    crearNuevaVenta,
    getDefaultEntity,
    searchOpenAccounts,
    cancelOpenAccount,
    setDataSaleNoPatient,
    setCant,
    validateNit,
    retrievePatientEmail,
    setDefaultValueExtraAmount,
    changeFieldFormBillingInformation,
    setDataName,
    changeReferentFilter
};

export const reducer = {
    [SET_SEARCH_SERVICE_IN_SALE]: (state) => {
        return {...state, buscador:''}
    },
    [LOADER_OPEN_ACCOUNT]: (state, { charge_open_account }) => {
        return {...state, charge_open_account }
    },
    [SET_PAGE_OPEN_ACCOUNT]: (state, { page_open_accounts }) => {
        return {...state, page_open_accounts }
    },
    [SET_BUSCADOR_OPEN_ACCOUNT]: (state, { buscador_cuenta_abierta }) => {
        return {...state, buscador_cuenta_abierta }
    },
    [SET_DEFAULT_ENTITY]: (state, default_financial_entity) => {
        return {
            ... state,
            default_financial_entity
        }
    },
    [SET_ALL_DATA_SALE]: (state) => {
        return {
            ...state,
            productsInCar: [],
            productsInCarOrdered: [],
            patientInfo: {
                patient: '',
                name : '',
                nit : '',
                referent: null,
                doctor: null,
            },
            totalInCar:0,
            totalPagado:0,
            is_open_account: false,
            id_account: 0,
        }
    },
    [SET_ALL_DATA_SALE_NO_PATIENT]: (state) => {
        return {
            ...state,
            productsInCar: [],
            productsInCarOrdered: [],
            totalInCar:0,
            totalPagado:0,
            is_open_account: false,
            id_account: 0,
        }
    },
    [SET_DATA_PAYMENTS]: (state, {data}) => {
        const result = groupBy(data, (a) => a.payment_type);

        return {
            ...state,
            data_payments: result,
        }
    },
    [ADD_PAYMENT_DESCRIPTION]: (state, { payment }) => {
        return{
            ...state,
            data_payments: [...state.data_payments, payment]
        }
    },
    [SET_CATEGORY_PRICES]: (state, { categories }) => {
        return {...state, categories }
    },
    [LOADER_SERVICE]: (state, { cargando }) => {
        return {...state, cargando }
    },
    [SET_DATA_SERVICE]: (state, { data }) => {
        return {...state, data }
    },
    [SET_PAGE_SERVICE]: (state, { page }) => {
        return {...state, page }
    },
    [SET_UPDATE_DATA_SERVICE]: (state, { updateData }) => {
        return {...state, updateData }
    },
    [SET_BUSCADOR_SERVICE]: (state, { buscador }) => {
        return {...state, buscador }
    },
    [SET_FILTRO_SERVICE]: (state, { filtro_services }) => {
        return {...state, filtro_services }
    },
    [ADD_PRODUCT_TO_CAR]: (state, { producto }) => {
        return{
            ...state,
            productsInCar: [...state.productsInCar, producto]
        }
    },
    [UPDATE_PRODUCT_TO_CAR_SALE]: (state, { products_list }) => {
        return{
            ...state,
            productsInCar: products_list,
        }
    },
    [RECALCULATE_COUPON]: (state, {data}) => {
        let arreglo = state.productsInCar.filter((producto) => {

            let totalCoupon = 0;
            producto.coupons.forEach((coupon) => {
                const resto = (producto.total - totalCoupon).toFixed(2);
                if(coupon.amount > parseFloat(resto)){
                    coupon.amount_label = parseFloat(resto);
                }else{
                    coupon.amount_label = coupon.amount;
                }

                totalCoupon += coupon.amount;
            });

            return producto;
        });

        return{
            ... state,
            productsInCar: arreglo
        }
    },

    [ADD_PRODUCT_COUPON]: (state, { data }) => {
        let arreglo = state.productsInCar.filter((item, index) => {
            if(index === data.index){
                item.coupons.push(data.coupon);
            }
            return item;
        });

        return{
            ... state,
            productsInCar: arreglo
        }
    },
    [REMOVE_PRODUCT_COUPON]: (state, { data }) => {

        let arreglo = state.productsInCar.filter((item, index) => {
            if(index === data.index){
                let coupons = item.coupons.filter(coupon => {
                    return coupon.code !== data.coupon_code;
                });
                item.coupons = coupons;
            }
            return item;
        });

        return{
            ...state,
            productsInCar: arreglo
        }
    },
    [REMOVE_PRODUCT_TO_CAR]: (state, { index }) => {
       let arreglo = state.productsInCar.filter((item, key) => {
            return key !== index;
        });

        return{
            ...state,
            productsInCar: arreglo
        }
    },
    [UP_DOWN_CANT]: (state, { data }) => {
        const arreglo = state.productsInCar.filter((item, key) => {
            if(key == data.index){
                item.cant = (data.up_down) ? item.cant + 1 : item.cant - 1;
                if(item.descuento > 0){
                    let total = parseFloat(item.price) * parseFloat(item.cant);
                    let descuento = parseFloat((total * item.descuento)).toFixed(2);
                    //agregado...
                    item.descuentoValor = descuento;
                    item.total = total - descuento;
                }else{
                    item.total = parseFloat(item.price) * parseFloat(item.cant);
                }

                item.totalStatic = parseFloat(item.price) * parseFloat(item.cant);
            }
            return item;
        });

        return{
            ...state,
            productsInCar: arreglo
        }
    },
    [SET_CANT]: (state, { data }) => {
        const arreglo = state.productsInCar.filter((item, key) => {
            if(key == data.index){
                //item.cant = (data.up_down) ? item.cant + 1 : item.cant - 1;
                item.cant = data.cant;

                if(item.descuento > 0){
                    let total = parseFloat(item.price) * parseFloat(item.cant);
                    let descuento = parseFloat((total * item.descuento)).toFixed(2);
                    //agregado...
                    item.descuentoValor = descuento;
                    item.total = total - descuento;
                }else{
                    item.total = parseFloat(item.price) * parseFloat(item.cant);
                }

                item.totalStatic = parseFloat(item.price) * parseFloat(item.cant);
            }
            return item;
        });

        return{
            ...state,
            productsInCar: arreglo
        }
    },
    [APPLY_DISCOUNT]: (state, { data }) => {
        const arreglo = state.productsInCar.filter((item, key) => {
            if(key == data.index){
                const porcentajeDecimal = (data.value / 100).toFixed(2);
                let descuento = parseFloat((item.totalStatic * porcentajeDecimal)).toFixed(2);
                item.descuentoValor = descuento;
                item.descuento = porcentajeDecimal;

                //add
                item.discount_authorization = (item.descuento_limite >= data.value) ? false : true;

                const total = parseFloat(item.price) * parseFloat(item.cant);
                item.total = (total - descuento).toFixed(2);
            }
            return item;
        });

        return{
            ...state,
            productsInCar: arreglo
        }
    },

    [APPLY_DISCOUNT_VALUE]: (state, { data }) => {
        const arreglo = state.productsInCar.filter((item, key) => {
            if(key == data.index){
                const descuentoValor = parseFloat(data.value).toFixed(2);
                item.descuentoValor = descuentoValor;
                const total = parseFloat(item.price) * parseFloat(item.cant);
                const descuento = (data.value / total).toFixed(4);
                item.descuento = descuento;
                //add
                if (parseFloat(item.descuento_limite) == 0) {
                    item.discount_authorization = true;
                } else {
                    // item.discount_authorization = ((item.price / parseFloat(item.descuento_limite)) >= data.value) ? false : true;
                    if ((descuento * 100) >= item.descuento_limite) {
                        item.discount_authorization = true;
                    }
                    else {
                        item.discount_authorization = false;
                    }
                }
                item.total = (total - descuentoValor).toFixed(2);
            }
            return item;
        });

        return{
            ...state,
            productsInCar: arreglo
        }
    },

    [TOTAL_CALCULATE]: (state, { data }) => {
        let acum = 0;
        state.productsInCar.forEach(item => {
            acum += parseFloat(item.total);
            /* Para que no descuente de más en los cupones... */
            let totalCoupon = 0;
            item.coupons.forEach(coupon => {
                totalCoupon += parseFloat(coupon.amount);
            });

            if(parseFloat(totalCoupon) > parseFloat(item.total)){
                acum -= item.total;
            }else{
                acum -= totalCoupon;
            }
        });

        return{
            ...state,
            totalInCar: acum,
            total_dif: acum
        }
    },
    [SET_PATIENT_INFO]: (state, { formData }) => {
        return{
            ...state,
            patientInfo: formData
        }
    },

    [SET_CARD_PAYMENT]: (state, { data }) => {
        return{
            ...state,
            card_payments: data.card_payments,
            card_payments_total: data.total,
        }
    },

    [SET_CARD_LIST]: (state, { card_list }) => {
        return{
            ...state,
            card_list
        }
    },

    [SET_DUES_LIST]: (state, { dues_list }) => {
        return{
            ...state,
            dues_list
        }
    },
    [SET_DUE_PAYMENT]: (state, { data }) => {
        return{
            ...state,
            due_payments: data.due_payments,
            due_payments_total: data.total,
        }
    },

    [GROUP_FACTURE]: (state) => {
        const arreglo = state.productsInCar;
        const result = groupBy(arreglo, (a) => a.financial_entity_name);

        let facturas = [];
        let cont = 0;
        let monto_extra=0;
        Object.entries(result).forEach((element) => {
            let total = 0, descuento=0;
            let financial_entity = { id: 0 , name: element[0] }
            //let dataBilling = { nit: "", name: "", correlative: null, code:"", state: "", id: 0};
            let dataBilling = {
                    nit: "",
                    name: "",
                    correlative: null,
                    code: "",
                    state: 0,
                    id: 0,
                    fel_status:0,
                    fel_error: '',
                    serie:'',
                    numero:'',
                    numeroAutorizacion:'',
                    total_movement:0,
                    total_paid: 0,
                    retention: false,
                    retention_amount: 0,
                    uuid: uuidv4(),
                    cash_uuid: uuidv4(),
                    fecha: '', //agregado
                };
            //El id en dataBilling es el id del movimiento si hubiere sido de una cuenta abierta
            let patientInfo = { medic: null, patient: null }; //add

            //let verify_medic = false; //add
            //let verify_patient = false; //add

            let verify_movement_id = false;
            let verify_code = false;
            let verify_state = false;
            let verify_fel_status = false;
            let verify_fel_error  = false;
            let verify_serie = false;
            let verify_numero = false;
            let verify_numeroAutorizacion = false;

            let verify_total_movement = false;
            let verify_total_paid = false;
            let verify_retention = false;
            let verify_retention_amount = false;
            let verify_datos_facturacion = false;

            let verify_date = false; //agregado

            let services = [];
            console.log("STATE", state);
            element[1].forEach((service, key) => {
                let descuento_sumado = 0;
                if(key === 0){
                    financial_entity.id = service.financial_entity;
                    financial_entity.factura = service.factura;
                    dataBilling.correlative = service.correlative;
                }

                if(!verify_movement_id && service.movement_id !== 0){
                    dataBilling.id = service.movement_id;
                    verify_movement_id = true;
                }

                if(!verify_code && service.code !== ''){
                    dataBilling.code = service.codigo;
                    verify_code = true;
                }


                if(!verify_fel_status && service.fel_status !== 0){
                    dataBilling.fel_status = service.fel_status;
                    verify_fel_status = true;
                }

                if(!verify_fel_error && service.fel_error !== ''){
                    dataBilling.fel_error = service.fel_error;
                    verify_fel_error = true;
                }

                if(!verify_serie && service.serie !== ''){
                    dataBilling.serie = service.serie;
                    verify_serie = true;
                }

                if(!verify_numero && service.numero !== ''){
                    dataBilling.numero = service.numero;
                    verify_numero = true;
                }

                if(!verify_numeroAutorizacion && service.numeroAutorizacion !== ''){
                    dataBilling.numeroAutorizacion = service.numeroAutorizacion;
                    verify_numeroAutorizacion = true;
                }

                if(!verify_state && service.state !== 0){
                    dataBilling.state = service.state;
                    verify_state = true;
                }

                if(!verify_total_movement && service.total_movement !== 0){
                    dataBilling.total_movement = service.total_movement;
                    verify_total_movement = true;
                }

                if(!verify_total_paid && service.total_paid !== 0){
                    dataBilling.total_paid = service.total_paid;
                    verify_total_paid = true;
                }

                if(!verify_retention && service.retention !== 0){
                    dataBilling.retention = service.retention;
                    verify_retention = true;
                }

                if(!verify_retention_amount && service.retention_amount !== 0){
                    dataBilling.retention_amount = service.retention_amount
                    verify_retention_amount = true;
                }

                //Add Ricky
                if(!verify_datos_facturacion && service.verify_datos_facturacion !== null){
                    dataBilling.name = service.datos_facturacion.name;
                    dataBilling.nit = service.datos_facturacion.nit;
                    dataBilling.patient = state.patientInfo.patient.label;
                    verify_datos_facturacion = true;
                }

                //agregado
                if(!verify_date && !!service.fecha){
                    dataBilling.fecha = service.fecha;
                    verify_date = true;
                }

                /*
                if(!verify_medic && service.medic !== null){
                    patientInfo.medic = service.medic;
                    verify_medic = true;
                }

                if(!verify_patient && service.patient !== null){
                    patientInfo.patient = service.patient;
                    verify_patient = true;
                }
                */

                let totc = service.cant * service.price;
                monto_extra = parseFloat(service.monto_extra);
                //total += parseFloat( parseFloat(service.total).toFixed(2) );
                total += service.is_charge ? 0 : parseFloat( parseFloat(totc).toFixed(2) );
                descuento += parseFloat(service.descuentoValor);
                descuento_sumado += parseFloat(service.descuentoValor); //ADD
                service.coupons.forEach(coupon => {
                    descuento += parseFloat(coupon.amount_label);
                    descuento_sumado += parseFloat(coupon.amount_label); //ADD
                });

                let obj = {
                    coupons: service.coupons,
                    service_name: service.name,
                    service: service.id,
                    price: service.price,
                    quantity : service.cant,
                    discount : service.descuentoValor,
                    discount_authorization: service.discount_authorization,
                    accumulated_discount: descuento_sumado,//ADD
                    subtotal : service.total,
                };

                services.push(obj);
            });

            const diferencia = parseFloat((parseFloat(total.toFixed(2)) - parseFloat(descuento.toFixed(2)) - parseFloat(dataBilling.total_paid) - parseFloat(dataBilling.retention_amount)).toFixed(2));
            const total_sin_descuento = parseFloat((total.toFixed(2) - descuento.toFixed(2)).toFixed(2));

            const calculate = {
                total: total_sin_descuento,
                pagado: dataBilling.total_paid,
                total_sin_descuento,
                descuento: parseFloat(descuento.toFixed(2)),
                retention: dataBilling.retention_amount,
                monto_extra: parseFloat(monto_extra),
                totalLabel: parseFloat(total.toFixed(2)),
                resto: parseFloat(diferencia).toFixed(2),
            }

            const dataPayments = {
                                    card_payments:[],
                                    due_payments: [],
                                    deposit_payments: [],
                                    cash: 0,
                                    //retention: false,
                                    //retention_amount: 0,
                                    retention: dataBilling.retention,
                                    retention_amount: dataBilling.retention_amount,
                                    cards_total: 0,
                                    dues_total: 0,
                                    deposit_total:0,
                                    index:cont,
                                    type: "venta",
                                };
            facturas.push({ financial_entity, services, dataPayments, calculate, dataBilling });
            cont++;
        })

        return{
            ...state,
            productsInCarOrdered: facturas
        }
    },
    [TOGGLE_CONF_COUPON_MODAL]: (state, { show_modal_confcoupon }) => {
        return{
            ... state,
            show_modal_confcoupon
        }
    },
    [CANCEL_SALE_1]: (state, { patientInfo }) => {
        return {
            ...state,
            patientInfo
        }
    },
    [CANCEL_SALE_2]: (state) => {
        return {
            ...state,
            productsInCar: [],
            productsInCarOrdered: [],
            totalInCar: 0,
            id_account: 0,
        }
    },
    [RUTA_ANTERIOR]: (state, { value }) => {
        return {
            ...state,
            isServiceRoute: value
        }
    },

    [SET_CASH]: (state, { efectivo }) => {
        return{
            ... state,
            efectivo
        }
    },

    [UPDATE_FACTURAS]: (state, { facturas }) => {
        return{
            ... state,
            productsInCarOrdered: facturas
        }
    },

    [SET_TOTAL_DIF]: (state, { total_dif }) => {
        return{
            ... state,
            total_dif
        }
    },
    [SET_CHECK_CASHBOX]: (state, { openCashBox }) => {
        return {...state, openCashBox }
    },
    [CONFIRM_DATA_BILLING]: (state, { datosFacturacion }) => {
        return { ...state, datosFacturacion}
    },

    [SET_DATA_SALE]: (state, {data}) => {
        let arreglo = [];
        let payment = 0;
        let pagado = 0;
        const patientInfo = {};

        data.forEach(movement => {
            const correlative = movement.correlative;
            const movement_id = movement.id;
            const codigo = movement.codigo;
            const made = movement.made_fel; //agregado
            const fel_error = movement.fel_error;
            const fel_status = movement.fel_status;
            const state = movement.state;

            const serie = movement.serie;
            const numero = movement.numero;
            const numeroAutorizacion = movement.numeroAutorizacion;

            const factura = movement.document_type === "REC" ? "recibo" : "factura";
            const financial_entity = movement.financial_entity.id;
            const financial_entity_name = movement.financial_entity.usuario_fel;

            const total_movement = movement.payment;
            const total_paid = movement.paid;
            const monto_extra= movement.extra_amount;
            const retention = movement.retention;
            const retention_amount = movement.retention_amount;

            let doc_id = movement.doctor !== null ? movement.doctor.id : null;
            let doc_movement = movement.doctor !== null ? movement.doctor.name : null;
            const medic = { value: doc_id, label: doc_movement };

            /*const patient = { value: movement.patient.id, label: movement.patient.first_name };*/
            const patient = { value: movement.patient.id, label: movement.patient.first_name + ' ' + movement.patient.last_name};
            const recibo = movement.financial_entity.afiliacion_fel === "exento" ? true : false;

            let entidad_financiera = 0;
            let afiliacion_fel = '';
            if(movement.financial_entity){
                entidad_financiera = movement.financial_entity.id;
                afiliacion_fel = movement.financial_entity.afiliacion_fel;
            }

            const referent = {
                //financial_entity: 0,
                financial_entity: entidad_financiera,
                //idReferentCategory: movement.referent.institution.referent_category.id,
                idReferentCategory: 0,
                label: movement.referent.name,
                //usuario_fel: movement.referent.institution.referent_category.financial_entity.usuario_fel,
                usuario_fel: movement.financial_entity.usuario_fel,
                receipt: recibo,
                value: movement.referent.id,

                //add
                afiliacion_fel,
            };

            //agregado
            const datos_facturacion = {name: movement.name, nit: movement.nit};
            //agregado...

            const nit = movement.nit || movement.patient.nit;
            patientInfo.medic = medic;
            patientInfo.patient = patient;
            patientInfo.name = patient.label;
            patientInfo.referent = referent;
            patientInfo.nit = nit;

            payment += parseFloat(movement.payment);
            //add ricky
            pagado += parseFloat(movement.paid);

            //CONSIDERA LA RETENCIÓN CÓMO PAGADA:
            pagado += parseFloat(movement.retention_amount);
            ////////////////////////////////////////////////////

            movement.movement_detail_movement.forEach(detail => {
                let coupons = [];
                detail.coupon_payment.forEach(cupon => {
                    const service = cupon.coupon.service !== null ? cupon.coupon.service.id : null; //add
                    const coupon = {
                        amount: cupon.coupon.amount,
                        amount_label: cupon.quantity,
                        code: cupon.coupon.code,
                        id: cupon.coupon.id,
                        name: cupon.coupon.name,
                        percentage: null,
                        //service: cupon.coupon.service.id,
                        service, //add
                        type_coupon: cupon.coupon.type_coupon,
                        used: cupon.coupon.used,
                    };

                    coupons.push(coupon);
                });

                const descuento = (((parseFloat(detail.discount) * 100) / parseFloat(detail.price)) / 100);
                const total_static = parseFloat((parseFloat(detail.price) * parseInt(detail.quantity)).toFixed(2));

                const service = {
                    cant: detail.quantity,
                    is_charge: detail.service.is_charge,
                    coupons,
                    descuento,
                    descuentoValor: detail.discount,
                    descuento_limite: detail.service.discount,
                    discount_authorization: detail.discount_authorization,
                    cupon: false,
                    factura,
                    financial_entity,
                    financial_entity_name,
                    id: detail.service.id,
                    name: detail.service.name,
                    price: detail.price,
                    total: parseFloat(detail.subtotal),
                    totalStatic: total_static,
                    correlative,
                    movement_id,
                    codigo,
                    fecha: made, //agregado
                    medic,
                    patient,

                    state,
                    fel_error,
                    fel_status,
                    serie,
                    numero,
                    numeroAutorizacion,

                    total_movement, //add ricky
                    total_paid, //add ricky
                    monto_extra,
                    retention_amount, //add ricky
                    retention,

                    datos_facturacion, //add Ricky
                };

                arreglo.push(service);
            });
        })

        return {
            ...state,
            productsInCar: arreglo,
            totalInCar: payment,
            totalPagado: pagado,
            patientInfo,
        }
    },
    [SET_OPEN_ACCOUNTS]: (state, { data }) => {
        return { ...state, open_accounts: data}
    },
    [UPP_SALE_INFO]: (state, { sale }) => {
        return { ...state, sale }
    },
    [BLANK_SERVICE_INFO]: (state) => {
        return {
            ...state,
            productsInCar: [],
            productsInCarOrdered: [],
            patientInfo: {
                patient: '',
                name : '',
                nit : '',
                referent: null,
                doctor:null,
            }
        }
    },
    [SET_BILL_MASK]: (state, { bill_mask }) => {
        return {
            ...state,
            bill_mask,
        }
    },
    [FORCE_UPDATE]:(state, { force_update }) => {
        return {
            ...state,
            force_update,
        }
    },

    [COPY_DATA_SALE]:(state) => {
        const productsInCarOrderedView = state.productsInCarOrdered;

        return {
            ...state,
            productsInCarOrderedView,
        }
    },

    [SET_IS_OPEN_ACCOUNT]:(state, { is_open_account }) => {
        return {
            ...state,
            is_open_account,
        }
    },

    [SET_ID_ACCOUNT]:(state, { id_account }) => {
        return {
            ...state,
            id_account,
        }
    },

    [SET_BILLING_EMAIL]:(state, { billing_email }) => {
        return {
            ...state,
            billing_email
        }
    },
    [SET_INTIAIL_EXTRA_AMOUNT_SALES]:(state, { extra_amount_initial }) => {
        return {
            ...state,
            extra_amount_initial
        }
    },
    [SET_ACCOUNT_REFERENT_FILTER]: (state, { accout_referent_filter }) => {
        return {...state, accout_referent_filter }
    },
    someMethod() {
        // Force a render with a simulated state change
        this.setState({ state: this.state });
    }
}

export const initialState = {
    cargando: false,
    page: 1,
    data: {
        count: 0,
        next: null,
        previous: null,
        results: [],
    },
    categories: [],
    buscador: '',
    filtro_services: null,
    updateData: {},
    productsInCar: [],
    productsInCarOrdered: [],
    temp_coupon: null,
    openCashBox: null,
    card_list: [],
    dues_list: [],
    card_payments: [

    ],
    due_payments: [

    ],
    deposit_payments:[

    ],
    card_payments_total: 0,
    due_payments_total: 0,
    deposit_payments_total: 0,
    totalInCar:0,
    totalPagado:0,
    efectivo:0,
    total_dif:0,
    patientInfo: {
        patient: '',
        name : '',
        nit : '',
        referent: null,
        doctor:null,
    },
    datos_facturacion: null,
    show_modal_coupon: false,
    show_modal_confcoupon: false,
    isServiceRoute: false,
    // openCashBox: null,

    //open_accounts: [],
    open_accounts: {
        count: 0,
        next: null,
        previous: null,
        results: [],
    },

    accout_referent_filter: '0',
    charge_open_account: false,
    page_open_accounts: 1,
    buscador_cuenta_abierta: '',

    sale: {
        state: "",
        code: "",
        movements: [],
    },
    bill_mask: false,
    data_payments: [],
    force_update: false,
    default_financial_entity: null,
    datosFacturacion: null,

    /* Para la vista previa de la factura */
    productsInCarOrderedView: [],

    /* Para saber si es una cuenta abierta*/
    is_open_account: false,
    id_account: 0,
    billing_email: '',
    extra_amount_initial: 0
};

export default handleActions(reducer, initialState);
