import Table from '../Utils/Grid';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { BreakLineCenter, BreakLine } from '../Utils/tableOptions';
import HeaderSimple from '../Utils/Header/HeaderSimple';
import ToolbarSimple from '../Utils/Toolbar/ToolbarSimple';
import { activeFormatter }  from '../Utils/Acciones/Acciones';

class Patients extends Component {

    componentWillMount() {
        this.props.listar()
    }
    componentWillUnmount() {
        this.props.resetStore();
    }

    render() {
        const {
            listar,
            detail,
            create,
            update,
            destroy,
        } = this.props;

        const {
            cargando,
            page,
            data
        } = this.props;

        return (
            <div className="content">
                <div className="m-auto row-fluid w-100">
                    <HeaderSimple
                        texto={"Crear paciente"}
                        ruta={"/patients/create"}
                    />
                    <div className="table-decoration">
                        <div className="top-title-toolbar">
                            <ToolbarSimple
                                titulo="LISTA DE PACIENTES"
                                buscar={this.props.search}
                                buscador={this.props.buscador}
                                autofocus
                            />
                        </div>
                        <Table
                            onPageChange={listar}
                            data={data}
                            loading={cargando}
                            page={page}
                        >
                            <TableHeaderColumn
                                tdStyle={BreakLineCenter}
                                thStyle={BreakLineCenter}
                                width={100}
                                dataField="first_name" 
                                dataSort
                                dataFormat={(cell, row, props)=>{
                                    return (
                                    <React.Fragment>
                                        {cell} 
                                        {row.file_number && 
                                            <React.Fragment>
                                                <br />
                                                <strong>({row.file_number})</strong>
                                            </React.Fragment>
                                        }
                                    </React.Fragment>                                    
                                    )
                                }}
                            >Nombre(s)</TableHeaderColumn>
                            <TableHeaderColumn
                                tdStyle={BreakLineCenter}
                                thStyle={BreakLineCenter}
                                width={100}
                                dataField="last_name" dataSort>Apellido(s)</TableHeaderColumn>
                            <TableHeaderColumn
                                tdStyle={BreakLineCenter}
                                thStyle={BreakLineCenter}
                                width={80}
                                dataField="birthdate" dataSort>Fecha de Nacimiento</TableHeaderColumn>
                            <TableHeaderColumn
                                tdStyle={BreakLineCenter}
                                thStyle={BreakLineCenter}
                                width={100}
                                dataField="departamento" dataSort>Departamento</TableHeaderColumn>
                            <TableHeaderColumn
                                tdStyle={BreakLineCenter}
                                thStyle={BreakLineCenter}
                                width={80}
                                dataField="cellphone" dataSort>Celular</TableHeaderColumn>
                            <TableHeaderColumn
                                tdStyle={BreakLineCenter}
                                thStyle={BreakLineCenter}
                                width={60}
                                dataField="phone" dataSort>Teléfono</TableHeaderColumn>
                            <TableHeaderColumn
                                tdStyle={BreakLineCenter}
                                thStyle={BreakLineCenter}
                                width={50}
                                dataFormat={(cell, row, props)=>{
                                    return row.es_igss == true ? 
                                            <React.Fragment>Si</React.Fragment> :
                                            <React.Fragment>No</React.Fragment>
                                }}
                                dataField="es_igss" dataSort>Es IGSS</TableHeaderColumn>
                            <TableHeaderColumn
                                tdStyle={BreakLineCenter}
                                thStyle={BreakLineCenter}
                                width={125}
                                dataFormat={(cell, row, props)=>{
                                    return row.es_igss == true ? 
                                            <React.Fragment>{row.no_afiliacion}</React.Fragment> :
                                            <React.Fragment></React.Fragment>
                                }}
                                dataField="no_afiliacion" dataSort>No. Afiliación</TableHeaderColumn>
                            <TableHeaderColumn
                                dataField="id"
                                isKey={true}
                                tdStyle={BreakLineCenter}
                                thStyle={{ whiteSpace: 'nowrap' }}
                                width="120px"
                                dataAlign="center"
                                dataFormat={activeFormatter({
                                    ver: '/patients',
                                    editar: '/patients',
                                    eliminar: destroy,
                                    historial_clinico: '/medical-history'
                                })}>Acciones</TableHeaderColumn>
                        </Table>
                    </div>
                </div>
            </div>
        )
    }
}

export default Patients;
