import { connect } from 'react-redux';
import _ from "lodash";
import Appointments from './AppointmentsWeekCreate';
import { actions } from '../../../redux/modules/appointments';
import { municipios } from 'variables';
import {resetStore} from "../../../redux/modules/login";

const ms2p = (state) => {
    let _municipios = [];
    try {
        const { departamento } = state.form.appointments.values;
        if (!!departamento) {
            _municipios = _.filter(municipios, {departamento: departamento.value});
        }
    } catch (e) {
    }
    return {
        ...state.appointments,
        municipios: _municipios,
    };
};

const md2p = { ...actions, resetStore };

export default connect(ms2p, md2p)(Appointments);
