import React, { Component } from 'react';
import { Field, FieldArray, reduxForm,  formValueSelector } from 'redux-form';
import { validate, validators } from 'validate-redux-form';
import { renderField, renderSearchSelect } from 'Utils/renderField/renderField';
import { connect } from 'react-redux'
import { Link } from 'react-router-dom';
import { api } from "api";


import { renderDueRow, renderRemoveRow } from './row';

//import './config';

let optionsPos = [];
const getOptions = (search)  =>{
    return api.get("pos",{search}).catch((error) => {})
    .then((data) => {
        if (data){
            optionsPos = [];
            data.results.forEach(x=>{
                optionsPos.push({value: x.id, label: x.name})
            })
            return optionsPos;
        }
        else{
            return [];
        }
    })
    .catch(e => {
        return [];
    })
}

class DuesForm extends Component{
    remove = (row) => {
        this.props.removeRow(row);
    }

    render(){
        const {
            handleSubmit,
        } = this.props;

        return(
            <form onSubmit={handleSubmit} className="pb-3 pt-5 d-flex justify-content-center">
                <div className="form-body-width">

                    <div className="px-md-5 d-flex justify-content-around flex-column flex-sm-row align-items-stretch align-items-sm-center">

                        <div className="m-2 d-none">
                            <div className="d-flex justify-content-start flex-column flex-sm-row align-items-stretch align-items-sm-center">
                                <label htmlFor="rol_name" className="m-0">Id Principal</label>
                            </div>
                            <div className=" d-flex justify-content-start flex-column flex-sm-row align-items-stretch align-items-sm-center">
                                <Field name="principal_id" component={renderField} type="text" className="form-control"/>
                            </div>
                        </div>

                    </div>

                    <div className="mt-3 px-md-5 d-flex justify-content-around flex-column flex-sm-row align-items-stretch align-items-sm-center">
                        <div className="m-2">
                            <div className="d-flex justify-content-start flex-column flex-sm-row align-items-stretch align-items-sm-center">
                                <label htmlFor="rol_name" className="m-0">Nombre</label>
                            </div>
                            <div className=" d-flex justify-content-start flex-column flex-sm-row align-items-stretch align-items-sm-center">
                                <Field name="name" autofocus component={renderField} type="text" className="form-control"/>
                            </div>
                        </div>
                        <div className="m-2">
                            <div className="d-flex justify-content-start flex-column flex-sm-row align-items-stretch align-items-sm-center">
                                <label htmlFor="pos" className="m-0">POS</label>
                            </div>
                            <div className=" d-flex justify-content-start flex-column flex-sm-row align-items-stretch align-items-sm-center">
                                <Field
                                    name="pos"
                                    valueKey="value"
                                    labelKey="label"
                                    label="pos"
                                    component={renderSearchSelect}
                                    loadOptions={getOptions}
                                    className="no-border"
                                />
                            </div>
                        </div>
                    </div>

                    <div className="px-md-5 d-flex justify-content-around flex-column flex-sm-row align-items-stretch align-items-sm-center">
                        <div className="m-2">
                            <div className="d-flex justify-content-start flex-column flex-sm-row align-items-stretch align-items-sm-center">
                                <label htmlFor="listado" className="m-0">Listado</label>
                            </div>
                            <div className=" d-flex justify-content-start flex-column flex-sm-row align-items-stretch align-items-sm-center">

                            <div className="table-responsive">
                            <table className="table table-sm" id="table-tarjet">
                                <thead className="thead-tarjet">
                                    <tr>
                                        <th>Cuotas</th>
                                        <th>Tas %</th>
                                        <th>% agregado al cliente</th>
                                        <th>Acciones</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <FieldArray
                                        name="due_detail"
                                        component={renderDueRow}
                                        props = {{ remove: this.remove }}
                                    />

                                    <FieldArray
                                        name="due_detail_remove"
                                        component={renderRemoveRow}
                                    />
                                </tbody>
                            </table>
                            </div>

                            </div>
                        </div>
                    </div>

                    <div className="d-flex justify-content-center flex-column flex-sm-row align-items-stretch align-items-sm-center w-100">
                        <Link className="btn btn-secondary m-3" to="/dues">Cancelar</Link>
                        <button type="submit" className="btn btn-primary m-3">Guardar</button>
                    </div>

                </div>
            </form>

        )
    }
};

DuesForm = reduxForm({
   form: 'dues',
   enableReinitialize: true,
   validate: data => {
        return validate(data, {
            'name': validators.exists()('Campo requerido.'),
       })
   }
})(DuesForm);

DuesForm = connect(
    state => ({
        initialValues: state.due
    })
)(DuesForm)

export default DuesForm;
