import React from 'react';
import { colourOptions } from './data';
import Select from 'react-select';
import classNames from 'classnames';

const dot = (color = "#ccc") => ({
    alignItems: "center",
    display: "flex",

    ":before": {
      backgroundColor: color,
      borderRadius: 5,
      content: '" "',
      display: "block",
      marginRight: 8,
      height: 25,
      width: 25,
    }
  });

  const colourStyles = {
    control: styles => ({
      ...styles,
      backgroundColor: "white",
      width:"200px !important",
    }),
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
      data.label = "";
      return {
        ...styles,
        backgroundColor: data.color,
        borderRadius: 5,
        maxWidth: "200px",
        alignItems: "center",
        padding: 5,
        margin: "10px 5px 10px 10px",
        content: '" "',
        display: "inline-flex",
        height: 25,
        width: 25,
        cursor: "pointer"
      };
    },

    input: styles => ({ ...styles, ...dot() }),

    placeholder: styles => ({ ...styles, ...dot() }),

    singleValue: (styles, { data }) => ({ ...styles, ...dot(data.color) })

  };


export const renderSelectColor = ({ input, disabled, id, options, valueKey, labelKey, meta: { touched, error } }) => {
    const invalid = touched && error;
    return(
        <div className='border-trasparent'>
            <Select
                id = {id}
                isDisabled={disabled}
                value={input.value}

                className={classNames('color-select border-trasparent', { 'is-invalid': invalid })}
                valueKey={valueKey} labelKey={labelKey}

                onChange={(e) => {
                    input.onChange(e ? e : null);
                }}

                defaultOptions

                label="Single select"
                options={colourOptions}
                styles={colourStyles}
            />


          {invalid &&
            <div className="invalid-feedback">
                {error}
                {input.value}
            </div>}
      </div>
    );
};
