import MethodPayment from './MethodsPayment';

import React, { Component } from 'react';

export default class tabsComponent extends Component{
    state = {
        tab_active: "venta"
    }

    changeTab(tabName){
        //cancel data payment se utiliza para limpiar los métodos de pago ingresados y setea si es venta o crédito..
        this.props.cancelDataPayment(this.props.index,tabName);
        this.setState({tab_active: tabName});
        this.props.forceRendering();
        this.forceUpdate();
    }

    render(){
        let tventa = (this.state.tab_active === "venta") ? "tp-active" : "tp-inactive";
        let tcredito = (this.state.tab_active === "credito") ? "tp-active" : "tp-inactive";
        let firstactive = (this.state.tab_active === "venta") ? "first-active" : "";

        return(
            <div className="ml-4 mr-4 d-flex flex-column h-75">
                <div className={"d-flex flex-wrap flex-row justify-content-start align-items-end w-100 b-white"}>
                    <div className={"d-flex w-30 tab-payment justify-content-center align-items-center " + tventa} onClick={() => this.changeTab("venta")}>VENTA</div>
                    <div className={"d-flex w-30 tab-payment justify-content-center align-items-center " + tcredito} onClick={() => this.changeTab("credito")}>DAR CRÉDITO</div>
                    <div className={"d-flex w-20 tab-comodin justify-content-center align-items-center"} >&nbsp;</div>
                </div>
                <div className={"d-flex tab-payment-content b-white p-2 " + firstactive}>
                    {
                        (this.state.tab_active == "venta") ?
                            <React.Fragment>
                                <MethodPayment {...this.props} tabActive={this.state.tab_active} forceRendering={this.props.forceRendering} />
                            </React.Fragment>
                        :
                            <MethodPayment {...this.props} tabActive={this.state.tab_active} forceRendering={this.props.forceRendering} />
                    }
                </div>
            </div>
        );
    }
}
