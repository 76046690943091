import Swal from 'sweetalert2';
import { api } from 'api';
import { createActions, handleActions } from 'redux-actions'
import { goBack } from 'react-router-redux'
import { initialize as initializeForm } from 'redux-form'
import { push } from 'react-router-redux';
const url = 'pos';

const LOADER_POS = 'LOADER_POS';
const SET_DATA_POS = 'SET_DATA_POS';
const SET_PAGE_POS = 'SET_PAGE_POS';
const SET_UPDATE_DATA_POS ='SET_UPDATE_DATA_POS';
const SET_BUSCADOR_POS = 'SET_BUSCADOR_POS';
const SET_FILTRO_POS = 'SET_FILTRO_POS';

const listar = (page = 1) =>  (dispatch, getStore) => {
    dispatch({type: LOADER_POS, cargando: true});
    const store = getStore();
    const search = store.pos.buscador;
    const filtro = store.pos.filtro_pos;
    let params = {page, search};

    if(filtro){
        params[''] = filtro
    }
    api.get(`${url}`, params).catch((error) => {
        dispatch({type: LOADER_POS, cargando: false});
        Swal(
            'Error',
            error.detail || 'Ha ocurrido un error, por favor vuelva a intentar.',
            'error'
        );
    }).then((data) => {
        if(data){
            dispatch({type: SET_DATA_POS, data});
            dispatch({type: SET_PAGE_POS, page});
        }
        dispatch({type: LOADER_POS, cargando: false});
    })
};

const detail = id => (dispatch, getState) =>{
    dispatch({type: LOADER_POS, cargando: true});
    api.get(`${url}/${id}`).catch((error) => {
        dispatch({type: LOADER_POS, cargando: false});
        if(error.statusCode  === 404){
            dispatch(push('/pos'))
        }
    }).then((data) => {
        dispatch({type: LOADER_POS, cargando: false});
        if(data){
            console.log("Data POS: ", data);
            //data.bank_account = {value: data.bank_account.id, label: data.bank_account.owner, number: data.bank_account.account_number}
            data.bank_account = {value: data.financial_entity.bank_account.id, label: data.financial_entity.bank_account.owner, number: data.financial_entity.bank_account.account_number}
            data.financial_entity = {value: data.financial_entity.id, label: data.financial_entity.usuario_fel}
            data.subsidiary = {value: data.subsidiary.id, label: data.subsidiary.name}
            dispatch({type: SET_UPDATE_DATA_POS, updateData: data});
            dispatch(initializeForm('pos', data))
        }
    })
}

const create = () => (dispatch, getStore) => {
    const formData = _.cloneDeep(getStore().form.pos.values);
    formData.financial_entity = formData.financial_entity.value
    formData.subsidiary = formData.subsidiary.value
    dispatch({type: LOADER_POS, cargando: true})
    api.post(`${url}`, formData).then((data) => {
        dispatch({type: LOADER_POS, cargando: false})
        Swal('Éxito', 'Se ha creado el POS.', 'success')
        .then(() => {
            dispatch(push('/pos'))
        })
    }).catch((error) => {
        dispatch({type: LOADER_POS, cargando: false})
        Swal(
            'Error',
             error.detail || 'Ha ocurrido un error, por favor vuelva a intentar.',
            'error'
        );
    }).finally(() => {
        dispatch({type: LOADER_POS, cargando: false})
    });
};

const update = () => (dispatch, getStore) => {
    const formData = _.cloneDeep(getStore().form.pos.values);
    dispatch({type: LOADER_POS, cargando: true});
    formData.financial_entity = formData.financial_entity.value
    formData.subsidiary = formData.subsidiary.value
    api.put(`${url}/${formData.id}`, formData)
    .then((data) => {
        dispatch({type: LOADER_POS, cargando: false});
        if(data){
            Swal(
                'Éxito',
                'Datos editados correctamente.',
                'success'
            ).then(() => {
                dispatch(push('/pos'));
            })
        }
    })
    .catch((error) =>{
        dispatch({type: LOADER_POS, cargando: false});
        Swal(
            'Error',
             error.detail || 'Ha ocurrido un error, verifique los datos.',
            'error'
        )
        if(error.statusCode === 404){
            dispatch(push('/pos'));
        }
    })
}
const destroy = (id) => (dispatch, getStore) => {
    dispatch({type: LOADER_POS, cargando: true});
    api.eliminar(`${url}/${id}`).catch((error) => {
        dispatch({type: LOADER_POS, cargando: false});
        Swal('Error', 'No se ha logrado borrar el registro.', 'error')
            .then(() => {
                dispatch(listar(1));
        })
    }).then((data) => {
        dispatch({type: LOADER_POS, cargando: false});
        Swal('Éxito', 'Se ha eliminado correctamente.', 'success')
            .then(() => {
                dispatch(listar(1));
            })
    })
}

const search = (search) => (dispatch, getStore) => {
    dispatch({type: SET_BUSCADOR_POS, buscador: search});
    dispatch(listar(1));
};
const filtro = (filtro) => (dispatch, getStore) => {
    if (filtro == 0){
        filtro = null
    }
    dispatch({type: SET_FILTRO_POS, filtro_pos: filtro});
    dispatch(listar(1));
};
export const actions = {
    listar,
    detail,
    create,
    update,
    destroy,
    search,
    filtro
};
export const reducer = {
    [LOADER_POS]: (state, { cargando }) => {
        return {...state, cargando }
    },
    [SET_DATA_POS]: (state, { data }) => {
        return {...state, data }
    },
    [SET_PAGE_POS]: (state, { page }) => {
        return {...state, page }
    },
    [SET_UPDATE_DATA_POS]: (state, { updateData }) => {
        return {...state, updateData }
    },
    [SET_BUSCADOR_POS]: (state, { buscador }) => {
        return {...state, buscador }
    },
    [SET_FILTRO_POS]: (state, { filtro_pos }) => {
        return {...state, filtro_pos }
    },
}
export const initialState = {
    cargando: false,
    page: 1,
    data: {
        count: 0,
        next: null,
        previous: null,
        results: [],
    },
    buscador: '',
    filtro_pos: null,
    updateData: {},
};
export default handleActions(reducer, initialState)
