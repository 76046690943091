const url = 'movements';
import { api } from 'api';
import Swal from 'sweetalert2';
import { push } from 'react-router-redux';
import { goBack } from 'react-router-redux';
import { initialize as initializeForm } from 'redux-form';
import { createActions, handleActions } from 'redux-actions';
import moment from "moment";
import { v4 as uuidv4 } from 'uuid'

const LOADER_MOVEMENT = 'LOADER_MOVEMENT';
const SET_DATA_MOVEMENT = 'SET_DATA_MOVEMENT';
const SET_PAGE_MOVEMENT = 'SET_PAGE_MOVEMENT';
const SET_BUSCADOR_MOVEMENT = 'SET_BUSCADOR_MOVEMENT';
const SET_FILTRO_MOVEMENT = 'SET_FILTRO_MOVEMENT';
const SET_BUSCADOR_FECHA_MOVEMENT = 'SET_BUSCADOR_FECHA_MOVEMENT';

const SET_DATA_SALE_BOX = 'SET_DATA_SALE_BOX';
const GROUP_BILL = 'GROUP_BILL';
const SET_CANCEL_MASK = 'SET_CANCEL_MASK';
const SET_OPEN_BOX_MOVEMENT = 'SET_OPEN_BOX_MOVEMENT';

const SET_CARD_LIST_MOVEMENT = 'SET_CARD_LIST_MOVEMENT';
const SET_DUES_LIST_MOVEMENT = 'SET_DUES_LIST_MOVEMENT';
const UPDATE_FACTURAS_MOVEMENT = 'UPDATE_FACTURAS_MOVEMENT';

const SET_CHECK_CASHBOX_MOVEMENT = 'SET_CHECK_CASHBOX_MOVEMENT';
const SET_HISTORY_PAYMENTS = 'SET_HISTORY_PAYMENTS';

const SET_FINAL_DATE = 'SET_FINAL_DATE';
const SET_INICIAL_DATE = 'SET_INICIAL_DATE';
const SET_EXTRA_AMOUNT_INITIAL = 'SET_EXTRA_AMOUNT_INITIAL';
const SET_ALL_PAYMENTS = 'SET_ALL_PAYMENTS';

export const isEmpty = value => value === undefined || value === null || value === '' || value === NaN;

const groupBy = (xs, f) => {
    return xs.reduce((r, v, i, a, k = f(v)) => ((r[k] || (r[k] = [])).push(v), r), {});
}

const listar = (page = 1) =>  (dispatch, getStore) => {
    dispatch({type: LOADER_MOVEMENT, cargando: true});
    const store = getStore();
    const search = store.movement.buscador;
    const filtro = store.movement.filtro_movement;
    const initial_date = store.movement.initial_date;
    const final_date = store.movement.final_date;

    let params = {page, search, initial_date, final_date};

    console.log("PARAMS DE FILTRO: ", params);

    if(filtro){
        params[''] = filtro
    }

    api.get(`${url}/allMovementList/`, params).catch((error) => {
        dispatch({type: LOADER_MOVEMENT, cargando: false});
        Swal(
            'Error',
            error.detail || 'Ha ocurrido un error, por favor vuelva a intentar.',
            'error'
        );
    }).then((data) => {
        if(data){
            console.log("data: ", data);
            dispatch({type: SET_DATA_MOVEMENT, data});
            dispatch({type: SET_PAGE_MOVEMENT, page});
        }
        dispatch({type: LOADER_MOVEMENT, cargando: false});
    })
};



const search = (search) => (dispatch) => {
    dispatch({type: SET_BUSCADOR_MOVEMENT, buscador: search});
    dispatch(listar(1));
};

const searchDate = (search) => (dispatch) => {
    dispatch({type: SET_BUSCADOR_FECHA_MOVEMENT, buscador_fecha: search});
    dispatch(listar(1));
};

const filtro = (filtro) => (dispatch, getStore) => {
    if (filtro == 0){
        filtro = null
    }
    dispatch({type: SET_FILTRO_MOVEMENT, filtro_movement: filtro});
    dispatch(listar(1));
};

const excluirRepetidos = (pagos_realizados, pagos_todo, id_pago) => {
    let excluir = false;
    pagos_realizados.forEach(pago => {
        console.log("Interación, id_pago ", id_pago, " pago ", pago.id);
        if(pago.id == id_pago){
            excluir = true;
        }
    });

    pagos_todo.forEach(pago => {
        console.log("Interación, id_pago ", id_pago, " pago_todo ", pago.id);
        if(pago.id == id_pago){
            excluir = true;
        }
    });

    return excluir;
}

const viewOneSale = (movement_id) => (dispatch) => {
    api.get("movements/getOneSale", { movement_id }).then((data) => {
        const pagos_realizados = _.cloneDeep(data.pagos_realizados);
        const pagos_todo = _.cloneDeep(data.pagos_todo);
        console.log("===================VIEW ONE SALE:============== ", data);
        data.anticipos_deposito.map((pago, i) => {
            const excluir = excluirRepetidos(pagos_realizados, pagos_todo, pago.id)
            //if(!excluir)
                data.pagos_realizados.push({
                    id: pago.id,
                    payment: pago.quantity,
                    made: pago.created,
                    payment_type: 4,
                })
        })

        data.anticipos_tarjeta.map((pago, i) => {
            const excluir = excluirRepetidos(pagos_realizados, pagos_todo, pago.id)

            //if(!excluir)
                data.pagos_realizados.push({
                    id: pago.id,
                    payment: pago.quantity,
                    made: pago.created,
                    payment_type: 2,
                })
        })

        data.anticipos_cuota.map((pago, i) => {
            const excluir = excluirRepetidos(pagos_realizados, pagos_todo, pago.id)

            //if(!excluir)
                data.pagos_realizados.push({
                    id: pago.id,
                    payment: parseFloat(pago.quantity) + parseFloat(pago.extra_amount),
                    made: pago.created,
                    payment_type: 3,
                });
        })

        const history_payments = data.pagos_realizados;

        let all_payments = []
        if(data.pagos_todo){
            all_payments = data.pagos_todo;
        }


        try{
            const openBox = data.oppeging_clossing_box.state == 1 ? true : false;
            dispatch({type: SET_OPEN_BOX_MOVEMENT, openBox});
        }catch(e){
            console.log("<<<<<<<<<<<<<<< Error >>>>>>>>>>>>>>>>>> ", e);
        }

        try{
            if (data.extra_amount) {
                dispatch({type: SET_EXTRA_AMOUNT_INITIAL, extra_amount_initial: data.extra_amount});
            }
            dispatch({type: SET_DATA_SALE_BOX, data});
            dispatch({type: GROUP_BILL});
            dispatch({type: SET_HISTORY_PAYMENTS, history_payments});
            dispatch({type: SET_ALL_PAYMENTS, all_payments});
        }catch(error){
            console.log("Error: ", error);
        }


        //dispatch({type: GROUP_BILL});
    }).catch((error) => {
        Swal(
            'Error',
             error.detail || 'Ha ocurrido un error, por favor vuelva a intentar.',
            'error'
        );
    });
};

const verifyFelCancel = (movement_id, mensaje=null) => (dispatch) => {
    console.log("Ejecutando función de verificación con id: ", movement_id);
    api.get("movements/verifyFelCancel", {movement_id}).then((data) => {

        //Comentar para habilitar la anulación FEL
        /*
        dispatch({type:SET_CANCEL_MASK, cancel_mask: false});
        Swal('Éxito', 'Factura anulada correctamente. ' + mensaje, 'success')
        .then(() => {
            dispatch(push('/report-movements/'));
        })
        */

        //Desomentar para habilitar la anulación FEL...

        //Descomentar para habilitar la anulación FEL
        console.log("data ---------> ", data);
        //const status = data[1];
        const status = data.status_fel_cancel
        console.log("data in verifyFel: ", status);
        if(status == 'waiting'){
            setTimeout(() => {
                dispatch(verifyFelCancel(movement_id));
            }, 1000);
        }else if(status === 'finished'){
            dispatch({type:SET_CANCEL_MASK, cancel_mask: false});
            Swal('Éxito', 'Factura anulada correctamente.', 'success')
            .then(() => {
                console.log("Factura anulada correctamente");
            })
            dispatch(push('/report-movements/'));
        }else{
            dispatch({type:SET_CANCEL_MASK, cancel_mask: false});
            console.log("Error detectado: ", status);

            Swal(
                'Error',
                 status,
                'error'
            );
        }
    }).catch((error)=>{
        Swal(
            'Error',
             error.detail || 'Ha ocurrido un error, por favor vuelva a intentar.',
            'error'
        );
    });
}

const cancelBill = () => (dispatch, getStore) => {
    const datos = getStore().form.FormConfirmCancel.values;
    const movement_id = datos.movement_id;
    const reason = datos.reason;

    console.log("datos: ----------------> ", datos);

    api.post("movements/cancelBill", { movement_id, reason }).then((data) => {
        dispatch({type:SET_CANCEL_MASK, cancel_mask: true});
        setTimeout(() => {
            dispatch(verifyFelCancel(movement_id, data.detail));
        }, 1000);
    }).catch((error) => {
        Swal(
            'Error',
             error.detail || 'Ha ocurrido un error, por favor vuelva a intentar.',
            'error'
        );
    });

    /*
    Swal.fire({
        title: '¿Está seguro(a) que desea anular la venta?',
        type: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#aaa',
        confirmButtonText: 'Aceptar',
        cancelButtonText: 'Cancelar',
        reverseButtons: true
    }).then((result) => {
        if (result.value) {
            api.post("movements/cancelBill", { movement_id }).then((data) => {
                dispatch({type:SET_CANCEL_MASK, cancel_mask: true});
                setTimeout(() => {
                    dispatch(verifyFelCancel(movement_id));
                }, 1000);
            }).catch((error) => {
                Swal(
                    'Error',
                     error.detail || 'Ha ocurrido un error, por favor vuelva a intentar.',
                    'error'
                );
            });
        }
    });
    */
}

const verifyFelCreditNote = (movement_id, mensaje=null) => (dispatch) => {
    console.log("Ejecutando función de verificación con id: ", movement_id);
    api.get("movements/verifyFelCreditNote", {movement_id}).then((data) => {
        console.log("data ---------> ", data);
        const status = data.status_fel_credit_note
        console.log("data in verifyFel: ", status);
        if(status == 'waiting'){
            setTimeout(() => {
                dispatch(verifyFelCreditNote(movement_id));
            }, 1000);
        }else if(status === 'finished'){
            dispatch({type:SET_CANCEL_MASK, cancel_mask: false});
            Swal('Éxito', 'Nota de crédito generada correctamente.', 'success')
            .then(() => {
                console.log("Nota de crédito generada correctamente");
            })
            dispatch(push('/report-movements/'));
        }else{
            dispatch({type:SET_CANCEL_MASK, cancel_mask: false});
            console.log("Error detectado: ", status);

            Swal(
                'Error',
                 status,
                'error'
            );
        }
    }).catch((error)=>{
        Swal(
            'Error',
             error.detail || 'Ha ocurrido un error, por favor vuelva a intentar.',
            'error'
        );
    });
}

const creditNote = () => (dispatch, getStore) => {
    const datos = getStore().form.FormConfirmCreditNote.values;
    const movement_id = datos.movement_id;
    const reason = datos.reason;

    console.log("datos: ----------------> ", datos);

    api.post("movements/creditNote", { movement_id, reason }).then((data) => {
        dispatch({type:SET_CANCEL_MASK, cancel_mask: true});
        setTimeout(() => {
            dispatch(verifyFelCreditNote(movement_id, data.detail));
        }, 1000);
    }).catch((error) => {
        Swal(
            'Error',
             error.detail || 'Ha ocurrido un error, por favor vuelva a intentar.',
            'error'
        );
    });
}

const totalDiferenciaMovement = (index, cantidad, tipo) => (dispatch, getStore) => {
    let facturas = getStore().movement.productsInCarOrdered;
    let isValid = false;

    facturas.forEach((factura, i) => {
        if(index === i){
            const total = factura.calculate.totalLabel;
            //const total = factura.calculate.total;
            //const total = factura.calculate.total - parseFloat(factura.dataBilling.retention) - parseFloat(factura.calculate.paid);

            const efectivo = (tipo === 'efectivo') ? cantidad : factura.dataPayments.cash;
            const tarjeta = (tipo === 'tarjeta') ? cantidad : factura.dataPayments.cards_total;
            const cuotas = (tipo === 'cuota') ?  cantidad : factura.dataPayments.dues_total;
            const depositos = (tipo === 'deposito') ?  cantidad : factura.dataPayments.deposit_total;

            const totalPagado = parseFloat(efectivo) + parseFloat(tarjeta) + parseFloat(cuotas) + parseFloat(depositos);

            console.log("Total pagado: ", totalPagado);

            const total_dif = total - parseFloat((totalPagado).toFixed(2));

            if(parseFloat(total_dif) >= 0){
                factura.calculate.resto = total_dif;
                isValid = true;
                //return true;
            }else{
                factura.calculate.resto = 0;
                //return false;
            }
        }
    });

    //console.log("FACTURAS IN totalDif()", facturas);

    dispatch({type:UPDATE_FACTURAS_MOVEMENT, facturas });
    return isValid;
}

const getCardsMovement = () => (dispatch) => {
    api.get('cards').catch((error) => {
        dispatch({type: LOADER_MOVEMENT, cargando: false});
        Swal(
            'Error',
            error.detail || 'Ha ocurrido un error, por favor vuelva a intentar.',
            'error'
        );
    }).then((data) => {
        if(data){
            let card_list = [];
            data.results.forEach(element => {
                card_list.push({ value: element.id, label: element.name });
            });

            dispatch({type: SET_CARD_LIST_MOVEMENT, card_list});
        }
    })
}

/**
 * Función utilizada para obtener los registros de las tarjetas y sus cuotas.
 */
const getDuesMovement = () => (dispatch) => {
    api.get('dues').catch((error) => {
        dispatch({type: LOADER_MOVEMENT, cargando: false});
        Swal(
            'Error',
            error.detail || 'Ha ocurrido un error, por favor vuelva a intentar.',
            'error'
        );
    }).then((data) => {
        if(data){

            let dues_list = [];
            data.results.forEach(element => {
                dues_list.push({ value: element.id, label: element.name, due_detail: element.due_detail });
            });

            dispatch({type: SET_DUES_LIST_MOVEMENT, dues_list});
        }
    })
}

const cardsPaymentMovement = () => (dispatch, getStore) => {
    const formData = getStore().form.cardsForm.values;
    const card_payments = formData.card_payments;
    const index = formData.index;
    let cardPayments = []; //add

    let total = 0;
    card_payments.forEach(element => {
        total += parseFloat(element.amount);
        let obj = element; //add
        obj.card = element.cards.value; //add
        obj.quantity = element.amount; //add
        obj.uuid = uuidv4(); //add por UUID

        cardPayments.push(obj);//add
    });
    total = total.toFixed(2);

    const verificarCantidad = dispatch(totalDiferenciaMovement(index, total, 'tarjeta'));

    if(verificarCantidad){
        let facturas = getStore().movement.productsInCarOrdered;
        facturas.forEach((factura, i) => {
            if(index === i){
                //factura.dataPayments.card_payments = card_payments;
                factura.dataPayments.card_payments = cardPayments; //add
                factura.dataPayments.cards_total = total;
            }
        });

        dispatch({type:UPDATE_FACTURAS_MOVEMENT, facturas});
        return true;
    }else{
        Swal.fire(
            'La cantidad que paga excede el total de la venta',
            'Por favor verifique',
            'question'
        )
        return false;
    }
}

const duesPaymentMovement = () => (dispatch, getStore) => {
    const formData = getStore().form.duesForm.values;
    const due_payments = formData.due_payments;
    const index = formData.index;
    const duePayments = []; //agregado

    let total = 0;
    let monto_extra_factura = getStore().movement.extra_amount_initial;
    if (monto_extra_factura == null){monto_extra_factura=0}
    let monto_extra=0;
    due_payments.forEach(element => {
        total += parseFloat(element.amount);
        let obj = element;
        let recargo=0;
        element.dues.due_detail.forEach(cuota =>{
            if(element.due_detail.value==cuota.id){
              recargo=(element.amount * (cuota.custumer_rate / 100 ))/1.12
            }
        })
        obj.quantity = element.amount;
        obj.due = element.dues.value;
        monto_extra += parseFloat(recargo.toFixed(2)) // recargo extra total
        obj.monto_extra= parseFloat(recargo.toFixed(2)); // recargo extra por cuota
        //obj.dues_quantity = element.due_detail.value;
        obj.dues_quantity = element.due_detail.label; //MOD
        obj.uuid = uuidv4();

        duePayments.push(obj);
    });
    total = total.toFixed(2);

    //console.log("Total dues Payment: ", total);
    const verificarCantidad = dispatch(totalDiferenciaMovement(index, total, 'cuota'));

    if(verificarCantidad){
        let facturas = _.cloneDeep(getStore().movement.productsInCarOrdered);
        facturas.forEach((factura, i) => {
            if(index === i){
                //factura.dataPayments.due_payments = due_payments;
                factura.dataPayments.due_payments = duePayments; //agregado
                factura.dataPayments.dues_total = total;
                if( due_payments.length > 0){
                    factura.calculate.monto_extra = parseFloat(monto_extra_factura)+parseFloat(monto_extra)
                }
                else{
                    factura.calculate.monto_extra = parseFloat(monto_extra_factura)
                }
            }
        });

        dispatch({type:UPDATE_FACTURAS_MOVEMENT, facturas});
        return true;
    }else{
        Swal.fire(
            'La cantidad que paga excede el total de la venta',
            'Por favor verifique',
            'question'
        )

        return false;
    }
}

const depositPaymentMovement = () => (dispatch, getStore) => {
    const formData = getStore().form.depositForm.values;
    const deposit_payments = formData.deposit_payments;
    const index = formData.index;
    let depositPayments = []; //add

    let total = 0;
    deposit_payments.forEach(element => {
        total += parseFloat(element.amount);
        let obj = element; //add
        obj.quantity = element.amount; //add
        obj.document_number = element.document_number; //add
        obj.uuid = uuidv4(), //agregado por uuid

        depositPayments.push(obj);//add
    });
    total = total.toFixed(2);

    const verificarCantidad = dispatch(totalDiferenciaMovement(index, total, 'deposito'));

    if(verificarCantidad){
        let facturas = getStore().movement.productsInCarOrdered;
        facturas.forEach((factura, i) => {
            if(index === i){
                factura.dataPayments.deposit_payments = depositPayments; //add
                factura.dataPayments.deposit_total = total;
            }
        });

        dispatch({type:UPDATE_FACTURAS_MOVEMENT, facturas});
        return true;
    }else{
        Swal.fire(
            'La cantidad que paga excede el total de la venta',
            'Por favor verifique',
            'question'
        )
        return false;
    }
}

const setCashPaymentMovement = (index, value) => (dispatch, getStore) => {
    //console.log("setCashPaymen() -> index: ", index, " value: ", value);

    const efectivo = isEmpty(value) ? 0 : parseFloat(value);
    const verificarCantidad = dispatch(totalDiferenciaMovement(index, efectivo, 'efectivo'));

    if(verificarCantidad){
        let facturas = getStore().movement.productsInCarOrdered;
        facturas.forEach((factura, i) => {
            if(index === i){
                factura.dataPayments.cash = efectivo;
            }
        });

        dispatch({type:UPDATE_FACTURAS_MOVEMENT, facturas});
        return true;
    }else{
        Swal.fire(
            'La cantidad que paga excede el total de la venta',
            'Por favor verifique',
            'question'
        )
        return false;
    }
}

const addDepositMovement = (id_movement) => (dispatch, getStore) => {
    console.log("id de movimiento ?????????????????????????????????????? ", id_movement);

    const data_sale = getStore().movement;

    if(data_sale.openCashBox.isOpen){
        const facturas = data_sale.productsInCarOrdered;
        const oppeging_closing_box = data_sale.openCashBox.data.id;
        console.log("Oppening_closing_box", oppeging_closing_box);

        api.post("movements/addPayment", {facturas, oppeging_closing_box}).then((data) => {
            console.log("DATA DEL BACKEND", data);

            Swal('Éxito', 'Deposito registrado correctamente.', 'success')
            .then(() => {

                setTimeout(() => {
                    const url_red = '/payments-history/' + id_movement + '/cobrar';
                    dispatch(push(url_red));
                  }, 500);
            })
        }).catch((error) => {
            Swal(
                'Error',
                 error.detail || 'Ha ocurrido un error, por favor vuelva a intentar.',
                'error'
            );
        });
    }else{
        Swal.fire(
            'No puede realizar el pago, no hay una caja abierta',
            'Por favor verifique',
            'question'
        )
        return false;
    }
}

const checkCashBoxMovement = () => (dispatch) =>{
    api.get("cash_box_op/checkCashBoxGeneral").catch((error) => {
        Swal(
            'Error',
            error.detail || 'Ha ocurrido un error, por favor vuelva a intentar.',
            'error'
        );
    }).then((data) => {
        if(data){
            console.log("Data del oppeging closing box: ", data);

            let openCashBox = Object()
            if(data == "THERE_IS_NO_OPEN_CASHBOX"){
                openCashBox.isOpen = false;
                openCashBox.data = null;
            }else{
                openCashBox.isOpen = true;
                openCashBox.data = data;
            }
            dispatch({type: SET_CHECK_CASHBOX_MOVEMENT, openCashBox});
        }
    })
}

const set_final_date = (value) => (dispatch) => {
    console.log("final date: " , value);
    dispatch({type: SET_FINAL_DATE, final_date: value});
    // dispatch(listar());
}

const set_initial_date = (value) => (dispatch) => {
    console.log("initial date: " , value);
    dispatch({type: SET_INICIAL_DATE, initial_date: value});
    // dispatch(listar());
}

const verifyCreateFel = (id_movement) => (dispatch) => {
    api.get("movements/verifyCreateFel", {id_movement}).then((data) => {
        status = data.status_fel;
        if(status === 'waiting'){
            setTimeout(() => {
                dispatch(verifyCreateFel(id_movement));
            }, 1000);
        }else if(status === 'finished'){
            Swal('Éxito', 'Factura electrónica registrada correctamente', 'success').then(()=>{
                dispatch(viewOneSale(id_movement));
            })
            dispatch({type:SET_CANCEL_MASK, cancel_mask: false});
        }else{
            Swal(
                'Error',
                 status,
                'error'
            )
            dispatch({type:SET_CANCEL_MASK, cancel_mask: false});
        }
    }).catch((error)=>{
        Swal(
            'Error',
             error.detail || 'Ha ocurrido un error, por favor vuelva a intentar.',
            'error'
        );
        dispatch({type:SET_CANCEL_MASK, cancel_mask: false});
    })
}

const createFel = (id_movement) => (dispatch) => {
    Swal.fire({
        title: '¿Está seguro(a) que desea crear la factura electrónica?',
        type: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#aaa',
        confirmButtonText: 'Aceptar',
        cancelButtonText: 'Cancelar',
        reverseButtons: true
    }).then((result) => {
        if (result.value) {
            api.post(`movements/createFel`, { id_movement}).then((data) => {
                dispatch({type:SET_CANCEL_MASK, cancel_mask: true});
                setTimeout(() => {
                    dispatch(verifyCreateFel(id_movement));
                }, 500);
            }).catch((error) => {
                Swal('Error', error.detail , 'error')
            })
        }
    });
}

export const actions = {
    listar,
    search,
    filtro,
    searchDate,
    viewOneSale,
    cancelBill,
    getCardsMovement,
    getDuesMovement,

    cardsPaymentMovement,
    duesPaymentMovement,
    depositPaymentMovement,
    setCashPaymentMovement,
    addDepositMovement,
    checkCashBoxMovement,
    set_initial_date,
    set_final_date,
    createFel,

    creditNote
};

export const reducer = {
    [SET_INICIAL_DATE]: (state, { initial_date }) => {
        return{
            ...state,
            initial_date
        }
    },

    [SET_FINAL_DATE]: (state, { final_date }) => {
        return{
            ...state,
            final_date
        }
    },

    [SET_CARD_LIST_MOVEMENT]: (state, { card_list }) => {
        return{
            ...state,
            card_list
        }
    },

    [SET_DUES_LIST_MOVEMENT]: (state, { dues_list }) => {
        return{
            ...state,
            dues_list
        }
    },

    [UPDATE_FACTURAS_MOVEMENT]: (state, { facturas }) => {
        //console.log("UPDATE FACTURS: ", facturas);
        return{
            ... state,
            productsInCarOrdered: facturas
        }
    },

    [SET_CHECK_CASHBOX_MOVEMENT]: (state, { openCashBox }) => {
        return {...state, openCashBox }
    },

    [LOADER_MOVEMENT]: (state, { cargando }) => {
        return {...state, cargando }
    },
    [SET_DATA_MOVEMENT]: (state, { data }) => {
        return {...state, data }
    },
    [SET_PAGE_MOVEMENT]: (state, { page }) => {
        return {...state, page }
    },
    [SET_BUSCADOR_MOVEMENT]: (state, { buscador }) => {
        return {...state, buscador }
    },
    [SET_FILTRO_MOVEMENT]: (state, { filtro_rol }) => {
        return {...state, filtro_movement }
    },
    [SET_BUSCADOR_FECHA_MOVEMENT]: (state, { buscador_fecha }) => {
        return {...state, buscador_fecha }
    },
    [SET_DATA_SALE_BOX]: (state, {data}) => {

        console.log("data en SET_DATA_SALE_BOX movement.js (Redux): ", data);

        let arreglo = [];
        let payment = 0;
        const patientInfo = {};
        const movement = data;
            const correlative = movement.correlative;
            const movement_id = movement.id;
            const codigo = movement.codigo;

            const fel_error = movement.fel_error;
            const fel_status = movement.fel_status;
            const fel_cancel_status = movement.fel_cancel_status;
            const serie = movement.serie;
            const numero = movement.numero;
            const numeroAutorizacion = movement.numeroAutorizacion;

            const factura = movement.document_type === "REC" ? "recibo" : "factura";
            const financial_entity = movement.financial_entity.id;
            const financial_entity_name = movement.financial_entity.usuario_fel;

            const total_movement = movement.payment;
            const total_paid = movement.paid;
            const monto_extra= movement.extra_amount;
            const retention = movement.retention_amount;
            const fecha = movement.made_fel;
            const cliente = movement.final_client_name;

            const opening_box = movement.oppeging_clossing_box.state;

            const estado = movement.state;

            const active = movement.active;

            let medic = null;
            if(movement.doctor){
                const medic = { value: movement.doctor.id, label: movement.doctor.name };
            }

            const patient = {
                value: movement.patient.id,
                label: movement.patient.first_name,
                last_name: movement.patient.last_name,
            };


            const recibo = movement.financial_entity.afiliacion_fel === "exento" ? true : false;

            const referent = {
                financial_entity: 0,
                //idReferentCategory: movement.referent.institution.referent_category.id,
                idReferentCategory: 0,
                label: movement.referent.name,
                //usuario_fel: movement.referent.institution.referent_category.financial_entity.usuario_fel,
                usuario_fel: movement.financial_entity.usuario_fel,
                //receipt: movement.referent.institution.referent_category.issue_receipt,
                receipt: recibo,
                value: movement.referent.id,
            };

            //agregado
            const datos_facturacion = {name: movement.name, nit: movement.nit};
            //agregado...

            const nit = movement.nit;
            patientInfo.medic = medic;
            patientInfo.patient = patient;
            patientInfo.referent = referent;
            patientInfo.nit = nit;

            payment += parseFloat(movement.payment);

            movement.movement_detail_movement.forEach(detail => {
                let coupons = [];
                detail.coupon_payment.forEach(cupon => {
                    const service = cupon.coupon.service !== null ? cupon.coupon.service.id : null; //add
                    const coupon = {
                        amount: cupon.coupon.amount,
                        amount_label: cupon.quantity,
                        code: cupon.coupon.code,
                        id: cupon.coupon.id,
                        name: cupon.coupon.name,
                        percentage: null,
                        //service: cupon.coupon.service.id,
                        service, //add
                        type_coupon: cupon.coupon.type_coupon,
                        used: cupon.coupon.used,
                    };

                    coupons.push(coupon);
                });

                const descuento = (((parseFloat(detail.discount) * 100) / parseFloat(detail.price)) / 100);
                const total_static = parseFloat((parseFloat(detail.price) * parseInt(detail.quantity)).toFixed(2));

                const service = {
                    cant: detail.quantity,
                    is_charge: detail.service.is_charge,
                    coupons,
                    descuento,
                    descuentoValor: detail.discount,
                    descuento_limite: detail.service.discount,
                    discount_authorization: detail.discount_authorization,
                    cupon: false,
                    factura,
                    financial_entity,
                    financial_entity_name,
                    id: detail.service.id,
                    name: detail.service.name,
                    price: detail.price,
                    total: parseFloat(detail.subtotal),
                    totalStatic: total_static,
                    correlative,
                    movement_id,
                    codigo,
                    medic,
                    patient,

                    active,


                    fel_error,
                    fel_status,
                    fel_cancel_status,
                    serie,
                    numero,
                    numeroAutorizacion,
                    estado,


                    total_movement,
                    total_paid,
                    monto_extra,
                    retention,
                    fecha,
                    cliente,

                    opening_box,
                    datos_facturacion, //add Ricky
                };

                arreglo.push(service);
            });

        return {
            ...state,
            productsInCar: arreglo,
            totalInCar: payment,
            patientInfo,
        }
    },
    [GROUP_BILL]: (state) => {
        const arreglo = state.productsInCar;
        const initial_extra = state.extra_amount_initial;
        const result = groupBy(arreglo, (a) => a.financial_entity_name);
        let facturas = [];
        let cont = 0;
        let monto_extra=initial_extra;
        Object.entries(result).forEach((element) => {
            let total = 0, descuento=0;
            let financial_entity = { id: 0 , name: element[0] }
            //modificado...
            let dataBilling = {
                                nit: "",
                                name: "",
                                correlative: null,
                                code:"",
                                state: 0,
                                id: 0,
                                fel_status:0,
                                fel_error: '',
                                serie:'',
                                numero:'',
                                numeroAutorizacion:'',
                                fel_cancel_status:0,
                                total_movement:0,
                                total_paid:0,
                                retention:0,
                                uuid: uuidv4(),
                                cash_uuid:uuidv4(),
                                fecha: '',
                                cliente: '',
                                active: false,
                                opening_box: false,
                            };
            //El id en dataBilling es el id del movimiento si hubiere sido de una cuenta abierta
            let patientInfo = { medic: null, patient: null }; //add

            let verify_movement_id = false;
            let verify_code = false;


            let verify_fel_status = false;
            let verify_fel_cancel_status = false;
            let verify_fel_error  = false;
            let verify_serie = false;
            let verify_numero = false;
            let verify_numeroAutorizacion = false;
            let verify_state = false;

            let verify_total_movement = false;
            let verify_total_paid = false;
            let verify_retention = false;
            let verify_fecha = false;
            let verify_cliente = false;

            let verify_active = false;

            let verify_opening_box = false;
            let verify_datos_facturacion = false;

            let services = [];
            element[1].forEach((service, key) => {
                let descuento_sumado = 0;
                if(key === 0){
                    financial_entity.id = service.financial_entity;
                    financial_entity.factura = service.factura;
                    dataBilling.correlative = service.correlative;
                }

                if(!verify_movement_id && service.movement_id !== 0){
                    dataBilling.id = service.movement_id;
                    verify_movement_id = true;
                }

                if(!verify_code && service.code !== ''){
                    dataBilling.code = service.codigo;
                    verify_code = true;
                }

                if(!verify_fel_status && service.fel_status !== 0){
                    dataBilling.fel_status = service.fel_status;
                    verify_fel_status = true;
                }

                if(!verify_fel_cancel_status && service.fel_cancel_status !== 0){
                    dataBilling.fel_cancel_status = service.fel_cancel_status;
                    verify_fel_cancel_status = true;
                }

                if(!verify_fel_error && service.fel_error !== ''){
                    dataBilling.fel_error = service.fel_error;
                    verify_fel_error = true;
                }

                if(!verify_serie && service.serie !== ''){
                    dataBilling.serie = service.serie;
                    verify_serie = true;
                }

                if(!verify_numero && service.numero !== ''){
                    dataBilling.numero = service.numero;
                    verify_numero = true;
                }

                if(!verify_numeroAutorizacion && service.numeroAutorizacion !== ''){
                    dataBilling.numeroAutorizacion = service.numeroAutorizacion;
                    verify_numeroAutorizacion = true;
                }

                if(!verify_state && service.state !== ''){
                    dataBilling.state = service.estado;
                    verify_state = true;
                }

                if(!verify_total_movement && service.total_movement !== 0){
                    dataBilling.total_movement = service.total_movement;
                    verify_total_movement = true;
                }

                if(!verify_total_paid && service.total_paid !== 0){
                    dataBilling.total_paid = service.total_paid;
                    verify_total_paid = true;
                }

                if(!verify_retention && service.retention !== 0){
                    dataBilling.retention = service.retention
                    verify_retention = true;
                }

                if(!verify_fecha && service.fecha !== ''){
                    dataBilling.fecha = service.fecha
                    verify_fecha = true;
                }

                if(!verify_cliente && service.cliente !== ''){
                    dataBilling.cliente = service.cliente
                    verify_cliente = true;
                }

                if(!verify_active && service.active !== ''){
                    dataBilling.active = service.active
                    verify_active = true;
                }

                if(!verify_opening_box && service.opening_box !== ''){
                    dataBilling.opening_box = service.opening_box
                    verify_opening_box = true;
                }

                //Add Ricky
                if(!verify_datos_facturacion && service.verify_datos_facturacion !== null){
                    dataBilling.name = service.datos_facturacion.name;
                    dataBilling.patient = `${state.patientInfo.patient.label} ${state.patientInfo.patient.last_name}`
                    dataBilling.nit = service.datos_facturacion.nit;
                    verify_datos_facturacion = true;
                }

                let totc = service.cant * service.price;

                total += service.is_charge ? 0 : parseFloat( parseFloat(totc).toFixed(2) );
                monto_extra += parseFloat(service.monto_extra);
                descuento += parseFloat(service.descuentoValor);
                descuento_sumado += parseFloat(service.descuentoValor); //ADD
                service.coupons.forEach(coupon => {
                    descuento += parseFloat(coupon.amount_label);
                    descuento_sumado += parseFloat(coupon.amount_label); //ADD
                });

                let obj = {
                    coupons: service.coupons,
                    service_name: service.name,
                    service: service.id,
                    price: service.price,
                    quantity : service.cant,
                    discount : service.descuentoValor,
                    discount_authorization: service.discount_authorization,
                    accumulated_discount: descuento_sumado,//ADD
                    subtotal : service.total,
                };

                services.push(obj);
            });

            console.log("dataBilling.retention: ", dataBilling.retention);

            let totalLabel = parseFloat((total.toFixed(2) - descuento.toFixed(2)).toFixed(2));
            totalLabel = parseFloat((totalLabel - parseFloat(dataBilling.retention)).toFixed(2));

            console.log("total label ------------------> ", totalLabel);

            let resto = parseFloat((dataBilling.total_movement - dataBilling.total_paid - parseFloat(dataBilling.retention)).toFixed(2));

            //const pago_mas_retencion = parseFloat((parseFloat(dataBilling.total_paid) + parseFloat(dataBilling.retention)).toFixed(2));
            //console.log("PAGO MÁS RETENCIÓN: ", pago_mas_retencion);
            //let resto = parseFloat((dataBilling.total_movement - pago_mas_retencion).toFixed(2));

            /*
            if(resto === 0){
                resto = totalLabel;
            }
            */

            console.log("RESTO ------> :",  resto);

            const calculate = { total: totalLabel, monto_extra: parseFloat(monto_extra), ya_pagado:resto, totalLabel:resto, descuento: parseFloat(descuento.toFixed(2)), resto };
            const dataPayments = {
                                    card_payments:[],
                                    due_payments: [],
                                    deposit_payments: [],
                                    cash: 0,
                                    retention: false,
                                    retention_amount: 0,
                                    cards_total: 0,
                                    dues_total: 0,
                                    deposit_total:0,
                                    index:cont,
                                    type: "venta",
                                };
            facturas.push({ financial_entity, services, dataPayments, calculate, dataBilling });
            cont++;
        })

        //console.log("FACTURAS: ", facturas);

        return{
            ...state,
            productsInCarOrdered: facturas
        }
    },
    [SET_CANCEL_MASK]: (state, { cancel_mask }) => {
        return {
            ...state,
            cancel_mask,
        }
    },
    [SET_OPEN_BOX_MOVEMENT]: (state, { openBox }) => {
        return {
            ...state,
            openBox,
        }
    },
    [SET_HISTORY_PAYMENTS]: (state, { history_payments }) => {
        return {
            ...state,
            history_payments,
        }
    },
    [SET_ALL_PAYMENTS]: (state, { all_payments }) => {
        return {
            ...state,
            all_payments,
        }
    },
    [SET_EXTRA_AMOUNT_INITIAL]: (state, { extra_amount_initial }) => {
        return {
            ...state,
            extra_amount_initial,
        }
    }
};

export const initialState = {
    cargando: false,
    page: 1,
    data: {
        count: 0,
        next: null,
        previous: null,
        results: [],
    },
    buscador: '',
    filtro_movement: null,
    updateData: {},
    buscador_fecha: moment(new Date()).format("YYYY-MM-DD"),

    productsInCar: [],
    productsInCarOrdered: [],
    datosFacturacion: null,
    cancel_mask: false,
    openBox: false,

    card_list: [
    ],
    dues_list: [
    ],
    data_payments: [
    ],

    openCashBox: null,
    extra_amount_initial: 0,
    history_payments: [],
    all_payments: [],
    initial_date: moment().date(1).format("DD/MM/YYYY"),
    final_date: moment().format("DD/MM/YYYY"),
};

export default handleActions(reducer, initialState);
