import React, { Component } from 'react';
import Table from '../Utils/Grid';
import { BreakLine } from '../Utils/tableOptions';
import { activeFormatter }  from '../Utils/Acciones/Acciones'
import { showNestedFields } from '../Utils/MyOptions'; //Mi archivo (Ricky)

import { validate, validators } from 'validate-redux-form';
import { Field, reduxForm, formValueSelector } from 'redux-form';
import { modalTextArea, modalCurrency }  from 'Utils/renderField/renderField';
import { RenderMoneda }  from 'Utils/renderField/renderTableField';
import { RenderCurrency } from 'Utils/renderField/renderReadField';
import { Link  } from "react-router-dom";

import MyModal from '../Utils/myModal';
import Spoiler from '../Utils/Spoiler';
import TableDescription from './tableDescription';

const BoxDetail = (props) => {
    let total = (props.totalVentasEfectivo + props.totalVentasTarjeta + props.totalIngresos + props.totalApertura);

    const alinear_derecha = {
        textAlign: 'right',
    };

    return(
        <div>
            <div className="table-decoration mx-5 mt-3 mb-3 d-flex flex-column">
                    <div className="fields">
                        <div className="top-title">
                            DETALLE DE CAJA
                        </div>

                        <div className="pt-3 pl-5 pr-5">
                            {props.paso_final !== true &&
                            <div className="w-100 d-flex justify-content-center mb-1">
                                            <Link to="/oppening-closing/close-box" className="btn btn-primary btn-sm">Cerrar Caja</Link>
                            </div>
                            }
                            {props.summary_boxes && props.summary_boxes.length > 0 ?
                                <React.Fragment>
                                {props.summary_boxes.map((detalle, i) =>
                                    <Spoiler show={(i === 0) ? true : true } label={detalle.usuario_fel} key={i}>
                                        <TableDescription
                                            {...detalle}
                                            addCash={props.addCash}
                                            id_or={props.id}
                                            summary_category={props.summary_category}
                                            openAccount={props.openAccount}
                                            paso_final={props.paso_final}
                                        />
                                    </Spoiler>
                                )}
                                </React.Fragment>
                                :

                                <div className="d-flex flex-row justify-content-center p-3">
                                    <h4>No se han realizado movimientos</h4>
                                </div>
                            }
                        </div>
                    </div>
            </div>

            <div className="table-decoration mx-5 mt-5 mb-0 d-flex flex-column">
                <div className="fields">
                    <div className="top-title">
                        LISTADO DE MOVIMIENTOS
                    </div>

                    <Table
                            onPageChange={props.listarMovimientos}
                            data={props.dataMovimientos}
                            loading={props.cargando}
                            page={props.page}
                        >
                            <TableHeaderColumn
                                tdStyle={BreakLine}
                                thStyle={BreakLine}
                                dataField="made" dataSort>Fecha</TableHeaderColumn>

                            <TableHeaderColumn
                                tdStyle={BreakLine}
                                thStyle={BreakLine}
                                dataField="movement_type" dataSort
                                dataFormat={(cell, row)=>{
                                    if(row.movement_type === 'Apertura de caja'){
                                        return "Apertura de caja";
                                    }
                                    else if(row.movement_type == "Venta" && row.state == 3){
                                        if(row.movement_ref === null){
                                            return "Venta";
                                        }else{
                                            return "Ingreso de efectivo por venta";
                                        }
                                    }else if(row.movement_type == "Venta" && row.state == 2){
                                        return "Cuenta Abierta";
                                    }else if(row.movement_type == "Venta" && row.state == 4){
                                        return "Cuenta por Cobrar";
                                    }else if(row.movement_type === "Anticipo" || row.movement_type === 'Cobro'){
                                        let detalle = row.movement_type;
                                        if(row.payment_type === 2){
                                            detalle += " (Tarjeta de crédito)";
                                        }else if(row.payment_type === 3){
                                            detalle += " (Cuotas)";
                                        }else if(row.payment_type === 4){
                                            detalle += " (Depósito)";
                                        }

                                        return(
                                            <div className='d-flex flex-column'>
                                            <span>{detalle}</span>
                                            {row.movement_ref &&
                                                <a
                                                    href={`/#/report-movements/view-bill/${row.movement_ref.id}/movimiento`}
                                                    target='_blank'
                                                >
                                                {row.movement_ref.correlative &&
                                                    <React.Fragment>{row.movement_ref.correlative}</React.Fragment>
                                                }
                                                </a>
                                            }
                                            </div>
                                        )

                                    }else{
                                        return " --- ";
                                    }
                                }}
                                >Tipo</TableHeaderColumn>

                            <TableHeaderColumn
                                tdStyle={BreakLine}
                                thStyle={BreakLine}
                                dataField="patient"
                                dataSort
                                dataFormat={(cell, row)=>{
                                    if(cell != null){
                                        return cell.first_name + " " + cell.last_name;
                                    }else{
                                        return " --- ";
                                    }
                                }}
                                >Paciente</TableHeaderColumn>

                            <TableHeaderColumn
                                tdStyle={BreakLine}
                                thStyle={BreakLine}
                                dataField="concept" dataSort>Concepto</TableHeaderColumn>

                            <TableHeaderColumn
                                tdStyle={BreakLine}
                                thStyle={BreakLine}
                                dataField="user" formatExtraData="user__username" dataSort={true} dataFormat={showNestedFields}>Operador</TableHeaderColumn>

                            <TableHeaderColumn
                                tdStyle={BreakLine}
                                thStyle={BreakLine}
                                dataField="document_type"
                                dataSort={true}
                                dataFormat={(cell, row)=>{
                                    return(
                                        <div className='d-flex flex-column'>
                                        {cell}
                                        {row.correlative &&
                                            <span>{row.correlative}</span>
                                        }
                                        </div>
                                    );
                                }}
                            >Tipo Documento</TableHeaderColumn>

                            <TableHeaderColumn
                                tdStyle={BreakLine}
                                thStyle={BreakLine}
                                dataFormat={(cell, row) => {
                                    const total = parseFloat(cell) + parseFloat(row.extra_amount)
                                    return <RenderMoneda monto={total} />
                                }}
                                dataField="payment"
                                dataSort
                            >
                                Pago de
                            </TableHeaderColumn>

                            <TableHeaderColumn
                                tdStyle={alinear_derecha}
                                dataField="id"
                                isKey={true}
                                dataAlign="center"
                                dataFormat={
                                    activeFormatter({
                                        imp_factura: '/report-movements/print-bill',
                                        ver_factura: '/report-movements/view-bill',
                                        //anular_factura: '/report-movements/cancel-bill',
                                        ruta_retorno: 'caja',
                                })}
                                >Acciones</TableHeaderColumn>
                        </Table>

                    <div className="d-flex">

                    </div>
                </div>
            </div>
        </div>
    );
}

export default BoxDetail;
