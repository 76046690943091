import React from 'react';
import { Link } from 'react-router-dom';
import { Field, reduxForm } from 'redux-form';
import { validate, validators } from 'validate-redux-form';
import { renderField, renderSearchSelect, renderSelectCustom, renderSwitch } from 'Utils/renderField/renderField';
import { api } from "api";
import './style';

let entities=[];
const getEntity = (search)  =>{
    return api.get("financial_entities", {search}).catch((error) => {})
        .then((data) => {
            if (data){
                entities = [];
                data.results.forEach(x=>{
                    entities.push({value: x.id, label: x.usuario_fel});
                })

                return entities;
            }
            else{
                return []
            }
        })
        .catch(e => {
            return []
        })
}

const Form = props => {

    const {
        handleSubmit
    } = props;

    const view = window.location.href.includes("view");

    return (
        <form onSubmit={handleSubmit} className="py-2 px-5">
           <div className="px-md-5 d-flex justify-content-around flex-column flex-sm-row align-items-stretch align-items-sm-center">
               <div className="m-2">
                    <div className="d-flex justify-content-start flex-column flex-sm-row align-items-stretch align-items-sm-center">
                        <label htmlFor="name" className="m-0">Nombre</label>
                    </div>
                    <div className="d-flex justify-content-start flex-column flex-sm-row align-items-stretch align-items-sm-center">
                        <Field name="name" disabled={view} autofocus component={renderField} type="text" className="form-control"/>
                    </div>
                </div>
                <div className="m-2">
                    <div className="d-flex justify-content-start flex-column flex-sm-row align-items-stretch align-items-sm-center">
                        <label htmlFor="description" className="m-0">Descripcion</label>
                    </div>
                    <div className="d-flex justify-content-start flex-column flex-sm-row align-items-stretch align-items-sm-center">
                        <Field name="description" disabled={view} component={renderField} type="text" className="form-control"/>
                    </div>
                </div>
            </div>
            <div className="px-md-5 d-flex justify-content-around flex-column flex-sm-row align-items-stretch align-items-sm-center">
                <div className="m-2">
                    <div className="d-flex justify-content-start flex-column flex-sm-row align-items-stretch align-items-sm-center">
                        <label htmlFor="financial_entity" className="m-0">Entidad Financiera</label>
                    </div>
                    <div className="d-flex justify-content-start flex-column flex-sm-row align-items-stretch align-items-sm-center">
                        <Field
                            name="financial_entity"
                            valueKey="value"
                            labelKey="label"
                            label="financial_entity"
                            component={renderSearchSelect}
                            loadOptions={getEntity}
                            className="no-border"
                            disabled={view}
                            className="caregory-referent-switch"
                        />
                    </div>
                </div>
                <div className="m-2">
                    <div className="d-flex justify-content-start flex-column flex-sm-row align-items-stretch align-items-sm-center">
                        <label htmlFor="financial_entity" className="m-0">Emite Factura o Recibo</label>
                    </div>
                    <div className="d-flex justify-content-start flex-column flex-sm-row align-items-stretch align-items-sm-center">
                        <div className="radio">
                            <label htmlFor="issue_receipt" className="radio-inline mr-5 d-flex align-items-center">
                                <Field name="issue_receipt" disabled={ view } component={renderField} type="radio" value={"false"}/>
                                &nbsp;Factura
                            </label>
                        </div>
                        <div className="radio">
                            <label htmlFor="issue_receipt" className="radio-inline mr-5 d-flex align-items-center">
                                <Field name="issue_receipt" disabled={ view } component={renderField} type="radio" value={"true"}/>
                                &nbsp;Recibo
                            </label>
                        </div>

                        {/*
                        <Field
                            name="issue_receipt"
                            component={renderSwitch}
                            className="form-control"
                            disabled={view}
                        />
                        <Field
                            name="comodin"
                            component="input"
                            type="text"
                            placeholder="Last Name"
                            className="form-control comodin invisible"
                        />
                        */}
                    </div>
                </div>
            </div>
            <div className="row-fluid">
                <div className="d-flex justify-content-center flex-column flex-sm-row align-items-stretch align-items-sm-center">
                    <Link className="btn btn-secondary m-3" to="/referent-categories">{!view ? ("Cancelar") : ("Regresar")}</Link>
                    {!view && (<button type="submit" className="btn btn-primary m-3">Guardar</button>)}
                </div>
            </div>
       </form>
    )
};



const ReferentCategoryForm = reduxForm({
    form: 'referentCategory',
    validate: data => {
        return validate(data, {
            'name':validators.exists()('Campo Requerido'),
            'financial_entity':validators.exists()('Campo Requerido'),
            'issue_receipt':validators.exists()('Campo Requerido'),
        })
    }
})(Form);

export default ReferentCategoryForm
