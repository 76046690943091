import React, {Component} from 'react';
import Modal from 'react-responsive-modal';
import classNames from 'classnames';
import "../notations";


export class Primera extends Component {
    constructor(props) {
        super(props);
        this.state = {open: false, numero: null};
    }

    openModal = () => {
        this.setState({ open: true });
    };

    customOpen = () =>{
        const {setClick} = this.props;
        this.openModal()
        setClick && setClick(true)
    }

    closeModal = () => {
        this.setState({ open: false });
    };

    selectOption = (input, value) => {
        if(value > 29){
            this.setState({numero: value});
        }else{
            if(value < 1 && this.state.numero != null){
                const final_value = this.state.numero + value;
                input.onChange(final_value);
                this.closeModal();
            }
        }
    };

    render() {
        const { input, meta: { touched, error }, title } = this.props;
        const { open } = this.state;
        const invalid = touched && error;

        let fi = 30;
        const ff = 37;

        let ci = 0;
        const cf = 4;

        let columnas = [];

        for(fi;fi<=ff;fi++){
            ci = 0;
            let fila = [];
            for(ci;ci<=cf;ci++){
                const cantidad = fi + (ci*8);
                fila.push(cantidad)
            }
            columnas.push(fila);
        }


        return (
            <React.Fragment>
                <Modal open={open} onClose={this.closeModal} >
                    <div className="text-center">
                        <h2 className="border-bottom">Escala de Queratometría</h2>
                    </div>
                    <div className='d-flex flex-row w-100'>
                        <span className='w-100' style={{fontSize:50, marginLeft:322}}> . </span>
                    </div>
                    <div className='d-flex flex-row'>
                        <table id="notations-table-modal">
                            <tr>
                            </tr>
                            <tbody>
                            {columnas.map(columna=>(
                                <tr key={columna}>
                                    {columna.map(fila=>(
                                        <React.Fragment key={fila}>
                                        {fila <=60 &&
                                        <td key={fila}>
                                            <div className="radio">
                                                <label htmlFor="service_type" className="radio-inline mr-5 d-flex align-items-center"
                                                    onClick={() => this.selectOption(input, fila)}>
                                                    <input type={"radio"} value={fila} {...input} onChange={() => this.selectOption(input, fila)}
                                                        className={classNames('form-control', { 'is-invalid': invalid })} />
                                                    &nbsp;{fila}
                                                </label>
                                            </div>
                                        </td>
                                        }
                                        </React.Fragment>
                                    ))}
                                </tr>
                            ))}
                            </tbody>
                        </table>


                        <table id="decimals-table-modal" style={{borderLeft: 'solid 2px black'}}>
                            <tr>
                            </tr>
                            <tbody>
                                <tr>
                                    <td></td>
                                    <td>
                                        <div className="radio">
                                            <label htmlFor="service_type" className="radio-inline mr-5 d-flex align-items-center ml-4"
                                                onClick={() => this.selectOption(input, 0.00)}>
                                                <input type={"radio"} value={0.00} {...input} onChange={() => this.selectOption(input, 0.00)}
                                                    className={classNames('form-control', { 'is-invalid': invalid })} />
                                                &nbsp;{0.00}
                                            </label>
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td></td>
                                    <td>
                                        <div className="radio">
                                            <label htmlFor="service_type" className="radio-inline mr-5 d-flex align-items-center ml-4"
                                                onClick={() => this.selectOption(input, 0.12)}>
                                                <input type={"radio"} value={0.12} {...input} onChange={() => this.selectOption(input, 0.12)}
                                                    className={classNames('form-control', { 'is-invalid': invalid })} />
                                                &nbsp;{0.12}
                                            </label>
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td></td>
                                    <td>
                                        <div className="radio">
                                            <label htmlFor="service_type" className="radio-inline mr-5 d-flex align-items-center ml-4"
                                                onClick={() => this.selectOption(input, 0.25)}>
                                                <input type={"radio"} value={0.25} {...input} onChange={() => this.selectOption(input, 0.25)}
                                                    className={classNames('form-control', { 'is-invalid': invalid })} />
                                                &nbsp;{0.25}
                                            </label>
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td></td>
                                    <td>
                                        <div className="radio">
                                            <label htmlFor="service_type" className="radio-inline mr-5 d-flex align-items-center ml-4"
                                                onClick={() => this.selectOption(input, 0.37)}>
                                                <input type={"radio"} value={0.37} {...input} onChange={() => this.selectOption(input, 0.37)}
                                                    className={classNames('form-control', { 'is-invalid': invalid })} />
                                                &nbsp;{0.37}
                                            </label>
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td></td>
                                    <td>
                                        <div className="radio">
                                            <label htmlFor="service_type" className="radio-inline mr-5 d-flex align-items-center ml-4"
                                                onClick={() => this.selectOption(input, 0.50)}>
                                                <input type={"radio"} value={0.50} {...input} onChange={() => this.selectOption(input, 0.50)}
                                                    className={classNames('form-control', { 'is-invalid': invalid })} />
                                                &nbsp;{0.50}
                                            </label>
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td></td>
                                    <td>
                                        <div className="radio">
                                            <label htmlFor="service_type" className="radio-inline mr-5 d-flex align-items-center ml-4"
                                                onClick={() => this.selectOption(input, 0.62)}>
                                                <input type={"radio"} value={0.62} {...input} onChange={() => this.selectOption(input, 0.62)}
                                                    className={classNames('form-control', { 'is-invalid': invalid })} />
                                                &nbsp;{0.62}
                                            </label>
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td></td>
                                    <td>
                                        <div className="radio">
                                            <label htmlFor="service_type" className="radio-inline mr-5 d-flex align-items-center ml-4"
                                                onClick={() => this.selectOption(input, 0.75)}>
                                                <input type={"radio"} value={0.75} {...input} onChange={() => this.selectOption(input, 0.75)}
                                                    className={classNames('form-control', { 'is-invalid': invalid })} />
                                                &nbsp;{0.75}
                                            </label>
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td></td>
                                    <td>
                                        <div className="radio">
                                            <label htmlFor="service_type" className="radio-inline mr-5 d-flex align-items-center ml-4"
                                                onClick={() => this.selectOption(input, 0.87)}>
                                                <input type={"radio"} value={0.87} {...input} onChange={() => this.selectOption(input, 0.87)}
                                                    className={classNames('form-control', { 'is-invalid': invalid })} />
                                                &nbsp;{0.87}
                                            </label>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>

                    <div className="d-flex justify-content-center align-items-center mt-4">
                        <div
                            className="btn btn-secondary mx-4"
                            onClick={() => {
                                input.onChange(undefined);
                                this.closeModal();
                            }}>
                            Cancelar
                        </div>
                    </div>
                </Modal>
                <span className="form-control text-center" onClick={this.customOpen} >{input.value}</span>
            </React.Fragment>
        );
    }
}
