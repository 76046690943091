import './calendar.css';
import classNames from 'classnames';
import Color from 'color';
import { api } from "api";
import moment from "moment";
import Table from '../Utils/Grid';
import PropTypes from 'prop-types';
// import { Field, reduxForm } from 'redux-form';
import { Link } from 'react-router-dom';
import React, { Component } from 'react';
import AsyncSelect from 'react-select/lib/Async';
import { BreakLine } from '../Utils/tableOptions';
import HeaderSimple from '../Utils/Header/HeaderSimple';
import { activeFormatter }  from '../Utils/Acciones/Acciones';
import { renderSearchSelect } from 'Utils/renderField/renderField';
import Modal from 'react-responsive-modal'; 
import { Tooltip } from 'reactstrap';

import MomentLocaleUtils, {
    formatDate,
    parseDate,
} from 'react-day-picker/moment';
import DayPickerInput from 'react-day-picker/DayPickerInput';

let persons = []

const hours = [
    {value:0.0, label:'0:00 AM', hrs:'00:00'}, {value:0.5, label:'0:30 AM', hrs:'00:30'},
    {value:1.0, label:'1:00 AM', hrs:'01:00'}, {value:1.5, label:'1:30 AM', hrs:'01:30'},
    {value:2.0, label:'2:00 AM', hrs:'02:00'}, {value:2.5, label:'2:30 AM', hrs:'02:30'},
    {value:3.0, label:'3:00 AM', hrs:'03:00'}, {value:3.5, label:'3:30 AM', hrs:'03:30'},
    {value:4.0, label:'4:00 AM', hrs:'04:00'}, {value:4.5, label:'4:30 AM', hrs:'04:30'},
    {value:5.0, label:'5:00 AM', hrs:'05:00'}, {value:5.5, label:'5:30 AM', hrs:'05:30'},
    {value:6.0, label:'6:00 AM', hrs:'06:00'}, {value:6.5, label:'6:30 AM', hrs:'06:30'},
    {value:7.0, label:'7:00 AM', hrs:'07:00'}, {value:7.5, label:'7:30 AM', hrs:'07:30'},
    {value:8.0, label:'8:00 AM', hrs:'08:00'}, {value:8.5, label:'8:30 AM', hrs:'08:30'},
    {value:9.0, label:'9:00 AM', hrs:'09:00'}, {value:9.5, label:'9:30 AM', hrs:'09:30'},

    {value:10.0, label:'10:00 AM', hrs:'10:00'}, {value:10.5, label:'10:30 AM', hrs:'10:30'},
    {value:11.0, label:'11:00 AM', hrs:'11:00'}, {value:11.5, label:'11:30 AM', hrs:'11:30'},
    {value:12.0, label:'12:00 PM', hrs:'12:00'}, {value:12.5, label:'12:30 PM', hrs:'12:30'},
    {value:13.0, label:'1:00 PM', hrs:'13:00'}, {value:13.5, label:'1:30 PM', hrs:'13:30'},
    {value:14.0, label:'2:00 PM', hrs:'14:00'}, {value:14.5, label:'2:30 PM', hrs:'14:30'},
    {value:15.0, label:'3:00 PM', hrs:'15:00'}, {value:15.5, label:'3:30 PM', hrs:'15:30'},
    {value:16.0, label:'4:00 PM', hrs:'16:00'}, {value:16.5, label:'4:30 PM', hrs:'16:30'},
    {value:17.0, label:'5:00 PM', hrs:'17:00'}, {value:17.5, label:'5:30 PM', hrs:'17:30'},
    {value:18.0, label:'6:00 PM', hrs:'18:00'}, {value:18.5, label:'6:30 PM', hrs:'18:30'},
    {value:19.0, label:'7:00 PM', hrs:'19:00'}, {value:19.5, label:'7:30 PM', hrs:'19:30'},
    {value:20.0, label:'8:00 PM', hrs:'20:00'}, {value:20.5, label:'8:30 PM', hrs:'20:30'},
    {value:21.0, label:'9:00 PM', hrs:'21:00'}, {value:21.5, label:'9:30 PM', hrs:':2130'},
    {value:22.0, label:'10:00 PM', hrs:'22:00'}, {value:22.5, label:'10:30 PM', hrs:'22:30'},
    {value:23.0, label:'11:00 PM', hrs:'23:00'}, {value:23.5, label:'11:30 PM', hrs:'23:30'},
]

const getPersons = (search) => {
    return api.get("referents/listDoctors", {search}).catch((error) => {})
        .then((data) => {
            if (data){
                persons = [];
                data.results.forEach(x=>{
                    persons.push({value: x.id, label: x.name})
                })
                return persons
            }
            else{
                return []
            }
        })
        .catch(e => {
            return []
        })
}

export const TitleCalendarRow = ( styles ) => {
    return(
        <div className="d-flex flex-grow-1">
            <div className="div-as-margin-title">
            </div>
            <div className="calendar-title-item d-flex align-items-center">
                Nombre de quien agenda
            </div>
            <div className="calendar-title-item d-flex align-items-center">
                Nombre del paciente
            </div>
            <div className="calendar-title-item-small d-flex align-items-center">
                Télefono
            </div>
            <div className="calendar-title-item d-flex align-items-center">
                Doctor(a)
            </div>
            <div className="calendar-title-item-small d-flex align-items-center">
                Acciones
            </div>
        </div>
    )
}

export const ScheduleEmptyRow = ({finish_hour, click, date, doctor_id, id, destroy, hour, appointment_holder, patient_name, phone_holder, doctor, ready, color} ) => {
    return(
        <Link to={'/appointments/create'} onClick={() => {click(date, {value: hour.value, label: hour.hrs}, { value: finish_hour.value, label: finish_hour.hrs})}}>
            <div className="d-flex flex-grow-1">
                <div className="div-as-margin d-flex justify-content-center" >
                    {hour.hrs}
                </div>
                <div className="schedule-row-item d-flex align-items-center" style={{borderLeft: `3px solid ${color}`, background: `${Color(color).alpha(0.3).lighten(0.5).hsl().string()}`}}>
                    {appointment_holder}
                </div>
                <div className="schedule-row-item d-flex align-items-center" style={{background: `${Color(color).alpha(0.3).lighten(0.5).hsl().string()}`}}>
                    {patient_name}
                </div>
                <div className="schedule-row-item-small d-flex align-items-center" style={{background: `${Color(color).alpha(0.3).lighten(0.5).hsl().string()}`}}>
                    {phone_holder}
                </div>
                <div className="schedule-row-item d-flex align-items-center" style={{background: `${Color(color).alpha(0.3).lighten(0.5).hsl().string()}`}}>
                    {doctor}
                </div>
                <div className="schedule-row-item-small d-flex align-items-center actions" style={{background: `${Color(color).alpha(0.3).lighten(0.5).hsl().string()}`}}>
                </div>
            </div>
        </Link>
    )
}

export const ScheduleRow = ({finish_hour, click, date, doctor_id, id, destroy, hour, appointment_holder, patient_name, phone_holder, doctor, ready, color} ) => {
    return(
        <div className="d-flex flex-grow-1">
            <div className="div-as-margin d-flex justify-content-center" >
                {hour.hrs}
            </div>
            <div className="schedule-row-item d-flex align-items-center" style={{borderLeft: `3px solid ${color}`, background: `${Color(color).alpha(0.3).lighten(0.5).hsl().string()}`}}>
                {appointment_holder}
            </div>
            <div className="schedule-row-item d-flex align-items-center" style={{background: `${Color(color).alpha(0.3).lighten(0.5).hsl().string()}`}}>
                {patient_name}
            </div>
            <div className="schedule-row-item-small d-flex align-items-center" style={{background: `${Color(color).alpha(0.3).lighten(0.5).hsl().string()}`}}>
                {phone_holder}
            </div>
            <div className="schedule-row-item d-flex align-items-center" style={{background: `${Color(color).alpha(0.3).lighten(0.5).hsl().string()}`}}>
                {doctor}
            </div>
            <div className="schedule-row-item-small d-flex align-items-center actions" style={{background: `${Color(color).alpha(0.3).lighten(0.5).hsl().string()}`}}>
                {
                    appointment_holder &&
                        <React.Fragment>
                            <div className={`check ${ ready == 'yes' ? '' : 'invisible'}`}/>
                            <Link to={`/appointments/${id}`}><div className="edit-patient action_space" /></Link>
                            <div className="delete-patient" onClick={() => destroy(id, date, date, doctor_id)}/>
                        </React.Fragment>
                }
            </div>
        </div>
    )
}

export const ScheduleLockedRow = ({hour}) => {
    return(
        <div className="d-flex flex-grow-1">
            <div className="div-as-margin d-flex justify-content-center" >
                {hour.hrs}
            </div>
            <div className="schedule-row-item d-flex align-items-center" style={{borderLeft: `3px solid #dcdcdc`, background: `#dcdcdc`}}>
            </div>
            <div className="schedule-row-item d-flex align-items-center" style={{background: `#dcdcdc`}}>
            </div>
            <div className="schedule-row-item-small d-flex align-items-center" style={{background: `#dcdcdc`}}>
            </div>
            <div className="schedule-row-item d-flex align-items-center" style={{background: `#dcdcdc`}}>
            </div>
            <div className="schedule-row-item-small d-flex align-items-center actions" style={{background: `#dcdcdc`}}>
            </div>
        </div>
    )
}

class Appointments extends Component {

    state = {
        date: "",
        base_date: "",
        doctor_id: '',
        current_date: moment().format("DD/MM/YYYY"),
    }

    componentWillMount() {
        this.props.listar(moment().format('YYYY-MM-DD'), moment().format('YYYY-MM-DD'), this.state.doctor_id, 1)
        moment.locale('es')
        this.setState({
            date: moment(),
            base_date: moment(),
            doctor_id: '',
        })
    }

    returnDayString(number){
        if(number == 0){
            return "DOMINGO"
        } else if(number == 1){
            return "LUNES"
        } else if(number == 2){
            return "MARTES"
        } else if(number == 3){
            return "MIERCOLES"
        } else if(number == 4){
            return "JUEVES"
        } else if(number == 5){
            return "VIERNES"
        } else if(number == 6){
            return "SABADO"
        } else {
            return "ERROR DE DIA"
        }
    }
    toggle = (id) => {
        return () => {
            const { state: { tooltips }} = this;
            tooltips[id] = !tooltips[id];
            this.setState({
                tooltips
            });
        }
    }
    isTooltipOpen = (id) => {
        //return !!this.state.tooltips[id];
        return false;
    }

    changeDay = (value) => {
        const new_date = moment(this.state.date, "DD-MM-YYYY").add(value,'days'); //add

        return () => {
            this.setState({
                date: moment(this.state.date, "DD-MM-YYYY").add(value,'days'),
                current_date: new_date.format("DD/MM/YYYY"), //add
            }, () => {
                if(!this.state.doctor_id) {
                    this.props.listar(this.state.date.format("YYYY-MM-DD"), this.state.date.format("YYYY-MM-DD"), this.state.doctor_id, 1)
                } else {
                    this.props.listar(this.state.date.format("YYYY-MM-DD"), this.state.date.format("YYYY-MM-DD"), this.state.doctor_id)
                }
            })
        }
    }

    render() {

        const {
            listar,
            detail,
            create,
            update,
            destroy,
            changeCreateInitializeData,
            isNavBarOpen,
        } = this.props;

        const {
            cargando,
            page,
            data,
            date,
            end,
            start,
            appointments
        } = this.props;

        //const cleanPatient = this.props.tooltip_info && this.props.tooltip_info.patient ? this.props.tooltip_info.patient.split("/")[0].trim() : '';

        const cleanPatient = this.props.tooltip_info && this.props.tooltip_info.patient ? this.props.tooltip_info.patient : '';

        return (
            <div className={classNames('content-with-tabs', { 'content-with-tabs-min': isNavBarOpen })}>
                <div className="d-inline-flex w-100">
                    <div className="row-fluid w-50 tab-background-blue">
                        <a href="/#/appointments" className="d-flex justify-content-center align-items-center tab-active tab-br-tr">
                            CITAS DE HOY
                        </a>
                    </div>
                    <div className="row-fluid w-50 tab-background">
                        <a href="/#/schedule" className="d-flex justify-content-center align-items-center tab tab-br-bl">
                            AGENDA
                        </a>
                    </div>
                </div>

                <Modal
                    open={this.props.modal_tooltip_show}
                    onClose={() => this.props.showTooltipModal(false)}
                    styles={{
                        modal: {
                            background: '#516060',
                        }
                    }}
                >
                    {!!this.props.tooltip_info &&
                        <div className="d-flex flex-column modal-tooltip">
                            <div className="d-flex mx-3 mt-3 bold">Nombre de quién agenda:</div>
                            <div className="d-flex ml-4 mr-3 mt-1 pl-2">{this.props.tooltip_info.holder}</div>
                            <div className="d-flex mx-3 mt-2 bold">Nombre del paciente:</div>
                            <div className="d-flex ml-4 mr-3 mt-1 pl-2">{cleanPatient}</div>

                            <div className="d-flex mx-3 mt-2 bold">Contacto:</div>
                            <div className="d-flex ml-4 mr-3 mt-1 pl-2">{this.props.tooltip_info.phone} / {this.props.tooltip_info.cellphone}</div>

                            {this.props.tooltip_info.note &&
                            <React.Fragment>
                                <div className="d-flex mx-3 mt-2 bold">Descripción:</div>
                                <div className="d-flex ml-4 mr-3 mt-1 pl-2">{this.props.tooltip_info.note}</div>
                            </React.Fragment>
                            }

                            {this.props.tooltip_info.requirements &&
                            <React.Fragment>
                                <div className="d-flex mx-3 mt-2 bold">Requerimientos:</div>
                                <div className="d-flex ml-4 mr-3 mt-1 pl-2">{this.props.tooltip_info.requirements}</div>
                            </React.Fragment>
                            }
                            <div className="d-flex mx-3 mt-2 bold">Registrado por:</div>
                            <div className="d-flex ml-4 mr-3 mt-1 pl-2"><strong>{this.props.tooltip_info.user}</strong>&nbsp;[{moment(this.props.tooltip_info.created).format("DD-MM-YYYY HH:mm:ss")}]</div>

                            {/*
                            <div className="d-flex mx-3 mt-2 bold">Teléfono:</div>
                            <div className="d-flex ml-4 mr-3 mt-1 pl-2">{this.props.tooltip_info.phone}</div>
                            <div className="d-flex mx-3 mt-2 bold">Celular:</div>
                            <div className="d-flex ml-4 mr-3 mt-1 pl-2">{this.props.tooltip_info.cellphone}</div>
                            */}
                            <div className="d-flex flex-column mx-3 mt-3 mb-1 tooltip-app-info">
                                <div className="d-flex justify-content-around">
                                    {this.returnDayString(moment(this.props.tooltip_info.date, "YYYY-MM-DD").weekday())}
                                    &nbsp;
                                    {moment(this.props.tooltip_info.date, "YYYY-MM-DD").date()}
                                    &nbsp;
                                    DE
                                    &nbsp;
                                    {moment(this.props.tooltip_info.date, "YYYY-MM-DD").format("MMMM").toUpperCase()}
                                </div>
                                <div className="d-flex justify-content-around">
                                    <div>{this.props.tooltip_info.start}</div>
                                    <div>A</div>
                                    <div>{this.props.tooltip_info.end}</div>
                                </div>
                            </div>
                            <div className="d-flex justify-content-end mt-3 top-border-white actions py-1 pr-1">
                                {!this.props.tooltip_info.in_line &&
                                    <img
                                        className="tooltip-add-icon"
                                        src={require("../../../../assets/img/icons/add-icon-white.png")}
                                        alt="add"
                                        title='pasar a cola'
                                        onClick={()=>this.props.pasarACola(this.props.tooltip_info.id,null, null, this.state.doctor_id)}
                                    />
                                }
                                <div className="action-separator mx-3"/>
                                <Link to={`/appointments/${this.props.tooltip_info.id}`}><div className="edit-patient-white tooltip-action" /></Link>
                                <div className="action-separator mx-3"/>
                                <div className="delete-patient-white mr-2" onClick={() => destroy(this.props.tooltip_info.id, null, null, this.state.doctor_id)}></div>
                                <div className="action-separator invisible "/>
                            </div>
                        </div>
                    }
                </Modal>
                {/*

                    NEXT <div> IS THE WHOLE CALENDAR (DAILY VIEW)

                */}

                <div className="table-decoration mx-3 mt-3 mb-3 d-flex flex-column">
                    {/*

                        THIS IS THE MAIN BAR

                    */}
                    <div className="d-flex hrs-bb">
                        <div className="d-flex my-2 mr-3 ml-3 justify-content-between w-100 flex-column">
                            <div className="d-flex">
                                <div className="d-flex d-md-none align-items-center calendar-date-label">
                                    &nbsp;{this.state.date.date()}
                                    &nbsp;/&nbsp;{this.state.date.month()}
                                    &nbsp;/&nbsp;{this.state.date.format("YYYY")}
                                    &nbsp;
                                </div>

                                <div className="d-md-flex d-none align-items-center calendar-date-label">
                                    {this.returnDayString(this.state.date.day())}
                                    &nbsp;&nbsp;{this.state.date.date()}
                                    &nbsp;&nbsp;DE&nbsp;&nbsp;{this.state.date.format("MMMM").toUpperCase()}
                                    &nbsp;&nbsp;DEL&nbsp;&nbsp;{this.state.date.format("YYYY")}
                                    &nbsp;
                                </div>
                                <div className="d-flex align-items-center ml-auto">
                                    <div className="d-flex align-items-center calendar-date-label mr-2">
                                        DOCTOR
                                    </div>
                                    <div className=" d-flex justify-content-end flex-column flex-sm-row align-items-stretch align-items-sm-center">
                                        <AsyncSelect
                                            isClearable
                                            className="form-control p-0 border-trasparent"
                                            classNamePrefix="react-select"
                                            theme={(theme) => {
                                                return {
                                                    ...theme,
                                                    borderRadius: '1em',
                                                    background: 'white',
                                                    colors: {
                                                        ...theme.colors,
                                                        text: '#415362',
                                                        primary25: '#ebebeb',
                                                        primary: '#0a5995',
                                                    },
                                                }
                                            }}                                            
                                            cache={false} placeholder="Todos los doctores"
                                            defaultOptions={ true }
                                            searchPromptText="Todos los doctores"
                                            loadOptions={getPersons}
                                            onChange={(el) => {
                                                if (el)
                                                    this.setState(
                                                        {doctor_id: el.value},
                                                        () => listar(this.state.date.format("YYYY-MM-DD"), this.state.date.format("YYYY-MM-DD"), this.state.doctor_id)
                                                    )
                                                else
                                                    this.setState(
                                                        {doctor_id: ""},
                                                        () => listar(this.state.date.format("YYYY-MM-DD"), this.state.date.format("YYYY-MM-DD"), this.state.doctor_id)
                                                    )
                                                }
                                            }
                                            // onChange={(el) => listar(this.state.date.format("YYYY-MM-DD"), el.value)}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="d-flex">
                                <a onClick={this.changeDay(-1)} className="button d-flex align-items-center br-left mr-2" href="/#/appointments/">
                                    &lt;
                                </a>
                                <a onClick={this.changeDay(1)} className="button d-flex align-items-center br-right mr-2" href="/#/appointments/">
                                    &gt;
                                </a>

                                <DayPickerInput
                                    id="filtro desde:"
                                    classNames={{container:"date-picker-custom input-back-date mr-2", overlay:"date-picker-custom-overlay"}}
                                    formatDate={formatDate}
                                    parseDate={parseDate}
                                    value={this.state.current_date}     // VALOR QUE SE TIENE EN REDUX
                                    inputProps={{disabled: false}}
                                    dayPickerProps={{
                                        locale: 'es',
                                        localeUtils: MomentLocaleUtils,
                                        modifiers: {
                                            disabled: { after: null, before: null }
                                        }
                                    }}
                                    onDayChange={(val) => {
                                        const date = moment(val).format("DD/MM/YYYY");
                                        const send_date = moment(val).format("YYYY-MM-DD");
                                        
                                        this.setState({
                                            date: moment(date, "DD-MM-YYYY"),
                                        });

                                        if(!this.state.doctor_id) {                                            
                                            this.props.listar(send_date, send_date, this.state.doctor_id, 1);
                                        } else {                                            
                                            this.props.listar(send_date, send_date, this.state.doctor_id);
                                        }                                                                                
                                    }}
                                />

                                <a href="/#/appointments/create" className="button d-flex align-items-center brb ml-1" >Agendar cita</a>
                                <a href="/#/appointments/lock-schedule" className="button-gray d-flex align-items-center brb ml-2" >Bloquear horario</a>
                            </div>
                        </div>
                    </div>
                    {/*

                        THIS IS THE BAR WITH ALL TITLES OF THE ROWS

                    */}
                    {
                        !!this.state.doctor_id ?
                            <div className="d-flex flex-column">
                                <TitleCalendarRow className="mb-2"/>
                                {
                                    hours.map((hour, index) => {
                                        if(hour.value >= 7.0 && hour.value < 19.0){
                                            const matches = data.filter(function (el) {
                                                return el.start_value == hour.value;
                                            })
                                            matches.sort((item, item_2) =>
                                                item_2.doctor.id - item.doctor.id
                                            )
                                            if(matches.length){
                                                return (
                                                    matches.map((appointment, index2) => (
                                                        appointment.locked ?
                                                            <ScheduleLockedRow
                                                                key={`${index2}.match`}
                                                                hour={hour}
                                                            />
                                                        :
                                                            <ScheduleRow
                                                                key={`${index2}.match`}
                                                                id={appointment.id}
                                                                date={this.state.date}
                                                                destroy={this.props.destroy}
                                                                doctor_id={this.state.doctor_id}
                                                                color={appointment.doctor.color}
                                                                doctor={appointment.doctor.name}
                                                                hour={(index2 == 0) && hour}
                                                                phone_holder={appointment.phone_holder}
                                                                ready={(appointment.patient != null) ? "yes" : "" }
                                                                appointment_holder={appointment.appointment_holder}
                                                                patient_name={(appointment.patient != null) ? `${appointment.patient.first_name} ${appointment.patient.last_name}` : appointment.patient_name}
                                                            />
                                                    ))
                                                )
                                            } else {
                                                return (
                                                    <ScheduleEmptyRow
                                                        key={index}
                                                        color={"#ffffff"}
                                                        hour={hour}
                                                        finish_hour={hours[index+1]}
                                                        date={this.state.date}
                                                        click={this.props.changeCreateInitializeData}
                                                    />
                                                )
                                            }
                                        }
                                    })
                                }
                            </div>
                    :
                        <div className="d-flex">
                            <div className="d-flex flex-column weekly-hrs-daily-view">
                                {
                                    hours.map((hour, index) => {
                                        if(hour.value >= 7.0 && hour.value < 19.0){
                                            return(
                                                <Link key={`${index}.link`} to={'/appointments/create'} onClick={() => {this.props.changeCreateInitializeData(this.state.date, {value: hour.value, label: hour.hrs}, { value: hours[index+1].value, label: hours[index+1].hrs})}}>
                                                    <div key={index} className=" cell-height cell-hrs align-items-start">
                                                        {hour.hrs}
                                                    </div>
                                                </Link>
                                            )
                                        }
                                    })
                                }
                            </div>
                            {
                               
                                appointments.map((day, index) => {
                                    return(
                                        index == 0 &&
                                            <div key={`day.${index}`} className="day-column-daily-view d-flex flex-column">
                                                {
                                                    
                                                    day.map((hour, index_2) => {
                                                        if(index_2 >= 14 && index_2 < 38){
                                                            return(
                                                                
                                                                <div key={`${index_2}.empty.stikcer`} >
                                                                    <div key={index_2} className="cell-height cell-hrs align-items-center schedule-cell-border d-flex">
                                                                        {
                                                                            hour.map((sticker, index_3) => {
                                                                                if (sticker){
                                                                                    
                                                                                    return(
                                                                                        <div key={`${index}.${index_2}.${index_3}`} className="d-flex cell-height equal-size cell-label-hide" style={{borderLeft: `3px solid ${sticker.color}`, position: "relative", background: `${Color(sticker.color).alpha(0.3).lighten(0.5).hsl().string()}`}}>
                                                                                            <div id={`sid_${sticker.sid}`} className="d-flex align-items-center cell-height label-text-cell w-100"
                                                                                                onClick={()=>this.props.showTooltipModal(true, sticker)}
                                                                                            >
                                                                                                {sticker.first && `${sticker.patient}`}
                                                                                                <Tooltip
                                                                                                    placement="right"
                                                                                                    autohide={false}
                                                                                                    target={`sid_${sticker.sid}`}
                                                                                                    delay={{ show: 0, hide: 35 }}
                                                                                                    toggle={this.toggle(sticker.sid)}
                                                                                                    isOpen={this.isTooltipOpen(sticker.sid)}
                                                                                                >
                                                                                                    <div className="d-flex flex-column">
                                                                                                        <div className="d-flex mx-3 mt-3 bold">Nombre de quién agenda:</div>
                                                                                                        <div className="d-flex ml-4 mr-3 mt-1 pl-2">{sticker.holder}</div>
                                                                                                        <div className="d-flex mx-3 mt-2 bold">Nombre del paciente:</div>
                                                                                                        <div className="d-flex ml-4 mr-3 mt-1 pl-2">{sticker.patient}</div>
                                                                                                        <div className="d-flex mx-3 mt-2 bold">Teléfono:</div>
                                                                                                        <div className="d-flex ml-4 mr-3 mt-1 pl-2">{sticker.phone}</div>
                                                                                                        <div className="d-flex flex-column mx-3 mt-3 mb-1 tooltip-app-info">
                                                                                                            <div className="d-flex justify-content-around">
                                                                                                                {this.returnDayString(moment(sticker.date, "YYYY-MM-DD").weekday())}
                                                                                                                &nbsp;
                                                                                                                {moment(sticker.date, "YYYY-MM-DD").date()}
                                                                                                                &nbsp;
                                                                                                                DE
                                                                                                                &nbsp;
                                                                                                                {moment(sticker.date, "YYYY-MM-DD").format("MMMM").toUpperCase()}
                                                                                                            </div>
                                                                                                            <div className="d-flex justify-content-around">
                                                                                                                <div>{sticker.start}</div>
                                                                                                                <div>A</div>
                                                                                                                <div>{sticker.end}</div>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                        <div className="d-flex justify-content-end mt-3 top-border-white actions py-1 pr-1">
                                                                                                            <div className="action-separator mx-3"/>
                                                                                                            <Link to={`/appointments/${sticker.id}`}><div className="edit-patient-white tooltip-action" /></Link>
                                                                                                            <div className="action-separator mx-3"/>
                                                                                                            <div className="delete-patient-white mr-2" onClick={() => destroy(sticker.id, this.state.week.monday, this.state.week.sunday)}></div>
                                                                                                            <div className="action-separator invisible "/>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </Tooltip>
                                                                                            </div>
                                                                                        </div>
                                                                                    )
                                                                                } else {
                                                                                    return(
                                                                                            <div key={`${index}.${index_2}.${index_3}`} className="d-flex cell-height equal-size" style={{borderLeft: '3px solid transparent', background: 'transparent'}}/>
                                                                                    )
                                                                                }
                                                                            })
                                                                        }
                                                                    </div>
                                                                </div>
                                                            )
                                                        }
                                                    })
                                                }
                                            </div>
                                    )
                                })
                            }
                        </div>
                    }
                </div>
            </div>
        )
    }
}

export default Appointments;
