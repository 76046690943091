import React, { Fragment } from "react";
import {
    renderField,
    renderSelectCustom,
    renderFieldCheck,
    renderSelectField2,
    renderFieldDPI,
} from "../../Utils/renderField/renderField";
import { Field, reduxForm, formValueSelector } from "redux-form";
import { validate, validators } from "validate-redux-form";
import { connect } from "react-redux";
import trim from "lodash/trim";
import validarNit from "validar-nit-gt";
import { api } from "api";
import Swal from "sweetalert2";

export const isEmpty = (value) =>
    value === undefined || value === null || value === "";
export function NIT(value) {
    if (!isEmpty(value)) {
        value = trim(value.toUpperCase());
        return value !== "C/F"
            ? validarNit(value)
                ? null
                : "Escriba un nit válido"
            : null;
    }
    return null;
}


const cuiIsValid = (cui) => {
    if (!cui) {
      return true;
    }

    cui = cui.toString();

    var cuiRegExp = /^[0-9]{4}\s?[0-9]{5}\s?[0-9]{4}$/;
    if (!cuiRegExp.test(cui)) {
      return false;
    }

    cui = cui.trim();
    cui = cui.replace(/\s/, "");
    cui = cui.replace(" ", "");
    var depto = parseInt(cui.substring(9, 11), 10);
    var muni = parseInt(cui.substring(11, 13));
    var numero = cui.substring(0, 8);
    var verificador = parseInt(cui.substring(8, 9));
    let munisPorDepto = [ 17, 8, 16, 16, 13, 14, 19, 8, 24, 21, 9, 30, 32, 21, 8, 17, 14, 5, 11, 11, 7, 17 ]
    if (depto === 0 || muni === 0) {
      return false;
    }
    if (depto > munisPorDepto.length) {
      return false;
    }
    if (muni > munisPorDepto[depto - 1]) {
      return false;
    }
    var total = 0;
    for (var i = 0; i < numero.length; i++) {
      total += numero[i] * (i + 2);
    }
    var modulo = total % 11;
    return modulo === verificador;
  };

export function DPI(value) {
    if (!isEmpty(value)) {
        value = trim(value.toUpperCase());
        return value !== "C/F"
            ? cuiIsValid(value)
                ? null
                : "Escriba un dpi válido"
            : null;
    }
    return null
}

const required = (value) => (value ? undefined : "Campo requerido.");
const validaNoEspacios = (value) => value && value.indexOf(" ") >= 0 ? "No se permiten espacios" : undefined;

class FrmBillingInformation extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            is_rental: false,
            tipo_documento: "",
            data_Paciente: {},
            initValueOptionMap: {
                NIT: "",
                CUI: "",
                EXT: "",
                CF: "C/F",
            },
            valueOptionMap: {
                NIT: "",
                CUI: "",
                EXT: "",
                CF: "C/F",
            },
        };
    }

    componentDidMount() {
        api.get(`patients/${this.props.patientID}`)
            .catch((error) => {
                Swal(
                    "Error",
                    error.detail ||
                    "Ha ocurrido un error, por favor vuelva a intentar.",
                    "error"
                );
            })
            .then((data) => {
                this.setState({
                    data_Paciente: data,
                    valueOptionMap: {
                        NIT: data.nit || "",
                        CUI: data.dpi || "",
                        EXT: "",
                        CF: "C/F",
                    },
                });
            });
    }

    // componentDidUpdate(prevProps, prevState, snapshot) {
    //     // si se cambia tipo_documento y cambia a EXT, se limpia el valor de nit
    //     if (this.state.tipo_documento !== prevState.tipo_documento && this.state.tipo_documento == "EXT") {
    //         this.props.changeFieldFormBillingInformation("nit", "")
    //     }
    //     console.log(prevState)
    //     console.log(this.state)

    // }

    render() {
        const {
            show_fields,
            handleSubmit,
            validateNit,
            name,
            total_pagar,
            changeFieldFormBillingInformation,
            setDataName,
        } = this.props;

        let optionsDocumentos = [
            { value: "NIT", label: "NIT", id: "NIT" },
            { value: "CUI", label: "CUI", id: "CUI" },
            { value: "EXT", label: "EXT", id: "EXT" },
        ];

        if (total_pagar < 2500) {
            optionsDocumentos = [
                ...optionsDocumentos,
                { value: "CF", label: "CF", id: "CF" },
            ];
        }

        const firstNameFieldValidators = name ? [] : [required];

        return (
            <form onSubmit={handleSubmit} id="billing_information">
                <div className="px-md-5 pt-3 d-flex flex-column flex-sm-row align-items-stretch align-items-sm-center">
                    <div className="radio">
                        <label className="radio-inline mr-5 d-flex align-items-center">
                            <Field
                                name="option"
                                component={renderField}
                                type="radio"
                                value={"a"}
                                inputId="firstFocus"
                                onChange={(e) => {
                                    changeFieldFormBillingInformation(
                                        "tipo_documento",
                                        ""
                                    );
                                    changeFieldFormBillingInformation(
                                        "name",
                                        ""
                                    );
                                    changeFieldFormBillingInformation(
                                        "nit",
                                        ""
                                    );
                                    changeFieldFormBillingInformation(
                                        "tipo_documdocumento_pacienteento",
                                        ""
                                    );
                                }}
                            />
                            &nbsp;Facturar con datos del paciente
                        </label>
                    </div>
                    <div className="radio">
                        <label className="radio-inline mr-5 d-flex align-items-center">
                            <Field
                                name="option"
                                component={renderField}
                                type="radio"
                                value={"b"}
                                onChange={(e) => {
                                    changeFieldFormBillingInformation(
                                        "tipo_documento",
                                        ""
                                    );
                                    changeFieldFormBillingInformation(
                                        "name",
                                        ""
                                    );
                                    changeFieldFormBillingInformation(
                                        "nit",
                                        ""
                                    );
                                    changeFieldFormBillingInformation(
                                        "tipo_documdocumento_pacienteento",
                                        ""
                                    );
                                }}
                            />
                            &nbsp;Ingresar nuevos datos
                        </label>
                    </div>
                    {/*<div className="radio">
                        <label className="radio-inline mr-5 d-flex align-items-center">
                            <Field name="option" component={renderField} type="radio" value={"c"}/>
                            &nbsp;CF
                        </label>
                    </div>*/}
                </div>

                {/*
                <div className="d-flex justify-content-around flex-column flex-sm-row align-items-stretch align-items-sm-center">
                    <div className="m-2">
                        <div className="d-flex justify-content-start flex-column flex-sm-row align-items-stretch align-items-sm-center">
                            <label htmlFor="Option" className="m-0">Datos de facturación</label>
                        </div>
                        <div className=" d-flex justify-content-start flex-column flex-sm-row align-items-stretch align-items-sm-center">
                            <Field name="option" component={renderSelectCustom} options={[
                                {value:1, label:'CONTINUAR CON LOS DATOS'},
                                {value:2, label:'INGRESAR DATOS'},
                                {value:3, label:'CF'},
                            ]}/>
                        </div>
                    </div>
                    <div className="m-2 invisible">
                        <div className="d-flex justify-content-start flex-column flex-sm-row align-items-stretch align-items-sm-center">
                            <label htmlFor="Comodin" className="m-0">Comodín</label>
                        </div>
                        <div className=" d-flex justify-content-start flex-column flex-sm-row align-items-stretch align-items-sm-center">
                            <Field name="comodin" component={renderField} type="text" className="form-control w-75"/>
                        </div>
                    </div>
                </div>
                */}
                {!show_fields && (
                    <div className="d-flex justify-content-around flex-column flex-sm-row align-items-stretch align-items-sm-center ">
                        <div className="m-2">
                            <div className="d-flex justify-content-start flex-column flex-sm-row align-items-stretch align-items-sm-center">
                                <label htmlFor="nit" className="m-0">
                                    Tipo documento
                                </label>
                            </div>
                            <div className=" d-flex justify-content-start flex-column flex-sm-row align-items-stretch align-items-sm-center">
                                <Field
                                    name="tipo_documento"
                                    component={renderSelectField2}
                                    type="text"
                                    validate={[required, validaNoEspacios]}
                                    className="form-control w-75"
                                    options={optionsDocumentos}
                                    item_vacio={true}
                                    disabled={false}
                                    onChange={(e) => {
                                        if (e.target.value == "EXT") {
                                            changeFieldFormBillingInformation(
                                                "documento_paciente",
                                                ""
                                            );
                                        } else {
                                            changeFieldFormBillingInformation(
                                                "documento_paciente",
                                                this.state.valueOptionMap[
                                                e.target.value
                                                ]
                                            );
                                        }

                                        this.setState({
                                            tipo_documento: e.target.value,
                                        });
                                    }}
                                />
                            </div>
                        </div>
                            <div className="m-2">
                                <div className="d-flex justify-content-start flex-column flex-sm-row align-items-stretch align-items-sm-center">
                                    <label htmlFor="name" className="m-0">
                                        {this.state.tipo_documento}
                                        &nbsp;
                                    </label>
                                </div>
                                <div className=" d-flex justify-content-start flex-column flex-sm-row align-items-stretch align-items-sm-center">
                                    <Field
                                        name="documento_paciente"
                                        component={renderField}
                                        validate={[required, validaNoEspacios]}
                                        type="text"
                                        className="form-control w-75"
                                        // input={this.state.tipo_documento != "EXT" ? {value:this.state.valueOptionMap[this.state.tipo_documento]} : {}}
                                        disabled={
                                            this.state.tipo_documento != "EXT" || this.state.tipo_documento == ""
                                        }
                                    />
                                </div>
                            </div>
                    </div>
                )}
                {show_fields && (
                    <React.Fragment>
                        <div className="d-flex justify-content-around flex-column flex-sm-row align-items-stretch align-items-sm-center">
                            <div className="m-2">
                                <div className="d-flex justify-content-start flex-column flex-sm-row align-items-stretch align-items-sm-center">
                                    <label htmlFor="nit" className="m-0">
                                        Tipo documento
                                    </label>
                                </div>
                                <div className=" d-flex justify-content-start flex-column flex-sm-row align-items-stretch align-items-sm-center">
                                    <Field
                                        name="tipo_documento"
                                        component={renderSelectField2}
                                        type="text"
                                        className="form-control w-75"
                                        validate={[required, validaNoEspacios]}
                                        options={optionsDocumentos}
                                        item_vacio={true}
                                        disabled={false}
                                        onChange={(e) => {
                                            if (e.target.value == "EXT") {
                                                changeFieldFormBillingInformation(
                                                    "nit",
                                                    ""
                                                );
                                            } else {
                                                changeFieldFormBillingInformation(
                                                    "nit",
                                                    this.state.initValueOptionMap[
                                                    e.target.value
                                                    ]
                                                );
                                                changeFieldFormBillingInformation(
                                                    "name",
                                                    "",
                                                )
                                            }
                                            this.setState({
                                                tipo_documento: e.target.value,
                                            });
                                        }}
                                    />
                                </div>
                            </div>
                            <div className="m-2">
                                {
                                this.state.tipo_documento != "NIT" &&
                                this.state.tipo_documento != "CUI" &&
                                // this.state.tipo_documento != "" &&
                                    (
                                        <React.Fragment>
                                            <div className="d-flex justify-content-start flex-column flex-sm-row align-items-stretch align-items-sm-center">
                                                <label
                                                    htmlFor="nit"
                                                    className="m-0"
                                                >
                                                    {this.state.tipo_documento != "" && (this.state.tipo_documento)}
                                                    &nbsp;
                                                </label>
                                            </div>
                                            <div className=" d-flex justify-content-start flex-column flex-sm-row align-items-stretch align-items-sm-center">
                                                <Field
                                                    name="nit"
                                                    component={renderField}
                                                    type="text"
                                                    validate={[required, validaNoEspacios]}
                                                    disabled={ this.state.tipo_documento == "CF" || this.state.tipo_documento == ""}
                                                    className="form-control w-75"
                                                />
                                            </div>
                                        </React.Fragment>
                                    )}
                                {this.state.tipo_documento == "NIT" && (
                                    <React.Fragment>
                                        <div className="d-flex justify-content-start flex-column flex-sm-row align-items-stretch align-items-sm-center">
                                            <label
                                                htmlFor="nit"
                                                className="m-0"
                                            >
                                                Nit
                                            </label>
                                        </div>
                                        <div className=" d-flex justify-content-start flex-column flex-sm-row align-items-stretch align-items-sm-center">
                                            <Field
                                                name="nit"
                                                component={renderField}
                                                type="text"
                                                className="form-control w-75"
                                                validate={NIT}
                                                onBlurs={validateNit}
                                                onKeyDownEnter={validateNit}
                                            />
                                        </div>
                                    </React.Fragment>
                                )}
                                {this.state.tipo_documento == "CUI" && (
                                    <React.Fragment>
                                        <div className="d-flex justify-content-start flex-column flex-sm-row align-items-stretch align-items-sm-center">
                                            <label
                                                htmlFor="nit"
                                                className="m-0"
                                            >
                                                CUI
                                            </label>
                                        </div>
                                        <div className=" d-flex justify-content-start flex-column flex-sm-row align-items-stretch align-items-sm-center">
                                        <Field
                                            name="nit"
                                            component={renderFieldDPI}
                                            type="number"
                                            className="form-control"
                                            validate={DPI}
                                            onBlurs={true}
                                            onKeyDownEnter={setDataName}
                                        />
                                        </div>
                                    </React.Fragment>
                                )}
                            </div>
                        </div>
                            <div className="m-2 px-5">
                                <div className="d-flex justify-content-start flex-column flex-sm-row align-items-stretch align-items-sm-center">
                                    <label htmlFor="name" className="m-0">
                                        Nombre
                                    </label>
                                </div>
                                    <Field
                                        name="name"
                                        validate={
                                            this.state.tipo_documento != "CF" &&
                                            this.state.tipo_documento != "EXT" ?
                                            []: [required, validaNoEspacios]}
                                        disabled={
                                            this.state.tipo_documento != "CF" &&
                                            this.state.tipo_documento != "EXT"
                                            // && this.state.tipo_documento != "CUI"
                                        }
                                        component={renderField}
                                        addClass="form-control w-100"
                                        type="text"
                                    />
                                </div>
                    </React.Fragment>
                )}
                <hr />
                <div className="px-md-5 pt-3 d-flex flex-column flex-sm-row align-items-stretch align-items-sm-center">
                    <div className="radio">
                        <label className="radio-inline mr-5 d-flex align-items-center">
                            <Field
                                name="is_rental"
                                component={renderFieldCheck}
                                onChange={() => {
                                    this.setState({
                                        is_rental: !this.state.is_rental,
                                    });
                                }}
                            />
                            &nbsp;¿Es alquiler?
                        </label>
                    </div>
                </div>
                <hr />
                <div className="px-md-5 pt-3 d-flex flex-column flex-sm-row align-items-stretch align-items-sm-center">
                    <div className="radio">
                        <label className="radio-inline mr-5 d-flex flex-row align-items-start">
                            <div className="d-flex flex-column">
                                <Field
                                    name="email"
                                    type="email"
                                    component={renderField}
                                    label={this.props.billingEmail}
                                // input={{value: this.props.billingEmail}}
                                />
                                <p style={{ fontSize: "10px" }}>
                                    La factura se enviará al correo ingresado
                                </p>
                            </div>
                            <div className="mx-4">
                                &nbsp;
                                {this.state.is_rental
                                    ? "Correo del médico al cual se enviará la factura electrónica"
                                    : "Correo del paciente al cual se enviará la factura electrónica"}
                            </div>
                        </label>
                    </div>
                </div>
                {/*
                <div className="row-fluid">
                    <div className="mt-5 d-flex justify-content-center flex-column flex-sm-row align-items-stretch align-items-sm-center">
                        <button type="button" className="btn btn-secondary" onClick={() => props.changeView('payment')}>Regresar</button>
                        <button type="submit" className="btn btn-primary mx-2">Finalizar Venta</button>
                    </div>
                </div>
                */}
            </form>
        );
    }
}

// let FrmBillingInformation = (props) => {
//     const {
//         show_fields,
//         handleSubmit,
//         validateNit,
//         name,
//     } = props;

//     const firstNameFieldValidators = name ? [] : [required];

//     return (
//         <form onSubmit={handleSubmit} id="billing_information" >

//             <div className="px-md-5 pt-3 d-flex flex-column flex-sm-row align-items-stretch align-items-sm-center">
//                 <div className="radio">
//                     <label className="radio-inline mr-5 d-flex align-items-center">
//                         <Field
//                             name="option"
//                             component={renderField}
//                             type="radio"
//                             value={"a"}
//                             inputId="firstFocus"
//                         />
//                         &nbsp;Facturar con datos del paciente
//                     </label>
//                 </div>
//                 <div className="radio">
//                     <label className="radio-inline mr-5 d-flex align-items-center">
//                         <Field name="option" component={renderField} type="radio" value={"b"}/>
//                         &nbsp;Ingresar nuevos datos
//                     </label>
//                 </div>
//                 {/*<div className="radio">
//                     <label className="radio-inline mr-5 d-flex align-items-center">
//                         <Field name="option" component={renderField} type="radio" value={"c"}/>
//                         &nbsp;CF
//                     </label>
//                 </div>*/}
//             </div>

//             {/*
//             <div className="d-flex justify-content-around flex-column flex-sm-row align-items-stretch align-items-sm-center">
//                 <div className="m-2">
//                     <div className="d-flex justify-content-start flex-column flex-sm-row align-items-stretch align-items-sm-center">
//                         <label htmlFor="Option" className="m-0">Datos de facturación</label>
//                     </div>
//                     <div className=" d-flex justify-content-start flex-column flex-sm-row align-items-stretch align-items-sm-center">
//                         <Field name="option" component={renderSelectCustom} options={[
//                             {value:1, label:'CONTINUAR CON LOS DATOS'},
//                             {value:2, label:'INGRESAR DATOS'},
//                             {value:3, label:'CF'},
//                         ]}/>
//                     </div>
//                 </div>
//                 <div className="m-2 invisible">
//                     <div className="d-flex justify-content-start flex-column flex-sm-row align-items-stretch align-items-sm-center">
//                         <label htmlFor="Comodin" className="m-0">Comodín</label>
//                     </div>
//                     <div className=" d-flex justify-content-start flex-column flex-sm-row align-items-stretch align-items-sm-center">
//                         <Field name="comodin" component={renderField} type="text" className="form-control w-75"/>
//                     </div>
//                 </div>
//             </div>
//             */}
//             {show_fields &&
//                 <div className="d-flex justify-content-around flex-column flex-sm-row align-items-stretch align-items-sm-center">
//                     <div className="m-2">
//                         <div className="d-flex justify-content-start flex-column flex-sm-row align-items-stretch align-items-sm-center">
//                             <label htmlFor="nit" className="m-0">Nit</label>
//                         </div>
//                         <div className=" d-flex justify-content-start flex-column flex-sm-row align-items-stretch align-items-sm-center">
//                             <Field
//                                 name="nit"
//                                 component={renderField}
//                                 type="text"
//                                 className="form-control w-75"
//                                 validate={NIT}
//                                 onBlurs={validateNit}
//                                 onKeyDownEnter={validateNit}
//                             />
//                         </div>
//                     </div>
//                     <div className="m-2">
//                         <div className="d-flex justify-content-start flex-column flex-sm-row align-items-stretch align-items-sm-center">
//                             <label htmlFor="name" className="m-0">Nombre</label>
//                         </div>
//                         <div className=" d-flex justify-content-start flex-column flex-sm-row align-items-stretch align-items-sm-center">
//                             <Field name="name" validate={firstNameFieldValidators} disabled={true} component={renderField} type="text" className="form-control w-75"/>
//                         </div>
//                     </div>
//                 </div>
//             }
//             <hr/>
//                 <div className="px-md-5 pt-3 d-flex flex-column flex-sm-row align-items-stretch align-items-sm-center">
//                     <div className="radio">
//                         <label className="radio-inline mr-5 d-flex align-items-center">
//                             <Field
//                                 name="is_rental"
//                                 component={renderFieldCheck}
//                             />
//                             &nbsp;¿Es alquiler?
//                         </label>
//                     </div>
//                 </div>
//                 <hr/>
//                 <div className="px-md-5 pt-3 d-flex flex-column flex-sm-row align-items-stretch align-items-sm-center">
//                     <div className="radio">
//                         <label className="radio-inline mr-5 d-flex align-items-center">
//                             <Field
//                                 name="email"
//                                 type="email"
//                                 component={renderField}
//                                 label="ejemplo@ejemplo.com"
//                             />
//                             &nbsp;Correo al cual se enviará la factura electrónica
//                         </label>
//                     </div>
//                 </div>
//             {/*
//             <div className="row-fluid">
//                 <div className="mt-5 d-flex justify-content-center flex-column flex-sm-row align-items-stretch align-items-sm-center">
//                     <button type="button" className="btn btn-secondary" onClick={() => props.changeView('payment')}>Regresar</button>
//                     <button type="submit" className="btn btn-primary mx-2">Finalizar Venta</button>
//                 </div>
//             </div>
//             */}
//         </form>
//     );
// }

FrmBillingInformation = reduxForm({
    enableReinitialize: true,
    form: "frmBillingInformation",
    initialValues: {
        option: "a",
        documento_paciente: "",
    },
    validate: (values) => {
        const errors = {};
        if (!values.option) errors.option = "Campo requerido.";
        if (!values.nit) errors.nit = "Campo requerido.";
        if (values.nit && values.nit.toUpperCase() === "CF")
            errors.nit = "Nit Invalido.";
        if (!values.tipo_documento || values.tipo_documento == "" || values.tipo_documento.trim() == "") {
            errors.tipo_documento = "Campo requerido.";
        }
        return errors;
    },
})(FrmBillingInformation);

const selector = formValueSelector("frmBillingInformation");

FrmBillingInformation = connect((state) => {
    const option = selector(state, "option");
    const name = selector(state, "name");
    let show_fields = option === "b" ? true : false;

    return {
        show_fields,
        name,
    };
})(FrmBillingInformation);

export default FrmBillingInformation;
