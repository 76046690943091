const url = 'financial_entities';
import { api } from 'api';
import Swal from 'sweetalert2';
import { push } from 'react-router-redux';
import { initialize as initializeForm } from 'redux-form';
import { handleActions } from 'redux-actions';

const LOADER_ENTITY = 'LOADER_ENTITY';
const SET_DATA_ENTITY = 'SET_DATA_ENTITY';
const SET_PAGE_ENTITY = 'SET_PAGE_ENTITY';
const SET_UPDATE_DATA_ENTITY = 'SET_UPDATE_DATA_ENTITY';
const SET_BUSCADOR_ENTITY = 'SET_BUSCADOR_ENTITY';
const SET_FILTRO_ENTITY = 'SET_FILTRO_ENTITY';

const listar = (page = 1) =>  (dispatch, getStore) => {
    dispatch({type: LOADER_ENTITY, cargando: true});
    const store = getStore();
    const search = store.entity.buscador;
    const filtro = store.entity.filtro_entity;
    let params = {page, search};
    if(filtro){
        params[''] = filtro
    }

    api.get(`${url}`, params).catch((error) => {
        dispatch({type: LOADER_ENTITY, cargando: false});
        Swal(
            'Error',
            error.detail || 'Ha ocurrido un error, por favor vuelva a intentar.',
            'error'
        );
    }).then((data) => {
        if(data){
            dispatch({type: SET_DATA_ENTITY, data});
            dispatch({type: SET_PAGE_ENTITY, page});
        }
        dispatch({type: LOADER_ENTITY, cargando: false});
    })
};

const detail = id => (dispatch, getState) =>{
    dispatch({type: LOADER_ENTITY, cargando: true});

    api.get(`${url}/${id}`).catch((error) => {
        dispatch({type: LOADER_ENTITY, cargando: false});
        if(error.statusCode === 404){
            dispatch(push('/financial_entity'))
        }
    }).then((data) => {
        dispatch({type: LOADER_ENTITY, cargando: false});
        if(data){
            dispatch({type: SET_UPDATE_DATA_ENTITY, updateData: data});
            const afiliacion = {value: data.afiliacion_fel, label: data.afiliacion_fel };
            data.bank_account = {value: data.bank_account.id, label: data.bank_account.owner, number: data.bank_account.account_number}
            data.afiliacion_fel = afiliacion;
            data.frases = JSON.parse(data.frases);
            dispatch(initializeForm('entity', data))
        }
    })
}

const create = () => (dispatch, getStore) => {
    const formData = _.cloneDeep(getStore().form.entity.values);
    formData.bank_account = formData.bank_account.value
    const afiliacion = formData.afiliacion_fel.value;
    formData.afiliacion_fel = afiliacion;
    formData.frases = JSON.stringify(formData.frases);
    if(!formData.hasOwnProperty('correo_copia')){
        formData.correo_copia = formData.correo_fel;
    }

    dispatch({type: LOADER_ENTITY, cargando: true})

    api.post(`${url}`, formData).then((data) => {
        dispatch({type: LOADER_ENTITY, cargando: false})
        Swal('Éxito', 'Se ha creado la entidad financiera.', 'success')
        .then(() => {
            dispatch(push('/financial_entity'))
        })
    }).catch((error) => {
        dispatch({type: LOADER_ENTITY, cargando: false})
        Swal(
            'Error',
             error.detail || 'Ha ocurrido un error, por favor vuelva a intentar.',
            'error'
        );
    }).finally(() => {
        dispatch({type: LOADER_ENTITY, cargando: false})
    });
};

const update = () => (dispatch, getStore) => {
    const formData = _.cloneDeep(getStore().form.entity.values);
    const afiliacion = formData.afiliacion_fel.value;
    formData.afiliacion_fel = afiliacion;
    formData.frases = JSON.stringify(formData.frases);
    formData.bank_account = formData.bank_account.value


    dispatch({type: LOADER_ENTITY, cargando: true});
    api.put(`${url}/${formData.id}`, formData)
    .then((data) => {
        dispatch({type: LOADER_ENTITY, cargando: false});
        if(data){
            Swal(
                'Éxito',
                'Datos editados correctamente.',
                'success'
            ).then(() => {
                dispatch(push('/financial_entity'));
            })
        }
    })
    .catch((error) =>{
        dispatch({type: LOADER_ENTITY, cargando: false});
        Swal(
            'Error',
             error.detail || 'Ha ocurrido un error, verifique los datos.',
            'error'
        )
        if(error.statusCode === 404){
            dispatch(push('/financial_entity/create'));
        }
    })
}

const destroy = (id) => (dispatch, getStore) => {
    dispatch({type: LOADER_ENTITY, cargando: true});
    api.eliminar(`${url}/${id}`).catch((error) => {
        dispatch({type: LOADER_ENTITY, cargando: false});
        Swal('Error', 'No se ha logrado borrar el registro.', 'error')
            .then(() => {
                dispatch(listar(1));
            })
    }).then((data) => {
        dispatch({type: LOADER_ENTITY, cargando: false});
        Swal('Éxito', 'Se ha eliminado correctamente.', 'success')
            .then(() => {
                dispatch(listar(1));
            })
    })
}

const search = (search) => (dispatch) => {
    dispatch({type: SET_BUSCADOR_ENTITY, buscador: search});
    dispatch(listar(1));
};

const filtro = (filtro) => (dispatch, getStore) => {
    if (filtro == 0){
        filtro = null
    }
    dispatch({type: SET_FILTRO_ENTITY, filtro_entity: filtro});
    dispatch(listar(1));
};

export const actions = {
    listar,
    create,
    update,
    detail,
    destroy,
    search,
    filtro,
};

export const reducer = {
    [LOADER_ENTITY]: (state, { cargando }) => {
        return {...state, cargando }
    },
    [SET_DATA_ENTITY]: (state, { data }) => {
        return {...state, data }
    },
    [SET_PAGE_ENTITY]: (state, { page }) => {
        return {...state, page }
    },
    [SET_UPDATE_DATA_ENTITY]: (state, { updateData }) => {
        return {...state, updateData }
    },
    [SET_BUSCADOR_ENTITY]: (state, { buscador }) => {
        return {...state, buscador }
    },
    [SET_FILTRO_ENTITY]: (state, { filtro_entity }) => {
        return {...state, filtro_entity }
    },
};

export const initialState = {
    cargando: false,
    page: 1,
    data: {
        count: 0,
        next: null,
        previous: null,
        results: [],
    },
    buscador: '',
    filtro_entity: null,
    updateData: {},
};

export default handleActions(reducer, initialState)
