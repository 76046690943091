import React from 'react';
import { Field, reduxForm } from 'redux-form';
import { validate, validators } from 'validate-redux-form';
import { 
    renderField,
    renderDatePicker,
    modalTextArea,
    renderAntTimeField,
    renderCurrency,
    dateInput,
} from 'Utils/renderField/renderField';

const Form = props => {

   const {
       handleSubmit
    } = props;

    const format = 'HH:mm';

    return(
        <form id="bank_transaction_form" onSubmit={handleSubmit} className="p-0">
            <div className="d-flex justify-content-center flex-column flex-sm-row align-items-stretch align-items-sm-center pt-1 pb-3 bbi-transaction">
                <div className="d-flex justify-content-start flex-column flex-sm-row align-items-stretch align-items-sm-center">
                    <label htmlFor="amount" className="modal-label-width">Monto:</label>
                </div>
                <div className=" d-flex justify-content-start flex-column flex-sm-row align-items-stretch align-items-sm-center">
                    <Field name="amount" component={renderCurrency} type="text" className="form-control"/>
                </div>
            </div>
            <div className="d-flex justify-content-center flex-column flex-sm-row align-items-stretch align-items-sm-center py-3 bbi-transaction">
                <div className="d-flex justify-content-start flex-column flex-sm-row align-items-stretch align-items-sm-center">
                    <label htmlFor="no_document" className="modal-label-width">No. documento:</label>
                </div>
                <div className=" d-flex justify-content-start flex-column flex-sm-row align-items-stretch align-items-sm-center">
                    <Field name="no_document" component={renderField} type="text" className="form-control"/>
                </div>
            </div>
            <div className="d-flex justify-content-center flex-column flex-sm-row align-items-stretch align-items-sm-center py-3 bbi-transaction">
                <div className=" d-flex justify-content-start flex-column flex-sm-row align-items-stretch align-items-sm-center">
                    <label htmlFor="date" className="modal-label-width">Fecha:</label>
                </div>
                <div className=" d-flex justify-content-end flex-column flex-sm-row align-items-stretch align-items-sm-center">
                    <Field name="date" component={dateInput} className="form-control" />
                    {/*<Field name="date" component={renderDatePicker} type="text" className="form-control" />*/}
                </div>
            </div>
            <div className="d-flex justify-content-center flex-column flex-sm-row align-items-stretch align-items-sm-center pt-3">
                <div className="">
                    <div className=" d-flex justify-content-start flex-column flex-sm-row align-items-stretch align-items-sm-center">
                        <label htmlFor="trasanction_jutification" className="modal-label-width">Motivo</label>
                    </div>
                    <div className=" d-flex justify-content-end flex-column flex-sm-row align-items-stretch align-items-sm-center">
                        <Field name="transanction_justification" component={modalTextArea} type="text" className="form-control justification-message-input" />
                    </div>
                </div>
            </div>
        </form>
    )
};

const BankTransactionForm = reduxForm({
   form: 'bankTransaction',
   validate: data => {
       return validate(data, {
           'amount': validators.exists()('Campo requerido.'),
           'no_document': validators.exists()('Campo requerido.'),
           'date': validators.exists()('Campo requerido.'),
           'hour': validators.exists()('Campo requerido.'),
           'transanction_justification': validators.exists()('Campo requerido.'),
       })
   }
})(Form);

export default BankTransactionForm
