const url = 'reporte/consultas';
import { api } from 'api';
import Swal from 'sweetalert2';
import { initialize as initializeForm } from 'redux-form';
import { createActions, handleActions } from 'redux-actions';
import moment from "moment";

const MD = 1;
const QX = 2;
const ALG = 3;
const TRX = 4;
const FAM = 5;

const LECTURA_INEXACTA = 1;
const MAL_ESTADO = 2;

import {
    RECORD,
    LENSOMETRY,
    VISUAL_ACUITY,
    AUTOREFRACTOR,
    KERATOMERY,
    REFRACTOR,
    TONOMETRY,
    MUSCLES,
    LAMP,
    INDIRECT_EYE,
    DIAGNOSTIC_PLAN,
    EDUCATIONAL_PLAN,
    OPTICS_ORDER,
} from '../../../utility/variables';

const LOADER_REPORTE_CONSULTA = 'LOADER_REPORTE_CONSULTA';
const SET_DATA_REPORTE_CONSULTA = 'SET_DATA_REPORTE_CONSULTA';
const SET_PAGE_REPORTE_CONSULTA = 'SET_PAGE_REPORTE_CONSULTA';
const SET_BUSCADOR_REPORTE_CONSULTA = 'SET_BUSCADOR_REPORTE_CONSULTA';
const SET_FILTRO_REPORTE_CONSULTA = 'SET_FILTRO_REPORTE_CONSULTA';
const SET_INICIAL_DATE_REPORTE_CONSULTA = 'SET_INICIAL_DATE_REPORTE_CONSULTA';
const SET_FINAL_DATE_REPORTE_CONSULTA = 'SET_FINAL_DATE_REPORTE_CONSULTA';
const SET_DOCTORS_LIST_REPORTE_CONSULTA = 'SET_DOCTORS_LIST_REPORTE_CONSULTA';
const SET_FILTRO_MEDICO_REPORTE_CONSULTA = 'SET_FILTRO_MEDICO_REPORTE_CONSULTA';
const SET_PATIENT_INFO_REPORTE_CONSULTA = 'SET_PATIENT_INFO_REPORTE_CONSULTA';
const SET_STEP_REPORTE_CONSULTA = 'SET_STEP_REPORTE_CONSULTA';

// PARA EL REPORTE DE INGRESOS
const listarMedicos = () => (dispatch) => {
    api.get(`${url}/listarMedicos`).catch((error) => {
        Swal(
            'Error',
            error.detail || 'Ha ocurrido un error, por favor vuelva a intentar.',
            'error'
        );
    }).then((data) => {
        if(data){
            data = data.map(item => {
                return {value: item.id, label: item.name};
            });
            data.push({value: 0, label: 'Todos'});
            dispatch({type: SET_DOCTORS_LIST_REPORTE_CONSULTA, doctors: data});
        }
    })
}

const listarConsultas = (page = 1) =>  (dispatch, getStore) => {
    dispatch({type: LOADER_REPORTE_CONSULTA, cargando: true});
    const store = getStore();
    const search = store.reporte_consulta.buscador
    const filtro = store.reporte_consulta.filtro;
    const medico = store.reporte_consulta.filtro_medico;

    const initial_date = store.reporte_consulta.initial_date;
    const final_date = store.reporte_consulta.final_date;

    let params = {page, search, initial_date, final_date, medico};
    if(filtro){
        params[''] = filtro
    }

    api.get(`${url}/listarConsultas`, params).catch((error) => {
        dispatch({type: LOADER_REPORTE_CONSULTA, cargando: false});
        Swal(
            'Error',
            error.detail || 'Ha ocurrido un error, por favor vuelva a intentar.',
            'error'
        );
    }).then((data) => {
        if(data){
            console.log("Data: ", data);
            dispatch({type: SET_DATA_REPORTE_CONSULTA, data: data});
            dispatch({type: SET_PAGE_REPORTE_CONSULTA, page: page});
        }
        dispatch({type: LOADER_REPORTE_CONSULTA, cargando: false});
    })
};

const search = (search) => (dispatch) => {
    dispatch({type: SET_BUSCADOR_REPORTE_CONSULTA, buscador: search});
    dispatch(listarConsultas(1));
};

const filtro = (filtro) => (dispatch, getStore) => {
    if (filtro == 0){
        filtro = null
    }

    dispatch({type: SET_FILTRO_REPORTE_CONSULTA, filtro});
    dispatch(listarConsultas(1));
};

const setFinalDate = (value) => (dispatch) => {
    dispatch({type: SET_FINAL_DATE_REPORTE_CONSULTA, final_date: value});
    dispatch(listarConsultas(1));
}

const setInitialDate = (value) => (dispatch) => {
    dispatch({type: SET_INICIAL_DATE_REPORTE_CONSULTA, initial_date: value});
    dispatch(listarConsultas(1));
}

const filtroMedico = (filtro) => (dispatch) => {
    dispatch({type: SET_FILTRO_MEDICO_REPORTE_CONSULTA, filtro_medico: filtro});
    dispatch(listarConsultas(1));
}

const getPatient = (patient_id) => (dispatch) => {
    api.get(`${url}/getPatient`, {patient_id}).catch((error) => {
        Swal(
            'Error',
            error.detail || 'Ha ocurrido un error, por favor vuelva a intentar.',
            'error'
        );
    }).then((data) => {
        if(data){
            console.log("Data: ", data);
            dispatch({type: SET_PATIENT_INFO_REPORTE_CONSULTA, patient: data});
        }
    })
}

const getRecordReport = (appointment_id) => (dispatch, getStore) => {
    dispatch({type: SET_STEP_REPORTE_CONSULTA, step: RECORD});
    api.get(`${url}/getRecordReport`, {appointment_id}).catch((error) => {
        console.log("No hay reporte de record para esta consulta");
    }).then((response) => {
        if(response){
            let md = []; let qx = []; let alg = []; let trx = []; let fam = [];
            if(response.consultation_reason){
                response.reason = response.consultation_reason;
            }
            if(response.disease_history){
            response.history = response.disease_history;
            }

            response.rar_ra.forEach(item => {
                if(item.record.record_type === MD){
                    md.push({
                        diagnostico: {value: item.record.name, label: item.record.name },
                        tratamiento: {value: item.record.md_treatment, label: item.record.md_treatment}
                    });
                }else if(item.record.record_type === QX){
                    qx.push({
                        cirugia: {value: item.record.name, label: item.record.name}
                    });
                }else if(item.record.record_type === ALG){
                    alg.push({
                        alergia: {values: item.record.name, label: item.record.name}
                    });
                }else if(item.record.record_type === TRX){
                    trx.push({
                        trauma: {values: item.record.name, label: item.record.name}
                    });
                }else if(item.record.record_type === FAM){
                    fam.push({
                        diagnostico: {value: item.record.name, label: item.record.name},
                        tratamiento: {value: item.record.fam_family, label: item.record.fam_family}
                    });
                }

            });

            response.md = md;
            response.qx = qx;
            response.alg = alg;
            response.trx = trx;
            response.fam = fam;

            if(response.fam_note){
                response.note = response.fam_note;
            }
            
            dispatch(initializeForm('Record', response));
            if(getStore().reporte_consulta.patient === null){
                dispatch({type: SET_PATIENT_INFO_REPORTE_CONSULTA, patient: response.appointment.patient});
            }
        }
    });
}

const getLensometryReport = (appointment_id) => (dispatch, getStore) => {
    dispatch({type: SET_STEP_REPORTE_CONSULTA, step: LENSOMETRY});
    api.get(`${url}/getLensometryReport`, {appointment_id}).catch((error) => {
        console.log("No hay reporte de record para esta consulta");
    }).then((response) => {
        if(response){
            if(parseInt(response.od_estado) === LECTURA_INEXACTA){
                response.od_estado = {value: LECTURA_INEXACTA, label: 'Lectura inexacta'}
            }else if(parseInt(response.od_estado) === MAL_ESTADO){
                response.od_estado = {value: MAL_ESTADO, label: 'Mal estado'}
            }
    
            if(parseInt(response.os_estado) === LECTURA_INEXACTA){
                response.os_estado = {value: LECTURA_INEXACTA, label: 'Lectura inexacta'}
            }else if(parseInt(response.os_estado) === MAL_ESTADO){
                response.os_estado = {value: MAL_ESTADO, label: 'Mal estado'}
            }

            const tipo = {value: response.types.id, label: response.types.texto}
            response.tipo = tipo; 

            if(response.type_note !== null){
                response.type = response.type_note.toString();
            }

            console.log("REPORTE LENSOMETRÍA: ", response);
            dispatch(initializeForm('Lensometry', response));
            if(getStore().reporte_consulta.patient === null){
                dispatch({type: SET_PATIENT_INFO_REPORTE_CONSULTA, patient: response.appointment.patient});
            }
        }
    });
}

const getVisualAcuityReport = (appointment_id) => (dispatch, getStore) => {
    dispatch({type: SET_STEP_REPORTE_CONSULTA, step: VISUAL_ACUITY});
    api.get(`${url}/getVisualAcuityReport`, {appointment_id}).catch((error) => {
        console.log("No hay reporte de record para esta consulta");
    }).then((response) => {
        if(response){
            if(response.type_note !== null){
                response.type = response.type_note.toString();
            }
            dispatch(initializeForm('VisualAcuity', response));
            if(getStore().reporte_consulta.patient === null){
                dispatch({type: SET_PATIENT_INFO_REPORTE_CONSULTA, patient: response.appointment.patient});
            }
        }
    });
}

export const getAutorefractorReport = (appointment_id) => (dispatch, getStore) =>{
    dispatch({type: SET_STEP_REPORTE_CONSULTA, step: AUTOREFRACTOR});
    api.get(`${url}/getAutorefractorReport`, {appointment_id}).then((response) => {
        if(response.type_note !== null){
            response.type = response.type_note.toString();
        }
        dispatch(initializeForm('Autorefractor', response));
        if(getStore().reporte_consulta.patient === null){
            dispatch({type: SET_PATIENT_INFO_REPORTE_CONSULTA, patient: response.appointment.patient});
        }
    }).catch((error)=>{
        console.log("Error: ", error);
    });
}

export const getKeratometryReport = (appointment_id) => (dispatch, getStore) =>{
    dispatch({type: SET_STEP_REPORTE_CONSULTA, step: KERATOMERY});
    api.get(`${url}/getKeratometryReport`, {appointment_id}).then((response) => {
        if(response.type_note !== null){
            response.type = response.type_note.toString();
        }
        if(getStore().reporte_consulta.patient === null){
            dispatch({type: SET_PATIENT_INFO_REPORTE_CONSULTA, patient: response.appointment.patient});
        }
        dispatch(initializeForm('Keratometry', response));
    }).catch((error)=>{
        console.log("Error: ", error);
    });
}

export const getRefractorReport = (appointment_id) => (dispatch, getStore) =>{
    dispatch({type: SET_STEP_REPORTE_CONSULTA, step: REFRACTOR});

    api.get(`${url}/getRefractorReport`, {appointment_id}).then((response) => {
        if(response.type_note !== null){
            response.type = response.type_note.toString();
        }
        dispatch(initializeForm('Refraction', response));

        if(getStore().reporte_consulta.patient === null){
            dispatch({type: SET_PATIENT_INFO_REPORTE_CONSULTA, patient: response.appointment.patient});
        }
    }).catch((error)=>{
        console.log("No hay reporte de record para esta consulta");
    });
}

export const getTonometryReport = (appointment_id) => (dispatch, getStore) =>{
    dispatch({type: SET_STEP_REPORTE_CONSULTA, step: TONOMETRY});
    api.get(`${url}/getTonometryReport`, {appointment_id}).then((response) => {
        if(response.od_tono_digital !== null){
            response.od_tono_digital = response.od_tono_digital.toString();
        }

        if(response.os_tono_digital !== null){
            response.os_tono_digital = response.os_tono_digital.toString();
        }

        if(response.type_note !== null){
            response.type = response.type_note.toString();
        }
        dispatch(initializeForm('Tonometry', response));
        if(getStore().reporte_consulta.patient === null){
            dispatch({type: SET_PATIENT_INFO_REPORTE_CONSULTA, patient: response.appointment.patient});
        }
    }).catch((error)=>{
        console.log("No hay reporte de record para esta consulta");
    });
}

export const getMuscleReport = (appointment_id) => (dispatch, getStore) =>{
    dispatch({type: SET_STEP_REPORTE_CONSULTA, step: MUSCLES});

    api.get(`${url}/getMuscleReport`, {appointment_id}).then((response) => {
        if(response.od_state !== null){
            response.od_state = capitalize(response.od_state.toString());
        }

        if(response.os_state !== null){
            response.os_state = capitalize(response.os_state.toString());
        }

        if(response.type_note !== null){
            response.type = response.type_note.toString();
        }
        dispatch(initializeForm('Muscle', response));
        if(getStore().reporte_consulta.patient === null){
            dispatch({type: SET_PATIENT_INFO_REPORTE_CONSULTA, patient: response.appointment.patient});
        }
    }).catch((error)=>{
        console.log("Error: ", error);
    });
}

export const getLampReport = (appointment_id) => (dispatch, getStore) =>{
    dispatch({type: SET_STEP_REPORTE_CONSULTA, step: LAMP});

    api.get(`${url}/getLampReport`, {appointment_id}).then((response) => {        
        response.od_via_lagrimal = response.od_via_lagrimal === null ? response.od_via_lagrimal_otro === null ? undefined : 'None' : capitalize(response.od_via_lagrimal.toString());
        response.os_via_lagrimal = response.os_via_lagrimal === null ? response.os_via_lagrimal_otro === null ? undefined : 'None' : capitalize(response.os_via_lagrimal.toString());
        response.od_parpado = response.od_parpado === null ? response.od_parpado_otro === null ? undefined : 'None' : capitalize(response.od_parpado.toString());
        response.os_parpado = response.os_parpado === null ? response.os_parpado_otro === null ? undefined : 'None' : capitalize(response.os_parpado.toString());
        response.od_conjuntiva = response.od_conjuntiva === null ? response.od_conjuntiva_otro === null ? undefined : 'None' : capitalize(response.od_conjuntiva.toString());
        response.os_conjuntiva = response.os_conjuntiva === null ? response.os_conjuntiva_otro === null ? undefined : 'None' : capitalize(response.os_conjuntiva.toString());
        response.od_esclera = response.od_esclera === null ? response.od_esclera_otro === null ? undefined : 'None' : capitalize(response.od_esclera.toString());
        response.os_esclera = response.os_esclera === null ? response.os_esclera_otro === null ? undefined : 'None' : capitalize(response.os_esclera.toString());
        response.od_cornea = response.od_cornea === null ? response.od_cornea_otro === null ? undefined : 'None' : capitalize(response.od_cornea.toString());
        response.os_cornea = response.os_cornea === null ? response.os_cornea_otro === null ? undefined : 'None' : capitalize(response.os_cornea.toString());
        response.od_camara_anterior = response.od_camara_anterior === null ? response.od_camara_anterior_otro === null ? undefined : 'None' : capitalize(response.od_camara_anterior.toString());
        response.os_camara_anterior = response.os_camara_anterior === null ? response.os_camara_anterior_otro === null ? undefined : 'None' : capitalize(response.os_camara_anterior.toString());
        response.od_iris = response.od_iris === null ? response.od_iris_otro === null ? undefined : 'None' : capitalize(response.od_iris.toString());
        response.os_iris = response.os_iris === null ? response.os_iris_otro === null ? undefined : 'None' : capitalize(response.os_iris.toString());
        response.od_pupila = response.od_pupila === null ? response.od_pupila_otro === null ? undefined : 'None' : capitalize(response.od_pupila.toString());
        response.os_pupila = response.os_pupila === null ? response.os_pupila_otro === null ? undefined : 'None' : capitalize(response.os_pupila.toString());
        response.od_cristalino = response.od_cristalino === null ? response.od_cristalino_otro === null ? undefined : 'None' : capitalize(response.od_cristalino.toString());
        response.os_cristalino = response.os_cristalino === null ? response.os_cristalino_otro === null ? undefined : 'None' : capitalize(response.os_cristalino.toString());
      
        response.od_macula_otro = response.od_mascula_otro;
        response.os_macula_otro = response.os_mascula_otro;

        if(response.type_note !== null){
            response.type = response.type_note.toString();
        }

        response.od_vitreo = response.od_vitreo === null ? response.od_vitreo_otro === null ? undefined : 'None' : capitalize(response.od_vitreo.toString());
        response.os_vitreo = response.os_vitreo === null ? response.os_vitreo_otro === null ? undefined : 'None' : capitalize(response.os_vitreo.toString());

        response.od_nervio_optico = response.od_nervio_optico === null ? response.od_nervio_optico_otro === null ? undefined : 'None' : capitalize(response.od_nervio_optico.toString());
        response.os_nervio_optico = response.os_nervio_optico === null ? response.os_nervio_optico_otro === null ? undefined : 'None' : capitalize(response.os_nervio_optico.toString());

        response.od_macula = response.od_mascula === null ? response.od_mascula_otro === null ? undefined : 'None' : capitalize(response.od_mascula.toString());
        response.os_macula = response.os_mascula === null ? response.os_mascula_otro === null ? undefined : 'None' : capitalize(response.os_mascula.toString());

        response.od_retina_periferica = response.od_retina_periferica === null ? response.od_retina_periferica_otro === null ? undefined : 'None' : capitalize(response.od_retina_periferica.toString());
        response.os_retina_periferica = response.os_retina_periferica === null ? response.os_retina_periferica_otro === null ? undefined : 'None' : capitalize(response.os_retina_periferica.toString());

        dispatch(initializeForm('Lamp', response));
        if(getStore().reporte_consulta.patient === null){
            dispatch({type: SET_PATIENT_INFO_REPORTE_CONSULTA, patient: response.appointment.patient});
        }
    }).catch((error)=>{
        console.log("No hay reporte de record para esta consulta");
    });
}

export const getIndirectEyeReport = (appointment_id) => (dispatch, getStore) =>{
    dispatch({type: SET_STEP_REPORTE_CONSULTA, step: INDIRECT_EYE});

    api.get(`${url}/getIndirectEyeReport`, {appointment_id}).then((response) => {
        
        const od_vitreo = (!response.od_vitreo &&  (response.od_vitreo_otro === null || response.od_vitreo_otro === '')) ? undefined : capitalize(response.od_vitreo.toString());
        response.od_vitreo = od_vitreo;

        const os_vitreo = (!response.os_vitreo &&  (response.os_vitreo === null || response.os_vitreo === '')) ? undefined : capitalize(response.os_vitreo.toString());
        response.os_vitreo = os_vitreo;

        const od_nervio_optico = (!response.od_nervio_optico &&  (response.od_nervio_optico_otro === null || response.od_nervio_optico_otro === '')) ? undefined : capitalize(response.od_nervio_optico.toString());
        response.od_nervio_optico = od_nervio_optico;

        const os_nervio_optico = (!response.os_nervio_optico &&  (response.os_nervio_optico_otro === null || response.os_nervio_optico_otro === '')) ? undefined : capitalize(response.os_nervio_optico.toString());
        response.os_nervio_optico = os_nervio_optico;

        const od_mascula = (!response.od_mascula &&  (response.od_mascula_otro === null || response.od_mascula_otro === '')) ? undefined : capitalize(response.od_mascula.toString());
        response.od_mascula = od_mascula;

        const os_mascula = (!response.os_mascula &&  (response.os_mascula === null || response.os_mascula === '')) ? undefined : capitalize(response.os_mascula.toString());
        response.os_mascula = os_mascula;

        const od_retina_periferica_select = (!response.od_retina_periferica_select && (response.od_retina_periferica_select === null || response.od_retina_periferica_select === '')) ? undefined: capitalize(response.od_retina_periferica_select.toString());
        response.od_retina_periferica_select = od_retina_periferica_select;

        const os_retina_periferica_select = (!response.os_retina_periferica_select && (response.os_retina_periferica_select === null || response.os_retina_periferica_select === '')) ? undefined: capitalize(response.os_retina_periferica_select.toString());
        response.os_retina_periferica_select = os_retina_periferica_select;

        if(response.type_note !== null){
            response.type = response.type_note.toString();
        }
        
        dispatch(initializeForm('IndirectEye', response));
        if(getStore().reporte_consulta.patient === null){
            dispatch({type: SET_PATIENT_INFO_REPORTE_CONSULTA, patient: response.appointment.patient});
        }
    }).catch((error)=>{
        console.log("Error: ", error);
    });
}

export const getDiagnosticPlanReport = (appointment_id) => (dispatch, getStore) =>{
    dispatch({type: SET_STEP_REPORTE_CONSULTA, step: DIAGNOSTIC_PLAN});
    api.get(`${url}/getDiagnosticPlanReport`, {appointment_id, marcar_inicio: false}).then((response) => {
        console.log("REPORTE PLAN DIAGNÓSTICO: ", response);
        response.dp_dpa.forEach(plan => {
            const nuevo_nombre = {value: plan.nombre, label: plan.nombre};
            plan.nombre = nuevo_nombre;
            let tratamientos = [];
            plan.td_dp.filter(td => {
                const new_commercial_name = td.treatment.commercial_name;

                const treatment_data = {
                    commercial_name: new_commercial_name,
                    quantity: td.quantity,
                    active_principle: td.treatment.active_principle,
                    application: td.application,
                    dose: td.dose,
                    frecuency: td.frecuency,
                    presentation: td.presentation,
                    time_duration: td.time_duration,
                }
                tratamientos.push(treatment_data);
            });

            plan.td_dp = tratamientos;
        });
        dispatch(initializeForm('DiagnosticPlan', response));

        if(getStore().reporte_consulta.patient === null){
            dispatch({type: SET_PATIENT_INFO_REPORTE_CONSULTA, patient: response.appointment.patient});
        }
    }).catch((error)=>{
        console.log("Error: ", error);
    });
}

export const getEducationalPlanReport = (appointment_id) => (dispatch, getStore) => {
    dispatch({type: SET_STEP_REPORTE_CONSULTA, step: EDUCATIONAL_PLAN
});
    api.get(`${url}/getEducationalPlanReport`, {appointment_id}).then((response) => {
        console.log("REPORTE PLAN EDUCACIONAL: ", response);
        let data = [];
        if(response.eps_ep){            
            response.eps_ep.forEach(plan => {
                if(plan.plan){
                    plan.id = plan.plan.id;
                    plan.nombre = plan.plan.nombre;
                    plan.incluye = plan.plan.incluye;
                }
                data.push(plan)
            });
            delete response.eps_ep
            response.planes = data;
            response.laboratorio = {value: response.laboratorios, label: response.laboratorios}
        }else{
            const planes = response;
            response = {planes};
        }

        dispatch(initializeForm('EducationalPlan', response));
    }).catch((error)=>{
        console.log("Error: ", error);
        if(getStore().reporte_consulta.patient === null){
            dispatch({type: SET_PATIENT_INFO_REPORTE_CONSULTA, patient: response.appointment.patient});
        }
    });
}

export const getOpticsOrderReport = (appointment_id) => (dispatch, getStore) => {
    dispatch({type: SET_STEP_REPORTE_CONSULTA, step: OPTICS_ORDER});

    api.get(`${url}/getOpticsOrderReport`, {appointment_id}).then((response) => {
        console.log("REPORTE ORDEN DE ÓPTICA: ", response);
        dispatch(initializeForm('OpticsOrder', response));
    }).catch((error)=>{
        console.log("Error: ", error);
        if(getStore().reporte_consulta.patient === null){
            dispatch({type: SET_PATIENT_INFO_REPORTE_CONSULTA, patient: response.appointment.patient});
        }
    });
}

const capitalize = (cadena) => {
    return cadena.charAt(0).toUpperCase() + cadena.slice(1)
}

export const actions = {
    // Ingresos
    listarConsultas,
    search,
    filtro,
    setFinalDate,
    setInitialDate,
    listarMedicos,
    filtroMedico,
    getRecordReport,
    getLensometryReport,
    getVisualAcuityReport,


    getAutorefractorReport,
    getKeratometryReport,
    getRefractorReport,
    getTonometryReport,
    getMuscleReport,
    getLampReport,
    getIndirectEyeReport,
    getDiagnosticPlanReport,
    getEducationalPlanReport,
    getOpticsOrderReport,

    getPatient,
};

export const reducer = {
    [LOADER_REPORTE_CONSULTA]: (state, { cargando }) => {
        return {...state, cargando }
    },

    // PARA EL REPORTE DE INGRESOS A BODEGA
    [SET_DATA_REPORTE_CONSULTA]: (state, { data }) => {
        return {...state, data }
    },
    [SET_PAGE_REPORTE_CONSULTA]: (state, { page }) => {
        return {...state, page }
    },
    [SET_BUSCADOR_REPORTE_CONSULTA]: (state, { buscador }) => {
        return {...state, buscador }
    },
    [SET_FILTRO_REPORTE_CONSULTA]: (state, { filtro }) => {
        return {...state, filtro }
    },
    [SET_INICIAL_DATE_REPORTE_CONSULTA]: (state, { initial_date }) => {
        return{
            ...state,
            initial_date
        }
    },
    [SET_FINAL_DATE_REPORTE_CONSULTA]: (state, { final_date }) => {
        return{
            ...state,
            final_date
        }
    },
    [SET_DOCTORS_LIST_REPORTE_CONSULTA]: (state, { doctors }) => {
        return{
            ...state,
            doctors
        }
    },
    [SET_FILTRO_MEDICO_REPORTE_CONSULTA]: (state, {filtro_medico}) => {
        return{
            ...state,
            filtro_medico
        }
    },
    [SET_PATIENT_INFO_REPORTE_CONSULTA]: (state, { patient }) => {
        return{
            ...state,
            patient
        }
    }
    ,
    [SET_STEP_REPORTE_CONSULTA]: (state, { step }) => {
        return{
            ...state,
            step
        }
    }
};

export const initialState = {
    cargando: false,
    page: 1,
    data: {
        count: 0,
        next: null,
        previous: null,
        results: [],
    },

    buscador: '',
    filtro: null,
    initial_date: moment().date(1).format("DD/MM/YYYY"),
    final_date: moment().format("DD/MM/YYYY"),
    doctors: [],
    filtro_medico: 0,
    patient: null,
    step: RECORD,
};

export default handleActions(reducer, initialState)
