import React from 'react';
import { Link } from 'react-router-dom';
import { Field, FieldArray, reduxForm } from 'redux-form';
import { validate, validators } from 'validate-redux-form';
import {renderSearchCreateSelect, renderField, renderTextArea, modalTextArea, renderFieldCheck} from "../../Utils/renderField/renderField";
import {api} from "../../../../api/api";
import { Tratamiento } from "../../Utils/Notations/tratamiento";
import {PL_DI} from "../../../../utility/variables";
import { PrintContainer, actions as printActions } from "../../Utils/Print";

const getRefillable = (search) => {
    let opciones = [];
    return api.get('refillable/getRefillable', {search, record_type: PL_DI}).then((data) => {
        console.log("Data: ", data);
        data.results.forEach(item => {
            opciones.push({
                value: item.id,
                label: item.texto
            });
        });
        return opciones;
    }).catch(()=>[]);
}

export const diagnosticRenderRow =({ fields, meta: {error, submitFailed}, edit, view}) => (
    <React.Fragment>
        {
            fields.map((field, index) => (
                <tr key={index}>
                    <td>
                        <Field
                            name={`${field}.nombre`}
                            loadOptions={getRefillable}
                            component={renderSearchCreateSelect}
                            type="text"
                            className="no-border"
                            isDisabled={view}
                        />
                    </td>
                    <td>
                        <Field
                            name={`${field}.td_dp`}
                            component={Tratamiento}
                            view={view}
                        />
                    </td>
                    <td className='text-center'>
                        {!view &&
                        <img className="action-img delete" src={require("../../../../../assets/img/icons/delete-icon.png")} onClick={() => fields.remove(index)}  alt="Eliminar" />
                        }
                    </td>
                </tr>
                )
            )

        }

        <tr>
            <td colSpan={2}>
                {submitFailed && (
                    <div className="invalid-feedback d-flex justify-content-center">
                        {error}
                    </div>
                )}
            </td>
            <td className='text-center'>
                {!view &&
                <img onClick={() => fields.push()} className="action-img watch" src={require("../../../../../assets/img/icons/add-icon.png")} alt="Agregar" />
                }
            </td>
        </tr>
    </React.Fragment>
)

const Form = (props) => {
    const { handleSubmit } = props;
    const view = window.location.href.includes("reporte");

    return (
        <form onSubmit={handleSubmit} className="d-flex flex-column">
            {!view ?
                <div className='d-flex flex-row justify-content-between'>
                    <h3><strong>PLAN Dx PLAN Tx PLAN Educ</strong></h3>
                    <button
                        className='btn btn-primary btn-sm'
                        onClick={(e) => {
                            e.preventDefault();
                            printActions.print('impresion-receta');
                        }}
                        style={{height: 30}}
                    >Imprimir receta</button>
                </div>
            :
                null
            }
            <div className="d-flex flex-column justify-content-center w-100">
                <table className='table w-100'>
                    <thead style={{background:'gray', fontWeight:'bold', color:'white'}}>
                        <tr>
                            <td className='text-center'>Nombres</td>
                            <td className='text-center'>Plan Tratamiento</td>
                            <td className='text-center'>Acciones</td>
                        </tr>
                    </thead>
                    <tbody>
                        <FieldArray
                            name='dp_dpa'
                            component={diagnosticRenderRow}
                            view={view}
                        />
                    </tbody>
                </table>
                <div className="d-flex flex-row w-100 mb-3">
                    <div className="d-flex flex-column w-75">
                    <label>Notas</label>
                    <Field
                        name="notes"
                        component={modalTextArea}
                        className="annotations"
                        disabled={view}
                    />
                    </div>
                    <div className='d-flex flex-row w-25 ml-3 align-items-end'>
                        <button
                            type="button"
                            className='btn btn-danger h-75'
                            onClick={()=>{props.change('notes', 'DIFERIDO');}}
                        >DIFERIDO</button>
                    </div>
                </div>
            </div>
            <PrintContainer name='impresion-receta' className="impresion">
                <div className='contenedor-receta'>
                    {props.receta && props.receta.dp_dpa.map((diagnostico, dk) => (
                        <React.Fragment>
                        <div key={dk} className='mt-3'>
                            <strong>{diagnostico.nombre.value}</strong>
                        </div>

                        {diagnostico.td_dp.map((tratamiento, tk) => (
                            <div key={tk} className='ml-4 mt-1'>
                                <div>
                                    <span>{tratamiento.commercial_name}</span>
                                    <span className='ml-2'>#{tratamiento.quantity}</span>
                                    <span className='ml-2'>{tratamiento.presentation}</span>
                                </div>

                                <div>
                                    <span>({tratamiento.active_principle})</span>
                                    <small className='ml-2'><strong>principio activo</strong></small>
                                </div>
                                <div>
                                    <span>{tratamiento.dose}</span>
                                </div>
                                <div>
                                    <span>{tratamiento.frecuency}</span>
                                </div>
                                <div>
                                    <span>{tratamiento.application}</span>
                                    <span className='ml-2'>{tratamiento.frecuency}</span>
                                </div>
                            </div>
                        ))
                        }
                        </React.Fragment>
                    ))}
                    <div className='mt-2'>{props.receta ? props.receta.notes : null}</div>
                </div>
            </PrintContainer>
        </form>
    )
};

const CrearForm = reduxForm({
    form: 'DiagnosticPlan',
    validate: (data) => {
        return validate(data, {
        });
    },
})(Form);

export default CrearForm;
