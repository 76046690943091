import React, { Component } from 'react';
import { Field } from 'redux-form';
import { renderNumber, renderPercentage } from 'Utils/renderField/renderField';

export const renderRemoveRow = ({ fields, meta: {error, submitFailed}, edit, view}) => (
    <React.Fragment>
        {
            fields.map((field, index) => (
                <tr key={index} className={"d-none"}>
                    <td className="">
                    <Field
                        component="input"
                        name={`${field}.id`}
                        type="hidden"
                    />
                    </td>

                    <td>
                        <Field
                            name={`${field}.dues`}
                            component={renderNumber}
                            label="Cuotas"
                            className="form-control"
                        />
                    </td>

                    <td>

                        <Field disabled={false} name={`${field}.rate`} component={renderPercentage} type="text" className="form-control"/>

                    </td>

                    <td>

                        <Field disabled={false} name={`${field}.custumer_rate`} label="Porcentaje de comisión" component={renderPercentage} type="text" className="form-control"/>
                    </td>
                </tr>
            )
            )
        }
    </React.Fragment>
)

export const renderDueRow =({ fields, remove, meta: {error, submitFailed}, edit, view}) => (

    <React.Fragment>
        {
            fields.map((field, index) => (
                <tr key={index} >
                    <td className="d-none">
                    <Field
                        component="input"
                        name={`${field}.id`}
                        type="hidden"
                    />
                    </td>

                    <td className="d-none">
                    <Field
                        component="input"
                        name={`${field}.remove`}
                        type="hidden"
                    />
                    </td>

                    <td>
                        <Field
                            name={`${field}.dues`}
                            component={renderNumber}
                            label="Cuotas"
                            className="form-control"
                        />
                    </td>
                    <td>
                        <Field  disabled={false}
                                name={`${field}.rate`}
                                component={renderPercentage}
                                type="text"
                                className="form-control"/>
                    </td>
                    <td>
                        <Field  disabled={false}
                                name={`${field}.custumer_rate`}
                                label="Porcentaje de comisión"
                                component={renderPercentage}
                                type="text"
                                className="form-control"/>
                    </td>

                    <td className="text-center">
                        <img className="action-img delete-item-package"
                            src={require("../../../../assets/img/icons/delete-icon.png")}
                            onClick={() => {
                                                if(fields.get(index) != undefined){
                                                    remove(fields.get(index))
                                                }else{
                                                    fields.remove(index)
                                                }
                                            }}
                            alt="Eliminar" />
                    </td>
                </tr>
                //</div>
                )
            )
        }
                <tr>
                <td colSpan={3}></td>
                <td className="text-center">
                        <img onClick={() => fields.push()} className="action-img add" src={require("../../../../assets/img/icons/add-icon.png")} alt="Agregar" />
                </td>
                </tr>
        {/* row for the add button */}
        {
            submitFailed && (
                <div className="invalid-feedback d-flex justify-content-center">
                    {error}
                </div>
            )
        }
    </React.Fragment>
)
