import React from 'react';
import { Field, reduxForm, formValueSelector } from 'redux-form';
import { validate, validators } from 'validate-redux-form';
import { RenderCurrency } from  '../../Utils/renderField/renderReadField';
import { renderFieldDateFormat  } from  '../../Utils/renderField/renderField';
import { renderField, renderSearchSelect, modalCurrency, renderCurrency, renderSwitch } from '../../Utils/renderField/renderField';
import { connect } from 'react-redux'
import { api } from "api";

const getBankAccounts = (search)  =>{
    return api.get("bank_accounts",{search})
        .then((data) => {
            if (data){
                const bank_accounts = data.results.map(x => (
                    {value: x.id, label: x.owner, number: x.account_number}
                ));
                return bank_accounts
            }
            else{
                return []
            }
        })
        .catch(e => {
            return []
        })
}

const getSmallBoxes = (search)  =>{
    return api.get("small_box",{search}).catch((error) => {})
        .then((data) => {
            if (data){
                let small_boxes = [];
                data.results.forEach(x=>{
                    small_boxes.push({value: x.id, label: x.name, nit: x.nit});
                })
                return small_boxes;
            }
            else{
                return []
            }
        })
        .catch(e => {
            return []
        })
}

let initialValues = {};

let PaymentForm = (props) => {
    const {
            handleSubmit,
            id_purchase,
            url_retorno,
            transaction_payment,
            cash_payment,
            retiene,
            descuento,
            provider,
        } = props;

    initialValues.id_purchase = id_purchase;
    initialValues.url_retorno = url_retorno;

    // let total_label = 0
    // if(retiene){
    //     if(props.total >= 2800){
    //         total_label = parseFloat(((props.total / 1.12 ) * 0.05).toFixed(2));
    //         props.change('retention_amount', total_label);
    //     }
    // }

    // PARA ISR
    let retention_amount = 0;
    if((props.total >= 2800) && !!provider && (provider.retention)){
        retention_amount = ((props.total / 1.12) * 0.05).toFixed(2);
        props.change('retention_amount', retention_amount);
    }else{
        props.change('retention_amount', 0);
    }
    // PARA IVA
    let iva_retention_amount = 0;
    if((props.total >= 2500) && !!provider && (provider.retention_iva)){
        iva_retention_amount = (((props.total / 1.12) * 0.12) * 0.15).toFixed(2);
        props.change('retention_amount_iva', iva_retention_amount);
    }else{
        props.change('retention_amount_iva', 0);
    }

    let totalPagar = props.total - (parseFloat(retention_amount) + parseFloat(iva_retention_amount));

    if(descuento > 0){
        totalPagar -= descuento
    }

    return (
        <form onSubmit={handleSubmit} id="form_payment" className="d-flex justify-content-center flex-column flex-sm-row align-items-stretch align-items-sm-center">
            <Field name="id_purchase" component={"input"} type="hidden"/>
            <Field name="url_retorno" component={"input"} type="hidden" />

            <table className="table" id="table-frm-pay-oc">
                <thead></thead>
                <tbody className="oc-body-form">
                    <tr>
                        <th className="oc-custom-table">
                            TOTAL A PAGAR
                        </th>
                        <td className="oc-custom-table">
                            {/*<RenderCurrency value={props.total} className="oc-total-pago"/>*/}
                            <RenderCurrency value={totalPagar} className="oc-total-pago"/>
                        </td>
                    </tr>
                    <tr>
                        <th className="oc-custom-table-middle">
                            EFECTIVO
                        </th>
                        <td className="oc-custom-table-middle">
                            <Field
                                name="cash"
                                component={modalCurrency}
                                type="text"
                                className="form-control oc-input-modal"
                            />
                        </td>
                    </tr>
                    {cash_payment &&
                        <React.Fragment>
                        <tr>
                            <th>NO. DOCUMENTO</th>
                            <td>
                                <Field
                                    name="no_document"
                                    component={renderField}
                                    type="text"
                                    className="form-control oc-input-modal"
                                />
                            </td>
                        </tr>
                        <tr className=""><th colSpan={2} className="oc-custom-table"><span className="pl-2">CAJA CHICA</span></th></tr>
                        <tr className="oc-white-border mx-5">
                            <td colSpan={2} className="oc-custom-table">
                                <div className="d-flex flex-row justify-content-around">
                                <Field
                                    name="small_box"
                                    valueKey="value"
                                    labelKey="nit"
                                    label="name"
                                    component={renderSearchSelect}
                                    loadOptions={getSmallBoxes}
                                    className="no-border"
                                    addClass="oc-select-modal"
                                />

                                <Field
                                    name="small_box"
                                    valueKey="value"
                                    labelKey="label"
                                    label="name"
                                    component={renderSearchSelect}
                                    loadOptions={getSmallBoxes}
                                    className="no-border"
                                    addClass="oc-select-modal"
                                />
                                </div>
                            </td>
                        </tr>
                        <tr><td>&nbsp;&nbsp;</td></tr>
                        </React.Fragment>
                    }
                    <tr>
                        <th className="oc-custom-table-middle">
                            TARJETA
                        </th>
                        <td className="oc-custom-table-middle">
                            <Field
                                name="card"
                                component={modalCurrency}
                                type="text"
                                className="form-control oc-input-modal"
                            />
                        </td>
                    </tr>
                    <tr>
                        <th className="oc-custom-table-middle">
                            VISA CUOTAS
                        </th>
                        <td className="oc-custom-table-middle">
                            <Field
                                name="due"
                                component={modalCurrency}
                                type="text"
                                className="form-control oc-input-modal"
                            />
                        </td>
                    </tr>
                    <tr>
                        <th>
                            TRANSACCIÓN
                        </th>
                        <td>
                            <Field
                                name="transaction"
                                component={modalCurrency}
                                type="text"
                                className="form-control oc-input-modal"
                            />
                        </td>
                    </tr>
                    {transaction_payment &&
                        <React.Fragment>
                        <tr>
                            <th>NO. DOCUMENTO</th>
                            <td>
                                <Field
                                    name="no_document"
                                    component={renderField}
                                    type="text"
                                    className="form-control oc-input-modal"
                                />
                            </td>
                        </tr>
                        <tr className="oc-white-border"><th colSpan={2} className="oc-custom-table"><span className="pl-2">CUENTA BANCARIA</span></th></tr>
                        <tr className="oc-white-border">
                            <td colSpan={2} className="oc-custom-table">
                                <div className="d-flex flex-row justify-content-around">
                                <Field
                                    name="bank_account"
                                    valueKey="value"
                                    labelKey="number"
                                    label="cuenta"
                                    component={renderSearchSelect}
                                    loadOptions={getBankAccounts}
                                    className="no-border"
                                    addClass="oc-select-modal"
                                />
                                <Field
                                    name="bank_account"
                                    valueKey="value"
                                    labelKey="label"
                                    label="referent"
                                    component={renderSearchSelect}
                                    loadOptions={getBankAccounts}
                                    className="no-border"
                                    addClass="oc-select-modal"
                                />
                                </div>
                            </td>
                        </tr>
                        </React.Fragment>
                    }


                    <tr>
                        <th>
                            NO. FACTURA
                        </th>
                        <td>
                            <Field
                                name="bill_number"
                                component={renderField}
                                type="text"
                                className="oc-input-modal"
                            />
                        </td>
                    </tr>
                    <tr>

                    </tr>
                    <tr>
                        <th>FECHA FACTURA</th>
                        <td>
                            <Field
                                name="fecha_fac"
                                component={renderFieldDateFormat}
                                type="text"
                            />
                        </td>
                    </tr>
                    <tr>
                        <th>DESCUENTO</th>
                        <td>
                            <Field
                                name="discount"
                                component={modalCurrency}
                                className="form-control oc-input-modal"
                            />
                        </td>
                    </tr>
                    {!!provider && (provider.retention) &&
                    <tr>
                        <th>
                            RETENCIÓN ISR
                        </th>
                        <td>
                            <Field
                                name="retention_amount"
                                component={modalCurrency}
                                className="form-control oc-input-modal"
                                disabled={true}
                            />
                        </td>
                    </tr>
                    }
                    {!!provider && (provider.retention_iva) &&
                    <tr>
                        <th>
                            RETENCIÓN IVA
                        </th>
                        <td>
                            <Field
                                name="retention_amount_iva"
                                component={modalCurrency}
                                className="form-control oc-input-modal"
                                disabled={true}
                            />
                        </td>
                    </tr>
                    }
                </tbody>
            </table>
        </form>
    );
}

PaymentForm = reduxForm({
    form: 'form_payment',
    initialValues,
    validate: data => {
        const errors={};
        console.log(data.transaction);
        if(data.transaction){
            if(!data.bank_account){
                errors.bank_account = 'Campo requerido';
            }

            if(!data.no_document){
                errors.no_document = 'Campo requerido';
            }
        }

        if(data.cash){
            if(!data.small_box){
                errors.small_box = 'Campo requerido';
            }

            if(!data.no_document){
                errors.no_document = 'Campo requerido';
            }
        }
        return errors;
    }
 })(PaymentForm);

const selector = formValueSelector('form_payment');

PaymentForm = connect(state => {
    const transaction = selector(state, "transaction");
    const cash = selector(state, "cash");
    //const retention = selector(state, "retention");
    const discount = selector(state, "discount");
    //const retiene = (retention === true) ? true : false


    let transaction_payment = false;
    if(transaction > 0){
        transaction_payment = true;
    }

    let cash_payment = false;
    if(cash > 0){
        cash_payment = true;
    }

    let descuento = 0;
    descuento = (discount > 0) ? discount : 0;

    return {
        transaction_payment,
        cash_payment,
        //retiene,
        descuento,
    };

})(PaymentForm);

export default PaymentForm;
