import _ from "lodash";

const validate = values => {
    const errors = {};
    if(!values.name){
        errors.name = 'Campo requerido';
    }
    if(!values.price){
        errors.price = 'Campo requerido';
    }
    if(!values.payback){
        errors.payback = 'Campo requerido';
    }
    if(!values.category){
        errors.category = 'Campo requerido';
    }
    if(!values.discount){
        errors.discount = 'Campo requerido';
    } else if(values.discount >= 100){
        errors.discount = '% no válido';
    }
    if(!values.service_category){
        errors.service_category = 'Campo requerido';
    }

    if(values.service_type === "product" && values.alert === true){
        if(!values.minimal_existence){
            errors.minimal_existence = 'campo requerido';
        }
    }

    if(!values.commissions){

    } else {
        const commissionsArrayErrors = [];
        values.commissions.forEach((commission, index) => {
            const commission_user_erros = {};
            if(!commission || !commission.user){
                commission_user_erros.user = 'Campo requerido';
                commissionsArrayErrors[index] = commission_user_erros;
            }
            if(!commission || !commission.commission){
                commission_user_erros.commission = 'Campo requerido';
                commissionsArrayErrors[index] = commission_user_erros;
            } else if (commission.commission < 1){
                commission_user_erros.commission = 'Cantidad no válida';
                commissionsArrayErrors[index] = commission_user_erros;
            }
            if(commission && _.filter(values.commissions, {user: commission.user}).length > 1){
                commission_user_erros.user = 'Usuario duplicado';
                commissionsArrayErrors[index] = commission_user_erros;

            }
        })
        if (commissionsArrayErrors.length) {
            errors.commissions = commissionsArrayErrors
        }
    }

    if(!values.categories_prices){

    } else {
        const categoriesPricesArrayErrors = [];
        values.categories_prices.forEach((category_price, index) => {
            const category_price_erros = {};
            if(!category_price || !category_price.price){
                category_price_erros.price = 'Campo requerido';
                categoriesPricesArrayErrors[index] = category_price_erros;
            }
            if(!category_price || !category_price.payback_1){
                category_price_erros.payback_1 = 'Campo requerido';
                categoriesPricesArrayErrors[index] = category_price_erros;
            }
            if(!category_price || !category_price.payback_2){
                category_price_erros.payback_2 = 'Campo requerido';
                categoriesPricesArrayErrors[index] = category_price_erros;
            }
        })
        if (categoriesPricesArrayErrors.length) {
            errors.categories_prices = categoriesPricesArrayErrors
        }
    }
    if(!values.package_items || values.package_items.length < 2){
        errors.package_items = { _error: 'Debe ingresar al menos dos elementos' }
    } else {
        const packageItemsArrayErrors = [];
        values.package_items.forEach((package_item, index) => {
            const package_item_erros = {};
            if(!package_item || !package_item.item){
                package_item_erros.item = 'Campo requerido';
                packageItemsArrayErrors[index] = package_item_erros;
            }
            if(!package_item || !package_item.quantity){
                package_item_erros.quantity = 'Campo requerido';
                packageItemsArrayErrors[index] = package_item_erros;
            } else if (package_item.quantity < 0){
                package_item_erros.quantity = 'Cantidad no válida';
                packageItemsArrayErrors[index] = package_item_erros;
            }
            if(package_item && _.filter(values.package_items, {item: package_item.item}).length > 1){
                package_item_erros.item = 'Material duplicado';
                packageItemsArrayErrors[index] = package_item_erros;

            }
        })
        if (packageItemsArrayErrors.length) {
            errors.package_items = packageItemsArrayErrors
        }
    }
    if(errors){
        console.log("HAY ERRORES")
    }
    return errors
};

export default validate
