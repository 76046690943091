import React, { Component } from 'react';
import classNames from 'classnames';

import LoadMask from 'Utils/LoadMask/LoadMask';
import Form from './ReferentForm';

export default class ReferentCrear extends Component {
    state = {
        editar: false,
        mensaje: 'Agregar referente',
        password: false,
    }

    componentWillMount() {
        const {
            createInitialize,
        } = this.props;

        createInitialize();

        if (this.props.match.params.id) {
            this.props.detail(this.props.match.params.id);
            this.setState({
                editar: true,
                mensaje: 'Editar referente',
            });
        }
    }

    render() {
        const {
            create,
            update,
            cargando,
            updateData,
            isNavBarOpen,
        } = this.props;

        return (
            <div className={classNames('content', { 'content-min': isNavBarOpen })}>
                <div className="m-auto row-fluid big-card-form">
                    <div className="table-decoration">
                        <LoadMask loading={cargando} blur_1>
                            {this.state.editar ? (
                                <div className="fields">
                                    <div className="top-title">
                                        REFERENTE
                                    </div>
                                    <Form
                                        onSubmit={update}
                                        updateData={updateData}
                                        editando
                                    />
                                </div>
                            ) : (
                                <div className="fields">
                                    <div className="top-title">
                                        CREAR REFERENTE
                                    </div>
                                    <Form onSubmit={create} />
                                </div>
                            )}
                        </LoadMask>
                    </div>
                </div>
            </div>
        );
    }
}
