import React, {Component} from 'react';
import Modal from 'react-responsive-modal';
import classNames from 'classnames';
import "../notations";


export class Ejz extends Component {
    constructor(props) {
        super(props);
        this.state = { open: false, inputValue: -1 };
    }

    openModal = () => {
        this.setState({ open: true, inputValue: -1 });
    };

    closeModal = () => {
        this.setState({ open: false, inputValue: -1 });
    };

    selectOption = (input, value) => {
        input.onChange(value);
        this.closeModal();
    };

    render() {
        const { input, meta: { touched, error }, title } = this.props;
        const { open } = this.state;
        const invalid = touched && error;

        //let fi = 1;
        let fi = 0;
        //const ff = 30;
        let ff = 44;

        let ci = 0;
        //const cf = 5;
        const cf = 4;

        let columnas = [];

        for(fi;fi<=ff;fi++){
            ci = 0;
            let fila = [];
            for(ci;ci<=cf;ci++){
                //const cantidad = fi + (ci*30);
                const cantidad = fi + (ci*45);
                fila.push(cantidad.toFixed(0))
            }
            columnas.push(fila);
        }


        return (
            <React.Fragment>
                <Modal open={open} onClose={this.closeModal} >
                    <div className="text-center">
                        <h2 className="border-bottom">{title}</h2>
                    </div>
                    {/*
                    <table id="notations-table-modal">
                        <tr>
                            
                        </tr>
                        <tbody>   
                        {columnas.map(columna=>(
                            <tr key={columna}>
                                {columna.map(fila=>{                                    
                                    const color_label = fila >= 0 ? 'color-verde' : 'color-rojo';
                                    if(fila <= 180){
                                        return(
                                            <td key={fila}>
                                                <div className="radio">
                                                    <label htmlFor="service_type" className={`radio-inline mr-5 d-flex align-items-center`}
                                                        onClick={() => this.selectOption(input, fila)}>
                                                        <input type={"radio"} value={fila} {...input} onChange={() => this.selectOption(input, fila)}
                                                            className={classNames('form-control', { 'is-invalid': invalid })} />
                                                        <span>&nbsp;{fila}</span>
                                                    </label>
                                                </div>
                                            </td>
                                        );
                                    }
                                })}
                            </tr>
                        ))}
                        </tbody>
                    </table>
                     */}
                     <div 
                        style={{
                            display: "flex",
                            flexDirection: "column"
                        }}
                     >
                        <div
                            style={{
                                width: "250px",
                                display: "flex",
                                gap: "8px",
                                justifyContent: "space-between",
                                border: "unset !important"
                            }}                            
                        >
                            <input
                                onChange={(e)=>{                                    
                                    this.setState({ inputValue: e.target.value })
                                }}
                                value={this.state.inputValue === -1 ? '' : this.state.inputValue}
                                type="number"
                                min={0}
                                max={180}
                            />
                            <button
                                onClick={()=>{
                                    this.selectOption(input, this.state.inputValue);                                    
                                }}
                                disabled={this.state.inputValue == -1 || this.state.inputValue > 180}
                                className="btn btn-sm btn-success"
                            >OK</button>
                        </div>
                        {this.state.inputValue < 0 || this.state.inputValue > 180 &&
                        <span style={{ color: "red" }}>
                            Solo se permiten valores entre 0 y 180
                        </span>
                        }
                     </div>
                    <div className="d-flex justify-content-center align-items-center mt-4">
                        <div
                            className="btn btn-secondary mx-4"
                            onClick={() => {
                                input.onChange(undefined);
                                this.closeModal();
                            }}>
                            Cancelar
                        </div>
                    </div>
                </Modal>
                <span className="form-control text-center" onClick={this.openModal} >{input.value}</span>
            </React.Fragment>
        );
    }
}
