import React, { Component } from 'react'
import ReactDOM from 'react-dom'
import { Link } from 'react-router-dom';
import './headersimple.css';

export default class HeaderSimple extends Component {
  componentDidMount(){
    ReactDOM.findDOMNode(this.refs['link0']).focus()
  }
  render() {
    return (
      <div className="col-md-12 d-flex justify-content-start justify-content-md-start pt-4 mb-4">
        {/* <button className="btn btn-primary btn-estadocuenta">
            Agregar usuario
        </button> */}
        <Link className="btn btn-primary" to={this.props.ruta} ref="link0">
            {this.props.texto}
        </Link>
      </div>
    )
  }
}
