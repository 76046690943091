import React from 'react'
import { Field, reduxForm, formValueSelector } from 'redux-form'
import { renderField } from '../Utils/renderField'
import { validate, validators } from 'validate-redux-form';
import { connect } from 'react-redux'

let LoginForm = props => {

    const {
        handleSubmit,
        pristine,
        reset,
        submitting,
        input_type,
    } = props;

    console.log("input_type: ", input_type);
    const icon_view = input_type === 'password' ? 'password-eye-icon' : 'password-slash-eye-icon'

    return ( 
        <form name="loginForm" className="form-validate mb-lg" onSubmit={handleSubmit}>
            <Field name="input_type" component='input' type="hidden" />
            <div>
                <span>
                    <img src={require("../../../../assets/img/icons/user-icon.png")} alt="Icono_de_usuario"/>
                </span>
                <label>
                    Usuario
                </label>
            </div>
            <div className="form-group has-feedback">
                <Field name="username" component={renderField} type="text" className="form-control" />
            </div>
            <div>
                <span>
                    <img src={require("../../../../assets/img/icons/lock-icon.png")} alt="Icono_de_contraseña"/>
                </span>
                <label>
                    Contraseña
                </label>
            </div>
            <div className="form-group has-feedback d-flex flex-row">
                <Field name="password" component={renderField} type={input_type} className="form-control" />                
                <a 
                    onClick={()=>{                        
                        const etiqueta = (input_type === 'password') ? 'text' : 'password';
                        props.change('input_type', etiqueta);
                    
                }}>
                    <img 
                        src={require(`../../../../assets/img/icons/${icon_view}.png`)} 
                        alt="Icono_de_contraseña"
                        style={{
                            width: 40,
                            height: 40,
                        }}
                    />
                </a>
            </div>
            <div className="clearfix" />
            <button type="submit" className="btn btn-primary">Iniciar sesión</button>
        </form>
    )
}

LoginForm =  reduxForm({
    form: 'login',
    validate: (data) => {
        return validate(data, {
            username: validators.exists()('Este campo es requerido'),
            password: validators.exists()('Este campo es requerido'),
        });
    },
})(LoginForm)

const selector = formValueSelector('login');

LoginForm = connect(state => {
    const input_type = selector(state, "input_type");

    return {
        input_type,
    };

})(LoginForm);

export default LoginForm;

// export default reduxForm({
//     form: 'login' // a unique identifier for this form
// })(LoginForm)