import React, { Component } from 'react';
import './toolbar.css';

class SearchChange extends Component {

    constructor(props){
        super(props);
    }

    render() {
        const {
            buscar,
            buscador,
            autofocus,
            date,
        } = this.props;

        return (
            <div className="col-12 pull-right toolbar-search p-0">
                {
                    (buscador !== undefined && buscar !== undefined) && (
                        <div className="contenedor-search">
                        <input id="search_change" type="text" name="buscar" placeholder="Buscar..."

                            ref={node => {
                                this.buscar = node;
                                    if (this.buscar) {
                                        if(autofocus){
                                            this.buscar.focus()
                                        }
                                        this.buscar.value = buscador;
                                    }
                                }}

                                onKeyPress={(event) => {
                                    if (event.key === 'Enter') {
                                        this.props.buscar(this.buscar.value);
                                    }
                                }}
                            // onChange={()=>{
                            //     this.props.buscar(this.buscar.value);
                            // }}

                            autoComplete="off" className="form-control w-100"

                        />
                        <button
                            className="form-control-feedback boton-busqueda"
                            onClick={(event) => {
                                event.preventDefault();
                                this.props.buscar(this.buscar.value);
                                date.fechaInicial && date.setInitialDate(date.fechaInicial)
                                date.fechaFinal && date.setFinalDate(date.fechaFinal)
                                }}
                        >
                        <img src={require("../../../../../assets/img/icons/search-icon.png")} alt="Buscar_icon"/>
                    </button>
                </div>
            )}
            </div>
        );
    }
}

export default SearchChange;
