import React, { Component } from 'react';
import { RenderCurrency } from 'Utils/renderField/renderReadField';
import MyModal from '../Utils/myModal';
import FormAddCash from './SetCashForm';
import '../arqueos/arqueo.css'

class Categoria extends Component{
    render(){
        const {categoria} = this.props;
        return(
            <table className="table table-sm table-bordered w-50">
                <thead>

                </thead>
                <tbody>
                    <tr>
                        <th colSpan={2}>
                            <span>
                                {categoria.nombre} &nbsp;
                                <RenderCurrency value={categoria.total}/>
                            </span>
                            <ul>
                                <li>
                                    <div className='d-flex flex-row w-100'>
                                        <div className='d-flex flex-row w-75'>
                                            <span>Efectivo:</span>
                                        </div>
                                        <div className='d-flex flex-row w-25'>
                                            <RenderCurrency value={categoria.total_efectivo}/>
                                        </div>

                                    </div>
                                    <div className='w-100 flex-wrapp'>
                                        {categoria.movimientos_efectivo.map((mov, i)=>
                                            <a href={`/#/report-movements/view-bill/${mov.id}/caja`} target='_blank' key={i}>
                                                <span className="badge badge-secondary m-1" style={{fontSize:10}} key={i}>
                                                    {mov.correlative}
                                                </span>
                                            </a>
                                        )}
                                    </div>
                                </li>
                                <li>
                                    <div className='d-flex flex-row w-100'>
                                        <div className='d-flex flex-row w-75'>
                                            <span>Tarjeta:</span>
                                        </div>
                                        <div className='d-flex flex-row w-25'>
                                            <RenderCurrency value={categoria.total_tarjeta}/>
                                        </div>
                                    </div>
                                    <div className='w-100 flex-wrapp'>
                                        {categoria.movimientos_tarjeta.map((mov, i)=>
                                            <a href={`/#/report-movements/view-bill/${mov.id}/caja`} target='_blank' key={i}>
                                                <span className="badge badge-secondary m-1" style={{fontSize:10}} key={i}>
                                                    {mov.correlative}
                                                </span>
                                            </a>
                                        )}
                                    </div>
                                </li>
                                <li>
                                    <div className='d-flex flex-row w-100'>
                                        <div className='d-flex flex-row w-75'>
                                            <span>Tarjeta (cuotas):</span>
                                        </div>
                                        <div className='d-flex flex-row w-25'>
                                            <RenderCurrency value={categoria.total_cuota}/>
                                        </div>
                                    </div>
                                    <div className='w-100 flex-wrapp'>
                                        {categoria.movimientos_cuota.map((mov, i)=>
                                            <a href={`/#/report-movements/view-bill/${mov.id}/caja`} target='_blank' key={i}>
                                                <span className="badge badge-secondary m-1" style={{fontSize:10}}>
                                                    {mov.correlative?mov.correlative: 'ID: ' + mov.id}
                                                </span>
                                            </a>
                                        )}
                                    </div>
                                </li>
                                <li>
                                    <div className='d-flex flex-row w-100'>
                                        <div className='d-flex flex-row w-75'>
                                            <span>Depósito</span>
                                        </div>
                                        <div className='d-flex flex-row w-25'>
                                            <RenderCurrency value={categoria.total_deposito}/>
                                        </div>
                                    </div>
                                    <div className='w-100 flex-wrapp'>
                                        {categoria.movimientos_deposito.map((mov, i)=>
                                            <a href={`/#/report-movements/view-bill/${mov.id}/caja`} target='_blank' key={i}>
                                                <span className="badge badge-secondary m-1" style={{fontSize:10}}>
                                                    {mov.correlative?mov.correlative: 'ID: ' + mov.id}
                                                </span>
                                            </a>
                                        )}
                                    </div>
                                </li>
                            </ul>
                        </th>
                    </tr>
                </tbody>
            </table>
        );
    }
}

const tableDescription = (props) =>{

    const modalForm = <FormAddCash financial_entity_id={props.id} onSubmit={() => props.addCash(props.id_or)} />;
    //const total = parseFloat(parseFloat(props.total_efectivo).toFixed(2)) + parseFloat(parseFloat(props.total_abonos).toFixed(2)) + parseFloat(parseFloat(props.total_tarjeta).toFixed(2)) + parseFloat(parseFloat(props.total_ingreso).toFixed(2));
    const total = parseFloat(parseFloat(props.ventas.totales.total_efectivo).toFixed(2)) + parseFloat(parseFloat(props.total_abonos_efectivo).toFixed(2))  + parseFloat(parseFloat(props.total_ingreso).toFixed(2));


    let summary_category = [];
    if(props.summary_category && props.summary_category.hasOwnProperty(props.usuario_fel)){
        summary_category = props.summary_category[props.usuario_fel];
    }

    if(props.summary_category && props.summary_category.length > 0){
        props.summary_category.forEach((categoria, i) => {
            if(categoria.nombre === props.usuario_fel){
                summary_category = categoria.categorias;
            }
        });
    }
    
    console.log('TOTAL ---------', props);
    console.log("datos de notas de crédito y anulaciones ::: ", props.ncredito_anulaciones.facturas);

    return(
        <React.Fragment>
        <div className="d-flex flex-column">
            <div className="d-flex flex-row justify-content-center align-items-top mt-2">
                {props.paso_final !== true &&
                <div className="w-50 d-flex justify-content-center">
                    <div className="text-center">
                        <div>
                            <img src={require("../../../../assets/img/icons/agregar-efectivo.png")} alt="CashBox image" className="addRemoveCashImage" />
                        </div>

                        <div>
                            <MyModal
                                isOpen={false}
                                classToggleButton="btn btn-success btn-sm toggle-button-modal"
                                textButton="Agregar Efectivo"
                                title="Agregar Efectivo"
                                body={modalForm}
                                formId="addCash"
                            />
                        </div>
                    </div>
                </div>
                }
            <table className="table table-sm table-bordered w-50">
                <thead>

                </thead>
                <tbody>
                    <tr>
                        <th colSpan={2}>
                            <span>
                                VENTAS &nbsp;
                                <RenderCurrency value={props.ventas.totales.total}/>
                            </span>
                            <ul>
                                <li>
                                    <div className='d-flex flex-row w-100'>
                                        <div className='d-flex flex-row w-75'>
                                            <span>Efectivo:</span>
                                        </div>
                                        <div className='d-flex flex-row w-25'>
                                            <RenderCurrency value={props.ventas.totales.total_efectivo}/>
                                        </div>

                                    </div>
                                    <div className='w-100 flex-wrapp'>
                                        {props.ventas.movs_efectivo.map((mov, i)=>
                                            mov.efective_quantity > 0
                                            ?
                                            <a href={`/#/report-movements/view-bill/${mov.id}/caja`} target='_blank' key={i}>
                                                <span className={`badge badge-secondary m-1`} style={{fontSize:10, background: mov.numeroAutorizacion ? 'green' : '#6c757d;'}} key={i}>
                                                    {mov.correlative} - Q.{mov.efective_quantity}
                                                </span>
                                            </a>
                                            :
                                            <a href={`/#/report-movements/view-bill/${mov.id}/caja`} target='_blank' key={i}>
                                                <span className="badge badge-secondary m-1 fac-red" style={{fontSize:10}} key={i}>
                                                    {mov.correlative} - Q.{mov.efective_quantity}
                                                </span>
                                            </a>
                                        )}
                                    </div>
                                </li>
                                <li>
                                    <div className='d-flex flex-row w-100'>
                                        <div className='d-flex flex-row w-75'>
                                            <span>Tarjeta:</span>
                                        </div>
                                        <div className='d-flex flex-row w-25'>
                                            <RenderCurrency value={props.ventas.totales.total_tarjeta}/>
                                        </div>
                                    </div>
                                    <div className='w-100 flex-wrapp'>
                                        {props.ventas.movs_tarjeta.map((mov, i)=>
                                            <a href={`/#/report-movements/view-bill/${mov.id}/caja`} target='_blank' key={i}>
                                                <span className="badge badge-secondary m-1" style={{fontSize:10}} key={i}>
                                                    {mov.correlative} - Q.{mov.card_quantity}
                                                </span>
                                            </a>
                                        )}
                                    </div>
                                </li>
                                <li>
                                    <div className='d-flex flex-row w-100'>
                                        <div className='d-flex flex-row w-75'>
                                            <span>Tarjeta (cuotas):</span>
                                        </div>
                                        <div className='d-flex flex-row w-25'>
                                            <RenderCurrency value={props.ventas.totales.total_cuotas}/>
                                        </div>
                                    </div>
                                    <div className='w-100 flex-wrapp'>
                                        {props.ventas.movs_cuotas.map((mov, i)=>
                                            <a href={`/#/report-movements/view-bill/${mov.id}/caja`} target='_blank' key={i}>
                                                <span className="badge badge-secondary m-1" style={{fontSize:10}}>
                                                    {mov.correlative} - Q.{(parseFloat(mov.due_quantity))}
                                                </span>
                                            </a>
                                        )}
                                    </div>
                                </li>
                                <li>
                                    <div className='d-flex flex-row w-100'>
                                        <div className='d-flex flex-row w-75'>
                                            <span>Depósito</span>
                                        </div>
                                        <div className='d-flex flex-row w-25'>
                                            <RenderCurrency value={props.ventas.totales.total_deposito}/>
                                        </div>
                                    </div>
                                    <div className='w-100 flex-wrapp'>
                                        {props.ventas.movs_deposito.map((mov, i)=>
                                            <a href={`/#/report-movements/view-bill/${mov.id}/caja`} target='_blank' key={i}>
                                                <span className="badge badge-secondary m-1" style={{fontSize:10}}>
                                                {mov.correlative} - Q.{mov.deposit_quantity}
                                                </span>
                                            </a>
                                        )}
                                    </div>
                                </li>
                            </ul>

                        </th>
                    </tr>
                    <tr>
                        <th colSpan={2}>
                            <span>
                                CUENTAS ABIERTAS (ANTICIPOS) &nbsp;
                                <RenderCurrency value={props.cuentas_abiertas.totales.total == null ? 0 : props.cuentas_abiertas.totales.total} />
                            </span>
                            <ul>
                                <li>
                                    <div className='d-flex flex-row w-100'>
                                        <div className='d-flex flex-row w-75'>
                                            <span>Efectivo:</span>
                                        </div>
                                        <div className='d-flex flex-row w-25'>
                                            <RenderCurrency value={props.cuentas_abiertas.totales.total_efectivo == null ? 0 : props.cuentas_abiertas.totales.total_efectivo}/>
                                        </div>
                                    </div>
                                     <div className='w-100 flex-wrapp'>
                                        {props.cuentas_abiertas.movs_efectivo.map((mov, i)=>
                                            <a href={`/#/report-movements/view-bill/${mov.id}/caja`} target='_blank' key={i}>
                                                <span className="badge badge-secondary m-1" style={{fontSize:10}} key={i}>
                                                    {mov.correlative} - Q.{mov.efective_quantity}
                                                </span>
                                            </a>
                                        )}
                                    </div>
                                </li>
                                <li>
                                    <div className='d-flex flex-row w-100'>
                                        <div className='d-flex flex-row w-75'>
                                            <span>Tarjeta:</span>
                                        </div>
                                        <div className='d-flex flex-row w-25'>
                                            <RenderCurrency value={props.cuentas_abiertas.totales.total_tarjeta == null ? 0 : props.cuentas_abiertas.totales.total_tarjeta}/>
                                        </div>
                                    </div>
                                     <div className='w-100 flex-wrapp'>
                                        {props.cuentas_abiertas.movs_tarjeta.map((mov, i)=>
                                            <a href={`/#/report-movements/view-bill/${mov.id}/caja`} target='_blank' key={i}>
                                                <span className="badge badge-secondary m-1" style={{fontSize:10}} key={i}>
                                                    {mov.correlative?mov.correlative: 'ID: '+mov.id} - Q.{mov.card_quantity}
                                                </span>
                                            </a>
                                        )}
                                    </div>
                                </li>
                                <li>
                                    <div className='d-flex flex-row w-100'>
                                        <div className='d-flex flex-row w-75'>
                                            <span>Tarjeta (cuotas):</span>
                                        </div>
                                        <div className='d-flex flex-row w-25'>
                                            <RenderCurrency value={props.cuentas_abiertas.totales.total_cuotas == null ? 0 : props.cuentas_abiertas.totales.total_cuotas}/>
                                        </div>
                                    </div>
                                    <div className='w-100 flex-wrapp'>
                                        {props.cuentas_abiertas.movs_cuotas.map((mov, i)=>
                                            <a href={`/#/report-movements/view-bill/${mov.id}/caja`} target='_blank' key={i}>
                                                <span className="badge badge-secondary m-1" style={{fontSize:10}}>
                                                    {mov.correlative? mov.correlative + '': 'ID: '+mov.id} - Q.{(parseFloat(mov.due_quantity))}
                                                </span>
                                            </a>
                                        )}
                                    </div>
                                </li>
                                <li>
                                    <div className='d-flex flex-row w-100'>
                                        <div className='d-flex flex-row w-75'>
                                            <span>Depósito</span>
                                        </div>
                                        <div className='d-flex flex-row w-25'>
                                            <RenderCurrency value={props.cuentas_abiertas.totales.total_deposito == null ? 0 : props.cuentas_abiertas.totales.total_deposito}/>
                                        </div>
                                    </div>
                                    <div className='w-100 flex-wrapp'>
                                        {props.cuentas_abiertas.movs_deposito.map((mov, i)=>
                                            <a href={`/#/report-movements/view-bill/${mov.id}/caja`} target='_blank' key={i}>
                                                <span className="badge badge-secondary m-1" style={{fontSize:10}}>
                                                {mov.correlative?mov.correlative: 'ID: '+ mov.id} - Q.{mov.deposit_quantity}
                                                </span>
                                            </a>
                                        )}
                                    </div>
                                </li>
                                <li>
                                    <div className='d-flex flex-row w-100'>
                                        <div className='d-flex flex-row w-75'>
                                            <span>Sin Anticipo</span>
                                        </div>
                                        <div className='d-flex flex-row w-25'>
                                            <RenderCurrency value={props.cuentas_abiertas.totales.total_sin_anticipo == null ? 0 : props.cuentas_abiertas.totales.total_sin_anticipo}/>
                                        </div>
                                    </div>
                                </li>
                            </ul>
                            <div className='w-100 flex-wrapp'>
                                {props.cuentas_abiertas.movs_sin_abono.map((mov, i)=>
                                    <span
                                        className="badge badge-secondary m-1 cursor-pointer"
                                        style={{fontSize:10}}
                                        key={i}
                                        onClick={()=>{
                                            props.openAccount(mov.codigo)
                                        }}
                                    >{mov.codigo} - Q.{mov.open_account_quantity}</span>
                                )}
                            </div>
                        </th>
                    </tr>
                    <tr>
                        <th colSpan={2}>
                            <span>
                                CUENTAS POR COBRAR (ABONOS) &nbsp;
                                <RenderCurrency value={props.cuentas_cobrar.totales.total} />
                            </span>
                            <ul>
                                <li>
                                    <div className='d-flex flex-row w-100'>
                                        <div className='d-flex flex-row w-75'>
                                            <span>Efectivo:</span>
                                        </div>
                                        <div className='d-flex flex-row w-25'>
                                            <RenderCurrency value={props.cuentas_cobrar.totales.total_efectivo}/>
                                        </div>
                                    </div>
                                    <div className='w-100 flex-wrapp'>
                                        {props.cuentas_cobrar.movs_efectivo.map((mov, i)=>
                                            <a href={`/#/report-movements/view-bill/${mov.id}/caja`} target='_blank' key={i}>
                                                <span className="badge badge-secondary m-1" style={{fontSize:10, background: mov.numeroAutorizacion ? 'green' : '#6c757d;'}} key={i}>
                                                    {mov.correlative} - Q.{mov.efective_quantity}
                                                </span>
                                            </a>
                                        )}
                                    </div>
                                </li>
                                <li>
                                    <div className='d-flex flex-row w-100'>
                                        <div className='d-flex flex-row w-75'>
                                            <span>Tarjeta:</span>
                                        </div>
                                        <div className='d-flex flex-row w-25'>
                                            <RenderCurrency value={props.cuentas_cobrar.totales.total_tarjeta}/>
                                        </div>
                                    </div>
                                    <div className='w-100 flex-wrapp'>
                                        {props.cuentas_cobrar.movs_tarjeta.map((mov, i)=>
                                            <a href={`/#/report-movements/view-bill/${mov.id}/caja`} target='_blank' key={i}>
                                                <span className="badge badge-secondary m-1" style={{fontSize:10}} key={i}>
                                                    {mov.correlative} - Q.{mov.card_quantity}
                                                </span>
                                            </a>
                                        )}
                                    </div>
                                </li>
                                <li>
                                    <div className='d-flex flex-row w-100'>
                                        <div className='d-flex flex-row w-75'>
                                            <span>Tarjeta (cuotas):</span>
                                        </div>
                                        <div className='d-flex flex-row w-25'>
                                            <RenderCurrency value={props.cuentas_cobrar.totales.total_cuotas}/>
                                        </div>
                                    </div>
                                    <div className='w-100 flex-wrapp'>
                                        {props.cuentas_cobrar.movs_cuotas.map((mov, i)=>
                                            <a href={`/#/report-movements/view-bill/${mov.id}/caja`} target='_blank' key={i}>
                                                <span className="badge badge-secondary m-1" style={{fontSize:10}}>
                                                    {mov.correlative} - Q.{mov.due_quantity}
                                                </span>
                                            </a>
                                        )}
                                    </div>
                                </li>
                                <li>
                                    <div className='d-flex flex-row w-100'>
                                        <div className='d-flex flex-row w-75'>
                                            <span>Depósito</span>
                                        </div>
                                        <div className='d-flex flex-row w-25'>
                                            <RenderCurrency value={props.cuentas_cobrar.totales.total_deposito}/>
                                        </div>
                                    </div>
                                    <div className='w-100 flex-wrapp'>
                                        {props.cuentas_cobrar.movs_deposito.map((mov, i)=>
                                            <a href={`/#/report-movements/view-bill/${mov.id}/caja`} target='_blank' key={i}>
                                                <span className="badge badge-secondary m-1" style={{fontSize:10}}>
                                                    {mov.correlative} - Q.{mov.deposit_quantity}
                                                </span>
                                            </a>
                                        )}
                                    </div>
                                </li>
                                <li>
                                    <div className='d-flex flex-row w-100'>
                                        <div className='d-flex flex-row w-75'>
                                            <span>Sin Abono</span>
                                        </div>
                                        <div className='d-flex flex-row w-25'>
                                            <RenderCurrency value={props.cuentas_cobrar.totales.total_sin_anticipo}/>
                                        </div>
                                    </div>
                                    <div className='w-100 flex-wrapp'>
                                        {props.cuentas_cobrar.movs_sin_abono.map((mov, i)=>
                                            <a href={`/#/report-movements/view-bill/${mov.id}/caja`} target='_blank' key={i}>
                                                <span className="badge badge-secondary m-1" style={{fontSize:10}}>
                                                    {mov.correlative} -Q{mov.payment}
                                                </span>
                                            </a>
                                        )}
                                    </div>
                                </li>
                            </ul>
                        </th>
                    </tr>
                    <tr>
                        <th>Agregado en efectivo</th>
                        <td className="right-text"><RenderCurrency value={props.total_ingreso}/></td>
                    </tr>
                    <tr>
                        <th>Abonos y Anticipos</th>
                        <td className="right-text"><RenderCurrency value={props.total_abonos_completo}/></td>
                    </tr>
                    <tr>
                        <th className="ident">Abonos y Anticipos, Efectivo</th>
                        <td className="right-text"><RenderCurrency value={props.total_abonos_efectivo}/></td>
                    </tr>
                    <tr>
                        <th>Retenciones</th>
                        <td className="right-text"><RenderCurrency value={props.total_retention}/></td>
                    </tr>
                    <tr>
                        <th>Ventas en efectivo</th>
                        <td className="right-text"><RenderCurrency value={props.ventas.totales.total_efectivo}/></td>
                    </tr>
                    <tr>
                        <th>Ventas con tarjeta</th>
                        <td className="right-text"><RenderCurrency value={props.total_tarjeta}/></td>
                    </tr>


                    <tr>
                        <th>Depósitos</th>
                        <td className="right-text"><RenderCurrency value={props.total_deposito}/></td>
                    </tr>
                    <tr>
                        <th>
                            Notas de credito y anulaciones
                            <div>
                                {props.ncredito_anulaciones.facturas.map((mov, i)=>
                                    <a href={`/#/report-movements/view-bill/${mov.id}/caja`} target='_blank' key={i}>
                                        <span className={`badge badge-secondary m-1 ${mov.fel_cancel_status == 3 && " fac-red"}`} style={{fontSize:10}}>
                                            {mov.correlative} - Q.{(parseFloat(mov.payment) + parseFloat(mov.extra_amount)).toFixed(2)}
                                        </span>
                                    </a>
                                )}
                                </div>
                            </th>
                        <td className="right-text"><RenderCurrency value={props.ncredito_anulaciones.total   }/></td>
                    </tr>
                    <tr>
                        <th >TOTAL EFECTIVO EN CAJA</th>
                        <td className="right-text"><RenderCurrency value={total}/></td>
                    </tr>

                </tbody>
            </table>
            </div>
            {summary_category.length > 0 ?
            <div className="d-flex flex-row justify-content-end">
            <table className="table table-sm table-bordered table-resume-category w-50">
                <thead>
                    <tr><th colSpan={2}>Resumen Por Categoría</th></tr>
                </thead>
                <tbody>
                    {summary_category.map((detalle, i) => (
                        <Categoria categoria={detalle} />
                    ))}
                </tbody>
            </table>
            </div>
            : null }
        </div>
        </React.Fragment>
    );
}

export default tableDescription;
