import { connect } from 'react-redux';

import Referent from './Referent';
import { actions } from '../../../redux/modules/referent';
import {resetStore} from "../../../redux/modules/login";


const ms2p = state => ({
    ...state.referents,
});

const md2p = { ...actions, resetStore };

export default connect(ms2p, md2p)(Referent);
