import { connect } from 'react-redux';
import Doctor from './DoctorCrear';
import { actions } from '../../../redux/modules/doctor';
import {resetStore} from "../../../redux/modules/login";


const ms2p = (state) => {
    return {
        ...state.doctor,
    };
};

const md2p = { ...actions, resetStore };

export default connect(ms2p, md2p)(Doctor);
