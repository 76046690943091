const url_ingresos = 'reportes/bodega/';
import { api } from 'api';
import Swal from 'sweetalert2';
import { push } from 'react-router-redux';
import { goBack } from 'react-router-redux';
import { initialize as initializeForm } from 'redux-form';
import { createActions, handleActions } from 'redux-actions';
import moment from "moment";

const LOADER_DETALLES = 'LOADER_DETALLES';

// Reporte detalles ingresos
const SET_DATA_DETALLES_INGRESOS = 'SET_DATA_DETALLES_INGRESOS';

// Reporte detalles reajustes
const SET_DATA_DETALLES_REAJUSTES = 'SET_DATA_DETALLES_REAJUSTES';

// PARA EL REPORTE DETALLES INGRESOS 
const listarDetallesIngresos = (ingreso_id) =>  (dispatch, getStore) => {
    dispatch({type: LOADER_DETALLES, cargando: true});

    api.get(`${url_ingresos}detalle_ingresos`, {id:ingreso_id}).catch((error) => {
        dispatch({type: LOADER_DETALLES, cargando: false});
        Swal(
            'Error',
            error.detail || 'Ha ocurrido un error, por favor vuelva a intentar.',
            'error'
        );
    }).then((data) => {
        if(data){
            dispatch({type: SET_DATA_DETALLES_INGRESOS, data_detalles_ingresos: data});
        }
        dispatch({type: LOADER_DETALLES, cargando: false});
    })
};
//---

// PARA EL REPORTE DETALLES REAJUSTES
const listarDetallesReajustes = (baja_id) =>  (dispatch, getStore) => {
    dispatch({type: LOADER_DETALLES, cargando: true});

    api.get(`${url_ingresos}detalle_reajuste`, {id:baja_id}).catch((error) => {
        dispatch({type: LOADER_DETALLES, cargando: false});
        Swal(
            'Error',
            error.detail || 'Ha ocurrido un error, por favor vuelva a intentar.',
            'error'
        );
    }).then((data) => {
        if(data){
            dispatch({type: SET_DATA_DETALLES_REAJUSTES, data_detalles_reajustes: data});
        }
        dispatch({type: LOADER_DETALLES, cargando: false});
    })
};
//---

export const actions = {
    // Ingresos
    listarDetallesIngresos,
    // Reajustes
    listarDetallesReajustes,
}

export const reducer = {
    [LOADER_DETALLES]: (state, { cargando }) => {
        return {...state, cargando }
    },

    // PARA EL REPORTE DE DETALLES INGRESOS A BODEGA
    [SET_DATA_DETALLES_INGRESOS]: (state, { data_detalles_ingresos }) => {
        return {...state, data_detalles_ingresos }
    },
    // PARA EL REPORTE DE DETALLES REAJUSTES A BODEGA
    [SET_DATA_DETALLES_REAJUSTES]: (state, { data_detalles_reajustes }) => {
        return {...state, data_detalles_reajustes }
    },
}

export const initialState = {
    cargando: false,
    // Para el reporte de detalles ingresos a bodega
    data_detalles_ingresos: {
        id: 0,
        creado: '',
        document_number: '',
        bodega: '',
        user: {},
        detalles: [],
    },
    // Para el reporte de detalles reajustes a bodega
    data_detalles_reajustes: {
        id: 0,
        creado: '',
        document_number: '',
        bodega: '',
        user: {},
        detalles: [],
    },
}

export default handleActions(reducer, initialState)
