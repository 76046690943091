import _ from 'lodash';
const url = 'services';
import { api } from "api";
import Swal from 'sweetalert2';
import { push } from 'react-router-redux';
import { goBack } from 'react-router-redux';
import { initialize as initializeForm } from 'redux-form';
import { createActions, handleActions } from 'redux-actions';

const LOADER_SERVICE = 'LOADER_SERVICE';
const SET_DATA_SERVICE = 'SET_DATA_SERVICE';
const SET_PAGE_SERVICE = 'SET_PAGE_SERVICE';
const SET_UPDATE_DATA_SERVICE ='SET_UPDATE_DATA_SERVICE';
const SET_BUSCADOR_SERVICE = 'SET_BUSCADOR_SERVICE';
const SET_FILTRO_SERVICE = 'SET_FILTRO_SERVICE';

const SET_CATEGORY_PRICES = 'SET_CATEGORY_PRICES';

const SET_SEARCH = 'SET_SEARCH';

const SET_SERVICE_TYPE = 'SET_SERVICE_TYPE';

const PRODUCT_STR = 'product'
const SERVICE_STR = 'service'
const PACKAGE_STR = 'package'
const FRACTION_STR = 'fraction'

const PRODUCT = 1
const SERVICE = 2
const PACKAGE = 3
const FRACTION = 4

const listar = (page = 1) =>  (dispatch, getStore) => {
    dispatch({type: LOADER_SERVICE, cargando: true});
    const store = getStore();
    const search = store.services.buscador || "";
    const filtro = store.services.filtro_services;
    let params = {page, search};

    if(filtro){
        params[''] = filtro
    }
    api.get(`${url}`, params).catch((error) => {
        dispatch({type: LOADER_SERVICE, cargando: false});
        Swal(
            'Error',
            error.detail || 'Ha ocurrido un error, por favor vuelva a intentar.',
            'error'
        );  
    }).then((data) => {
        console.log("Data: ", data);
        if(data){
            dispatch({type: SET_DATA_SERVICE, data});
            dispatch({type: SET_PAGE_SERVICE, page});
        }
        dispatch({type: LOADER_SERVICE, cargando: false});
        dispatch({type: SET_UPDATE_DATA_SERVICE, updateData: {}});
    })
};

const detail = id => (dispatch, getState) => {
    dispatch({type: LOADER_SERVICE, cargando: true});
    api.get(`${url}/${id}`).catch((error) => {
        dispatch({type: LOADER_SERVICE, cargando: false});
        if(error.statusCode  === 404){
            dispatch(push('/services'))
        }
    }).then((data) => {
        dispatch({type: LOADER_SERVICE, cargando: false});
        console.log("Data service detail: ", data);
        if(data){

            for (let index = 0; index < data.financial_entities.length; index++) {
                const financial_entity_new = {label: data.financial_entities[index].financial_entity.usuario_fel, value : data.financial_entities[index].financial_entity.id};
                data.financial_entities[index].financial_entity = financial_entity_new;
                delete data.financial_entities[index].service;
                delete data.financial_entities[index].modified;
                delete data.financial_entities[index].created;
                delete data.financial_entities[index].active;
            }

            data.service_category.value = data.service_category.id;
            delete data.service_category.id;
            data.service_category.label = data.service_category.name;
            delete data.service_category.name;
            for (let index = 0; index < data.commissions.length; index++) {
                let user_copy = {}
                user_copy.value = data.commissions[index].user.id;
                user_copy.label = data.commissions[index].user.first_name;
                delete data.commissions[index].user;
                data.commissions[index].user = user_copy;
            }
            for (let index = 0; index < data.categories_prices.length; index++) {
                // data.categories_prices[index].table_id = data.categories_prices[index].id;
                data.categories_prices[index].id = data.categories_prices[index].referent_category.id;
                data.categories_prices[index].name = data.categories_prices[index].referent_category.name;
                delete data.categories_prices[index].referent_category;
            }
            if(data.service_type == PRODUCT){
                data.service_type = PRODUCT_STR
            }else if(data.service_type == SERVICE){
                data.service_type = SERVICE_STR
            }else if(data.service_type == FRACTION){
                data.service_type = FRACTION_STR
            }


            dispatch({type: SET_UPDATE_DATA_SERVICE, updateData: data});
            dispatch(initializeForm('services', data))
        }
    })
}

const create = () => (dispatch, getStore) => {
    const tipo = _.cloneDeep(getStore().services.type);
    const formData = _.cloneDeep(getStore().form.services.values);

    console.log("FORM DATA SERVICE TYPE: ", formData.service_type);

    /*
    if(!formData.service_type){
        formData.service_type = PACKAGE
    } else if(formData.service_type == PRODUCT_STR){
        formData.service_type = PRODUCT
    } else if (formData.service_type == SERVICE_STR){
        formData.service_type = SERVICE
    } else if (formData.service_type == FRACTION_STR){
        formData.service_type = FRACTION
    }
    */

    if(tipo === 'package'){        
        formData.service_type = PACKAGE
    } else if(formData.service_type == PRODUCT_STR){        
        formData.service_type = PRODUCT
    } else if (formData.service_type == SERVICE_STR){        
        formData.service_type = SERVICE
    } else if (formData.service_type == FRACTION_STR){        
        formData.service_type = FRACTION
    }

    if(!formData.package_items){
        formData.package_items = []
    } else if (formData.package_items.length > 0) {
        formData.service_type = PACKAGE
    }
    if(!formData.commissions){
        formData.commissions = []
    } else {
        for (let index = 0; index < formData.commissions.length; index++) {
            formData.commissions[index].user = formData.commissions[index].user.value;
        }
    }
    if(!formData.financial_entities){
        formData.financial_entities = [];
    }else{
        for (let index = 0; index < formData.financial_entities.length; index++) {
            formData.financial_entities[index].financial_entity = formData.financial_entities[index].financial_entity.value;
        }
    }

    console.log("Form data: ", formData);

    dispatch({type: LOADER_SERVICE, cargando: true})
    api.post(`${url}`, formData).then((data) => {
        dispatch({type: LOADER_SERVICE, cargando: false})
        Swal('Éxito', 'Se ha creado el servicio.', 'success')
        .then(() => {
            //dispatch(push('/services'))
            dispatch(push('/services_conf'))
        })
    }).catch((error) => {
        dispatch({type: LOADER_SERVICE, cargando: false})
        dispatch({type: SET_SERVICE_TYPE, tipo});
        Swal(
            'Error',
             error.detail || 'Ha ocurrido un error, por favor vuelva a intentar.',
            'error'
        );
    }).finally(() => {
        dispatch({type: LOADER_SERVICE, cargando: false})
    });
};


const url_all = 'referent_categories/all';
const createCategories = () => (dispatch) => {
    api.get(`${url_all}`).catch((error) => {
        Swal(
            'Error',
            error.detail || 'Ha ocurrido un error, no se encontraron categorías de referente.',
            'error'
        );
    }).then((data) => {
        if(data){
            dispatch(initializeForm('services', {'categories_prices': data, 'service_type': SERVICE_STR}))
            dispatch({type: SET_CATEGORY_PRICES, categories: data});
        }
    })
}

const loadCategories = () => (dispatch, getStore) => {
    const { categories } = getStore().services;
    dispatch(initializeForm('services', {'categories_prices': categories, 'service_type': SERVICE_STR}))
}

const update = () => (dispatch, getStore) => {
    const formData = getStore().form.services.values;

    if(!formData.service_type){
        formData.service_type = PACKAGE
    } else if(formData.service_type == PRODUCT_STR){
        formData.service_type = PRODUCT
    } else if (formData.service_type == SERVICE_STR){
        formData.service_type = SERVICE
    } else if (formData.service_type == FRACTION_STR){
        formData.service_type = FRACTION
    }

    if(!formData.package_items){
        formData.package_items = []
    }
    if(formData.commissions.length){
        for (let index = 0; index < formData.commissions.length; index++) {
            formData.commissions[index].user = formData.commissions[index].user.value;
        }
    }

    if(formData.financial_entities.length > 0){
        for (let index = 0; index < formData.financial_entities.length; index++) {
            formData.financial_entities[index].financial_entity = formData.financial_entities[index].financial_entity.value;
        }
    }

    dispatch({type: LOADER_SERVICE, cargando: true});
    api.put(`${url}/${formData.id}`, formData)
    .then((data) => {
        dispatch({type: LOADER_SERVICE, cargando: false});
        if(data){
            Swal(
                'Éxito',
                'Datos editados correctamente.',
                'success'
            ).then(() => {
                //dispatch(push('/services'));
                dispatch(push('/services_conf'))
            })
        }
    })
    .catch((error) =>{
        dispatch({type: LOADER_SERVICE, cargando: false});
        Swal(
            'Error',
             error.detail || 'Ha ocurrido un error, verifique los datos.',
            'error'
        )
        if(error.statusCode === 404){
            dispatch(push('/services/create'));
        }
    })
}
const destroy = (id) => (dispatch, getStore) => {
    dispatch({type: LOADER_SERVICE, cargando: true});
    api.eliminar(`${url}/${id}`).catch((error) => {
        dispatch({type: LOADER_SERVICE, cargando: false});
        Swal('Error', 'No se ha logrado borrar el registro.', 'error')
            .then(() => {
                dispatch(listar(1));
        })
    }).then((data) => {
        dispatch({type: LOADER_SERVICE, cargando: false});
        Swal('Éxito', 'Se ha eliminado correctamente.', 'success')
            .then(() => {
                dispatch(listar(1));
            })
    })
}

const search = (search) => (dispatch, getStore) => {
    dispatch({type: SET_BUSCADOR_SERVICE, buscador: search});
    dispatch(listar(1));
};
const filtro = (filtro) => (dispatch, getStore) => {
    if (filtro == 0){
        filtro = null
    }
    dispatch({type: SET_FILTRO_SERVICE, filtro_services: filtro});
    dispatch(listar(1));
};

/** Ésta función no se está utilizando */
const setSearchService = () => (dispatch) => {
    dispatch({type: SET_SEARCH, buscador: ''});
}

const setServiceType = (value) => (dispatch) => {    
    dispatch({type: SET_SERVICE_TYPE, tipo:value});
}

export const actions = {
    listar,
    detail,
    create,
    createCategories,
    update,
    destroy,
    search,
    loadCategories,
    filtro,
    setSearchService,
    setServiceType,
};
export const reducer = {
    [SET_SEARCH]: (state, { buscador }) => {
        return {...state, buscador }
    },
    [SET_CATEGORY_PRICES]: (state, { categories }) => {
        return {...state, categories }
    },
    [LOADER_SERVICE]: (state, { cargando }) => {
        return {...state, cargando }
    },
    [SET_DATA_SERVICE]: (state, { data }) => {
        return {...state, data }
    },
    [SET_PAGE_SERVICE]: (state, { page }) => {
        return {...state, page }
    },
    [SET_UPDATE_DATA_SERVICE]: (state, { updateData }) => {
        return {...state, updateData }
    },
    [SET_BUSCADOR_SERVICE]: (state, { buscador }) => {
        return {...state, buscador }
    },
    [SET_FILTRO_SERVICE]: (state, { filtro_services }) => {
        return {...state, filtro_services }
    },
    [SET_SERVICE_TYPE]: (state, { tipo }) => {
        return {...state, type: tipo }
    },    
}
export const initialState = {
    cargando: false,
    page: 1,
    data: {
        count: 0,
        next: null,
        previous: null,
        results: [],
    },
    categories: [],
    buscador: '',
    filtro_services: null,
    updateData: {},
    type: 'service/product',
};
export default handleActions(reducer, initialState)
