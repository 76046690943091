import { connect } from 'react-redux';
import Banks from './Banks';
import { actions } from '../../../redux/modules/banks';
import {resetStore} from "../../../redux/modules/login";


const ms2p = (state) => {
    return {
        ...state.banks,
    };
};

const md2p = { ...actions, resetStore };

export default connect(ms2p, md2p)(Banks);
