const url_inventario = 'inventario';
const url_whearhouse = 'whearhouse';
const url = 'whearhouse-admin'
import { api } from 'api';
import Swal from 'sweetalert2';
import { push } from 'react-router-redux';
import { goBack } from 'react-router-redux';
import { initialize as initializeForm } from 'redux-form';
import { createActions, handleActions } from 'redux-actions';
import Service from '../../common/components/sales/Services';
import moment from "moment";
import _ from "lodash";
import PaymentForm from '../../common/components/purchase_order/payment/paymentForm';

const LOADER_ORDER = 'LOADER_ORDER';
const SET_DATA_ORDER = 'SET_DATA_ORDER';
const SET_PAGE_ORDER = 'SET_PAGE_ORDER';
const SET_UPDATE_DATA_ORDER = 'SET_UPDATE_DATA_ORDER';
const SET_BUSCADOR_ORDER = 'SET_BUSCADOR_ORDER';
const SET_FILTRO_ORDER = 'SET_FILTRO_ORDER';
const SET_BODEGA = 'SET_BODEGA';

const LOADER_SERVICE = 'LOADER_SERVICE';
const SET_DATA_SERVICE = 'SET_DATA_SERVICE';
const SET_PAGE_SERVICE = 'SET_PAGE_SERVICE';
const SET_UPDATE_DATA_SERVICE ='SET_UPDATE_DATA_SERVICE';
const SET_BUSCADOR_SERVICE = 'SET_BUSCADOR_SERVICE';
const SET_FILTRO_SERVICE = 'SET_FILTRO_SERVICE';

const ADD_PRODUCT_TO_LIST = 'ADD_PRODUCT_TO_LIST';
const TOTAL_CALCULATE = 'TOTAL_CALCULATE';
const REMOVE_SERVICE_TO_LIST = 'REMOVE_SERVICE_TO_LIST';
const UP_DOWN_CANT_ORDER = 'UP_DOWN_CANT_ORDER';
const SET_LOTE = 'SET_LOTE';
const SET_VENCIMIENTO = 'SET_VENCIMIENTO';
const VALIDATE_LIST = 'VALIDATE_LIST';
const SET_PRICE = 'SET_PRICE';

const SET_DATA_INVENTORY = 'SET_DATA_INVENTORY';
const SET_PAGE_INVENTORY = 'SET_PAGE_INVENTORY';
const SET_BUSCADOR_INVENTORY = 'SET_BUSCADOR_INVENTORY';
const SET_FILTRO_INVENTORY = 'SET_FILTRO_INVENTORY';

const SET_SERVICE_LIST = 'SET_SERVICE_LIST';

const SET_EXISTENCIA_REAL = 'SET_EXISTENCIA_REAL';
const SET_CANTIDAD_LOTE = 'SET_CANTIDAD_LOTE';
const SET_PRODUCTS_LIST_ORDER = 'SET_PRODUCTS_LIST_ORDER';
const UPDATE_PRODUCTS_LIST_ORDER = 'UPDATE_PRODUCTS_LIST_ORDER';

const CLEAN_DATA_ORDER = 'CLEAN_DATA_ORDER';
const UP_DOWN_CANT_ENTRY = 'UP_DOWN_CANT_ENTRY';
const SET_PROVIDER = 'SET_PROVIDER';

const listarOrdenesPendientes = (page = 1) =>  (dispatch, getStore) => {
    dispatch({type: LOADER_ORDER, cargando: true});
    const store = getStore();
    const search = store.order.buscador;
    const filtro = store.order.filtro_order;
    let params = {page, search};
    if(filtro){
        params[''] = filtro
    }

    api.get(`purchase_order/getEntryOrders`, params).catch((error) => {
        dispatch({type: LOADER_ORDER, cargando: false});
        Swal(
            'Error',
            error.detail || 'Ha ocurrido un error, por favor vuelva a intentar.',
            'error'
        );
    }).then((data) => {
        if(data){
            dispatch({type: SET_DATA_ORDER, data});
            dispatch({type: SET_PAGE_ORDER, page});
        }
        dispatch({type: LOADER_ORDER, cargando: false});
    })
};

const listar = (page = 1) =>  (dispatch, getStore) => {
    dispatch({type: LOADER_ORDER, cargando: true});
    const store = getStore();
    const search = store.order.buscador;
    const filtro = store.order.filtro_order;
    let params = {page, search};
    if(filtro){
        params[''] = filtro
    }

    api.get(`${url_order}`, params).catch((error) => {
        dispatch({type: LOADER_ORDER, cargando: false});
        Swal(
            'Error',
            error.detail || 'Ha ocurrido un error, por favor vuelva a intentar.',
            'error'
        );
    }).then((data) => {
        if(data){
            dispatch({type: SET_DATA_ORDER, data});
            dispatch({type: SET_PAGE_ORDER, page});
        }
        dispatch({type: LOADER_ORDER, cargando: false});
    })
};

const listarInventario = (page = 1) =>  (dispatch, getStore) => {
    dispatch({type: LOADER_ORDER, cargando: true});
    const store = getStore();
    const search = store.order.buscador;
    const filtro = store.order.filtro_order;

    let params = {page, search};
    if(filtro){
        params[''] = filtro
    }

    api.get(`${url_inventario}`, params).catch((error) => {
        dispatch({type: LOADER_ORDER, cargando: false});
        Swal(
            'Error',
            error.detail || 'Ha ocurrido un error, por favor vuelva a intentar.',
            'error'
        );
    }).then((data) => {
        if(data){
            dispatch({type: SET_DATA_ORDER, data});
            dispatch({type: SET_PAGE_ORDER, page});
        }
        dispatch({type: LOADER_ORDER, cargando: false});
    })
};

const getPurchaseOrder = (id_order, ingreso=false) => (dispatch, getState) =>{
    api.get(`purchase_order/${id_order}`).catch((error) => {
        console.log("error: ", error)
    }).then((data) => {
        if(data){
            console.log("Data en getPurchaseOrder: ", data);

            const formData = {
                    provider: {value: data.provider.id, label: data.provider.name},
                    spending_type: data.spending_type && {value: data.spending_type.id, label: data.spending_type.name},
                    date: moment(data.order_date).format("DD-MM-YYYY"),
                    note: data.comment
                }

            dispatch({type: SET_PROVIDER, provider:data.provider});
            dispatch(initializeForm('nuevaOrden', formData));

            let listaProductos = [];
            data.details.forEach((detalle) => {
                //const total = (ingreso) ? 0 : parseFloat(detalle.cost);

                //const total = (ingreso) ? 0 : (parseFloat(detalle.cost) * parseInt(detalle.quantity));
                const total = (ingreso) ? 0 : (parseFloat(detalle.service.cost) * parseInt(detalle.quantity));

                const producto = {
                    id: detalle.service.id,
                    name: detalle.service.name,
                    cantidad: detalle.quantity,
                    //precio: parseFloat(detalle.cost),
                    precio: parseFloat(detalle.service.cost),
                    total,

                    consignment: detalle.consignment,

                    //Para la recepción
                    cantidad_recibida: detalle.received_quantity,
                    cantidad_ingresa: 0,
                    nota: '',
                    lote: '',
                    vencimiento: '',
                    id_order_detail: detalle.id
                }
                listaProductos.push(producto);
            });

            const datos = {
                productsInList: listaProductos,
                id_purchase_order: id_order,
                order_state: data.state
            }
            dispatch({type: SET_PRODUCTS_LIST_ORDER, data: datos});
            dispatch({type: TOTAL_CALCULATE});
        }
    })
}

const listarServicios = (page = 1) =>  (dispatch, getStore) => {
    dispatch({type: LOADER_SERVICE, cargando: true});
    const store = getStore();
    const search = store.order.buscador_service;
    const filtro = store.order.filtro_service;
    let params = {page, search };

    if(filtro){
        params[''] = filtro
    }
    api.get(`services/getServiceWearhouse`, params).catch((error) => {
    //api.get(`services`, params).catch((error) => {
        dispatch({type: LOADER_SERVICE, cargando: false});
        Swal(
            'Error',
            error.detail || 'Ha ocurrido un error, por favor vuelva a intentar.',
            'error'
        );
    }).then((data) => {
        const search2 = getStore().order.buscador_service;
        if(search2 == search){
            if(data){
                console.log("Data recibida: ", data);
                dispatch({type: SET_DATA_SERVICE, data});
                dispatch({type: SET_PAGE_SERVICE, page});
            }
            dispatch({type: LOADER_SERVICE, cargando: false});
            dispatch({type: SET_UPDATE_DATA_SERVICE, updateData: {}});
        }
    })
};

const addServiceToListOrder = (service, cant=null) => (dispatch, getStore) => {
    console.log("Ejecutando Order.js -> addServiceToListOrder");
    console.log("Service: ||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||", service);
    const _cant = cant !== null ? cant : 1;

    const products_list = _.cloneDeep(getStore().order.productsInList);
    const product = products_list.find(item => item.id === service.id);
    const index = products_list.findIndex(item => item.id === service.id);

    if(product && (index !== -1)){
        products_list[index].cantidad += 1;
    }else{
        products_list.push({
            id: service.id,
            name: service.name,
            cantidad: _cant,
            precio: parseFloat(service.cost),
            total: parseFloat(service.cost),
            consignment: false,
        });
    }

    dispatch({type: UPDATE_PRODUCTS_LIST_ORDER, productsInList: products_list});
    dispatch({type: TOTAL_CALCULATE});

    /*

    console.log("AGREGAR SERVICIO A LA ORDEN");

    const producto = {
        id: service.id,
        name: service.name,
        cantidad: 1,
        precio: parseFloat(service.cost),
        total: parseFloat(service.cost),
    };

    dispatch({type: ADD_PRODUCT_TO_LIST, producto});
    dispatch({type: TOTAL_CALCULATE});
    */
}


const setServiceList = () => (dispatch) => {
    dispatch({type: SET_SERVICE_LIST});
}

const cancelOrder = (url_return=null) => (dispatch, getStore) => {
    Swal.fire({
        title: '¿Está seguro(a) que desea cancelar el ingreso? Perdera los datos del ingreso actual',
        type: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#aaa',
        confirmButtonText: 'Aceptar',
        cancelButtonText: 'Cancelar',
        reverseButtons: true
    }).then((result) => {
        if (result.value) {
            dispatch({type: SET_SERVICE_LIST});
            if(url_return === null){
                dispatch(push('/ordenes'));
            }else{
                dispatch(push(url_return));
            }
        }
    });
}

const removeServiceToList = (index) => (dispatch) => {
    console.log("Llamada a la función remove service to list, id: ", index);
    dispatch({type: REMOVE_SERVICE_TO_LIST, index});
    dispatch({type: TOTAL_CALCULATE});
}

const upDownCantOrder = (index, up_down) => (dispatch, getStore) => {
    const listado = _.cloneDeep(getStore().order.productsInList);

    let arreglo = [];
    listado.forEach((item, key) => {
        if(key === index){
            if(up_down){
                item.cantidad = item.cantidad + 1;
            }else{
                item.cantidad = (item.cantidad > 1) ? item.cantidad - 1 : 1 ;
            }
        }

        item.total = parseFloat(item.precio) * parseFloat(item.cantidad);
        arreglo.push(item);
    });

    dispatch({type:UPDATE_PRODUCTS_LIST_ORDER, productsInList: arreglo});
    dispatch({type:TOTAL_CALCULATE});
}

const setCantOrder = (index, cantidad) => (dispatch, getStore) => {
    const listado = _.cloneDeep(getStore().order.productsInList);

    let arreglo = [];
    listado.forEach((item, key) => {
        if(key === index){
            item.cantidad = cantidad;
        }
        item.total = parseFloat(item.precio) * parseFloat(item.cantidad);
        arreglo.push(item);
    });

    dispatch({type:UPDATE_PRODUCTS_LIST_ORDER, productsInList: arreglo});
    dispatch({type:TOTAL_CALCULATE});
}

const setCantEntry = (index, cantidad) => (dispatch, getStore) => {
    const listado = _.cloneDeep(getStore().order.productsInList);

    let arreglo = [];
    listado.forEach((item, key) => {

        if(key === index){
            item.cantidad_ingresa = cantidad ;
        }
        item.total = parseFloat(item.precio) * parseFloat(item.cantidad_ingresa);
        arreglo.push(item);
    });

    dispatch({type:UPDATE_PRODUCTS_LIST_ORDER, productsInList: arreglo});
    dispatch({type:TOTAL_CALCULATE});
}

const setConsignment = (index) => (dispatch, getStore) => {
    const listado = _.cloneDeep(getStore().order.productsInList);

    let arreglo = [];
    listado.forEach((item, key) => {
        if(key === index){
            item.consignment = !item.consignment;

        }
        arreglo.push(item);
    });

    dispatch({type:UPDATE_PRODUCTS_LIST_ORDER, productsInList: arreglo});
}

/** FUNCIÓN UTILIZADA EN LA RECEPCIÓN */
const upDownCantEntry = (index, up_down, min=null) => (dispatch, getStore) => {
    const _min = (min !== null) ? min : 1;
    const listado = _.cloneDeep(getStore().order.productsInList);

    let arreglo = [];
    listado.forEach((item, key) => {
        const limite = parseInt(item.cantidad) - parseInt(item.cantidad_recibida);

        if(key === index){
            if(up_down){
                if((item.cantidad_ingresa + 1) <= limite){
                    item.cantidad_ingresa = item.cantidad_ingresa + 1;
                }
            }else{
                item.cantidad_ingresa = (item.cantidad_ingresa > 1) ? item.cantidad_ingresa - 1 : _min ;
            }
        }

        item.total = parseFloat(item.precio) * parseFloat(item.cantidad_ingresa);
        arreglo.push(item);
    });

    dispatch({type:UPDATE_PRODUCTS_LIST_ORDER, productsInList: arreglo});
    dispatch({type:TOTAL_CALCULATE});
}

/** FUNCIÓN UTILIZADA EN RECEPCIÓN DE PRODUCTOS */
const setConsignmentAuthorization = (index) => (dispatch, getStore) => {
    const listado = _.cloneDeep(getStore().order.productsInList);

    let arreglo = [];
    listado.forEach((item, key) => {
        if(key === index){
            item.consignment = !item.consignment;

        }
        arreglo.push(item);
    });

    dispatch({type:UPDATE_PRODUCTS_LIST_ORDER, productsInList: arreglo});
}

/** FUNCIÓN UTILIZADA EN LA RECEPCIÓN */
const setNote = (index, note) => (dispatch, getStore) => {
    const listado = _.cloneDeep(getStore().order.productsInList);

    let arreglo = [];
    listado.forEach((item, key) => {
        if(key === index){
            item.nota = note;
        }

        item.total = parseFloat(item.precio) * parseFloat(item.cantidad_ingresa);
        arreglo.push(item);
    });

    dispatch({type:UPDATE_PRODUCTS_LIST_ORDER, productsInList: arreglo});
}

/** FUNCIÓN UTILIZADA EN LA RECEPCIÓN */
const finishEntry = () => (dispatch, getStore) => {
    const _store = getStore().order;
    const lista_de_productos = _store.productsInList;

    let lote_sin_fecha = false

    lista_de_productos.forEach(product => {
        if(product.cantidad_ingresa){
            /*
            if(product.lote !== "" && product.vencimiento === ""){
                lote_sin_fecha = true;
            }

            if(product.vencimiento !== "" && product.lote === ""){
                lote_sin_fecha = true;
            }
            */
        }
    });


    const formData = getStore().form.nuevaOrden.values;
    if(formData.bodega === undefined){
        Swal.fire(
            'Para hacer un ingreso debe seleccionar una bodega',
            'Por favor seleccione',
            'question'
        )
    }else if(lote_sin_fecha){
        Swal.fire(
            'El número de lote y fecha de vencimiento son datos complementarios, debe ingresar ambos',
            'Por favor verifique',
            'question'
        )
    }
    /*else if(formData.bill_number === undefined || formData.bill_number === null || formData.bill_number === ""){
        Swal.fire(
            'Debe ingresar un número de factura',
            'Por favor verifique',
            'question'
        )
    }*/
    else{
        Swal.fire({
            title: '¿Está seguro(a) que desea confirmar el ingreso? a la bodega ' + formData.bodega.label,
            type: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#aaa',
            confirmButtonText: 'Aceptar',
            cancelButtonText: 'Cancelar',
            reverseButtons: true
        }).then((result) => {
            if (result.value) {
                const store = getStore().order;
                const productsList = store.productsInList;
                let arreglo = [];
                productsList.forEach(product => {
                    if(product.cantidad_ingresa){
                        const producto = {
                            service: product.id,
                            entry_quantity: product.cantidad_ingresa,
                            note: product.nota,
                            lote: product.lote,
                            cost: product.precio,
                            expiration_date: product.vencimiento,
                            total: product.total,
                            consignment: product.consignment,
                            id_order_detail: product.id_order_detail
                        };
                        arreglo.push(producto);
                    }
                });

                const total = store.total_in_list;
                const id_purchase_order = store.id_purchase_order;

                const datos = {
                    warehouse: formData.bodega.value,
                    total: total.toFixed(2),
                    details: arreglo,
                    purchase: id_purchase_order,
                    bill_number: formData.bill_number,
                    fecha_fac: formData.fecha_fac,
                }

                console.log("Datos a enviar: ", datos);

                api.post(`entry_movement`, datos).then((data) => {
                    Swal('Éxito', 'La recepción se ha registrado correctamente', 'success')
                    .then(() => {
                        dispatch(push('/pending-orders'));
                    })
                }).catch((error) => {
                    Swal(
                        'Error',
                            error.detail || 'Ha ocurrido un error, por favor vuelva a intentar.',
                        'error'
                    );
                }).finally(() => {

                });
            }
        });
    }
}


const search = (search) => (dispatch) => {
    dispatch({type: SET_BUSCADOR_ORDER, buscador: search});
    dispatch(listar(1));
};

const filtro = (filtro) => (dispatch, getStore) => {
    if (filtro == 0){
        filtro = null
    }
    dispatch({type: SET_FILTRO_ORDER, filtro_order: filtro});
    dispatch(listar(1));
};

const filtroInventario = (filtro) => (dispatch, getStore) => {
    if (filtro == 0){
        filtro = null
    }
    dispatch({type: SET_FILTRO_INVENTORY, filtro_inventario: filtro});
    dispatch(chargeInventory(1));
};

const searchService = (search) => (dispatch) => {
    dispatch({type: SET_BUSCADOR_SERVICE, buscador_service: search});
    dispatch(listarServicios(1));
};

const filtroService = (filtro) => (dispatch, getStore) => {
    if (filtro == 0){
        filtro = null
    }
    dispatch({type: SET_FILTRO_SERVICE, filtro_service: filtro});
    dispatch(listarServicios(1));
};

const searchInventario = (search) => (dispatch) => {
    dispatch({type: SET_BUSCADOR_INVENTORY, buscador_inventario: search});
    dispatch(chargeInventory(1));
};

const updateOrder = (url_retorno=null, authorization=true, cambia_estado=true) => (dispatch, getStore) => {
    if(typeof url_retorno === "object"){
        url_retorno = null;
    }

    const formData = getStore().form.nuevaOrden.values;
    const comment = formData.note !== undefined ? formData.note : '';
    const store = getStore().order;
    console.log("Total antes de aproximar: ", store.total_in_list);
    let total = store.total_in_list.toFixed(2);
    const productsList = store.productsInList;
    const id_purchase_order = store.id_purchase_order;

    let arreglo = [];
    productsList.forEach(product => {
        const producto = {
            service: product.id,
            quantity: product.cantidad,
            consignment: product.consignment,
            cost: product.precio,
        };
        arreglo.push(producto);
    });

    let paymentForm = getStore().form.form_payment;
    let total_payment = 0;
    if(paymentForm !== undefined && paymentForm !== null){
        paymentForm = paymentForm.values;
        url_retorno = paymentForm.url_retorno;
        authorization = true

        if(paymentForm.cash !== undefined){
            total_payment += parseFloat(paymentForm.cash);
        }
        if(paymentForm.card !== undefined){
            total_payment += parseFloat(paymentForm.card);
        }
        if(paymentForm.due !== undefined){
            total_payment += parseFloat(paymentForm.due);
        }
        if(paymentForm.transaction !== undefined){
            total_payment += parseFloat(paymentForm.transaction);
            paymentForm.bank_account = paymentForm.bank_account.value
        }

        //add
        const descuento = paymentForm.discount ? paymentForm.discount : 0;
        const retention_amount = (paymentForm.retention_amount) ? paymentForm.retention_amount : 0;
        const retention_amount_iva = (paymentForm.retention_amount_iva) ? paymentForm.retention_amount_iva : 0;

        console.log("Retention: ", retention_amount);
        console.log("Retention IVA: ", retention_amount_iva);
        console.log("Descuento: ", descuento);

        total -= descuento;
        total -= retention_amount;
        total -= retention_amount_iva;

        console.log("TOTAL_PAYMENT: ", total_payment);
        //add...

        paymentForm.total_payment = total_payment;
    }else{
        paymentForm = null;
    }

    console.log("Total payment: ", total_payment, "total: ", total);

    console.log("CONTENIDO DEL url_retorno: ", url_retorno);

    console.log("paymentForm: ", paymentForm);

    if (paymentForm != null && total_payment !== total){
        Swal.fire(
            'Debe pagar el total de la compra',
            'Corrija',
            'question'
        )
    }else{
        const data = {
                    id_purchase_order,
                    provider: formData.provider.value,
                    spending_type: formData.spending_type.value,
                    date : formData.date,
                    comment,
                    details: arreglo,

                    total,
                    payment: paymentForm,

                    authorization,
                    cambia_estado, //add...
                }

        api.put(`purchase_order/updateOrder`, data).then((data) => {
            Swal('Éxito', 'Se ha actualizado la orden de compra.', 'success')
            .then(() => {
                dispatch(setServiceList());
                dispatch({type: SET_SERVICE_LIST});
                if(url_retorno === null){
                    console.log("Redirección realizada correctamente");
                    dispatch(push('/ordenes'));
                }else{
                    dispatch(push(url_retorno));
                }
            })
        }).catch((error) => {
            Swal(
                'Error',
                    error.detail || 'Ha ocurrido un error, por favor vuelva a intentar.',
                'error'
            );
        }).finally(() => {

        });
    }
}

const sentOrder = () => (dispatch, getStore) => {
    dispatch({type: LOADER_ORDER, cargando: true});
    const formData = getStore().form.nuevaOrden.values;
    console.log("formData in Sent Order: ", formData);
    const comment = formData.note !== undefined ? formData.note : '';
    let arreglo = [];
    const productsList = getStore().order.productsInList;
    productsList.forEach(product => {
        const producto = {
            service: product.id,
            quantity: product.cantidad,
            consignment: product.consignment,
        };
        arreglo.push(producto);
    });

    const data = {
                //bodega,
                provider: formData.provider.value,
                spending_type: formData.spending_type.value,
                date : formData.date,
                comment,
                details: arreglo,
            }

    console.log("Data ---> ", data);
    api.post(`purchase_order`, data).then((data) => {
        Swal('Éxito', 'Se ha enviado la orden de compra.', 'success')
        .then(() => {
            dispatch(setServiceList());
            dispatch(push('/ordenes'))
        })
    }).catch((error) => {
        Swal(
            'Error',
             error.detail || 'Ha ocurrido un error, por favor vuelva a intentar.',
            'error'
        );
    }).finally(() => {

    });
}

const chargeInventory = (page = 1) => (dispatch, getStore) => {
    const store = getStore().order;
    const bodega = store.bodega.id;
    const search = store.buscador_inventario;
    const filtro = store.filtro_inventario;

    let params = {page, search, bodega};

    if(filtro){
        params[''] = filtro
    }

    console.log("PARAMS: ", params);

    api.get("inventario/loadInventory", params).catch((error) => {
        Swal(
            'Error',
            error.detail || 'Ha ocurrido un error, por favor vuelva a intentar.',
            'error'
        );
    }).then((data) => {
        console.log("data charge inventory: ", data);
        dispatch({type: SET_DATA_INVENTORY, data});
        dispatch({type: SET_PAGE_INVENTORY, page_inventario: page});
    })
}

const initializeOrderForm = () => (dispatch) => {
    console.log("Inicializando formulario");
    dispatch(initializeForm('nuevaOrden', {'date': moment(new Date()).format("DD-MM-YYYY")}))
}

const setPrice = (index, value, ingreso=false) => (dispatch, getStore) => {
    const listaProductos = _.cloneDeep(getStore().order.productsInList);

    const arreglo = listaProductos.filter((item, key) => {
        if(key === index){
            item.precio = parseFloat(value);
            if(ingreso){
                item.total = parseFloat(item.precio) * parseFloat(item.cantidad_ingresa);
            }else{
                item.total = parseFloat(item.precio) * parseFloat(item.cantidad);
            }
        }
        return item;
    });

    dispatch({type: UPDATE_PRODUCTS_LIST_ORDER, productsInList:arreglo});
    dispatch({type: TOTAL_CALCULATE});
}

const purchaseAuthorization = (authorization) => (dispatch, getStore) => {
    const store = getStore().order;
    const id_purchase = store.id_purchase_order;

    const data = {
        id_purchase,
        authorization,
    }

    api.post("purchase_order/purchaseAuthorization", data).catch((error) => {
        Swal(
            'Error',
            error.detail || 'Ha ocurrido un error, por favor vuelva a intentar.',
            'error'
        );
    }).then((data) => {
        const mensaje = (authorization) ? 'Se ha autorizado la orden.' : 'Se ha rechazado la orden.';
        Swal('Éxito', mensaje, 'success')
        .then(() => {
            dispatch(push('/orders-adm'))
        });
    })
}

const setLote = (index, value) => (dispatch, getStore) => {
    let existe = false;
    const products = _.cloneDeep(getStore().order.productsInList);

    products.forEach(product => {
        if(product.lote === value){
            existe = true;
        }
    });

    if(value !== '' && existe){
        Swal.fire(
            'El lote ya está asignado',
            'Por favor verifique',
            'question'
        )
    }else{
        let arreglo = [];
        products.forEach((item, key) => {
            if(key === index){
                item.lote = value;
            }

            arreglo.push(item);
        });

        dispatch({type:UPDATE_PRODUCTS_LIST_ORDER, productsInList: arreglo});
    }
}

const setVencimiento = (index, value) => (dispatch, getStore) => {
    const products = _.cloneDeep(getStore().order.productsInList);

    let arreglo = [];
    products.forEach((item, key) => {
        if(key === index){
            item.vencimiento = value;
        }

        arreglo.push(item);
    });

    dispatch({type:UPDATE_PRODUCTS_LIST_ORDER, productsInList: arreglo});
}

const publicData = () => (dispatch) => {
    dispatch({type:CLEAN_DATA_ORDER});
}

const setAllEntry = (all_entry) => (dispatch, getStore) => {
    const listado = _.cloneDeep(getStore().order.productsInList);
    let arreglo = [];

    if(all_entry){
        listado.forEach((item) => {
            const cantidad_falta = parseFloat(item.cantidad) - parseFloat(item.cantidad_recibida);
            item.cantidad_ingresa = cantidad_falta;
            item.total = parseFloat(item.precio) * parseFloat(item.cantidad_ingresa);
            arreglo.push(item);
        });
    }else{
        listado.forEach((item) => {
            item.cantidad_ingresa = 0;
            item.total = parseFloat(item.precio) * parseFloat(item.cantidad_ingresa);
            arreglo.push(item);
        });
    }
    dispatch({type:UPDATE_PRODUCTS_LIST_ORDER, productsInList: arreglo});
    dispatch({type:TOTAL_CALCULATE});
}

export const actions = {
    setAllEntry,
    finishEntry,
    setNote,
    setLote,
    setVencimiento,
    purchaseAuthorization,
    setPrice,
    updateOrder,
    getPurchaseOrder,
    listarInventario,
    listar,
    search,
    filtro,
    listarServicios,
    searchService,
    filtroService,
    removeServiceToList,
    upDownCantOrder,
    upDownCantEntry,
    setCantOrder,
    setCantEntry,
    sentOrder,
    searchInventario,
    filtroInventario,
    chargeInventory,
    cancelOrder,
    addServiceToListOrder,
    initializeOrderForm,
    listarOrdenesPendientes,
    publicData,
    setConsignment,
    setConsignmentAuthorization,
};

export const reducer = {
    [SET_PROVIDER]: (state, {provider}) =>{
        return{
            ... state,
            provider
        }
    },
    [UPDATE_PRODUCTS_LIST_ORDER]: (state, {productsInList}) =>{
        return{
            ... state,
            productsInList
        }
    },
    [SET_PRODUCTS_LIST_ORDER]: (state, {data}) => {
        return {
            ... state,
            productsInList: data.productsInList,
            id_purchase_order: data.id_purchase_order,
            order_state: data.order_state,
        }
    },
    [SET_CANTIDAD_LOTE]: (state, {productsInList}) => {
        return {
            ...state,
            productsInList,
        }
    },
    [SET_SERVICE_LIST]: (state) => {
        return {
            ...state,
            productsInList: [],
            total_in_list: 0,
        }
    },
    [CLEAN_DATA_ORDER]: (state) => {
        return{
            ...state,
            productsInList: [],
            total_in_list: 0,
            id_purchase_order: 0,
        }
    },
    [LOADER_ORDER]: (state, { cargando }) => {
        return {...state, cargando }
    },
    [SET_DATA_ORDER]: (state, { data }) => {
        return {...state, data }
    },
    [SET_PAGE_ORDER]: (state, { page }) => {
        return {...state, page }
    },
    [SET_PAGE_INVENTORY]: (state, { page_inventario }) => {
        return {...state, page_inventario }
    },
    [SET_BUSCADOR_ORDER]: (state, { buscador }) => {
        return {...state, buscador }
    },
    [SET_BUSCADOR_INVENTORY]: (state, { buscador_inventario }) => {
        return {...state, buscador_inventario }
    },
    [SET_FILTRO_ORDER]: (state, { filtro_order }) => {
        return {...state, filtro_order }
    },
    [SET_BODEGA]: (state, { data }) => {
        return {...state, bodega: data}
    },

    [LOADER_SERVICE]: (state, { cargando }) => {
        return {...state, cargando_service: cargando }
    },
    [SET_DATA_SERVICE]: (state, { data }) => {
        return {...state, data_service : data }
    },
    [SET_PAGE_SERVICE]: (state, { page }) => {
        return {...state, page_service: page }
    },
    [SET_UPDATE_DATA_SERVICE]: (state, { updateData }) => {
        return {...state, update_data_service: updateData }
    },
    [SET_BUSCADOR_SERVICE]: (state, { buscador_service }) => {
        return {...state, buscador_service }
    },
    [SET_FILTRO_SERVICE]: (state, { filtro_services }) => {
        return {...state, filtro_services }
    },
    [SET_FILTRO_INVENTORY]: (state, { filtro_inventario }) => {
        return {...state, filtro_inventario }
    },
    [ADD_PRODUCT_TO_LIST]: (state, { producto }) => {

        return{
            ...state,
            productsInList: [...state.productsInList, producto]
        }
    },
    [REMOVE_SERVICE_TO_LIST]: (state, { index }) => {
        let arreglo = state.productsInList.filter((item, key) => {
             return index !== key;
         });

         return{
             ...state,
             productsInList: arreglo
         }
     },
     [UP_DOWN_CANT_ORDER]: (state, { data }) => {
         console.log("data en UP_DOWN_CANT_ORDER: ", data);
        const arreglo = state.productsInList.filter((item, key) => {
            if(key === data.index){
                if(data.up_down){
                    item.cantidad    = item.cantidad + 1;
                }else{
                    item.cantidad = (item.cantidad > 1) ? item.cantidad - 1 : 1 ;
                }

                item.total = parseFloat(item.precio) * parseFloat(item.cantidad);
            }
            return item;
        });

        return{
            ...state,
            productsInList: arreglo
        }
    },
    [SET_VENCIMIENTO]: (state, { data }) => {
        let validate_list = true;
        const arreglo = state.productsInList.filter((item) => {
            if(item.id === data.id){
                if(data.value === '' || data.value === undefined){
                    console.log("Error detectado")
                    item.vencimiento_error = true;
                    validate_list = false;
                }else{
                    item.vencimiento = data.value;
                }
            }
            return item;
        });

        return{
            ...state,
            productsInList: arreglo,
            validate_list,
        }
    },
    [TOTAL_CALCULATE]: (state) => {
        let acum = 0;
        state.productsInList.forEach(item => {
            acum += parseFloat(item.total);
        });

        return{
            ...state,
            total_in_list: acum,
        }
    },
    [VALIDATE_LIST]: (state) => {
        console.log("validando datos");
        let validate_list = true;
        const arreglo = state.productsInList.filter((item) => {
            item.precio_error = false;
            if(item.precio === '' || item.precio == undefined ){
                item.precio_error = true;
                validate_list = false;
            }

            return item;
        });

        return{
            ...state,
            productsInList: arreglo,
            validacion: validate_list,
        }
    },
    [SET_DATA_INVENTORY]: (state, { data }) => {
        return {...state, data_inventario : data }
    },

    //Esto es de la parte del reajuste en bodega...
    [SET_EXISTENCIA_REAL]: (state, { data }) => {
        const arreglo = state.productsInList.filter((item, key) => {
            console.log("item en SET_EXISTENCIA_REAL: ", item);
            if(key === data.index){
                if(data.value === ''){
                    item.existencia_real = undefined;
                }else{
                    item.existencia_real = parseInt(data.value);
                }
            }

            return item;
        });

        return{
            ...state,
            productsInList: arreglo
        }
    },

};

export const initialState = {
    cargando: false,
    page: 1,
    data: {
        count: 0,
        next: null,
        previous: null,
        results: [],
    },

    page_service: 1,
    cargando_service: false,
    data_service: {
        count: 0,
        next: null,
        previous: null,
        results: [],
    },

    buscador_service: '',
    filtro_service: null,

    buscador: '',
    filtro_order: null,
    updateData: {},
    update_data_service: {},
    id_purchase_order: 0,
    order_state: 0,
    productsInList: [],
    validacion: true,
    total_in_list: 0,
    data_inventario: {
        count: 0,
        next: null,
        previous: null,
        results: [],
    },
    buscador_inventario: '',
    page_inventario: 1,
    cargando_inventario: false,
    provider: null,
};

export default handleActions(reducer, initialState)
