import React, { Component } from 'react';
import '../payment.css';
import SalesTab from '../payment_methods/Sale';
import Factura from '../Factura';
import Spoiler from '../../Utils/Spoiler';
import Tabs from './TabComponent';
import { Link } from 'react-router-dom';
import MyModal from '../../Utils/myModal';
import BillingForm from '../payment_methods/FormBillingInformation';
//import { actions as printActions } from "../Utils/Print";
import { PrintContainer, actions as printActions } from "Utils/Print";
import LoadMask from "../../../components/Utils/LoadMask/LoadMaskBill";
class Deposit extends Component {
    componentWillUnmount() {
        this.props.resetStore();
        this.props.setDefaultValueExtraAmount();
    }

    state = {
        tab_active: "venta",
        total_general: 0,
        uuid: null
    }

    componentWillMount = () =>{
        //agregado
        let acum=0;
        this.props.productsInCarOrdered.forEach(element => {
            acum += element.calculate.resto;
        });
        this.setState({ total_general: acum });
        //agregado...

        if(this.props.patientInfo.referent === null){
            this.props.noDataPatient();
        }
     }

    changeTab(tabName){
        this.setState({tab_active: tabName});
    }

    forceRendering = () => {
        this.forceUpdate();
    }

    addDeposit = () => {
        //console.log("Sí se está agregando el depósito");
        this.props.addDeposit();
        /*
        setTimeout(() => {
            this.forceUpdate();
        }, 500);
        */
    }

    render() {

        let tventa = (this.state.tab_active === "venta") ? "tp-active" : "tp-inactive";
        let tcredito = (this.state.tab_active === "credito") ? "tp-active" : "tp-inactive";
        let firstactive = (this.state.tab_active === "venta") ? "first-active" : "";


        const {
            patientInfo,
            productsInCarOrdered,
        } = this.props;

        const {
            destroy,
            listar,
            bill_mask,
            billing_email
        } = this.props;

        let acum=0;
        this.props.productsInCarOrdered.forEach(element => {
            if (element.dataPayments.type === 'venta'){
                acum += element.calculate.resto;
            }
        });

        console.log("Acumulado es: ", acum);
        console.log("RENDERIZANDO EL COMPONENTE Deposit.js -------------------------------------");
        let loading = true;
        return (
            <div className="content-with-tabs">
                <LoadMask loading={bill_mask} dark blur>
                <div className="d-inline-flex w-100">
                    <div className="row-fluid w-50 tab-background-blue">
                        <a href="/#/oppening-closing" className="d-flex justify-content-center align-items-center tab-active tab-br-tr">
                            VENTA
                        </a>
                    </div>
                    <div className="row-fluid w-50 tab-background">
                        <a href="/#/oppening-closing/box" className="d-flex justify-content-center align-items-center tab tab-br-bl">
                            CAJA
                        </a>
                    </div>
                </div>

                <div className="table-decoration w-50 h-100 mx-5 mt-3 mb-3 d-flex flex-column sales-services  no-print" id="left-tab">
                    <div className="fields">
                        <div className="top-title" id="payment-title">
                            DEPOSITOS REALIZADOS A LA CUENTA
                        </div>

                        <div className="m-auto row-fluid w-100 h-100">
                            <div className="table-decoration rcp-table-decoration">

                                <div className="table-container d-flex justify-content-center">
                                    <table className="table table-sm w-75" id="table-patient">
                                        <tbody>
                                            <tr>
                                                <th>
                                                    Nombre:
                                                </th>
                                                <td>
                                                    {patientInfo.name}
                                                </td>
                                                <th>
                                                    Nit:
                                                </th>
                                                <td>
                                                    {patientInfo.nit}
                                                </td>
                                            </tr>
                                            <tr>
                                                <th>
                                                    Referido:
                                                </th>
                                                <td>
                                                   {/* {patientInfo.referent !== null ? patientInfo.referent.label : ''} */}
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="content-method-payments">
                        <React.Fragment>
                        {productsInCarOrdered.map((factura, i) =>
                            <Spoiler show={(i === 0) ? true : false } label={factura.financial_entity.name} key={i}>
                                <Tabs
                                    index={i}
                                    { ...this.props }
                                    factura={factura}
                                    chargePayments={this.props.chargePayments}
                                    forceRendering={this.forceRendering}
                                />
                            </Spoiler>

                        )}
                        <div className="d-flex flex-row justify-content-center align-items-center mb-3">
                            <Link className="btn btn-secondary btn-sm m-2" to="/oppening-closing/sales/">Cancelar</Link>
                            <button type="submit" className="btn btn-primary btn-sm m-2" onClick={() => this.addDeposit()}>Guardar depósito</button>
                        </div>
                        </React.Fragment>
                    </div>
                </div>

                <div className="table-decoration w-25 h-100 mx-5 mt-3 mb-3 d-flex flex-column sales-services no-print" id="right-tab">
                    <div className="d-flex justify-content-around align-items-center title-print-view">
                            <div><span className="font-weight-bold" >PRE VISUALIZACIÓN</span></div>
                            {/*
                            <div>
                                <button type="button" className="btn btn-sm btn-success"  onClick={(e) => {
                                    e.preventDefault();
                                    printActions.print('facturas');
                                }}>Imprimir</button>
                            </div>
                            */}
                    </div>

                    <div className="d-flex flex-column p-5 fac-content">
                        <PrintContainer name='facturas' className="impresion-venta">
                            {productsInCarOrdered.map((factura, i) =>
                                <Factura
                                    key={i}
                                    factura={factura}
                                />
                            )}
                        </PrintContainer>
                    </div>
                </div>
                </LoadMask>
            </div>
        )
    }
}

export default Deposit;
