import Swal from 'sweetalert2';
import { api } from 'api';
import { handleActions } from 'redux-actions'
import { initialize as initializeForm } from 'redux-form'
import { push } from 'react-router-redux';
import moment from "moment";
const url = 'surgery';
import { departamentos, municipios } from 'variables';

/* Para los servicios del modal */
const SET_BUSCADOR_SERVICE = 'SET_BUSCADOR_SERVICE';
const LOADER_SERVICE = 'LOADER_SERVICE';
const SET_DATA_SERVICE = 'SET_DATA_SERVICE';
const SET_PAGE_SERVICE = 'SET_PAGE_SERVICE';
const SET_MODAL_SERVICE = 'SET_MODAL_SERVICE';
const SET_SERVICES_LIST = 'SET_SERVICES_LIST';
/* ... */

/* Para el proceso de cirugía */
const SET_SERVICES_PERFORM_SURGERY = 'SET_SERVICES_PERFORM_SURGERY';
/* ... */

const LOADER_SURGERY = 'LOADER_SURGERY';
const SET_DATA_SURGERY= 'SET_DATA_SURGERY';
const SET_PAGE_SURGERY = 'SET_PAGE_SURGERY';
const SET_UPDATE_DATA_SURGERY ='SET_UPDATE_DATA_SURGERY';
const SET_BUSCADOR_SURGERY = 'SET_BUSCADOR_SURGERY';
const SET_RESULT_SURGERY = 'SET_RESULT_SURGERY';
const SET_FILTRO_SURGERY = 'SET_FILTRO_SURGERY';
const SET_INITIALIZE_FORM_DATA = 'SET_INITIALIZE_FORM_DATA';
const SET_APPOINTMENTS_SURGERY = 'SET_APPOINTMENTS_SURGERY';
const SET_OVERLAPS_SURGERY = 'SET_OVERLAPS_SURGERY';
const SET_DOCTORS = 'SET_DOCTORS';
const SET_SURGERY_INFO = 'SET_SURGERY_INFO';
const MALE = 1
const FEMALE = 2
const MALE_STR = 'Masculino'
const FEMALE_STR = 'Femenino'
const DOCTOR = 0
const SET_OBSERVATIONS = 'SET_OBSERVATIONS';
const SET_CHECK_CASHBOX_IN_SURGERY = 'SET_CHECK_CASHBOX_IN_SURGERY';
const GROUP_BILL = 'GROUP_BILL';
const CLEAN_DATA_SURGERY = 'CLEAN_DATA_SURGERY';

const SET_REPORT_SURGERY = 'SET_REPORT_SURGERY';

const SET_SURGERY_TOOLTIP_INFO = 'SET_SURGERY_TOOLTIP_INFO';
const SET_SURGERY_SHOW_TOOLTIP_MODAL = 'SET_SURGERY_SHOW_TOOLTIP_MODAL';

const SET_END_SURGERY_LOADER = 'SET_END_SURGERY_LOADER';

const hours = [
    {value:0.0, label:'0:00 AM', hrs:'00:00'}, {value:0.5, label:'0:30 AM', hrs:'00:30'},
    {value:1.0, label:'1:00 AM', hrs:'01:00'}, {value:1.5, label:'1:30 AM', hrs:'01:30'},
    {value:2.0, label:'2:00 AM', hrs:'02:00'}, {value:2.5, label:'2:30 AM', hrs:'02:30'},
    {value:3.0, label:'3:00 AM', hrs:'03:00'}, {value:3.5, label:'3:30 AM', hrs:'03:30'},
    {value:4.0, label:'4:00 AM', hrs:'04:00'}, {value:4.5, label:'4:30 AM', hrs:'04:30'},
    {value:5.0, label:'5:00 AM', hrs:'05:00'}, {value:5.5, label:'5:30 AM', hrs:'05:30'},
    {value:6.0, label:'6:00 AM', hrs:'06:00'}, {value:6.5, label:'6:30 AM', hrs:'06:30'},
    {value:7.0, label:'7:00 AM', hrs:'07:00'}, {value:7.5, label:'7:30 AM', hrs:'07:30'},
    {value:8.0, label:'8:00 AM', hrs:'08:00'}, {value:8.5, label:'8:30 AM', hrs:'08:30'},
    {value:9.0, label:'9:00 AM', hrs:'09:00'}, {value:9.5, label:'9:30 AM', hrs:'09:30'},

    {value:10.0, label:'10:00 AM', hrs:'10:00'}, {value:10.5, label:'10:30 AM', hrs:'10:30'},
    {value:11.0, label:'11:00 AM', hrs:'11:00'}, {value:11.5, label:'11:30 AM', hrs:'11:30'},
    {value:12.0, label:'12:00 PM', hrs:'12:00'}, {value:12.5, label:'12:30 PM', hrs:'12:30'},
    {value:13.0, label:'1:00 PM', hrs:'13:00'}, {value:13.5, label:'1:30 PM', hrs:'13:30'},
    {value:14.0, label:'2:00 PM', hrs:'14:00'}, {value:14.5, label:'2:30 PM', hrs:'14:30'},
    {value:15.0, label:'3:00 PM', hrs:'15:00'}, {value:15.5, label:'3:30 PM', hrs:'15:30'},
    {value:16.0, label:'4:00 PM', hrs:'16:00'}, {value:16.5, label:'4:30 PM', hrs:'16:30'},
    {value:17.0, label:'5:00 PM', hrs:'17:00'}, {value:17.5, label:'5:30 PM', hrs:'17:30'},
    {value:18.0, label:'6:00 PM', hrs:'18:00'}, {value:18.5, label:'6:30 PM', hrs:'18:30'},
    {value:19.0, label:'7:00 PM', hrs:'19:00'}, {value:19.5, label:'7:30 PM', hrs:'19:30'},
    {value:20.0, label:'8:00 PM', hrs:'20:00'}, {value:20.5, label:'8:30 PM', hrs:'20:30'},
    {value:21.0, label:'9:00 PM', hrs:'21:00'}, {value:21.5, label:'9:30 PM', hrs:':2130'},
    {value:22.0, label:'10:00 PM', hrs:'22:00'}, {value:22.5, label:'10:30 PM', hrs:'22:30'},
    {value:23.0, label:'11:00 PM', hrs:'23:00'}, {value:23.5, label:'11:30 PM', hrs:'23:30'},
]

const groupBy = (xs, f) => {
    return xs.reduce((r, v, i, a, k = f(v)) => ((r[k] || (r[k] = [])).push(v), r), {});
}

const getDoctors = () => (dispatch, getStore) => {
    let persons = []
    api.get("referents/listAllDoctors").catch((error) => {})
        .then((data) => {
            if (data){
                persons = [];
                data.results.forEach( x => { persons.push(x) })
                dispatch({type: SET_DOCTORS, doctors: persons});
            }
        })
        .catch(e => {

        })
}

export const setUpdateData = updateData => ({
    type: SET_UPDATE_DATA_SURGERY,
    updateData,
});


const listar = (initial_date, final_date, doctor_id='', exclude_locks=0) =>  (dispatch, getStore) => {
    dispatch({type: LOADER_SURGERY, cargando: true});

    const current_user = getStore().login.me;
    const subsidiary = current_user.person_user.subsidiary;
    // const user = current_user.id;

    let params = {initial_date, final_date, doctor_id, exclude_locks, subsidiary};

    api.get('surgery/all', params).catch((error) => {
        dispatch({type: LOADER_SURGERY, cargando: false});
        Swal(
            'Error',
            error.detail || 'Ha ocurrido un error, por favor vuelva a intentar.',
            'error'
        );
    }).then((data) => {
        if(data){
            dispatch({type: SET_DATA_SURGERY, data});
            let day = moment(initial_date)
            let gap = 0
            let overlap_level = 1
            let first_start_value = 0
            let last_end_value = 0

            let overlaps = []
            let columns = []
            let all_appointments = []

            for (let index = 0; index < 7; index++) {
                overlaps.push(new Array(48).fill(null))
                let register = data.filter(function (el) {
                    return el.date == day.format('YYYY-MM-DD');
                }).sort((item, item_2) => {
                    if (item.start_value - item_2.start_value === 0)
                        return (
                            (item_2.end_value - item_2.start_value) - (item.end_value - item.start_value)
                        )
                    return item.start_value - item_2.start_value
                })

                all_appointments.push(register)

                for (let index_2 = 1; index_2 < register.length; index_2++) {
                    if(index_2 - 1 == 0){
                        gap = 0
                        overlap_level = 1
                        first_start_value = 0
                        last_end_value = 0
                    }
                    if (parseInt(register[index_2].start_value * 2) < parseInt(register[index_2 - 1].end_value * 2) || parseInt(register[index_2].start_value * 2) < parseInt(last_end_value)) {
                        overlap_level = overlap_level + 1
                        if (gap == 0) {
                            gap = 1
                            let overlap_values = new Array(3)
                            first_start_value = register[index_2 - 1].start_value * 2
                            if (parseInt(register[index_2].end_value * 2) > parseInt(register[index_2 - 1].end_value * 2)) {
                                last_end_value = parseInt(register[index_2].end_value * 2)
                            } else {
                                last_end_value = parseInt(register[index_2 - 1].end_value * 2)
                            }
                            overlap_values[0] = first_start_value
                            overlap_values[1] = last_end_value
                            overlap_values[2] = overlap_level
                            overlaps[index][first_start_value] = overlap_values
                            // if(index== 1){
                            //     console.log("VALORES: ", overlap_values)
                            // }
                        } else {
                            if (parseInt(register[index_2].end_value * 2) > last_end_value) {
                                last_end_value = parseInt(register[index_2].end_value * 2)
                                overlaps[index][first_start_value][1] = last_end_value
                            }
                            overlaps[index][first_start_value][2] = overlap_level
                        }
                    } else {
                        gap = 0
                        overlap_level = 1
                        first_start_value = 0
                        last_end_value = 0
                    }

                }
                day = moment(day, "DD-MM-YYYY").add(1, 'days')
            }

            console.log("ALL APPOINTMENTS", all_appointments)

            for (let index = 0; index < 7; index++) {
                if(all_appointments[index].length){
                    let column = new Array(48).fill(null).map(() => { return [0] })

                    // columns.push(Array(48).fill(null).map(() => {
                    //     return [null]
                    // }))
                    for (let index_2 = 0; index_2 < overlaps[index].length; index_2++) {
                        if(overlaps[index][index_2] != null){
                            // console.log("OVERLAP OBJECT: ", overlaps[index][index_2][2])
                            for (let index_3 = overlaps[index][index_2][0]; index_3 < overlaps[index][index_2][1]; index_3++) {
                                column[index_3] = new Array(overlaps[index][index_2][2]).fill(0)
                                // column[index_3] = new Array(overlaps[index][index_2][2]).fill(null)
                                // column[index_3][0] = 0
                            }
                        }
                    }
                    columns.push(column)

                } else (
                    columns.push(new Array(48).fill([null]))
                )
            }

            // HASTA AQUÍ YA ESTÁ EL ESPACIADO DE LAS MATRICES SOLO HACE FALTA COLOCAR LOS DATOS PARA QUE SE RENDERIZEN CORRECTAMENTE

            let horizantal_deep = 0;
            let sticker_id = 0;
            for (let index = 0; index < all_appointments.length; index++) {
                for (let index_2 = 0; index_2 < all_appointments[index].length; index_2++) {
                    horizantal_deep = 0;
                    for (let horizontal_position = 0; horizontal_position < columns[index][all_appointments[index][index_2].start_value * 2].length; horizontal_position++) {
                        if(columns[index][all_appointments[index][index_2].start_value * 2][horizontal_position] == 0 || columns[index][all_appointments[index][index_2].start_value * 2][horizontal_position] == null){
                            horizantal_deep = horizontal_position
                            horizontal_position = all_appointments[index].length
                        }
                    }
                    for (let index_3 = parseInt(all_appointments[index][index_2].start_value * 2); index_3 < parseInt(all_appointments[index][index_2].end_value * 2); index_3++) {

                        if (index_3 == parseInt(all_appointments[index][index_2].start_value * 2)) {
                            let obj
                            if (all_appointments[index][index_2].patient != null) {
                                let patient = all_appointments[index][index_2].patient.first_name + ' ' + all_appointments[index][index_2].patient.last_name;
                                patient += all_appointments[index][index_2].note ? ` / ${all_appointments[index][index_2].note}` : '';

                                obj = {
                                    'first': 1,

                                    'sid': sticker_id,
                                    'id': all_appointments[index][index_2].id,
                                    'color': all_appointments[index][index_2].doctor.color,
                                    //'patient': all_appointments[index][index_2].patient.first_name + ' ' + all_appointments[index][index_2].patient.last_name,
                                    'patient': patient,
                                    'holder': all_appointments[index][index_2].appointment_holder,
                                    //'phone': all_appointments[index][index_2].phone_holder,
                                    //'cellphone': all_appointments[index][index_2].cellphone_holder,
                                    'procedure': all_appointments[index][index_2].surgery_services.length > 0 ? all_appointments[index][index_2].surgery_services[0].service.name : null, //add
                                    'phone': all_appointments[index][index_2].patient ? all_appointments[index][index_2].patient.phone : '',
                                    'cellphone': all_appointments[index][index_2].patient ? all_appointments[index][index_2].patient.cellphone : '',
                                    'requirements': all_appointments[index][index_2].requirements,
                                    'note': all_appointments[index][index_2].note,
                                    'date': all_appointments[index][index_2].date,
                                    'start': hours[parseInt(all_appointments[index][index_2].start_value * 2)].label,
                                    'end': hours[parseInt(all_appointments[index][index_2].end_value * 2)].label,
                                    'locked': all_appointments[index][index_2].locked,
                                    //add
                                    'created': all_appointments[index][index_2].created,
                                    'user': all_appointments[index][index_2].user.username,
                                }
                                sticker_id = sticker_id + 1
                            } else {
                                let patient = all_appointments[index][index_2].patient.first_name + ' ' + all_appointments[index][index_2].patient.last_name;
                                patient += all_appointments[index][index_2].note ? ` / ${all_appointments[index][index_2].note}` : '';

                                obj = {
                                    'first': 1,

                                    'sid': sticker_id,
                                    'id': all_appointments[index][index_2].id,
                                    'color': all_appointments[index][index_2].doctor.color,
                                    //'patient': all_appointments[index][index_2].patient_name,
                                    'patient': patient,
                                    'holder': all_appointments[index][index_2].appointment_holder,
                                    //'phone': all_appointments[index][index_2].phone_holder,
                                    //'cellphone': all_appointments[index][index_2].cellphone_holder,
                                    'procedure': all_appointments[index][index_2].surgery_services.length > 0 ? all_appointments[index][index_2].surgery_services[0].service.name : null, //add
                                    'phone': all_appointments[index][index_2].patient ? all_appointments[index][index_2].patient.phone : '',
                                    'cellphone': all_appointments[index][index_2].patient ? all_appointments[index][index_2].patient.cellphone: '',
                                    'requirements': all_appointments[index][index_2].requirements,
                                    'note': all_appointments[index][index_2].note,
                                    'requirements': all_appointments[index][index_2].patient ? all_appointments[index][index_2].requirements : '',
                                    'note': all_appointments[index][index_2].patient ? all_appointments[index][index_2].note : '',                                    
                                    'date': all_appointments[index][index_2].date,
                                    'start': hours[parseInt(all_appointments[index][index_2].start_value * 2)].label,
                                    'end': hours[parseInt(all_appointments[index][index_2].end_value * 2)].label,
                                    'locked': all_appointments[index][index_2].locked,
                                    //add
                                    'created': all_appointments[index][index_2].created,
                                    'user': all_appointments[index][index_2].user.username,
                                }
                                sticker_id = sticker_id + 1
                            }
                            columns[index][index_3][horizantal_deep] = obj
                        } else {
                            let obj
                            if (all_appointments[index][index_2].patient != null) {
                                let patient = all_appointments[index][index_2].patient.first_name + ' ' + all_appointments[index][index_2].patient.last_name;
                                patient += all_appointments[index][index_2].note ? ` / ${all_appointments[index][index_2].note}` : '';

                                obj = {
                                    'sid': sticker_id,
                                    'id': all_appointments[index][index_2].id,
                                    'color': all_appointments[index][index_2].doctor.color,
                                    //'patient': all_appointments[index][index_2].patient.first_name + ' ' + all_appointments[index][index_2].patient.last_name,
                                    'patient': patient,
                                    'holder': all_appointments[index][index_2].appointment_holder,
                                    //'phone': all_appointments[index][index_2].phone_holder,
                                    //'cellphone': all_appointments[index][index_2].cellphone_holder,
                                    'procedure': all_appointments[index][index_2].surgery_services.length > 0 ? all_appointments[index][index_2].surgery_services[0].service.name : null, //add

                                    'phone': all_appointments[index][index_2].patient ? all_appointments[index][index_2].patient.phone : '',
                                    'cellphone': all_appointments[index][index_2].patient ? all_appointments[index][index_2].patient.cellphone : '',
                                    'requirements': all_appointments[index][index_2].requirements,
                                    'note': all_appointments[index][index_2].note,
                                    'date': all_appointments[index][index_2].date,
                                    'start': hours[parseInt(all_appointments[index][index_2].start_value * 2)].label,
                                    'end': hours[parseInt(all_appointments[index][index_2].end_value * 2)].label,
                                    'locked': all_appointments[index][index_2].locked,
                                    //add
                                    'created': all_appointments[index][index_2].created,
                                    'user': all_appointments[index][index_2].user.username,
                                }
                                sticker_id = sticker_id + 1
                            } else {
                                let patient = all_appointments[index][index_2].patient.first_name + ' ' + all_appointments[index][index_2].patient.last_name;
                                patient += all_appointments[index][index_2].note ? ` / ${all_appointments[index][index_2].note}` : '';

                                obj = {
                                    'sid': sticker_id,
                                    'id': all_appointments[index][index_2].id,
                                    'color': all_appointments[index][index_2].doctor.color,
                                    //'patient': all_appointments[index][index_2].patient_name,
                                    'patient': patient,
                                    'holder': all_appointments[index][index_2].appointment_holder,
                                    //'phone': all_appointments[index][index_2].phone_holder,
                                    //'cellphone': all_appointments[index][index_2].cellphone_holder,
                                    'procedure': all_appointments[index][index_2].surgery_services.length > 0 ? all_appointments[index][index_2].surgery_services[0].service.name : null, //add
                                    'phone': all_appointments[index][index_2].patient ? all_appointments[index][index_2].patient.phone: '',
                                    'cellphone': all_appointments[index][index_2].patient ? all_appointments[index][index_2].patient.cellphone: '',
                                    'requirements': all_appointments[index][index_2].requirements,
                                    'note': all_appointments[index][index_2].note,
                                    'date': all_appointments[index][index_2].date,
                                    'start': hours[parseInt(all_appointments[index][index_2].start_value * 2)].label,
                                    'end': hours[parseInt(all_appointments[index][index_2].end_value * 2)].label,
                                    'locked': all_appointments[index][index_2].locked,
                                    //add
                                    'created': all_appointments[index][index_2].created,
                                    'user': all_appointments[index][index_2].user.username,
                                }
                                sticker_id = sticker_id + 1
                            }
                            columns[index][index_3][horizantal_deep] = obj
                            // let obj = {
                            //     'sdi': sticker_id,
                            //     'id': all_appointments[index][index_2].id,
                            //     'color': all_appointments[index][index_2].doctor.color
                            // }
                            // sticker_id = sticker_id + 1
                            // columns[index][index_3][horizantal_deep] = obj
                        }
                        // if(columns[index][index_3].length){
                        //     columns[index][index_3][horizantal_deep] = "x"
                        // }

                    }
                    // sticker_id = sticker_id + 1
                }
            }

            let max_position = 0
            for (let index = 0; index < overlaps.length; index++) {
                for (let index_2 = 0; index_2 < overlaps[index].length; index_2++) {
                    if(overlaps[index][index_2] != null){
                        max_position = 0
                        for (let index_3 = overlaps[index][index_2][0]; index_3 < overlaps[index][index_2][1]; index_3++) {
                            for (let index_4 = 0; index_4 < columns[index][index_3].length; index_4++) {
                                if(columns[index][index_3][index_4] != 0 && index_4 > max_position){
                                    max_position = index_4
                                }
                            }

                        }
                        for (let index_5 = overlaps[index][index_2][0]; index_5 < overlaps[index][index_2][1]; index_5++) {
                            for (let index_6 = max_position + 1; index_6 < overlaps[index][index_2][2]; index_6++) {
                                // columns[index][index_5][index_6] = null
                                columns[index][index_5].pop()
                            }
                        }
                    }
                }
            }

            // console.log("APPOINTMENTS: ", all_appointments)
            // console.log("Columns: ", columns)
            // console.log("ARRAY OVERLAPS -------------- \n",overlaps,"\n")
            dispatch({type: SET_OVERLAPS_SURGERY, overlaps})
            dispatch({type: SET_APPOINTMENTS_SURGERY, appointments: columns});
        }
        dispatch({type: LOADER_SURGERY, cargando: false});
    })
};

const detailPatient = id => (dispatch, getState) =>{
    dispatch({type: LOADER_SURGERY, cargando: true});
    api.get(`patients/${id}`).catch((error) => {
        dispatch({type: LOADER_SURGERY, cargando: false});
        if(error.statusCode  === 404){
            dispatch(push('/'))
        }
    }).then((data) => {
        dispatch({type: LOADER_SURGERY, cargando: false});
        if(data){
            const old_data = getState().form.surgery.values;
            data.patient_id = data.id
            delete data.id
            if(data.gender == MALE){
                data.gender = {value: MALE, label: 'Masculino'}
            } else {
                data.gender = {value: FEMALE, label: 'Femenino'}
            }
            for (let index = 0; index < departamentos.length; index++) {
                if(departamentos[index].value == data.departamento){
                    data.departamento = departamentos[index];
                }
            }
            for (let index = 0; index < municipios.length; index++) {
                if(municipios[index].value == data.municipio){
                    data.municipio = municipios[index];
                }
            }
            if(data.family_member_of){
                data.family_member_of.value = data.family_member_of.id;
                data.family_member_of.label = data.family_member_of.name;
            }
            if(data.referred_by){
                data.referred_by.value = data.referred_by.id;
                data.referred_by.label = data.referred_by.name;
            }
            dispatch(initializeForm('surgery', {...data, ...old_data}))
        }
    })
}

const detail = id => async(dispatch, getState) =>{
    console.log(`\n\n\nINICIOOOO\n\n\n`);
    dispatch({type: SET_MODAL_SERVICE, modal_service: false});

    let response = await api.get(`configuration/getConfiguration`);
    const default_financial_entity = response.default_financial_entity;

    dispatch({type: LOADER_SURGERY, cargando: true});
    api.get(`${url}/${id}`).catch((error) => {
        dispatch({type: LOADER_SURGERY, cargando: false});
        if(error.statusCode  === 404){
            dispatch(push('/surgeries'));
        }
    }).then((data) => {
        console.log("\n\n\nDataa\n\n\n");
        dispatch({type: LOADER_SURGERY, cargando: false});
        if(data){
            let temporary = {}
            temporary.value = data.doctor.id;
            temporary.label = data.doctor.name;
            data.doctor = temporary;

            let referente = {};
            let idFinancialEntity = 0;
            let usuarioFel = "";
            let idReferentCategory = 0;
            let receipt = false;
            let afiliacion_fel = ""; //add



            if(data.referent.institution !== null){
                idFinancialEntity = data.referent.institution.referent_category.financial_entity.id;
                usuarioFel = data.referent.institution.referent_category.financial_entity.usuario_fel;
                idReferentCategory = data.referent.institution.referent_category.id;
                receipt = data.referent.institution.referent_category.issue_receipt;
                afiliacion_fel =  data.referent.institution.referent_category.financial_entity.afiliacion_fel;
            }else{
                idFinancialEntity = default_financial_entity.id;
                usuarioFel = default_financial_entity.usuario_fel;
                idReferentCategory = 0;
                receipt = default_financial_entity.afiliacion_fel === "exento" ? true : false;
                afiliacion_fel = default_financial_entity.usuario_fel; //add
            }

            referente = {
                value: data.referent.id,
                label: data.referent.name,
                usuario_fel: usuarioFel,
                financial_entity: idFinancialEntity,
                idReferentCategory: idReferentCategory,
                receipt,
                afiliacion_fel, //add
            };

            data.referent = referente;

            temporary = {}
            data.start = hours.find((item, index) => item.value == parseFloat(data.start_value))
            data.end = hours.find((item, index) => item.value == parseFloat(data.end_value))
            data.start.label = data.start.hrs
            data.end.label = data.end.hrs
            // delete data.start.hrs
            // delete data.end.hrs

            if(!data.patient){
                delete data.patient;
            } else {
                console.log("SÍ HAY UN PACIENTE REGISTRADO...");
                data.patient = {value: data.patient.id, label: data.patient.first_name + ' ' + data.patient.last_name};
            }

            delete data.active;
            delete data.modified;
            delete data.end_value;
            delete data.start_value;
            delete data.appointment_type;
            delete data.subsidiary;

            dispatch({type: SET_UPDATE_DATA_SURGERY, updateData: data});
            dispatch(initializeForm('surgery', data))

            //add ricky
            let services_list = [];
            if(data.surgery_services){
                data.surgery_services.forEach((ss) => {
                    services_list.push({
                        id: ss.service.id,
                        name: ss.service.name,
                        quantity: ss.quantity,

                        price: ss.price,
                        financial_entity: ss.financial_entity.id,
                    });
                });
            }

            dispatch({type: SET_SERVICES_LIST, services_list});
            //...
        }
    })
}

const create = () => (dispatch, getStore) => {
    const formData = _.cloneDeep(getStore().form.surgery.values);
    const current_user = getStore().login.me;

    formData.subsidiary = current_user.person_user.subsidiary;
    formData.user = current_user.id;

    formData.doctor = formData.doctor.value;
    formData.referent = formData.referent.value;
    formData.start_value = formData.start.value;
    formData.end_value = formData.end.value;
    formData.start = formData.start.label + ':00';
    formData.end = formData.end.label + ':00';
    formData.appointment_type = DOCTOR

    const patient = {id: formData.patient.value};
    formData.patient = patient;

    /*
    if (formData.cellphone_holder == undefined){
        formData.cellphone_holder = ''
    }

    if(formData.first_name && formData.first_name != null){
        formData.patient = {}
        formData.patient.first_name = formData.first_name;
        formData.patient.last_name = formData.last_name;
        formData.patient.gender = formData.gender.value;
        formData.patient.birthdate = formData.birthdate;
        formData.patient.nationality = formData.nationality;
        delete formData.first_name;
        delete formData.last_name;
        delete formData.gender;
        delete formData.birthdate;
        delete formData.nationality;

        if(formData.petient_search){
            formData.patient.id = formData.patient_id;
            delete formData.active;
            delete formData.created;
            delete formData.modified;
            delete formData.patient_id;
            delete formData.petient_search;
        }
    }
    if(formData.married_last_name || formData.married_last_name == null){
        if(formData.married_last_name != null){
            formData.patient.married_last_name = formData.married_last_name;
        }
        delete formData.married_last_name;
    }
    if(formData.address || formData.address == null ){
        if(formData.address != null){
            formData.patient.address = formData.address;
        }
        delete formData.address;
    }
    if(formData.dpi || formData.dpi == null){
        if(formData.dpi != null){
            formData.patient.dpi = formData.dpi;
        }
        delete formData.dpi;
    }
    if(formData.profession || formData.profession == null){
        if(formData.profession != null){
            formData.patient.profession = formData.profession;
        }
        delete formData.profession;
    }
    if(formData.email || formData.email == null){
        if(formData.email != null){
            formData.patient.email = formData.email;
        }
        delete formData.email;
    }
    if(formData.phone || formData.phone == null){
        if(formData.phone != null){
            formData.patient.phone = formData.phone;
        }
        delete formData.phone;
    }
    if(formData.cellphone || formData.cellphone == null){
        if(formData.cellphone != null){
            formData.patient.cellphone = formData.cellphone;
        }
        delete formData.cellphone;
    }
    if(formData.departamento || formData.departamento == null){
        if(formData.departamento != null){
            formData.patient.departamento = formData.departamento.value;
        }
        delete formData.departamento;
    }
    if(formData.municipio || formData.municipio == null){
        if(formData.municipio != null){
            formData.patient.municipio = formData.municipio.value;
        }
        delete formData.municipio;
    }
    if(formData.family_member_of || formData.family_member_of == null){
        if(formData.family_member_of != null){
            formData.patient.family_member_of = formData.family_member_of.value;
        }
        delete formData.family_member_of;
    }
    if(formData.referred_by || formData.referred_by == null){
        if(formData.referred_by != null){
            formData.patient.referred_by = formData.referred_by.value;
        }
        delete formData.referred_by;
    }
    */

    //add ricky
    const services_list = _.cloneDeep(getStore().surgery.services_list);
    if(services_list.length){
        formData.services_list = services_list;
    }
    //...

    dispatch({type: LOADER_SURGERY, cargando: true})
    api.post(`${url}`, formData).then((data) => {
        dispatch({type: LOADER_SURGERY, cargando: false})
        let mensaje = "Se ha creado la cita";
        if(data.detail !== '' && data.detail !== "True"){
            mensaje += `, sin embargo faltan los siguientes insumos: ${data.detail}`;
        }

        Swal('Éxito', mensaje, 'success')
        .then(() => {
            dispatch(push('/surgeries'))
        })
    }).catch((error) => {
        dispatch({type: LOADER_SURGERY, cargando: false})
        Swal(
            'Error',
             error.detail || 'Ha ocurrido un error, por favor vuelva a intentar.',
            'error'
        );
    }).finally(() => {
        dispatch({type: LOADER_SURGERY, cargando: false})
    });
};

const schedule_create = (data, state) => (dispatch, getStore) => {

    const formData = _.cloneDeep(getStore().form.schedule.values);

    formData.doctor = formData.doctor.value;
    formData.start_value = formData.start.value;
    formData.end_value = formData.end.value;
    formData.start = formData.start.label + ':00';
    formData.end = formData.end.label + ':00';
    formData.appointment_type = DOCTOR

    const current_user = getStore().login.me;

    formData.subsidiary = current_user.person_user.subsidiary;
    formData.user = current_user.id;

    if (formData.cellphone_holder == undefined){
        formData.cellphone_holder = ''
    }

    if(formData.first_name && formData.first_name != null){
        formData.patient = {}
        formData.patient.first_name = formData.first_name;
        formData.patient.last_name = formData.last_name;
        formData.patient.gender = formData.gender.value;
        formData.patient.birthdate = formData.birthdate;
        formData.patient.nationality = formData.nationality;
        delete formData.first_name;
        delete formData.last_name;
        delete formData.gender;
        delete formData.birthdate;
        delete formData.nationality;

        if(formData.petient_search){
            formData.patient.id = formData.patient_id;
            delete formData.active;
            delete formData.created;
            delete formData.modified;
            delete formData.patient_id;
            delete formData.petient_search;
        }
    }

    dispatch({type: LOADER_SURGERY, cargando: true})
    api.post(`${url}`, formData).then((data) => {
        dispatch({type: LOADER_SURGERY, cargando: false})
        Swal('Éxito', 'Se ha creado la cita.', 'success')
        .then(() => {
            dispatch(push('/schedule'))
            if (state.doctor_id) {
                dispatch(listar(state.week.monday.format("YYYY-MM-DD"), state.week.sunday.format("YYYY-MM-DD"), state.doctor_id))
            }
            else {
                dispatch(listar(state.week.monday.format("YYYY-MM-DD"), state.week.sunday.format("YYYY-MM-DD"), state.doctor_id, 1))
            }
        })
    }).catch((error) => {
        dispatch({type: LOADER_SURGERY, cargando: false})
        Swal(
            'Error',
             error.detail || 'Ha ocurrido un error, por favor vuelva a intentar.',
            'error'
        );
    }).finally(() => {
        dispatch({type: LOADER_SURGERY, cargando: false})
    });
};

const update = () => (dispatch, getStore) => {
    const formData = _.cloneDeep(getStore().form.surgery.values);
    formData.doctor = formData.doctor.value;
    formData.referent = formData.referent.value;
    formData.start_value = formData.start.value;
    formData.end_value = formData.end.value;
    formData.start = formData.start.label + ':00';
    formData.end = formData.end.label + ':00';
    formData.appointment_type = DOCTOR
    if(formData.first_name){
        formData.patient = {}
        formData.patient.first_name = formData.first_name;
        formData.patient.last_name = formData.last_name;
        formData.patient.gender = formData.gender.value;
        formData.patient.birthdate = formData.birthdate;
        formData.patient.nationality = formData.nationality;
        delete formData.first_name;
        delete formData.last_name;
        delete formData.gender;
        delete formData.birthdate;
        delete formData.nationality;

        if(formData.petient_search){
            formData.patient.id = formData.patient_id;
            delete formData.active;
            delete formData.created;
            delete formData.modified;
            delete formData.patient_id;
            delete formData.petient_search;
        } else if(formData.patient_id){
            formData.patient.id = formData.patient_id;
            delete formData.active;
            delete formData.created;
            delete formData.modified;
            delete formData.patient_id;
        }

        if(formData.married_last_name){
            formData.patient.married_last_name = formData.married_last_name;
            delete formData.married_last_name;
        }
        if(formData.address){
            formData.patient.address = formData.address;
            delete formData.address;
        }
        if(formData.dpi){
            formData.patient.dpi = formData.dpi;
            delete formData.dpi;
        }
        if(formData.profession){
            formData.patient.profession = formData.profession;
            delete formData.profession;
        }
        if(formData.email){
            formData.patient.email = formData.email;
            delete formData.email;
        }
        if(formData.phone){
            formData.patient.phone = formData.phone;
            delete formData.phone;
        }
        if(formData.cellphone){
            formData.patient.cellphone = formData.cellphone;
            delete formData.cellphone;
        }
        if(formData.departamento){
            formData.patient.departamento = formData.departamento.value;
            delete formData.departamento;
        }
        if(formData.municipio){
            formData.patient.municipio = formData.municipio.value;
            delete formData.municipio;
        }
        if(formData.married_last_name || formData.married_last_name == null){
            if(formData.married_last_name != null){
                formData.patient.married_last_name = formData.married_last_name;
            }
            delete formData.married_last_name;
        }
        if(formData.address || formData.address == null ){
            if(formData.address != null){
                formData.patient.address = formData.address;
            }
            delete formData.address;
        }
        if(formData.dpi || formData.dpi == null){
            if(formData.dpi != null){
                formData.patient.dpi = formData.dpi;
            }
            delete formData.dpi;
        }
        if(formData.profession || formData.profession == null){
            if(formData.profession != null){
                formData.patient.profession = formData.profession;
            }
            delete formData.profession;
        }
        if(formData.email || formData.email == null){
            if(formData.email != null){
                formData.patient.email = formData.email;
            }
            delete formData.email;
        }
        if(formData.phone || formData.phone == null){
            if(formData.phone != null){
                formData.patient.phone = formData.phone;
            }
            delete formData.phone;
        }
        if(formData.cellphone || formData.cellphone == null){
            if(formData.cellphone != null){
                formData.patient.cellphone = formData.cellphone;
            }
            delete formData.cellphone;
        }
        if(formData.departamento || formData.departamento == null){
            if(formData.departamento != null){
                formData.patient.departamento = formData.departamento.value;
            }
            delete formData.departamento;
        }
        if(formData.municipio || formData.municipio == null){
            if(formData.municipio != null){
                formData.patient.municipio = formData.municipio.value;
            }
            delete formData.municipio;
        }
        if(formData.family_member_of || formData.family_member_of == null){
            if(formData.family_member_of != null){
                formData.patient.family_member_of = formData.family_member_of.value;
            }
            delete formData.family_member_of;
        }
        if(formData.referred_by || formData.referred_by == null){
            if(formData.referred_by != null){
                formData.patient.referred_by = formData.referred_by.value;
            }
            delete formData.referred_by;
        }
        if(formData.family_member_of){
            formData.patient.family_member_of = formData.family_member_of.value;
            delete formData.family_member_of;
        }
        if(formData.referred_by){
            formData.patient.referred_by = formData.referred_by.value;
            delete formData.referred_by;
        }
    }

    //add ricky
    const services_list = _.cloneDeep(getStore().surgery.services_list);
    if(services_list.length){
        formData.services_list = services_list;
    }
    //...

    // console.log("UPDATE DATA", formData)
    dispatch({type: LOADER_SURGERY, cargando: true});
    api.put(`${url}/${formData.id}`, formData)
    .then((data) => {
        dispatch({type: LOADER_SURGERY, cargando: false});
        if(data){
            Swal(
                'Éxito',
                'Datos editados correctamente.',
                'success'
            ).then(() => {
                dispatch(push('/surgeries'));
            })
        }
    })
    .catch((error) =>{
        dispatch({type: LOADER_SURGERY, cargando: false});
        Swal(
            'Error',
             error.detail || 'Ha ocurrido un error, verifique los datos.',
            'error'
        )
        if(error.statusCode === 404){
            dispatch(push('/surgeries'));
        }
    })
}


const week_update = () => (dispatch, getStore) => {
    const formData = _.cloneDeep(getStore().form.surgery.values);
    formData.doctor = formData.doctor.value;
    formData.start_value = formData.start.value;
    formData.end_value = formData.end.value;
    formData.start = formData.start.label + ':00';
    formData.end = formData.end.label + ':00';
    formData.appointment_type = DOCTOR
    if(formData.first_name){
        formData.patient = {}
        formData.patient.first_name = formData.first_name;
        formData.patient.last_name = formData.last_name;
        formData.patient.gender = formData.gender.value;
        formData.patient.birthdate = formData.birthdate;
        formData.patient.nationality = formData.nationality;
        delete formData.first_name;
        delete formData.last_name;
        delete formData.gender;
        delete formData.birthdate;
        delete formData.nationality;

        if(formData.petient_search){
            formData.patient.id = formData.patient_id;
            delete formData.active;
            delete formData.created;
            delete formData.modified;
            delete formData.patient_id;
            delete formData.petient_search;
        } else if(formData.patient_id){
            formData.patient.id = formData.patient_id;
            delete formData.active;
            delete formData.created;
            delete formData.modified;
            delete formData.patient_id;
        }

        if(formData.married_last_name){
            formData.patient.married_last_name = formData.married_last_name;
            delete formData.married_last_name;
        }
        if(formData.address){
            formData.patient.address = formData.address;
            delete formData.address;
        }
        if(formData.dpi){
            formData.patient.dpi = formData.dpi;
            delete formData.dpi;
        }
        if(formData.profession){
            formData.patient.profession = formData.profession;
            delete formData.profession;
        }
        if(formData.email){
            formData.patient.email = formData.email;
            delete formData.email;
        }
        if(formData.phone){
            formData.patient.phone = formData.phone;
            delete formData.phone;
        }
        if(formData.cellphone){
            formData.patient.cellphone = formData.cellphone;
            delete formData.cellphone;
        }
        if(formData.departamento){
            formData.patient.departamento = formData.departamento.value;
            delete formData.departamento;
        }
        if(formData.municipio){
            formData.patient.municipio = formData.municipio.value;
            delete formData.municipio;
        }
        if(formData.married_last_name || formData.married_last_name == null){
            if(formData.married_last_name != null){
                formData.patient.married_last_name = formData.married_last_name;
            }
            delete formData.married_last_name;
        }
        if(formData.address || formData.address == null ){
            if(formData.address != null){
                formData.patient.address = formData.address;
            }
            delete formData.address;
        }
        if(formData.dpi || formData.dpi == null){
            if(formData.dpi != null){
                formData.patient.dpi = formData.dpi;
            }
            delete formData.dpi;
        }
        if(formData.profession || formData.profession == null){
            if(formData.profession != null){
                formData.patient.profession = formData.profession;
            }
            delete formData.profession;
        }
        if(formData.email || formData.email == null){
            if(formData.email != null){
                formData.patient.email = formData.email;
            }
            delete formData.email;
        }
        if(formData.phone || formData.phone == null){
            if(formData.phone != null){
                formData.patient.phone = formData.phone;
            }
            delete formData.phone;
        }
        if(formData.cellphone || formData.cellphone == null){
            if(formData.cellphone != null){
                formData.patient.cellphone = formData.cellphone;
            }
            delete formData.cellphone;
        }
        if(formData.departamento || formData.departamento == null){
            if(formData.departamento != null){
                formData.patient.departamento = formData.departamento.value;
            }
            delete formData.departamento;
        }
        if(formData.municipio || formData.municipio == null){
            if(formData.municipio != null){
                formData.patient.municipio = formData.municipio.value;
            }
            delete formData.municipio;
        }
        if(formData.family_member_of || formData.family_member_of == null){
            if(formData.family_member_of != null){
                formData.patient.family_member_of = formData.family_member_of.value;
            }
            delete formData.family_member_of;
        }
        if(formData.referred_by || formData.referred_by == null){
            if(formData.referred_by != null){
                formData.patient.referred_by = formData.referred_by.value;
            }
            delete formData.referred_by;
        }
        if(formData.family_member_of){
            formData.patient.family_member_of = formData.family_member_of.value;
            delete formData.family_member_of;
        }
        if(formData.referred_by){
            formData.patient.referred_by = formData.referred_by.value;
            delete formData.referred_by;
        }
    }
    // console.log("UPDATE DATA", formData)
    dispatch({type: LOADER_SURGERY, cargando: true});
    api.put(`${url}/${formData.id}`, formData)
    .then((data) => {
        dispatch({type: LOADER_SURGERY, cargando: false});
        if(data){
            Swal(
                'Éxito',
                'Datos editados correctamente.',
                'success'
            ).then(() => {
                dispatch(push('/schedule'));
            })
        }
    })
    .catch((error) =>{
        dispatch({type: LOADER_APPOINTMENTS, cargando: false});
        Swal(
            'Error',
             error.detail || 'Ha ocurrido un error, verifique los datos.',
            'error'
        )
        if(error.statusCode === 404){
            dispatch(push('/schedule'));
        }
    })
}

const destroy = (id, initial_date, final_date, doctor_id, current_doctor) => (dispatch, getStore) => {
    let initial=null;
    let final=null;
    if(initial_date!=null && final_date!=null){
        initial = initial_date.format("YYYY-MM-DD");
        final = final_date.format("YYYY-MM-DD")
    }
    else
    {
        initial=moment().format("YYYY-MM-DD");
        final=moment().format("YYYY-MM-DD");
    }
    Swal({
        title: '¿Desea eliminar este elemento?',
        text: 'No puedes deshacer esta acción',
        type: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Sí, eliminar',
        cancelButtonText: 'No, cancelar',
        reverseButtons: true
    }).then((result) => {
        if (result.value) {
            dispatch({type: LOADER_SURGERY, cargando: true});
            api.eliminar(`${url}/${id}`)
            .then((data) => {
                dispatch({type: LOADER_SURGERY, cargando: false});
                Swal('Éxito', 'Se ha eliminado correctamente.', 'success')
                    .then(() => {
                        if (current_doctor){
                            dispatch(listar(initial, final, doctor_id));
                        } else {
                            dispatch(listar(initial, final, doctor_id, 1));
                        }
                    })
            })
            .catch((error) => {
                dispatch({type: LOADER_SURGERY, cargando: false});
                Swal('Error', 'No se ha logrado borrar el registro.', 'error')
                    .then(() => {
                        if (current_doctor){
                            dispatch(listar(initial, final, doctor_id));
                        } else {
                            dispatch(listar(initial, final, doctor_id, 1));
                        }
                })
            })
        }
        dispatch({type:SET_SURGERY_SHOW_TOOLTIP_MODAL, modal_tooltip_show: false});
    });
}

const createInitialize = () => (dispatch, getStore) => {
    dispatch(initializeForm('surgery',
        {
            'date': getStore().appointments.date,
            'end': getStore().appointments.end,
            'start': getStore().appointments.start,
            'is_surgery':false,
        }
    ))
}

const miniCreateInitialize = () => (dispatch, getStore) => {
    dispatch(initializeForm('schedule',
        {
            'date': getStore().appointments.date,
            'end': getStore().appointments.end,
            'start': getStore().appointments.start,
        }
    ))
}

const createInitializePatient = () => (dispatch, getState) => {
    const old_data = getState().form.appointments.values;
    dispatch(initializeForm('appointments', {birthdate: moment(new Date()).format("YYYY-MM-DD"), ...old_data}))
}

const changeCreateInitializeData = (date, start, end) => (dispatch, getStore) => {
    dispatch({type: SET_INITIALIZE_FORM_DATA, date: date.format("YYYY-MM-DD"), start, end})
}

const search = (search) => (dispatch, getStore) => {
    dispatch({type: SET_BUSCADOR_SURGERY, buscador: search});
    dispatch(listar(1));
};
const filtro = (filtro) => (dispatch, getStore) => {
    if (filtro == 0){
        filtro = null
    }
    dispatch({type: SET_FILTRO_SURGERY, filtro_appointments: filtro});
    dispatch(listar(1));
};

const listarServicios = (page = 1) =>  (dispatch, getStore) => {
    const referent = _.cloneDeep(getStore().form.surgery.values.referent);
    const idReferentCategory = referent !== undefined && referent !== null ? referent.idReferentCategory : 0;

    dispatch({type: LOADER_SERVICE, cargando: true});
    const store = getStore();
    const search = store.surgery.buscador_service;
    const filtro = store.surgery.filtro_service;
    let params = {page, search, idReferentCategory};

    if(filtro){
        params[''] = filtro
    }
    api.get(`services/getServiceForSale`, params).catch((error) => {
        dispatch({type: LOADER_SERVICE, cargando: false});
        Swal(
            'Error',
            error.detail || 'Ha ocurrido un error, por favor vuelva a intentar.',
            'error'
        );
    }).then((data) => {
        const search2 = getStore().surgery.buscador_service;
        if(search2 == search){
            if(data){
                dispatch({type: SET_DATA_SERVICE, data});
                dispatch({type: SET_PAGE_SERVICE, page});
                dispatch({type: SET_MODAL_SERVICE, modal_service: true});
            }
            dispatch({type: LOADER_SERVICE, cargando: false});
            //dispatch({type: SET_UPDATE_DATA_SERVICE, updateData: {}});
        }
    })
};

const searchService = (search) => (dispatch, getStore) => {
    dispatch({type: SET_BUSCADOR_SERVICE, buscador_service: search});
    dispatch(listarServicios(1));
};

/**
 *  Este se utiliza cuando se agenda la cirugía y se registran los productos, servicios y paquetes que utilizará
 * @param {*} add_service
 */
const addServiceToList = (add_service) => (dispatch, getStore) => {
    const services_list = _.cloneDeep(getStore().surgery.services_list);
    const service = services_list.find(item => item.id === add_service.id);
    const index = services_list.findIndex(item => item.id === add_service.id)

    if(service && (index !== -1)){
        services_list[index].quantity += 1;
    }else{
        services_list.push({
            id: add_service.id,
            name: add_service.name,
            quantity: 1,

            price: add_service.price,
            financial_entity: add_service.financial_entity.value,
        });
    }

    dispatch({type: SET_SERVICES_LIST, services_list});
    dispatch({type: SET_MODAL_SERVICE, modal_service: false});

}

const addServiceToListDiff = () => (dispatch, getStore) => {
    const formData = _.cloneDeep(getStore().form.frmInterSurgery.values);

    const services_list = _.cloneDeep(getStore().surgery.services_list);
    const service = services_list.find(item => item.id === formData.id);
    const index = services_list.findIndex(item => item.id === formData.id)

    if(service && (index !== -1)){
        services_list[index].quantity += 1;
    }else{
        services_list.push({
            id: formData.id,
            name: formData.name,
            quantity: 1,

            price: formData.price,
            financial_entity: formData.financial_entity.value,
        });
    }

    dispatch({type: SET_SERVICES_LIST, services_list});
    dispatch({type: SET_MODAL_SERVICE, modal_service: false});

}

const addServiceToListDiffInSurgery = () => (dispatch, getStore) => {
    const formData = _.cloneDeep(getStore().form.frmInterSurgery.values);

    const services_list = _.cloneDeep(getStore().surgery.services_perform_surgery);
    const service = services_list.find(item => item.id === formData.id);
    const index = services_list.findIndex(item => item.id === formData.id)

    if(service && (index !== -1)){
        services_list[index].quantity += 1;
    }else{
        services_list.push({
            id: formData.id,
            name: formData.name,
            quantity: 1,
            used: 0,
            initial: false,
            price: parseFloat(formData.price).toFixed(2),
            financial_entity: formData.financial_entity.value,
        });
    }

    dispatch({type: SET_SERVICES_PERFORM_SURGERY, services_perform_surgery: services_list});
    dispatch({type: GROUP_BILL}); //add
    dispatch({type: SET_MODAL_SERVICE, modal_service: false});
}

/**
 *
 * @param {*} add_service
 */
const addServiceToListInSurgery = (add_service) => (dispatch, getStore) => {
    const services_list = _.cloneDeep(getStore().surgery.services_perform_surgery);
    const service = services_list.find(item => item.id === add_service.id);
    const index = services_list.findIndex(item => item.id === add_service.id)

    if(service && (index !== -1)){
        services_list[index].quantity += 1;
    }else{
        services_list.push({
            id: add_service.id,
            name: add_service.name,
            quantity: 1,
            used: 0,
            barcode: add_service.barcode,
            initial: false,

            price: parseFloat(add_service.price).toFixed(2),
            financial_entity: add_service.financial_entity.value,
        });
    }

    dispatch({type:SET_SERVICES_PERFORM_SURGERY, services_perform_surgery: services_list});
    dispatch({type: GROUP_BILL}); //add
    dispatch({type: SET_MODAL_SERVICE, modal_service: false});
    dispatch(limpiarBuscadorServicios());
}

/**
 * Este se utiliza cuando se agenda la cirugía y se registran los productos, servicios y paquetes que utilizará
 * @param {*} id
 * @param {*} up_down
 */
const upDownCant = (id, up_down) => (dispatch, getStore) => {
    const services_list = _.cloneDeep(getStore().surgery.services_list);
    const index = services_list.findIndex(item => item.id === id)

    if(up_down){
        services_list[index].quantity += 1;
    }else{
        if(services_list[index].quantity - 1 >= 0){
            services_list[index].quantity -= 1;
        }else{
            services_list[index].quantity = 0;
        }
    }

    dispatch({type: SET_SERVICES_LIST, services_list});
    dispatch({type: SET_MODAL_SERVICE, modal_service: false});
}

const setCant = (id, cantidad) => (dispatch, getStore) => {
    const services_list = _.cloneDeep(getStore().surgery.services_list);
    const index = services_list.findIndex(item => item.id === id)

    services_list[index].quantity = cantidad;

    if(services_list[index].quantity < 0){
        services_list[index].quantity = 0
    }

    dispatch({type: SET_SERVICES_LIST, services_list});
    dispatch({type: SET_MODAL_SERVICE, modal_service: false});
}

/**
 * Este se utiliza cuando ya se está efectuando la cirugía para modificar la cantidad de insumos que se están utilizando
 * @param {*} id
 * @param {*} up_down
 */
const upDownCantInSurgery = (id, up_down) => (dispatch, getStore) => {
    const services_list = _.cloneDeep(getStore().surgery.services_perform_surgery);
    const index = services_list.findIndex(item => item.id === id)

    if(up_down){
        services_list[index].used += 1;
    }else{
        if(services_list[index].used - 1 >= 0){
            services_list[index].used -= 1;
        }else{
            services_list[index].used = 0;
        }
    }

    dispatch({type:SET_SERVICES_PERFORM_SURGERY, services_perform_surgery: services_list});
    dispatch({type: SET_MODAL_SERVICE, modal_service: false});
    dispatch({type: GROUP_BILL}); //add
}

const setCantInSurgery = (id, cantidad) => (dispatch, getStore) => {
    const services_list = _.cloneDeep(getStore().surgery.services_perform_surgery);
    const index = services_list.findIndex(item => item.id === id)

    services_list[index].used = cantidad;

    if(services_list[index].used < 0){
        services_list[index].used = 0
    }

    dispatch({type: SET_SERVICES_PERFORM_SURGERY, services_perform_surgery: services_list});
    dispatch({type: SET_MODAL_SERVICE, modal_service: false});
    dispatch({type: GROUP_BILL}); //add
}

const removeServiceToList = (id) => (dispatch, getStore) => {
    const services_list = _.cloneDeep(getStore().surgery.services_list);

    let arreglo = services_list.filter((item) => {
        return item.id !== id;
    });

    dispatch({type: SET_SERVICES_LIST, services_list: arreglo });
    dispatch({type: SET_MODAL_SERVICE, modal_service: false});
}

const removeServiceToListInSurgery = (id) => (dispatch, getStore) => {
    const services_list = _.cloneDeep(getStore().surgery.services_perform_surgery);
    let arreglo = services_list.filter((item) => {
        return item.id !== id;
    });

    dispatch({type:SET_SERVICES_PERFORM_SURGERY, services_perform_surgery: arreglo});
    dispatch({type: SET_MODAL_SERVICE, modal_service: false});
    dispatch({type: GROUP_BILL}); //add
}

//Funciones del proceso de cirugía
const performSurgery = (id_surgery) => (dispatch, getStore) => {
    dispatch({type: SET_MODAL_SERVICE, modal_service: false});
    api.get(`${url}/${id_surgery}`).catch((error) => {
        console.log("Error: ", error);
    }).then((data_surgery) => {
        if(data_surgery){
            dispatch({type: SET_SURGERY_INFO, surgery_info:data_surgery});
            api.get(`${url}/getItemizedProducts`, {id_surgery}).catch((error) => {
                dispatch({type: LOADER_SERVICE, cargando: false});
                Swal(
                    'Error',
                    error.detail || 'Ha ocurrido un error, por favor vuelva a intentar.',
                    'error'
                );
            }).then((data) => {
                //Para ordenar alfabeticamente
                data = data.sort(function (a, b){
                    if (a.service.name.trim() < b.service.name.trim())
                      return -1;
                    if (a.service.name.trim() > b.service.name.trim())
                      return 1;
                    return 0;
                })

                let services_perform_surgery = [];
                data.forEach(item => {
                    const element = services_perform_surgery.find(sps => sps.id === item.service.id);
                    const index = services_perform_surgery.findIndex(sps => sps.id === item.service.id);

                    if(element && (index !== -1)){
                        services_perform_surgery[index].quantity += item.quantity;
                    }else{
                        const _used = (item.service.service_type === 2) ? item.quantity : 0;

                        services_perform_surgery.push({
                            id: item.service.id,
                            name: item.service.name,
                            quantity: item.quantity,
                            //used: 0,
                            used: _used, //mod
                            type: item.service.service_type, //add
                            barcode: item.service.barcode,
                            initial: true, //Esto es para saber si es un producto que inicialmente estaba incluido en la cirugía, sea de forma individual o en un paquete, pero ya estaba incluido
                            price: item.price,
                            financial_entity: item.financial_entity.id,
                        });
                    }

                });

                dispatch({type: GROUP_BILL}); //add
                dispatch({type:SET_SERVICES_PERFORM_SURGERY, services_perform_surgery})
            })
        }
    })


    /*
    api.get(`${url}/getItemizedProducts`, {id_surgery}).catch((error) => {
        dispatch({type: LOADER_SERVICE, cargando: false});
        Swal(
            'Error',
            error.detail || 'Ha ocurrido un error, por favor vuelva a intentar.',
            'error'
        );
    }).then((data) => {
        let services_perform_surgery = [];
        data.forEach(item => {
            const element = services_perform_surgery.find(sps => sps.id === item.service.id);
            const index = services_perform_surgery.findIndex(sps => sps.id === item.service.id);

            if(element && (index !== -1)){
                services_perform_surgery[index].quantity += item.quantity;
            }else{
                services_perform_surgery.push({
                    id: item.service.id,
                    name: item.service.name,
                    quantity: item.quantity,
                    used: 0,
                    barcode: item.service.barcode,
                });
            }

        });

        console.log("data parseada: ", services_perform_surgery);
        dispatch({type:SET_SERVICES_PERFORM_SURGERY, services_perform_surgery})
    })
    */
}

const toggleModalServicios = (estado) => (dispatch) =>{
    dispatch({type: SET_MODAL_SERVICE, modal_service: estado});
}

const setObservations = (e) => (dispatch) => {
    const observations = e.target.value;
    dispatch({type: SET_OBSERVATIONS, observations});
}

const endSurgery = (surgery_id) => (dispatch, getStore) => {
    Swal.fire({
        title: '¿Está seguro(a) que desea finalizar la cirugía?, no podrá deshacer ésta acción',
        type: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#aaa',
        confirmButtonText: 'Aceptar',
        cancelButtonText: 'Cancelar',
        reverseButtons: true
    }).then((result) => {
        if (result.value) {
            dispatch({type: SET_END_SURGERY_LOADER, end_surgery_loader: true})
            const surgery = _.cloneDeep(getStore().surgery);
            const bills = surgery.services_perform_surgery_ordered;
            const observations = surgery.observations;

            const data = {
                surgery_id,
                bills,
                observations,
            }

            api.post(`${url}/endSurgery`, data).catch((error) => {
                Swal(
                    'Error',
                    error.detail || 'Ha ocurrido un error, por favor vuelva a intentar.',
                    'error'
                );
            }).then((response) => {
                Swal('Éxito', response.detail , 'success')
                .then(() => {
                    dispatch({type: CLEAN_DATA_SURGERY});
                    dispatch(push(`/detalle-cirugia/${surgery_id}`))
                })
            }).finally(()=>{
                dispatch({type: SET_END_SURGERY_LOADER, end_surgery_loader: false});
            })
        }
    });
}

const getReport = (surgery_id) => (dispatch, getStore) => {
    api.get(`${url}/getReport`, {surgery_id}).then((response) => {
        console.log("data recibida: ", response);
        dispatch({type:SET_REPORT_SURGERY, surgery_report: response})
    }).catch((error) => {
        Swal(
            'Error',
            error.detail || 'Ha ocurrido un error, por favor vuelva a intentar.',
            'error'
        );
    })
}

const limpiarProductosCirugia = () => (dispatch) => {
    dispatch({type: CLEAN_DATA_SURGERY});
}

const showTooltipModal = (value, data=null) => (dispatch) => {
    console.log("Data modal tooltip: ", data);
    if(data != null){
        dispatch({type: SET_SURGERY_TOOLTIP_INFO, tooltip_info: data});
    }
    dispatch({type:SET_SURGERY_SHOW_TOOLTIP_MODAL, modal_tooltip_show: value});
}

const limpiarBuscadorServicios = () => (dispatch) => {
    dispatch({type: SET_BUSCADOR_SERVICE, buscador_service: ""});
}

export const actions = {
    listar,
    detail,
    create,
    update,
    week_update,
    search,
    filtro,
    destroy,
    getDoctors,
    detailPatient,
    setUpdateData,
    schedule_create,
    createInitialize,
    miniCreateInitialize,
    createInitializePatient,
    changeCreateInitializeData,
    listarServicios,
    searchService,
    addServiceToList,
    removeServiceToList,
    upDownCant,
    performSurgery,
    upDownCantInSurgery,
    addServiceToListInSurgery,
    toggleModalServicios,
    removeServiceToListInSurgery,
    setObservations,
    endSurgery,
    addServiceToListDiff,
    addServiceToListDiffInSurgery,
    limpiarProductosCirugia,
    getReport,
    setCant,
    setCantInSurgery,
    showTooltipModal,
    limpiarBuscadorServicios
};

export const reducer = {
    [CLEAN_DATA_SURGERY]: (state) => {
        return{
            ...state,
            services_list: [],
            updateData: {},
        }
    },
    [GROUP_BILL]: (state) => {
        const arreglo = state.services_perform_surgery;
        const result = groupBy(arreglo, (a) => a.financial_entity);

        let facturas = [];
        let cont = 0;
        Object.entries(result).forEach((element) => {
            let financial_entity = { id: 0 , name: element[0] }
            let dataBilling = {
                    nit: "",
                    name: "",
                    correlative: null,
                    code: "",
                    state: 0,
                    id: 0,
                    fel_status:0,
                    fel_error: '',
                    serie:'',
                    numero:'',
                    numeroAutorizacion:'',
                    total_movement:0,
                    total_paid: 0,
                    retention: false,
                    retention_amount: 0,
                };

            let verify_datos_facturacion = false;

            let services = [];
            element[1].forEach((service, key) => {
                if(key === 0){
                    financial_entity.id = service.financial_entity;
                }

                if(!verify_datos_facturacion && service.verify_datos_facturacion !== null){
                    dataBilling.name = '';
                    dataBilling.nit = 'CF';
                    verify_datos_facturacion = true;
                }


                let obj = {
                    service_name: service.name,
                    service: service.id,
                    price: service.price,
                    quantity : service.quantity,
                    discount : 0,
                    used: service.used,
                    initial: service.initial,
                    discount_authorization: false,
                    accumulated_discount: 0,//ADD
                    //subtotal : service.total,
                };

                services.push(obj);
            });

            const calculate = { }

            const dataPayments = { };
            facturas.push({ financial_entity, services, dataPayments, calculate, dataBilling });
            cont++;
        })

        return{
            ...state,
            services_perform_surgery_ordered: facturas
        }
    },

    [SET_SERVICES_PERFORM_SURGERY]: (state, { services_perform_surgery }) => {
        return {...state, services_perform_surgery }
    },
    [SET_SERVICES_LIST]: (state, { services_list }) => {
        return {...state, services_list }
    },
    [LOADER_SURGERY]: (state, { cargando }) => {
        return {...state, cargando }
    },
    [SET_DATA_SURGERY]: (state, { data }) => {
        return {...state, data }
    },
    [SET_PAGE_SURGERY]: (state, { page }) => {
        return {...state, page }
    },
    [SET_RESULT_SURGERY]: (state, { result }) => {
        return {...state, result }
    },
    [SET_UPDATE_DATA_SURGERY]: (state, { updateData }) => {
        return {...state, updateData }
    },
    [SET_BUSCADOR_SURGERY]: (state, { buscador }) => {
        return {...state, buscador }
    },
    [SET_FILTRO_SURGERY]: (state, { filtro_appointments }) => {
        return {...state, filtro_appointments }
    },
    [SET_INITIALIZE_FORM_DATA]: (state, { date, end, start }) => {
        return {...state, date, end, start }
    },
    [SET_APPOINTMENTS_SURGERY]: (state, {appointments}) => {
        return {...state, appointments }
    },
    [SET_OVERLAPS_SURGERY]: (state, {overlaps}) => {
        return {...state, overlaps }
    },
    [SET_DOCTORS]: (state, {doctors}) =>{
        return {...state, doctors}
    },

    [SET_BUSCADOR_SERVICE]: (state, { buscador_service }) => {
        return {...state, buscador_service }
    },
    [LOADER_SERVICE]: (state, { cargando }) => {
        return {...state, cargando_services: cargando }
    },
    [SET_DATA_SERVICE]: (state, { data }) => {
        return {...state, data_service : data }
    },
    [SET_PAGE_SERVICE]: (state, { page }) => {
        return {...state, page_service: page }
    },
    [SET_MODAL_SERVICE]: (state, { modal_service }) => {
        return {...state, modal_service }
    },
    [SET_SURGERY_INFO]: (state, { surgery_info }) => {
        return {...state, surgery_info }
    },
    [SET_OBSERVATIONS]: (state, { observations }) => {
        return {...state, observations }
    },
    [SET_CHECK_CASHBOX_IN_SURGERY]: (state, { openCashBox }) => {
        return {...state, openCashBox }
    },
    [SET_REPORT_SURGERY]: (state, { surgery_report }) => {
        return {...state, surgery_report }
    },

    [SET_SURGERY_SHOW_TOOLTIP_MODAL]: (state, {modal_tooltip_show}) =>{
        return {...state, modal_tooltip_show}
    },
    [SET_SURGERY_TOOLTIP_INFO]: (state, {tooltip_info}) =>{
        return {...state, tooltip_info}
    },
    [SET_END_SURGERY_LOADER]: (state, {end_surgery_loader}) =>{
        return {...state, end_surgery_loader}
    }
}

export const initialState = {
    doctors: [],
    cargando: false,
    // page: 1,
    // result: [],
    data: [],
    buscador: '',
    filtro_appointments: null,
    updateData: {},
    date: moment(new Date()).format("YYYY-MM-DD"),
    end: {value:10.5, label:'10:30'},
    start: {value:10.0, label:'10:00'},
    appointments: [],
    overlaps: [],

    services_list: [],

    data_service: {
        count: 0,
        next: null,
        previous: null,
        results: [],
    },

    cargando_services: false,
    page_service: 1,
    buscador_service: '',
    filtro_service: null,
    modal_service: false,

    services_perform_surgery: [],

    surgery_info: null,
    observations: '',

    openCashBox: null,
    services_perform_surgery_ordered: null,

    surgery_report: null,
    idReferentCategory: 0,

    modal_tooltip_show: false,
    tooltip_info: null,

    end_surgery_loader: false,
};

export default handleActions(reducer, initialState)
