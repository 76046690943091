import { api } from 'api';
import moment from 'moment';
import { handleActions } from 'redux-actions';
const URL = 'reporte/promedio-procedimientos';
const LOADER_REPORTE = 'LOADER_REPORTE';
const SET_FINAL_DATE_REPORTE = 'SET_FINAL_DATE_REPORTE';
const SET_INITIAL_DATE_REPORTE = 'SET_INITIAL_DATE_REPORTE';
const SET_DATA_REPORTE_P = 'SET_DATA_REPORTE_P';


const listar = () => (dispatch, getStore) => {
    dispatch({type: LOADER_REPORTE, cargado: true });
    const store = getStore();
    const search = store.procedimientos_promedio.buscador;
    const filtro = store.procedimientos_promedio.filtro;

    const initial_date = store.procedimientos_promedio.initial_date;
    const final_date = store.procedimientos_promedio.final_date;
    let params = {search, initial_date, final_date};
    if(filtro){
        params[''] = filtro
    }
    api.get(`${URL}/getProcedimientos`, params).then((resp) => {
        dispatch({type: SET_DATA_REPORTE_P, data: resp});
    }).catch((error) => {
        console.log('resp: ', error);
    }).finally(() => {
        dispatch({ type: LOADER_REPORTE, cargado: false });
    })
}

const setFinalDate = (value) => (dispatch) => {
    dispatch({type: SET_FINAL_DATE_REPORTE, final_date: value});
    // dispatch(listar());
};

const setInitialDate = (value) => (dispatch) => {
    dispatch({type: SET_INITIAL_DATE_REPORTE, initial_date: value});
    // dispatch(listar());
};


export const actions = {
    listar,
    setFinalDate,
    setInitialDate
};


export const reducer = {
    [LOADER_REPORTE]: (state, { cargado }) => {
        return { ...state, cargado }
    },
    [SET_FINAL_DATE_REPORTE]: (state, {final_date}) => {
        return {...state, final_date }
    },
    [SET_INITIAL_DATE_REPORTE]: (state, {initial_date}) => {
        return {...state, initial_date }
    },
    [SET_DATA_REPORTE_P]: (state, {data}) => {
        return {...state, data}
    }
};


export const initialState = {
    cargado: false,
    page: 1,
    data: [],
    initial_date: moment().date(1).format('DD/MM/YYYY'),
    final_date: moment().format('DD/MM/YYYY'),
};

export default handleActions(reducer, initialState);
