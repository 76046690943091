const url = 'roles';
import { api } from 'api';
import moment from "moment";
import Swal from 'sweetalert2';
import { push } from 'react-router-redux';
import { goBack } from 'react-router-redux';
import { initialize as initializeForm } from 'redux-form';
import { createActions, handleActions } from 'redux-actions';

const LOADER_RECEIVABLE = 'LOADER_RECEIVABLE';
const SET_DATA_RECEIVABLE = 'SET_DATA_RECEIVABLE';
const SET_PAGE_RECEIVABLE = 'SET_PAGE_RECEIVABLE';
const SET_UPDATE_DATA_RECEIVABLE = 'SET_UPDATE_DATA_RECEIVABLE';
const SET_BUSCADOR_RECEIVABLE = 'SET_BUSCADOR_RECEIVABLE';
const SET_FILTRO_RECEIVABLE = 'SET_FILTRO_RECEIVABLE';
const SET_FINAL_DATE = 'SET_FINAL_DATE';
const SET_INICIAL_DATE = 'SET_INICIAL_DATE';

const listar = (page = 1) =>  (dispatch, getStore) => {
    dispatch({type: LOADER_RECEIVABLE, cargando: true});
    const store = getStore();
    const search = store.receivable.buscador;
    const filtro = store.receivable.filtro_receivable;
    const initial_date = store.receivable.initial_date;
    const final_date = store.receivable.final_date;
    let params = {page, search, initial_date, final_date };
    if(filtro){
        params['estado'] = filtro
    }

    api.get(`movements/getReceivableMovements`, params).catch((error) => {
        dispatch({type: LOADER_RECEIVABLE, cargando: false});
        Swal(
            'Error',
            error.detail || 'Ha ocurrido un error, por favor vuelva a intentar.',
            'error'
        );
    }).then((data) => {
        if(data){
            dispatch({type: SET_DATA_RECEIVABLE, data});
            dispatch({type: SET_PAGE_RECEIVABLE, page});
        }
        dispatch({type: LOADER_RECEIVABLE, cargando: false});
    })
};


const search = (search) => (dispatch) => {
    dispatch({type: SET_BUSCADOR_RECEIVABLE, buscador: search});
    dispatch(listar(1));
};

const filtro = (filtro) => (dispatch, getStore) => {
    if (filtro == 0){
        filtro = null
    }
    dispatch({type: SET_FILTRO_RECEIVABLE, filtro_receivable: filtro});
    dispatch(listar(1));
};

const set_initial_date = (value) => (dispatch) => {
    dispatch({type: SET_INICIAL_DATE, initial_date: value});
    //dispatch(listar());
}
const set_final_date = (value) => (dispatch) => {
    dispatch({type: SET_FINAL_DATE, final_date: value});
    //dispatch(listar());
}

export const actions = {
    listar,
    search,
    filtro,
    set_final_date,
    set_initial_date
};

export const reducer = {
    [SET_INICIAL_DATE]: (state, { initial_date }) => {
        return{
            ...state,
            initial_date
        }
    },
    [SET_FINAL_DATE]: (state, { final_date }) => {
        return{
            ...state,
            final_date
        }
    },
    [LOADER_RECEIVABLE]: (state, { cargando }) => {
        return {...state, cargando }
    },
    [SET_DATA_RECEIVABLE]: (state, { data }) => {
        return {...state, data }
    },
    [SET_PAGE_RECEIVABLE]: (state, { page }) => {
        return {...state, page }
    },
    [SET_UPDATE_DATA_RECEIVABLE]: (state, { updateData }) => {
        return {...state, updateData }
    },
    [SET_BUSCADOR_RECEIVABLE]: (state, { buscador }) => {
        return {...state, buscador }
    },
    [SET_FILTRO_RECEIVABLE]: (state, { filtro_receivable }) => {
        return {...state, filtro_receivable }
    },
};

export const initialState = {
    cargando: false,
    page: 1,
    data: {
        count: 0,
        next: null,
        previous: null,
        results: [],
    },
    buscador: '',
    filtro_receivable: null,
    updateData: {},
    initial_date: moment().date(1).format("DD/MM/YYYY"),
    final_date: moment().format("DD/MM/YYYY"),
};

export default handleActions(reducer, initialState)
