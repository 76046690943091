import { connect } from 'react-redux';
import Institution from './InstitutionCrear';
import { actions } from '../../../redux/modules/institution';
import {resetStore} from "../../../redux/modules/login";

const ms2p = state => ({
    ...state.institution,
});

const md2p = { ...actions, resetStore };

export default connect(ms2p, md2p)(Institution);
