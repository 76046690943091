import { connect } from 'react-redux';
import _ from 'lodash';

import { municipios } from 'variables';
import Patient from './PatientCreate';
import { actions } from '../../../redux/modules/patients';
import {resetStore} from "../../../redux/modules/login";


const ms2p = (state) => {
    let _municipios = [];
    try {
        const { departamento } = state.form.patients.values;
        if (!!departamento) {
            _municipios = _.filter(municipios, {departamento: departamento.value});
        }
    } catch (e) {
        // fails gracefully
    }
    return {
        ...state.patients,
        municipios: _municipios,
    };
};

const md2p = { ...actions, resetStore };

export default connect(ms2p, md2p)(Patient);
