import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import Table from '../Utils/Grid';
import { BreakLine } from '../Utils/tableOptions';
import HeaderSimple from '../Utils/Header/HeaderSimple';
import ToolbarSimple from '../Utils/Toolbar/ToolbarSimple';
import { activeFormatter } from '../Utils/Acciones/Acciones';

class BankAccounts extends Component {
    componentWillUnmount() {
        this.props.resetStore();
    }

    componentWillMount() {
        this.props.listar()
    }

    render() {
        const {
            listar,
            detail,
            create,
            update,
            destroy,
            cargando,
            page,
            data,
            isNavBarOpen,
        } = this.props;

        return (
            <div className={classNames('content', { 'content-min': isNavBarOpen })}>

                {/* <div className="d-inline-flex w-100">
                    <div className="row-fluid w-50 tab-background-blue">
                        <a href="/#/banks" className="d-flex justify-content-center align-items-center tab-active tab-br-tr">
                            LISTADO
                        </a>
                    </div>
                    <div className="row-fluid w-50 tab-background">
                        <a href="/#/pending" className="d-flex justify-content-center align-items-center tab tab-br-bl">
                            PENDIENTES
                        </a>
                    </div>
                </div> */}

                <div className="px-3 py-4 row-fluid w-100">
                    {/* <HeaderSimple
                        texto={"Agregar número de cuenta"}
                        ruta={"/bank-accounts/create"}
                    /> */}
                    <div className="table-decoration">
                        <div className="top-title-toolbar">
                            <ToolbarSimple
                                titulo="LISTA DE CUENTAS BANCARIAS"
                                buscar={this.props.search}
                                buscador={this.props.buscador}
                                autofocus
                            />
                        </div>
                        <Table
                            onPageChange={listar}
                            data={data}
                            loading={cargando}
                            page={page}
                        >
                            <TableHeaderColumn
                                tdStyle={BreakLine}
                                thStyle={BreakLine}
                                dataField="bank_name" dataSort>Banco</TableHeaderColumn>
                            <TableHeaderColumn
                                tdStyle={BreakLine}
                                thStyle={BreakLine}
                                dataField="account_number" dataSort>No. de cuenta</TableHeaderColumn>
                            <TableHeaderColumn
                                tdStyle={BreakLine}
                                thStyle={BreakLine}
                                dataField="owner" dataSort>Nombre de cuenta</TableHeaderColumn>
                            <TableHeaderColumn
                                dataField="id"
                                isKey={true}
                                dataAlign="center"
                                dataFormat={activeFormatter({ ver_movimientos: '/banks' })}>Acciones</TableHeaderColumn>
                        </Table>
                    </div>
                </div>
            </div>
        )
    }
}

export default BankAccounts;
