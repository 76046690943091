import React, { Component } from 'react';
import { RenderCurrency } from 'Utils/renderField/renderReadField';
import NumberFormat from 'react-number-format';

import MyModal from '../Utils/myModal';
import NewFormCoupon from './FormCouponNew';
class ProductInCar extends Component {
    state = {
        toggle: false,
        descuento: 0,
        descuentoValor: 0
    }

    handleChange = (e) => {
        const value = e.target.value;
        const name = e.target.name;
        this.setState({ [name]: value });
    }


    setToggle = () => {
        const stateToggle = this.state.toggle;
        this.setState({toggle: !stateToggle});
    }

    componentWillMount = () => {
        this.setState({ descuento: (this.props.product.descuento * 100)});
        this.setState({ descuentoValor: this.props.product.descuentoValor});
    }

    render(){
        let classInputDicount = this.props.product.discount_authorization ? " my-input-error" : "";

        return(
            <React.Fragment>
            <tr className="row">
                <td className="col-sm-1 " style={{width:"1% !important"}}>
                     {
                        this.state.toggle ?
                            <img
                                className="card-img-top cursor-pointer"
                                onClick={() => this.setToggle()}
                                src={require("../../../../assets/img/icons/up-arrow-icon.png")}
                                alt="CashBox image"
                            />
                        :
                            <img
                                className="card-img-top cursor-pointer"
                                onClick={() => this.setToggle()}
                                src={require("../../../../assets/img/icons/down-arrow-icon.png")}
                                alt="CashBox image"
                            />
                    }
                </td>
                <td className="col-sm-3"><span className="table-span">{this.props.product.name}</span></td>
                <td className="col-sm-4"><span className="">{this.props.upDownCant(this.props.i, this.props.product.cant)}</span></td>
                <td className="col-sm-2">
                    <span className="table-span"><RenderCurrency value={this.props.product.total} /></span>
                </td>
                <td className="col-sm-2">
                    <button onClick={()=>this.props.removeProductToCart(this.props.i)} className="circle-button-delete">
                        <img className="card-img-top d-block" src={require("../../../../assets/img/icons/delete-patient-icon.png")} alt="Delete icon" />
                    </button>
                </td>
            </tr>
            { (this.state.toggle) &&
                <tr>
                    <td colSpan={5} className="b-516060">
                        <div className="d-flex flex-column justify-content-center align-items-center p-2">
                            <div className="d-flex justify-content-center align-items-center">
                                Descuento de &nbsp;
                                Q&nbsp;<NumberFormat   prefix='Q '
                                    value={(this.props.product.descuentoValor) != 0 ? this.props.product.descuentoValor : ''}
                                    onValueChange={(values) => {
                                        this.setState({descuentoValor: values.formattedValue})
                                    }}
                                    className="my-number-format"
                                    onChange={(e) => this.props.applyDiscountValue(e,this.props.i)}
                                />
                                &nbsp; O &nbsp;
                                <NumberFormat   suffix=' %'
                                    value={(this.props.product.descuento) != 0 ? (this.props.product.descuento * 100).toFixed(2) : ''}
                                    onValueChange={(values) => {
                                        this.setState({descuento: values.floatValue})
                                        //this.setState({descuentoValor: (values.floatValue * this.props.product.total)})
                                    }}
                                    className={"my-number-format" + classInputDicount}
                                    onChange={(e) => this.props.applyDiscount(e,this.props.i)}
                                />&nbsp;%
                                &nbsp; &nbsp;

                                <MyModal isOpen={false}
                                    title=""
                                    formId="setCouponCode"
                                    textButton="Cupon"
                                    classToggleButton="d-block h-100 button-down"
                                    body={
                                            <NewFormCoupon onSubmit={this.props.setCouponCode} index={this.props.i} />
                                    }
                                />

                            </div>
                            {(this.props.product.discount_authorization) &&
                                <span className="my-alert-message">Se necesita autorización para poder aplicar el descuento</span>
                            }
                        </div>
                    </td>
                </tr>

            }
            {this.props.product.coupons.map((coupon, i) =>
                <tr className="row" key={i}>
                    <td className="col-sm-1 " style={{width:"1% !important"}}>
                    </td>
                    <td colSpan={2} className="col-sm-7 cupon-row">
                        Descuento por cupón &nbsp;
                        <span className="font-weight-bold">{coupon.code}</span>
                    </td>
                    <td className="col-sm-2 cupon-row">
                        {/*<span><RenderCurrency value={coupon.amount} /></span>*/}
                        <span><RenderCurrency value={coupon.amount_label} /></span>
                    </td>
                    <td className="col-sm-2">
                        <button onClick={()=>this.props.removeCoupon(this.props.i, coupon.code)} className="circle-button-delete">
                            <img className="card-img-top d-block" src={require("../../../../assets/img/icons/delete-patient-icon.png")} alt="Delete icon" />
                        </button>
                    </td>
                </tr>
            )}

            </React.Fragment>
        );
    }
}

export default ProductInCar;
