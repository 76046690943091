const url = 'reporte/productos-consignacion';
import { api } from 'api';
import Swal from 'sweetalert2';
import { push } from 'react-router-redux';
import { goBack } from 'react-router-redux';
import { initialize as initializeForm } from 'redux-form';
import { createActions, handleActions } from 'redux-actions';
import moment from "moment";

const LOADER_CONSIGNMENT_PRODUCTS = 'LOADER_CONSIGNMENT_PRODUCTS';
const SET_DATA_CONSIGNMENT_PRODUCTS = 'SET_DATA_CONSIGNMENT_PRODUCTS';
const SET_PAGE_CONSIGNMENT_PRODUCTS = 'SET_PAGE_CONSIGNMENT_PRODUCTS';
const SET_BUSCADOR_CONSIGNMENT_PRODUCTS = 'SET_BUSCADOR_CONSIGNMENT_PRODUCTS';
const SET_FILTRO_CONSIGNMENT_PRODUCTS = 'SET_FILTRO_CONSIGNMENT_PRODUCTS';
const SET_INICIAL_DATE_CONSIGNMENT_PRODUCTS = 'SET_INICIAL_DATE_CONSIGNMENT_PRODUCTS';
const SET_FINAL_DATE_CONSIGNMENT_PRODUCTS = 'SET_FINAL_DATE_CONSIGNMENT_PRODUCTS';
const SET_ENTRY_MOVEMENT_REPORT = 'SET_ENTRY_MOVEMENT_REPORT';

const listar = (page = 1) =>  (dispatch, getStore) => {
    dispatch({type: LOADER_CONSIGNMENT_PRODUCTS, cargando: true});
    const store = getStore();
    const search = store.reporte_consignacion.buscador;
    const filtro = store.reporte_consignacion.filtro;
    const initial_date = store.reporte_consignacion.initial_date;
    const final_date = store.reporte_consignacion.final_date;

    let params = {page, search, initial_date, final_date};
    if(filtro){
        params[''] = filtro
    }

    api.get(`${url}/getProductosConsignacion`, params).catch((error) => {
        dispatch({type: LOADER_CONSIGNMENT_PRODUCTS, cargando: false});
        Swal(
            'Error',
            error.detail || 'Ha ocurrido un error, por favor vuelva a intentar.',
            'error'
        );
    }).then((data) => {
        if(data){
            console.log("Productos en consignación: ", data);
            dispatch({type: SET_DATA_CONSIGNMENT_PRODUCTS, data});
            dispatch({type: SET_PAGE_CONSIGNMENT_PRODUCTS, page});
        }
        dispatch({type: LOADER_CONSIGNMENT_PRODUCTS, cargando: false});
    })
};

const entryMovementReport = (entry_movement_id) => (dispatch) => {
    api.get(`${url}/getEntryMovementReport`, {entry_movement_id}).catch((error) => {
        dispatch({type: LOADER_CONSIGNMENT_PRODUCTS, cargando: false});
        Swal(
            'Error',
            error.detail || 'Ha ocurrido un error, por favor vuelva a intentar.',
            'error'
        );
    }).then((data) => {
        if(data){
            console.log("Entry movement: ", data);
            dispatch({type: SET_ENTRY_MOVEMENT_REPORT, entry_movement: data});
        }
        dispatch({type: LOADER_CONSIGNMENT_PRODUCTS, cargando: false});
    })
}

const search = (search) => (dispatch) => {
    dispatch({type: SET_BUSCADOR_CONSIGNMENT_PRODUCTS, buscador: search});
    dispatch(listar(1));
};

const filtro = (filtro) => (dispatch, getStore) => {
    if (filtro == 0){
        filtro = null
    }
    dispatch({type: SET_FILTRO_CONSIGNMENT_PRODUCTS, filtro});
    dispatch(listar(1));
};

const setFinalDate = (value) => (dispatch) => {
    dispatch({type: SET_FINAL_DATE_CONSIGNMENT_PRODUCTS, final_date: value});
    // dispatch(listar(1));
}

const setInitialDate = (value) => (dispatch) => {
    dispatch({type: SET_INICIAL_DATE_CONSIGNMENT_PRODUCTS, initial_date: value});
    // dispatch(listar(1));
}
// ...

export const actions = {
    // Ingresos
    listar,
    search,
    filtro,
    setFinalDate,
    setInitialDate,
    entryMovementReport,
};

export const reducer = {
    [LOADER_CONSIGNMENT_PRODUCTS]: (state, { cargando }) => {
        return {...state, cargando }
    },
    [SET_DATA_CONSIGNMENT_PRODUCTS]: (state, { data }) => {
        return {...state, data }
    },
    [SET_PAGE_CONSIGNMENT_PRODUCTS]: (state, { page }) => {
        return {...state, page }
    },
    [SET_BUSCADOR_CONSIGNMENT_PRODUCTS]: (state, { buscador }) => {
        return {...state, buscador }
    },
    [SET_FILTRO_CONSIGNMENT_PRODUCTS]: (state, { filtro }) => {
        return {...state, filtro }
    },
    [SET_INICIAL_DATE_CONSIGNMENT_PRODUCTS]: (state, { initial_date }) => {
        return{
            ...state,
            initial_date
        }
    },
    [SET_FINAL_DATE_CONSIGNMENT_PRODUCTS]: (state, { final_date }) => {
        return{
            ...state,
            final_date
        }
    },
    [SET_ENTRY_MOVEMENT_REPORT]: (state, { entry_movement }) => {
        return{
            ...state,
            entry_movement
        }
    },
};

export const initialState = {
    cargando: false,
    page: 1,
    data: {
        count: 0,
        next: null,
        previous: null,
        results: [],
    },
    buscador: '',
    filtro: null,

    initial_date: moment().date(1).format("DD/MM/YYYY"),
    final_date: moment().format("DD/MM/YYYY"),
    entry_movement: null,
};

export default handleActions(reducer, initialState)
