import { connect } from "react-redux";
import { actions } from "../../../redux/modules/dashboard";
import Home from "./Home";

const ms2p = (state, op) => {
    return {
        ...state.login,
        dashboard: state.dashboard,
    };
};
const md2p = {
    ...actions,
};

export default connect(ms2p, md2p)(Home);
