const url = 'payback';
import { api } from 'api';
import Swal from 'sweetalert2';
import { push } from 'react-router-redux';
import { goBack } from 'react-router-redux';
import { initialize as initializeForm } from 'redux-form';
import { createActions, handleActions } from 'redux-actions';
import moment from "moment";

const LOADER_PAYBACK = 'LOADER_PAYBACK';
const SET_DATA_PAYBACK = 'SET_DATA_PAYBACK';
const SET_PAGE_PAYBACK = 'SET_PAGE_PAYBACK';
const SET_UPDATE_DATA_PAYBACK = 'SET_UPDATE_DATA_PAYBACK';
const SET_BUSCADOR_PAYBACK = 'SET_BUSCADOR_PAYBACK';
const SET_FILTRO_PAYBACK = 'SET_FILTRO_PAYBACK';

const LOADER_PAYBACK_PAID = 'LOADER_PAYBACK_PAID';
const SET_DATA_PAYBACK_PAID = 'SET_DATA_PAYBACK_PAID';
const SET_PAGE_PAYBACK_PAID = 'SET_PAGE_PAYBACK_PAID';
const SET_BUSCADOR_PAYBACK_PAID = 'SET_BUSCADOR_PAYBACK_PAID';
const SET_FILTRO_PAYBACK_PAID = 'SET_FILTRO_PAYBACK_PAID';
const SET_FINAL_DATE = 'SET_FINAL_DATE';
const SET_INICIAL_DATE = 'SET_INICIAL_DATE';

/**
 * Función utilizada para obtener los payback pendientes de pagar
*/
const listar = (page = 1) =>  (dispatch, getStore) => {
    dispatch({type: LOADER_PAYBACK, cargando: true});
    const store = getStore();
    const search = store.payback.buscador;
    const filtro = store.payback.filtro_payback;
    let params = {page, search};
    if(filtro){
        params[''] = filtro
    }

    api.get(`${url}/getPayback`, params).catch((error) => {
        dispatch({type: LOADER_PAYBACK, cargando: false});
        Swal(
            'Error',
            error.detail || 'Ha ocurrido un error, por favor vuelva a intentar.',
            'error'
        );
    }).then((data) => {
        console.log("Datos payback: ", data);

        if(data){
            dispatch({type: SET_DATA_PAYBACK, data});
            dispatch({type: SET_PAGE_PAYBACK, page});
        }
        dispatch({type: LOADER_PAYBACK, cargando: false});
    })
};

/**
 * Función utilizada para obtener los payback que ya fueron pagados
*/
const listarPaybackPagado = (page_paid = 1) =>  (dispatch, getStore) => {
    dispatch({type: LOADER_PAYBACK_PAID, cargando_pagado: true});
    const store = getStore();
    const search = store.payback.buscador_pagado;
    const filtro = store.payback.filtro_payback_paid;
    const initial_date = store.payback.initial_date;
    const final_date = store.payback.final_date;

    let params = { page: page_paid, search, initial_date, final_date };

    if(filtro){
        params[''] = filtro
    }

    api.get(`${url}/getPaybackPaid`, params).catch((error) => {
        dispatch({type: LOADER_PAYBACK_PAID, cargando: false});
        Swal(
            'Error',
            error.detail || 'Ha ocurrido un error, por favor vuelva a intentar.',
            'error'
        );
    }).then((data) => {
        console.log("Datos payback pagado: ", data);

        if(data){
            dispatch({type: SET_DATA_PAYBACK_PAID, data_paid: data});
            dispatch({type: SET_PAGE_PAYBACK_PAID, page_paid});
        }
        dispatch({type: LOADER_PAYBACK_PAID, cargando: false});
    })
};

/**
 * Función utilizada para realizar el pago de un payback
 * */
const create = () => (dispatch, getStore) => {
    const formData = getStore().form.formPaymentPayback.values;
    console.log("Form data: ", formData);

    dispatch({type: LOADER_PAYBACK, cargando: true})

    api.post(`${url}/payPayback`, formData).then((data) => {
        console.log("data post: ", data);
        dispatch({type: LOADER_PAYBACK, cargando: false})
        Swal('Éxito', 'Payback pagado correctamente.', 'success')
        .then(() => {
            dispatch(listar());
        })
    }).catch((error) => {
        dispatch({type: LOADER_PAYBACK, cargando: false})
        Swal(
            'Error',
             error.detail || 'Ha ocurrido un error, por favor vuelva a intentar.',
            'error'
        );
    }).finally(() => {
        dispatch({type: LOADER_PAYBACK, cargando: false})
    });
};

const cancelPaybackPaid = (payback_id) => (dispatch, getStore) => {
    Swal.fire({
        title: '¿Está seguro(a) que desea anular el pago?',
        type: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#aaa',
        confirmButtonText: 'Aceptar',
        cancelButtonText: 'Cancelar',
        reverseButtons: true
    }).then((result) => {
        if(result.value){
            api.post(`${url}/cancelPayback`, {payback_id}).then((data) => {
                dispatch({type: LOADER_PAYBACK_PAID, cargando: false})
                Swal('Éxito', data.detail, 'success')
                .then(() => {
                    dispatch(listarPaybackPagado());
                })
            }).catch((error) => {
                dispatch({type: LOADER_PAYBACK_PAID, cargando: false})
                Swal(
                    'Error',
                    error.detail || 'Ha ocurrido un error, por favor vuelva a intentar.',
                    'error'
                );
            }).finally(() => {
                dispatch({type: LOADER_PAYBACK_PAID, cargando: false})
            });
        }
    });
}

const search = (search) => (dispatch) => {
    dispatch({type: SET_BUSCADOR_PAYBACK, buscador: search});
    dispatch(listar(1));
};

const searchPaid = (search) => (dispatch) => {
    dispatch({type: SET_BUSCADOR_PAYBACK_PAID, buscador_pagado: search});
    dispatch(listarPaybackPagado(1));
};

const filtro = (filtro) => (dispatch, getStore) => {
    if (filtro == 0){
        filtro = null
    }
    dispatch({type: SET_FILTRO_PAYBACK, filtro_PAYBACK: filtro});
    dispatch(listar(1));
};

const set_initial_date = (value) => (dispatch) => {
    dispatch({type: SET_INICIAL_DATE, initial_date: value});
    //dispatch(listarPaybackPagado());
}

const set_final_date = (value) => (dispatch) => {
    dispatch({type: SET_FINAL_DATE, final_date: value});
    //dispatch(listarPaybackPagado());
}

export const actions = {
    listar,
    create,
    search,
    filtro,

    listarPaybackPagado,
    searchPaid,
    cancelPaybackPaid,
    set_initial_date,
    set_final_date,
};

export const reducer = {
    [LOADER_PAYBACK]: (state, { cargando }) => {
        return {...state, cargando }
    },
    [SET_DATA_PAYBACK]: (state, { data }) => {
        return {...state, data }
    },
    [SET_PAGE_PAYBACK]: (state, { page }) => {
        return {...state, page }
    },
    [SET_UPDATE_DATA_PAYBACK]: (state, { updateData }) => {
        return {...state, updateData }
    },
    [SET_BUSCADOR_PAYBACK]: (state, { buscador }) => {
        return {...state, buscador }
    },
    [SET_FILTRO_PAYBACK]: (state, { filtro_payback }) => {
        return {...state, filtro_payback }
    },


    [LOADER_PAYBACK_PAID]: (state, { cargando_pagado }) => {
        return {...state, cargando_pagado }
    },
    [SET_DATA_PAYBACK_PAID]: (state, { data_paid }) => {
        return {...state, data_paid }
    },
    [SET_PAGE_PAYBACK_PAID]: (state, { page_paid }) => {
        return {...state, page_paid }
    },
    [SET_BUSCADOR_PAYBACK_PAID]: (state, { buscador_pagado }) => {
        return {...state, buscador_pagado }
    },
    [SET_FILTRO_PAYBACK_PAID]: (state, { filtro_payback_paid }) => {
        return {...state, filtro_payback_paid }
    },

    [SET_INICIAL_DATE]: (state, { initial_date }) => {
        return{
            ...state,
            initial_date
        }
    },

    [SET_FINAL_DATE]: (state, { final_date }) => {
        return{
            ...state,
            final_date
        }
    },
};

export const initialState = {
    cargando: false,
    page: 1,
    data: {
        count: 0,
        next: null,
        previous: null,
        results: [],
    },
    buscador: '',
    filtro_payback: null,
    updateData: {},

    //del payback por pagar:
    cargando_pagado: false,
    page_paid: 1,
    data_paid: {
        count: 0,
        next: null,
        previous: null,
        results: [],
    },
    buscador_pagado: '',
    filtro_payback_paid: null,

    initial_date: moment().date(1).format("DD/MM/YYYY"),
    final_date: moment().format("DD/MM/YYYY"),
};

export default handleActions(reducer, initialState)
