import Table from '../Utils/Grid';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { BreakLine } from '../Utils/tableOptions';
import HeaderSimple from '../Utils/Header/HeaderSimple';
import { activeFormatter }  from '../Utils/Acciones/Acciones'
import { showNestedFields } from '../Utils/MyOptions'; //Mi archivo (Ricky)

// Function to get acces to role field 'str'

function formatRole(cell){
    if (cell){
        return <span>{cell.role}</span>
    } else {
        return <span></span>
    }
}

class Users extends Component {

    componentWillMount() {
        this.props.listar()
    }
    componentWillUnmount() {
        this.props.resetStore();
    }

    render() {

        const {
            listar,
            detail,
            create,
            update,
            destroy,
        } = this.props;

        const {
            cargando,
            page,
            data
        } = this.props;

        return (
            <div className="content">
                <div className="m-auto row-fluid w-100">
                    <HeaderSimple texto={"Agregar usuario"} ruta={"/users/create"}/>
                    <div className="table-decoration">
                        <div className="top-title">
                            LISTADO DE USUARIOS
                        </div>
                        <Table
                            onPageChange={listar}
                            data={data}
                            loading={cargando}
                            page={page}
                        >
                            <TableHeaderColumn
                                tdStyle={BreakLine}
                                thStyle={BreakLine}
                                dataField="first_name" dataSort>Nombre</TableHeaderColumn>
                            <TableHeaderColumn
                                tdStyle={BreakLine}
                                thStyle={BreakLine}
                                dataField="username" dataSort>Username</TableHeaderColumn>
                            <TableHeaderColumn
                                tdStyle={BreakLine}
                                thStyle={BreakLine}
                                dataFormat= {formatRole}
                                dataField="person_user" formatExtraData="person_user__role__name" dataSort={true} dataFormat={showNestedFields}>Rol</TableHeaderColumn>
                            <TableHeaderColumn
                                dataField="id"
                                isKey={true}
                                dataAlign="center"
                                dataFormat={activeFormatter({ ver: '/users', editar: '/users', eliminar: destroy })}>Acciones</TableHeaderColumn>
                        </Table>
                    </div>
                </div>
            </div>

        )
    }
}

export default Users;
