import { api } from "api";
import Swal from 'sweetalert2';
import { push } from 'react-router-redux';
import { createAction, handleActions } from 'redux-actions';
import { initialize as initializeForm } from 'redux-form'

const INITIAL = 'INITIAL';
const SUBMIT = 'SUBMIT';
const NAME_ERROR = 'NAME_ERROR';
const PASS_ERROR = 'PASS_ERROR';
const LOGIN_LOADER = 'LOGIN_LOADER';
const SUBMIT_ERROR = 'SUBMIT_ERROR';
const SET_DATA_ME = 'SET_DATA_ME';
export const RESET_STORE = 'RESET_STORE';
const url_login = 'get-token';

export const constants = {
    SUBMIT,
};

// ------------------------------------
// Actions
// ------------------------------------
/* Funcion para simular el login, ELIMINAR */
function logInMock(dispatch) {
    dispatch({ type: LOGIN_LOADER, loader: false });
    dispatch({ type: SUBMIT, autenticado: true });
}

export const resetStore = () => ({
    type: RESET_STORE,
});

export const onSubmit = (data = {}) => (dispatch) => {
    let username = '';
    if(data.username){
        username = data.username;
    }

    dispatch({ type: LOGIN_LOADER, loader: true });

    api.post(`${url_login}`, data).then((response) => {
        if (response){
            localStorage.setItem('token', response.token);
            dispatch(push("/home"));
        }
    }).catch((error) => {
        console.log("23123123:", error)
        Swal(
            'Error',
             error.detail || 'Usuario o contraseña incorrecta, intente de nuevo.',
            'error'
        );
        dispatch(initializeForm('login', {username, input_type: 'password'}))
    })
    .finally(() => {
        dispatch({ type: LOGIN_LOADER, loader: false });
    });
};

export const getInfo = () => (dispatch) => {
    api.get(`me/getUserInfo`, {}).then((response) => {
        if (response){
            if(response.role_name === 'superuser' || response.role_name === 'superusuario'){
                response['username'] = 'Super Usuario'
            }
            dispatch({type: SET_DATA_ME, me: response})
        }
    }).catch((error) => {
        console.log("error al obtener información del usuario")
    })
};

export const dashboardVisualize = (value) => (dispatch, getStore) => {
    dispatch({ type: LOGIN_LOADER, loader: true });
    api.post(`me/dashboard_visualize`, {value}).then((response) => {
        if (response){
            let me = getStore().login.me;
            me.dashboard_visualize = response.value
            dispatch({type: SET_DATA_ME, me})
        }
    }).catch((error) => {
        console.log("Error al cambiar el estado", error)
    }).finally(()=>{
        dispatch({ type: LOGIN_LOADER, loader: false });
    })
};

export const setUserInfo = (data) => (dispatch) => {
    dispatch({type: SET_DATA_ME, me: data})
};

export const logOut = () => (dispatch) => {
    localStorage.removeItem('token');
    dispatch({ type: SUBMIT, autenticado: false });
};

export const initialLoad = createAction(INITIAL);
export const hasNameError = nameError => ({
    type: NAME_ERROR,
    nameError,
});
export const hasPassError = passError => ({
    type: PASS_ERROR,
    passError,
});

export const actions = {
    initialLoad,
    hasNameError,
    hasPassError,
    onSubmit,
    logOut,
    setUserInfo,
    getInfo,
    dashboardVisualize
};

export const reducers = {
    [INITIAL]: (state) => {
        const token = localStorage.getItem('token');
        if (token) {
            return {
                ...state,
                redirect: true,
            };
        }
        return {
            ...state,
            redirect: false,
        };
    },
    [SUBMIT]: (state, { autenticado }) => {
        return {
            ...state,
            autenticado,
        };
    },
    [NAME_ERROR]: (state, { nameError }) => {
        return {
            ...state,
            nameError,
        };
    },
    [PASS_ERROR]: (state, { passError }) => {
        return {
            ...state,
            passError,
        };
    },
    [LOGIN_LOADER]: (state, { loader }) => {
        return {
            ...state,
            loader,
        };
    },
    [SUBMIT_ERROR]: (state, { submitError }) => {
        return {
            ...state,
            submitError,
        };
    },
    [SET_DATA_ME]: (state, { me }) => {
        return {
            ...state,
            me,
        };
    },
    [RESET_STORE]: (state, { newState }) => {
        return {
            ...state,
            ...newState,
        };
    },
};

export const initialState = {
    submitError: false,
    passError: false,
    nameError: false,
    autenticado: false,
    loader: false,
    me: null,
};

export default handleActions(reducers, initialState);
