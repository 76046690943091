import React, { Component } from 'react';
import { Field, FieldArray, reduxForm,  formValueSelector } from 'redux-form';
import { validate, validators } from 'validate-redux-form';
import { renderCurrency, renderSearchSelect } from 'Utils/renderField/renderField';
import { connect } from 'react-redux'
import { Link } from 'react-router-dom';
import Spoiler from '../Utils/Spoiler';
import Table from '../Utils/Grid';
import LoadMask from '../Utils/LoadMask/LoadMask';
import { renderRemoveCard, renderCardRow, renderOrderIgssRow } from './row';
import { BreakLineCenter, BreakLine } from '../Utils/tableOptions';
import { api } from "api";
import './config';

let entities=[];
const getEntity = (search)  =>{
    return api.get("financial_entities", {search}).catch((error) => {})
        .then((data) => {
            if (data){
                entities = [];
                data.results.forEach(x=>{
                    entities.push({value: x.id, label: x.usuario_fel});
                })

                return entities;
            }
            else{
                return []
            }
        })
        .catch(e => {
            return []
        })
}
class ConfigurationForm extends Component{
    remove = (card) => {
        this.props.removeCard(card);
    }

    removeNoOrderIgss = async (order) => {
        const result = await this.props.removeNoOrderIgss(order)
        return result
    }

    render(){
        const {
            handleSubmit,
        } = this.props;

        return(
            <LoadMask loading={this.props.cargando}>
            <form onSubmit={handleSubmit} className="pb-3 pt-5 d-flex justify-content-center">
                {/*<div className="form-body-width">*/}
                <div>
                    <Spoiler show={true} label="APERTURA DE CAJA">
                        <div className="d-flex justify-content-start flex-column flex-sm-row align-items-stretch align-items-sm-center w-100">
                            <div className="m-2">
                                <div className="d-flex justify-content-start flex-column flex-sm-row align-items-stretch align-items-sm-center">
                                    <label htmlFor="rol_name" className="m-0">Monto</label>
                                </div>
                                <div className=" d-flex justify-content-start flex-column flex-sm-row align-items-stretch align-items-sm-center">
                                    <Field
                                        name="opening_amount"
                                        component={renderCurrency}
                                        className="form-control"
                                        autofocus                                        
                                    />
                                </div>
                            </div>
                        </div>
                    </Spoiler>

                    <Spoiler show={true} label="ENTIDAD FINANCIERA">
                        <div className="d-flex justify-content-start flex-column flex-sm-row align-items-stretch align-items-sm-center">
                            <Field
                                name="financial_entity"
                                valueKey="value"
                                labelKey="label"
                                label="financial_entity"
                                component={renderSearchSelect}
                                loadOptions={getEntity}
                                className="no-border"
                                className="caregory-referent-switch"
                            />
                        </div>
                    </Spoiler>

                    <Spoiler label="TARJETAS ACEPTADAS">
                        <div className="table-responsive d-flex">
                        <table className="table table-sm" id="table-tarjet">
                            <thead className="thead-tarjet">
                                <tr>
                                    <th>Tarjetas</th>
                                    <th>POS</th>
                                    <th>Cobro mensual</th>
                                    <th>Porcentaje comisión</th>
                                    <th>Acciones</th>
                                </tr>
                            </thead>
                            <tbody>
                                <FieldArray
                                name="cards"
                                component={renderCardRow}
                                props = {{ remove: this.remove }}
                                />

                                <FieldArray
                                name="removeCards"
                                component={renderRemoveCard}
                                />
                            </tbody>
                        </table>
                        </div>
                    </Spoiler>

                    <Spoiler show={true} label="NÚMEROS DE ORDEN - IGSS">
                        <div className="table-responsive d-flex">
                            <table className="table table-sm" id="table-tarjet">
                                <thead className="thead-tarjet">
                                    <tr>
                                        <th>Nombre</th>
                                        <th>Número de Orden</th>
                                        <th>Acciones</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <FieldArray
                                        name="no_orders_igss"
                                        component={renderOrderIgssRow}
                                        props={{ 
                                            remove: this.removeNoOrderIgss,
                                        }}
                                    />
                                </tbody>
                            </table>
                        </div>
                    </Spoiler>

                    <div className="d-flex justify-content-center mb-2">
                        <Link className="btn btn-secondary mx-4" to="/configuration">Cancelar</Link>
                        <button type="submit" className="btn btn-primary mx-4">Guardar</button>
                    </div>
                </div>
            </form>
            </LoadMask>

        )
    }
};

ConfigurationForm = reduxForm({
   form: 'configuration',
   enableReinitialize: true,
   validate: data => {
        return validate(data, {
            'opening_amount': validators.exists()('Campo requerido.'),
            'name': validators.exists()('Campo requerido.'),
            'no_order': validators.exists()('Campo requerido.'),
       })
   }
})(ConfigurationForm);

ConfigurationForm = connect(
    state => ({
        initialValues: state.configuration
    })
)(ConfigurationForm)

export default ConfigurationForm;
