import React, { Component } from 'react';
import classNames from 'classnames';

import Box from './Box';
import Table from '../Utils/Grid';
import ToolbarSimple from '../Utils/Toolbar/ToolbarSimple';

import './cashBox.css';

class OpenBox extends Component{
    componentWillUnmount() {
        this.props.resetStore();
    }
    componentWillMount() {
        this.props.listar();
    }

    render(){
        /*
        const {
            cargando,
            page,
            data,
        } = this.props;
        */

        const {
            page,
            data,
            tabCaja,
            openCashBox,
            addCash,
            closeBox,
            isNavBarOpen,
        } = this.props;

        return(
        <div className={classNames('content-with-tabs', { 'content-with-tabs-min': isNavBarOpen })}>
            <div className="d-inline-flex w-100">
                <div className="row-fluid w-50 tab-background">
                    <a href="/#/oppening-closing" className="d-flex justify-content-center align-items-center tab tab-br-br">
                        VENTA
                    </a>
                </div>
                <div className="row-fluid w-50 tab-background-blue">
                    <a href="/#/oppening-closing/box" className="d-flex justify-content-center align-items-center tab-active tab-br-tl">
                        CAJA
                    </a>
                </div>
            </div>

            <div className="table-decoration row mx-5 mt-5 mb-3 d-flex content-cashbox">
                    <div className="w-100 text-center my-title"><h3>Selecciona la caja que deseas abrir</h3></div>
                        {data.length > 0 ?
                            data.map((cashbox) =>
                                <Box key={cashbox.id} data={cashbox} openBox={this.props.openBox} />
                            )
                            :
                            null
                        }
                    </div>
            </div>
        );
    }
}

export default OpenBox;
