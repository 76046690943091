import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Field, FieldArray, reduxForm, formValueSelector } from 'redux-form';
import { validate, validators } from 'validate-redux-form';
import {renderSearchCreateSelect, renderField, renderSelectCustom} from "../../Utils/renderField";
import {api} from "../../../../api/api";
import {modalTextArea, render} from "../../Utils/renderField/renderField";
import { connect } from 'react-redux'
import './record_style';

const MD = 1
const QX = 2
const ALG = 3
const TRX = 4
const FAM = 5
const TRT = 6

const getRefillableMd = (search) => {
    let opciones = [];
    return api.get('refillable/getRefillable', {search, record_type: MD}).then((data) => {
        data.results.forEach(item => {
            opciones.push({
                value: item.id,
                label: item.texto
            });
        });
        return opciones;
    }).catch(()=>[]);
}

const getRefillableQx = (search) => {
    let opciones = [];
    return api.get('refillable/getRefillable', {search, record_type: QX}).then((data) => {
        data.results.forEach(item => {
            opciones.push({
                value: item.id,
                label: item.texto
            });
        });
        return opciones;
    }).catch(()=>[]);
}

const getRefillableAlg = (search) => {
    let opciones = [];
    return api.get('refillable/getRefillable', {search, record_type: ALG}).then((data) => {
        data.results.forEach(item => {
            opciones.push({
                value: item.id,
                label: item.texto
            });
        });
        return opciones;
    }).catch(()=>[]);
}

const getRefillableTrx = (search) => {
    let opciones = [];
    return api.get('refillable/getRefillable', {search, record_type: TRX}).then((data) => {
        data.results.forEach(item => {
            opciones.push({
                value: item.id,
                label: item.texto
            });
        });
        return opciones;
    }).catch(()=>[]);
}

const getRefillableFam = (search) => {
    let opciones = [];
    return api.get('refillable/getRefillable', {search, record_type: FAM}).then((data) => {
        data.results.forEach(item => {
            opciones.push({
                value: item.id,
                label: item.texto
            });
        });
        return opciones;
    }).catch(()=>[]);
}

const getRefillableTrt = (search) => {
    let opciones = [];
    return api.get('refillable/getRefillable', {search, record_type: TRT}).then((data) => {
        data.results.forEach(item => {
            opciones.push({
                value: item.id,
                label: item.texto
            });
        });
        return opciones;
    }).catch(()=>[]);
}

export const renderMD =({ fields, meta: {error, submitFailed}, edit, view}) => (
    <React.Fragment>
        {
            fields.map((field, index) => (
                <div key={index} className="d-flex flex-row justify-content-around mt-2 w-100">
                    <div className="d-flex flex-row">
                        <Field
                            name={`${field}.diagnostico`}
                            valueKey="value"
                            labelKey="label"
                            label="name"
                            component={renderSearchCreateSelect}
                            loadOptions={getRefillableMd}
                            className="no-border"
                            isDisabled={view}
                        />
                    </div>
                    <div className="d-flex flex-row">
                        <Field
                            name={`${field}.tratamiento`}
                            valueKey="value"
                            labelKey=""
                            label="name"
                            component={renderSearchCreateSelect}
                            loadOptions={getRefillableTrt}
                            className="no-border"
                            isDisabled={view}
                        />
                    </div>
                    {
                        !view &&
                            <div className="d-flex flex-row">
                                <img className="record-action-img delete-create-button" src={require("../../../../../assets/img/icons/delete-icon.png")} onClick={() => fields.remove(index)}  alt="Eliminar" />
                            </div>
                    }
                </div>
                )
            )
        }
        {/* row for the add button */}
        {
            !view &&
                <div className="d-flex flex-row justify-content-end mr-3">
                    <div className='d-flex flex-row mr-3'>
                        <img onClick={() => fields.push()} className="record-action-img add-create-button" src={require("../../../../../assets/img/icons/add-icon.png")} alt="Agregar" />
                    </div>
                </div>
        }
        {
            submitFailed && (
                <div className="invalid-feedback d-flex justify-content-center">
                    {error}
                </div>
            )
        }
    </React.Fragment>
);

export const renderQX =({ fields, meta: {error, submitFailed}, edit, view}) => (
    <React.Fragment>
        {
            fields.map((field, index) => (
                <div key={index} className="d-flex flex-row justify-content-around mt-2 w-100">
                    <div className="d-flex flex-row">
                        <Field
                            name={`${field}.cirugia`}
                            valueKey="value"
                            labelKey="label"
                            label="name"
                            component={renderSearchCreateSelect}
                            loadOptions={getRefillableQx}
                            className="no-border"
                            isDisabled={view}
                        />
                    </div>
                    {
                        !view &&
                            <div className="d-flex flex-row">
                                <img className="record-action-img delete-create-button" src={require("../../../../../assets/img/icons/delete-icon.png")} onClick={() => fields.remove(index)}  alt="Eliminar" />
                            </div>
                    }
                </div>
                )
            )
        }
        {/* row for the add button */}
        {
            !view &&
                <div className="d-flex flex-row justify-content-end mr-1">
                    <div className='d-flex flex-row mr-3'>
                        <img onClick={() => fields.push()} className="record-action-img add-create-button" src={require("../../../../../assets/img/icons/add-icon.png")} alt="Agregar" />
                    </div>
                </div>
        }
        {
            submitFailed && (
                <div className="invalid-feedback d-flex justify-content-center">
                    {error}
                </div>
            )
        }
    </React.Fragment>
);

export const renderALG =({ fields, meta: {error, submitFailed}, edit, view}) => (
    <React.Fragment>
        {
            fields.map((field, index) => (
                <div key={index} className="d-flex flex-row justify-content-around mt-2 w-100">
                    <div className="d-flex flex-row">
                        <Field
                            name={`${field}.alergia`}
                            valueKey="value"
                            labelKey="label"
                            label="name"
                            component={renderSearchCreateSelect}
                            loadOptions={getRefillableAlg}
                            className="no-border"
                            isDisabled={view}
                        />
                    </div>
                    {
                        !view &&
                            <div className="d-flex flex-row">
                                <img className="record-action-img delete-create-button" src={require("../../../../../assets/img/icons/delete-icon.png")} onClick={() => fields.remove(index)}  alt="Eliminar" />
                            </div>
                    }
                </div>
                )
            )
        }
        {/* row for the add button */}
        {
            !view &&
                <div className="d-flex flex-row justify-content-end mr-1">
                    <div className='d-flex flex-row mr-3'>
                        <img onClick={() => fields.push()} className="record-action-img add-create-button" src={require("../../../../../assets/img/icons/add-icon.png")} alt="Agregar" />
                    </div>
                </div>
        }
        {
            submitFailed && (
                <div className="invalid-feedback d-flex justify-content-center">
                    {error}
                </div>
            )
        }
    </React.Fragment>
);

export const renderTRX =({ fields, meta: {error, submitFailed}, edit, view}) => (
    <React.Fragment>
        {
            fields.map((field, index) => (
                <div key={index} className="d-flex flex-row justify-content-around mt-2 w-100">
                    <div className="d-flex flex-row">
                        <Field
                            name={`${field}.trauma`}
                            valueKey="value"
                            labelKey="label"
                            label="name"
                            component={renderSearchCreateSelect}
                            loadOptions={getRefillableTrx}
                            className="no-border"
                            isDisabled={view}
                        />
                    </div>
                    {
                        !view &&
                            <div className="d-flex flex-row">
                                <img className="record-action-img delete-create-button" src={require("../../../../../assets/img/icons/delete-icon.png")} onClick={() => fields.remove(index)}  alt="Eliminar" />
                            </div>
                    }
                </div>
                )
            )
        }
        {/* row for the add button */}
        {
            !view &&
                <div className="d-flex flex-row justify-content-end mr-1">
                    <div className='d-flex flex-row mr-3'>
                        <img onClick={() => fields.push()} className="record-action-img add-create-button" src={require("../../../../../assets/img/icons/add-icon.png")} alt="Agregar" />
                    </div>
                </div>
        }
        {
            submitFailed && (
                <div className="invalid-feedback d-flex justify-content-center">
                    {error}
                </div>
            )
        }
    </React.Fragment>
);

export const renderFAM =({ fields, meta: {error, submitFailed}, edit, view}) => (
    <React.Fragment>
        {
            fields.map((field, index) => (
                <div key={index} className="d-flex flex-row justify-content-around mt-2 w-100">
                    <div className="d-flex flex-row">
                        <Field
                            name={`${field}.diagnostico`}
                            valueKey="value"
                            labelKey="label"
                            label="name"
                            component={renderSearchCreateSelect}
                            loadOptions={getRefillableMd}
                            className="no-border"
                            isDisabled={ view }
                        />
                    </div>
                    <div className="d-flex flex-row">
                        <Field
                            name={`${field}.tratamiento`}
                            valueKey="value"
                            labelKey=""
                            label="name"
                            component={renderSearchCreateSelect}
                            loadOptions={getRefillableFam}
                            className="no-border"
                            isDisabled={ view }
                        />
                    </div>
                    {
                        !view &&
                            <div className="d-flex flex-row">
                                <img className="record-action-img delete-create-button" src={require("../../../../../assets/img/icons/delete-icon.png")} onClick={() => fields.remove(index)}  alt="Eliminar" />
                            </div>
                    }
                </div>
                )
            )
        }
        {/* row for the add button */}
        {
            !view &&
                <div className="d-flex flex-row justify-content-end mr-3">
                    <div className='d-flex flex-row mr-3'>
                        <img onClick={() => fields.push()} className="record-action-img add-create-button" src={require("../../../../../assets/img/icons/add-icon.png")} alt="Agregar" />
                    </div>
                </div>
        }
        {
            submitFailed && (
                <div className="invalid-feedback d-flex justify-content-center">
                    {error}
                </div>
            )
        }
    </React.Fragment>
);

const Form = (props) => {
    const {handleSubmit, od_nervio_optico, os_nervio_optico} = props;
    const view = window.location.href.includes("reporte");

    return (
        <form onSubmit={handleSubmit} className="d-flex flex-column">
            {!view ? <h4 className='cl-titulo'><strong>DATOS CONSULTA </strong></h4> : null}

            <div className='d-flex flex-row justify-content-between w-100'>
                <div className='d-flex flex-column w-75 justify-content-center mr-5'>
                    <label>Motivo de la consulta</label>
                    <Field name='reason' autofocus component={modalTextArea} disabled={view} className="annotations w-100" />
                </div>
                <div className='d-flex flex-column w-75 justify-content-center'>
                    <label>Historia de la enfermedad</label>
                    <Field name='history' component={modalTextArea} disabled={view} className="annotations w-100" />
                </div>
            </div>

            <div className='d-flex flex-column justify-content-between w-100 mt-4'>
                <label>Antecedentes</label>
                <div className='d-flex flex-row justify-content-start ml-2'>
                    <label style={{fontSize:18, color:'blue'}}>MD</label>
                </div>

                <div className="d-flex flex-column w-100">
                    <div className='d-flex flex-row justify-content-between ml-5'>
                        <div className='d-flex flex-row justify-content-start'><label>Diagnóstico</label></div>
                        <div className='d-flex flex-row justify-content-start'><label>Tratamiento</label></div>
                        <div className='d-flex flex-row justify-content-start'><label></label></div>
                    </div>
                    <div>
                        <FieldArray
                            name="md"
                            component={renderMD}                            
                            edit={false}
                            view={view}
                        />
                    </div>
                </div>

                <div className='d-flex flex-column w-50 justify-content-start ml-5'>
                    <label>Notas</label>
                    <Field name='md_note' component={modalTextArea} disabled={view} className="annotations w-75" />
                </div>
            </div>

            <div className='d-flex flex-column justify-content-between w-100 mt-4'>
                <label>Antecedentes</label>
                <div className='d-flex flex-row justify-content-start ml-2'>
                    <label style={{fontSize:18, color:'blue'}}>QX</label>
                </div>

                <div className='d-flex flex-row w-100'>
                    <div className="d-flex flex-column w-50">
                        <div className='d-flex flex-row justify-content-between ml-5'>
                            <div className='d-flex flex-row justify-content-start'><label>Cirugía</label></div>
                            <div className='d-flex flex-row justify-content-start'><label></label></div>
                        </div>
                        <div>
                            <FieldArray
                                name="qx"
                                component={renderQX}                                
                                edit={false}
                                view={view}
                            />
                        </div>
                    </div>
                    <div className='d-flex flex-column w-50'>
                        <label>Notas</label>
                        <Field
                            name={'qx_note'}
                            component={modalTextArea}
                            className='annotations w-75'
                            disabled={view}
                        />
                    </div>
                </div>
            </div>

            <div className='d-flex flex-column justify-content-between w-100 mt-4'>
                <div className='d-flex flex-row justify-content-start ml-2'>
                    <label style={{fontSize:18, color:'blue'}}>ALG</label>
                </div>

                <div className='d-flex flex-row w-100'>
                    <div className="d-flex flex-column w-50">
                        <div className='d-flex flex-row justify-content-between ml-5'>
                            <div className='d-flex flex-row justify-content-start'><label>Alergia</label></div>
                            <div className='d-flex flex-row justify-content-start'><label></label></div>
                        </div>
                        <div>
                            <FieldArray
                                name="alg"
                                component={renderALG}                                
                                edit={false}
                                view={view}
                            />
                        </div>
                    </div>
                    <div className='d-flex flex-column w-50'>
                        <label>Notas</label>
                        <Field
                            name={'alg_note'}
                            component={modalTextArea}
                            className='annotations w-75'
                            disabled={view}
                        />
                    </div>
                </div>
            </div>

            <div className='d-flex flex-column justify-content-between w-100 mt-4'>
                <div className='d-flex flex-row justify-content-start ml-2'>
                    <label style={{fontSize:18, color:'blue'}}>TRX</label>
                </div>

                <div className='d-flex flex-row w-100'>
                    <div className="d-flex flex-column w-50">
                        <div className='d-flex flex-row justify-content-between ml-5'>
                            <div className='d-flex flex-row justify-content-start'><label>Trauma</label></div>
                            <div className='d-flex flex-row justify-content-start'><label></label></div>
                        </div>
                        <div>
                            <FieldArray
                                name="trx"
                                component={renderTRX}                                
                                edit={false}
                                view={view}
                            />
                        </div>
                    </div>
                    <div className='d-flex flex-column w-50'>
                        <label>Notas</label>
                        <Field
                            name={'trx_note'}
                            component={modalTextArea}
                            className='annotations w-75'
                            disabled={view}
                        />
                    </div>
                </div>
            </div>

            <div className='d-flex flex-column justify-content-between w-100 mt-4'>
                <label>Antecedentes</label>
                <div className='d-flex flex-row justify-content-start ml-2'>
                    <label style={{fontSize:18, color:'blue'}}>FAM</label>
                </div>

                <div className="d-flex flex-column w-100">
                    <div className='d-flex flex-row justify-content-between ml-5'>
                        <div className='d-flex flex-row justify-content-start'><label>Diagnóstico</label></div>
                        <div className='d-flex flex-row justify-content-start'><label>Familiar</label></div>
                        <div className='d-flex flex-row justify-content-start'><label></label></div>
                    </div>
                    <div>
                        <FieldArray
                            name="fam"
                            component={renderFAM}                            
                            edit={false}
                            view={view}
                        />
                    </div>
                </div>

                <div className='d-flex flex-column w-50 justify-content-start ml-2 mb-5'>
                    <label>Notas</label>
                    <div className='d-flex flex-row w-100'>
                        <div className='d-flex flex-row w-75'>
                        <Field name='note' component={modalTextArea} className="annotations w-100" disabled={view}/>
                        </div>
                        <div className='d-flex flex-row w-25 ml-3'>
                            <button
                                type="button"
                                className='btn btn-danger'
                                onClick={()=>{props.change('note', 'DIFERIDO');}}
                            >DIFERIDO</button>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    )
};

let CrearForm = reduxForm({
    // a unique name for the form
    form: 'Record',
    validate: (data) => {
        return validate(data, {
        });
    },
})(Form);

const selector = formValueSelector('Record');

CrearForm = connect(state => {
    //const od_nervio_optico = selector(state, "od_nervio_optico");

    return {
        variable: true,
    };

})(CrearForm);

export default CrearForm;
