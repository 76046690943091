import { api } from 'api';
import Swal from 'sweetalert2';
import { handleActions } from 'redux-actions';

const url = 'configuration';
const url_cards = 'cards';
const url_no_orders_igss = 'no_orders_igss';

const LOADER_CONFIGURATION = 'LOADER_CONFIGURATION';
const SET_CONFIGURATION = 'SET_CONFIGURATION';
const SET_DATA_CARDS = 'SET_DATA_CARDS';
const SET_OPENING_AMOUNT = 'SET_OPENING_AMOUNT';
const SET_REMOVE_CARDS = 'SET_REMOVE_CARDS';
const REMOVE_CARD = 'REMOVE_CARD';
const SET_NAV_BAR_CLASS = 'SET_NAV_BAR_CLASS';

const VISANET = 1;
const CREDOMATIC = 2;

// PURE ACTIONS
export const setNavBarClass = isNavBarOpen => ({
    type: SET_NAV_BAR_CLASS,
    isNavBarOpen,
});

const removeCard = card => (dispatch) => {
    dispatch({ type: SET_REMOVE_CARDS, card });
    const index = card.id;
    dispatch({ type: REMOVE_CARD, index });
};

const removeNoOrderIgss = order => async (dispatch, getStore) => {

    const alert = await Swal({
        title: 'Confirmación',
        text: `¿Esta seguro de eliminar el número de orden ${order.name} / ${order.no_order}?`,
        type: 'question',
        showCancelButton: true,
        confirmButtonText: 'Si, eliminar',
        cancelButtonText: 'No',
        reverseButtons: true
    })

    if(alert.value == true){
        const store = getStore().configuration
        let no_orders_igss = store.no_orders_igss.filter(item => item.id!=order.id)
        store.no_orders_igss = no_orders_igss
        dispatch({ type: SET_CONFIGURATION, data: store });
    }

    return alert

}

const save = () => (dispatch, getStore) => {
    const formData = getStore().form.configuration.values;

    if (formData.financial_entity !== undefined && formData.financial_entity !== null) {
        formData.financial_entity = formData.financial_entity.value;
    }

    console.log('formData: ', formData);

    api.post(`${url_cards}`, formData).then((data) => {
        dispatch({ type: LOADER_CONFIGURATION, cargando: false });
        api.put(`${url}/1`, formData).then((data) => {
            api.post(`${url_no_orders_igss}/createGroup`, formData.no_orders_igss).then(response=> {
                Swal('Éxito', 'Configuración actualizada correctamente', 'success')
                .then(() => {
                    dispatch(getConfiguration());
                });
            }).catch((error) => {
                dispatch({ type: LOADER_CONFIGURATION, cargando: false });
                Swal(
                    'Error',
                    error ? error.detail : 'Ha ocurrido un error, por favor vuelva a intentar.',
                    'error'
                );
            });
        }).catch((error) => {
            dispatch({ type: LOADER_CONFIGURATION, cargando: false });
            Swal(
                'Error',
                error ? error.detail : 'Ha ocurrido un error, por favor vuelva a intentar.',
                'error'
            );
        });
    }).catch((error) => {
        dispatch({ type: LOADER_CONFIGURATION, cargando: false });
        Swal(
            'Error',
            error ? error.detail : 'Ha ocurrido un error, por favor vuelva a intentar.',
            'error'
        );
    }).finally(() => {
        dispatch({ type: LOADER_CONFIGURATION, cargando: false });
    });
};

// Este es el funcional...
const getConfiguration = () => (dispatch) => {
    const data = { opening_amount: 0, cards: [] };

    api.get(`${url}/getConfiguration`).then((response) => {
        data.opening_amount = response.opening_amount;

        const fin_ent = response.default_financial_entity;
        if (fin_ent != null) {
            const financial_entity = { value: fin_ent.id, label: fin_ent.usuario_fel };
            data.financial_entity = financial_entity;
        }


        api.get(`${url_cards}`).then((response) => {
            const cards = [];
            response.results.forEach((dato) => {
                const label = dato.pos == VISANET ? 'VISANET' : 'CREDOMATIC';
                const pos = { label, value: dato.pos };
                const card = {
                    id: dato.id,
                    name: dato.name,
                    pos,
                    commission_percentage: dato.commission_percentage,
                    monthly_charge: dato.monthly_collection,
                    remove: false,
                };
                cards.push(card);
            });

            data.cards = cards;

            api.get(`${url_no_orders_igss}`).then(response => {
                data.no_orders_igss = response.results

                dispatch({ type: SET_CONFIGURATION, data });
            })
        });
    });
};

export const actions = {
    save,
    getConfiguration,
    removeCard,
    removeNoOrderIgss,
};

export const reducer = {
    [LOADER_CONFIGURATION]: (state, { cargando }) => {
        return { ...state, cargando: cargando };
    },
    [SET_CONFIGURATION]: (state, { data }) => {
        return {
            ...state,
            opening_amount: data.opening_amount,
            cards: data.cards,
            no_orders_igss: data.no_orders_igss,
            financial_entity: data.financial_entity,
        };
    },
    [SET_OPENING_AMOUNT]: (state, { opening_amount }) => {
        return { ...state, opening_amount };
    },
    [SET_DATA_CARDS]: (state, { cards }) => {
        return { ...state, cards };
    },
    [REMOVE_CARD]: (state, { index }) => {
        const arreglo = state.cards.filter((item, key) => {
            return item.id !== index;
        });

        return {
            ...state,
            cards: arreglo,
        };
    },
    [SET_REMOVE_CARDS]: (state, { card }) => {
        return {
            ...state,
            removeCards: [...state.removeCards, card],
        };
    },
    [SET_NAV_BAR_CLASS]: (state, { isNavBarOpen }) => ({
        ...state,
        isNavBarOpen,
    }),
};

export const initialState = {
    cargando: false,
    opening_amount: 0,
    cards: [],
    no_orders_igss: [],
    isNavBarOpen: false,
    removeCards: [],
    financial_entity: null,
};

export default handleActions(reducer, initialState);
