import React, { Component } from 'react';
import Dashboard from './Dashboard';

const LogoProlaser = () => (
    <div
        className="m-auto row d-flex align-content-center w-100"
        style={{
            minHeight: 'calc(100vh - 60px)',
        }}
    >
        <div className="d-none d-sm-none d-md-flex d-lg-flex justify-content-center align-items-center m-auto">
            <img src={require('../../../../assets/img/logo_prolaser_gris.png')} alt="Prolaser_logo" />
        </div>
    </div>
);

class HomeContainer extends Component {
    constructor(props) {
        super(props);
        this.state = {dashboard: false}
    }

    componentWillMount() {
        // const { me, getDashboardData } = this.props;
        // if (me && me.permisos && me.permisos.dashboard) {
        //     getDashboardData();
        // }
        const { getDashboardDataCharged } = this.props;
        getDashboardDataCharged();
    }

    componentWillReceiveProps(nextProps, nextContext) {
        /*
        const { me, getDashboardData } = nextProps;
        if (me && me.permisos && me.permisos.dashboard && me.dashboard_visualize && !this.state.dashboard) {
            this.setState({dashboard:true})
            getDashboardData();            
        }
        */
    }

    render() {
        const { me } = this.props;
        return (
            <div className="content pt-3">
                { me && me.permisos && me.permisos.dashboard ? (
                    <Dashboard {...this.props} />
                ) : (
                    <LogoProlaser /> 
                ) }
            </div>
        );
    }
}

export default HomeContainer;
