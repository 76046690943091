import login, {RESET_STORE} from './modules/login';
import users from './modules/users';
import interpreter from './modules/interpreter';
import roles from './modules/roles';
import { combineReducers } from 'redux';
import services from './modules/services';
import schedule from './modules/schedule';
import patients from './modules/patients';
import referents from './modules/referent';
import cashBoxes from './modules/cashBoxes';
import { reducer as form } from 'redux-form';
import subsidiary from './modules/subsidiary';
import institution from './modules/institution';
import bankAccounts from './modules/bankAccount';
import appointments from './modules/appointments';
import scheduleLock from './modules/scheduleLock';
import serviceCategory from './modules/serviceCategory';
import referentCategory from './modules/referentCategory';
import { routerReducer as routing } from 'react-router-redux';
import oppeningClosingBox from './modules/oppeningClosingBox';
import doctor from './modules/doctor';
import sales from './modules/sales';
import configuration from './modules/configuration';
import due from './modules/due';
import entity from './modules/financialEntity';
import coupon from './modules/coupon';
import whearhouse from './modules/whearhouse';
import whearhouseAdmin from './modules/whearhouseAdmin';
import provider from './modules/provider';
import movement from './modules/movements';
import banks from './modules/banks';
import pendings from './modules/pendings';
import pos from './modules/pos';
import payment from './modules/payments';
import receivable from './modules/receivable';
import purchase_order from './modules/purchaseOrder';
import order from './modules/Order';
import entry_order from './modules/EntryOrder';
import small_box from './modules/smallBox';
import spending_type from './modules/spendingType';
import expense from './modules/expenses';
import retention_payment from './modules/retentionPayment';
import surgery from './modules/surgery';
import diagnostic_orden from './modules/diagnostic_orden';
import scheduleSurgery from './modules/scheduleSurgery';
import payback from './modules/payback';
import arqueo from './modules/arqueos';
import arqueoNew from './modules/reportes/new-arqueos';
import todays_surgery from './modules/todaysSurgery';
import scheduleLockSurgery from './modules/scheduleLockSurgery';
import profile from './modules/profile';
import fee from './modules/surgeryFee';
import consult from './modules/consult';
import plan from './modules/plan';
import records from './modules/records';
import medical_history from "./modules/medical_history";
import reporte_bodega from "./modules/reportes/bodega";
import detalles_reporte_bodega from "./modules/reportes/DetallesBodegas";
import reporte_consulta from "./modules/reportes/consultas";
import reporte_consignacion from "./modules/reportes/ProductsConsignment";
import reporte_cuenta_abierta from "./modules/reportes/cuentasAbiertas";
import reporte_cuenta_abierta_igss from "./modules/reportes/cuentasAbiertasIgss";
import reporte_consolidado from "./modules/reportes/consolidado";
import reporte_consolidado_gastos from "./modules/reportes/consolidadoGastos";
import tiemposConsulta from "./modules/reportes/tiemposConsulta";
import reporte_gastos from "./modules/reportes/gastos";
import procedimientos_promedio from './modules/reportes/procedimientosPromedio';
import reporte_pago_grupal from "./modules/reportes/pagoGrupal";
import bitacora from "./modules/bitacora";
import dashboard from './modules/dashboard';
import {cleanState} from "../utility/variables";
import tasks from "./modules/tasks";
import treatments from './modules/treatments';

const appReducer = combineReducers({
    form,
    bitacora,
    consult,
    dashboard,
    pos,
    login,
    roles,
    banks,
    users,
    interpreter,
    routing,
    patients,
    services,
    pendings,
    schedule,
    cashBoxes,
    referents,
    subsidiary,
    institution,
    bankAccounts,
    appointments,
    scheduleLock,
    serviceCategory,
    referentCategory,
    oppeningClosingBox,
    doctor,
    sales,
    configuration,
    due,
    entity,
    coupon,
    whearhouse,
    whearhouseAdmin,
    provider,
    movement,
    payment,
    receivable,
    purchase_order,
    order,
    entry_order,
    small_box,
    spending_type,
    expense,
    retention_payment,
    surgery,
    diagnostic_orden,
    scheduleSurgery,
    payback,
    arqueo,
    arqueoNew,
    todays_surgery,
    scheduleLockSurgery,
    profile,
    fee,
    plan,
    records,
    medical_history,
    detalles_reporte_bodega,
    reporte_bodega,
    reporte_consulta,
    reporte_consignacion,
    reporte_cuenta_abierta,
    reporte_cuenta_abierta_igss,
    reporte_consolidado,
    reporte_consolidado_gastos,
    reporte_gastos,
    reporte_pago_grupal,
    procedimientos_promedio,
    tiemposConsulta,
    tasks,
    treatments
});


export default (state, action) => {
    if (action.type === RESET_STORE) {
        state = {
            ...state,
            ...cleanState
        }
    }
    return appReducer(state, action)
}
