const url = 'reporte/cuentas-abiertas';
import { api } from 'api';
import Swal from 'sweetalert2';
import { push } from 'react-router-redux';
import { goBack } from 'react-router-redux';
import { initialize as initializeForm } from 'redux-form';
import { createActions, handleActions } from 'redux-actions';
import moment from "moment";

import { actions as saleActions } from '../sales';

const LOADER_OPEN_ACCOUNTS = 'LOADER_OPEN_ACCOUNTS';
const SET_DATA_OPEN_ACCOUNTS = 'SET_DATA_OPEN_ACCOUNTS';
const SET_PAGE_OPEN_ACCOUNTS = 'SET_PAGE_OPEN_ACCOUNTS';
const SET_BUSCADOR_OPEN_ACCOUNTS = 'SET_BUSCADOR_OPEN_ACCOUNTS';
const SET_FILTRO_OPEN_ACCOUNTS = 'SET_FILTRO_OPEN_ACCOUNTS';
const SET_INICIAL_DATE_OPEN_ACCOUNTS = 'SET_INICIAL_DATE_OPEN_ACCOUNTS';
const SET_FINAL_DATE_OPEN_ACCOUNTS = 'SET_FINAL_DATE_OPEN_ACCOUNTS';
const SET_SALDO_TOTAL = 'SET_SALDO_TOTAL';
const CHANGE_SELECT_FILTER = 'CHANGE_SELECT_FILTER';

const listar = (page = 1) =>  (dispatch, getStore) => {
    dispatch({type: LOADER_OPEN_ACCOUNTS, cargando: true});
    const store = getStore();
    const search = store.reporte_cuenta_abierta.buscador;
    const filtro = store.reporte_cuenta_abierta.filtro;
    const initial_date = store.reporte_cuenta_abierta.initial_date;
    const final_date = store.reporte_cuenta_abierta.final_date;
    const select_search = store.reporte_cuenta_abierta.select_search;

    let params;
    if(!!initial_date && !!final_date){
        params = {page, search, select_search, initial_date, final_date};
    }else{
        params = {page, search, select_search};
    }
    if(filtro){
        params[''] = filtro
    }

    api.get(`${url}/getCuentasAbiertas`, params).catch((error) => {
        dispatch({type: LOADER_OPEN_ACCOUNTS, cargando: false});
        Swal(
            'Error',
            error.detail || 'Ha ocurrido un error, por favor vuelva a intentar.',
            'error'
        );
    }).then((data) => {
        if(data){
            console.log("Cuentas Abiertas: ", data);
            dispatch({type: SET_DATA_OPEN_ACCOUNTS, data});
            dispatch({type: SET_PAGE_OPEN_ACCOUNTS, page});

            api.get(`${url}/getTotalCuentasAbiertas`, params).catch((error) => {
                Swal(
                    'Error',
                    error.detail || 'Ha ocurrido un error, por favor vuelva a intentar.',
                    'error'
                );
            }).then((data) => {
                if(data){
                    console.log("Total cuentas Abiertas: ", data);
                    dispatch({type: SET_SALDO_TOTAL, saldo_total: data.saldo});
                }
            })
        }
        dispatch({type: LOADER_OPEN_ACCOUNTS, cargando: false});
    })
};

const changeFilter = (option) => (dispatch) => {
    dispatch({type: CHANGE_SELECT_FILTER, select_search: option});
    dispatch(listar(1));
};

const search = (search) => (dispatch) => {
    dispatch({type: SET_BUSCADOR_OPEN_ACCOUNTS, buscador: search});
    dispatch(listar(1));
};

const filtro = (filtro) => (dispatch, getStore) => {
    if (filtro == 0){
        filtro = null
    }
    dispatch({type: SET_FILTRO_OPEN_ACCOUNTS, filtro});
    dispatch(listar(1));
};

const setFinalDate = (value) => (dispatch) => {
    dispatch({type: SET_FINAL_DATE_OPEN_ACCOUNTS, final_date: value});
    // dispatch(listar(1));
}

const setInitialDate = (value) => (dispatch) => {
    dispatch({type: SET_INICIAL_DATE_OPEN_ACCOUNTS, initial_date: value});
    // dispatch(listar(1));
}

const openAccount = (id) => (dispatch, getStore) => {
    dispatch(saleActions.openAccount(id));
}

const cancelOpenAccount = (id) => (dispatch, getStore) => {
    dispatch(saleActions.cancelOpenAccount(id));
}

export const actions = {
    listar,
    search,
    filtro,
    setFinalDate,
    setInitialDate,

    openAccount,
    cancelOpenAccount,
    changeFilter
};

export const reducer = {
    [LOADER_OPEN_ACCOUNTS]: (state, { cargando }) => {
        return {...state, cargando }
    },
    [SET_DATA_OPEN_ACCOUNTS]: (state, { data }) => {
        return {...state, data }
    },
    [SET_PAGE_OPEN_ACCOUNTS]: (state, { page }) => {
        return {...state, page }
    },
    [SET_BUSCADOR_OPEN_ACCOUNTS]: (state, { buscador }) => {
        return {...state, buscador }
    },
    [SET_FILTRO_OPEN_ACCOUNTS]: (state, { filtro }) => {
        return {...state, filtro }
    },
    [CHANGE_SELECT_FILTER]: (state, { select_search }) => {
        return {...state, select_search }
    },    
    [SET_INICIAL_DATE_OPEN_ACCOUNTS]: (state, { initial_date }) => {
        return{
            ...state,
            initial_date
        }
    },
    [SET_FINAL_DATE_OPEN_ACCOUNTS]: (state, { final_date }) => {
        return{
            ...state,
            final_date
        }
    },
    [SET_SALDO_TOTAL]: (state, { saldo_total }) => {
        return{
            ...state,
            saldo_total
        }
    },
};

export const initialState = {
    cargando: false,
    page: 1,
    data: {
        count: 0,
        next: null,
        previous: null,
        results: [],
    },
    buscador: '',
    filtro: null,
    select_search: 0,
    //initial_date: moment().date(1).format("DD/MM/YYYY"),
    //final_date: moment().format("DD/MM/YYYY"),
    initial_date: null,
    final_date: null,
    entry_movement: null,
    saldo_total:0,
};

export default handleActions(reducer, initialState)
