import React, { Component } from 'react';
import { api } from "api";
import _ from "lodash";
import { Link } from 'react-router-dom';
import { Field, reduxForm, formValueSelector } from 'redux-form';
import { validate, validators } from 'validate-redux-form';
import { renderField, renderSearchSelect, renderSelectCustom }  from 'Utils/renderField/renderField';
import { connect } from 'react-redux';
import trim from 'lodash/trim';
import validarNit from 'validar-nit-gt';

export const isEmpty = value => value === undefined || value === null || value === '';
export function NIT(value) {
    if (!isEmpty(value)) {
        value = trim(value.toUpperCase())
        return validarNit(value) ? null : value === 'CF' ? null : 'Escriba un nit válido o CF'
    }
    return null
}

let persons=[];
export const getReferents =  async (search) => {
    try{
    const response = await api.get(`configuration/getConfiguration`);
    const default_financial_entity = response.default_financial_entity;

    const data = await api.get("referents/getReferentsWithEntity/", {search});

    if (data){
        persons = [];
        data.forEach(x=>{
                //console.log(" default financial entity:  ", default_financial_entity);
                let idFinancialEntity = 0;
                let usuarioFel = "";
                let idReferentCategory = 0;
                let receipt = false;
                let afiliacion_fel = ""; //agregado

                if(x.institution !== null){
                    idFinancialEntity = x.institution.referent_category.financial_entity.id;
                    usuarioFel = x.institution.referent_category.financial_entity.usuario_fel;
                    idReferentCategory = x.institution.referent_category.id;
                    receipt = x.institution.referent_category.issue_receipt;
                    afiliacion_fel =  x.institution.referent_category.financial_entity.afiliacion_fel; //agregado
                }else{
                    idFinancialEntity = default_financial_entity.id;
                    usuarioFel = default_financial_entity.usuario_fel;
                    idReferentCategory = 0;
                    receipt = default_financial_entity.afiliacion_fel === "exento" ? true : false;
                    afiliacion_fel = default_financial_entity.afiliacion_fel; //add
                }

                persons.push(
                    {
                        value: x.id,
                        label: x.name,
                        usuario_fel: usuarioFel,
                        financial_entity: idFinancialEntity,
                        idReferentCategory: idReferentCategory,
                        receipt,
                        afiliacion_fel,
                        //acronimo
                    }
                )
                //const acronimo = x.institution.referent_category.financial_entity.acronimo
        });
        return persons;
    }else{
        return [];
    }

    }catch(error){
        console.error(error);
    }
}

let patients=[];
const getPatients = (search)  =>{
    return api.get("patients", {search}).catch((error) => ()=>console.log('search patients error: ', error))
        .then((data) => {
            if (data){
                patients = [];
                data.results.forEach(x=>{
                    const id_referent = (x.referred_by) ? x.referred_by.id : null;
                    const nombre_completo = x.first_name + " " + x.last_name;

                    patients.push({
                        value: x.id,
                        //label: x.first_name,
                        label: nombre_completo,
                        nit: x.nit,
                        id_referent,
                    });
                });
                return patients;
            }
            else{
                return [];
            }
        })
        .catch(e => {
            return [];
        })
}

let doctors=[];
const getDoctors = (search) => {
    return api.get("referents/listDoctors", {search}).catch((error) => {
        return [];
    }).then((data) => {
        if(data){
            doctors=[];
            data.results.forEach(x =>{
                doctors.push({value: x.id, label: x.name});
            });

            return doctors;
        }else{
            return [];
        }
    })
}

//let PatientForm = props => {
class PatientForm extends Component{
    findNit = (value) => {
        const nit = value.toLowerCase();
        if(nit !== '' && nit !== 'c' && nit !== 'cf'){
            this.props.findNit(nit);

        }
    }

    render(){
        const view = window.location.href.includes("view");
        const {
            handleSubmit,
            setPaciente
        } = this.props;

        return(
            <form onSubmit={handleSubmit} className="py-3 px-5">
                <div className="px-md-5 d-flex justify-content-around flex-column flex-sm-row align-items-stretch align-items-sm-center">
                    <div className="m-2">
                        <div className="d-flex justify-content-start flex-column flex-sm-row align-items-stretch align-items-sm-center">
                            <label htmlFor="patient" className="m-0">Paciente</label>
                        </div>
                        <div className=" d-flex justify-content-start flex-column flex-sm-row align-items-stretch align-items-sm-center">
                            <Field
                                    name="patient"
                                    valueKey="value"
                                    labelKey="label"
                                    label="patient"
                                    onChange={setPaciente}
                                    component={renderSearchSelect}
                                    loadOptions={getPatients}
                                    className="no-border"
                                    autofocus
                                />
                        </div>
                        {/*
                        <div className="d-flex justify-content-start flex-column flex-sm-row align-items-stretch align-items-sm-center">
                            <label htmlFor="name" className="m-0">Nombre</label>
                        </div>
                        <div className=" d-flex justify-content-start flex-column flex-sm-row align-items-stretch align-items-sm-center">
                            <Field name="name" disabled={view} component={renderField} type="text" className="form-control"/>
                        </div>
                        */}
                    </div>


                    <div className="m-2">
                        <div className="d-flex justify-content-start flex-column flex-sm-row align-items-stretch align-items-sm-center">
                            <label htmlFor="nit" className="m-0">Nit</label>
                        </div>
                        <div className=" d-flex justify-content-start flex-column flex-sm-row align-items-stretch align-items-sm-center">
                            <Field
                                name="nit"
                                disabled={view}
                                component={renderField}
                                type="text"
                                className="form-control"
                                //validate={NIT}
                                //onChange={(e) => this.findNit(e.target.value)}
                                />
                        </div>
                    </div>
                </div>

                <div className="px-md-5 d-flex justify-content-around flex-column flex-sm-row align-items-stretch align-items-sm-center">
                    <div className="m-2">
                        <div className="d-flex justify-content-start flex-column flex-sm-row align-items-stretch align-items-sm-center">
                            <label htmlFor="referente" className="m-0">Referente</label>
                        </div>
                        <div className=" d-flex justify-content-start flex-column flex-sm-row align-items-stretch align-items-sm-center">
                            {/*<Field name="referente" disabled={view} component={renderField} type="text" className="form-control"/>*/}
                            <Field
                                    name="referent"
                                    valueKey="value"
                                    labelKey="label"
                                    label="referent"
                                    component={renderSearchSelect}
                                    loadOptions={getReferents}
                                    className="no-border"
                                />
                        </div>
                    </div>

                    <div className="m-2">
                        <div className="d-flex justify-content-start flex-column flex-sm-row align-items-stretch align-items-sm-center">
                            <label htmlFor="referente" className="m-0">Atendido por</label>
                        </div>
                        <div className=" d-flex justify-content-start flex-column flex-sm-row align-items-stretch align-items-sm-center">
                            {/*<Field name="referente" disabled={view} component={renderField} type="text" className="form-control"/>*/}
                            <Field
                                    name="medic"
                                    valueKey="value"
                                    labelKey="label"
                                    label="medic"
                                    component={renderSearchSelect}
                                    loadOptions={getDoctors}
                                    className="no-border"
                                />
                        </div>
                    </div>
                </div>

                <div className="px-md-5 d-flex justify-content-around flex-column flex-sm-row align-items-stretch align-items-sm-center">
                    <div className="m-2">
                        <div className="d-flex justify-content-start flex-column flex-sm-row align-items-stretch align-items-sm-center">
                            <label htmlFor="referente" className="m-0">Refractado por</label>
                        </div>
                        <div className=" d-flex justify-content-start flex-column flex-sm-row align-items-stretch align-items-sm-center">
                            <Field
                                name="refracted"
                                valueKey="value"
                                labelKey="label"
                                label="refracted"
                                component={renderSearchSelect}
                                loadOptions={getDoctors}
                                className="no-border"
                            />
                        </div>
                    </div>
                </div>
                <div className="row-fluid">
                    <div className="d-flex justify-content-center flex-column flex-sm-row align-items-stretch align-items-sm-center">
                        {/*<Link className="btn btn-secondary m-3" to="/oppening-closing/">{!view ? ("Cancelar") : ("Regresar")}</Link>*/}
                        {/*<Link className="btn btn-primary m-3" to="/oppening-closing/sales/">Siguiente</Link>*/}
                        <button type="button" className="btn btn-secondary m-3" onClick={()=>this.props.cancelSale1()}>Cancelar</button>
                        <button type="submit" className="btn btn-primary m-3">Siguiente</button>
                    </div>
                </div>
            </form>
        )
    }
};

PatientForm = reduxForm({
   form: 'patient',
   validate: data => {
        return validate(data, {
            'patient': validators.exists()('Campo requerido.'),
            'name': validators.exists()('Campo requerido.'),
            'nit': validators.exists()('Campo requerido.'),
            'referent': validators.exists()('Campo requerido.'),
       })
   }
})(PatientForm);

PatientForm = connect(
    state => ({
        initialValues: state.sales.patientInfo
    })
)(PatientForm)

export default PatientForm;
