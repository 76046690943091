import './schedule.css';
import Table from '../Utils/Grid';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { BreakLine } from '../Utils/tableOptions';
import HeaderSimple from '../Utils/Header/HeaderSimple';
import { activeFormatter }  from '../Utils/Acciones/Acciones';
import { Link } from 'react-router-dom';
import Color from 'color';
import { api } from "api";
import moment from "moment";
import appointments from '../../../redux/modules/appointments';
import { Tooltip } from 'reactstrap';
import { __esModule } from 'react-loader-spinner/dist';
import AsyncSelect from 'react-select/lib/Async';
import Form from './ScheduleForm';
import classNames from 'classnames';
import Modal from 'react-responsive-modal'; 
import LoadMask from '../Utils/LoadMask/LoadMask';
import MomentLocaleUtils, {
    formatDate,
    parseDate,
} from 'react-day-picker/moment';
import DayPickerInput from 'react-day-picker/DayPickerInput';

const days = ["Lunes", "Martes", "Miércoles", "Jueves", "Viernes", "Sábado", "Domingo"]

const hours = [
    {value:0.0, label:'0:00 AM', hrs:'00:00'}, {value:0.5, label:'0:30 AM', hrs:'00:30'},
    {value:1.0, label:'1:00 AM', hrs:'01:00'}, {value:1.5, label:'1:30 AM', hrs:'01:30'},
    {value:2.0, label:'2:00 AM', hrs:'02:00'}, {value:2.5, label:'2:30 AM', hrs:'02:30'},
    {value:3.0, label:'3:00 AM', hrs:'03:00'}, {value:3.5, label:'3:30 AM', hrs:'03:30'},
    {value:4.0, label:'4:00 AM', hrs:'04:00'}, {value:4.5, label:'4:30 AM', hrs:'04:30'},
    {value:5.0, label:'5:00 AM', hrs:'05:00'}, {value:5.5, label:'5:30 AM', hrs:'05:30'},
    {value:6.0, label:'6:00 AM', hrs:'06:00'}, {value:6.5, label:'6:30 AM', hrs:'06:30'},
    {value:7.0, label:'7:00 AM', hrs:'07:00'}, {value:7.5, label:'7:30 AM', hrs:'07:30'},
    {value:8.0, label:'8:00 AM', hrs:'08:00'}, {value:8.5, label:'8:30 AM', hrs:'08:30'},
    {value:9.0, label:'9:00 AM', hrs:'09:00'}, {value:9.5, label:'9:30 AM', hrs:'09:30'},

    {value:10.0, label:'10:00 AM', hrs:'10:00'}, {value:10.5, label:'10:30 AM', hrs:'10:30'},
    {value:11.0, label:'11:00 AM', hrs:'11:00'}, {value:11.5, label:'11:30 AM', hrs:'11:30'},
    {value:12.0, label:'12:00 PM', hrs:'12:00'}, {value:12.5, label:'12:30 PM', hrs:'12:30'},
    {value:13.0, label:'1:00 PM', hrs:'13:00'}, {value:13.5, label:'1:30 PM', hrs:'13:30'},
    {value:14.0, label:'2:00 PM', hrs:'14:00'}, {value:14.5, label:'2:30 PM', hrs:'14:30'},
    {value:15.0, label:'3:00 PM', hrs:'15:00'}, {value:15.5, label:'3:30 PM', hrs:'15:30'},
    {value:16.0, label:'4:00 PM', hrs:'16:00'}, {value:16.5, label:'4:30 PM', hrs:'16:30'},
    {value:17.0, label:'5:00 PM', hrs:'17:00'}, {value:17.5, label:'5:30 PM', hrs:'17:30'},
    {value:18.0, label:'6:00 PM', hrs:'18:00'}, {value:18.5, label:'6:30 PM', hrs:'18:30'},
    {value:19.0, label:'7:00 PM', hrs:'19:00'}, {value:19.5, label:'7:30 PM', hrs:'19:30'},
    {value:20.0, label:'8:00 PM', hrs:'20:00'}, {value:20.5, label:'8:30 PM', hrs:'20:30'},
    {value:21.0, label:'9:00 PM', hrs:'21:00'}, {value:21.5, label:'9:30 PM', hrs:':2130'},
    {value:22.0, label:'10:00 PM', hrs:'22:00'}, {value:22.5, label:'10:30 PM', hrs:'22:30'},
    {value:23.0, label:'11:00 PM', hrs:'23:00'}, {value:23.5, label:'11:30 PM', hrs:'23:30'},
]

let persons = []

const getPersons = (search) => {
    return api.get("referents/listDoctorsSelect", {search}).catch((error) => {})
        .then((data) => {
            if (data){
                persons = [];
                data.results.forEach(x=>{
                    persons.push({value: x.id, label: x.name})
                })
                return persons
            }
            else{
                return []
            }
        })
        .catch(e => {
            return []
        })
}

class Schedule extends Component {
    state = {
        date: "",
        base_date: "",
        doctor_id: "",
        open_tab: true,
        monthly_view: 0,
        show_form: 0,
        week: {
            monday: "",
            tuesday: "",
            wednesday: "",
            thursday: "",
            friday: "",
            saturday: "",
            sunday: "",
        },
        tooltips: [],
    }

    cancel = () => {
        this.setState({
            show_form: 0
        })
    }

    toggle = (id) => {
        return () => {
            const { state: { tooltips }} = this;
            tooltips[id] = !tooltips[id];
            this.setState({
                tooltips
            });
        }
    }

    showTooltip = (id, value) =>  {
        return () => {
            const { state: { tooltips }} = this;
            tooltips[id] = value;
            this.setState({
                tooltips
            });
        }
    }

    returnDayString(number){
        if(number == 0){
            return "LUNES"
        } else if(number == 1){
            return "MARTES"
        } else if(number == 2){
            return "MIÉRCOLES"
        } else if(number == 3){
            return "JUEVES"
        } else if(number == 4){
            return "VIERNES"
        } else if(number == 5){
            return "SÁBADO"
        } else if(number == 6){
            return "DOMINGO"
        } else {
            return "ERROR DE DIA"
        }
    }

    isTooltipOpen = (id) => {
        //return !!this.state.tooltips[id];
        return false;
    }

    componentWillMount() {

        moment.locale('es')

        this.props.getDoctors()
        this.props.listar(moment().weekday(0).format('YYYY-MM-DD'), moment().weekday(6).format('YYYY-MM-DD'), this.state.doctor_id, 1)
        this.setState({
            date: moment(),
            base_date: moment(),
            week: {
                monday: moment().weekday(0),
                tuesday: moment().weekday(1),
                wednesday: moment().weekday(2),
                thursday: moment().weekday(3),
                friday: moment().weekday(4),
                saturday: moment().weekday(5),
                sunday: moment().weekday(6),
            }
        })
        const {
            miniCreateInitialize,
        } = this.props;

        miniCreateInitialize();
    }

    tabToggle = () => {
        this.setState({
            open_tab: !this.state.open_tab
        })
    }

    changeWeek = (value) => {
        console.log("new week ::: ", moment(this.state.date, "DD-MM-YYYY").add(value * 7, 'days'));

        return () => {
            this.setState({
                date: moment(this.state.date, "DD-MM-YYYY").add(value * 7, 'days'),
            }, () => {
                this.setState({
                    week: {
                        monday: moment(this.state.date, 'YYYY-MM-DD').weekday(0),
                        tuesday: moment(this.state.date, 'YYYY-MM-DD').weekday(1),
                        wednesday: moment(this.state.date, 'YYYY-MM-DD').weekday(2),
                        thursday: moment(this.state.date, 'YYYY-MM-DD').weekday(3),
                        friday: moment(this.state.date, 'YYYY-MM-DD').weekday(4),
                        saturday: moment(this.state.date, 'YYYY-MM-DD').weekday(5),
                        sunday: moment(this.state.date, 'YYYY-MM-DD').weekday(6),
                    }
                }, () => {
                    if(!this.state.doctor_id) {
                        this.props.listar(this.state.week.monday.format("YYYY-MM-DD"), this.state.week.sunday.format("YYYY-MM-DD"), this.state.doctor_id, 1)
                    } else {
                        this.props.listar(this.state.week.monday.format("YYYY-MM-DD"), this.state.week.sunday.format("YYYY-MM-DD"), this.state.doctor_id)
                    }
                })
            })
        }
    }

    changeWeekFromADay = (value) => {
        const new_date = moment(this.state.date, "DD-MM-YYYY").add(value,'days'); 

        return () => {
            this.setState({
                date: new_date,
            }, () => {
                this.setState({
                    week: {
                        monday: moment(this.state.date, 'YYYY-MM-DD').weekday(0),
                        tuesday: moment(this.state.date, 'YYYY-MM-DD').weekday(1),
                        wednesday: moment(this.state.date, 'YYYY-MM-DD').weekday(2),
                        thursday: moment(this.state.date, 'YYYY-MM-DD').weekday(3),
                        friday: moment(this.state.date, 'YYYY-MM-DD').weekday(4),
                        saturday: moment(this.state.date, 'YYYY-MM-DD').weekday(5),
                        sunday: moment(this.state.date, 'YYYY-MM-DD').weekday(6),
                    }
                }, () => {
                    if(!this.state.doctor_id) {
                        this.props.listar(this.state.week.monday.format("YYYY-MM-DD"), this.state.week.sunday.format("YYYY-MM-DD"), this.state.doctor_id, 1)
                    } else {
                        this.props.listar(this.state.week.monday.format("YYYY-MM-DD"), this.state.week.sunday.format("YYYY-MM-DD"), this.state.doctor_id)
                    }
                })
            })
        }
    }

    afterSubmit = (data) => {
        this.props.schedule_create(data, this.state)
        this.cancel()
    }

    render() {

        const classes = 'tooltip-inner'

        const {
            page,
            data, 
            listar,
            detail,
            update,
            destroy,
            doctors,
            cargando,
            getDoctors,
            appointments,
            isNavBarOpen,
            schedule_create,
        } = this.props;

        //const cleanPatient = this.props.tooltip_info && this.props.tooltip_info.patient ? this.props.tooltip_info.patient.split("/")[0].trim() : '';

        const cleanPatient = this.props.tooltip_info && this.props.tooltip_info.patient ? this.props.tooltip_info.patient : '';

        return (
            <div className={classNames('content-with-tabs', {
                'content-with-tabs-min': isNavBarOpen,
            })}>
                <div className="d-inline-flex w-100">
                    <div className="row-fluid w-50 tab-background">
                        <a href="/#/appointments" className="d-flex justify-content-center align-items-center tab tab-br-br">
                            CITAS DE HOY
                        </a>
                    </div>
                    <div className="row-fluid w-50 tab-background-blue">
                        <a href="/#/schedule" className="d-flex justify-content-center align-items-center tab-active tab-br-tl">
                            AGENDA
                        </a>
                    </div>
                </div>

                <Modal
                    open={this.props.modal_tooltip_show}
                    onClose={() => this.props.showTooltipModal(false)}
                    styles={{
                        modal: {
                            background: '#516060',
                        }
                    }}
                >
                    {!!this.props.tooltip_info &&
                        <div className="d-flex flex-column modal-tooltip">
                            <div className="d-flex mx-3 mt-3 bold">Nombre de quién agenda:</div>
                            <div className="d-flex ml-4 mr-3 mt-1 pl-2">{this.props.tooltip_info.holder}</div>
                            <div className="d-flex mx-3 mt-2 bold">Nombre del paciente:</div>
                            <div className="d-flex ml-4 mr-3 mt-1 pl-2">{cleanPatient}</div>

                            <div className="d-flex mx-3 mt-2 bold">Contacto:</div>
                            <div className="d-flex ml-4 mr-3 mt-1 pl-2">{this.props.tooltip_info.phone} / {this.props.tooltip_info.cellphone}</div>

                            {this.props.tooltip_info.note &&
                            <React.Fragment>
                            <div className="d-flex mx-3 mt-2 bold">Descripción:</div>
                            <div className="d-flex ml-4 mr-3 mt-1 pl-2">{this.props.tooltip_info.note}</div>
                            </React.Fragment>
                            }

                            {this.props.tooltip_info.requirements &&
                            <React.Fragment>
                            <div className="d-flex mx-3 mt-2 bold">Requerimientos:</div>
                            <div className="d-flex ml-4 mr-3 mt-1 pl-2">{this.props.tooltip_info.requirements}</div>
                            </React.Fragment>
                            }
                            <div className="d-flex mx-3 mt-2 bold">Registrado por:</div>
                            <div className="d-flex ml-4 mr-3 mt-1 pl-2"><strong>{this.props.tooltip_info.user}</strong>&nbsp;[{moment(this.props.tooltip_info.created).format("DD-MM-YYYY HH:mm:ss")}]</div>

                            {/*
                            <div className="d-flex mx-3 mt-2 bold">Teléfono:</div>
                            <div className="d-flex ml-4 mr-3 mt-1 pl-2">{this.props.tooltip_info.phone}</div>
                            <div className="d-flex mx-3 mt-2 bold">Celular:</div>
                            <div className="d-flex ml-4 mr-3 mt-1 pl-2">{this.props.tooltip_info.cellphone}</div>
                            */}
                            <div className="d-flex flex-column mx-3 mt-3 mb-1 tooltip-app-info">
                                <div className="d-flex justify-content-around">
                                    {this.returnDayString(moment(this.props.tooltip_info.date, "YYYY-MM-DD").weekday())}
                                    &nbsp;
                                    {moment(this.props.tooltip_info.date, "YYYY-MM-DD").date()}
                                    &nbsp;
                                    DE
                                    &nbsp;
                                    {moment(this.props.tooltip_info.date, "YYYY-MM-DD").format("MMMM").toUpperCase()}
                                </div>
                                <div className="d-flex justify-content-around">
                                    <div>{this.props.tooltip_info.start}</div>
                                    <div>A</div>
                                    <div>{this.props.tooltip_info.end}</div>
                                </div>
                            </div>
                            <div className="d-flex justify-content-end mt-3 top-border-white actions py-1 pr-1">
                                {!this.props.tooltip_info.in_line &&
                                    <img
                                        className="tooltip-add-icon"
                                        src={require("../../../../assets/img/icons/add-icon-white.png")}
                                        alt="add"
                                        title='pasar a cola'
                                        onClick={()=>this.props.pasarACola(this.props.tooltip_info.id, this.state.week.monday, this.state.week.sunday, this.state.doctor_id)}
                                    />
                                }
                                <div className="action-separator mx-3"/>
                                <Link to={`/schedule/${this.props.tooltip_info.id}`}><div className="edit-patient-white tooltip-action" /></Link>
                                <div className="action-separator mx-3"/>
                                <div className="delete-patient-white mr-2" onClick={() => destroy(this.props.tooltip_info.id, this.state.week.monday, this.state.week.sunday, this.state.doctor_id)}></div>
                                <div className="action-separator invisible "/>
                            </div>
                        </div>
                    }
                </Modal>

                <LoadMask loading={cargando}> 
                <div className="d-inline-flex w-100">
                    <div className="d-flex week-width justify-content-center tab-div-height">
                        <div className="table-decoration d-flex week-width flex-column ml-3 mr-2 mt-3">
                            <div className="d-flex px-3 py-2 justify-content-between schedule-bottom-margin">
                                <div className="d-flex align-items-center calendar-date-label">
                                    LUNES&nbsp;&nbsp;{this.state.week.monday.date()}&nbsp;&nbsp;DE&nbsp;&nbsp;{this.state.week.monday.format("MMMM").toUpperCase()}
                                    &nbsp;&nbsp;{this.state.week.monday.format("YYYY")}
                                    &nbsp;&nbsp;&nbsp;&nbsp;-&nbsp;&nbsp;&nbsp;&nbsp;
                                    DOMINGO&nbsp;&nbsp;{this.state.week.sunday.date()}&nbsp;&nbsp;DE&nbsp;&nbsp;{this.state.week.sunday.format("MMMM").toUpperCase()}
                                    &nbsp;&nbsp;{this.state.week.sunday.format("YYYY")}
                                </div>
                                <div className="d-flex align-items-center my-2">
                                    <a onClick={this.changeWeek(-1)} className="button d-flex align-items-center br-left ml-2 mr-2" href="/#/schedule/">
                                        &lt;
                                    </a>
                                    <a onClick={this.changeWeek(1)} className="button d-flex align-items-center br-right mr-2" href="/#/schedule/">
                                        &gt;
                                    </a>
                                    {/* <a href="/#/appointments/create" className="button d-flex align-items-center brb ml-1" >Agendar cita</a> */}
                                    {/*
                                    <div className="d-flex week-month-switch">
                                        <a onClick={ () => { this.setState({monthly_view: 0})} } className={`${!this.state.monthly_view ? 'week-montn-button-active' : 'week-montn-button'} d-flex align-items-center br-right br-left`} href="/#/schedule/">
                                            Semana
                                        </a>
                                    </div>
                                    */}
                                    <DayPickerInput 
                                        id="filtro desde:"
                                        classNames={{container:"date-picker-custom input-back-date mr-2", overlay:"date-picker-custom-overlay"}}
                                        formatDate={formatDate}
                                        parseDate={parseDate}
                                        //value={this.state.date}
                                        inputProps={{disabled: false}}
                                        dayPickerProps={{
                                            locale: 'es',
                                            localeUtils: MomentLocaleUtils,
                                            modifiers: {
                                                disabled: { after: null, before: null }
                                            }
                                        }}
                                        onDayChange={(val) => {                                                                                      
                                            const date = moment(val).format("DD/MM/YYYY");
                                            const send_date = moment(val).format("YYYY-MM-DD");
                                            
                                            this.setState({
                                                date: moment(date, "DD-MM-YYYY"),
                                            }, ()=>{

                                                this.setState({
                                                    week: {
                                                        monday: moment(this.state.date, 'YYYY-MM-DD').weekday(0),
                                                        tuesday: moment(this.state.date, 'YYYY-MM-DD').weekday(1),
                                                        wednesday: moment(this.state.date, 'YYYY-MM-DD').weekday(2),
                                                        thursday: moment(this.state.date, 'YYYY-MM-DD').weekday(3),
                                                        friday: moment(this.state.date, 'YYYY-MM-DD').weekday(4),
                                                        saturday: moment(this.state.date, 'YYYY-MM-DD').weekday(5),
                                                        sunday: moment(this.state.date, 'YYYY-MM-DD').weekday(6),
                                                    }
                                                }, () => {
                                                    if(!this.state.doctor_id) {
                                                        this.props.listar(this.state.week.monday.format("YYYY-MM-DD"), this.state.week.sunday.format("YYYY-MM-DD"), this.state.doctor_id, 1)
                                                    } else {
                                                        this.props.listar(this.state.week.monday.format("YYYY-MM-DD"), this.state.week.sunday.format("YYYY-MM-DD"), this.state.doctor_id)
                                                    }
                                                })                                                

                                            });
                                            /*
                                            if(!this.state.doctor_id) {                                 
                                                this.props.listar(send_date, send_date, this.state.doctor_id, 1);
                                            } else {                                            
                                                this.props.listar(send_date, send_date, this.state.doctor_id);
                                            } 
                                            */                                        
                                        }}
                                    />
                                </div>
                            </div>
                            <div className="w-100 no-grow">
                                {
                                    !this.state.monthly_view &&
                                        <React.Fragment>
                                            <div className="d-flex days-of-the-week-row">
                                                <div className="d-flex justify-content-center align-items-center cell-hrs-width hrs-bb">HRS </div>
                                                <div className={`${this.state.date.weekday() == 0 && this.state.date.format('YYYY-MM-DD') == this.state.base_date.format('YYYY-MM-DD') ? 'current-cell-border' : 'cell-border'} d-flex justify-content-center align-items-center cell-day-width`}>LUN / {this.state.week.monday.date()}</div>
                                                <div className={`${this.state.date.weekday() == 1 && this.state.date.format('YYYY-MM-DD') == this.state.base_date.format('YYYY-MM-DD') ? 'current-cell-border' : 'cell-border'} d-flex justify-content-center align-items-center cell-day-width`}>MAR / {this.state.week.tuesday.date()}</div>
                                                <div className={`${this.state.date.weekday() == 2 && this.state.date.format('YYYY-MM-DD') == this.state.base_date.format('YYYY-MM-DD') ? 'current-cell-border' : 'cell-border'} d-flex justify-content-center align-items-center cell-day-width`}>MIE / {this.state.week.wednesday.date()}</div>
                                                <div className={`${this.state.date.weekday() == 3 && this.state.date.format('YYYY-MM-DD') == this.state.base_date.format('YYYY-MM-DD') ? 'current-cell-border' : 'cell-border'} d-flex justify-content-center align-items-center cell-day-width`}>JUE / {this.state.week.thursday.date()}</div>
                                                <div className={`${this.state.date.weekday() == 4 && this.state.date.format('YYYY-MM-DD') == this.state.base_date.format('YYYY-MM-DD') ? 'current-cell-border' : 'cell-border'} d-flex justify-content-center align-items-center cell-day-width`}>VIE / {this.state.week.friday.date()}</div>
                                                <div className={`${this.state.date.weekday() == 5 && this.state.date.format('YYYY-MM-DD') == this.state.base_date.format('YYYY-MM-DD') ? 'current-cell-border' : 'cell-border'} d-flex justify-content-center align-items-center cell-day-width`}>SAB / {this.state.week.saturday.date()}</div>
                                                <div className={`${this.state.date.weekday() == 6 && this.state.date.format('YYYY-MM-DD') == this.state.base_date.format('YYYY-MM-DD') ? 'current-cell-border' : 'cell-border'} d-flex justify-content-center align-items-center cell-day-width`}>DOM / {this.state.week.sunday.date()}</div>
                                            </div>


                                            {/* <div className="d-flex no-grow"> */}
                                            <div className="d-flex">
                                                <div className="d-flex flex-column weekly-hrs">
                                                    {
                                                        hours.map((hour, index) => {
                                                            if(hour.value >= 7.0 && hour.value < 19.0){
                                                                return(
                                                                    <div key={index} className=" cell-height cell-hrs align-items-start">
                                                                        {hour.hrs}
                                                                    </div>
                                                                )
                                                            }
                                                        })
                                                    }
                                                </div>
                                                {
                                                    appointments.map((day, index) => {

                                                        return(
                                                            <div key={`day.${index}`} className={`${this.state.date.weekday() == index && this.state.date.format('YYYY-MM-DD') == this.state.base_date.format('YYYY-MM-DD') ? 'current-day-column' : 'day-column'} d-flex flex-column`}
                                                                //onClick={()=>console.log("Click sobre el cuadro")}
                                                                //onClick={()=>this.props.showTooltipModal(day)}
                                                                //onClick={()=>this.props.showTooltipModal(true, day)}
                                                            >
                                                                {
                                                                    day.map((hour, index_2) => {
                                                                        if(index_2 >= 14 && index_2 < 38){
                                                                            return(
                                                                                <div key={index_2} className="cell-height cell-hrs align-items-center schedule-cell-border d-flex">
                                                                                    {
                                                                                        hour.map((sticker, index_3) => {
                                                                                            if (sticker){
                                                                                                return(
                                                                                                    sticker.locked ?
                                                                                                        <div key={`${index}.${index_2}.${index_3}`} className="d-flex cell-height equal-size" style={{borderLeft: '3px solid #dcdcdc', background: '#dcdcdc'}}/>
                                                                                                :
                                                                                                    <div key={`${index}.${index_2}.${index_3}`} className="d-flex cell-height equal-size cell-label-hide" style={{borderLeft: `3px solid ${sticker.color}`, position: "relative", background: `${Color(sticker.color).alpha(0.3).lighten(0.5).hsl().string()}`}}>
                                                                                                        <div id={`sid_${sticker.sid}`} className="d-flex align-items-center cell-height label-text-cell w-100"
                                                                                                            onClick={()=>this.props.showTooltipModal(true, sticker)}
                                                                                                        >
                                                                                                            {sticker.first && `${sticker.patient}`}
                                                                                                            <Tooltip
                                                                                                                placement="right"
                                                                                                                autohide={false}
                                                                                                                target={`sid_${sticker.sid}`}
                                                                                                                delay={{ show: 0, hide: 35 }}
                                                                                                                toggle={this.toggle(sticker.sid)}
                                                                                                                isOpen={this.isTooltipOpen(sticker.sid)}
                                                                                                            >
                                                                                                                <div className="d-flex flex-column">
                                                                                                                    <div className="d-flex mx-3 mt-3 bold">Nombre de quién agenda:</div>
                                                                                                                    <div className="d-flex ml-4 mr-3 mt-1 pl-2">{sticker.holder}</div>
                                                                                                                    <div className="d-flex mx-3 mt-2 bold">Nombre del paciente:</div>
                                                                                                                    <div className="d-flex ml-4 mr-3 mt-1 pl-2">{sticker.patient}</div>

                                                                                                                    <div className="d-flex mx-3 mt-2 bold">Contacto:</div>
                                                                                                                    <div className="d-flex ml-4 mr-3 mt-1 pl-2">{sticker.phone} / {sticker.cellphone}</div>
                                                                                                                    <div className="d-flex mx-3 mt-2 bold">Registrado por:</div>
                                                                                                                    <div className="d-flex ml-4 mr-3 mt-1 pl-2"><strong>{sticker.user}</strong>&nbsp;[{sticker.created}]</div>

                                                                                                                    {/*
                                                                                                                    <div className="d-flex mx-3 mt-2 bold">Teléfono:</div>
                                                                                                                    <div className="d-flex ml-4 mr-3 mt-1 pl-2">{sticker.phone}</div>
                                                                                                                    <div className="d-flex mx-3 mt-2 bold">Celular:</div>
                                                                                                                    <div className="d-flex ml-4 mr-3 mt-1 pl-2">{sticker.cellphone}</div>
                                                                                                                    */}
                                                                                                                    <div className="d-flex flex-column mx-3 mt-3 mb-1 tooltip-app-info">
                                                                                                                        <div className="d-flex justify-content-around">
                                                                                                                            {this.returnDayString(moment(sticker.date, "YYYY-MM-DD").weekday())}
                                                                                                                            &nbsp;
                                                                                                                            {moment(sticker.date, "YYYY-MM-DD").date()}
                                                                                                                            &nbsp;
                                                                                                                            DE
                                                                                                                            &nbsp;
                                                                                                                            {moment(sticker.date, "YYYY-MM-DD").format("MMMM").toUpperCase()}
                                                                                                                        </div>
                                                                                                                        <div className="d-flex justify-content-around">
                                                                                                                            <div>{sticker.start}</div>
                                                                                                                            <div>A</div>
                                                                                                                            <div>{sticker.end}</div>
                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                    <div className="d-flex justify-content-end mt-3 top-border-white actions py-1 pr-1">
                                                                                                                        {!sticker.in_line &&
                                                                                                                            <img
                                                                                                                                className="tooltip-add-icon"
                                                                                                                                src={require("../../../../assets/img/icons/add-icon-white.png")}
                                                                                                                                alt="add"
                                                                                                                                title='pasar a cola'
                                                                                                                                onClick={()=>this.props.pasarACola(sticker.id, this.state.week.monday, this.state.week.sunday, this.state.doctor_id)}
                                                                                                                            />
                                                                                                                        }
                                                                                                                        <div className="action-separator mx-3"/>
                                                                                                                        <Link to={`/schedule/${sticker.id}`}><div className="edit-patient-white tooltip-action" /></Link>
                                                                                                                        <div className="action-separator mx-3"/>
                                                                                                                        <div className="delete-patient-white mr-2" onClick={() => destroy(sticker.id, this.state.week.monday, this.state.week.sunday, this.state.doctor_id)}></div>
                                                                                                                        <div className="action-separator invisible "/>
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                            </Tooltip>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                )
                                                                                            } else {
                                                                                                return(
                                                                                                    <div key={`${index}.${index_2}.${index_3}`} className="d-flex cell-height equal-size" style={{borderLeft: '3px solid transparent', background: 'transparent'}}/>
                                                                                                )
                                                                                            }
                                                                                        })
                                                                                    }
                                                                                </div>
                                                                            )
                                                                        }
                                                                    })
                                                                }
                                                            </div>
                                                        )
                                                    })
                                                }
                                            </div>
                                        </React.Fragment>
                                }
                            </div>
                        </div>
                    </div>

                    {/*

                        This div is where the tab and the form should be

                     */}

                    <div className="d-flex flex-column align-items-center tab-div-height">
                        <div onClick={this.tabToggle} className="little-tab-top mt-auto"></div>
                        <div onClick={this.tabToggle} className="little-tab"></div>
                        <div onClick={this.tabToggle} className="little-tab-bottom mb-auto"></div>
                    </div>

                    <div className={`${this.state.open_tab == true ? 'open' : 'closed'}-tab d-flex tab-div-height`}>
                        <div className={`${this.state.open_tab == true ? 'open' : 'closed'}-tab d-flex flex-grow-1 flex-column`}>
                            <div className="mt-3 calendar-tab d-flex flex-column" style={{overflow: 'hidden'}}>
                                <div className="d-flex flex-column h-100" style={{overflow: 'auto'}}>
                                    <div className="d-flex tab-filter py-2 align-items-center">
                                        <AsyncSelect
                                            id="filter-tab-input"
                                            isClearable
                                            className={`${this.state.open_tab ? 'w-100' : 'width-zero'} form-control p-0 border-trasparent`}
                                            classNamePrefix="react-select"
                                            theme={(theme) => {
                                                return {
                                                    ...theme,
                                                    borderRadius: '1em',
                                                    background: 'white',
                                                    colors: {
                                                        ...theme.colors,
                                                        text: '#415362',
                                                        primary25: '#ebebeb',
                                                        primary: '#0a5995',
                                                    },
                                                }
                                            }}
                                            classNamePrefix="react-select"
                                            cache={false} placeholder="Todos los doctores"
                                            defaultOptions={ true }
                                            searchPromptText="Todos los doctores"
                                            loadOptions={getPersons}
                                            onChange={(el) => {
                                                if (el)
                                                    this.setState(
                                                        {doctor_id: el.value},
                                                        () => listar(this.state.week.monday.format('YYYY-MM-DD'), this.state.week.sunday.format('YYYY-MM-DD'), this.state.doctor_id)
                                                    )
                                                else
                                                    this.setState(
                                                        {doctor_id: ""},
                                                        () => listar(this.state.week.monday.format('YYYY-MM-DD'), this.state.week.sunday.format('YYYY-MM-DD'), this.state.doctor_id, 1)
                                                    )
                                                }
                                            }
                                        />
                                    </div>
                                    {
                                        !this.state.show_form ?
                                            <React.Fragment>
                                                <div className="d-flex flex-column justify-content-end tab-transition">
                                                    <React.Fragment>
                                                        <div className="d-flex justify-content-center">
                                                            <a
                                                                href="/#/schedule/"
                                                                onClick={() => { this.props.miniCreateInitialize(); this.setState({ show_form: 1})} }
                                                                className={`${this.state.open_tab ? 'w-75' : 'width-zero'} button d-flex justify-content-center align-items-center brb mt-4`}>
                                                                Agendar cita
                                                            </a>
                                                        </div>
                                                        <div className="d-flex justify-content-center">
                                                            <a href="/#/appointments/lock-schedule-week" className={`${this.state.open_tab ? 'w-75' : 'width-zero'} button-gray d-flex justify-content-center align-items-center brb mt-4 mb-4`}>Bloquear horario</a>
                                                        </div>
                                                    </React.Fragment>
                                                </div>
                                                <div className="d-flex flex-column justify-content-end tab-transition">
                                                    {
                                                        this.state.open_tab &&
                                                            doctors.map((doctor, index) => {
                                                                return(
                                                                    <div key={`${index}.doctor_div`} className={`${this.state.open_tab && 'pl-4'} d-flex justify-content-start align-items-center`}>
                                                                        {
                                                                            this.state.open_tab &&
                                                                                <div key={`${index}.color`} style={{background: `${doctor.color}`, color: 'transparent', height: '15px', width: '15px', borderRadius: '100%'}}></div>
                                                                        }
                                                                        <div key={`${index}.doctor`} className={`${this.state.open_tab ? 'w-75 my-1 mx-2' : 'width-zero'}`} style={{color: '#555252', fontWeight: 'bold', fontFamily: 'Ubuntu', fontSize: 'small'}}>{doctor.name}</div>
                                                                    </div>
                                                                )
                                                            })
                                                    }
                                                </div>
                                            </React.Fragment>
                                    :
                                        <div className="px-3">
                                            <Form onSubmit={this.afterSubmit} cancel={this.cancel}/>
                                        </div>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                </LoadMask>
            </div>
        )
    }
}

export default Schedule;
