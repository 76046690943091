import Table from '../Utils/Grid';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import React, { Component } from 'react';
import { BreakLine } from '../Utils/tableOptions';
import HeaderSimple from '../Utils/Header/HeaderSimple';
import CustomToolbar from '../Utils/Toolbar/CustomToolbar';
import { activeFormatter }  from '../Utils/Acciones/Acciones';
import { formatoMoneda } from '../Utils/renderField/renderReadField';
import './services.css';
    
class Services extends Component {

    componentWillMount() {
        this.props.listar()
        console.log("--------------------> ", window.location.href.includes("services_conf"));
    }
    
    componentWillUnmount() {
        this.props.resetStore();
    }

    isExpandableRow(row) {
        if(row.categories_prices && row.categories_prices.length){
            return true;
        } else {
            return false;
        }
    }

    expandComponent(row) {
        return (
            <React.Fragment>
                <div className="d-flex justify-content-center mr-md-5 pr-md-5 py-md-2 expanded-prices-rows expanded-rows-title">
                    <div className="w-25 d-flex justify-content-center">
                        Categoría
                    </div>
                    <div className="w-25 d-flex justify-content-center">
                        Precio
                    </div>
                </div>
                {
                    row.categories_prices && row.categories_prices.map((price, index) => (
                        <div key={index} className="d-flex justify-content-center mr-md-5 pr-md-5 py-md-2 expanded-prices-rows">
                            <div className="w-25 d-flex justify-content-center">
                                {price.referent_category.name}
                            </div>
                            <div className="w-25 d-flex justify-content-center">
                                Q. {price.price}
                            </div>
                        </div>
                        )
                    )
                }
            </React.Fragment>
        );
    }

    comodinFormat = () => {
        return " -- -- "
    }

    render() {
        const {
          listar,
          detail,
          create,
          update,
          destroy,
          isNavBarOpen,
        } = this.props;

        const {
          cargando,
          page,
          data
        } = this.props;
  
        const margin_top = window.location.href.includes("services_conf") ? "" : "pt-5";

        return (
            <div className={classNames('content', { 'content-min': isNavBarOpen })}>
                <div className={"m-auto row-fluid w-100 " + (window.location.href.includes("services_conf") ? "" : "pt-5")}>
                    {window.location.href.includes("services_conf") &&
                        <HeaderSimple
                            texto={"Crear servicio/producto"}
                            //ruta={"/services/create"}
                            ruta={"/services_conf/create"}
                        />
                    }

                    <div className={"table-decoration "}>
                        <div className="d-flex flex-row justify-content-between align-items-center">
                            <div className="top-title-toolbar mt-3 d-flex">
                                <span className="ml-4 text-uppercase text-titulo">
                                    <strong>LISTADO DE SERVICIOS</strong>
                                </span>
                            </div>                        

                            <div className="top-title-toolbar mt-3">
                                <CustomToolbar
                                    buscar={this.props.search || ""}
                                    buscador={this.props.buscador || ""}
                                    autofocus
                                />
                            </div>
                        </div>

                        <Table
                            onPageChange={listar}
                            data={data}
                            loading={cargando}
                            page={page}
                            expandableRow={ this.isExpandableRow }
                            expandComponent={ this.expandComponent }
                        >
                            <TableHeaderColumn
                                tdStyle={BreakLine}
                                thStyle={BreakLine}
                                dataField="name" dataSort>Nombre</TableHeaderColumn>
                            <TableHeaderColumn
                                tdStyle={BreakLine}
                                thStyle={BreakLine}
                                dataField="service_type" dataSort>Tipo</TableHeaderColumn>
                            <TableHeaderColumn
                                tdStyle={BreakLine}
                                thStyle={BreakLine}
                                // dataAlign={'right'}
                                dataFormat={formatoMoneda}
                                dataField="price" dataSort>Precio</TableHeaderColumn>
                            <TableHeaderColumn
                                tdStyle={BreakLine}
                                thStyle={BreakLine}
                                dataField="code" dataSort>Código</TableHeaderColumn>
                            <TableHeaderColumn
                                tdStyle={BreakLine}
                                thStyle={BreakLine}
                                dataField="barcode" dataSort>Código de Barras</TableHeaderColumn>
                            {window.location.href.includes("services_conf") ?
                                <TableHeaderColumn
                                    dataField="id"
                                    isKey={true}
                                    dataAlign="center"
                                    //dataFormat={activeFormatter({ ver: '/services', editar: '/services', eliminar: destroy })}>Acciones</TableHeaderColumn>
                                    dataFormat={activeFormatter({ ver: '/services_conf', editar: '/services_conf', eliminar: destroy })}>Acciones</TableHeaderColumn>
                                :
                                    <TableHeaderColumn
                                        dataField="id"
                                        isKey={true}
                                        dataAlign="center"
                                        dataFormat={this.comodinFormat}
                                        width={'1%'}
                                    ></TableHeaderColumn>
                            }
                        </Table>
                    </div>
                </div>
            </div>
        )
    }
}

export default Services;
