import React, { Component } from 'react';
import "./toolbar.css";
import SearchChange from "./SearchChange"

export default class ToolbarChange extends Component {
    render() {
        return (
            <div className="w-100 d-flex justify-content-between">
                <div className="col-6 row">
                    <div className="titulo d-flex align-items-center">
                        {/* <img width={35} className="" src={this.props.icono} alt="Usuario" /> */}
                        <span className="ml-2 text-uppercase text-titulo"><strong>{this.props.titulo}</strong></span>
                    </div>
                </div>
                <div className="col-6 text-right search">
                    {(this.props.buscar !== undefined) && (
                        <SearchChange 
                            buscar={this.props.buscar}
                            buscador={this.props.buscador}
                            autofocus={this.props.autofocus}
                        />
                    )}
                </div>
            </div>
        )
    }
}
