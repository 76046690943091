import React from 'react';
import { modalTextArea, modalCurrency, renderSelectCustom } from '../Utils/renderField/renderField';
import { Field, reduxForm, formValueSelector } from 'redux-form';
import { validate, validators } from 'validate-redux-form';
import { connect } from 'react-redux'

let initialValues = {};

let FormAddCash = (props) => {
    const {
        handleSubmit,
        financial_entity_id,
    } = props;

    initialValues.financial_entity_id = financial_entity_id;

    return(
        <div className="container-fluid">
            <form onSubmit={handleSubmit} id="addCash">
                <Field name="financial_entity_id" component={"input"} type="hidden"/>

                <div className="row">
                    <div className="col-md-6 ml-auto">
                        <label>Monto</label>
                    </div>
                    <div className="col-md-6 ml-auto">
                         <Field name="mount" component={modalCurrency} value="0" type="text" className="input-modal"/>
                    </div>
                </div>
                <hr />
                <div className="row">
                    <div className="col-md-12 ml-auto">
                        <label>Descripción</label>
                    </div>
                    <div className="col-md-12 ml-auto">
                        <Field name="concept" component={modalTextArea} className="textarea-modal" />
                    </div>
                </div>
            </form>
        </div>
    )
}

FormAddCash = reduxForm({
    form: 'addCash',
    initialValues,
    validate: data => {
         return validate(data, {
             'mount': validators.exists()('Campo requerido.'),
             'concept': validators.exists()('Campo requerido.'),
        })
    }
 })(FormAddCash)

export default FormAddCash;
