import React, { Component } from 'react';
import './cashBox'
import OpenBox from './OpenBox';

const Box = (props) => {
    let open = (props.data.state.toLowerCase() == 'cerrada') ? true : false;

    let image = (open) ?
        <img className="card-img-top d-block box-image" src={require("../../../../assets/img/icons/cashBoxActive.png")} alt="CashBox image" /> :
        <img className="card-img-top d-block box-image" src={require("../../../../assets/img/icons/cashBox.png")} alt="CashBox image" />;

    let state = (open) ? "Disponible" : "En uso por " + props.data.user;
    let clase = (open) ? "" : "disabled";

    return(
        <div className="card text-center cashbox">
            <a href={'/#/oppening-closing/opening-box/' + props.data.id} className={"linkCashBox " + clase}>
            {image}
            <div className="card-body">
                <h6 className="card-title">{props.data.name}</h6>
                <p className="card-text">{state}</p>
            </div>
            </a>
        </div>
    );
}

export default Box;
