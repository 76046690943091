import React, { Component } from 'react';
import { Field } from 'redux-form';
import { renderSelectCustom } from 'Utils/renderField/renderField';
import { renderField, renderCurrency, renderPercentage, renderSearchSelect, renderFieldFormat } from 'Utils/renderField/renderField';
import Modal from 'react-responsive-modal';
import { api } from "api";

/*
const optionsPos = [
    { value: 1, label: 'VISANET' },
    { value: 2, label: 'CREDOMATIC' },
];
*/
let optionsPos = [], noOrders = [];
const getOptions = (search)  =>{
    return api.get("pos",{search}).catch((error) => {})
    .then((data) => {
        if (data){
            optionsPos = [];
            data.results.forEach(x=>{
                optionsPos.push({value: x.id, label: x.name})
            })
            return optionsPos;
        }
        else{
            return [];
        }
    })
    .catch(e => {
        return [];
    })
}

export const renderRemoveCard = ({ fields, meta: {error, submitFailed}, edit, view}) => (
    <React.Fragment>
        {
            fields.map((field, index) => (
                <tr key={index} className={"d-none"}>
                    <td className="">
                    <Field
                        component="input"
                        name={`${field}.id`}
                        //type="hidden"
                        type="text"
                    />
                    </td>
                    <td className="">
                    <Field
                        component="input"
                        name={`${field}.remove`}
                        //type="hidden"
                        type="text"
                    />
                    </td>
                    <td>
                        <Field
                            name={`${field}.name`}
                            label="name"
                            type="text"
                            component={renderField}
                            className="no-border"
                            disabled={false}
                        />
                    </td>
                    <td>
                        <Field
                            name={`${field}.pos`}
                            label="pos"
                            component={renderSearchSelect}                            
                            loadOptions={getOptions}
                            className="no-border"
                            disabled={false}
                        />
                    </td>
                    <td>
                        <Field
                            disabled={false}
                            name={`${field}.monthly_charge`}
                            component={renderCurrency}
                            type="text"
                            className="form-control"
                        />
                    </td>
                    <td>
                        <Field
                            disabled={false}
                            name={`${field}.commission_percentage`}
                            label="Porcentaje de comisión"
                            component={renderPercentage}
                            type="text"
                            className="form-control"/>
                    </td>
                </tr>
            )
            )
        }
    </React.Fragment>
)


export const renderCardRow =({ fields, remove, meta: {error, submitFailed}, edit, view}) => (

    <React.Fragment>
        {
            fields.map((field, index) => (
                <tr key={index} >
                    <td className="d-none">
                    <Field
                        component="input"
                        name={`${field}.id`}
                        type="hidden"
                    />
                    </td>

                    <td className="d-none">
                    <Field
                        component="input"
                        name={`${field}.remove`}
                        type="hidden"
                    />
                    </td>
                    <td>
                        <Field
                            name={`${field}.name`}
                            label="name"
                            type="text"
                            component={renderField}
                            className="no-border"
                            disabled={false}
                        />
                    </td>
                    <td>
                        <Field
                            name={`${field}.pos`}
                            valueKey="value"
                            labelKey="label"
                            label="name"
                            component={renderSearchSelect}                            
                            loadOptions={getOptions}
                            className="no-border"
                            disabled={false}
                        />
                    </td>
                    <td>
                        <Field
                            disabled={false}
                            name={`${field}.monthly_charge`}
                            component={renderCurrency}
                            type="text"
                            className="form-control"
                        />
                    </td>
                    <td>
                        <Field
                            disabled={false}
                            name={`${field}.commission_percentage`}
                            label="Porcentaje de comisión"
                            component={renderPercentage}
                            type="text"
                            className="form-control"
                        />
                    </td>
                    <td className="text-center">
                        <img className="action-img delete-item-package"
                            src={require("../../../../assets/img/icons/delete-icon.png")}
                            onClick={() => {
                                                if(fields.get(index) != undefined){
                                                    remove(fields.get(index))
                                                }else{
                                                    fields.remove(index)
                                                }
                                            }}
                            alt="Eliminar" />
                    </td>
                </tr>
                //</div>
                )
            )
        }
                <tr id="add-row">
                <td colSpan={4}></td>
                <td className="text-center">
                        <img onClick={() => fields.push()} className="action-img add" src={require("../../../../assets/img/icons/add-icon.png")} alt="Agregar" />
                </td>
                </tr>
        {/* row for the add button */}
        {
            submitFailed && (
                <div className="invalid-feedback d-flex justify-content-center">
                    {error}
                </div>
            )
        }
    </React.Fragment>
)

/* COMPONENTES PARA NO. ORDEN IGSS */
const getNoOrdersIgss = search => {
    api.get("no_orders_igss",{search})
    .then((data) => {
        if (data){
            noOrders = data;
            return noOrders;
        }
        else{
            return [];
        }
    })
    .catch(e => {
        return [];
    })
}

export const renderOrderIgssRow = ({ fields, remove }) =>{
    return (
        <React.Fragment>
            {fields.map((field, index) => (
                <tr key={index}>
                    <td className="d-none">
                        <Field
                            component="input"
                            name={`${field}.id`}
                            type="hidden"
                        />
                    </td>
                    <td>
                        <Field
                            name={`${field}.name`}
                            label="Nombre"
                            type="text"
                            component={renderField}
                            className="no-border"
                            disabled={false}
                        />
                    </td>
                    <td>
                        <Field
                            name={`${field}.no_order`}
                            placeholder="No. Order"
                            inputId={`${field}.id`}
                            fieldFormat={"##-#####-####"}
                            mask={['#', '#', '#', '#', '#', '#', '#', '#', '#', '#', '#']}
                            onValueChange={(values)=>{
                                return values.formattedValue
                            }}
                            component={renderFieldFormat}
                            className="no-border"
                            disabled={false}
                        />
                    </td>
                    <td className="text-center">
                        <img className="action-img delete-item-package"
                            src={require("../../../../assets/img/icons/delete-icon.png")}
                            onClick={ async () => {
                                                if(fields.get(index) != undefined && fields.get(index).id){
                                                    const id = fields.get(index).id,
                                                        result = await remove(fields.get(index))
                                                            
                                                    if(result.value==true && id==fields.get(index).id) fields.remove(index)
                                                }else{
                                                    fields.remove(index)
                                                }
                                            }}
                            alt="Eliminar" />
                    </td>
                </tr>
            ))}
            <tr id="add-row">
            <td colSpan={2}></td>
            <td className="text-center">
                    <img onClick={() => fields.push()} className="action-img add" src={require("../../../../assets/img/icons/add-icon.png")} alt="Agregar" />
            </td>
            </tr>
        </React.Fragment>
    )
}