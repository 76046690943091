import _ from "lodash";

const validate = values => {
    const errors = {};
    if(!values.appointment_holder){
        errors.appointment_holder = true;
    }
    if(!values.patient_name){
        errors.patient_name = true;
    }
    /*if(!values.phone_holder){
        errors.phone_holder = true;
    }*/
    if(!values.cellphone_holder){
        errors.cellphone_holder = true
    }
    if(!values.doctor){
        errors.doctor = true;
    }
    if(!values.date){
        errors.date = true;
    }
    if(!values.start){
        errors.start = true;
    }
    if(!values.end){
        errors.end = true;
    }
    if(!!values.start && !!values.end){
        if(values.end.value <= values.start.value)
            errors.end = true;
    }
    
    return errors
};

export default validate
